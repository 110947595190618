.news-feeds-group {
	z-index: 0;
	position: relative;
	overflow: hidden;
	padding-top: space(8);
	padding-bottom: space(8);
	// .news-feeds-group__container{
		
		// section__header-container style locate in another files

		.news-feeds-group__cards-wrapper{
			@include create-row;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: space-between;
			margin-bottom: space(3);

			@include breakpoint(large) {
				flex-direction: row;
				flex-wrap: wrap;
			}

			.article-6col{
				@include flex-grid-column();

				.article-6col__card-container{
					article.article-6col__card-link{
						// @include flex-grid-column();
						padding-top: space(6);
						padding-bottom: 0;

						@include breakpoint(large) {
							padding-bottom: space(6);
						}
					}
				}
			}

			.article-6col__copy-wrapper {
				margin: 0;
				padding: 0;

				.article-6col__copy{
					padding-top: space(5);

					.news-lockup__headline{
						color: color(red);
						margin: 0 0 space(3) 0;
						outline: unset;
						outline-offset: unset;

						a{
							&:focus{
								outline: 2px dotted #c41230;
								outline-offset: 4px;
							}
						}
					}

					.news-lockup__title{
						@include text-heading-5;
						margin: 0 0 space(3) 0;
						
						@include breakpoint(large) {
							margin: 0 0 space(3) 0;
						}

						.news-lockup__title-link{
							color: color(black);

							&::after{
								align-self: flex-end;
								background-color: transparent;
								border-right: 2px solid #c41230;
								border-top: 2px solid #c41230;
								content: "";
								display: inline-block;
								height: 9px;
								margin-right: -24px;
								transform: translateY(-100%) rotate(45deg) translateX(10px);
								transition: all .2s ease;
								width: 9px;
							}
							&:hover{
								&::after{
									transform: translateY(-16px) rotate(45deg) translateX(20px);
								}
							}
						}
					}
					
					.news-lockup__body{
						margin: 0 0 space(3) 0;
					}
					.news-lockup__byline--text{
						display: block;
						margin: 0 0 space(5) 0;
					}
				}
			}
			
		}
		/*******************************/
		.column-noimg{
			display: flex;
			margin-top: space(3);

			.article-6col{
				flex: 0 0 100%;
				max-width: 100%;
				// margin: 0 0 10px 0;
				// padding: 10px 0 0 0;

				@include breakpoint(large) {
					flex: 0 0 48%;
     			max-width: 48%;
				}

				.article-6col__card-container{
					height: 100%;

					.article-6col__card-link{
						height: 100%;
						padding: 0 !important;

						.article-6col__copy-wrapper {
							background-color: unset;
							border-bottom: 1px solid #CCCAB8;
							width: 100%;
							height: 100%;

							// .article-6col__copy{
							// 	.news-lockup__headline{

							// 	}
							// 	.news-lockup__title{
							// 		.news-lockup__title-link{

							// 		}
							// 	}
							// 	.news-lockup__byline--text{

							// 	}
							// }
						}
					}
				}

			}

			.noimage{
				display: none;
			}
 		}
		/*******************************/
		.column-2{
			.article-6col{
				flex: 0 0 100%;
				max-width: 100%;
				margin-bottom: 20px;
				@include breakpoint(large) {
					flex: 0 0 48%;
     			max-width: 48%;
				}

				.article-6col__card-container{
					.article-6col__card-link{
						.article-6col__copy-wrapper {

							@include breakpoint(large) {
								padding: 24px;
								margin-top: -36px;
							}

							.article-6col__copy{
								@include breakpoint(large) {
									padding: 0;
								}

								.news-lockup__headline{
									margin-top: 0;
								}
							}
						}
					}
				}
			}
		}
		/*******************************/
		.column-3{
			.article-6col{
				flex: 0 0 100%;
				max-width: 100%;
				@include breakpoint(large) {
					flex: 0 0 33.3%;
     			max-width: 33.3%;
				}

				.article-6col__card-container{
					.article-6col__card-link{
						.article-6col__copy-wrapper {
							background-color: unset;
							width: 100%;
						}
					}
				}

			}
		}
		/*******************************/
		.column-4{
			.article-6col{
				flex: 0 0 100%;
				max-width: 100%;
				@include breakpoint(large) {
					flex: 0 0 25%;
					max-width: 25%;
				}

				.article-6col__card-container{
					.article-6col__card-link{
						.article-6col__copy-wrapper {
							background-color: unset;
							width: 100%;
						}
					}
				}
			}
		}	

}
/*******************************/
// White Section Background
.section-bgcolor-white{
	.column-2{
		.article-6col__copy-wrapper {
			background-color: color(white);
		}
	}
}

// Warm White Section Background
.section-bgcolor-warmwhite{
	.column-2{
		.article-6col__copy-wrapper {
			background-color: color(warm-white);
		}
	}
}

// Light Tan Section Background
.section-bgcolor-lighttan{
	.column-2{
		.article-6col__copy-wrapper {
			background-color: color(light-tan);
		}
	}
}

// Tan Section Background
.section-bgcolor-tan{
	.column-2{
		.article-6col__copy-wrapper {
			background-color: color(tan);
		}
	}

	.news-lockup__byline--text{
		color: color(black);
	}
}

// Red Section Background
.section-bgcolor-red{
	.column-2{
		.article-6col__copy-wrapper {
			background-color: color(red);
		}
	}

	.news-lockup__headline{
		outline: unset;
		outline-offset: unset;

		a{
			color: color(white);

			&:focus{
				outline: 2px dotted color(white) !important;
			}
		}
	}
	.news-lockup__title{
		color: color(white);

		&:focus-within{
			outline: 2px dotted color(white) !important;
			outline-offset: 4px;
		}

		.news-lockup__title-link{
			color: color(white) !important;

			&::after{
				border-right: 2px solid color(white) !important;
				border-top: 2px solid color(white) !important;
			}
		}
	}
	.news-lockup__body{
		color: color(white);
	}
	.news-lockup__byline--text{
		color: color(white);
	}
}

