.collection-list {
  padding-top: space(10);
  padding-bottom: space(10);
  background-color: color(white);

  &.collection-list--white-warm {
    background-color: color(white-warm);
  }

  &.collection-list--red {
    background-color: color(red);
  }

  @include breakpoint(medium) {
    padding-top: space(15);
    padding-bottom: space(15);
  }

  @include breakpoint(large) {
    padding-top: space(25);
    padding-bottom: space(25);
  }

  .collection-list__header-container {
    @include create-row;

    .collection-list__header-inner {
      @include flex-grid-column(12);
      margin-left: auto;
      margin-right: auto;
      align-self: center;
      justify-self: center;
      padding-bottom: space(10);

      @include breakpoint(large) {
        @include flex-grid-column(8);
      }

      .collection-list__header-heading {
        @include text-heading-3;
        color: color(red);
        padding-bottom: space(3.75);

        @include breakpoint(large) {
          padding-bottom: space(5);
        }
      }

      .collection-list__header-subheading {
        @include text-body-regular;
        color: color(black);

        @include breakpoint(large) {
          @include text-body-large;
        }
      }
    }
  }

  .collection-list__list-container {
    @include create-row;

    .collection-list__list-inner {
      @include flex-grid-column(12);
      margin-left: auto;
      margin-right: auto;

      @include breakpoint(large) {
        @include flex-grid-column(8);
      }
    }

    li.base-list-text {
      padding: space(2) 0 space(2) space(7.5);
      display:block;

      &:before {
        content: "";
        background-image: url("../svgs/functional/icon-target--red.svg");
        background-repeat: no-repeat;
        background-size: contain;
        width: 10px;
        height: 10px;
        display: inline-block;
        margin-right: space(5);
        margin-left: space(-7.5);
      }

      .base-list-text__content {
        margin-left:0;
      }
    }
  }

  .collection-list__cta-container {
    @include create-row;
    @include flex-grid-column(4);

    @include breakpoint(large) {
      margin-left: auto;
      margin-right: auto;
      margin-top: space(10);
      @include flex-grid-column(7);
    }
  }
}
