.news-form {
  background-color: color(red);
  color: color(white);
  &__body-wrapper {
    @include news-body-regular;
  }
  &__copy-wrapper {
    @include flex-grid-size(12);
    margin-top: rem(71);
    // margin-bottom: rem(30);
    @include breakpoint(medium) {
      @include flex-grid-size(4);
      @include grid-column-position(0);
      margin-top: rem(114);
      margin-bottom: rem(114);
    }
  }
  &__decoration-bar {
    width: 1px;
    background-color: color(white);
    height: 100%;
  }
  &__form-container {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    max-width: 100%;
    justify-content: space-between;
    align-items: flex-end;
  }
  &__form-warning {
  }
  &__form-warning-container {
  }
  &__form-wrapper {
    @include flex-grid-size(12);
    // display: flex;
    // align-items: center;
    margin-bottom: rem(85);
    margin-top: rem(15);
    @include breakpoint(medium) {
      margin-top: rem(117);
      @include flex-grid-size(5);
      @include grid-column-position(2);
      margin-bottom: 0;
    }
  }
  &__heading-wrapper {
    font-family: font(freight-display);
    font-size: rem(47);
    line-height: rem(57);
    font-weight: 400;
    margin-bottom: rem(20);
  }
  &__icon {
  }
  &__icon-wrapper {
    height: rem(31);
    width: rem(31);
    margin-bottom: rem(13);
  }
  &__inner {
    @include create-news-row;
  }
  &__side-decoration {
    display: none;
    @include breakpoint(medium) {
      display: block;
      @include grid-column-position(0);
      @include flex-grid-size(1);
      margin-top: rem(125);
      display: flex;
      flex-direction: column;
      align-items: center;

      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
    }
  }

  &__submit-button {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: rem(24) rem(28);
    @include breakpoint(medium) {
      height: 50px;
      width: 50px;
      padding: 0;
    }
  }

  &__submit-button-text {
    color: color(red);
    font-family: font(proxima-nova);
    font-weight: 600;
    font-size: rem(12);
    line-height: rem(15);
    letter-spacing: rem-cal(2);
    text-transform: uppercase;
    @include breakpoint(medium) {
      display: none;
    }
  }

  &__submit-button-icon {
    height: 13px;
    width: 8px;
    @include breakpoint(medium) {
      margin: 0 auto;
    }
  }

  .form-success-message {
    font-family: font(proxima-nova);
    font-weight: 600;
    font-size: rem(12);
    line-height: rem(15);
    letter-spacing: rem-cal(2);
    text-transform: uppercase;
    flex: 0 0 100%;
    padding: 25px 28px;
  }

  input:focus, button:focus, select:focus{
    @include focus-white;
  }

  .form-element {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    @include breakpoint(small down) {
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;
    }
    width: calc(-10px + 50%);
    max-width: calc(-10px + 50%);
    flex: 0 0 calc(-10px + 50%);

    &.form-element--layout-1 {
      // @include flex-grid-column(12);ß
      // padding-top: space(10);ß
    }

    &.form-element--layout-2 {
      // @include flex-grid-column(12);ß
      flex: 2 1 20%;
      max-width: 100%;
      > .form-input__inner {
        padding-right: 20px;
      }
      @include breakpoint(small down) {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
        > .form-input__inner {
          padding-right: 0px;
        }
      }
    }

    &.form-element--layout-3 {
      // @include flex-grid-column(12);ß
      @include breakpoint(small down) {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
        margin-top: rem(27);
        border: white solid 2px;
      }
      width: 50px;
      max-width: 50px;
      flex: 0 0 50px;
      background-color: color(white);
      @include breakpoint(medium) {
        // @include flex-grid-column(4);ß
      }
    }

    &.form-element--layout-4 {
      // @include flex-grid-column(12);ß

      @include breakpoint(medium) {
        // @include flex-grid-column(3);ß
      }
    }

    .form-input__inner {
      width: 100%;
    }

    .m_form-input {
      position: relative;
      margin-bottom: rem(12);
      @include breakpoint(medium) {
        margin-bottom: 0;
      }
    }

    .m_form-input::before,
    .m_form-input::after {
      content: "";
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;
      height: 1px;
      background-color: #d1d0d0;
      z-index: 1;
    }

    .m_form-input::after {
      width: 0%;
      background-color: #7e6d53;
      transition: all 0.2s;
    }

    .m_form-input.focus::after {
      width: 100%;
    }

    label {
      font-family: font(proxima-nova);
      font-weight: 600;
      font-size: rem(12);
      line-height: rem(15);
      letter-spacing: rem-cal(2);
      text-transform: uppercase;
      display: block;
      position: relative;
      top: 20px;
      opacity: 0;
      // z-index: -1;
      transition: all 0.2s;
      color: color(white);
      margin-bottom: rem(12);
      @include breakpoint(medium) {
        margin-bottom: rem(5);
        margin-top: rem(5);
      }
    }

    label.focus {
      color: color(white);
      padding-left: rem(20);
    }

    label.no-empty,
    label.focus {
      top: 0px;
      opacity: 1;
    }

    input,
    select {
      position: relative;
      width: 100%;
      // font-size: 16px;ß
      border: none;
      font-size: rem(12);
      font-weight: 600;
      line-height: rem(15);
      letter-spacing: rem-cal(2);
      text-transform: uppercase;
      font-family: font(proxima-nova);
      background-color: color(red);
      color: color(white);
      border: white solid 2px;
      padding: rem(24) rem(28);
      height: rem(50);

      @include breakpoint(medium) {
        padding: rem(12) rem(20);
      }
      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: color(white);
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: color(white);
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: color(white);
      }
    }

    select {
      background: none;
      color: #777;
      -webkit-appearance: none;
    }

    select.focus {
      color: color(black);
    }

    .caret-down {
      position: absolute;
      top: 50%;
      right: 0;
      display: block;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid color(grey);

      &.focus {
        border-top: 5px solid color(black);
      }
    }

    .caret-up {
      position: absolute;
      top: 50%;
      right: 0;
      display: block;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid color(grey);
    }

    textarea {
      width: 100%;
      padding: 10px;
      font-size: 16px;
      border: none;
      border: 1px solid color(gray);
      font-size: 24px;
      font-family: font(freight-text);
    }

    textarea:focus {
      outline: none;
      border: 1px solid #7e6d53;
    }

    p {
      height: space(4);
      color: color(white);
      font-family: font(proxima-nova);
      font-size: 12px;
      font-weight: 400;
      margin-top: space(1);
      position: absolute;
      bottom: rem(-15);
      left: space(2);

      @include breakpoint(medium) {
        bottom: space(-6);
        left: space(3.75);
      }
    }
  }
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
