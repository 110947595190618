.news-podcast-group {
  @include breakpoint(medium) {
    overflow: hidden;
  }

  &__bar {
    height: 100%;
    width: 1px;
    background-color: color(white);
  }
  &__blog-container {
    background-color: color(red);
    overflow: hidden;
    @include breakpoint(medium) {
      overflow: unset;
      -webkit-box-shadow: -50px 0px 0px 0px color(red);
      -moz-box-shadow: -50px 0px 0px 0px color(red);
      box-shadow: -50px 0px 0px 0px color(red);
    }
  }
  &__podcast-item,
  &__blog-item {
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    flex: 1 1 100%;
  }
  &__blog-items-cta {
    @include breakpoint(small down) {
      flex: 0 1 auto;
      margin-left: auto;
    }
  }
  &__podcast-container,
  &__blog-container {
    @include flex-grid-size(6);
    @include flex-grid-row;
    max-width: unset;
  }
  &__podcast-items-cta,
  &__blog-items-cta {
    .base-cta-text {
      margin-top: 0;
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: rem(45);
    }
  }
  &__podcast-items-title,
  &__blog-items-title {
    margin-bottom: rem(25);
    margin-top: rem(35);
  }
  &__blog-items-container {
    @include breakpoint(medium) {
      flex: 0 0 67%;
      @include grid-column-position(center);
    }
  }
  &__podcast-items-container,
  &__blog-items-container {
    @include breakpoint(small down) {
      @include create-news-row;
    }
    @include breakpoint(medium) {
      display: flex;
      flex-flow: column;
    }
  }
  &__icon-container {
    height: 72px;
    width: 33px;
    transform: translateX(-50%);
    margin-top: 35px;
    @include breakpoint(medium) {
      display: flex;
    }
  }
  &__inner {
    @include breakpoint(medium) {
      @include create-news-row;
    }
  }
  &__item-divider {
    content: "";
    width: 100%;
    height: 1px;
    background: white;
    display: block;
    margin: rem(25) 0;
  }
  &__podcast-container {
    background: color(white-warm);
    @include breakpoint(medium) {
      overflow: unset;
      -webkit-box-shadow: 50px 0px 0px 0px color(white-warm);
      -moz-box-shadow: 50px 0px 0px 0px color(white-warm);
      box-shadow: 50px 0px 0px 0px color(white-warm);
    }
  }
  &__podcast-item {
    .news-podcast-group__item-divider {
      background: color(gold-accent);
    }
  }
  &__podcast-items-container {
    @include breakpoint(medium) {
      flex: 0 0 84%;
      margin-left: auto;
    }
  }
  &__side-deocration {
    display: none;
    height: 100%;
    @include breakpoint(medium) {
      display: block;
    }
  }
}

.news-podcast-group__blog-container {
  a:focus {
    .blog-lockup-item__item-container {
      @include focus-white;
    }
  }
}

.focus-white {
  &:focus-within,
  &:focus,
  &:hover {
    text-decoration: underline;
  }

  &:focus-within {
    @include focus-white;
  }
}
svg.news-podcast-group__icon {
  height: auto;
  width: 32px;
}
