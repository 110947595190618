// Colors
$colors: (
  transparent: rgba(0, 0, 0, 0),
  black-overlay: rgba(0, 0, 0, 0.8),
  black-overlay-light: rgba(0, 0, 0, 0.6),
  black-gradient: rgba(0, 0, 0, 0.01),
  black: #231f20,
  black-00: #000,
  black-28: #282828,
  white: #fff,
  white-warm: #faf9f7,
  gray: #d1d0d0,
  red: #c41230,
  red-accent: #ad102a,
  gold: #edece2,
  gold-accent: #ccc9b8,
  dark-gray: #7e6d53,
  warm-white: #FAF9F7,
  light-tan: #EDECE2,
  tan: #CCCAB8,
);

@function color($key) {
  @return map-get($colors, $key);
}

// Fonts
$fonts: (
  freight-display: (
    freight-display-pro,
    serif,
  ),
  freight-text: (
    freight-text-pro,
    serif,
  ),
  proxima-nova: (
    proxima-nova,
    sans-serif,
  ),
);

@function font($key) {
  @return map-get($fonts, $key...);
}

// Easing (material defaults)
$eases: (
  default: cubic-bezier(0.4, 0, 0.2, 1),
  accelerate: cubic-bezier(0.4, 0, 1, 1),
  decelerate: cubic-bezier(0, 0, 0.2, 1),
);

@function ease($key: default) {
  @return map-get($eases, $key);
}

// Time
$time: (
  default: 300ms,
);

@function time($key: default) {
  @return map-get($time, $key);
}

// Freight Text Pro
@font-face {
  font-family: 'freight-text-pro';
  src: url("../webfonts/freight-text/freight-text-pro-book.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'freight-text-pro';
  src: url("../webfonts/freight-text/freight-text-pro-light.otf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'freight-text-pro';
  src: url("../webfonts/freight-text/freight-text-pro-medium.otf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'freight-text-pro';
  src: url("../webfonts/freight-text/freight-text-pro-bold.otf");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'freight-text-pro';
  src: url("../webfonts/freight-text/freight-text-pro-book-italic.otf");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'freight-text-pro';
  src: url("../webfonts/freight-text/freight-text-pro-light-italic.otf");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'freight-text-pro';
  src: url("../webfonts/freight-text/freight-text-pro-medium-italic.otf");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'freight-text-pro';
  src: url("../webfonts/freight-text/freight-text-pro-bold-italic.otf");
  font-weight: 700;
  font-style: italic;
}

// Freight Display Pro
@font-face {
  font-family: 'freight-display-pro';
  src: url("../webfonts/freight-display/freight-display-pro-book.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'freight-display-pro';
  src: url("../webfonts/freight-display/freight-display-pro-light.otf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'freight-display-pro';
  src: url("../webfonts/freight-display/freight-display-pro-medium.otf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'freight-display-pro';
  src: url("../webfonts/freight-display/freight-display-pro-bold.otf");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'freight-display-pro';
  src: url("../webfonts/freight-display/freight-display-pro-book-italic.otf");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'freight-display-pro';
  src: url("../webfonts/freight-display/freight-display-pro-light-italic.otf");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'freight-display-pro';
  src: url("../webfonts/freight-display/freight-display-pro-medium-italic.otf");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'freight-display-pro';
  src: url("../webfonts/freight-display/freight-display-pro-bold-italic.otf");
  font-weight: 700;
  font-style: italic;
}