/**
 * Foundation for Sites
 * Version 6.8.1
 * https://get.foundation
 * Licensed under MIT Open Source
 */
@font-face {
  font-family: "freight-text-pro";
  src: url("../webfonts/freight-text/freight-text-pro-book.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "freight-text-pro";
  src: url("../webfonts/freight-text/freight-text-pro-light.otf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "freight-text-pro";
  src: url("../webfonts/freight-text/freight-text-pro-medium.otf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "freight-text-pro";
  src: url("../webfonts/freight-text/freight-text-pro-bold.otf");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "freight-text-pro";
  src: url("../webfonts/freight-text/freight-text-pro-book-italic.otf");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "freight-text-pro";
  src: url("../webfonts/freight-text/freight-text-pro-light-italic.otf");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "freight-text-pro";
  src: url("../webfonts/freight-text/freight-text-pro-medium-italic.otf");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "freight-text-pro";
  src: url("../webfonts/freight-text/freight-text-pro-bold-italic.otf");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "freight-display-pro";
  src: url("../webfonts/freight-display/freight-display-pro-book.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "freight-display-pro";
  src: url("../webfonts/freight-display/freight-display-pro-light.otf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "freight-display-pro";
  src: url("../webfonts/freight-display/freight-display-pro-medium.otf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "freight-display-pro";
  src: url("../webfonts/freight-display/freight-display-pro-bold.otf");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "freight-display-pro";
  src: url("../webfonts/freight-display/freight-display-pro-book-italic.otf");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "freight-display-pro";
  src: url("../webfonts/freight-display/freight-display-pro-light-italic.otf");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "freight-display-pro";
  src: url("../webfonts/freight-display/freight-display-pro-medium-italic.otf");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "freight-display-pro";
  src: url("../webfonts/freight-display/freight-display-pro-bold-italic.otf");
  font-weight: 700;
  font-style: italic;
}
.fixed-aspect-ratio-4-3 {
  padding-top: 75%;
  position: relative;
  height: 0;
  width: 100%;
}
.fixed-aspect-ratio-4-3 img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.fixed-aspect-ratio-16-9 {
  padding-top: 56.25%;
  position: relative;
  height: 0;
  width: 100%;
}
.fixed-aspect-ratio-16-9 img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}
.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.leftImage {
  float: left;
  margin-right: 20px;
}

.rightImage {
  float: right;
  margin-left: 20px;
}

.leftImage,
.rightImage {
  margin-bottom: 10px;
}

.leftCaption,
.rightCaption {
  font-size: 14px !important;
  line-height: 140% !important;
  font-style: italic;
  margin-top: 10px;
  margin-bottom: 10px !important;
  width: auto;
}

.leftCaption img,
.rightCaption img {
  width: auto;
  height: auto;
}

.leftCaption {
  float: left;
  margin-right: 20px;
}

.rightCaption {
  float: right;
  margin-left: 20px;
}

figure.leftAlignedImage,
figure.rightAlignedImage {
  max-width: 400px;
  margin-bottom: 20px;
}

figure.leftAlignedImage {
  float: left;
  margin-right: 20px;
}

figure.rightAlignedImage {
  float: right;
  margin-left: 20px;
}

figure.leftAlignedImage img,
figure.rightAlignedImage img {
  width: 100%;
  height: auto;
}

figure.leftAlignedImage figcaption,
figure.rightAlignedImage figcaption {
  font-size: 14px;
  line-height: 140%;
  font-style: italic;
  margin-top: 10px;
}

.grid {
  display: grid;
}

.grid > div {
  padding: 1rem;
}

.grid.no-padding > div {
  padding: 0;
}

.grid-gap-1 {
  gap: 1rem;
}

.grid-gap-2 {
  gap: 2rem;
}

.grid-cols-4 {
  grid-template-columns: repeat(1, 1fr);
}
@media print, screen and (min-width: 40em) {
  .grid-cols-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media print, screen and (min-width: 64em) {
  .grid-cols-4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

.grid-cols-3 {
  grid-template-columns: repeat(1, 1fr);
}
@media print, screen and (min-width: 40em) {
  .grid-cols-3 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media print, screen and (min-width: 64em) {
  .grid-cols-3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

.grid-cols-2 {
  grid-template-columns: repeat(1, 1fr);
}
@media print, screen and (min-width: 40em) {
  .grid-cols-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media print, screen and (min-width: 64em) {
  .grid-cols-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.grid-cols-1 {
  grid-template-columns: repeat(1, 1fr);
}

.noShow {
  display: none;
}

.visuallyHidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

html {
  height: 100%;
  box-sizing: border-box;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  scroll-padding: 20vh;
}

*,
::before,
::after {
  box-sizing: inherit;
  font-display: swap;
}

body {
  margin: 0;
}

img,
svg {
  display: block;
  max-width: 100%;
  height: auto;
}

ol,
ul {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
}

li {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: currentColor;
  letter-spacing: inherit;
}

p {
  margin-top: 0;
  margin-bottom: 0;
  color: currentColor;
}

a {
  color: currentColor;
  text-decoration: none;
  cursor: pointer;
}

button {
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  appearance: none;
}

blockquote {
  padding: 0;
  margin: 0;
}

hr {
  margin: 0;
  border: 0;
  border-bottom: 1px solid #e2e2e2;
}

cite,
address {
  font-style: normal;
}

figure {
  display: block;
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

[v-cloak] {
  display: none !important;
}

.text-black {
  color: #231f20;
}

.ctaArrow a {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.0625rem;
  text-transform: capitalize;
  position: relative;
  display: inline-block;
  padding: 12px 16px 12px 0;
  margin-top: 20px;
  color: #231f20 !important;
  font-style: normal !important;
  text-decoration: none !important;
}
@media print, screen and (min-width: 40em) {
  .ctaArrow a {
    font-size: 16px;
  }
}
.ctaArrow a:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.ctaArrow a.base-cta-text--light, .ctaArrow .news-card-group--background-red a.base-cta-text, .news-card-group--background-red .ctaArrow a.base-cta-text {
  color: #fff;
}
.ctaArrow a.base-cta-text--light:focus, .ctaArrow .news-card-group--background-red a.base-cta-text:focus, .news-card-group--background-red .ctaArrow a.base-cta-text:focus {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}
.ctaArrow a::before {
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
  align-self: flex-end;
  width: 9px;
  height: 9px;
  background-color: transparent;
  content: "";
  border-top: 2px solid #c41230;
  border-right: 2px solid #c41230;
  transform: translateY(-50%) rotate(45deg);
  transition: all 0.2s ease;
}
.ctaArrow a:hover::before {
  transform: translate(5px, -50%) rotate(45deg) scale(1);
}

.ctaButton {
  position: relative;
  transform: translate(6px, 6px);
  outline: 2px solid #c41230;
  outline-offset: -2px;
}
@media print, screen and (min-width: 64em) {
  .ctaButton {
    width: 200px;
  }
}
.ctaButton a {
  background-color: #c41230;
  color: #fff !important;
  transform: translate(-6px, -6px);
  font-style: normal !important;
  text-decoration: none !important;
  display: block;
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.0625rem;
  padding: 20px 40px 20px 20px;
  position: relative;
  text-transform: capitalize;
  z-index: 1;
  transition-duration: 0.3s !important;
  transition-property: transform !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}
@media print, screen and (min-width: 64em) {
  .ctaButton a {
    width: 200px;
  }
}
.ctaButton a:hover {
  transform: translate(0px, 0px);
}
.ctaButton a::after {
  content: "";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  right: 20px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}
.ctaButton a:focus {
  outline: 2px dotted #c41230 !important;
  outline-offset: 8px !important;
  transform: translate(0px, 0px);
}

@media print, screen and (min-width: 64em) {
  .sidebar-layout {
    max-width: 75rem;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-flow: row wrap;
  }
  .sidebar-layout__sidebar {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .sidebar-layout__sidebar {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .sidebar-layout__sidebar {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .sidebar-layout__sidebar {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .sidebar-layout__sidebar {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .sidebar-layout__sidebar {
    flex: 0 0 25%;
    max-width: 25%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) and (min-width: 40em) {
  .sidebar-layout__sidebar {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) and (min-width: 64em) {
  .sidebar-layout__sidebar {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 64em) and (min-width: 75em) {
  .sidebar-layout__sidebar {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 64em) and (min-width: 90em) {
  .sidebar-layout__sidebar {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .sidebar-layout__components {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .sidebar-layout__components {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .sidebar-layout__components {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .sidebar-layout__components {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .sidebar-layout__components {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .sidebar-layout__components {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    margin-top: 40px;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .sidebar-layout__components {
    margin-left: 8.3333333333%;
  }
}
@media print, screen and (min-width: 64em) {
  .sidebar-layout__components-inner {
    margin-right: -0.46875rem;
    margin-left: -0.46875rem;
    margin-right: -0.46875rem;
    margin-left: -0.46875rem;
    display: flex;
    flex-flow: row wrap;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .sidebar-layout__components-inner {
    margin-right: -0.78125rem;
    margin-left: -0.78125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .sidebar-layout__components-inner {
    margin-right: -0.78125rem;
    margin-left: -0.78125rem;
  }
}
.base-btn-primary {
  position: relative;
  display: inline-block;
  min-width: 205px;
  padding: 10px 11px;
  text-transform: capitalize;
  color: #fff;
  background-color: #c41230;
}
.base-btn-primary:hover, .base-btn-primary:focus {
  outline: none;
  background-color: #ad102a;
}
.base-btn-primary.base-btn-primary--light:focus {
  background-color: #ad102a;
}
@media screen and (min-width: 75em) {
  .base-btn-primary {
    min-width: 220px;
  }
}

.base-cta-primary {
  position: relative;
  display: inline-block;
  min-width: 200px;
  padding: 0 11px 11px 0;
  margin-top: 12px;
}
.base-cta-primary:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.base-cta-primary.base-cta-primary--light:focus {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}
@media screen and (min-width: 75em) {
  .base-cta-primary {
    min-width: 200px;
  }
}

.base-cta-primary__outline {
  position: absolute;
  top: 6px;
  right: 5px;
  bottom: 5px;
  left: 6px;
  border: 2px solid #c41230;
}
.base-cta-primary--light .base-cta-primary__outline {
  border-color: #fff;
}

.base-cta-primary__content {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.0625rem;
  text-transform: capitalize;
  position: relative;
  z-index: 1;
  display: block;
  padding: 20px 40px 20px 20px;
  color: #fff;
  background-color: #c41230;
  transition-property: transform;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
@media print, screen and (min-width: 40em) {
  .base-cta-primary__content {
    font-size: 16px;
  }
}
@media print, screen and (min-width: 64em) {
  .base-cta-primary__content {
    width: 200px;
  }
}
.base-cta-primary--light .base-cta-primary__content {
  color: #c41230;
  background-color: #fff;
}
.base-cta-primary:hover .base-cta-primary__content, .base-cta-primary:focus .base-cta-primary__content {
  transform: translate(6px, 6px);
}

.base-cta-primary__arrow {
  position: absolute;
  top: 50%;
  right: 20px;
  display: block;
  width: 10px;
  height: 10px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: translateY(-50%) rotate(45deg);
}
.base-cta-primary--light .base-cta-primary__arrow {
  border-color: #c41230;
}

.base-cta-link {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 300px;
  padding: 0 11px 11px 0;
  margin-top: 12px;
}
.base-cta-link:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.base-cta-link.base-cta-link--light:focus {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}
@media print, screen and (min-width: 64em) {
  .base-cta-link {
    min-width: 290px;
  }
}
@media screen and (min-width: 75em) {
  .base-cta-link {
    min-width: 330px;
  }
}

.base-cta-link__outline {
  position: absolute;
  top: 6px;
  right: 5px;
  bottom: 5px;
  left: 6px;
  border: 2px solid #c41230;
}
.base-cta-link--light .base-cta-link__outline {
  border-color: #fff;
}

.base-cta-link__content {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.0625rem;
  text-transform: capitalize;
  position: relative;
  z-index: 1;
  display: block;
  padding: 20px 40px 20px 20px;
  color: #fff;
  background-color: #c41230;
  text-align: center;
  transition-property: transform;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
@media print, screen and (min-width: 40em) {
  .base-cta-link__content {
    font-size: 16px;
  }
}
.base-cta-link--light .base-cta-link__content {
  color: #c41230;
  background-color: #fff;
}
.base-cta-link:hover .base-cta-link__content, .base-cta-link:focus .base-cta-link__content {
  transform: translate(6px, 6px);
}

.base-cta-text {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.0625rem;
  text-transform: capitalize;
  position: relative;
  display: inline-block;
  padding: 12px 16px 12px 0;
  margin-top: 20px;
  color: #231f20;
}
@media print, screen and (min-width: 40em) {
  .base-cta-text {
    font-size: 16px;
  }
}
.base-cta-text:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.base-cta-text.base-cta-text--light, .news-card-group--background-red .base-cta-text {
  color: #fff;
}
.base-cta-text.base-cta-text--light:focus, .news-card-group--background-red .base-cta-text:focus {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}
.base-cta-text::before {
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
  align-self: flex-end;
  width: 9px;
  height: 9px;
  background-color: transparent;
  content: "";
  border-top: 2px solid #c41230;
  border-right: 2px solid #c41230;
  transform: translateY(-50%) rotate(45deg);
  transition: all 0.2s ease;
}
.base-cta-text:hover::before {
  transform: translate(5px, -50%) rotate(45deg) scale(1);
}

.base-cta-mega {
  position: relative;
  display: block;
  padding-top: 30px;
  padding-right: 60px;
  padding-bottom: 15px;
  color: #231f20;
  font-family: freight-display-pro, serif;
  font-size: 26px;
  font-weight: 300;
  line-height: 1;
}
@media print, screen and (min-width: 64em) {
  .base-cta-mega {
    padding-top: 35px;
    padding-right: 60px;
    padding-bottom: 20px;
    font-size: 38px;
  }
}
.base-cta-mega.base-cta-mega--small {
  font-size: 26px;
}
.base-cta-mega::before, .base-cta-mega::after {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  background-color: #d1d0d0;
  content: "";
}
.base-cta-mega::after {
  background-color: #c41230;
  transform-origin: left;
  transform: scaleX(0);
  transition-property: transform;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.base-cta-mega.base-cta-mega--light::before {
  background-color: #fff;
}
.base-cta-mega.base-cta-mega--light::after {
  background-color: #ccc9b8;
}
.base-cta-mega:hover::after, .base-cta-mega:focus::after {
  transform: scaleX(1);
}
.base-cta-mega:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.base-cta-mega.base-cta-mega--light {
  color: #fff;
}
.base-cta-mega.base-cta-mega--light:focus {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}

.base-cta-mega__arrow {
  position: absolute;
  top: 50%;
  right: 5px;
  display: block;
  width: 12px;
  height: 12px;
  border-top: 2px solid #c41230;
  border-right: 2px solid #c41230;
  transform: translateY(-50%) rotate(45deg);
}
.base-cta-mega--light .base-cta-mega__arrow {
  border-color: #fff;
}

.base-cta-social {
  display: block;
  width: 40px;
  height: 40px;
  margin-top: 8px;
  padding: 2px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0);
  fill: #fff;
  transition-property: background-color, fill;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.base-cta-social:hover, .base-cta-social:focus {
  background-color: #fff;
  fill: #c41230;
}
.base-cta-social:focus {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}
.base-cta-social.base-cta-social--dark {
  border-color: #c41230;
  fill: #c41230;
}
.base-cta-social.base-cta-social--dark:hover, .base-cta-social.base-cta-social--dark:focus {
  background-color: #c41230;
  fill: #fff;
}
.base-cta-social.base-cta-social--dark:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}

.footer .base-cta-social:hover, .footer .base-cta-social:focus {
  background-color: #c41230;
  fill: #c41230;
  border-color: #ccc9b8;
}
@media print, screen and (min-width: 64em) {
  .footer .base-cta-social:hover, .footer .base-cta-social:focus {
    background-color: #c41230;
    fill: #c41230;
    border-color: #ccc9b8;
  }
}

.base-link-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: inherit;
  cursor: pointer;
}

.base-link-text:hover {
  text-decoration: underline;
}

.base-link-primary {
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
}
.base-link-primary .base-link-primary--link {
  position: relative;
  color: #231f20;
}
.base-link-primary .base-link-primary--link::after {
  content: "";
  position: absolute;
  top: 3px;
  right: -35px;
  background-image: url("../svgs/functional/icon-arrow-left--red.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 22px;
  height: 15px;
}

.base-list-text {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  align-content: flex-start;
  color: #231f20;
}
.base-list-text.base-list-text--light {
  color: #fff;
}
.base-list-text .base-list-text__content {
  margin-left: 20px;
  padding-bottom: 12px;
}
.base-list-text .base-list-text__list-style {
  min-height: 10px;
}

.base-lockup__label {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.04375rem;
  font-style: italic;
}
.base-lockup--dark .base-lockup__label {
  color: #c41230;
}
.base-lockup--light .base-lockup__label {
  color: #fff;
}
.base-lockup--black .base-lockup__label {
  color: #231f20;
}

.base-lockup--dark .base-lockup__heading {
  color: #c41230;
}
.base-lockup--light .base-lockup__heading {
  color: #fff;
}
.base-lockup--black .base-lockup__heading {
  color: #231f20;
}
.base-lockup__heading.base-lockup__heading--1 {
  font-family: freight-display-pro, serif;
  font-size: 52px;
  font-weight: 300;
  line-height: 58px;
  letter-spacing: 0.015625rem;
}
@media print, screen and (min-width: 64em) {
  .base-lockup__heading.base-lockup__heading--1 {
    font-size: 84px;
    line-height: 96px;
    letter-spacing: 0.05625rem;
  }
}
.base-lockup__heading.base-lockup__heading--2 {
  font-family: freight-display-pro, serif;
  font-size: 38px;
  line-height: 48px;
  font-weight: 400;
}
@media print, screen and (min-width: 64em) {
  .base-lockup__heading.base-lockup__heading--2 {
    font-weight: 300;
    font-size: 44px;
    line-height: 50px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
.base-lockup__heading.base-lockup__heading--3 {
  font-family: freight-display-pro, serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 300;
}
@media print, screen and (min-width: 64em) {
  .base-lockup__heading.base-lockup__heading--3 {
    font-size: 38px;
    line-height: 44px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
.base-lockup__heading.base-lockup__heading--4 {
  font-family: proxima-nova, sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
}
@media print, screen and (min-width: 64em) {
  .base-lockup__heading.base-lockup__heading--4 {
    font-size: 26px;
    line-height: 32px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.base-lockup__label + .base-lockup__heading {
  margin-top: 4px;
}

.base-lockup__copy {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
}
.base-lockup--dark .base-lockup__copy {
  color: #231f20;
}
.base-lockup--light .base-lockup__copy {
  color: #fff;
}
.base-lockup--black .base-lockup__copy {
  color: #231f20;
}
.base-lockup__copy.base-lockup__copy--small {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.04375rem;
}
.base-lockup__copy.base-lockup__copy--large {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0.015625rem;
}
@media print, screen and (min-width: 64em) {
  .base-lockup__copy.base-lockup__copy--large {
    font-size: 24px;
    line-height: 36px;
  }
}
.base-lockup__copy.base-lockup__copy--regular h6 {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: 45px;
}
@media print, screen and (min-width: 64em) {
  .base-lockup__copy.base-lockup__copy--regular h6 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.base-lockup__copy.base-lockup__copy--regular h5 {
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 45px;
}
@media print, screen and (min-width: 64em) {
  .base-lockup__copy.base-lockup__copy--regular h5 {
    font-size: 20px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #c41230;
  }
}
.base-lockup__copy.base-lockup__copy--regular h4 {
  font-family: proxima-nova, sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 45px;
}
@media print, screen and (min-width: 64em) {
  .base-lockup__copy.base-lockup__copy--regular h4 {
    font-size: 26px;
    line-height: 32px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.base-lockup__copy.base-lockup__copy--regular h3 {
  font-family: freight-display-pro, serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 300;
  margin-bottom: 15px;
  margin-top: 45px;
}
@media print, screen and (min-width: 64em) {
  .base-lockup__copy.base-lockup__copy--regular h3 {
    font-size: 38px;
    line-height: 44px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
.base-lockup__copy.base-lockup__copy--regular h3.table-header {
  font-family: proxima-nova, sans-serif;
  font-size: 20px;
  line-height: 1.3;
  font-weight: 500;
  margin-bottom: 20px;
}
@media print, screen and (min-width: 64em) {
  .base-lockup__copy.base-lockup__copy--regular h3.table-header {
    font-size: 24px;
    line-height: 1.25;
  }
}
.base-lockup__copy.base-lockup__copy--regular p {
  margin-bottom: 20px;
}
.base-lockup__copy.base-lockup__copy--regular a {
  color: #c41230;
  text-decoration: underline;
  font-style: normal;
  outline: 2px dotted transparent;
  outline-offset: 0;
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.base-lockup__copy.base-lockup__copy--regular a:hover {
  text-decoration: none;
}
.base-lockup__copy.base-lockup__copy--regular a:focus {
  text-decoration: none;
  outline: 2px dotted #c41230;
}
.base-lockup__copy.base-lockup__copy--regular ul {
  margin-bottom: 20px;
}
.base-lockup__copy.base-lockup__copy--regular ul li {
  padding: 8px 0 8px 30px;
}
.base-lockup__copy.base-lockup__copy--regular ul li:before {
  content: "";
  background-image: url("../svgs/functional/icon-target--red.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 20px;
  margin-left: -30px;
}
.base-lockup__copy.base-lockup__copy--regular ol {
  list-style: none;
  counter-reset: my-awesome-counter;
  margin-bottom: 20px;
}
.base-lockup__copy.base-lockup__copy--regular ol li {
  padding: 8px 0;
  counter-increment: my-awesome-counter;
  padding-left: 36px;
}
.base-lockup__copy.base-lockup__copy--regular ol li:before {
  content: counter(my-awesome-counter);
  font-family: proxima-nova, sans-serif;
  color: #c41230;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.75px;
  margin-right: 16px;
  text-align: center;
  display: inline-block;
  width: 20px;
  vertical-align: middle;
  margin-left: -36px;
}
.base-lockup__heading + .base-lockup__copy {
  margin-top: 24px;
}

.base-lockup__ctas {
  margin-top: 24px;
}
@media print, screen and (min-width: 40em) {
  .base-lockup__ctas .base-cta-primary:not(:last-child) {
    margin-right: 24px;
  }
}

.base-carousel-controls__button {
  transition-property: background-color;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  width: 32px;
  height: 32px;
  background-color: #c41230;
}
.base-carousel-controls__button .base-cta-primary__arrow {
  right: 50%;
  transform: translate(50%, -50%) rotate(45deg);
}
.base-carousel-controls__button[aria-label=previous] .base-cta-primary__arrow {
  right: calc(50% - 2px);
}
.base-carousel-controls__button[aria-label=next] .base-cta-primary__arrow {
  right: calc(50% + 2px);
}
.base-carousel-controls__button:focus {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}
.base-carousel-controls__button:hover {
  background-color: #ad102a;
}

.base-carousel-controls__button--next .base-cta-primary__arrow {
  transform: translate(50%, -50%) rotate(-135deg);
}

.section__header-container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  padding-bottom: 12px;
}
@media print, screen and (min-width: 40em) {
  .section__header-container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .section__header-container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .section__header-container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .section__header-container {
    width: 90%;
    max-width: 1404px;
  }
}
.section__header-container .section__header-inner {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  align-self: center;
  justify-self: center;
  padding-bottom: 12px;
}
@media print, screen and (min-width: 40em) {
  .section__header-container .section__header-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .section__header-container .section__header-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .section__header-container .section__header-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .section__header-container .section__header-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .section__header-container .section__header-inner {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    padding-bottom: 12px;
  }
}
.section__header-container .section__header-inner .base-lockup__heading {
  font-family: freight-display-pro, serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 300;
  padding-bottom: 15px;
}
@media print, screen and (min-width: 64em) {
  .section__header-container .section__header-inner .base-lockup__heading {
    font-size: 38px;
    line-height: 44px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
@media print, screen and (min-width: 64em) {
  .section__header-container .section__header-inner .base-lockup__heading {
    padding-bottom: 20px;
  }
}
.section__header-container .section__header-inner .base-lockup__copy {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0.015625rem;
  padding-left: 0 !important;
  margin-top: 0;
}
@media print, screen and (min-width: 64em) {
  .section__header-container .section__header-inner .base-lockup__copy {
    font-size: 24px;
    line-height: 36px;
  }
}
.section__header-container .section-cta-container {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-bottom: 12px;
}
@media print, screen and (min-width: 40em) {
  .section__header-container .section-cta-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .section__header-container .section-cta-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .section__header-container .section-cta-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .section__header-container .section-cta-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .section__header-container .section-cta-container {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    display: flex;
    justify-content: flex-end;
    padding-top: 0px;
    padding-bottom: 12px;
    align-self: center;
  }
}

.sectionheader {
  padding-bottom: 32px;
  padding-top: 32px;
}

.section-bgcolor-white {
  background-color: #fff;
}

.section-bgcolor-warmwhite {
  background-color: #FAF9F7;
}

.section-bgcolor-lighttan {
  background-color: #EDECE2;
}

.section-bgcolor-tan {
  background-color: #CCCAB8;
}

.section-bgcolor-red {
  background-color: #c41230;
}
.section-bgcolor-red .base-lockup__heading {
  color: #fff;
}
.section-bgcolor-red .base-lockup__copy {
  color: #fff;
}
.section-bgcolor-red .base-cta-primary__outline {
  border: 2px solid #fff;
}
.section-bgcolor-red .base-cta-primary__content {
  background-color: #fff;
  color: #c41230;
}
.section-bgcolor-red .base-cta-primary__content .base-cta-primary__arrow {
  border-right: 2px solid #c41230;
  border-top: 2px solid #c41230;
}

.breadcrumbs {
  background-color: #edece2;
  border-bottom: 1px solid #ccc9b8;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  height: 50px;
}
@media print, screen and (min-width: 64em) {
  .breadcrumbs {
    height: 41px;
  }
}

.breadcrumbs__selected-link {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 1px;
  font-weight: 800;
}

.breadcrumbs__link {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 1px;
}
.breadcrumbs__link:hover {
  text-decoration: underline;
}
.breadcrumbs__link:focus {
  outline: 2px dotted #000;
  outline-offset: 3px;
}

.breadcrumbs__divider {
  width: 10px;
  height: 1px;
  border: 1px solid #c41230;
  margin-left: 8px;
  margin-right: 8px;
}

.base-select-primary {
  border: 1px solid #d1d0d0;
  position: relative;
  min-width: 150px;
}
.base-select-primary::after {
  content: "";
  position: absolute;
  top: 12.5px;
  right: 8px;
  width: 15px;
  height: 15px;
  background-image: url("../svgs/functional/icon-arrow-down--red.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
.base-select-primary .base-select-primary--select {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
  width: 100%;
  height: 38px;
  border: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 28px;
  outline: 0;
  line-height: 20px;
  color: #c41230;
  text-transform: uppercase;
  -webkit-appearance: none;
}

.HeaderMainMenu__link.active {
  border-color: #fff !important;
}

.HeaderMainMenu__item-wrapper {
  position: relative;
  width: 100%;
  padding-right: 30px;
  padding-top: 8px;
  padding-bottom: 8px;
}
@media print, screen and (min-width: 64em) {
  .HeaderMainMenu__item-wrapper {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.button-skip-content {
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}
.button-skip-content:focus, .button-skip-content:active {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  outline: 2px dotted #c41230;
  outline-offset: 4px;
  visibility: visible;
  overflow: visible;
  clip: auto;
  width: auto;
  height: auto;
  display: block;
  padding: 10px;
  color: #c41230;
  background-color: #fff;
  outline-offset: -2px;
  z-index: 999;
}
@media print, screen and (min-width: 64em) {
  .button-skip-content:focus, .button-skip-content:active {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

.header__main_menu_child {
  display: block;
  margin-bottom: 30px;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  color: black;
  z-index: 1;
  background-color: #fff;
  bottom: 0px;
  padding: 32px 18px;
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  font-weight: 500;
  animation: child-slide-css 0.3s ease;
}
@media print, screen and (min-width: 40em) {
  .header__main_menu_child {
    padding: 80px 38px 32px 38px;
  }
}
@media print, screen and (min-width: 64em) {
  .header__main_menu_child {
    bottom: 0px;
    padding: 0 74px 80px 74px;
    color: #fff;
    left: 50%;
    width: 50%;
    background-color: transparent;
  }
}
.header__main_menu_child ul {
  background: #fff;
}
@media print, screen and (min-width: 64em) {
  .header__main_menu_child ul {
    background: transparent;
  }
}
.header__main_menu_child li {
  padding-top: 0px;
  padding-bottom: 0px;
}
.header__main_menu_child li:not(:first-child) {
  margin-top: 5px;
}
.header__main_menu_child a:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.header__main_menu_child div {
  position: relative;
  border-bottom: 1px solid #d1d0d0;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.header__main_menu_child div button {
  position: absolute;
  top: 5px;
}
.header__main_menu_child div button:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.header__main_menu_child div button span {
  left: 2px;
  transform: rotate(225deg);
  position: relative;
}
.header__main_menu_child div h1 {
  font-family: proxima-nova, sans-serif;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.44;
}
@media print, screen and (min-width: 64em) {
  .header__main_menu_child {
    position: absolute;
  }
  .header__main_menu_child div {
    display: none;
  }
  .header__main_menu_child a:focus {
    outline: 2px dotted #fff;
  }
}

@keyframes child-slide-css {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
header {
  height: 68px;
}
@media print, screen and (min-width: 64em) {
  header {
    height: 82px;
  }
}
header .top-menu {
  display: flex;
  flex-direction: column;
}
header .caret-wrap {
  position: absolute;
  top: 5px;
  width: 17px;
  height: 17px;
  right: 0;
}
header .caret-wrap:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
@media print, screen and (min-width: 64em) {
  header .caret-wrap:focus {
    outline: 2px dotted #fff;
    outline-offset: 4px;
  }
}
header span.caret {
  position: relative;
  right: 2px;
  display: block;
  width: 12px;
  height: 12px;
  border-top: 2px solid #c41230;
  border-right: 2px solid #c41230;
  transform: rotate(45deg);
}
@media print, screen and (min-width: 64em) {
  header span.caret {
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
  }
}
header .sub-menu-carat {
  top: 3px;
  left: 5%;
  width: 8.4px;
  height: 8.4px;
  border-right: 2px solid #c41230;
  border-bottom: 2px solid #c41230;
  transform: translateY(-70%) rotate(45deg);
}
@media print, screen and (min-width: 64em) {
  header .sub-menu-carat {
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
  }
}
header .sub-menu-carat-wrapper {
  position: relative;
  display: inline-block;
  top: 3px;
  left: 5%;
  width: 15px;
  height: 8.4px;
}
header .slide-fade-enter-active {
  transition: all 0.3s ease;
}
header .slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
header .slide-fade-enter,
header .slide-fade-leave-to {
  transform: translateY(-30px);
  opacity: 0;
}

.top-menu-wrapper {
  position: fixed;
  width: 100%;
  z-index: 101;
  display: flex;
  flex-direction: row;
  align-content: center;
  background-color: #c41230;
}
@media screen and (min-width: 31.25em) {
  .top-menu-wrapper {
    top: 0;
  }
}
@media print, screen and (min-width: 40em) {
  .top-menu-wrapper {
    top: 0;
  }
}
@media screen and (min-width: 52.125em) {
  .top-menu-wrapper {
    top: 0;
  }
}
@media print, screen and (min-width: 64em) {
  .top-menu-wrapper {
    top: 0;
  }
}
.top-menu-wrapper button.trigger-menu {
  font-size: 10px;
  padding: 20px;
  background-color: #ad102a;
  order: 4;
}
@media print, screen and (min-width: 64em) {
  .top-menu-wrapper button.trigger-menu {
    order: 1;
  }
}
.top-menu-wrapper button.trigger-menu:before {
  content: "";
  background: url("../svgs/functional/icon-menu-rest.svg") no-repeat scroll 0 0 transparent;
  width: 35px;
  height: 35px;
  display: inline-block;
}
.top-menu-wrapper button.trigger-menu:focus:before, .top-menu-wrapper button.trigger-menu:hover:before {
  background: url("../svgs/functional/icon-menu-hover.svg") no-repeat scroll 0 0 transparent;
}
.top-menu-wrapper button.trigger-menu:focus {
  border: 2px dotted #fff;
  outline: none;
}
.top-menu-wrapper button.trigger-menu.close:before {
  background: url("../svgs/functional/icon-menu-active.svg") no-repeat scroll 0 0 transparent;
  background-position: center;
}
.top-menu-wrapper a.logo {
  padding: 10px 5px 10px 10px;
  order: 1;
  margin-top: 10px;
}
@media print, screen and (min-width: 64em) {
  .top-menu-wrapper a.logo {
    padding: 0;
    order: 2;
    margin-top: 0;
  }
}
.top-menu-wrapper a.logo img {
  height: 50px;
  max-width: none;
}
@media print, screen and (min-width: 64em) {
  .top-menu-wrapper a.logo img {
    padding: 20px 24px;
    height: 82px;
  }
}
.top-menu-wrapper a.logo:focus {
  outline: 2px dotted #fff;
  outline-offset: 4px;
  outline-offset: -3px;
}
.top-menu-wrapper a.regionalsLogo {
  padding: 0;
  margin-top: 0;
}
.top-menu-wrapper a.regionalsLogo img {
  padding: 0;
  height: 80px;
}
@media print, screen and (min-width: 64em) {
  .top-menu-wrapper a.regionalsLogo img {
    height: 82px;
  }
}
.top-menu-wrapper button.search-icon {
  font-size: 0;
  border-left: 1px solid #ad102a;
  padding: 20px;
  margin-left: auto;
  order: 3;
}
@media print, screen and (min-width: 64em) {
  .top-menu-wrapper button.search-icon {
    border-left: none;
    margin-right: 8px;
    margin-left: 0;
    order: 4;
  }
}
.top-menu-wrapper button.search-icon:before {
  content: "";
  background: url("../svgs/functional/icon-search-white.svg") no-repeat scroll center center transparent;
  width: 30px;
  height: 16px;
  display: inline-block;
}
@media print, screen and (min-width: 64em) {
  .top-menu-wrapper button.search-icon:before {
    width: 18px;
  }
}
.top-menu-wrapper button.search-icon:focus {
  outline: none;
}
.top-menu-wrapper button.search-icon:focus:before {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}
.top-menu-wrapper button.close-icon {
  font-size: 0;
  border-left: 1px solid #ad102a;
  padding: 20px;
  margin-left: auto;
  order: 3;
  outline: 0;
}
@media print, screen and (min-width: 64em) {
  .top-menu-wrapper button.close-icon {
    border-left: none;
    margin-right: 8px;
    margin-left: 0;
    order: 4;
  }
}
.top-menu-wrapper button.close-icon:before {
  content: "";
  background: url("../svgs/functional/icon-menu-white-close.svg") no-repeat scroll center center transparent;
  width: 30px;
  height: 16px;
  display: inline-block;
}
@media print, screen and (min-width: 64em) {
  .top-menu-wrapper button.close-icon:before {
    width: 18px;
  }
}
.top-menu-wrapper button.close-icon:focus:before {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}
.top-menu-wrapper .header__utility_menu {
  margin-left: auto;
  padding: 0 32px;
  display: none;
}
@media print, screen and (min-width: 64em) {
  .top-menu-wrapper .header__utility_menu {
    display: block;
    padding-right: 0;
    order: 2;
  }
}
.top-menu-wrapper .header__utility_menu #header__utility_menu__heading {
  display: none;
}
.top-menu-wrapper .header__utility_menu ul > li {
  display: inline-block;
  padding: 32px 20px;
  position: relative;
}
@media screen and (min-width: 75em) {
  .top-menu-wrapper .header__utility_menu ul > li {
    padding: 32px 12px;
  }
}
.top-menu-wrapper .header__utility_menu ul > li a,
.top-menu-wrapper .header__utility_menu ul > li button {
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: 1px;
  color: #fff;
  text-transform: uppercase;
  padding: 2px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  transition-property: border-color;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.top-menu-wrapper .header__utility_menu ul > li a:hover,
.top-menu-wrapper .header__utility_menu ul > li button:hover {
  border-color: #fff;
}
.top-menu-wrapper .header__utility_menu ul > li a:focus,
.top-menu-wrapper .header__utility_menu ul > li button:focus {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}
.top-menu-wrapper .header__utility_menu ul > li a[aria-expanded=true] .sub-menu-carat,
.top-menu-wrapper .header__utility_menu ul > li button[aria-expanded=true] .sub-menu-carat {
  transform: translateY(-20%) rotate(224deg);
}
.top-menu-wrapper .header__utility_menu ul > li > ul {
  width: 200px;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 99;
  pointer-events: none;
  transition: opacity 0.2s ease, visibility 0.2s ease;
  background-color: #c41230;
  padding: 10px 0;
}
.top-menu-wrapper .header__utility_menu ul > li > ul li {
  padding: 4px 20px;
  display: block;
}
.top-menu-wrapper .header__utility_menu ul > li:hover > ul {
  pointer-events: auto;
}

.big-menu-wrapper {
  position: fixed;
  top: 68px;
  width: 100%;
  z-index: 100;
  background-color: #fff;
  overflow: auto;
  height: calc(100vh - 68px);
}
.big-menu-wrapper #header__main_menu_desktop__heading {
  display: none;
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper {
    top: 82px;
    height: auto;
    max-height: calc(100vh - 83px);
  }
}
.big-menu-wrapper .big-menu--inner {
  padding: 32px 0 80px;
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .big-menu-wrapper .big-menu--inner {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .big-menu--inner {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .big-menu-wrapper .big-menu--inner {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .big-menu-wrapper .big-menu--inner {
    width: 90%;
    max-width: 1404px;
  }
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .big-menu--inner {
    max-width: none;
    margin: 0;
    align-items: stretch;
    border-top: 1px solid #ad102a;
    background-color: #c41230;
    padding: 40px 56px 0 56px;
    width: 100%;
  }
}
@media screen and (min-width: 75em) {
  .big-menu-wrapper .big-menu--inner {
    padding: 40px 60px 0 60px;
  }
}
@media screen and (min-width: 90em) {
  .big-menu-wrapper .big-menu--inner {
    padding: 40px 60px 0 60px;
  }
}
.big-menu-wrapper .menu-container {
  width: 100%;
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .menu-container {
    display: flex;
    justify-content: space-between;
    width: 66.66667%;
    position: relative;
  }
}
.big-menu-wrapper .menu-container .main-slide-enter-active {
  transition: all 0.3s ease;
}
.big-menu-wrapper .menu-container .main-slide-leave-active {
  transition: all 0s ease;
}
.big-menu-wrapper .menu-container .main-slide-enter,
.big-menu-wrapper .menu-container .main-slide-leave-to {
  transform: translateX(-100px);
  opacity: 0;
}
.big-menu-wrapper .menu-container .main-menu-inner.not-active-mobile {
  display: none;
}
@media print, screen and (min-width: 40em) {
  .big-menu-wrapper .menu-container .main-menu-inner {
    padding-top: 40px;
  }
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .menu-container .main-menu-inner {
    padding: 0px 48px 80px 0;
    width: 50%;
    border-right: 1px solid #ad102a;
  }
  .big-menu-wrapper .menu-container .main-menu-inner.not-active-mobile {
    display: block;
  }
}
@media screen and (min-width: 75em) {
  .big-menu-wrapper .menu-container .main-menu-inner {
    padding: 0px 52px 80px 0;
  }
}
@media screen and (min-width: 90em) {
  .big-menu-wrapper .menu-container .main-menu-inner {
    padding: 0px 60px 80px 0;
  }
}
.big-menu-wrapper .menu-container .main-menu-inner .header__main_menu {
  border-bottom: 1px solid #d1d0d0;
  padding-bottom: 30px;
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .menu-container .main-menu-inner .header__main_menu {
    border: none;
    padding-bottom: 0;
  }
}
.big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li {
  padding: 8px 0;
}
.big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li:last-child {
  margin-bottom: 0;
}
.big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li:focus {
    outline: 2px dotted #fff;
    outline-offset: 4px;
  }
}
.big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li a.HeaderMainMenu__link {
  font-size: 18px;
  text-transform: uppercase;
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  /* display: block; */
}
.big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li a.HeaderMainMenu__link:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li a.HeaderMainMenu__link {
    text-transform: inherit;
    color: #fff;
    font-family: freight-text-pro, serif;
    font-size: 28px;
    font-weight: 300;
    line-height: 1.1;
    letter-spacing: 0.75px;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    transition-property: border-color;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    /* &.active,
      &:hover {
        opacity: 0.75;
      } */
    /* &.not-active {
        opacity: 0.5;
      } */
  }
  .big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li a.HeaderMainMenu__link:focus {
    outline: 2px dotted #fff;
    outline-offset: 4px;
  }
  .big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li a.HeaderMainMenu__link:active, .big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li a.HeaderMainMenu__link:hover {
    border-bottom: 1px solid white;
    outline: none;
  }
  .big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li a.HeaderMainMenu__link:after {
    display: none;
  }
}
.big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li a.HeaderMainMenu__link a.caret-wrap {
  display: none;
}
.big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li button.expander-wrap {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 35px;
  height: 35px;
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li button.expander-wrap {
    right: 0px;
    top: 20%;
  }
}
@media screen and (min-width: 75em) {
  .big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li button.expander-wrap {
    right: 0px;
    top: 20%;
  }
}
@media screen and (min-width: 90em) {
  .big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li button.expander-wrap {
    right: 0px;
    top: 20%;
  }
}
.big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li button.expander-wrap:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li button.expander-wrap:focus {
    top: 20%;
    right: 0;
    outline: 2px dotted #fff;
    outline-offset: 4px;
  }
}
.big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li span.expander-plus {
  position: relative;
  display: block;
  width: 12px;
  height: 12px;
}
.big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li span.expander-plus:before {
  content: "";
  background-color: #c41230;
  width: 12px;
  height: 2px;
  position: absolute;
  top: 5px;
  left: 12px;
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li span.expander-plus:before {
    background-color: #fff;
  }
}
.big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li span.expander-plus:after {
  content: "";
  background-color: #c41230;
  width: 2px;
  height: 12px;
  position: absolute;
  top: 0px;
  left: 17px;
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li span.expander-plus:after {
    background-color: #fff;
  }
}
.big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li span.expander-minus {
  position: relative;
  display: block;
  width: 12px;
  height: 12px;
}
.big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li span.expander-minus:before {
  content: "";
  background-color: #c41230;
  width: 12px;
  height: 2px;
  position: absolute;
  top: 5px;
  left: 12px;
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li span.expander-minus:before {
    background-color: #fff;
  }
}
.big-menu-wrapper .menu-container .main-menu-children-inner {
  width: 100%;
  border-bottom: 1px solid #d1d0d0;
}
.big-menu-wrapper .menu-container .main-menu-children-inner .child-slide-enter-active {
  transition: all 0.3s ease;
}
.big-menu-wrapper .menu-container .main-menu-children-inner .child-slide-leave-active {
  transition: all 0s ease;
}
.big-menu-wrapper .menu-container .main-menu-children-inner .child-slide-enter,
.big-menu-wrapper .menu-container .main-menu-children-inner .child-slide-leave-to {
  transform: translateX(100px);
  opacity: 0;
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .menu-container .main-menu-children-inner {
    border-left: 1px solid #edece2;
    padding: 80px 74px;
    width: 50%;
    border-bottom: none;
  }
  .big-menu-wrapper .menu-container .main-menu-children-inner.active {
    border-left: 1px solid #edece2;
  }
}
.big-menu-wrapper .menu-container .main-menu-children-inner .header__main_menu_child {
  display: block;
  margin-bottom: 30px;
}
.big-menu-wrapper .menu-container .main-menu-children-inner .header__main_menu_child div {
  position: relative;
  border-bottom: 1px solid #d1d0d0;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.big-menu-wrapper .menu-container .main-menu-children-inner .header__main_menu_child div button {
  position: absolute;
  top: 5px;
}
.big-menu-wrapper .menu-container .main-menu-children-inner .header__main_menu_child div button:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.big-menu-wrapper .menu-container .main-menu-children-inner .header__main_menu_child div button span {
  left: 2px;
  transform: rotate(225deg);
  position: relative;
}
.big-menu-wrapper .menu-container .main-menu-children-inner .header__main_menu_child div h1 {
  font-family: proxima-nova, sans-serif;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.44;
}
@media print, screen and (min-width: 40em) {
  .big-menu-wrapper .menu-container .main-menu-children-inner .header__main_menu_child {
    padding-top: 80px;
  }
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .menu-container .main-menu-children-inner .header__main_menu_child {
    position: absolute;
    padding-top: 0;
  }
  .big-menu-wrapper .menu-container .main-menu-children-inner .header__main_menu_child div {
    display: none;
  }
}
.big-menu-wrapper .menu-container .main-menu-children-inner ul li {
  transition: opacity 0.2s ease, visibility 0.2s ease;
  margin-top: 19px;
  position: relative;
}
.big-menu-wrapper .menu-container .main-menu-children-inner ul li button {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: 0.25px;
  padding: 2px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  transition-property: border-color;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.big-menu-wrapper .menu-container .main-menu-children-inner ul li button:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .menu-container .main-menu-children-inner ul li button {
    color: #fff;
    line-height: 0.94;
    letter-spacing: 0.25px;
  }
  .big-menu-wrapper .menu-container .main-menu-children-inner ul li button:hover {
    border-color: #fff;
  }
  .big-menu-wrapper .menu-container .main-menu-children-inner ul li button:focus {
    outline: 2px dotted #fff;
    outline-offset: 4px;
  }
  .big-menu-wrapper .menu-container .main-menu-children-inner ul li button:after {
    display: none;
  }
}
.big-menu-wrapper .menu-container .main-menu-children-inner ul li:first-child {
  margin-top: 0;
}
.big-menu-wrapper .other-menus-container {
  width: 100%;
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .other-menus-container {
    border-left: 1px solid #edece2;
    padding: 0px 60px 80px 74px;
    margin-left: auto;
    width: 33.33333%;
  }
  .big-menu-wrapper .other-menus-container .header__utility_menu {
    display: none;
  }
}
.big-menu-wrapper .other-menus-container h2 {
  text-transform: uppercase;
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.67;
  letter-spacing: 1.5px;
  text-align: center;
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .other-menus-container h2 {
    color: #fff;
    text-align: left;
  }
}
.big-menu-wrapper .other-menus-container .header__secondary_menu {
  display: none;
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .other-menus-container .header__secondary_menu {
    display: block;
  }
}
.big-menu-wrapper .other-menus-container .header__secondary_menu ul li {
  margin-top: 19px;
}
.big-menu-wrapper .other-menus-container .header__secondary_menu ul li button,
.big-menu-wrapper .other-menus-container .header__secondary_menu ul li a {
  color: #fff;
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.44;
  padding: 2px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  transition-property: border-color;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.big-menu-wrapper .other-menus-container .header__secondary_menu ul li button:hover,
.big-menu-wrapper .other-menus-container .header__secondary_menu ul li a:hover {
  border-color: #fff;
}
.big-menu-wrapper .other-menus-container .header__secondary_menu ul li button:focus,
.big-menu-wrapper .other-menus-container .header__secondary_menu ul li a:focus {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}
.big-menu-wrapper .other-menus-container .header__social_menu {
  padding-top: 30px;
}
.big-menu-wrapper .other-menus-container .header__social_menu ul {
  flex-wrap: wrap;
}
.big-menu-wrapper .other-menus-container .header__social_menu .base-cta-social {
  fill: #c41230;
  border-color: #c41230;
  background-color: #c41230;
}
.big-menu-wrapper .other-menus-container .header__social_menu .base-cta-social:hover, .big-menu-wrapper .other-menus-container .header__social_menu .base-cta-social:focus {
  background-color: #c41230;
  fill: #fff;
}
.big-menu-wrapper .other-menus-container .header__social_menu .base-cta-social:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .other-menus-container .header__social_menu {
    padding-top: 50px;
  }
  .big-menu-wrapper .other-menus-container .header__social_menu .base-cta-social {
    fill: #fff;
    border-color: #fff;
  }
  .big-menu-wrapper .other-menus-container .header__social_menu .base-cta-social:hover, .big-menu-wrapper .other-menus-container .header__social_menu .base-cta-social:focus {
    /*background-color: color(white);
    fill: color(red);*/
    border: 2px solid #ccc9b8;
  }
  .big-menu-wrapper .other-menus-container .header__social_menu .base-cta-social:focus {
    outline: 2px dotted #fff;
    outline-offset: 4px;
  }
}
.big-menu-wrapper .other-menus-container .header__utility_menu {
  padding-top: 30px;
}
.big-menu-wrapper .other-menus-container .header__utility_menu .utility-slide-enter-active {
  transition: all 0.3s ease;
}
.big-menu-wrapper .other-menus-container .header__utility_menu .utility-slide-leave-active {
  transition: all 0s ease;
}
.big-menu-wrapper .other-menus-container .header__utility_menu .utility-slide-enter,
.big-menu-wrapper .other-menus-container .header__utility_menu .utility-slide-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
.big-menu-wrapper .other-menus-container .header__utility_menu h2 {
  display: none;
}
.big-menu-wrapper .other-menus-container .header__utility_menu ul li {
  margin-top: 19px;
  position: relative;
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.big-menu-wrapper .other-menus-container .header__utility_menu ul li a,
.big-menu-wrapper .other-menus-container .header__utility_menu ul li button {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition-property: border-color;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  display: block;
}
.big-menu-wrapper .other-menus-container .header__utility_menu ul li a:hover,
.big-menu-wrapper .other-menus-container .header__utility_menu ul li button:hover {
  border-color: #c41230;
}
.big-menu-wrapper .other-menus-container .header__utility_menu ul li a:focus,
.big-menu-wrapper .other-menus-container .header__utility_menu ul li button:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.big-menu-wrapper .other-menus-container .header__utility_menu ul li .caret {
  position: absolute;
  top: 0;
  right: 0;
  width: 17px;
  height: 17px;
}
.big-menu-wrapper .other-menus-container .header__utility_menu ul li span.caret.open {
  transform: rotate(-45deg);
  right: 2px;
  top: 5px;
}
.big-menu-wrapper .other-menus-container .header__utility_menu ul li span.caret.close {
  transform: rotate(135deg);
  top: -3px;
}
.big-menu-wrapper .other-menus-container .header__utility_menu ul li ul.sub-menu {
  padding: 0px 10px;
}

.search-menu {
  position: fixed;
  top: 168px;
  left: 0px;
  width: 100%;
  z-index: 100;
  background-color: #fff;
  padding-top: 13px;
  padding-bottom: 23px;
}
@media print, screen and (min-width: 64em) {
  .search-menu {
    top: 82px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.search-menu .serch-menu-wrapper {
  position: relative;
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .search-menu .serch-menu-wrapper {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .search-menu .serch-menu-wrapper {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .search-menu .serch-menu-wrapper {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .search-menu .serch-menu-wrapper {
    width: 90%;
    max-width: 1404px;
  }
}
.search-menu .serch-menu-wrapper .HeaderSearchMenu__submit {
  position: absolute;
  top: 50%;
  right: 0px;
  width: 10px;
  height: 10px;
  border-top: 2px solid #c41230;
  border-right: 2px solid #c41230;
  transform: translateY(-50%) rotate(45deg);
}
.search-menu .serch-menu-wrapper .HeaderSearchMenu__submit:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.search-menu input {
  width: calc(100% - 15px);
  padding: 10px 0;
  font-size: 32px;
  font-weight: 200;
  letter-spacing: 1px;
  outline: none;
  border: 0;
  border-bottom: 2px solid #ccc9b8;
  /* clears the 'X' from Internet Explorer */
  /* clears the 'X' from Chrome */
}
@media print, screen and (min-width: 64em) {
  .search-menu input {
    font-size: 36px;
    padding: 16px 0;
  }
}
.search-menu input::-webkit-input-placeholder {
  color: #949494;
  letter-spacing: 2px;
  opacity: 1;
}
.search-menu input::-ms-input-placeholder {
  color: #949494;
  letter-spacing: 2px;
  opacity: 1;
}
.search-menu input::placeholder {
  color: #949494;
  letter-spacing: 2px;
  opacity: 1;
}
.search-menu input::-moz-placeholder {
  color: #949494;
  letter-spacing: 2px;
  opacity: 1;
}
.search-menu input:-moz-placeholder {
  color: #949494;
  letter-spacing: 2px;
  opacity: 1;
}
.search-menu input::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
.search-menu input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
.search-menu input::-webkit-search-decoration, .search-menu input::-webkit-search-cancel-button, .search-menu input::-webkit-search-results-button, .search-menu input::-webkit-search-results-decoration {
  display: none;
}

.top-menu--overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(35, 31, 32, 0.8);
  z-index: 99;
}

.top-menu--overlay-white {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 99;
}

.utility-dropdown--small {
  width: 100%;
  text-align: left;
}
.utility-dropdown--small .caret {
  width: 12px !important;
  height: 12px !important;
}

.hide-element {
  left: -10000px !important;
  top: -10000px !important;
}

.haslock:after {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  background-image: url(https://miamioh.edu/_hannonhill/_files/svgs/white/lock1_black.svg);
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 5px;
}
@media print, screen and (min-width: 64em) {
  .haslock:after {
    background-image: url(https://miamioh.edu/_hannonhill/_files/svgs/white/lock1.svg);
  }
}

.pre-footer__image {
  width: 100%;
  height: auto;
}

.footer {
  color: #fff;
}

.footer-primary {
  padding-top: 40px;
  background-color: #c41230;
  background-image: url("../images/illustrations/illustration-duotone-red.png");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  /*@include breakpoint(small) {
    background: {
      size: auto 100vh;
    }
  }*/
}
@media print, screen and (min-width: 40em) {
  .footer-primary {
    padding-bottom: 40px;
  }
}

.footer-primary__inner {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .footer-primary__inner {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .footer-primary__inner {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .footer-primary__inner {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .footer-primary__inner {
    width: 90%;
    max-width: 1404px;
  }
}

.footer-primary__content {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .footer-primary__content {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .footer-primary__content {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .footer-primary__content {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .footer-primary__content {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 40em) {
  .footer-primary__content {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
}

.footer-primary__column {
  flex-basis: auto;
}
.footer-primary__column.footer-primary__column--logo {
  flex-basis: 100%;
}
@media screen and (min-width: 75em) {
  .footer-primary__column.footer-primary__column--logo {
    flex-basis: auto;
  }
}
.footer-primary__column.footer-primary__column--resources {
  margin-top: 32px;
}
@media print, screen and (min-width: 40em) {
  .footer-primary__column.footer-primary__column--resources {
    margin-top: 0;
  }
}
.footer-primary__column.footer-primary__column--desktop {
  display: none;
}
@media print, screen and (min-width: 40em) {
  .footer-primary__column.footer-primary__column--desktop {
    display: initial;
  }
}
@media print, screen and (min-width: 40em) {
  .footer-primary__column.footer-primary__column--mobile {
    display: none;
  }
}

.footer-primary__logo-link {
  display: block;
  width: 100%;
  height: 52px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 24px;
}
@media print, screen and (min-width: 40em) {
  .footer-primary__logo-link {
    margin-right: 0;
    margin-left: 0;
  }
}
@media print, screen and (min-width: 64em) {
  .footer-primary__logo-link {
    width: 315px;
    height: 60px;
  }
}
.footer-primary__logo-link:focus {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}
.footer-primary__logo-link img {
  height: 50px;
  max-width: unset;
}

.footer-primary__heading {
  font-family: freight-display-pro, serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 300;
}
@media print, screen and (min-width: 64em) {
  .footer-primary__heading {
    font-size: 38px;
    line-height: 44px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

.footer-primary__menu {
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.115625rem;
  text-transform: uppercase;
  padding-bottom: 20px;
  padding-left: 26px;
}
@media print, screen and (min-width: 40em) {
  .footer-primary__menu {
    margin-top: 16px;
    padding-left: 0;
  }
}

.footer-primary__menu-item {
  margin-top: 10px;
}

.footer-primary__menu-link {
  display: inline-block;
  padding: 2px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  transition-property: border-color;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.footer-primary__menu-link:hover {
  border-color: #fff;
}
.footer-primary__menu-link:focus {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}

.footer-primary__social-heading {
  font-family: freight-display-pro, serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 300;
  margin-top: 40px;
  text-align: center;
}
@media print, screen and (min-width: 64em) {
  .footer-primary__social-heading {
    font-size: 38px;
    line-height: 44px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
@media print, screen and (min-width: 40em) {
  .footer-primary__social-heading {
    text-align: left;
    margin-top: 0;
  }
}

.footer-primary__social-menu {
  display: flex;
  justify-content: center;
}
@media print, screen and (min-width: 40em) {
  .footer-primary__social-menu {
    justify-content: flex-start;
  }
}

.footer-primary__social-menu-item {
  margin-top: 4px;
}
.footer-primary__social-menu-item:not(:last-child) {
  margin-right: 14px;
}
@media print, screen and (min-width: 40em) {
  .footer-primary__social-menu-item {
    margin-top: 8px;
  }
}

.footer-primary__cta-menu {
  margin-top: 40px;
}
.footer-primary__cta-menu .base-cta-primary {
  display: block;
}
@media print, screen and (min-width: 40em) {
  .footer-primary__cta-menu {
    margin-top: 24px;
  }
  .footer-primary__cta-menu .base-cta-primary {
    display: inline-block;
  }
}

.footer .footer-secondary {
  padding: 16px 0 14px;
  background-color: #ad102a;
}

.footer-secondary__inner {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .footer-secondary__inner {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .footer-secondary__inner {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .footer-secondary__inner {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .footer-secondary__inner {
    width: 90%;
    max-width: 1404px;
  }
}

.footer-secondary__legal-list {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  text-align: center;
}
@media print, screen and (min-width: 40em) {
  .footer-secondary__legal-list {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .footer-secondary__legal-list {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .footer-secondary__legal-list {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .footer-secondary__legal-list {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 40em) {
  .footer-secondary__legal-list {
    text-align: center;
  }
}
@media print, screen and (min-width: 64em) {
  .footer-secondary__legal-list {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
    margin-left: 8.3333333333%;
  }
}
@media screen and (min-width: 90em) {
  .footer-secondary__legal-list {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    margin-left: 16.6666666667%;
  }
}

.footer-secondary__legal-item {
  display: block;
  padding: 4px 0;
}
@media print, screen and (min-width: 40em) {
  .footer-secondary__legal-item {
    display: inline-block;
    padding: 4px 20px;
  }
}

.footer-secondary__legal-link {
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  display: inline-block;
  font-weight: 500;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  transition-property: border-color;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
@media print, screen and (min-width: 40em) {
  .footer-secondary__legal-link {
    display: inline-block;
  }
}
.footer-secondary__legal-link:hover {
  border-color: #fff;
}
.footer-secondary__legal-link:focus {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}

.footer__copyright {
  background-color: #231f20;
  padding-top: 10px;
  padding-bottom: 10px;
}

.footer__copyright-inner {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .footer__copyright-inner {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .footer__copyright-inner {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .footer__copyright-inner {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .footer__copyright-inner {
    width: 90%;
    max-width: 1404px;
  }
}

.footer__copyright-content {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  text-align: center;
}
@media print, screen and (min-width: 40em) {
  .footer__copyright-content {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .footer__copyright-content {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .footer__copyright-content {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .footer__copyright-content {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.footer-accordion {
  margin-right: -1.09375rem;
  margin-left: -1.09375rem;
  margin-right: -1.59375rem;
  margin-left: -1.59375rem;
  border-bottom: 1px solid #edece2;
  background-color: #ad102a;
}
@media print, screen and (min-width: 40em) {
  .footer-accordion {
    margin-right: 0;
    margin-left: 0;
  }
}
@media print, screen and (min-width: 40em) {
  .footer-accordion {
    border: 0;
    background-color: rgba(0, 0, 0, 0);
  }
}

.footer-accordion__button {
  position: relative;
  display: block;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 26px;
  width: 100%;
  text-align: left;
}
.footer-accordion__button::after {
  position: absolute;
  top: 50%;
  right: 32px;
  display: block;
  width: 10px;
  height: 10px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: translateY(-60%) rotate(45deg);
  content: "";
}
.footer-accordion__button[disabled=disabled] {
  cursor: default;
}
.footer-accordion__button[aria-expanded=true]::after {
  transform: translateY(-20%) rotate(-135deg);
}
@media print, screen and (min-width: 40em) {
  .footer-accordion__button {
    padding: 0;
  }
  .footer-accordion__button::after {
    display: none;
  }
}

.footer-campus {
  margin-top: 16px;
  margin-bottom: 32px;
}
@media screen and (min-width: 75em) {
  .footer-campus {
    margin-bottom: 0;
  }
}
.footer-campus .toggle-campus__select-wrap {
  position: relative;
  fill: #fff;
  transition-property: background-color, fill;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.footer-campus .toggle-campus__select-wrap::after {
  position: absolute;
  top: 50%;
  right: 20px;
  display: block;
  width: 10px;
  height: 10px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: translateY(-70%) rotate(45deg);
  content: "";
}
.footer-campus .toggle-campus__select-wrap:hover {
  background-color: #fff;
}
.footer-campus .toggle-campus__select-wrap:hover::after {
  border-right: 2px solid #c41230;
  border-bottom: 2px solid #c41230;
}
.footer-campus .toggle-campus__select-wrap:hover .toggle-campus__select {
  color: #c41230;
}
.footer-campus .toggle-campus__select {
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.115625rem;
  text-transform: uppercase;
  transition-property: background-color, fill;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  display: block;
  width: 100%;
  padding: 16px 48px 16px 20px;
  color: #fff;
  font-size: 16px;
  border: 2px solid #fff;
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0);
  appearance: none;
}
.footer-campus .toggle-campus__select:focus {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}
@media print, screen and (min-width: 64em) {
  .footer-campus .toggle-campus__select {
    font-size: 12px;
  }
}
.footer-campus .toggle-campus__select option {
  color: #231f20;
  background: #fff;
}
.footer-campus select::-ms-expand {
  display: none;
}

.footer-campus__panel {
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  font-size: 14px;
  margin-top: 24px;
  line-height: 19px;
}

.footer-campus__list.footer-campus__list--secondary {
  margin-top: 16px;
}

.footer-campus__link {
  text-decoration: underline;
}
.footer-campus__link:focus {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}

.contentPage .sidebar-layout {
  overflow: visible;
}
.contentPage .sidebar-layout__sidebar {
  overflow: hidden;
}

.sidebar {
  position: relative;
  height: 100%;
  padding-top: 60px;
}
.sidebar:after {
  content: "";
  position: absolute;
  top: 60px;
  right: 0;
  height: 100%;
  width: 1px;
  background-color: #edece2;
}
.sidebar a.button-skip-content {
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}
.sidebar a.button-skip-content:focus {
  width: auto;
  height: auto;
  position: relative;
}

.sidebar-list {
  border: 0;
  display: none;
}
@media print, screen and (min-width: 64em) {
  .sidebar-list {
    display: block;
  }
}

.sidebar-item {
  list-style: none;
  padding: 10px 0;
}

.sidebar-link {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.373333px;
  color: #231f20;
  padding: 2px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  transition-property: border-color;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.sidebar-link:hover {
  border-color: #231f20;
}

.sidebar-link--active {
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.444444px;
  color: #c41230;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  transition-property: border-color;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.sidebar-link--active:hover {
  border-color: #c41230;
}

select::-ms-expand {
  display: none;
}

.sidebar__disclosure-wrap {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  position: relative;
  display: block;
}
@media print, screen and (min-width: 40em) {
  .sidebar__disclosure-wrap {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .sidebar__disclosure-wrap {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .sidebar__disclosure-wrap {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .sidebar__disclosure-wrap {
    width: 90%;
    max-width: 1404px;
  }
}
@media print, screen and (min-width: 64em) {
  .sidebar__disclosure-wrap {
    display: none;
  }
}

.sidebar__disclosure {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .sidebar__disclosure {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .sidebar__disclosure {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .sidebar__disclosure {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .sidebar__disclosure {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.sidebar__disclosure-button {
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.115625rem;
  text-transform: uppercase;
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
  color: #c41230;
  padding: 16px 48px 16px 20px;
  border: 2px solid #ccc9b8;
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0);
  appearance: none;
}
.sidebar__disclosure-button::after {
  position: absolute;
  top: 50%;
  right: 20px;
  display: block;
  width: 10px;
  height: 10px;
  border-right: 2px solid #c41230;
  border-bottom: 2px solid #c41230;
  transform: translateY(-70%) rotate(45deg);
  content: "";
}
.sidebar__disclosure-button.expanded::after {
  border-right: 0;
  border-bottom: 0;
  border-top: 2px solid #c41230;
  border-left: 2px solid #c41230;
  transform: translateY(-30%) rotate(45deg);
}

.sidebar__disclosure-content {
  padding: 20px;
  background-color: #faf9f7;
}

.sidebar__content-link {
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.115625rem;
  text-transform: uppercase;
  color: #231f20;
  display: block;
  text-transform: none;
  margin-top: 25px;
  font-size: 14px;
}
.sidebar__content-link:first-of-type {
  margin-top: 0;
}

.sidebar__content-link--active {
  color: #c41230;
}

.cards-news-stat {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #fff;
}
@media print, screen and (min-width: 40em) {
  .cards-news-stat {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-news-stat {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.cards-news-stat__cards-container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .cards-news-stat__cards-container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-news-stat__cards-container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-news-stat__cards-container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .cards-news-stat__cards-container {
    width: 90%;
    max-width: 1404px;
  }
}

.cards-news-stat__heading-container {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  align-self: center;
  justify-self: center;
  padding-bottom: 40px;
}
@media print, screen and (min-width: 40em) {
  .cards-news-stat__heading-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-news-stat__heading-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-news-stat__heading-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .cards-news-stat__heading-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-news-stat__heading-container {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    padding-bottom: 60px;
  }
}

.cards-news-stat__cta-container {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-top: 28px;
  display: none;
}
@media print, screen and (min-width: 40em) {
  .cards-news-stat__cta-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-news-stat__cta-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-news-stat__cta-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .cards-news-stat__cta-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-news-stat__cta-container {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    padding-top: 0px;
    display: flex;
    justify-content: flex-end;
    order: 0;
    padding-bottom: 60px;
    align-self: center;
  }
}

.cards-news-stat__cta-container-footer {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-top: 28px;
}
@media print, screen and (min-width: 40em) {
  .cards-news-stat__cta-container-footer {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-news-stat__cta-container-footer {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-news-stat__cta-container-footer {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .cards-news-stat__cta-container-footer {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-news-stat__cta-container-footer {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    padding-top: 0px;
    display: none;
    justify-content: flex-end;
    order: 0;
    padding-bottom: 60px;
    align-self: center;
  }
}

.cards-news-stat__card {
  position: relative;
  margin-bottom: 40px;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .cards-news-stat__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-news-stat__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-news-stat__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .cards-news-stat__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-news-stat__card {
    margin-bottom: 0;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-left: 0%;
  }
}
.cards-news-stat__card .cards-news-stat__card-inner {
  display: block;
}
.cards-news-stat__card .cards-news-stat__card-inner::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0;
  background-color: transparent;
  left: 0;
  bottom: 0;
  overflow: hidden;
  transition: height 0.2s ease-out;
}
.cards-news-stat__card .cards-news-stat__card-inner:hover {
  cursor: grab;
}
.cards-news-stat__card .cards-news-stat__card-inner:hover .cards-news-stat__headline {
  border-bottom-color: #231f20;
}
.cards-news-stat__card:last-of-type {
  margin-bottom: 0;
}

.cards-news-stat__card-inner {
  position: relative;
  display: block;
  padding-bottom: 25px;
  border-bottom: 1px solid #ccc9b8;
}

.cards-news-stat__card-inner:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}

.cards-news-stat__image-container {
  position: relative;
  padding-bottom: 15px;
}

.cards-news-stat__image {
  height: 220px;
  width: 100%;
  object-fit: cover;
}

.cards-news-stat__category {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.04375rem;
  color: #c41230;
  font-style: italic;
  padding-bottom: 4px;
}

.cards-news-stat__headline {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  display: inline;
  line-height: 1.5;
  color: #231f20;
  font-weight: 600;
  padding-bottom: 2px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  transition-property: border-bottom-color;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
@media print, screen and (min-width: 64em) {
  .cards-news-stat__headline {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

.cards-news-stat__divider {
  border-bottom: 1px solid #ccc9b8;
  margin-top: 28px;
  margin-bottom: 40px;
}
@media print, screen and (min-width: 64em) {
  .cards-news-stat__divider {
    margin-top: 20px;
  }
}

.cards-news-stat__stats-container {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-top: 0;
}
@media print, screen and (min-width: 40em) {
  .cards-news-stat__stats-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-news-stat__stats-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-news-stat__stats-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .cards-news-stat__stats-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-news-stat__stats-container {
    padding-top: 0px;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-left: 0%;
  }
}

.cards-news-stat__stats-container-inner {
  background-color: #edece2;
  padding: 20px 0;
}
@media print, screen and (min-width: 64em) {
  .cards-news-stat__stats-container-inner {
    padding: 0;
  }
}

.cards-news-stat__stat {
  border-left: 4px solid #ccc9b8;
  padding-left: 20px;
  margin-bottom: 52px;
}

.cards-news-stat__stat:last-child {
  margin-bottom: 0px;
}

.cards-news-stat__number {
  font-family: freight-display-pro, serif;
  font-size: 54px;
  font-weight: 400;
  line-height: 40px;
  font-variant: lining-nums;
  color: #c41230;
  padding-bottom: 17px;
}

.cards-news-stat__copy {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.04375rem;
  color: #231f20;
}

.accordion-primary {
  width: 100%;
  margin-top: 12px;
  margin-bottom: 20px;
}

.accordion-primary__container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .accordion-primary__container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .accordion-primary__container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .accordion-primary__container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .accordion-primary__container {
    width: 90%;
    max-width: 1404px;
  }
}

.accordion-primary__inner {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .accordion-primary__inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .accordion-primary__inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .accordion-primary__inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .accordion-primary__inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.accordion-primary__column {
  flex-basis: auto;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  border-top: 1px solid #edece2;
}
.accordion-primary__column:last-child {
  border-bottom: 1px solid #edece2;
}
@media print, screen and (min-width: 40em) {
  .accordion-primary__column.accordion-primary__column--resources {
    margin-top: 0;
    order: 2;
  }
}
.accordion-primary__column .accordion-primary__accordion .accordion-primary__heading {
  font-family: proxima-nova, sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 0 !important;
}
@media print, screen and (min-width: 64em) {
  .accordion-primary__column .accordion-primary__accordion .accordion-primary__heading {
    font-size: 26px;
    line-height: 32px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.accordion-primary__column .accordion-primary__accordion .accordion-primary__heading .accordion-primary__button {
  width: 100%;
  text-align: left;
  transition-property: color;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.accordion-primary__column .accordion-primary__accordion .accordion-primary__heading .accordion-primary__button::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 1px;
  border: 1px solid #c41230;
  background: #c41230;
  top: 50%;
  left: 0;
  transform: rotate(0deg);
  transition: all 0.3s;
  cursor: pointer;
}
.accordion-primary__column .accordion-primary__accordion .accordion-primary__heading .accordion-primary__button::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 1px;
  border: 1px solid #c41230;
  background: #c41230;
  top: 50%;
  left: 0;
  transform: rotate(90deg);
  transition: all 0.3s;
  cursor: pointer;
}
.accordion-primary__column .accordion-primary__accordion .accordion-primary__heading .accordion-primary__button:hover {
  color: #c41230;
}
.accordion-primary__column .accordion-primary__accordion .accordion-primary__heading .accordion-primary__button:hover::after {
  border: 1px solid #ad102a;
}
.accordion-primary__column .accordion-primary__accordion .accordion-primary__heading .accordion-primary__button:hover::before {
  border: 1px solid #ad102a;
}
.accordion-primary__column .accordion-primary__accordion .accordion-primary__heading .accordion-primary__button:focus {
  background-color: transparent;
  color: currentColor;
}
.accordion-primary__column .accordion-primary__accordion .accordion-primary__heading .accordion-primary__button[aria-expanded=true]::after {
  border: 1px solid #ad102a;
  transform: rotate(225deg);
  background: #ad102a;
}
.accordion-primary__column .accordion-primary__accordion .accordion-primary__heading .accordion-primary__button[aria-expanded=true]::before {
  border: 1px solid #ad102a;
  transform: rotate(315deg);
  background: #ad102a;
}
.accordion-primary__column .accordion-primary__accordion .accordion-primary__heading .accordion-primary__button-label {
  display: block;
  max-width: 100%;
  margin-left: 40px;
  font-size: 24px;
  font-weight: 500;
  line-height: 115%;
}

.accordion-primary--body {
  padding-top: 40px;
  padding-bottom: 40px;
}

.top_alert {
  display: none;
  width: 100%;
  background: #edece2;
  position: fixed;
  z-index: 102;
  top: 0;
}
.top_alert p {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
  color: #231f20;
  padding: 8px 12px;
  font-size: 16px;
}
.top_alert a {
  text-decoration: underline;
}
.top_alert a:hover {
  text-decoration: none;
}
.top_alert a:focus {
  outline: 2px dotted currentColor;
  outline-offset: 4px;
}

.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
}

.alerts-base {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
}

.alerts-base__container {
  background: #fff;
  max-width: 1170px;
  margin: 0 auto;
}

.alerts__header-container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .alerts__header-container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .alerts__header-container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .alerts__header-container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .alerts__header-container {
    width: 90%;
    max-width: 1404px;
  }
}

.alerts__header-inner {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .alerts__header-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .alerts__header-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .alerts__header-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .alerts__header-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.alerts__header-heading {
  font-family: freight-display-pro, serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 300;
  color: #c41230;
}
@media print, screen and (min-width: 64em) {
  .alerts__header-heading {
    font-size: 38px;
    line-height: 44px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

.alerts {
  border: 2px solid #c41230;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
@media print, screen and (min-width: 64em) {
  .alerts {
    flex-direction: row;
    padding: 10px 10px 10px 25px;
  }
}
@media print, screen and (min-width: 64em) {
  .alerts .alerts__text-container {
    flex: 0 0 calc(100% - 260px);
  }
}
.alerts .alerts__button-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
}
@media print, screen and (min-width: 64em) {
  .alerts .alerts__button-container {
    margin-top: 0;
    justify-content: flex-end;
  }
}
.alerts .alerts__button-container button {
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.09375rem;
  flex: 0 0 49%;
  min-width: auto;
  letter-spacing: 0.25px;
}
@media print, screen and (min-width: 64em) {
  .alerts .alerts__button-container button {
    width: 120px;
    max-width: 120px;
  }
  .alerts .alerts__button-container button:first-child {
    margin-right: 10px;
  }
  .alerts .alerts__button-container button:focus {
    outline: 2px dotted #c41230;
    outline-offset: 4px;
  }
}

.alerts__text-heading {
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.09375rem;
  text-transform: uppercase;
  color: #c41230;
  letter-spacing: 1.5px;
}

.alerts__text-copy {
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.09375rem;
  font-weight: 500;
  letter-spacing: 0.25px;
}

.achievements__container {
  background-color: #edece2;
  padding-top: 40px;
  padding-bottom: 40px;
}
@media print, screen and (min-width: 40em) {
  .achievements__container {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media print, screen and (min-width: 64em) {
  .achievements__container {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.achievements__container--inner {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .achievements__container--inner {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .achievements__container--inner {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .achievements__container--inner {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .achievements__container--inner {
    width: 90%;
    max-width: 1404px;
  }
}
@media print, screen and (min-width: 40em) {
  .achievements__container--inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .achievements__container--inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .achievements__container--inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .achievements__container--inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.achievements__outline {
  width: 100%;
}
@media print, screen and (min-width: 64em) {
  .achievements__outline {
    transform: translate(5px, 5px);
    background-color: #fff;
  }
}

.achievements__inner {
  z-index: 1;
  width: 100%;
  display: block;
}
.achievements__inner .achievements__content-inner {
  background-color: #fff;
  width: 100%;
}
.achievements__inner .achievements__content-mobile-outline {
  padding: 40px 25px 20px;
  color: #c41230;
  background-color: #fff;
  border: 1px solid #edece2;
  transform: translate(-5px, -5px);
}
@media print, screen and (min-width: 64em) {
  .achievements__inner .achievements__content-mobile-outline {
    border: none;
    padding: 0px;
    transform: translate(0);
  }
}
@media print, screen and (min-width: 64em) {
  .achievements__inner {
    color: #c41230;
    background-color: #fff;
    border: 1px solid #edece2;
    padding: 60px 100px 55px;
    transform: translate(-5px, -5px);
  }
}

.achievements__inner-inner {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  width: 100% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
@media print, screen and (min-width: 40em) {
  .achievements__inner-inner {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .achievements__inner-inner {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .achievements__inner-inner {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .achievements__inner-inner {
    width: 90%;
    max-width: 1404px;
  }
}

.achievements__content {
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media print, screen and (min-width: 64em) {
  .achievements__content {
    align-items: center;
  }
}
.achievements__content .achievements__cta {
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
}
@media print, screen and (min-width: 64em) {
  .achievements__content .achievements__cta {
    margin-top: 0;
  }
}
.achievements__content .achievements__cta .base-cta-primary {
  margin-top: 0;
}

.achievements__heading-container {
  padding-bottom: 15px;
  text-align: center;
}
@media print, screen and (min-width: 64em) {
  .achievements__heading-container {
    padding-bottom: 40px;
  }
}

.achievements__heading {
  font-family: freight-display-pro, serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 300;
  color: #c41230;
}
@media print, screen and (min-width: 64em) {
  .achievements__heading {
    font-size: 38px;
    line-height: 44px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

.achievements__callouts-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding-bottom: 10px;
}
.achievements__callouts-container a:focus {
  outline: 2px dotted #000;
  outline-offset: 4px;
}
@media print, screen and (min-width: 64em) {
  .achievements__callouts-container {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.achievements__callout {
  width: 100%;
  margin-bottom: 32px;
}
@media print, screen and (min-width: 64em) {
  .achievements__callout {
    width: 33.33333%;
    padding: 8px;
  }
}
.achievements__callout:last-of-type {
  margin-bottom: 5px;
}
@media print, screen and (min-width: 64em) {
  .achievements__callout:last-of-type {
    margin-bottom: 32px;
  }
}

.achievements__callout-link {
  display: flex;
  width: 100%;
}

.achievements__callout-icon {
  width: 20%;
  min-width: 35px;
  max-width: 55px;
  object-fit: contain;
  object-position: 50% 0;
}

.achievements__callout-text {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
  width: 100%;
  padding-left: 15px;
  color: #231f20;
}

.cards-text {
  padding-top: 32px;
  padding-bottom: 32px;
  background-color: #c41230;
  color: #fff;
}
.cards-text .cards-text__header-container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .cards-text .cards-text__header-container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text .cards-text__header-container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-text .cards-text__header-container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .cards-text .cards-text__header-container {
    width: 90%;
    max-width: 1404px;
  }
}
.cards-text .cards-text__header-container .cards-text__header-inner {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  align-self: center;
  justify-self: center;
  padding-bottom: 12px;
}
@media print, screen and (min-width: 40em) {
  .cards-text .cards-text__header-container .cards-text__header-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text .cards-text__header-container .cards-text__header-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-text .cards-text__header-container .cards-text__header-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .cards-text .cards-text__header-container .cards-text__header-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text .cards-text__header-container .cards-text__header-inner {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    padding-bottom: 12px;
  }
}
.cards-text .cards-text__header-container .cards-text__header-inner .cards-text__header-heading {
  font-family: freight-display-pro, serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 300;
  padding-bottom: 15px;
}
@media print, screen and (min-width: 64em) {
  .cards-text .cards-text__header-container .cards-text__header-inner .cards-text__header-heading {
    font-size: 38px;
    line-height: 44px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text .cards-text__header-container .cards-text__header-inner .cards-text__header-heading {
    padding-bottom: 20px;
  }
}
.cards-text .cards-text__header-container .cards-text__header-inner .cards-text__header-subheading {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0.015625rem;
  padding-left: 0 !important;
}
@media print, screen and (min-width: 64em) {
  .cards-text .cards-text__header-container .cards-text__header-inner .cards-text__header-subheading {
    font-size: 24px;
    line-height: 36px;
  }
}
.cards-text .cards-text__header-container .cards-text_header_cta-container {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  /*display: none;*/
  padding-top: 0;
  padding-bottom: 12px;
}
@media print, screen and (min-width: 40em) {
  .cards-text .cards-text__header-container .cards-text_header_cta-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text .cards-text__header-container .cards-text_header_cta-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-text .cards-text__header-container .cards-text_header_cta-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .cards-text .cards-text__header-container .cards-text_header_cta-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text .cards-text__header-container .cards-text_header_cta-container {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    display: flex;
    justify-content: flex-end;
    padding-top: 0px;
    padding-bottom: 12px;
    align-self: center;
  }
}
.cards-text .cards-text_footer_cta-container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-top: 28px;
}
@media print, screen and (min-width: 40em) {
  .cards-text .cards-text_footer_cta-container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text .cards-text_footer_cta-container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-text .cards-text_footer_cta-container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .cards-text .cards-text_footer_cta-container {
    width: 90%;
    max-width: 1404px;
  }
}
@media print, screen and (min-width: 40em) {
  .cards-text .cards-text_footer_cta-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text .cards-text_footer_cta-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-text .cards-text_footer_cta-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .cards-text .cards-text_footer_cta-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text .cards-text_footer_cta-container {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    display: none;
    justify-content: flex-end;
    padding-top: 0px;
    padding-bottom: 60px;
    align-self: center;
  }
}
.cards-text .cards-text__cards-container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  flex-direction: column;
}
@media print, screen and (min-width: 40em) {
  .cards-text .cards-text__cards-container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text .cards-text__cards-container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-text .cards-text__cards-container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .cards-text .cards-text__cards-container {
    width: 90%;
    max-width: 1404px;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text .cards-text__cards-container {
    flex-direction: row;
  }
}
.cards-text.cards-text--2 .cards-text__card {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-top: 24px;
  padding-bottom: 24px;
}
@media print, screen and (min-width: 40em) {
  .cards-text.cards-text--2 .cards-text__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text.cards-text--2 .cards-text__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-text.cards-text--2 .cards-text__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .cards-text.cards-text--2 .cards-text__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text.cards-text--2 .cards-text__card {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .cards-text.cards-text--2 .cards-text__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .cards-text.cards-text--2 .cards-text__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .cards-text.cards-text--2 .cards-text__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .cards-text.cards-text--2 .cards-text__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.cards-text.cards-text--3 .cards-text__card {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-top: 24px;
  padding-bottom: 24px;
}
@media print, screen and (min-width: 40em) {
  .cards-text.cards-text--3 .cards-text__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text.cards-text--3 .cards-text__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-text.cards-text--3 .cards-text__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .cards-text.cards-text--3 .cards-text__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text.cards-text--3 .cards-text__card {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .cards-text.cards-text--3 .cards-text__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .cards-text.cards-text--3 .cards-text__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .cards-text.cards-text--3 .cards-text__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .cards-text.cards-text--3 .cards-text__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.cards-text.cards-text--4 .cards-text__card {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-top: 24px;
  padding-bottom: 24px;
}
@media print, screen and (min-width: 40em) {
  .cards-text.cards-text--4 .cards-text__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text.cards-text--4 .cards-text__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-text.cards-text--4 .cards-text__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .cards-text.cards-text--4 .cards-text__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text.cards-text--4 .cards-text__card {
    flex: 0 0 25%;
    max-width: 25%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .cards-text.cards-text--4 .cards-text__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .cards-text.cards-text--4 .cards-text__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .cards-text.cards-text--4 .cards-text__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .cards-text.cards-text--4 .cards-text__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.cards-text .cards-text__card .cards-text__card-image {
  height: 26px;
  object-fit: cover;
  display: none;
}
@media print, screen and (min-width: 40em) {
  .cards-text .cards-text__card .cards-text__card-image {
    display: block;
  }
}
.cards-text .cards-text__card .cards-text__card-inner {
  padding-right: 20px;
}
.cards-text .cards-text__card .cards-text__card-inner .cards-text__card-headline {
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #fff;
  padding: 0;
  padding-bottom: 20px;
}
@media print, screen and (min-width: 64em) {
  .cards-text .cards-text__card .cards-text__card-inner .cards-text__card-headline {
    font-size: 20px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #c41230;
  }
}
.cards-text .cards-text__card .cards-text__card-inner .cards-text__card-headline .cards-text__base-link-text:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.cards-text .cards-text__card .cards-text__card-inner .cards-text__card-headline .cards-text__base-link-text {
  display: inline-block;
}
.cards-text .cards-text__card .cards-text__card-inner .cards-text__card-headline .cards-text__base-link-text::after {
  display: inline-block;
  width: 9px;
  height: 9px;
  align-self: flex-end;
  background-color: transparent;
  content: "";
  border-top: 2px solid #c41230;
  border-right: 2px solid #c41230;
  transform: translateY(-100%) rotate(45deg) translateX(10px);
  transition: all 0.2s ease;
}
.cards-text .cards-text__card .cards-text__card-inner .cards-text__card-headline .cards-text__base-link-text:hover:after {
  transform: translateY(-16px) rotate(45deg) translateX(20px);
}
.cards-text .cards-text__card .cards-text__card-inner .cards-text__card-copy {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
  color: #fff;
  padding-top: 14px;
  margin-bottom: 8px;
}
.cards-text .cards-text__card .cards-text__card-inner .cards-text__card-copy ul {
  display: list-item;
  margin: 0 0 2rem 2rem;
}
.cards-text .cards-text__card .cards-text__card-inner .cards-text__card-copy ul::marker {
  color: transparent;
}
.cards-text .cards-text__card .cards-text__card-inner .cards-text__card-copy ul li {
  display: list-item;
  padding: 0px;
  line-height: 160%;
  margin: 5px 0;
}
.cards-text .cards-text__card .cards-text__card-inner .cards-text__card-copy ul li:before {
  content: "";
  background-image: url("../svgs/functional/icon-target--red.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-left: -20px;
  margin-right: 10px;
}
.cards-text .cards-text__card .cards-text__card-inner .cards-text__card-copy ul li ul {
  list-style-type: disc;
  display: list-item;
  margin: 0 0 0 2rem;
}
.cards-text .cards-text__card .cards-text__card-inner .cards-text__card-copy ul li ul li::before {
  content: unset;
}
.cards-text .cards-text__card .cards-text__card-inner .cards-text__card-copy ul li ul li::marker {
  color: #C41230;
  font-size: 22px;
}
.cards-text .cards-text__card .cards-text__card-inner .cards-text__card-copy ul li ul li ul {
  list-style-type: circle;
}
.cards-text .cards-text__card .cards-text__card-inner .cards-text__card-copy ul li ul li ul li ul {
  list-style-type: square;
}
.cards-text .cards-text__card .cards-text__card-inner .cards-text__card-copy ol {
  list-style: decimal;
  margin: 0 0 2rem 2rem;
}
.cards-text .cards-text__card .cards-text__card-inner .cards-text__card-copy ol li {
  padding: 0 0 0 0rem;
  display: list-item;
  line-height: 160%;
  margin: 5px 0;
}
.cards-text .cards-text__card .cards-text__card-inner .cards-text__card-copy ol li:before {
  font-family: proxima-nova, sans-serif;
  color: #c41230;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.75px;
  margin-right: 16px;
  text-align: center;
  display: inline-block;
  width: 20px;
  vertical-align: middle;
  margin-left: -36px;
  content: unset;
}
.cards-text .cards-text__card .cards-text__card-inner .cards-text__card-copy ol li::marker {
  color: #C41230;
  font-size: 22px;
}
.cards-text .cards-text__card .cards-text__card-inner .cards-text__card-copy ol li ol {
  list-style: lower-alpha;
  margin: 0 0 0 2rem;
}
.cards-text .cards-text__card .cards-text__card-inner .cards-text__card-copy ol li ol li ol {
  list-style: lower-roman;
}
.cards-text .cards-text__card .cards-text__card-inner .cards-text__card-copy ol li ol li ol li ol {
  list-style: decimal;
}
.cards-text .cards-text__card .cards-text__card-inner .cards-text__card-copy a {
  color: #c41230;
  text-decoration: underline;
  font-style: normal;
  outline: 2px dotted transparent;
  outline-offset: 0;
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.cards-text .cards-text__card .cards-text__card-inner .cards-text__card-copy a:hover {
  text-decoration: none;
}
.cards-text .cards-text__card .cards-text__card-inner .cards-text__card-copy a:focus {
  text-decoration: none;
  outline: 2px dotted #c41230;
}
.cards-text .cards-text__card .cards-text__card-inner .cards-text__card-copy h2 {
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #c41230;
  padding-top: 8px;
  padding-bottom: 8px;
}
@media print, screen and (min-width: 64em) {
  .cards-text .cards-text__card .cards-text__card-inner .cards-text__card-copy h2 {
    font-size: 20px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #c41230;
  }
}
.cards-text .cards-text__card .cards-text__card-inner .cards-text__card-copy h3 {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: #231f20;
  padding-top: 8px;
  padding-bottom: 8px;
}
@media print, screen and (min-width: 64em) {
  .cards-text .cards-text__card .cards-text__card-inner .cards-text__card-copy h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.cards-text .cards-text__card .cards-text__card-inner .cards-text__card-copy h4 {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: #c41230;
  padding-top: 8px;
  padding-bottom: 8px;
}
@media print, screen and (min-width: 64em) {
  .cards-text .cards-text__card .cards-text__card-inner .cards-text__card-copy h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.cards-text .cards-text__card .cards-text__card-inner .cards-text__card-copy h5 {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: #231f20;
  padding-top: 8px;
  padding-bottom: 8px;
}
@media print, screen and (min-width: 64em) {
  .cards-text .cards-text__card .cards-text__card-inner .cards-text__card-copy h5 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.cards-text .cards-text__card .cards-text__card-inner .cards-text__card-copy h6 {
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.09375rem;
  color: #c41230;
  padding-top: 8px;
  padding-bottom: 8px;
}
.cards-text .cards-text__card .cards-text__card-inner .cards-text__card-copy p {
  margin-bottom: 20px;
}
@media print, screen and (min-width: 64em) {
  .cards-text .cards-text__card .cards-text__card-inner .cards-text__card-cta {
    padding-bottom: 0;
  }
}
.cards-text .cards-text__card .cards-text__card-inner .cards-text__card-cta .base-cta-text {
  margin-top: 0;
}
.cards-text .cards-text__card .cards-text__card-inner .cards-text__card-cta a:before {
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
  width: 9px;
  height: 9px;
  background-color: transparent;
  content: "";
  border-top: 2px solid #c41230;
  border-right: 2px solid #c41230;
  transform: translateY(-50%) rotate(45deg);
  transition: all 0.2s ease;
}
.cards-text .cards-text__card .cards-text__card-inner .cards-text__card-cta a .base-cta-text__arrow {
  display: none;
}
.cards-text .cards-text__card .cards-text__card-inner .cards-text__card-cta a:hover::before {
  transform: translate(5px, -50%) rotate(45deg) scale(1);
}
.cards-text.warm {
  background-color: #faf9f7 !important;
}
.cards-text.cards-text--warm {
  background-color: #faf9f7 !important;
  color: #231f20;
}
.cards-text.cards-text--warm .cards-text__header-heading {
  color: #c41230;
}
.cards-text.cards-text--warm .cards-text__card .cards-text__card-inner .cards-text__card-headline {
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #231f20;
  padding: 0;
}
@media print, screen and (min-width: 64em) {
  .cards-text.cards-text--warm .cards-text__card .cards-text__card-inner .cards-text__card-headline {
    font-size: 20px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #c41230;
  }
}
.cards-text.cards-text--warm .cards-text__card .cards-text__card-inner .cards-text__card-copy {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
  color: #231f20;
  padding-top: 14px;
}
.cards-text.cards-text--warm .cards-text__card .cards-text__card-inner .cards-text__card-copy a {
  color: #c41230;
  text-decoration: underline;
  font-style: normal;
  outline: 2px dotted transparent;
  outline-offset: 0;
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.cards-text.cards-text--warm .cards-text__card .cards-text__card-inner .cards-text__card-copy a:hover {
  text-decoration: none;
}
.cards-text.cards-text--warm .cards-text__card .cards-text__card-inner .cards-text__card-copy a:focus {
  text-decoration: none;
  outline: 2px dotted #c41230;
}
.cards-text.cards-text--warm .cards-text__card .cards-text__card-inner .cards-text__card-copy a h2 {
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #231f20;
  padding-top: 8px;
  padding-bottom: 8px;
}
@media print, screen and (min-width: 64em) {
  .cards-text.cards-text--warm .cards-text__card .cards-text__card-inner .cards-text__card-copy a h2 {
    font-size: 20px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #c41230;
  }
}
.cards-text.cards-text--warm .cards-text__card .cards-text__card-inner .cards-text__card-copy a h3 {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: #c41230;
  padding-top: 8px;
  padding-bottom: 8px;
}
@media print, screen and (min-width: 64em) {
  .cards-text.cards-text--warm .cards-text__card .cards-text__card-inner .cards-text__card-copy a h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.cards-text.cards-text--warm .cards-text__card .cards-text__card-inner .cards-text__card-copy a h4 {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: #231f20;
  padding-top: 8px;
  padding-bottom: 8px;
}
@media print, screen and (min-width: 64em) {
  .cards-text.cards-text--warm .cards-text__card .cards-text__card-inner .cards-text__card-copy a h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.cards-text.cards-text--warm .cards-text__card .cards-text__card-inner .cards-text__card-copy a h5 {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: #c41230;
  padding-top: 8px;
  padding-bottom: 8px;
}
@media print, screen and (min-width: 64em) {
  .cards-text.cards-text--warm .cards-text__card .cards-text__card-inner .cards-text__card-copy a h5 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.cards-text.cards-text--warm .cards-text__card .cards-text__card-inner .cards-text__card-copy a h6 {
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.09375rem;
  color: #231f20;
  padding-top: 8px;
  padding-bottom: 8px;
}
.cards-text.cards-text--white {
  background-color: #fff;
  color: #231f20;
}
.cards-text.cards-text--white .cards-text__header-heading {
  color: #c41230;
}
.cards-text.cards-text--white .cards-text__card .cards-text__card-inner .cards-text__card-headline {
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #231f20;
  padding: 0;
}
@media print, screen and (min-width: 64em) {
  .cards-text.cards-text--white .cards-text__card .cards-text__card-inner .cards-text__card-headline {
    font-size: 20px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #c41230;
  }
}
.cards-text.cards-text--white .cards-text__card .cards-text__card-inner .cards-text__card-copy {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
  color: #231f20;
  padding-top: 12px;
}
.cards-text.cards-text--white .cards-text__card .cards-text__card-inner .cards-text__card-copy a {
  color: #c41230;
  text-decoration: underline;
  font-style: normal;
  outline: 2px dotted transparent;
  outline-offset: 0;
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.cards-text.cards-text--white .cards-text__card .cards-text__card-inner .cards-text__card-copy a:hover {
  text-decoration: none;
}
.cards-text.cards-text--white .cards-text__card .cards-text__card-inner .cards-text__card-copy a:focus {
  text-decoration: none;
  outline: 2px dotted #c41230;
}
.cards-text.cards-text--white .cards-text__card .cards-text__card-inner .cards-text__card-copy h2 {
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #231f20;
  padding-top: 8px;
  padding-bottom: 8px;
}
@media print, screen and (min-width: 64em) {
  .cards-text.cards-text--white .cards-text__card .cards-text__card-inner .cards-text__card-copy h2 {
    font-size: 20px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #c41230;
  }
}
.cards-text.cards-text--white .cards-text__card .cards-text__card-inner .cards-text__card-copy h3 {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: #c41230;
  padding-top: 8px;
  padding-bottom: 8px;
}
@media print, screen and (min-width: 64em) {
  .cards-text.cards-text--white .cards-text__card .cards-text__card-inner .cards-text__card-copy h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.cards-text.cards-text--white .cards-text__card .cards-text__card-inner .cards-text__card-copy h4 {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: #231f20;
  padding-top: 8px;
  padding-bottom: 8px;
}
@media print, screen and (min-width: 64em) {
  .cards-text.cards-text--white .cards-text__card .cards-text__card-inner .cards-text__card-copy h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.cards-text.cards-text--white .cards-text__card .cards-text__card-inner .cards-text__card-copy h5 {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: #c41230;
  padding-top: 8px;
  padding-bottom: 8px;
}
@media print, screen and (min-width: 64em) {
  .cards-text.cards-text--white .cards-text__card .cards-text__card-inner .cards-text__card-copy h5 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.cards-text.cards-text--white .cards-text__card .cards-text__card-inner .cards-text__card-copy h6 {
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.09375rem;
  color: #231f20;
  padding-top: 8px;
  padding-bottom: 8px;
}
.cards-text.cards-text--red .cards-text__card .cards-text__card-inner .cards-text__card-headline {
  color: #fff !important;
  padding: 0;
}
.cards-text.cards-text--red .cards-text__card .cards-text__card-inner .cards-text__card-copy a {
  color: unset;
}
.cards-text.cards-text--red .cards-text__card .cards-text__card-inner .cards-text__card-copy ul li:before {
  background-image: url("../svgs/functional/icon-target-white.svg");
}
.cards-text.cards-text--red .cards-text__card .cards-text__card-inner .cards-text__card-copy ul li ul li::marker {
  color: unset;
}
.cards-text.cards-text--red .cards-text__card .cards-text__card-inner .cards-text__card-copy ol li:before {
  color: unset;
}
.cards-text.cards-text--red .cards-text__card .cards-text__card-inner .cards-text__card-copy ol li::marker {
  color: unset;
}
.cards-text.cards-text--red .cards-text__card .cards-text__card-inner .cards-text__card-cta a:before {
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
}
.cards-text.cards-text--red .cards-text__card .cards-text__card-inner .cards-text__base-link-text:focus {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}
.cards-text.cards-text--red .cards-text__card .cards-text__card-inner .cards-text__base-link-text:after {
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
}
.cards-text .cards-text__card:last-child .cards-text__card-cta {
  padding-bottom: 0;
}
.cards-text.cards-text-multirow--2 .cards-text__card {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-top: 24px;
  padding-bottom: 24px;
}
@media print, screen and (min-width: 40em) {
  .cards-text.cards-text-multirow--2 .cards-text__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text.cards-text-multirow--2 .cards-text__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-text.cards-text-multirow--2 .cards-text__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .cards-text.cards-text-multirow--2 .cards-text__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text.cards-text-multirow--2 .cards-text__card {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .cards-text.cards-text-multirow--2 .cards-text__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .cards-text.cards-text-multirow--2 .cards-text__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .cards-text.cards-text-multirow--2 .cards-text__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .cards-text.cards-text-multirow--2 .cards-text__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.cards-text.cards-text-multirow--3 .cards-text__card {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-top: 24px;
  padding-bottom: 24px;
}
@media print, screen and (min-width: 40em) {
  .cards-text.cards-text-multirow--3 .cards-text__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text.cards-text-multirow--3 .cards-text__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-text.cards-text-multirow--3 .cards-text__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .cards-text.cards-text-multirow--3 .cards-text__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text.cards-text-multirow--3 .cards-text__card {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .cards-text.cards-text-multirow--3 .cards-text__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .cards-text.cards-text-multirow--3 .cards-text__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .cards-text.cards-text-multirow--3 .cards-text__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .cards-text.cards-text-multirow--3 .cards-text__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.cards-text.cards-text-multirow--4 .cards-text__card {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-top: 24px;
  padding-bottom: 24px;
}
@media print, screen and (min-width: 40em) {
  .cards-text.cards-text-multirow--4 .cards-text__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text.cards-text-multirow--4 .cards-text__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-text.cards-text-multirow--4 .cards-text__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .cards-text.cards-text-multirow--4 .cards-text__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text.cards-text-multirow--4 .cards-text__card {
    flex: 0 0 25%;
    max-width: 25%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .cards-text.cards-text-multirow--4 .cards-text__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .cards-text.cards-text-multirow--4 .cards-text__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .cards-text.cards-text-multirow--4 .cards-text__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .cards-text.cards-text-multirow--4 .cards-text__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.cards-text-alt {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #faf9f7;
}
@media print, screen and (min-width: 40em) {
  .cards-text-alt {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text-alt {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.cards-text-alt .cards-text-alt__header-container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .cards-text-alt .cards-text-alt__header-container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text-alt .cards-text-alt__header-container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-text-alt .cards-text-alt__header-container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .cards-text-alt .cards-text-alt__header-container {
    width: 90%;
    max-width: 1404px;
  }
}
.cards-text-alt .cards-text-alt__header-container .cards-text-alt__header-inner {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  align-self: center;
  justify-self: center;
  padding-bottom: 12px;
}
@media print, screen and (min-width: 40em) {
  .cards-text-alt .cards-text-alt__header-container .cards-text-alt__header-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text-alt .cards-text-alt__header-container .cards-text-alt__header-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-text-alt .cards-text-alt__header-container .cards-text-alt__header-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .cards-text-alt .cards-text-alt__header-container .cards-text-alt__header-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text-alt .cards-text-alt__header-container .cards-text-alt__header-inner {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    padding-bottom: 12px;
  }
}
.cards-text-alt .cards-text-alt__header-container .cards-text-alt__header-inner .cards-text-alt__header-heading {
  font-family: freight-display-pro, serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 300;
  padding-bottom: 15px;
  color: #c41230;
}
@media print, screen and (min-width: 64em) {
  .cards-text-alt .cards-text-alt__header-container .cards-text-alt__header-inner .cards-text-alt__header-heading {
    font-size: 38px;
    line-height: 44px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text-alt .cards-text-alt__header-container .cards-text-alt__header-inner .cards-text-alt__header-heading {
    padding-bottom: 20px;
  }
}
.cards-text-alt .cards-text-alt__header-container .cards-text-alt__header-inner .cards-text-alt__header-subheading {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0.015625rem;
  padding-left: 0 !important;
}
@media print, screen and (min-width: 64em) {
  .cards-text-alt .cards-text-alt__header-container .cards-text-alt__header-inner .cards-text-alt__header-subheading {
    font-size: 24px;
    line-height: 36px;
  }
}
.cards-text-alt .cards-text-alt__header-container .cards-text-alt_header_cta-container {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-bottom: 12px;
}
@media print, screen and (min-width: 40em) {
  .cards-text-alt .cards-text-alt__header-container .cards-text-alt_header_cta-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text-alt .cards-text-alt__header-container .cards-text-alt_header_cta-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-text-alt .cards-text-alt__header-container .cards-text-alt_header_cta-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .cards-text-alt .cards-text-alt__header-container .cards-text-alt_header_cta-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text-alt .cards-text-alt__header-container .cards-text-alt_header_cta-container {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    display: flex;
    justify-content: flex-end;
    padding-top: 0px;
    padding-bottom: 12px;
    align-self: center;
  }
}
.cards-text-alt .cards-text-alt_footer_cta-container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-top: 28px;
}
@media print, screen and (min-width: 40em) {
  .cards-text-alt .cards-text-alt_footer_cta-container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text-alt .cards-text-alt_footer_cta-container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-text-alt .cards-text-alt_footer_cta-container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .cards-text-alt .cards-text-alt_footer_cta-container {
    width: 90%;
    max-width: 1404px;
  }
}
@media print, screen and (min-width: 40em) {
  .cards-text-alt .cards-text-alt_footer_cta-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text-alt .cards-text-alt_footer_cta-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-text-alt .cards-text-alt_footer_cta-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .cards-text-alt .cards-text-alt_footer_cta-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text-alt .cards-text-alt_footer_cta-container {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    display: none;
    justify-content: flex-end;
    padding-top: 0px;
    padding-bottom: 60px;
    align-self: center;
  }
}
.cards-text-alt .cards-text-alt__cards-container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  flex-direction: column;
}
@media print, screen and (min-width: 40em) {
  .cards-text-alt .cards-text-alt__cards-container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text-alt .cards-text-alt__cards-container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-text-alt .cards-text-alt__cards-container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .cards-text-alt .cards-text-alt__cards-container {
    width: 90%;
    max-width: 1404px;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text-alt .cards-text-alt__cards-container {
    flex-direction: row;
  }
}
.cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card {
  padding-bottom: 24px;
  padding-top: 24px;
  padding-left: 1.06rem;
}
.cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone {
  background-color: #c41230;
  height: 100%;
}
.cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner {
  height: 100%;
  position: relative;
  z-index: 1;
  display: block;
  padding: 20px 32px;
  color: #fff;
  background-color: #faf9f7;
  border: 1px solid #c41230;
  transform: translate(-10px, -10px);
}
.cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner .cards-text-alt__card-headline {
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #231f20;
  padding-bottom: 20px;
}
@media print, screen and (min-width: 64em) {
  .cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner .cards-text-alt__card-headline {
    font-size: 20px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #c41230;
  }
}
.cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner .cards-text-alt__card-headline .cards-text__base-link-text {
  display: inline-block;
}
.cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner .cards-text-alt__card-headline .cards-text__base-link-text::after {
  display: inline-block;
  align-self: flex-end;
  width: 9px;
  height: 9px;
  background-color: transparent;
  content: "";
  border-top: 2px solid #c41230;
  border-right: 2px solid #c41230;
  transform: translateY(-100%) rotate(45deg) translateX(10px);
  transition: all 0.2s ease;
}
.cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner .cards-text-alt__card-headline .cards-text__base-link-text:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner .cards-text-alt__card-copy {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
  color: #231f20;
}
.cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner .cards-text-alt__card-copy a {
  color: #c41230;
  text-decoration: underline;
  font-style: normal;
  outline: 2px dotted transparent;
  outline-offset: 0;
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner .cards-text-alt__card-copy a:hover {
  text-decoration: none;
}
.cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner .cards-text-alt__card-copy a:focus {
  text-decoration: none;
  outline: 2px dotted #c41230;
}
.cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner .cards-text-alt__card-copy h6 {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: 45px;
}
@media print, screen and (min-width: 64em) {
  .cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner .cards-text-alt__card-copy h6 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner .cards-text-alt__card-copy h5 {
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 45px;
}
@media print, screen and (min-width: 64em) {
  .cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner .cards-text-alt__card-copy h5 {
    font-size: 20px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #c41230;
  }
}
.cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner .cards-text-alt__card-copy h4 {
  font-family: proxima-nova, sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 45px;
}
@media print, screen and (min-width: 64em) {
  .cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner .cards-text-alt__card-copy h4 {
    font-size: 26px;
    line-height: 32px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner .cards-text-alt__card-copy h3 {
  font-family: freight-display-pro, serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 300;
  margin-bottom: 15px;
  margin-top: 45px;
}
@media print, screen and (min-width: 64em) {
  .cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner .cards-text-alt__card-copy h3 {
    font-size: 38px;
    line-height: 44px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
.cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner .cards-text-alt__card-copy h3.table-header {
  font-family: proxima-nova, sans-serif;
  font-size: 20px;
  line-height: 1.3;
  font-weight: 500;
  margin-bottom: 20px;
}
@media print, screen and (min-width: 64em) {
  .cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner .cards-text-alt__card-copy h3.table-header {
    font-size: 24px;
    line-height: 1.25;
  }
}
.cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner .cards-text-alt__card-copy p {
  margin-bottom: 20px;
}
.cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner .cards-text-alt__card-copy ul {
  display: list-item;
  margin: 0 0 2rem 2rem;
}
.cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner .cards-text-alt__card-copy ul::marker {
  color: transparent;
}
.cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner .cards-text-alt__card-copy ul li {
  display: list-item;
  padding: 0px;
  line-height: 160%;
  margin: 5px 0;
}
.cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner .cards-text-alt__card-copy ul li:before {
  content: "";
  background-image: url("../svgs/functional/icon-target--red.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-left: -20px;
  margin-right: 10px;
}
.cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner .cards-text-alt__card-copy ul li ul {
  list-style-type: disc;
  display: list-item;
  margin: 0 0 0 2rem;
}
.cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner .cards-text-alt__card-copy ul li ul li::before {
  content: unset;
}
.cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner .cards-text-alt__card-copy ul li ul li::marker {
  color: #C41230;
  font-size: 22px;
}
.cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner .cards-text-alt__card-copy ul li ul li ul {
  list-style-type: circle;
}
.cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner .cards-text-alt__card-copy ul li ul li ul li ul {
  list-style-type: square;
}
.cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner .cards-text-alt__card-copy ol {
  list-style: decimal;
  margin: 0 0 2rem 2rem;
}
.cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner .cards-text-alt__card-copy ol li {
  padding: 0 0 0 0rem;
  display: list-item;
  line-height: 160%;
  margin: 5px 0;
}
.cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner .cards-text-alt__card-copy ol li:before {
  font-family: proxima-nova, sans-serif;
  color: #c41230;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.75px;
  margin-right: 16px;
  text-align: center;
  display: inline-block;
  width: 20px;
  vertical-align: middle;
  margin-left: -36px;
  content: unset;
}
.cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner .cards-text-alt__card-copy ol li::marker {
  color: #C41230;
  font-size: 22px;
}
.cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner .cards-text-alt__card-copy ol li ol {
  list-style: lower-alpha;
  margin: 0 0 0 2rem;
}
.cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner .cards-text-alt__card-copy ol li ol li ol {
  list-style: lower-roman;
}
.cards-text-alt .cards-text-alt__cards-container .cards-text-alt__card .cards-text-alt__card-outlone .cards-text-alt__card-inner .cards-text-alt__card-copy ol li ol li ol li ol {
  list-style: decimal;
}
@media print, screen and (min-width: 64em) {
  .cards-text-alt.cards-text-alt--2 .cards-text-alt__card {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 1.87rem !important;
    padding-left: 1.87rem !important;
    margin-left: 0;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .cards-text-alt.cards-text-alt--2 .cards-text-alt__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .cards-text-alt.cards-text-alt--2 .cards-text-alt__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .cards-text-alt.cards-text-alt--2 .cards-text-alt__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .cards-text-alt.cards-text-alt--2 .cards-text-alt__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text-alt.cards-text-alt--3 .cards-text-alt__card {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .cards-text-alt.cards-text-alt--3 .cards-text-alt__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .cards-text-alt.cards-text-alt--3 .cards-text-alt__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .cards-text-alt.cards-text-alt--3 .cards-text-alt__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .cards-text-alt.cards-text-alt--3 .cards-text-alt__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-text-alt.cards-text-alt--4 .cards-text-alt__card {
    flex: 0 0 25%;
    max-width: 25%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .cards-text-alt.cards-text-alt--4 .cards-text-alt__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .cards-text-alt.cards-text-alt--4 .cards-text-alt__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .cards-text-alt.cards-text-alt--4 .cards-text-alt__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .cards-text-alt.cards-text-alt--4 .cards-text-alt__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.carousel-cards {
  padding-top: 60px;
  padding-bottom: 60px;
}
.carousel-cards .carousel-cards__wrap--heading .carousel-cards__header-inner {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
  padding-bottom: 12px;
}
.carousel-cards .carousel-cards__wrap--heading .carousel-cards__header-inner .carousel-cards__heading {
  font-size: 38px;
  line-height: 44px;
  font-weight: 300;
  margin-bottom: 10px;
  margin-top: 30px;
}
.carousel-cards .carousel-cards__wrap--heading .carousel-cards__header-inner .carousel-cards__header-subheading {
  font-family: freight-text-pro, serif;
  font-size: 24px;
  line-height: 36px;
  font-weight: 300;
  letter-spacing: 0.015625rem;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}
.carousel-cards .carousel-cards__wrap--heading .carousel-cards__header_cta-container {
  align-self: center;
  display: flex;
  flex: 0 0 33.3333333333%;
  justify-content: flex-end;
  max-width: 33.3333333333%;
  padding-bottom: 12px;
  padding-top: 0;
}
.carousel-cards .button-skip-content__wrap {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .carousel-cards .button-skip-content__wrap {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .carousel-cards .button-skip-content__wrap {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .carousel-cards .button-skip-content__wrap {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .carousel-cards .button-skip-content__wrap {
    width: 90%;
    max-width: 1404px;
  }
}
.carousel-cards .button-skip-content {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}
@media print, screen and (min-width: 40em) {
  .carousel-cards .button-skip-content {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .carousel-cards .button-skip-content {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .carousel-cards .button-skip-content {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .carousel-cards .button-skip-content {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.carousel-cards .button-skip-content:focus, .carousel-cards .button-skip-content:active {
  visibility: visible;
  position: static;
  overflow: visible;
  clip: auto;
  width: auto;
  height: auto;
  display: block;
}

.carousel-cards__inner {
  overflow: hidden;
  width: 100%;
}

.carousel-cards__wrap {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .carousel-cards__wrap {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .carousel-cards__wrap {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .carousel-cards__wrap {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .carousel-cards__wrap {
    width: 90%;
    max-width: 1404px;
  }
}

.carousel-cards__heading {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  font-family: freight-display-pro, serif;
  font-size: 38px;
  line-height: 48px;
  font-weight: 400;
  color: #c41230;
  text-align: left;
}
@media print, screen and (min-width: 40em) {
  .carousel-cards__heading {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .carousel-cards__heading {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .carousel-cards__heading {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .carousel-cards__heading {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .carousel-cards__heading {
    font-weight: 300;
    font-size: 44px;
    line-height: 50px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

.carousel-cards__item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-cards__list {
  display: inline-flex;
  align-items: flex-end;
  height: 360px;
  margin-top: 40px;
  margin-bottom: 40px;
}
@media print, screen and (min-width: 40em) {
  .carousel-cards__list {
    height: 500px;
  }
}

.carousel-cards__list--no-top-margin {
  margin-top: 0px;
}

.carousel-cards__list-item {
  margin-left: 15px;
}
@media print, screen and (min-width: 40em) {
  .carousel-cards__list-item {
    margin-left: calc(15px * 2);
  }
}
.carousel-cards__list-item:hover {
  cursor: pointer;
}

.carousel-cards__top-controls {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  font-family: freight-display-pro, serif;
  font-size: 38px;
  line-height: 48px;
  font-weight: 400;
  margin-top: 15px;
  margin-bottom: 35px;
  line-height: 0;
}
@media print, screen and (min-width: 40em) {
  .carousel-cards__top-controls {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .carousel-cards__top-controls {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .carousel-cards__top-controls {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .carousel-cards__top-controls {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .carousel-cards__top-controls {
    font-weight: 300;
    font-size: 44px;
    line-height: 50px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
@media print, screen and (min-width: 40em) {
  .carousel-cards__top-controls {
    display: block;
    line-height: 0;
  }
}
.carousel-cards__top-controls .carousel-secondary__slider {
  text-align: left;
}

.carousel-container__item-overlay::after {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 75px;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
}
@media print, screen and (min-width: 64em) {
  .carousel-container__item-overlay::after {
    height: 220px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
  }
}

.carousel-container__item-content {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  padding-right: 25px;
  padding-bottom: 25px;
  padding-left: 25px;
}
.carousel-cards__list-item--isActive .carousel-container__item-content {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  padding-right: 25px;
  padding-bottom: 25px;
  padding-left: 25px;
}

.CarouselContainerListItem {
  position: relative;
  width: 325px;
  height: 285px;
  background-color: #c41230;
}
@media print, screen and (min-width: 40em) {
  .CarouselContainerListItem {
    width: 530px;
    height: 500px;
  }
}

.carousel-container__content-heading {
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #fff !important;
  margin-bottom: 20px;
  display: inline-block;
  font-size: 22px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  letter-spacing: 0.04375rem;
}
@media print, screen and (min-width: 64em) {
  .carousel-container__content-heading {
    font-size: 20px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #c41230;
  }
}
.carousel-container__content-heading:focus {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}
.carousel-container__content-heading a::after {
  display: inline-block;
  width: 9px;
  height: 9px;
  align-self: flex-end;
  background-color: transparent;
  content: "";
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: translateY(-100%) rotate(45deg) translateX(10px);
  transition: all 0.2s ease;
}
.carousel-container__content-heading a:hover::after {
  transform: translateY(-16px) rotate(45deg) translateX(20px);
}

.carousel-container__content-text {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.04375rem;
  color: #fff;
  font-weight: 300;
  margin: 0;
  display: none;
}
.carousel-container__content-text:focus {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}
.carousel-container__content-text a {
  color: #fff;
  text-decoration: underline;
  font-style: italic;
  outline: 2px dotted transparent;
  outline-offset: 0;
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.carousel-container__content-text a:hover {
  text-decoration: none;
}
.carousel-container__content-text a:focus {
  text-decoration: none;
  outline: 2px dotted #fff;
}
.carousel-cards__list-item--isActive .carousel-container__content-text {
  display: block;
}

.carousel-cards__wrap-inner {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .carousel-cards__wrap-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .carousel-cards__wrap-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .carousel-cards__wrap-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .carousel-cards__wrap-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.carousel-cards__inner-wrap {
  display: none;
}
@media print, screen and (min-width: 64em) {
  .carousel-cards__inner-wrap {
    position: relative;
    display: block;
  }
}
.carousel-cards__inner-wrap:hover .carouse-cards__slider-indicator {
  background-color: #c41230;
  width: 12px;
  height: 12px;
  top: -3px;
}

.carouse-cards__slider-indicator-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% - 40px);
  height: 5px;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.carousel-cards__slider-indicator-rail {
  position: absolute;
  height: 1px;
  width: 100%;
  background-color: #ccc9b8;
  top: 2px;
}

.carouse-cards__slider-indicator {
  transition: all 1s ease;
  top: -2px;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc9b8;
}

.carousel-cards__slider {
  width: 100%;
  height: 36px;
  background-color: rgba(0, 0, 0, 0);
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  padding: 0px;
}

.carousel-cards__slider::-webkit-slider-thumb {
  position: relative;
  width: 40px;
  height: 32px;
  background-image: url("../svgs/functional/icon-range-thumb.svg");
  appearance: none;
  -webkit-appearance: none;
  z-index: 100000;
}

.carousel-cards__slider::-moz-range-thumb {
  position: relative;
  width: 42px;
  height: 34px;
  background-image: url("../svgs/functional/icon-range-thumb.svg");
  appearance: none;
  -webkit-appearance: none;
  z-index: 100000;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  background-color: transparent;
}

.carousel-cards__slider:focus::-moz-range-thumb {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}

input[type=range]::-moz-focus-outer {
  border: 0;
}

.carousel-cards__slider:focus::-webkit-slider-thumb {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-ms-track {
  background: transparent;
  border-color: transparent;
  color: black;
}

input[type=range]::-ms-thumb {
  background: red;
  color: red;
  width: 40px;
  height: 32px;
  background-image: url("../svgs/functional/icon-range-thumb.svg");
  background-repeat: no-repeat;
  margin-left: 2px;
  margin-right: 2px;
  border: 4px solid transparent;
  outline: 2px dashed transparent;
  padding: 2px;
}

input[type=range]:focus::-ms-thumb {
  border: 4px solid transparent;
  outline: 2px dashed red;
  padding: 2px;
}

input[type=range]::-ms-fill-upper {
  background: transparent;
}

input[type=range]::-ms-fill-lower {
  background: transparent;
}

input[type=range]::-ms-tooltip {
  display: block;
}

@media screen and (-ms-high-contrast: none) {
  .carousel-cards__slider {
    height: 48px;
    width: 100%;
    margin-left: 0px;
  }
}
@supports (-ms-ime-align: auto) {
  .carousel-cards__slider {
    height: 48px;
  }
}
.carousel-cards__wrap-mobile {
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;
}
@media print, screen and (min-width: 40em) {
  .carousel-cards__wrap-mobile {
    position: relative;
    bottom: 12px;
    padding-bottom: 0;
    padding-top: 40px;
  }
}
@media print, screen and (min-width: 64em) {
  .carousel-cards__wrap-mobile {
    position: absolute;
    bottom: 40px;
    left: 80px;
  }
}
@media screen and (min-width: 75em) {
  .carousel-cards__wrap-mobile {
    left: 120px;
  }
}

.carousel-cards__inner-wrap-mobile {
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  appearance: none;
  outline: none;
  text-align: center;
}
@media print, screen and (min-width: 64em) {
  .carousel-cards__inner-wrap-mobile {
    text-align: left;
  }
}

.carousel-cards__slider-mobile {
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  appearance: none;
  outline: none;
  text-align: center;
}
@media print, screen and (min-width: 64em) {
  .carousel-cards__slider-mobile {
    text-align: left;
    display: none;
  }
}
.carousel-cards__slider-mobile input {
  outline: none;
  border: none;
  position: relative;
  width: 30px;
  height: 30px;
  appearance: none;
  margin-right: 5px;
  background-color: #c41230;
  background-repeat: no-repeat;
  background-size: auto;
  cursor: pointer;
  color: rgba(0, 0, 0, 0);
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-position: center;
}
.carousel-cards__slider-mobile input:first-child {
  background-image: url("../svgs/functional/icon-arrow-left--white.svg");
}
.carousel-cards__slider-mobile input:last-child {
  background-image: url("../svgs/functional/icon-arrow-right--white.svg");
}
.carousel-cards__slider-mobile input:disabled {
  background-color: rgba(173, 16, 42, 0.5);
}
.carousel-cards__slider-mobile input:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.carousel-cards__slider-mobile input:hover {
  background-color: #ad102a;
}

.carousel-image-bleed {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  height: auto;
}
@media print, screen and (min-width: 64em) {
  .carousel-image-bleed {
    height: 620px;
    display: block;
  }
}

.carousel-image-bleed__inner {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  flex-direction: column;
}
@media print, screen and (min-width: 40em) {
  .carousel-image-bleed__inner {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .carousel-image-bleed__inner {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .carousel-image-bleed__inner {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .carousel-image-bleed__inner {
    width: 90%;
    max-width: 1404px;
  }
}

.carousel-image-bleed__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  height: 650px;
}
@media print, screen and (min-width: 40em) {
  .carousel-image-bleed__image {
    height: 100%;
  }
}

.carousel-image-bleed__segment-wrap {
  display: none;
}
@media print, screen and (min-width: 64em) {
  .carousel-image-bleed__segment-wrap {
    flex: 0 0 25%;
    max-width: 25%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    display: block;
    position: relative;
    height: 110px;
    min-height: 110px;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .carousel-image-bleed__segment-wrap {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .carousel-image-bleed__segment-wrap {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .carousel-image-bleed__segment-wrap {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .carousel-image-bleed__segment-wrap {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.carousel-image-bleed__segment {
  width: 100%;
  height: 110px;
  min-height: 110px;
  border-right: 2px solid #c41230;
  display: none;
}

.carousel-image-bleed__list-wrap {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  position: relative;
  margin-top: 300px;
  background-color: #fff;
  border: 2px solid #c41230;
  min-height: 415px;
}
@media print, screen and (min-width: 40em) {
  .carousel-image-bleed__list-wrap {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .carousel-image-bleed__list-wrap {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .carousel-image-bleed__list-wrap {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .carousel-image-bleed__list-wrap {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.carousel-image-bleed__list-wrap::before {
  display: none;
}
@media print, screen and (min-width: 40em) {
  .carousel-image-bleed__list-wrap::before {
    position: absolute;
    display: block;
    right: 10px;
    bottom: 10px;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border: 2px solid #c41230;
    content: "";
  }
}
@media print, screen and (min-width: 64em) {
  .carousel-image-bleed__list-wrap {
    flex: 0 0 50%;
    max-width: 50%;
    margin-top: 0;
  }
}

.carousel-image-bleed__list {
  padding: 50px 35px;
}

.carousel-image-bleed__item-content-wrap .carousel-image-bleed-item__content-heading {
  font-family: freight-display-pro, serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 300;
  margin-bottom: 34px;
  color: #c41230;
}
@media print, screen and (min-width: 64em) {
  .carousel-image-bleed__item-content-wrap .carousel-image-bleed-item__content-heading {
    font-size: 38px;
    line-height: 44px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
@media print, screen and (min-width: 64em) {
  .carousel-image-bleed__item-content-wrap .carousel-image-bleed-item__content-heading {
    width: calc(100% - 80px);
  }
}
.carousel-image-bleed__item-content-wrap .carousel-image-bleed-item__content-copy {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
}

.carousel-image-bleed__item-icon-wrap {
  position: absolute;
  right: 0;
  top: 0;
  margin-left: auto;
  width: 60px;
  height: 60px;
  display: none;
}
@media print, screen and (min-width: 64em) {
  .carousel-image-bleed__item-icon-wrap {
    display: block;
  }
}

.carousel-image-bleed__item-icon {
  width: 100%;
  height: 100%;
}

.carousel-image-bleed__controls {
  padding: 30px 0 10px 0;
  position: absolute;
  top: 0;
  z-index: 1;
}

.carousel-primary {
  background-color: #c41230;
  position: relative;
  display: flex;
  flex-direction: column;
}
@media print, screen and (min-width: 64em) {
  .carousel-primary {
    flex-direction: row;
  }
}

.carousel-primary-text-container {
  padding: 35px 25px 30px 25px;
}
@media print, screen and (min-width: 40em) {
  .carousel-primary-text-container {
    padding-top: 60px;
  }
}
.carousel-primary-text-container .carousel-primary__heading__icon {
  max-height: 75px;
  width: 75px;
}
@media print, screen and (min-width: 64em) {
  .carousel-primary-text-container {
    width: 50%;
    padding-top: 40px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .carousel-primary-text-container .carousel-primary__heading__icon {
    content: "";
    position: absolute;
    top: 60px;
    left: 20px;
    width: 50px;
    height: 50px;
  }
  .carousel-primary-text-container .carousel-primary__heading__icon.flip {
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
  }
  .carousel-primary-text-container:after {
    content: "";
    position: absolute;
    top: 130px;
    left: 56px;
    width: 1px;
    height: calc(100% - 130px);
    background-color: #faf9f7;
  }
}
@media screen and (min-width: 75em) {
  .carousel-primary-text-container {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }
}
@media screen and (min-width: 75em) and (min-width: 40em) {
  .carousel-primary-text-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) and (min-width: 64em) {
  .carousel-primary-text-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) and (min-width: 75em) {
  .carousel-primary-text-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 75em) and (min-width: 90em) {
  .carousel-primary-text-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .carousel-primary-text-container {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }
}
@media screen and (min-width: 90em) and (min-width: 40em) {
  .carousel-primary-text-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 90em) and (min-width: 64em) {
  .carousel-primary-text-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 90em) and (min-width: 75em) {
  .carousel-primary-text-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) and (min-width: 90em) {
  .carousel-primary-text-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.carousel-primary-image-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}
@media print, screen and (min-width: 64em) {
  .carousel-primary-image-container {
    width: 50%;
    max-height: 600px;
  }
}
.carousel-primary-image-container::before {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
  height: calc(100% - 75px);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: auto;
  content: "";
}
@media print, screen and (min-width: 64em) {
  .carousel-primary-image-container::before {
    height: calc(100% - 125px);
  }
}
.carousel-primary-image-container::after {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 75px;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
}
@media print, screen and (min-width: 64em) {
  .carousel-primary-image-container::after {
    height: 125px;
  }
}
.carousel-primary-image-container .carousel-primary__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.carousel-primary-image-container .carousel-primary__image:focus {
  outline-offset: -3px;
}

.carousel-primary__item-content {
  position: relative;
  height: 100%;
}
@media print, screen and (min-width: 64em) {
  .carousel-primary__item-content .carousel-primary__item-content-inner {
    padding: 80px 0px 0px 80px;
  }
}
.carousel-primary__item-content .carousel-primary__content-heading {
  font-family: freight-display-pro, serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 300;
  color: #fff;
  margin-bottom: 15px;
}
@media print, screen and (min-width: 64em) {
  .carousel-primary__item-content .carousel-primary__content-heading {
    font-size: 38px;
    line-height: 44px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
@media print, screen and (min-width: 64em) {
  .carousel-primary__item-content .carousel-primary__content-heading {
    margin-bottom: 20px;
  }
}
.carousel-primary__item-content .carousel-primary__content-text {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
  color: #fff;
  margin-bottom: 40px;
}

.carousel-primary__item-image-container {
  width: 100%;
  height: 100%;
}
@media print, screen and (min-width: 64em) {
  .carousel-primary__item-image-container {
    height: 100%;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
  }
}
.carousel-primary__item-image-container .carousel-primary__image-desc {
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.09375rem;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 0.1875rem;
  /*position: absolute;*/
  bottom: 4px;
  right: 20px;
  z-index: 2;
}
@media print, screen and (min-width: 64em) {
  .carousel-primary__item-image-container .carousel-primary__image-desc {
    font-family: proxima-nova, sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    position: initial;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .carousel-primary__item-image-container .carousel-primary__image-desc {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

.carousel-primary__wrap {
  /* position: relative; */
  width: calc(100% - 20px);
  padding-top: 20px;
  padding-bottom: 40px;
}
@media print, screen and (min-width: 40em) {
  .carousel-primary__wrap {
    width: calc(100% - 70px);
  }
}
@media print, screen and (min-width: 64em) {
  .carousel-primary__wrap {
    padding-left: 80px;
    width: 100%;
    padding-bottom: 0;
    margin-bottom: -47px;
  }
}

.carousel-primary__wrap-inner {
  /*position: relative; */
}

.carousel-primary__inner-wrap {
  /*position: relative;*/
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.carouse-primary__slider-indicator-wrap {
  /*position: absolute; */
  top: calc(50% - 2px);
  left: 80px;
  height: 5px;
  width: calc(100% - 275px);
}
@media print, screen and (min-width: 40em) {
  .carouse-primary__slider-indicator-wrap {
    width: calc(100% - 235px);
  }
}
@media print, screen and (min-width: 64em) {
  .carouse-primary__slider-indicator-wrap {
    width: calc(100% - 325px);
  }
}

.carousel-primary__slider-indicator-rail {
  /* position: absolute; */
  height: 2px;
  width: 70%;
  background-color: #fff;
  top: 2px;
}

.carousel-primary__slider {
  width: 35%;
  background-color: rgba(0, 0, 0, 0);
  appearance: none;
  outline: none;
  text-align: left;
  position: relative;
  z-index: 10;
}
.carousel-primary__slider button {
  outline: none;
  border: none;
  /*position: relative; */
  width: 30px;
  height: 30px;
  appearance: none;
  margin-right: 5px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: auto;
  cursor: pointer;
  color: rgba(0, 0, 0, 0);
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-position: center;
}
.carousel-primary__slider button:first-child {
  background-image: url("../svgs/functional/icon-arrow-left--red.svg");
}
.carousel-primary__slider button:first-child:disabled {
  background-color: transparent;
  background-image: url("../svgs/functional/icon-arrow-left--white.svg");
  opacity: 1;
  outline: 2px solid #ad102a;
  outline-offset: -2px;
  cursor: no-drop;
}
.carousel-primary__slider button:last-child {
  background-image: url("../svgs/functional/icon-arrow-right--red.svg");
}
.carousel-primary__slider button:last-child:disabled {
  background-color: transparent;
  background-image: url("../svgs/functional/icon-arrow-right--white.svg");
  opacity: 1;
  outline: 2px solid #ad102a;
  outline-offset: -2px;
  cursor: no-drop;
}
.carousel-primary__slider button:focus {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}
.carousel-primary__slider button:hover {
  background-color: #edece2;
}

.carousel-quote {
  padding-top: 60px;
  padding-bottom: 40px;
  overflow: hidden;
  position: relative;
  background-color: #faf9f7;
}
@media print, screen and (min-width: 64em) {
  .carousel-quote {
    padding-bottom: 60px;
    background-color: #fff;
  }
}

.carousel-quote__container {
  position: relative;
  background-color: #faf9f7;
  width: 100%;
  height: auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
}
@media print, screen and (min-width: 64em) {
  .carousel-quote__container {
    flex-direction: row;
    padding: 40px;
    justify-content: space-between;
  }
}

.carousel-quote__list {
  width: 100%;
}

.carousel-quote__list-item {
  width: 100%;
  padding-left: 0px;
}
@media print, screen and (min-width: 64em) {
  .carousel-quote__list-item {
    padding: 20px;
  }
}

@media print, screen and (min-width: 64em) {
  .carousel-quote__left-wrap {
    width: 38%;
    left: -88px;
    top: inherit;
  }
}

.carousel-quote__item-image-container {
  position: relative;
  width: 100%;
  top: 0;
}
.carousel-quote__item-image-container::after {
  content: "";
  background-image: url("../svgs/functional/icon-quote--white-warm.svg");
  background-repeat: no-repeat;
  position: absolute;
  bottom: -40px;
  left: calc(50% - 40px);
  width: 80px;
  height: 80px;
}
@media print, screen and (min-width: 64em) {
  .carousel-quote__item-image-container::after {
    top: 84px;
    right: -48px;
    left: auto;
  }
}
.carousel-quote__item-image-container .carousel-quote__image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
@media print, screen and (min-width: 64em) {
  .carousel-quote__item-image-container .carousel-quote__image {
    height: 500px;
  }
}

.carousel-quote__item-content {
  position: relative;
  width: 100%;
}
@media print, screen and (min-width: 64em) {
  .carousel-quote__item-content {
    width: calc(62% - 50px);
    display: flex;
    align-items: center;
    padding-bottom: 40px;
  }
}
.carousel-quote__item-content .carousel-quote__list-item--isActive {
  display: block;
  padding-top: 0px;
  padding-bottom: 0px;
}
@media print, screen and (min-width: 64em) {
  .carousel-quote__item-content .carousel-quote__list-item--isActive {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.carousel-quote__item-content .carousel-quote__list-item--isActive .carousel-quote__content-heading {
  font-family: freight-display-pro, serif;
  font-size: 30px;
  font-weight: 300;
  line-height: normal;
  color: #c41230;
  padding-top: 56px;
  margin-bottom: 15px;
}
@media print, screen and (min-width: 64em) {
  .carousel-quote__item-content .carousel-quote__list-item--isActive .carousel-quote__content-heading {
    padding-top: 0;
    font-size: 36px;
    margin-bottom: 34px;
  }
}
.carousel-quote__item-content .carousel-quote__list-item--isActive .carousel-quote__content-text {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: #231f20;
  margin-bottom: 8px;
}
.carousel-quote__item-content .carousel-quote__list-item--isActive .carousel-quote__content-subject {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #231f20;
}

.carousel-quote__inner-wrap {
  position: relative;
  margin: 20px 0;
  display: flex;
  justify-content: left;
}
.carousel-quote__slider {
  width: 70px;
  background-color: rgba(0, 0, 0, 0);
  appearance: none;
  outline: none;
  text-align: center;
}
.carousel-quote__slider button {
  outline: none;
  border: none;
  position: relative;
  width: 30px;
  height: 30px;
  appearance: none;
  margin-right: 5px;
  background-color: #c41230;
  background-repeat: no-repeat;
  background-size: auto;
  cursor: pointer;
  color: rgba(0, 0, 0, 0);
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-position: center;
}
.carousel-quote__slider button:first-child {
  background-image: url("../svgs/functional/icon-arrow-left--white.svg");
}
.carousel-quote__slider button:first-child:disabled {
  background-color: transparent;
  background-image: url("../svgs/functional/icon-arrow-left--red.svg");
  outline: 2px solid #ccc9b8;
  outline-offset: -2px;
  opacity: 1;
  cursor: no-drop;
}
.carousel-quote__slider button:last-child {
  background-image: url("../svgs/functional/icon-arrow-right--white.svg");
}
.carousel-quote__slider button:last-child:disabled {
  background-color: transparent;
  background-image: url("../svgs/functional/icon-arrow-right--red.svg");
  outline: 2px solid #ccc9b8;
  outline-offset: -2px;
  opacity: 1;
  cursor: no-drop;
}
.carousel-quote__slider button:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.carousel-quote__slider button:hover {
  background-color: #ad102a;
}

.chart-primary {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #faf9f7;
}
@media print, screen and (min-width: 40em) {
  .chart-primary {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media print, screen and (min-width: 64em) {
  .chart-primary {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.chart-primary__container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  background-color: #fff;
  border: 1px solid #edece2;
}
@media print, screen and (min-width: 40em) {
  .chart-primary__container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .chart-primary__container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .chart-primary__container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .chart-primary__container {
    width: 90%;
    max-width: 1404px;
  }
}

.chart-primary__inner {
  position: relative;
  width: 100%;
  background-color: #fff;
  border: 1px solid #edece2;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 20px;
  padding-right: 20px;
  top: -5px;
  left: -5px;
  display: block;
  flex-direction: initial;
}
@media print, screen and (min-width: 64em) {
  .chart-primary__inner {
    display: flex;
    flex-direction: row;
    padding-top: 60px;
    padding-bottom: 60px;
    padding-left: 40px;
    padding-right: 40px;
  }
}

.chart-primary__text-container {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .chart-primary__text-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .chart-primary__text-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .chart-primary__text-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .chart-primary__text-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 40em) {
  .chart-primary__text-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
}
@media print, screen and (min-width: 64em) {
  .chart-primary__text-container {
    flex: 0 0 50%;
    max-width: 50%;
    display: block;
  }
}
.chart-primary__text-container .chart-primary__header-heading {
  font-family: freight-display-pro, serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 300;
  color: #c41230;
  margin-bottom: 36px;
}
@media print, screen and (min-width: 64em) {
  .chart-primary__text-container .chart-primary__header-heading {
    font-size: 38px;
    line-height: 44px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
@media print, screen and (min-width: 40em) {
  .chart-primary__text-container .chart-primary__header-heading {
    width: 50%;
    padding-right: 40px;
  }
}
@media print, screen and (min-width: 64em) {
  .chart-primary__text-container .chart-primary__header-heading {
    width: 100%;
    padding-right: 0;
    margin-bottom: 20px;
  }
}
.chart-primary__text-container .chart-primary__description-list {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
@media print, screen and (min-width: 40em) {
  .chart-primary__text-container .chart-primary__description-list {
    width: 50%;
  }
}
@media print, screen and (min-width: 64em) {
  .chart-primary__text-container .chart-primary__description-list {
    width: 100%;
  }
}
.chart-primary__text-container .chart-primary__description-list-item {
  margin-bottom: 12px;
  width: 100%;
}
.chart-primary__text-container .chart-primary__description-list-item .chart-primary__color-indicator {
  width: 20px;
  height: 20px;
  border: 1px solid #edece2;
  margin-right: 20px;
  display: inline-block;
  vertical-align: middle;
}
.chart-primary__text-container .chart-primary__description-list-item .chart-primary__color-indicator .chart-primary__color-indicator-red {
  width: 14px;
  height: 14px;
  margin: 2px;
  background-color: #c41230;
}
.chart-primary__text-container .chart-primary__description-list-item .chart-primary__color-indicator .chart-primary__color-indicator-gold {
  width: 14px;
  height: 14px;
  margin: 2px;
  background-color: #edece2;
}
.chart-primary__text-container .chart-primary__description-list-item .chart-primary__color-indicator .chart-primary__color-indicator-red-accent {
  width: 14px;
  height: 14px;
  margin: 2px;
  background-color: #ad102a;
}
.chart-primary__text-container .chart-primary__description-list-item .chart-primary__color-indicator .chart-primary__color-indicator-grey {
  width: 14px;
  height: 14px;
  margin: 2px;
  background-color: #d1d0d0;
}
.chart-primary__text-container .chart-primary__description-list-item .chart-primary__description-text {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0.015625rem;
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 46px);
}
@media print, screen and (min-width: 64em) {
  .chart-primary__text-container .chart-primary__description-list-item .chart-primary__description-text {
    font-size: 24px;
    line-height: 36px;
  }
}

.chart-primary__chart-container {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  margin: 0 auto;
}
@media print, screen and (min-width: 40em) {
  .chart-primary__chart-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .chart-primary__chart-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .chart-primary__chart-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .chart-primary__chart-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 40em) {
  .chart-primary__chart-container {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.carousel-secondary {
  background-color: #edece2;
  position: relative;
}
@media print, screen and (min-width: 64em) {
  .carousel-secondary {
    padding: 0;
    display: flex;
    height: 600px;
  }
}

.carousel-secondary__item-content {
  width: 100%;
  padding: 35px 25px 30px 25px;
}
@media print, screen and (min-width: 40em) {
  .carousel-secondary__item-content {
    padding-top: 60px;
  }
}
@media print, screen and (min-width: 64em) {
  .carousel-secondary__item-content {
    width: calc(100vw - 660px);
    padding: 50px 40px 40px;
  }
}
@media screen and (min-width: 90em) {
  .carousel-secondary__item-content {
    width: 780px;
  }
}
.carousel-secondary__item-content .carousel-secondary__item-icon {
  width: 80px;
}

.carousel-secondary__list-wrapper {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 325px;
}
@media print, screen and (min-width: 40em) {
  .carousel-secondary__list-wrapper {
    height: 600px;
  }
}
@media print, screen and (min-width: 64em) {
  .carousel-secondary__list-wrapper {
    width: 660px;
  }
}
@media screen and (min-width: 90em) {
  .carousel-secondary__list-wrapper {
    width: calc(100vw - 780px);
  }
}

.carousel-secondary__list {
  width: 100%;
  min-width: 10000px;
  display: flex;
  align-items: flex-end;
}
.carousel-secondary__list:after {
  content: "";
  display: table;
  clear: both;
}

.carousel-secondary__list-item {
  width: 350px;
  padding-left: 0px;
  padding-right: 25px;
}
@media print, screen and (min-width: 40em) {
  .carousel-secondary__list-item {
    width: 625px;
    padding-right: 25px;
  }
}
.carousel-secondary__list-item .CarouselContainerListItem {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
@media print, screen and (min-width: 64em) {
  .carousel-secondary__list-item .CarouselContainerListItem {
    flex-direction: row-reverse;
  }
}
.carousel-secondary__list-item--isActive .carousel-secondary__list-item .CarouselContainerListItem {
  flex-direction: column;
  margin-bottom: 30px;
}
@media print, screen and (min-width: 64em) {
  .carousel-secondary__list-item--isActive .carousel-secondary__list-item .CarouselContainerListItem {
    flex-direction: row;
    margin-bottom: 30px;
  }
}

.carousel-secondary__list-item--isActive {
  width: 350px;
}
@media print, screen and (min-width: 40em) {
  .carousel-secondary__list-item--isActive {
    width: 625px;
  }
}

.CarouselContainerListItem {
  background-color: #c41230;
}
.carousel-secondary__list-item--isActive .CarouselContainerListItem {
  flex-direction: column;
}
@media print, screen and (min-width: 64em) {
  .carousel-secondary__list-item--isActive .CarouselContainerListItem {
    flex-direction: row;
  }
}

.carousel-secondary__item-inner {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 20px;
}
@media print, screen and (min-width: 64em) {
  .carousel-secondary__item-inner {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 12px;
  }
}
@media screen and (min-width: 75em) {
  .carousel-secondary__item-inner {
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 20px;
  }
}
.carousel-secondary__item-inner .carousel-secondary__content-heading {
  font-family: freight-display-pro, serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 300;
  color: #c41230;
  margin-bottom: 15px;
}
@media print, screen and (min-width: 64em) {
  .carousel-secondary__item-inner .carousel-secondary__content-heading {
    font-size: 38px;
    line-height: 44px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
@media print, screen and (min-width: 64em) {
  .carousel-secondary__item-inner .carousel-secondary__content-heading {
    font-family: proxima-nova, sans-serif;
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    margin-bottom: 12px;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .carousel-secondary__item-inner .carousel-secondary__content-heading {
    font-size: 26px;
    line-height: 32px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 75em) {
  .carousel-secondary__item-inner .carousel-secondary__content-heading {
    font-family: proxima-nova, sans-serif;
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 75em) and (min-width: 64em) {
  .carousel-secondary__item-inner .carousel-secondary__content-heading {
    font-size: 26px;
    line-height: 32px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 90em) {
  .carousel-secondary__item-inner .carousel-secondary__content-heading {
    font-family: freight-display-pro, serif;
    font-size: 30px;
    line-height: 36px;
    font-weight: 300;
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 90em) and (min-width: 64em) {
  .carousel-secondary__item-inner .carousel-secondary__content-heading {
    font-size: 38px;
    line-height: 44px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
.carousel-secondary__item-inner .carousel-secondary__content-text {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
  margin-bottom: 20px;
}
.carousel-secondary__item-inner .base-cta-primary {
  margin-top: 10px;
}
@media print, screen and (min-width: 64em) {
  .carousel-secondary__item-inner .base-cta-primary {
    margin-top: 20px;
  }
}

.carousel-secondary__item-image-container {
  position: relative;
  width: 100%;
}
@media print, screen and (min-width: 64em) {
  .carousel-secondary__item-image-container {
    width: 100%;
  }
}
.carousel-secondary__item-image-container img {
  object-fit: cover;
  height: 100%;
}
.carousel-secondary__item-image-container .carousel-secondary__item-image-overlay::after {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 75px;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
}
@media print, screen and (min-width: 64em) {
  .carousel-secondary__item-image-container .carousel-secondary__item-image-overlay::after {
    height: 220px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
  }
}
.carousel-secondary__item-image-container .carousel-secondary__item-image-text-inner {
  position: absolute;
  bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  display: none;
}
@media print, screen and (min-width: 64em) {
  .carousel-secondary__item-image-container .carousel-secondary__item-image-text-inner {
    bottom: 40px;
    padding-left: 48px;
    padding-right: 48px;
  }
}
.carousel-secondary__list-item--isActive .carousel-secondary__item-image-container .carousel-secondary__item-image-text-inner {
  display: block;
}
.carousel-secondary__item-image-container .carousel-secondary__item-image-text-inner .carousel-secondary__item-image-heading {
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #fff !important;
  margin-bottom: 20px;
  font-size: 22px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  letter-spacing: 0.04375rem;
}
@media print, screen and (min-width: 64em) {
  .carousel-secondary__item-image-container .carousel-secondary__item-image-text-inner .carousel-secondary__item-image-heading {
    font-size: 20px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #c41230;
  }
}
.carousel-secondary__item-image-container .carousel-secondary__item-image-text-inner .carousel-secondary__item-image-heading .carousel-secondary__item-image-heading-link::after {
  display: inline-block;
  width: 9px;
  height: 9px;
  align-self: flex-end;
  background-color: transparent;
  content: "";
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: translateY(-100%) rotate(45deg) translateX(10px);
  transition: all 0.2s ease;
}
.carousel-secondary__item-image-container .carousel-secondary__item-image-text-inner .carousel-secondary__item-image-heading .carousel-secondary__item-image-heading-link:hover:after {
  transform: translateY(-16px) rotate(45deg) translateX(20px);
}
.carousel-secondary__item-image-container .carousel-secondary__item-image-text-inner .carousel-secondary__item-image-heading .carousel-secondary__item-image-heading-link:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.carousel-secondary__item-image-container .carousel-secondary__item-image-text-inner .carousel-secondary__item-image-text {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.04375rem;
  font-weight: 300;
  color: #fff;
}

.carousel-secondary__wrap {
  padding: 35px 0 30px;
}
@media print, screen and (min-width: 40em) {
  .carousel-secondary__wrap {
    bottom: 12px;
  }
}
@media print, screen and (min-width: 64em) {
  .carousel-secondary__wrap {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 12px;
    bottom: 40px;
    left: 80px;
  }
}
@media screen and (min-width: 75em) {
  .carousel-secondary__wrap {
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 20px;
  }
}

.carousel-secondary__inner-wrap {
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  appearance: none;
  outline: none;
  text-align: center;
}
@media print, screen and (min-width: 64em) {
  .carousel-secondary__inner-wrap {
    text-align: left;
  }
}

.carousel-secondary__slider {
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  appearance: none;
  outline: none;
  text-align: left;
}
.carousel-secondary__slider button {
  outline: none;
  border: none;
  position: relative;
  width: 30px;
  height: 30px;
  appearance: none;
  margin-right: 5px;
  background-color: #c41230;
  background-repeat: no-repeat;
  background-size: auto;
  cursor: pointer;
  color: rgba(0, 0, 0, 0);
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-position: center;
}
.carousel-secondary__slider button:first-child {
  background-image: url("../svgs/functional/icon-arrow-left--white.svg");
}
.carousel-secondary__slider button:first-child:disabled {
  background-color: transparent;
  background-image: url("../svgs/functional/icon-arrow-left--red.svg");
  outline: 2px solid #ccc9b8;
  outline-offset: -2px;
  cursor: no-drop;
}
.carousel-secondary__slider button:last-child {
  background-image: url("../svgs/functional/icon-arrow-right--white.svg");
}
.carousel-secondary__slider button:last-child:disabled {
  background-color: transparent;
  background-image: url("../svgs/functional/icon-arrow-right--red.svg");
  outline: 2px solid #ccc9b8;
  outline-offset: -2px;
  cursor: no-drop;
}
.carousel-secondary__slider button:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.carousel-secondary__slider button:hover {
  background-color: #ad102a;
}

.content-grid {
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
}
.content-grid::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 560px;
  top: 0;
  z-index: -1;
  background-image: url("../images/illustrations/illustration-duotone-red.png");
  background-position: center top;
  background-size: 160%;
  background-color: #c41230;
}
@media print, screen and (min-width: 40em) {
  .content-grid::before {
    background-size: 130%;
  }
}
@media print, screen and (min-width: 64em) {
  .content-grid::before {
    background-size: 100%;
  }
}
@media screen and (min-width: 90em) {
  .content-grid::before {
    background-size: 70%;
  }
}
@media print, screen and (min-width: 64em) {
  .content-grid {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.content-grid__inner {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  flex-flow: column wrap;
}
@media print, screen and (min-width: 40em) {
  .content-grid__inner {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .content-grid__inner {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .content-grid__inner {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .content-grid__inner {
    width: 90%;
    max-width: 1404px;
  }
}
@media print, screen and (min-width: 40em) {
  .content-grid__inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .content-grid__inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .content-grid__inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .content-grid__inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .content-grid__inner {
    flex-flow: row wrap;
  }
}

.content-grid__background-container {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.content-grid__background-container .content-grid__inner-container {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  justify-self: center;
  padding-bottom: 20px;
}
@media print, screen and (min-width: 40em) {
  .content-grid__background-container .content-grid__inner-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .content-grid__background-container .content-grid__inner-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .content-grid__background-container .content-grid__inner-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .content-grid__background-container .content-grid__inner-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .content-grid__background-container .content-grid__inner-container {
    max-width: 970px;
  }
}
.content-grid__background-container .content-grid__inner-container .content-grid__header-container {
  text-align: center;
}
.content-grid__background-container .content-grid__inner-container .content-grid__header-container .content-grid__header-icon {
  width: 80px;
  margin: auto;
}
.content-grid__background-container .content-grid__inner-container .content-grid__header-container .content-grid__heading {
  font-family: freight-display-pro, serif;
  font-size: 38px;
  line-height: 48px;
  font-weight: 400;
  color: #fff;
  padding-top: 13px;
}
@media print, screen and (min-width: 64em) {
  .content-grid__background-container .content-grid__inner-container .content-grid__header-container .content-grid__heading {
    font-weight: 300;
    font-size: 44px;
    line-height: 50px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
.content-grid__background-container .content-grid__inner-container .content-grid__copy-container {
  padding-top: 15px;
  text-align: center;
}
.content-grid__background-container .content-grid__inner-container .content-grid__copy-container .content-grid__copy {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0.015625rem;
  color: #fff;
  padding-bottom: 20px;
}
@media print, screen and (min-width: 64em) {
  .content-grid__background-container .content-grid__inner-container .content-grid__copy-container .content-grid__copy {
    font-size: 24px;
    line-height: 36px;
  }
}
.content-grid__background-container .content-grid__inner-container .content-grid__copy-container .content-grid__header-cta {
  margin-top: 10px;
}

.content-grid__content-container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-top: 20px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
@media print, screen and (min-width: 64em) {
  .content-grid__content-container {
    flex-direction: row;
  }
}
.content-grid__content-container .content-grid__content-wrapper {
  position: relative;
  width: 100%;
  margin: 0;
}
@media print, screen and (min-width: 64em) {
  .content-grid__content-container .content-grid__content-wrapper {
    width: 50%;
  }
}
.content-grid__content-container .content-grid__content-special {
  height: 100%;
  position: relative;
}
.content-grid__content-container .content-grid__content-special::before {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.6) 90%);
  height: calc(100% - 75px);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: auto;
  content: "";
  z-index: 1;
}
@media print, screen and (min-width: 64em) {
  .content-grid__content-container .content-grid__content-special::before {
    height: calc(100% - 125px);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0.6) 90%);
  }
}
.content-grid__content-container .content-grid__content-special::after {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 75px;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
}
@media print, screen and (min-width: 64em) {
  .content-grid__content-container .content-grid__content-special::after {
    height: 125px;
  }
}
.content-grid__content-container .content-grid__content-special .content-grid__content-image {
  width: 100%;
}
.content-grid__content-container .content-grid__content-special .content-grid__content-text {
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 30px;
  color: #fff;
  z-index: 2;
}
.content-grid__content-container .content-grid__content-special .content-grid__content-text .content-grid__content-text-headline {
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 27px;
  margin-bottom: 20px;
}
.content-grid__content-container .content-grid__content-special .content-grid__content-text .content-grid__content-text-copy {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.04375rem;
  font-size: 16px;
  margin-bottom: 20px;
}
.content-grid__content-container .content-grid__content-special .content-grid__content-text a {
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #fff;
  position: relative;
  display: inline-block;
  padding: 12px 16px 12px 0;
  margin-top: 20px;
  font-weight: 700;
  text-decoration: none;
  margin: 0;
}
.content-grid__content-container .content-grid__content-special .content-grid__content-text a:before {
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
  width: 9px;
  height: 9px;
  background-color: transparent;
  content: "";
  transform: translateY(-50%) rotate(45deg);
  transition: all 0.2s ease;
}
.content-grid__content-container .content-grid__content-special .content-grid__content-text a:hover:before {
  transform: translate(5px, -50%) rotate(45deg) scale(1);
}
.content-grid__content-container .content-grid__content-special .content-grid__content-text a:focus {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}
.content-grid__content-container .content-grid__normal-content-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
@media print, screen and (min-width: 40em) {
  .content-grid__content-container .content-grid__normal-content-wrapper {
    flex-direction: row;
  }
}
.content-grid__content-container .content-grid__content-normal {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  flex-grow: 1;
}
@media print, screen and (min-width: 40em) {
  .content-grid__content-container .content-grid__content-normal {
    width: 50%;
    height: auto;
  }
}
@media print, screen and (min-width: 64em) {
  .content-grid__content-container .content-grid__content-normal {
    width: 50%;
    height: 50%;
  }
}
.content-grid__content-container .content-grid__content-normal .content-grid__content-text-headline {
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-size: 19px;
  line-height: 22px;
  text-align: center;
  color: #fff;
}
.content-grid__content-container .content-grid__content-normal .content-grid__content-normal-bordered-image {
  position: relative;
  width: 100%;
  height: 100%;
}
.content-grid__content-container .content-grid__content-normal .content-grid__content-normal-bordered-image::before {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.6) 100%);
  height: calc(100% - 75px);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: auto;
  content: "";
  z-index: 1;
}
@media print, screen and (min-width: 64em) {
  .content-grid__content-container .content-grid__content-normal .content-grid__content-normal-bordered-image::before {
    height: calc(100% - 125px);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0.6) 100%);
  }
}
.content-grid__content-container .content-grid__content-normal .content-grid__content-normal-bordered-image::after {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 75px;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
}
@media print, screen and (min-width: 64em) {
  .content-grid__content-container .content-grid__content-normal .content-grid__content-normal-bordered-image::after {
    height: 125px;
  }
}
.content-grid__content-container .content-grid__content-normal .content-grid__content-normal-bordered-image .content-grid__content-normal-image {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
}
.content-grid__content-container .content-grid__content-normal .content-grid__content-normal-bordered-image .content-grid__content-normal-inner {
  position: absolute;
  width: 90%;
  height: 90%;
  top: -50%;
  left: -10%;
  transform: translateX(17%) translateY(61%);
  padding: 17px;
  border: solid 1px #ccc9b8;
  z-index: 2;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}
.content-grid__content-container .content-grid__content-normal .content-grid__content-normal-bordered-image .content-grid__content-normal-inner a {
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 2px;
  color: #fff;
}
.content-grid__content-container .content-grid__content-normal .content-grid__content-normal-bordered-image .content-grid__content-normal-inner a:before {
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
}
.content-grid__content-container .content-grid__content-normal .content-grid__content-normal-normal-text {
  background-color: #edece2;
  padding: 15px;
  width: 100%;
  height: calc(100vw - 30px);
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media print, screen and (min-width: 40em) {
  .content-grid__content-container .content-grid__content-normal .content-grid__content-normal-normal-text {
    height: 100%;
  }
}
.content-grid__content-container .content-grid__content-normal .content-grid__content-normal-normal-text .content-grid__content-normal-inner {
  text-align: center;
  margin: auto;
}
.content-grid__content-container .content-grid__content-normal .content-grid__content-normal-normal-text .content-grid__content-text-headline {
  font-family: freight-text-pro, serif;
  font-size: 54px;
  line-height: 63px;
  letter-spacing: 0.84375px;
  color: #c41230;
  margin-bottom: 10px;
}
.content-grid__content-container .content-grid__content-normal .content-grid__content-normal-normal-text .content-grid__content-text-copy {
  font-family: freight-text-pro, serif;
  font-size: 28px;
  line-height: 35px;
  text-align: center;
  color: #231f20;
  margin-bottom: 10px;
  font-weight: 300;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .content-grid__content-container .content-grid__content-normal .content-grid__content-normal-normal-text .content-grid__content-text-copy {
    padding: 0 15px;
  }
}
@media screen and (-ms-high-contrast: none) {
  .content-grid__content-container .content-grid__content-normal .content-grid__content-normal-normal-text .content-grid__content-text-copy {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}
.content-grid__content-container .content-grid__content-normal .content-grid__content-normal-normal-text a {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 700;
  font-style: italic;
  line-height: 17px;
  color: #c41230;
}
.content-grid__content-container .content-grid__content-normal .content-grid__content-normal-normal-text a:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.content-grid__content-container .content-grid__content-normal .content-grid__content-normal-normal-image {
  position: relative;
}
.content-grid__content-container .content-grid__content-normal .content-grid__content-normal-normal-image::before {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
  height: calc(100% - 75px);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: auto;
  content: "";
  z-index: 1;
}
@media print, screen and (min-width: 64em) {
  .content-grid__content-container .content-grid__content-normal .content-grid__content-normal-normal-image::before {
    height: calc(100% - 125px);
  }
}
.content-grid__content-container .content-grid__content-normal .content-grid__content-normal-normal-image::after {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 75px;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
}
@media print, screen and (min-width: 64em) {
  .content-grid__content-container .content-grid__content-normal .content-grid__content-normal-normal-image::after {
    height: 125px;
  }
}
.content-grid__content-container .content-grid__content-normal .content-grid__content-normal-normal-image .content-grid__content-normal-image {
  width: 100%;
}
.content-grid__content-container .content-grid__content-normal .content-grid__content-normal-normal-image .content-grid__content-normal-inner {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 15px;
  z-index: 2;
}
.content-grid__content-container .content-grid__content-normal .content-grid__content-normal-normal-image .content-grid__content-normal-inner a {
  color: #fff;
}
.content-grid__content-container .content-grid__content-normal .content-grid__content-normal-normal-image .content-grid__content-normal-inner a:before {
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
}

.context-image {
  position: relative;
  background-color: #fff;
}

.context-image__badge {
  position: absolute;
  top: 48px;
  display: block;
  width: 60px;
  height: 65px;
  content: "";
}
@media screen and (max-width: 768px) {
  .context-image__badge {
    width: 50px;
    height: 55px;
  }
}
@media print, screen and (min-width: 40em) {
  .context-image__badge {
    right: 0;
  }
}

.context-image__inner {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .context-image__inner {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .context-image__inner {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .context-image__inner {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .context-image__inner {
    width: 90%;
    max-width: 1404px;
  }
}

.context-image__context {
  position: relative;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-top: 60px;
}
@media print, screen and (min-width: 40em) {
  .context-image__context {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .context-image__context {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .context-image__context {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .context-image__context {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 40em) {
  .context-image__context {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .context-image__context.right {
    margin-left: 58.3333333333%;
  }
}

.context-image__figure {
  position: relative;
  margin-top: 48px;
  margin-right: 24px;
  margin-bottom: 48px;
  margin-left: 24px;
  background-color: #c41230;
}
@media print, screen and (min-width: 40em) {
  .context-image__figure {
    position: absolute;
    top: 0;
    width: 48%;
    height: 100%;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 0;
  }
  .context-image__figure.left {
    right: 0;
  }
}

.context-image__image {
  width: 100%;
  height: 375px;
  object-fit: cover;
}
@media print, screen and (min-width: 40em) {
  .context-image__image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}

.context-image__caption {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  padding: 24px;
  color: #fff;
}
.context-image__caption::before {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
  height: calc(100% - 75px);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: auto;
  content: "";
}
@media print, screen and (min-width: 64em) {
  .context-image__caption::before {
    height: 125px;
    top: calc(100% - 75px - 125px);
  }
}
.context-image__caption::after {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 75px;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}
@media print, screen and (min-width: 64em) {
  .context-image__caption::after {
    height: 75px;
  }
}
.context-image__caption-heading {
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #fff !important;
}
@media print, screen and (min-width: 64em) {
  .context-image__caption-heading {
    font-size: 20px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #c41230;
  }
}

.context-image__caption-copy {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.04375rem;
  position: relative;
  font-weight: 300;
}
.context-image__caption-heading + .context-image__caption-copy {
  margin-top: 20px;
}

.context-list-image {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #fff;
}
.context-list-image.context-list-image--gold {
  background-color: #edece2;
}
.context-list-image.context-list-image--red {
  background-color: #c41230;
}
.context-list-image.context-list-image--red .context-list-image__article-cta .base-cta-text:before {
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
}
@media print, screen and (min-width: 40em) {
  .context-list-image {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media print, screen and (min-width: 64em) {
  .context-list-image {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.context-list-image__inner {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .context-list-image__inner {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .context-list-image__inner {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .context-list-image__inner {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .context-list-image__inner {
    width: 90%;
    max-width: 1404px;
  }
}
.context-list-image__inner article:last-of-type {
  padding-bottom: 0;
}
.context-list-image__inner article:last-of-type .context-list-image__article-inner {
  padding-bottom: 0;
}
.context-list-image__inner .context-list-image__lockup-container .base-lockup__copy--large {
  margin-top: 15px;
}
@media print, screen and (min-width: 64em) {
  .context-list-image__inner .context-list-image__lockup-container .base-lockup__copy--large {
    margin-top: 24px;
  }
}

.context-list-image--vertical .context-list-image__lockup-container {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-bottom: 40px;
}
@media print, screen and (min-width: 40em) {
  .context-list-image--vertical .context-list-image__lockup-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .context-list-image--vertical .context-list-image__lockup-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .context-list-image--vertical .context-list-image__lockup-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .context-list-image--vertical .context-list-image__lockup-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .context-list-image--vertical .context-list-image__lockup-container {
    flex: 0 0 50%;
    max-width: 50%;
    padding-bottom: 50px;
  }
}
.context-list-image--vertical .context-list-image__articles {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .context-list-image--vertical .context-list-image__articles {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .context-list-image--vertical .context-list-image__articles {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .context-list-image--vertical .context-list-image__articles {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .context-list-image--vertical .context-list-image__articles {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .context-list-image--vertical .context-list-image__articles {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.context-list-image--vertical .context-list-image__article-inner {
  padding-bottom: 40px;
}
@media print, screen and (min-width: 64em) {
  .context-list-image--vertical .context-list-image__article-inner {
    padding-bottom: 50px;
  }
}

.context-list-image--horizontal .context-list-image__lockup-container {
  padding-bottom: 40px;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .context-list-image--horizontal .context-list-image__lockup-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .context-list-image--horizontal .context-list-image__lockup-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .context-list-image--horizontal .context-list-image__lockup-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .context-list-image--horizontal .context-list-image__lockup-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .context-list-image--horizontal .context-list-image__lockup-container {
    padding-bottom: 50px;
  }
}
.context-list-image--horizontal .context-list-image__article {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-bottom: 40px;
}
@media print, screen and (min-width: 40em) {
  .context-list-image--horizontal .context-list-image__article {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .context-list-image--horizontal .context-list-image__article {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .context-list-image--horizontal .context-list-image__article {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .context-list-image--horizontal .context-list-image__article {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .context-list-image--horizontal .context-list-image__article {
    padding-bottom: 50px;
  }
}
@media print, screen and (min-width: 64em) {
  .context-list-image--horizontal .context-list-image__article.context-list-image__article--2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .context-list-image--horizontal .context-list-image__article.context-list-image__article--3 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .context-list-image--horizontal .context-list-image__article.context-list-image__article--4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media print, screen and (min-width: 64em) {
  .context-list-image--vertical .context-list-image__article {
    display: flex;
  }
}

.context-list-image__article-image-container {
  margin-bottom: 28px;
}

.context-list-image__article-image {
  width: 100%;
}
.context-list-image--vertical .context-list-image__article-image {
  width: 100%;
}

.context-list-image__article-headline {
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #c41230;
}
@media print, screen and (min-width: 64em) {
  .context-list-image__article-headline {
    font-size: 20px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #c41230;
  }
}
.context-list-image__article-headline.context-list-image__article-headline--red {
  color: #c41230;
}
.context-list-image__article-headline.context-list-image__article-headline--white {
  color: #fff;
}

.context-list-image__article-copy {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
  color: #231f20;
  padding-top: 12px;
}
.context-list-image--red .context-list-image__article-copy {
  color: #fff;
}

.form-section {
  padding-top: 40px;
  padding-bottom: 40px;
}
@media print, screen and (min-width: 40em) {
  .form-section {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media print, screen and (min-width: 64em) {
  .form-section {
    padding-top: 80px;
    padding-bottom: 60px;
  }
}
.form-section.form-section--red {
  background-color: #c41230;
}
.form-section.form-section--white {
  background-color: #fff;
}
.form-section.form-section--gold {
  background-color: #edece2;
}

.form-container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  position: relative;
  align-items: flex-start;
}
@media print, screen and (min-width: 40em) {
  .form-container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .form-container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .form-container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .form-container {
    width: 90%;
    max-width: 1404px;
  }
}

.form__heading-container {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  align-self: flex-start;
  justify-self: flex-start;
  padding-bottom: 12px;
}
@media print, screen and (min-width: 40em) {
  .form__heading-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .form__heading-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .form__heading-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .form__heading-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .form__heading-container {
    padding-bottom: 20px;
  }
}
.form__heading-container .form__heading {
  font-family: freight-display-pro, serif;
  font-size: 38px;
  line-height: 48px;
  font-weight: 400;
}
@media print, screen and (min-width: 64em) {
  .form__heading-container .form__heading {
    font-weight: 300;
    font-size: 44px;
    line-height: 50px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

.form-elements-container {
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
@media print, screen and (min-width: 40em) {
  .form-elements-container {
    flex-direction: row;
  }
}
.form-elements-container .form-warning-indication {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #7e6d53;
}

.form-element {
  padding-top: 20px;
  position: relative;
}
.form-element.form-element--layout-1 {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-top: 40px;
}
@media print, screen and (min-width: 40em) {
  .form-element.form-element--layout-1 {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .form-element.form-element--layout-1 {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .form-element.form-element--layout-1 {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .form-element.form-element--layout-1 {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.form-element.form-element--layout-2 {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .form-element.form-element--layout-2 {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .form-element.form-element--layout-2 {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .form-element.form-element--layout-2 {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .form-element.form-element--layout-2 {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 40em) {
  .form-element.form-element--layout-2 {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }
}
@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .form-element.form-element--layout-2 {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 40em) and (min-width: 64em) {
  .form-element.form-element--layout-2 {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 40em) and (min-width: 75em) {
  .form-element.form-element--layout-2 {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 40em) and (min-width: 90em) {
  .form-element.form-element--layout-2 {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.form-element.form-element--layout-3 {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .form-element.form-element--layout-3 {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .form-element.form-element--layout-3 {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .form-element.form-element--layout-3 {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .form-element.form-element--layout-3 {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 40em) {
  .form-element.form-element--layout-3 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }
}
@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .form-element.form-element--layout-3 {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 40em) and (min-width: 64em) {
  .form-element.form-element--layout-3 {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 40em) and (min-width: 75em) {
  .form-element.form-element--layout-3 {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 40em) and (min-width: 90em) {
  .form-element.form-element--layout-3 {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.form-element.form-element--layout-4 {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .form-element.form-element--layout-4 {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .form-element.form-element--layout-4 {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .form-element.form-element--layout-4 {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .form-element.form-element--layout-4 {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 40em) {
  .form-element.form-element--layout-4 {
    flex: 0 0 25%;
    max-width: 25%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }
}
@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .form-element.form-element--layout-4 {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 40em) and (min-width: 64em) {
  .form-element.form-element--layout-4 {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 40em) and (min-width: 75em) {
  .form-element.form-element--layout-4 {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 40em) and (min-width: 90em) {
  .form-element.form-element--layout-4 {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.form-element .m_form-input {
  position: relative;
}
.form-element .m_form-input::before,
.form-element .m_form-input::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 1px;
  background-color: #d1d0d0;
  z-index: 1;
}
.form-element .m_form-input::after {
  width: 0%;
  background-color: #7e6d53;
  transition: all 0.2s;
}
.form-element .m_form-input.focus::after {
  width: 100%;
}
.form-element label {
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  display: block;
  line-height: 20px;
  position: relative;
  top: 20px;
  opacity: 0;
  z-index: -1;
  transition: all 0.2s;
}
.form-element label.focus {
  color: #c41230;
}
.form-element label.no-empty,
.form-element label.focus {
  top: 0px;
  opacity: 1;
}
.form-element input,
.form-element select {
  position: relative;
  width: 100%;
  font-size: 16px;
  padding: 4px 0;
  border: none;
  font-size: 24px;
  font-family: freight-text-pro;
  outline: none;
}
.form-element select {
  background: none;
  color: #777;
  -webkit-appearance: none;
}
.form-element select.focus {
  color: #231f20;
}
.form-element .caret-down {
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #d1d0d0;
}
.form-element .caret-down.focus {
  border-top: 5px solid #231f20;
}
.form-element .caret-up {
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #d1d0d0;
}
.form-element textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: none;
  border: 1px solid #d1d0d0;
  font-size: 24px;
  font-family: freight-text-pro, serif;
}
.form-element textarea:focus {
  outline: none;
  border: 1px solid #7e6d53;
}
.form-element p {
  height: 16px;
  color: #c41230;
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 400;
  margin-top: 4px;
  position: absolute;
  bottom: -18px;
  left: 8px;
}
@media print, screen and (min-width: 40em) {
  .form-element p {
    bottom: -24px;
    left: 15px;
  }
}

.form-element-inner {
  width: 100%;
}

.form-warning-indicator {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .form-warning-indicator {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .form-warning-indicator {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .form-warning-indicator {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .form-warning-indicator {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.dependency-video {
  padding-top: 80px;
  padding-bottom: 30px;
  background: -webkit-linear-gradient(to right, #faf9f7 60%, #fff 60%);
  background: -o-linear-gradient(to right, #faf9f7 60%, #fff 60%);
  background: -moz-linear-gradient(to right, #faf9f7 60%, #fff 60%);
  background: linear-gradient(to right, #faf9f7 60%, #fff 60%);
}
@media print, screen and (min-width: 64em) {
  .dependency-video {
    padding-bottom: 80px;
  }
}

.dependency-video__container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .dependency-video__container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .dependency-video__container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .dependency-video__container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .dependency-video__container {
    width: 90%;
    max-width: 1404px;
  }
}
@media print, screen and (min-width: 64em) {
  .dependency-video__container {
    padding-left: 80px;
    padding-right: 80px;
  }
}

.dependency-video__inner {
  position: relative;
  width: 100%;
}
@media print, screen and (min-width: 64em) {
  .dependency-video__inner {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .dependency-video__inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .dependency-video__inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .dependency-video__inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .dependency-video__inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.dependency-video__background {
  position: relative;
  width: 100%;
  height: 270px;
  background-color: #c41230;
}
@media print, screen and (min-width: 40em) {
  .dependency-video__background {
    height: 370px;
  }
}
@media print, screen and (min-width: 64em) {
  .dependency-video__background {
    position: relative;
    height: 550px;
  }
}

.dependency-video__header-container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  align-items: center;
  z-index: 1;
  padding-top: 40px;
}
@media print, screen and (min-width: 40em) {
  .dependency-video__header-container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .dependency-video__header-container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .dependency-video__header-container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .dependency-video__header-container {
    width: 90%;
    max-width: 1404px;
  }
}
@media print, screen and (min-width: 64em) {
  .dependency-video__header-container {
    padding-top: 0;
    height: 550px;
    position: absolute;
    width: 100%;
    top: 0;
  }
}

.dependency-video__background-image {
  position: relative;
  z-index: -1;
  width: 100%;
  height: 370px;
  object-fit: cover;
}
@media print, screen and (min-width: 64em) {
  .dependency-video__background-image {
    height: 550px;
  }
}

.dependency-video__video {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}
.dependency-video__video::after {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 5%, rgba(0, 0, 0, 0.9) 100%);
  width: 100%;
  height: 75px;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
}
@media print, screen and (min-width: 64em) {
  .dependency-video__video::after {
    height: 180px;
  }
}
.dependency-video__video:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}

.dependency-video__heading-inner {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
}
@media print, screen and (min-width: 40em) {
  .dependency-video__heading-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .dependency-video__heading-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .dependency-video__heading-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .dependency-video__heading-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .dependency-video__heading-inner {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
}

.dependency-video__header-icon-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 30px;
  height: 100%;
  overflow: hidden;
  transform: translate(-50px);
  display: none;
}
@media print, screen and (min-width: 64em) {
  .dependency-video__header-icon-container {
    display: block;
    width: 40px;
    transform: translate(-77px);
  }
}

.dependency-video__header-icon-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.dependency-video__header-icon {
  position: relative;
  align-items: center;
  background-color: #faf9f7;
  margin-bottom: 20px;
  width: 40px;
  height: 40px;
}

.dependency-video__content-icon {
  background-color: #faf9f7;
}

.dependency-video__header-icon-line {
  position: relative;
  width: 1px;
  height: 100%;
  background-color: #c41230;
  transform: translate(0, 0);
}

.dependency-video__heading {
  font-family: freight-display-pro, serif;
  font-size: 38px;
  line-height: 48px;
  font-weight: 400;
  color: #c41230;
  position: relative;
}
@media print, screen and (min-width: 64em) {
  .dependency-video__heading {
    font-weight: 300;
    font-size: 44px;
    line-height: 50px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
@media print, screen and (min-width: 64em) {
  .dependency-video__heading {
    position: absolute;
    bottom: 0;
    margin-bottom: 40px;
    color: #fff;
    z-index: 1;
  }
}

.dependency-video__modal {
  z-index: 100;
}

.dependency-video__video-button {
  position: absolute;
  right: calc(50% - 40px);
  bottom: calc(50% - 40px);
  z-index: 10;
  width: 80px;
  height: 80px;
  padding: 2px;
  border-radius: 50%;
  fill: #fff;
}
@media print, screen and (min-width: 64em) {
  .dependency-video__video-button {
    right: 50px;
    bottom: 50px;
    width: 100px;
    height: 100px;
  }
}
.dependency-video__video-button::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 50%;
  transition-property: border-color;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  content: "";
}
.dependency-video__video-button:hover::before {
  border-color: #fff;
}
.dependency-video__video-button:focus {
  outline: 2px dotted #fff;
  outline-offset: 4px;
  outline-offset: -4px;
}

.dependency-video__video-button-icon--play {
  width: 100%;
  display: block;
}

.HeaderMainMenu__link.active {
  border-color: #fff !important;
}

.HeaderMainMenu__item-wrapper {
  position: relative;
  width: 100%;
  padding-right: 30px;
  padding-top: 8px;
  padding-bottom: 8px;
}
@media print, screen and (min-width: 64em) {
  .HeaderMainMenu__item-wrapper {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.button-skip-content {
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}
.button-skip-content:focus, .button-skip-content:active {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  outline: 2px dotted #c41230;
  outline-offset: 4px;
  visibility: visible;
  overflow: visible;
  clip: auto;
  width: auto;
  height: auto;
  display: block;
  padding: 10px;
  color: #c41230;
  background-color: #fff;
  outline-offset: -2px;
  z-index: 999;
}
@media print, screen and (min-width: 64em) {
  .button-skip-content:focus, .button-skip-content:active {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

.header__main_menu_child {
  display: block;
  margin-bottom: 30px;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  color: black;
  z-index: 1;
  background-color: #fff;
  bottom: 0px;
  padding: 32px 18px;
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  font-weight: 500;
  animation: child-slide-css 0.3s ease;
}
@media print, screen and (min-width: 40em) {
  .header__main_menu_child {
    padding: 80px 38px 32px 38px;
  }
}
@media print, screen and (min-width: 64em) {
  .header__main_menu_child {
    bottom: 0px;
    padding: 0 74px 80px 74px;
    color: #fff;
    left: 50%;
    width: 50%;
    background-color: transparent;
  }
}
.header__main_menu_child ul {
  background: #fff;
}
@media print, screen and (min-width: 64em) {
  .header__main_menu_child ul {
    background: transparent;
  }
}
.header__main_menu_child li {
  padding-top: 0px;
  padding-bottom: 0px;
}
.header__main_menu_child li:not(:first-child) {
  margin-top: 5px;
}
.header__main_menu_child a:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.header__main_menu_child div {
  position: relative;
  border-bottom: 1px solid #d1d0d0;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.header__main_menu_child div button {
  position: absolute;
  top: 5px;
}
.header__main_menu_child div button:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.header__main_menu_child div button span {
  left: 2px;
  transform: rotate(225deg);
  position: relative;
}
.header__main_menu_child div h1 {
  font-family: proxima-nova, sans-serif;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.44;
}
@media print, screen and (min-width: 64em) {
  .header__main_menu_child {
    position: absolute;
  }
  .header__main_menu_child div {
    display: none;
  }
  .header__main_menu_child a:focus {
    outline: 2px dotted #fff;
  }
}

@keyframes child-slide-css {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
header {
  height: 68px;
}
@media print, screen and (min-width: 64em) {
  header {
    height: 82px;
  }
}
header .top-menu {
  display: flex;
  flex-direction: column;
}
header .caret-wrap {
  position: absolute;
  top: 5px;
  width: 17px;
  height: 17px;
  right: 0;
}
header .caret-wrap:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
@media print, screen and (min-width: 64em) {
  header .caret-wrap:focus {
    outline: 2px dotted #fff;
    outline-offset: 4px;
  }
}
header span.caret {
  position: relative;
  right: 2px;
  display: block;
  width: 12px;
  height: 12px;
  border-top: 2px solid #c41230;
  border-right: 2px solid #c41230;
  transform: rotate(45deg);
}
@media print, screen and (min-width: 64em) {
  header span.caret {
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
  }
}
header .sub-menu-carat {
  top: 3px;
  left: 5%;
  width: 8.4px;
  height: 8.4px;
  border-right: 2px solid #c41230;
  border-bottom: 2px solid #c41230;
  transform: translateY(-70%) rotate(45deg);
}
@media print, screen and (min-width: 64em) {
  header .sub-menu-carat {
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
  }
}
header .sub-menu-carat-wrapper {
  position: relative;
  display: inline-block;
  top: 3px;
  left: 5%;
  width: 15px;
  height: 8.4px;
}
header .slide-fade-enter-active {
  transition: all 0.3s ease;
}
header .slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
header .slide-fade-enter,
header .slide-fade-leave-to {
  transform: translateY(-30px);
  opacity: 0;
}

.top-menu-wrapper {
  position: fixed;
  width: 100%;
  z-index: 101;
  display: flex;
  flex-direction: row;
  align-content: center;
  background-color: #c41230;
}
@media screen and (min-width: 31.25em) {
  .top-menu-wrapper {
    top: 0;
  }
}
@media print, screen and (min-width: 40em) {
  .top-menu-wrapper {
    top: 0;
  }
}
@media screen and (min-width: 52.125em) {
  .top-menu-wrapper {
    top: 0;
  }
}
@media print, screen and (min-width: 64em) {
  .top-menu-wrapper {
    top: 0;
  }
}
.top-menu-wrapper button.trigger-menu {
  font-size: 10px;
  padding: 20px;
  background-color: #ad102a;
  order: 4;
}
@media print, screen and (min-width: 64em) {
  .top-menu-wrapper button.trigger-menu {
    order: 1;
  }
}
.top-menu-wrapper button.trigger-menu:before {
  content: "";
  background: url("../svgs/functional/icon-menu-rest.svg") no-repeat scroll 0 0 transparent;
  width: 35px;
  height: 35px;
  display: inline-block;
}
.top-menu-wrapper button.trigger-menu:focus:before, .top-menu-wrapper button.trigger-menu:hover:before {
  background: url("../svgs/functional/icon-menu-hover.svg") no-repeat scroll 0 0 transparent;
}
.top-menu-wrapper button.trigger-menu:focus {
  border: 2px dotted #fff;
  outline: none;
}
.top-menu-wrapper button.trigger-menu.close:before {
  background: url("../svgs/functional/icon-menu-active.svg") no-repeat scroll 0 0 transparent;
  background-position: center;
}
.top-menu-wrapper a.logo {
  padding: 10px 5px 10px 10px;
  order: 1;
  margin-top: 10px;
}
@media print, screen and (min-width: 64em) {
  .top-menu-wrapper a.logo {
    padding: 0;
    order: 2;
    margin-top: 0;
  }
}
.top-menu-wrapper a.logo img {
  height: 50px;
  max-width: none;
}
@media print, screen and (min-width: 64em) {
  .top-menu-wrapper a.logo img {
    padding: 20px 24px;
    height: 82px;
  }
}
.top-menu-wrapper a.logo:focus {
  outline: 2px dotted #fff;
  outline-offset: 4px;
  outline-offset: -3px;
}
.top-menu-wrapper a.regionalsLogo {
  padding: 0;
  margin-top: 0;
}
.top-menu-wrapper a.regionalsLogo img {
  padding: 0;
  height: 80px;
}
@media print, screen and (min-width: 64em) {
  .top-menu-wrapper a.regionalsLogo img {
    height: 82px;
  }
}
.top-menu-wrapper button.search-icon {
  font-size: 0;
  border-left: 1px solid #ad102a;
  padding: 20px;
  margin-left: auto;
  order: 3;
}
@media print, screen and (min-width: 64em) {
  .top-menu-wrapper button.search-icon {
    border-left: none;
    margin-right: 8px;
    margin-left: 0;
    order: 4;
  }
}
.top-menu-wrapper button.search-icon:before {
  content: "";
  background: url("../svgs/functional/icon-search-white.svg") no-repeat scroll center center transparent;
  width: 30px;
  height: 16px;
  display: inline-block;
}
@media print, screen and (min-width: 64em) {
  .top-menu-wrapper button.search-icon:before {
    width: 18px;
  }
}
.top-menu-wrapper button.search-icon:focus {
  outline: none;
}
.top-menu-wrapper button.search-icon:focus:before {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}
.top-menu-wrapper button.close-icon {
  font-size: 0;
  border-left: 1px solid #ad102a;
  padding: 20px;
  margin-left: auto;
  order: 3;
  outline: 0;
}
@media print, screen and (min-width: 64em) {
  .top-menu-wrapper button.close-icon {
    border-left: none;
    margin-right: 8px;
    margin-left: 0;
    order: 4;
  }
}
.top-menu-wrapper button.close-icon:before {
  content: "";
  background: url("../svgs/functional/icon-menu-white-close.svg") no-repeat scroll center center transparent;
  width: 30px;
  height: 16px;
  display: inline-block;
}
@media print, screen and (min-width: 64em) {
  .top-menu-wrapper button.close-icon:before {
    width: 18px;
  }
}
.top-menu-wrapper button.close-icon:focus:before {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}
.top-menu-wrapper .header__utility_menu {
  margin-left: auto;
  padding: 0 32px;
  display: none;
}
@media print, screen and (min-width: 64em) {
  .top-menu-wrapper .header__utility_menu {
    display: block;
    padding-right: 0;
    order: 2;
  }
}
.top-menu-wrapper .header__utility_menu #header__utility_menu__heading {
  display: none;
}
.top-menu-wrapper .header__utility_menu ul > li {
  display: inline-block;
  padding: 32px 20px;
  position: relative;
}
@media screen and (min-width: 75em) {
  .top-menu-wrapper .header__utility_menu ul > li {
    padding: 32px 12px;
  }
}
.top-menu-wrapper .header__utility_menu ul > li a,
.top-menu-wrapper .header__utility_menu ul > li button {
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: 1px;
  color: #fff;
  text-transform: uppercase;
  padding: 2px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  transition-property: border-color;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.top-menu-wrapper .header__utility_menu ul > li a:hover,
.top-menu-wrapper .header__utility_menu ul > li button:hover {
  border-color: #fff;
}
.top-menu-wrapper .header__utility_menu ul > li a:focus,
.top-menu-wrapper .header__utility_menu ul > li button:focus {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}
.top-menu-wrapper .header__utility_menu ul > li a[aria-expanded=true] .sub-menu-carat,
.top-menu-wrapper .header__utility_menu ul > li button[aria-expanded=true] .sub-menu-carat {
  transform: translateY(-20%) rotate(224deg);
}
.top-menu-wrapper .header__utility_menu ul > li > ul {
  width: 200px;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 99;
  pointer-events: none;
  transition: opacity 0.2s ease, visibility 0.2s ease;
  background-color: #c41230;
  padding: 10px 0;
}
.top-menu-wrapper .header__utility_menu ul > li > ul li {
  padding: 4px 20px;
  display: block;
}
.top-menu-wrapper .header__utility_menu ul > li:hover > ul {
  pointer-events: auto;
}

.big-menu-wrapper {
  position: fixed;
  top: 68px;
  width: 100%;
  z-index: 100;
  background-color: #fff;
  overflow: auto;
  height: calc(100vh - 68px);
}
.big-menu-wrapper #header__main_menu_desktop__heading {
  display: none;
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper {
    top: 82px;
    height: auto;
    max-height: calc(100vh - 83px);
  }
}
.big-menu-wrapper .big-menu--inner {
  padding: 32px 0 80px;
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .big-menu-wrapper .big-menu--inner {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .big-menu--inner {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .big-menu-wrapper .big-menu--inner {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .big-menu-wrapper .big-menu--inner {
    width: 90%;
    max-width: 1404px;
  }
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .big-menu--inner {
    max-width: none;
    margin: 0;
    align-items: stretch;
    border-top: 1px solid #ad102a;
    background-color: #c41230;
    padding: 40px 56px 0 56px;
    width: 100%;
  }
}
@media screen and (min-width: 75em) {
  .big-menu-wrapper .big-menu--inner {
    padding: 40px 60px 0 60px;
  }
}
@media screen and (min-width: 90em) {
  .big-menu-wrapper .big-menu--inner {
    padding: 40px 60px 0 60px;
  }
}
.big-menu-wrapper .menu-container {
  width: 100%;
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .menu-container {
    display: flex;
    justify-content: space-between;
    width: 66.66667%;
    position: relative;
  }
}
.big-menu-wrapper .menu-container .main-slide-enter-active {
  transition: all 0.3s ease;
}
.big-menu-wrapper .menu-container .main-slide-leave-active {
  transition: all 0s ease;
}
.big-menu-wrapper .menu-container .main-slide-enter,
.big-menu-wrapper .menu-container .main-slide-leave-to {
  transform: translateX(-100px);
  opacity: 0;
}
.big-menu-wrapper .menu-container .main-menu-inner.not-active-mobile {
  display: none;
}
@media print, screen and (min-width: 40em) {
  .big-menu-wrapper .menu-container .main-menu-inner {
    padding-top: 40px;
  }
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .menu-container .main-menu-inner {
    padding: 0px 48px 80px 0;
    width: 50%;
    border-right: 1px solid #ad102a;
  }
  .big-menu-wrapper .menu-container .main-menu-inner.not-active-mobile {
    display: block;
  }
}
@media screen and (min-width: 75em) {
  .big-menu-wrapper .menu-container .main-menu-inner {
    padding: 0px 52px 80px 0;
  }
}
@media screen and (min-width: 90em) {
  .big-menu-wrapper .menu-container .main-menu-inner {
    padding: 0px 60px 80px 0;
  }
}
.big-menu-wrapper .menu-container .main-menu-inner .header__main_menu {
  border-bottom: 1px solid #d1d0d0;
  padding-bottom: 30px;
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .menu-container .main-menu-inner .header__main_menu {
    border: none;
    padding-bottom: 0;
  }
}
.big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li {
  padding: 8px 0;
}
.big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li:last-child {
  margin-bottom: 0;
}
.big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li:focus {
    outline: 2px dotted #fff;
    outline-offset: 4px;
  }
}
.big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li a.HeaderMainMenu__link {
  font-size: 18px;
  text-transform: uppercase;
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  /* display: block; */
}
.big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li a.HeaderMainMenu__link:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li a.HeaderMainMenu__link {
    text-transform: inherit;
    color: #fff;
    font-family: freight-text-pro, serif;
    font-size: 28px;
    font-weight: 300;
    line-height: 1.1;
    letter-spacing: 0.75px;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    transition-property: border-color;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    /* &.active,
      &:hover {
        opacity: 0.75;
      } */
    /* &.not-active {
        opacity: 0.5;
      } */
  }
  .big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li a.HeaderMainMenu__link:focus {
    outline: 2px dotted #fff;
    outline-offset: 4px;
  }
  .big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li a.HeaderMainMenu__link:active, .big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li a.HeaderMainMenu__link:hover {
    border-bottom: 1px solid white;
    outline: none;
  }
  .big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li a.HeaderMainMenu__link:after {
    display: none;
  }
}
.big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li a.HeaderMainMenu__link a.caret-wrap {
  display: none;
}
.big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li button.expander-wrap {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 35px;
  height: 35px;
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li button.expander-wrap {
    right: 0px;
    top: 20%;
  }
}
@media screen and (min-width: 75em) {
  .big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li button.expander-wrap {
    right: 0px;
    top: 20%;
  }
}
@media screen and (min-width: 90em) {
  .big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li button.expander-wrap {
    right: 0px;
    top: 20%;
  }
}
.big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li button.expander-wrap:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li button.expander-wrap:focus {
    top: 20%;
    right: 0;
    outline: 2px dotted #fff;
    outline-offset: 4px;
  }
}
.big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li span.expander-plus {
  position: relative;
  display: block;
  width: 12px;
  height: 12px;
}
.big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li span.expander-plus:before {
  content: "";
  background-color: #c41230;
  width: 12px;
  height: 2px;
  position: absolute;
  top: 5px;
  left: 12px;
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li span.expander-plus:before {
    background-color: #fff;
  }
}
.big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li span.expander-plus:after {
  content: "";
  background-color: #c41230;
  width: 2px;
  height: 12px;
  position: absolute;
  top: 0px;
  left: 17px;
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li span.expander-plus:after {
    background-color: #fff;
  }
}
.big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li span.expander-minus {
  position: relative;
  display: block;
  width: 12px;
  height: 12px;
}
.big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li span.expander-minus:before {
  content: "";
  background-color: #c41230;
  width: 12px;
  height: 2px;
  position: absolute;
  top: 5px;
  left: 12px;
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .menu-container .main-menu-inner .header__main_menu ul li span.expander-minus:before {
    background-color: #fff;
  }
}
.big-menu-wrapper .menu-container .main-menu-children-inner {
  width: 100%;
  border-bottom: 1px solid #d1d0d0;
}
.big-menu-wrapper .menu-container .main-menu-children-inner .child-slide-enter-active {
  transition: all 0.3s ease;
}
.big-menu-wrapper .menu-container .main-menu-children-inner .child-slide-leave-active {
  transition: all 0s ease;
}
.big-menu-wrapper .menu-container .main-menu-children-inner .child-slide-enter,
.big-menu-wrapper .menu-container .main-menu-children-inner .child-slide-leave-to {
  transform: translateX(100px);
  opacity: 0;
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .menu-container .main-menu-children-inner {
    border-left: 1px solid #edece2;
    padding: 80px 74px;
    width: 50%;
    border-bottom: none;
  }
  .big-menu-wrapper .menu-container .main-menu-children-inner.active {
    border-left: 1px solid #edece2;
  }
}
.big-menu-wrapper .menu-container .main-menu-children-inner .header__main_menu_child {
  display: block;
  margin-bottom: 30px;
}
.big-menu-wrapper .menu-container .main-menu-children-inner .header__main_menu_child div {
  position: relative;
  border-bottom: 1px solid #d1d0d0;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.big-menu-wrapper .menu-container .main-menu-children-inner .header__main_menu_child div button {
  position: absolute;
  top: 5px;
}
.big-menu-wrapper .menu-container .main-menu-children-inner .header__main_menu_child div button:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.big-menu-wrapper .menu-container .main-menu-children-inner .header__main_menu_child div button span {
  left: 2px;
  transform: rotate(225deg);
  position: relative;
}
.big-menu-wrapper .menu-container .main-menu-children-inner .header__main_menu_child div h1 {
  font-family: proxima-nova, sans-serif;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.44;
}
@media print, screen and (min-width: 40em) {
  .big-menu-wrapper .menu-container .main-menu-children-inner .header__main_menu_child {
    padding-top: 80px;
  }
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .menu-container .main-menu-children-inner .header__main_menu_child {
    position: absolute;
    padding-top: 0;
  }
  .big-menu-wrapper .menu-container .main-menu-children-inner .header__main_menu_child div {
    display: none;
  }
}
.big-menu-wrapper .menu-container .main-menu-children-inner ul li {
  transition: opacity 0.2s ease, visibility 0.2s ease;
  margin-top: 19px;
  position: relative;
}
.big-menu-wrapper .menu-container .main-menu-children-inner ul li button {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: 0.25px;
  padding: 2px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  transition-property: border-color;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.big-menu-wrapper .menu-container .main-menu-children-inner ul li button:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .menu-container .main-menu-children-inner ul li button {
    color: #fff;
    line-height: 0.94;
    letter-spacing: 0.25px;
  }
  .big-menu-wrapper .menu-container .main-menu-children-inner ul li button:hover {
    border-color: #fff;
  }
  .big-menu-wrapper .menu-container .main-menu-children-inner ul li button:focus {
    outline: 2px dotted #fff;
    outline-offset: 4px;
  }
  .big-menu-wrapper .menu-container .main-menu-children-inner ul li button:after {
    display: none;
  }
}
.big-menu-wrapper .menu-container .main-menu-children-inner ul li:first-child {
  margin-top: 0;
}
.big-menu-wrapper .other-menus-container {
  width: 100%;
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .other-menus-container {
    border-left: 1px solid #edece2;
    padding: 0px 60px 80px 74px;
    margin-left: auto;
    width: 33.33333%;
  }
  .big-menu-wrapper .other-menus-container .header__utility_menu {
    display: none;
  }
}
.big-menu-wrapper .other-menus-container h2 {
  text-transform: uppercase;
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.67;
  letter-spacing: 1.5px;
  text-align: center;
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .other-menus-container h2 {
    color: #fff;
    text-align: left;
  }
}
.big-menu-wrapper .other-menus-container .header__secondary_menu {
  display: none;
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .other-menus-container .header__secondary_menu {
    display: block;
  }
}
.big-menu-wrapper .other-menus-container .header__secondary_menu ul li {
  margin-top: 19px;
}
.big-menu-wrapper .other-menus-container .header__secondary_menu ul li button,
.big-menu-wrapper .other-menus-container .header__secondary_menu ul li a {
  color: #fff;
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.44;
  padding: 2px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  transition-property: border-color;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.big-menu-wrapper .other-menus-container .header__secondary_menu ul li button:hover,
.big-menu-wrapper .other-menus-container .header__secondary_menu ul li a:hover {
  border-color: #fff;
}
.big-menu-wrapper .other-menus-container .header__secondary_menu ul li button:focus,
.big-menu-wrapper .other-menus-container .header__secondary_menu ul li a:focus {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}
.big-menu-wrapper .other-menus-container .header__social_menu {
  padding-top: 30px;
}
.big-menu-wrapper .other-menus-container .header__social_menu ul {
  flex-wrap: wrap;
}
.big-menu-wrapper .other-menus-container .header__social_menu .base-cta-social {
  fill: #c41230;
  border-color: #c41230;
  background-color: #c41230;
}
.big-menu-wrapper .other-menus-container .header__social_menu .base-cta-social:hover, .big-menu-wrapper .other-menus-container .header__social_menu .base-cta-social:focus {
  background-color: #c41230;
  fill: #fff;
}
.big-menu-wrapper .other-menus-container .header__social_menu .base-cta-social:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
@media print, screen and (min-width: 64em) {
  .big-menu-wrapper .other-menus-container .header__social_menu {
    padding-top: 50px;
  }
  .big-menu-wrapper .other-menus-container .header__social_menu .base-cta-social {
    fill: #fff;
    border-color: #fff;
  }
  .big-menu-wrapper .other-menus-container .header__social_menu .base-cta-social:hover, .big-menu-wrapper .other-menus-container .header__social_menu .base-cta-social:focus {
    /*background-color: color(white);
    fill: color(red);*/
    border: 2px solid #ccc9b8;
  }
  .big-menu-wrapper .other-menus-container .header__social_menu .base-cta-social:focus {
    outline: 2px dotted #fff;
    outline-offset: 4px;
  }
}
.big-menu-wrapper .other-menus-container .header__utility_menu {
  padding-top: 30px;
}
.big-menu-wrapper .other-menus-container .header__utility_menu .utility-slide-enter-active {
  transition: all 0.3s ease;
}
.big-menu-wrapper .other-menus-container .header__utility_menu .utility-slide-leave-active {
  transition: all 0s ease;
}
.big-menu-wrapper .other-menus-container .header__utility_menu .utility-slide-enter,
.big-menu-wrapper .other-menus-container .header__utility_menu .utility-slide-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
.big-menu-wrapper .other-menus-container .header__utility_menu h2 {
  display: none;
}
.big-menu-wrapper .other-menus-container .header__utility_menu ul li {
  margin-top: 19px;
  position: relative;
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.big-menu-wrapper .other-menus-container .header__utility_menu ul li a,
.big-menu-wrapper .other-menus-container .header__utility_menu ul li button {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition-property: border-color;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  display: block;
}
.big-menu-wrapper .other-menus-container .header__utility_menu ul li a:hover,
.big-menu-wrapper .other-menus-container .header__utility_menu ul li button:hover {
  border-color: #c41230;
}
.big-menu-wrapper .other-menus-container .header__utility_menu ul li a:focus,
.big-menu-wrapper .other-menus-container .header__utility_menu ul li button:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.big-menu-wrapper .other-menus-container .header__utility_menu ul li .caret {
  position: absolute;
  top: 0;
  right: 0;
  width: 17px;
  height: 17px;
}
.big-menu-wrapper .other-menus-container .header__utility_menu ul li span.caret.open {
  transform: rotate(-45deg);
  right: 2px;
  top: 5px;
}
.big-menu-wrapper .other-menus-container .header__utility_menu ul li span.caret.close {
  transform: rotate(135deg);
  top: -3px;
}
.big-menu-wrapper .other-menus-container .header__utility_menu ul li ul.sub-menu {
  padding: 0px 10px;
}

.search-menu {
  position: fixed;
  top: 168px;
  left: 0px;
  width: 100%;
  z-index: 100;
  background-color: #fff;
  padding-top: 13px;
  padding-bottom: 23px;
}
@media print, screen and (min-width: 64em) {
  .search-menu {
    top: 82px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.search-menu .serch-menu-wrapper {
  position: relative;
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .search-menu .serch-menu-wrapper {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .search-menu .serch-menu-wrapper {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .search-menu .serch-menu-wrapper {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .search-menu .serch-menu-wrapper {
    width: 90%;
    max-width: 1404px;
  }
}
.search-menu .serch-menu-wrapper .HeaderSearchMenu__submit {
  position: absolute;
  top: 50%;
  right: 0px;
  width: 10px;
  height: 10px;
  border-top: 2px solid #c41230;
  border-right: 2px solid #c41230;
  transform: translateY(-50%) rotate(45deg);
}
.search-menu .serch-menu-wrapper .HeaderSearchMenu__submit:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.search-menu input {
  width: calc(100% - 15px);
  padding: 10px 0;
  font-size: 32px;
  font-weight: 200;
  letter-spacing: 1px;
  outline: none;
  border: 0;
  border-bottom: 2px solid #ccc9b8;
  /* clears the 'X' from Internet Explorer */
  /* clears the 'X' from Chrome */
}
@media print, screen and (min-width: 64em) {
  .search-menu input {
    font-size: 36px;
    padding: 16px 0;
  }
}
.search-menu input::-webkit-input-placeholder {
  color: #949494;
  letter-spacing: 2px;
  opacity: 1;
}
.search-menu input::-ms-input-placeholder {
  color: #949494;
  letter-spacing: 2px;
  opacity: 1;
}
.search-menu input::placeholder {
  color: #949494;
  letter-spacing: 2px;
  opacity: 1;
}
.search-menu input::-moz-placeholder {
  color: #949494;
  letter-spacing: 2px;
  opacity: 1;
}
.search-menu input:-moz-placeholder {
  color: #949494;
  letter-spacing: 2px;
  opacity: 1;
}
.search-menu input::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
.search-menu input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
.search-menu input::-webkit-search-decoration, .search-menu input::-webkit-search-cancel-button, .search-menu input::-webkit-search-results-button, .search-menu input::-webkit-search-results-decoration {
  display: none;
}

.top-menu--overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(35, 31, 32, 0.8);
  z-index: 99;
}

.top-menu--overlay-white {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 99;
}

.utility-dropdown--small {
  width: 100%;
  text-align: left;
}
.utility-dropdown--small .caret {
  width: 12px !important;
  height: 12px !important;
}

.hide-element {
  left: -10000px !important;
  top: -10000px !important;
}

.haslock:after {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  background-image: url(https://miamioh.edu/_hannonhill/_files/svgs/white/lock1_black.svg);
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 5px;
}
@media print, screen and (min-width: 64em) {
  .haslock:after {
    background-image: url(https://miamioh.edu/_hannonhill/_files/svgs/white/lock1.svg);
  }
}

.hero-primary {
  position: relative;
  overflow: hidden;
  min-height: 75vh;
  padding-top: 80px;
  padding-bottom: 80px;
  align-items: center;
}
@media print, screen and (min-width: 64em) {
  .hero-primary {
    min-height: 625px;
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.hero-primary__background-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #c41230;
  z-index: -1;
}

.hero-primary__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-primary__inner {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .hero-primary__inner {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .hero-primary__inner {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .hero-primary__inner {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .hero-primary__inner {
    width: 90%;
    max-width: 1404px;
  }
}
@media print, screen and (min-width: 40em) {
  .hero-primary__inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .hero-primary__inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .hero-primary__inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .hero-primary__inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.hero-primary__content {
  background-color: rgba(0, 0, 0, 0.4);
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  position: relative;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
@media print, screen and (min-width: 40em) {
  .hero-primary__content {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .hero-primary__content {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .hero-primary__content {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .hero-primary__content {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 40em) {
  .hero-primary__content {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
}
@media screen and (min-width: 90em) {
  .hero-primary__content {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
}

.hero-primary__badge {
  position: absolute;
  top: 0;
  left: 54px;
  display: block;
  width: 35px;
  height: 35px;
  transform: translate(-72px, 12px);
}
.hero-primary__badge::after {
  position: absolute;
  top: 130%;
  left: 50%;
  display: block;
  width: 1px;
  height: 1000px;
  background-color: #fff;
  content: "";
}
@media print, screen and (min-width: 64em) {
  .hero-primary__badge {
    width: 50px;
    height: 50px;
    transform: translate(-80px, 22px);
  }
}

.hero-primary__text {
  padding-left: 30px;
}

.hero-primary__video-button {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 1;
  width: 40px;
  height: 40px;
  padding: 2px;
  border: 2px solid #fff;
  border-radius: 50%;
  background-color: rgba(35, 31, 32, 0.4);
  fill: #fff;
}
@media print, screen and (min-width: 64em) {
  .hero-primary__video-button {
    right: 30px;
    bottom: 30px;
    width: 40px;
    height: 40px;
  }
}
.hero-primary__video-button::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 50%;
  transition-property: border-color;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  content: "";
}
.hero-primary__video-button:hover::before {
  border-color: #fff;
}
.hero-primary__video-button:focus {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}

.hero-primary__video-button-icon--pause {
  width: 100%;
  display: none;
}
.hero-primary__video-button.isPlaying .hero-primary__video-button-icon--pause {
  display: block;
}

.hero-primary__video-button-icon--play {
  width: 100%;
  display: block;
}
.hero-primary__video-button.isPlaying .hero-primary__video-button-icon--play {
  display: none;
}

.hero-secondary {
  background-color: #c41230;
  background-image: url("../images/illustrations/illustration-duotone-red.png");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  padding-top: 60px;
  padding-bottom: 60px;
}
@media print, screen and (min-width: 64em) {
  .hero-secondary {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}
.hero-secondary .hero-secondary__inner {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .hero-secondary .hero-secondary__inner {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .hero-secondary .hero-secondary__inner {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .hero-secondary .hero-secondary__inner {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .hero-secondary .hero-secondary__inner {
    width: 90%;
    max-width: 1404px;
  }
}
.hero-secondary .hero-secondary__inner .base-lockup__heading {
  color: #fff;
}

.hero-secondary__breadcrumbs {
  z-index: 1;
  transform: translate(0px, 60px);
}
@media print, screen and (min-width: 40em) {
  .hero-secondary__breadcrumbs {
    width: auto;
  }
}
@media print, screen and (min-width: 64em) {
  .hero-secondary__breadcrumbs {
    position: relative;
    max-width: 75rem;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-flow: row wrap;
    margin-right: 0.625rem;
    margin-left: 0.625rem;
    flex: 1;
    margin-right: 1.125rem;
    margin-left: 1.125rem;
    transform: translate(0px, 120px);
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .hero-secondary__breadcrumbs {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .hero-secondary__breadcrumbs {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .hero-secondary__breadcrumbs {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .hero-secondary__breadcrumbs {
    width: 90%;
    max-width: 1404px;
  }
}
@media screen and (min-width: 75em) {
  .hero-secondary__breadcrumbs {
    width: 100%;
  }
}
.hero-secondary__breadcrumbs .breadcrumbs {
  height: 41px;
  justify-content: flex-start;
  width: auto;
}
@media print, screen and (min-width: 64em) {
  .hero-secondary__breadcrumbs .breadcrumbs {
    position: absolute;
    top: -20px;
    left: 2vw;
    width: auto;
  }
}
.inline-video {
  padding-top: 80px;
  padding-bottom: 40px;
  background: -webkit-linear-gradient(to right, #faf9f7 60%, #fff 60%);
  background: -o-linear-gradient(to right, #faf9f7 60%, #fff 60%);
  background: -moz-linear-gradient(to right, #faf9f7 60%, #fff 60%);
  background: linear-gradient(to right, #faf9f7 60%, #fff 60%);
}
@media print, screen and (min-width: 40em) {
  .inline-video {
    padding-bottom: 60px;
  }
}
@media print, screen and (min-width: 64em) {
  .inline-video {
    padding-bottom: 80px;
  }
}

.inline-video__container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  position: relative;
  z-index: 1;
}
@media print, screen and (min-width: 40em) {
  .inline-video__container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .inline-video__container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .inline-video__container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .inline-video__container {
    width: 90%;
    max-width: 1404px;
  }
}
@media print, screen and (min-width: 64em) {
  .inline-video__container {
    padding-left: 80px;
    padding-right: 80px;
  }
}

.inline-video__inner {
  position: relative;
  width: 100%;
}
@media print, screen and (min-width: 64em) {
  .inline-video__inner {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .inline-video__inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .inline-video__inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .inline-video__inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .inline-video__inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.inline-video__background {
  position: relative;
  width: 100%;
  height: 270px;
}
@media print, screen and (min-width: 40em) {
  .inline-video__background {
    height: 370px;
  }
}
@media print, screen and (min-width: 64em) {
  .inline-video__background {
    position: absolute;
    height: 550px;
  }
}
.inline-video__background::before {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
  height: calc(100% - 75px);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: auto;
  content: "";
}
@media print, screen and (min-width: 64em) {
  .inline-video__background::before {
    height: calc(100% - 125px);
  }
}
.inline-video__background::after {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 75px;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
}
@media print, screen and (min-width: 64em) {
  .inline-video__background::after {
    height: 125px;
  }
}

.inline-video__header-container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  align-items: center;
  z-index: 1;
  padding-top: 40px;
}
@media print, screen and (min-width: 40em) {
  .inline-video__header-container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .inline-video__header-container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .inline-video__header-container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .inline-video__header-container {
    width: 90%;
    max-width: 1404px;
  }
}
@media print, screen and (min-width: 64em) {
  .inline-video__header-container {
    padding-top: 0;
    height: 550px;
  }
}

.inline-video__background-image {
  position: relative;
  z-index: -1;
  width: 100%;
  height: 370px;
  object-fit: cover;
}
@media print, screen and (min-width: 64em) {
  .inline-video__background-image {
    position: absolute;
    height: 550px;
  }
}

.inline-video__video-button {
  position: absolute;
  right: 50px;
  bottom: 50px;
  width: 40px;
  height: 40px;
  z-index: 1;
  padding: 2px;
  border: 2px solid #fff;
  border-radius: 50%;
  background-color: rgba(35, 31, 32, 0.4);
  fill: #fff;
}
.inline-video__video-button::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 50%;
  transition-property: border-color;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  content: "";
}
.inline-video__video-button:hover::before {
  border-color: #fff;
}
.inline-video__video-button:focus {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}

.inline-video__video-button-icon--pause {
  width: 100%;
  display: none;
}
.inline-video__video-button.isPlaying .inline-video__video-button-icon--pause {
  display: block;
}

.inline-video__video-button-icon--play {
  width: 100%;
  display: block;
}
.inline-video__video-button.isPlaying .inline-video__video-button-icon--play {
  display: none;
}

.inline-video__heading-inner {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
}
@media print, screen and (min-width: 40em) {
  .inline-video__heading-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .inline-video__heading-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .inline-video__heading-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .inline-video__heading-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .inline-video__heading-inner {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
}

.inline-video__header-icon-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 30px;
  height: 100%;
  overflow: hidden;
  transform: translate(-50px);
  display: none;
}
@media print, screen and (min-width: 64em) {
  .inline-video__header-icon-container {
    display: block;
    width: 40px;
    transform: translate(-77px);
  }
}

.inline-video__header-icon-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.inline-video__header-icon {
  position: relative;
  align-items: center;
  background-color: #faf9f7;
  margin-bottom: 20px;
  width: 40px;
  height: 40px;
}

.inline-video__content-icon {
  background-color: #faf9f7;
}

.inline-video__header-icon-line {
  position: relative;
  width: 1px;
  height: 100%;
  background-color: #c41230;
  transform: translate(0, 0);
}

.inline-video__heading {
  font-family: freight-display-pro, serif;
  font-size: 38px;
  line-height: 48px;
  font-weight: 400;
  color: #c41230;
  position: relative;
}
@media print, screen and (min-width: 64em) {
  .inline-video__heading {
    font-weight: 300;
    font-size: 44px;
    line-height: 50px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
@media print, screen and (min-width: 64em) {
  .inline-video__heading {
    position: absolute;
    bottom: 0;
    margin-bottom: 40px;
    color: #fff;
  }
}

/*===== HERO LANDING - EMBED FORM =====*/
.hero-landing__embed-form {
  flex: 0 0 100%;
  max-width: 100%;
  margin-left: 0;
  order: -1;
  -webkit-box-flex: 0;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  transform: translateY(-25px);
  -webkit-transform: translateY(-25px);
}

@media screen and (min-width: 64em) {
  .hero-landing__embed-form {
    flex: 0 0 25%;
    max-width: 25%;
    margin-left: 8.33333%;
    order: 1;
    -webkit-box-flex: 0;
    transform: translateY(-350px);
    -webkit-transform: translateY(-350px);
    height: 0;
  }
}
@media screen and (min-width: 90em) {
  .hero-landing__embed-form {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.hero-landing__embed-form-inner {
  background-color: #edece2;
  padding: 30px;
}

.hero-landing__embed-form-header {
  color: #c41230;
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.09375rem;
  padding-bottom: 20px;
}

.hero-landing__embed-form input[type=text], .hero-landing__embed-form input[type=email], .context-embed__context input[type=text], .context-embed__context input[type=email] {
  width: 100%;
  padding: 5px;
}

.hero-landing__embed-form div.form_page input[type=tel] {
  padding: 5px;
}

.hero-landing__embed-form select, .context-embed__context select {
  padding: 5px;
  margin-top: 5px;
}

.hero-landing__embed-form .form_label, .context-embed__context .form_label {
  font-size: 15px;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
}

.hero-landing__embed-form .form_button_submit, .context-embed__context .form_button_submit {
  background: #c41230;
  color: #fff;
  padding: 20px 40px;
  display: block;
  font-size: 18px;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.125rem;
}

.hero-landing__embed-form .form_button_submit:hover, .context-embed__context .form_button_submit:hover {
  background: #fff;
  color: #c41230;
}

.hero-landing__embed-form .form_button_submit:focus, .context-embed__context .form_button_submit:focus {
  outline: 2px dotted #c41230;
  outline-offset: 3px;
}

.form_question.form_select select {
  width: 100%;
}

/* Hide some sample form elements to make it shorter */
/*==== New Context Embed Widget ====*/
.context-embed {
  position: relative;
  background: #fff;
}

.context-embed__inner {
  display: flex;
  flex-flow: row wrap;
  flex: 1;
  max-width: 75rem;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}

.context-embed__context {
  position: relative;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}

@media screen and (min-width: 40em) {
  .context-embed__inner {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
  .context-embed__context {
    position: relative;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .context-embed__context.right {
    margin-left: 58.33333%;
  }
  .context-embed__context.left {
    margin-right: 58.33333%;
  }
  .context-embed__figure.left {
    right: 0;
  }
}
@media screen and (min-width: 64em) {
  .context-embed__inner {
    max-width: 75rem;
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
  .context-embed__context {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .context-embed__inner {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
  .context-embed__context {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .context-embed__inner {
    max-width: 1404px;
    width: 90%;
  }
}
.contact-int {
  width: 100%;
  margin-top: 28px;
  margin-bottom: 56px;
  background-color: #edece2;
}
@media print, screen and (min-width: 64em) {
  .contact-int {
    background-color: transparent;
  }
}

.contact-int__container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  padding-top: 40px;
  padding-left: 0.46875rem;
  padding-bottom: 20px;
  align-items: center;
  background-color: #edece2;
}
@media print, screen and (min-width: 40em) {
  .contact-int__container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .contact-int__container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .contact-int__container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .contact-int__container {
    width: 90%;
    max-width: 1404px;
  }
}
@media print, screen and (min-width: 64em) {
  .contact-int__container {
    padding-left: 40px;
  }
  .sidebar-layout__components .contact-int__container {
    padding-bottom: 40px;
  }
}

.contact-int__image-container {
  width: 100%;
  padding-bottom: 48px;
  padding-right: 0.46875rem;
}
@media screen and (min-width: 52.5625em) {
  .contact-int__image-container {
    display: flex;
    width: auto;
    padding-top: 60px;
    padding-right: 60px;
    padding-bottom: 60px;
  }
}
@media print, screen and (min-width: 64em) {
  .sidebar-layout__components .contact-int__image-container {
    margin-left: auto;
    margin-right: auto;
  }
}

.contact-int__image {
  width: auto;
  height: 100%;
  max-height: 270px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 52.5625em) {
  .contact-int__image {
    width: 270px;
    height: 270px;
  }
}

.contact-int__text {
  margin-right: auto;
}
@media screen and (min-width: 52.5625em) {
  .contact-int__text {
    margin-left: 0;
  }
}
@media print, screen and (min-width: 64em) {
  .sidebar-layout__components .contact-int__text {
    margin-left: auto;
    padding-bottom: 20px;
  }
}

.contact-int__label {
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.09375rem;
  text-transform: uppercase;
  color: #c41230;
  margin-bottom: 15px;
}

.contact-int__text-inner {
  position: relative;
}
.contact-int__text-inner a:focus {
  outline: 2px dotted #000;
  outline-offset: 4px;
}
@media screen and (min-width: 52.5625em) {
  .contact-int__text-inner {
    padding-right: 40px;
  }
}
.contact-int__text-inner .contact-int__email {
  text-decoration: underline;
}
.contact-int__text-inner .contact-int__phone {
  text-decoration: underline;
  font-variant: lining-nums;
}
.contact-int__text-inner .contact-int__link {
  text-decoration: underline;
}
.contact-int__text-inner .contact-int__description {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
}

.contact-int__heading {
  font-family: freight-display-pro, serif;
  font-size: 38px;
  line-height: 48px;
  font-weight: 400;
  font-weight: 100;
}
@media print, screen and (min-width: 64em) {
  .contact-int__heading {
    font-weight: 300;
    font-size: 44px;
    line-height: 50px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 52.5625em) {
  .contact-int__heading {
    font-size: 45px;
  }
}

.contact-int__address {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
  padding-top: 15px;
}

.contact-int__email {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
  display: block;
}

.contact-int__phone {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
  display: block;
}

.contact-int__link {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
  display: block;
  padding-top: 15px;
}

.contact-int__icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 33px;
}
@media screen and (min-width: 52.5625em) {
  .contact-int__icon {
    top: 20px;
    right: 20px;
  }
}

.context-image-int {
  width: 100%;
  margin-top: 28px;
  margin-bottom: 56px;
}

.context-image-int__container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .context-image-int__container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .context-image-int__container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .context-image-int__container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .context-image-int__container {
    width: 90%;
    max-width: 1404px;
  }
}

.context-image-int__image {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  height: 335px;
  object-fit: cover;
}
@media print, screen and (min-width: 40em) {
  .context-image-int__image {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .context-image-int__image {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .context-image-int__image {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .context-image-int__image {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .context-image-int__image {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    order: 2;
  }
}

.context-image-int__text {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .context-image-int__text {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .context-image-int__text {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .context-image-int__text {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .context-image-int__text {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .context-image-int__text {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
}

.context-image-int__lockup-container {
  padding-top: 32px;
  padding-bottom: 16px;
}
@media print, screen and (min-width: 64em) {
  .context-image-int__lockup-container {
    padding-top: 0;
  }
}
.context-image-int__lockup-container .base-lockup__copy--regular {
  margin-top: 15px;
}
.context-image-int__lockup-container .base-lockup__copy--regular h6 {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: 45px;
}
@media print, screen and (min-width: 64em) {
  .context-image-int__lockup-container .base-lockup__copy--regular h6 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.context-image-int__lockup-container .base-lockup__copy--regular h5 {
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 45px;
}
@media print, screen and (min-width: 64em) {
  .context-image-int__lockup-container .base-lockup__copy--regular h5 {
    font-size: 20px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #c41230;
  }
}
.context-image-int__lockup-container .base-lockup__copy--regular h4 {
  font-family: proxima-nova, sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 45px;
}
@media print, screen and (min-width: 64em) {
  .context-image-int__lockup-container .base-lockup__copy--regular h4 {
    font-size: 26px;
    line-height: 32px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.context-image-int__lockup-container .base-lockup__copy--regular h3 {
  font-family: freight-display-pro, serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 300;
  margin-bottom: 15px;
  margin-top: 45px;
}
@media print, screen and (min-width: 64em) {
  .context-image-int__lockup-container .base-lockup__copy--regular h3 {
    font-size: 38px;
    line-height: 44px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
.context-image-int__lockup-container .base-lockup__copy--regular h3.table-header {
  font-family: proxima-nova, sans-serif;
  font-size: 20px;
  line-height: 1.3;
  font-weight: 500;
  margin-bottom: 20px;
}
@media print, screen and (min-width: 64em) {
  .context-image-int__lockup-container .base-lockup__copy--regular h3.table-header {
    font-size: 24px;
    line-height: 1.25;
  }
}
.context-image-int__lockup-container .base-lockup__copy--regular p {
  margin-bottom: 20px;
}
.context-image-int__lockup-container .base-lockup__copy--regular a {
  color: #c41230;
  text-decoration: underline;
  font-style: normal;
  outline: 2px dotted transparent;
  outline-offset: 0;
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.context-image-int__lockup-container .base-lockup__copy--regular a:hover {
  text-decoration: none;
}
.context-image-int__lockup-container .base-lockup__copy--regular a:focus {
  text-decoration: none;
  outline: 2px dotted #c41230;
}
.context-image-int__lockup-container .base-lockup__copy--regular ul {
  margin-bottom: 20px;
}
.context-image-int__lockup-container .base-lockup__copy--regular ul li {
  padding: 8px 0 8px 30px;
}
.context-image-int__lockup-container .base-lockup__copy--regular ul li:before {
  content: "";
  background-image: url("../svgs/functional/icon-target--red.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 20px;
  margin-left: -30px;
}
.context-image-int__lockup-container .base-lockup__copy--regular ol {
  list-style: none;
  counter-reset: my-awesome-counter;
  margin-bottom: 20px;
}
.context-image-int__lockup-container .base-lockup__copy--regular ol li {
  padding: 8px 0;
  counter-increment: my-awesome-counter;
  padding-left: 36px;
}
.context-image-int__lockup-container .base-lockup__copy--regular ol li:before {
  content: counter(my-awesome-counter);
  font-family: proxima-nova, sans-serif;
  color: #c41230;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.75px;
  margin-right: 16px;
  text-align: center;
  display: inline-block;
  width: 20px;
  vertical-align: middle;
  margin-left: -36px;
}
@media print, screen and (min-width: 64em) {
  .context-image-int__lockup-container {
    margin-top: 24px;
  }
}

.feature-image-int {
  width: 100%;
  margin-top: 28px;
  margin-bottom: 56px;
}

.feature-image-int__container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .feature-image-int__container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .feature-image-int__container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .feature-image-int__container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .feature-image-int__container {
    width: 90%;
    max-width: 1404px;
  }
}

.feature-image-int__image {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  height: 200px;
  object-fit: cover;
}
@media print, screen and (min-width: 40em) {
  .feature-image-int__image {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .feature-image-int__image {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .feature-image-int__image {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .feature-image-int__image {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 40em) {
  .feature-image-int__image {
    height: 470px;
  }
}

.feature-image-int__text-container {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .feature-image-int__text-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .feature-image-int__text-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .feature-image-int__text-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .feature-image-int__text-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 40em) {
  .feature-image-int__text-container {
    flex: 0 0 87.5%;
    max-width: 87.5%;
    position: relative;
    margin-top: -48px;
  }
}

.feature-image-int__text-inner {
  padding: 24px;
  background-color: #c41230;
}
@media print, screen and (min-width: 40em) {
  .feature-image-int__text-inner {
    padding: 32px;
  }
}

.feature-image-int__heading {
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.09375rem;
  text-transform: uppercase;
  color: #fff;
}

.feature-image-int__copy {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
  font-size: 16px;
  padding-top: 8px;
  color: #fff;
}

.feature-image-2up {
  display: flex;
  flex-direction: column;
}
@media print, screen and (min-width: 64em) {
  .feature-image-2up {
    flex-direction: row;
  }
}

.feature-image-2up__card {
  position: relative;
  padding: 35px 25px;
  background-color: #c41230;
}
@media print, screen and (min-width: 64em) {
  .feature-image-2up__card {
    height: 600px;
    width: 100%;
    padding: 44px;
  }
}

.feature-image-2up__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.feature-image-2up__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.feature-image-2up__content {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  border: solid 1px #ccc9b8;
  padding: 24px;
}
@media print, screen and (min-width: 64em) {
  .feature-image-2up__content {
    padding: 52px;
  }
}

.feature-image-2up__heading {
  font-family: freight-display-pro, serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 300;
  color: #fff;
  padding-bottom: 15px;
}
@media print, screen and (min-width: 64em) {
  .feature-image-2up__heading {
    font-size: 38px;
    line-height: 44px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
@media print, screen and (min-width: 64em) {
  .feature-image-2up__heading {
    padding-bottom: 16px;
  }
}

.feature-image-2up__copy {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
  color: #fff;
  padding-bottom: 12px;
}
@media print, screen and (min-width: 40em) {
  .feature-image-2up__copy {
    padding-bottom: 40px;
  }
}
@media print, screen and (min-width: 64em) {
  .feature-image-2up__copy {
    padding-bottom: 40px;
  }
}
.feature-image-2up__copy a {
  color: #fff;
  text-decoration: underline;
  font-style: italic;
  outline: 2px dotted transparent;
  outline-offset: 0;
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.feature-image-2up__copy a:hover {
  text-decoration: none;
}
.feature-image-2up__copy a:focus {
  text-decoration: none;
  outline: 2px dotted #fff;
}
.feature-image-2up__copy h6 {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: 45px;
  color: #fff;
}
@media print, screen and (min-width: 64em) {
  .feature-image-2up__copy h6 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.feature-image-2up__copy h5 {
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 45px;
  color: #fff;
}
@media print, screen and (min-width: 64em) {
  .feature-image-2up__copy h5 {
    font-size: 20px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #c41230;
  }
}
.feature-image-2up__copy h4 {
  font-family: proxima-nova, sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 45px;
  color: #fff;
}
@media print, screen and (min-width: 64em) {
  .feature-image-2up__copy h4 {
    font-size: 26px;
    line-height: 32px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.feature-image-2up__copy h3 {
  font-family: freight-display-pro, serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 300;
  margin-bottom: 15px;
  margin-top: 45px;
  color: #fff;
}
@media print, screen and (min-width: 64em) {
  .feature-image-2up__copy h3 {
    font-size: 38px;
    line-height: 44px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
.feature-image-2up__copy h3.table-header {
  font-family: proxima-nova, sans-serif;
  font-size: 20px;
  line-height: 1.3;
  font-weight: 500;
  margin-bottom: 20px;
}
@media print, screen and (min-width: 64em) {
  .feature-image-2up__copy h3.table-header {
    font-size: 24px;
    line-height: 1.25;
  }
}
.feature-image-2up__copy p {
  margin-bottom: 20px;
}
.feature-image-2up__copy ul {
  margin-bottom: 20px;
}
.feature-image-2up__copy ul li {
  padding: 8px 0 8px 30px;
}
.feature-image-2up__copy ul li:before {
  content: "";
  background-image: url("../svgs/functional/icon-list-style--white.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 20px;
  margin-left: -30px;
}
.feature-image-2up__copy ol {
  list-style: none;
  counter-reset: my-awesome-counter;
  margin-bottom: 20px;
}
.feature-image-2up__copy ol li {
  padding: 8px 0;
  counter-increment: my-awesome-counter;
  padding-left: 36px;
}
.feature-image-2up__copy ol li:before {
  content: counter(my-awesome-counter);
  font-family: proxima-nova, sans-serif;
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.75px;
  margin-right: 16px;
  text-align: center;
  display: inline-block;
  width: 20px;
  vertical-align: middle;
  margin-left: -36px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .hero-landing__header-icon-line {
    transform: translate(0, 0);
  }
  .hero-landing__header-icon-container {
    position: static;
  }
}
.collection-links {
  margin-top: 60px;
  margin-bottom: 60px;
}
.collection-links.collection-links--white {
  background-color: #fff;
}
.collection-links.collection-links--white .collection-links-description {
  color: #231f20;
}
.collection-links.collection-links--white a.base-cta-text {
  color: #231f20;
}
.collection-links.collection-links--gold {
  background-color: #edece2;
  padding-top: 60px;
  padding-bottom: 60px;
  margin: 0;
}
.collection-links.collection-links--red {
  background-color: #c41230;
  padding-top: 60px;
  padding-bottom: 60px;
  margin: 0;
}
.collection-links.collection-links--red .base-lockup__copy {
  color: #fff;
}
.collection-links.collection-links--red a.base-cta-text {
  color: #fff;
}
.collection-links.collection-links--red a.base-cta-text:before {
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
}
.collection-links .collection-links__link-container {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  padding-right: 0;
}

.collection-links__container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .collection-links__container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-links__container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .collection-links__container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .collection-links__container {
    width: 90%;
    max-width: 1404px;
  }
}

.collection-links__heading-container {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-bottom: 30px;
}
@media print, screen and (min-width: 40em) {
  .collection-links__heading-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-links__heading-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .collection-links__heading-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .collection-links__heading-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.collection-links--white .collection-links__heading-container, .collection-links--gold .collection-links__heading-container {
  color: #c41230;
}
.collection-links--red .collection-links__heading-container {
  color: white;
}
@media print, screen and (min-width: 64em) {
  .collection-links__heading-container {
    flex: 0 0 100%;
    max-width: 100%;
    padding-bottom: 12px;
  }
}

.collection-links__description-container {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-bottom: 30px;
}
@media print, screen and (min-width: 40em) {
  .collection-links__description-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-links__description-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .collection-links__description-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .collection-links__description-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-links__description-container {
    padding-bottom: 60px;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
}

.collection-links__link {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .collection-links__link {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-links__link {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .collection-links__link {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .collection-links__link {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-links__link {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
}
.collection-links__link a {
  margin-top: 0;
  margin-bottom: 12px;
}

.collection-links-int {
  width: 100%;
  margin-top: 28px;
  margin-bottom: 56px;
}

.collection-links-int__container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .collection-links-int__container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-links-int__container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .collection-links-int__container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .collection-links-int__container {
    width: 90%;
    max-width: 1404px;
  }
}

.collection-links-int__heading-container {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .collection-links-int__heading-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-links-int__heading-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .collection-links-int__heading-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .collection-links-int__heading-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.collection-links-int__link {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .collection-links-int__link {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-links-int__link {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .collection-links-int__link {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .collection-links-int__link {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-links-int__link {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.cards-news {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #edece2;
}
@media print, screen and (min-width: 40em) {
  .cards-news {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-news {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.cards-news__cta-container {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-top: 28px;
  display: none;
}
@media print, screen and (min-width: 40em) {
  .cards-news__cta-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-news__cta-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-news__cta-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .cards-news__cta-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-news__cta-container {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    padding-top: 0px;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 24px;
    align-self: center;
  }
}

.cards-news__cta-container-footer {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-top: 0;
}
@media print, screen and (min-width: 40em) {
  .cards-news__cta-container-footer {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-news__cta-container-footer {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-news__cta-container-footer {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .cards-news__cta-container-footer {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-news__cta-container-footer {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    padding-top: 0px;
    justify-content: flex-end;
    padding-bottom: 60px;
    align-self: center;
    display: none;
  }
}

.cards-news__cards-container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  justify-content: center;
}
@media print, screen and (min-width: 40em) {
  .cards-news__cards-container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-news__cards-container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-news__cards-container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .cards-news__cards-container {
    width: 90%;
    max-width: 1404px;
  }
}

.cards-news__heading-container {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  align-self: center;
  justify-self: center;
  padding-bottom: 28px;
}
@media print, screen and (min-width: 40em) {
  .cards-news__heading-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-news__heading-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-news__heading-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .cards-news__heading-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-news__heading-container {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    padding-bottom: 28px;
  }
}

.cards-news__heading {
  font-family: freight-display-pro, serif;
  font-size: 38px;
  line-height: 48px;
  font-weight: 400;
  color: #c41230;
}
@media print, screen and (min-width: 64em) {
  .cards-news__heading {
    font-weight: 300;
    font-size: 44px;
    line-height: 50px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

.cards-news__card {
  position: relative;
  margin-bottom: 0;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .cards-news__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-news__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-news__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .cards-news__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.cards-news__card:last-child {
  margin-bottom: 0;
}
@media print, screen and (min-width: 64em) {
  .cards-news__card {
    margin-bottom: 0;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-left: 0%;
  }
}
.cards-news__card .cards-news__card-inner::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0;
  background-color: transparent;
  left: 0;
  bottom: 0;
  overflow: hidden;
  transition: height 0.2s ease-out;
}
.cards-news__card:hover {
  cursor: grab;
}
.cards-news__card:hover .cards-news__headline {
  border-bottom-color: #231f20;
}

.cards-news__card-link {
  display: block;
  width: 100%;
}

.cards-news__card-link:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}

.cards-news__image-container {
  position: relative;
  padding-bottom: 15px;
}

.cards-news__image {
  height: 220px;
  width: 100%;
  object-fit: cover;
}

.cards-news__card-inner {
  position: relative;
  padding-bottom: 10px;
}

.cards-news__category {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.04375rem;
  color: #c41230;
  font-style: italic;
  padding-bottom: 4px;
}

.cards-news__headline {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  display: inline;
  line-height: 1.5;
  color: #231f20;
  font-weight: 600;
  padding-bottom: 2px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  transition-property: border-bottom-color;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
@media print, screen and (min-width: 64em) {
  .cards-news__headline {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

.cards-news__divider {
  border-bottom: 1px solid #ccc9b8;
  margin-top: 28px;
  margin-bottom: 40px;
}
@media print, screen and (min-width: 64em) {
  .cards-news__divider {
    margin-top: 20px;
  }
}

.cards-events {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #c41230;
}
@media print, screen and (min-width: 40em) {
  .cards-events {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-events {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.cards-events__cards-container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .cards-events__cards-container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-events__cards-container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-events__cards-container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .cards-events__cards-container {
    width: 90%;
    max-width: 1404px;
  }
}

.cards-events__heading-container {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  align-self: center;
  justify-self: center;
  padding-bottom: 40px;
}
@media print, screen and (min-width: 40em) {
  .cards-events__heading-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-events__heading-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-events__heading-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .cards-events__heading-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-events__heading-container {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    padding-bottom: 60px;
  }
}

.cards-events__cta-container {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  display: none;
  padding-top: 28px;
}
@media print, screen and (min-width: 40em) {
  .cards-events__cta-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-events__cta-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-events__cta-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .cards-events__cta-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-events__cta-container {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    display: flex;
    justify-content: flex-end;
    padding-top: 0px;
    padding-bottom: 60px;
    align-self: center;
  }
}

.cards-events__cta-container-footer {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-top: 28px;
}
@media print, screen and (min-width: 40em) {
  .cards-events__cta-container-footer {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-events__cta-container-footer {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-events__cta-container-footer {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .cards-events__cta-container-footer {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-events__cta-container-footer {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    display: none;
    justify-content: flex-end;
    padding-top: 0px;
    padding-bottom: 60px;
    align-self: center;
  }
}

.cards-events__heading {
  font-family: freight-display-pro, serif;
  font-size: 38px;
  line-height: 48px;
  font-weight: 400;
  color: #fff;
}
@media print, screen and (min-width: 64em) {
  .cards-events__heading {
    font-weight: 300;
    font-size: 44px;
    line-height: 50px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

.cards-events__card-inner {
  position: relative;
  padding-bottom: 25px;
  border-bottom: 1px solid #ccc9b8;
}

.cards-events__inner {
  position: relative;
  display: block;
}

.cards-events__inner:focus {
  outline: none;
}
.cards-events__inner:focus .cards-events__card-inner {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}

.cards-events__card {
  position: relative;
  margin-bottom: 40px;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
.cards-events__card:last-child {
  margin-bottom: 0;
}
@media print, screen and (min-width: 40em) {
  .cards-events__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-events__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-events__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .cards-events__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-events__card {
    margin-bottom: 0;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-left: 0%;
  }
}
.cards-events__card .cards-events__card-inner::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0;
  background-color: transparent;
  left: 0;
  bottom: 0;
  overflow: hidden;
  transition: height 0.2s ease-out;
}
.cards-events__card:hover {
  cursor: grab;
}
.cards-events__card:hover .cards-events__headline {
  border-bottom-color: #fff;
}

.cards-events__image-container {
  position: relative;
  padding-bottom: 15px;
}

.cards-events__image {
  height: 200px;
  width: 100%;
  object-fit: cover;
  top: 0px;
}

.cards-events__date-container {
  height: 25px;
  background-color: #c41230;
  position: absolute;
  top: 10px;
  left: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cards-events__date {
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.09375rem;
  color: #fff;
  margin: 0;
  font-weight: 500;
}

.cards-events__time {
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.09375rem;
  text-transform: uppercase;
  color: #fff;
  padding-bottom: 8px;
}

.cards-events__headline {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  display: inline;
  line-height: 1.5;
  color: #fff;
  font-weight: 600;
  padding-bottom: 2px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  transition-property: border-bottom-color;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
@media print, screen and (min-width: 64em) {
  .cards-events__headline {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

.cards-events__divider {
  border-bottom: 1px solid #ccc9b8;
  margin-top: 28px;
  margin-bottom: 40px;
}
@media print, screen and (min-width: 64em) {
  .cards-events__divider {
    margin-top: 20px;
  }
}

.collection-links-callout {
  padding-bottom: 40px;
  position: relative;
  background-color: #c41230;
}
@media print, screen and (min-width: 40em) {
  .collection-links-callout {
    padding-bottom: 60px;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-links-callout {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.collection-links-callout .collection-links-callout__container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .collection-links-callout .collection-links-callout__container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-links-callout .collection-links-callout__container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .collection-links-callout .collection-links-callout__container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .collection-links-callout .collection-links-callout__container {
    width: 90%;
    max-width: 1404px;
  }
}
@media print, screen and (min-width: 40em) {
  .collection-links-callout .collection-links-callout__container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-links-callout .collection-links-callout__container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .collection-links-callout .collection-links-callout__container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .collection-links-callout .collection-links-callout__container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-links-callout .collection-links-callout__container .collection-links-callout__red-background {
    position: absolute;
    top: 80px;
    bottom: 80px;
    width: 50%;
    height: inherit;
    left: 0;
    background: #ad102a;
  }
}
.collection-links-callout .collection-links-callout__container .collection-links-callout__heading {
  position: relative;
  width: 100%;
  background-color: #ad102a;
  text-align: left;
  overflow: hidden;
  margin-bottom: 40px;
  padding: 4px 60px 60px !important;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  flex: auto;
  margin-right: -10px;
  margin-left: -10px;
  max-width: none;
}
@media print, screen and (min-width: 40em) {
  .collection-links-callout .collection-links-callout__container .collection-links-callout__heading {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-links-callout .collection-links-callout__container .collection-links-callout__heading {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .collection-links-callout .collection-links-callout__container .collection-links-callout__heading {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .collection-links-callout .collection-links-callout__container .collection-links-callout__heading {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-links-callout .collection-links-callout__container .collection-links-callout__heading {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    margin-bottom: 80px;
    margin-left: 0;
    margin-right: 0;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .collection-links-callout .collection-links-callout__container .collection-links-callout__heading {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .collection-links-callout .collection-links-callout__container .collection-links-callout__heading {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .collection-links-callout .collection-links-callout__container .collection-links-callout__heading {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .collection-links-callout .collection-links-callout__container .collection-links-callout__heading {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.collection-links-callout .collection-links-callout__container .collection-links-callout__heading .collection-links-callout__heading__icon {
  content: " ";
  position: absolute;
  top: 40px;
  left: 10px;
  width: 30px;
  height: 30px;
}
.collection-links-callout .collection-links-callout__container .collection-links-callout__heading .collection-links-callout__heading__icon.flip {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}
@media print, screen and (min-width: 64em) {
  .collection-links-callout .collection-links-callout__container .collection-links-callout__heading .collection-links-callout__heading__icon {
    width: 50px;
    height: 50px;
    top: 56px;
    left: 32px;
  }
}
.collection-links-callout .collection-links-callout__container .collection-links-callout__heading::after {
  content: " ";
  position: absolute;
  top: 90px;
  left: 20px;
  width: 1px;
  height: calc(100% - 90px);
  background-color: #edece2;
}
@media print, screen and (min-width: 64em) {
  .collection-links-callout .collection-links-callout__container .collection-links-callout__heading::after {
    left: 30px;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-links-callout .collection-links-callout__container .collection-links-callout__heading {
    padding: 80px 120px !important;
  }
  .collection-links-callout .collection-links-callout__container .collection-links-callout__heading::before {
    top: 80px;
    left: 30px;
  }
  .collection-links-callout .collection-links-callout__container .collection-links-callout__heading::after {
    top: 150px;
    left: 50px;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-links-callout .collection-links-callout__container .collection-links-callout__heading {
    padding: 60px 80px !important;
    margin-bottom: 0px;
  }
}
.collection-links-callout .collection-links-callout__container .collection-links-callout__heading .collection-links-callout__heading-inner {
  margin: 66px auto 0 auto;
}
.collection-links-callout .collection-links-callout__container .collection-links-callout__heading .collection-links-callout__heading-inner .collection-links-callout__heading-text {
  font-family: freight-display-pro, serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 300;
  color: #fff;
  margin-bottom: 15px;
}
@media print, screen and (min-width: 64em) {
  .collection-links-callout .collection-links-callout__container .collection-links-callout__heading .collection-links-callout__heading-inner .collection-links-callout__heading-text {
    font-size: 38px;
    line-height: 44px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-links-callout .collection-links-callout__container .collection-links-callout__heading .collection-links-callout__heading-inner .collection-links-callout__heading-text {
    margin-bottom: 20px;
  }
}
.collection-links-callout .collection-links-callout__container .collection-links-callout__heading .collection-links-callout__heading-inner .collection-links-callout__heading-copy {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0.015625rem;
  color: #fff;
}
@media print, screen and (min-width: 64em) {
  .collection-links-callout .collection-links-callout__container .collection-links-callout__heading .collection-links-callout__heading-inner .collection-links-callout__heading-copy {
    font-size: 24px;
    line-height: 36px;
  }
}
.collection-links-callout .collection-links-callout__container .collection-links-callout__inner {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  margin-left: auto;
}
@media print, screen and (min-width: 40em) {
  .collection-links-callout .collection-links-callout__container .collection-links-callout__inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-links-callout .collection-links-callout__container .collection-links-callout__inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .collection-links-callout .collection-links-callout__container .collection-links-callout__inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .collection-links-callout .collection-links-callout__container .collection-links-callout__inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-links-callout .collection-links-callout__container .collection-links-callout__inner {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .collection-links-callout .collection-links-callout__container .collection-links-callout__inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .collection-links-callout .collection-links-callout__container .collection-links-callout__inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .collection-links-callout .collection-links-callout__container .collection-links-callout__inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .collection-links-callout .collection-links-callout__container .collection-links-callout__inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.collection-links-callout .collection-links-callout__container .collection-links-callout__inner .collection-links-callout__subheading {
  font-family: proxima-nova, sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  color: #fff;
}
@media print, screen and (min-width: 64em) {
  .collection-links-callout .collection-links-callout__container .collection-links-callout__inner .collection-links-callout__subheading {
    font-size: 26px;
    line-height: 32px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-links-callout .collection-links-callout__container .collection-links-callout__inner nav .collection-links-callout__link-container a {
    font-size: 32px;
  }
}

.collection-links-image {
  position: relative;
  background-color: #edece2;
}

.collection-links-image__image {
  width: 100%;
  height: 372px;
  object-fit: cover;
}
@media print, screen and (min-width: 64em) {
  .collection-links-image__image {
    position: absolute;
    right: 0px;
    width: calc(50vw - 25px);
    min-height: 700px;
    height: 100%;
  }
}

.collection-links-image__container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .collection-links-image__container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-links-image__container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .collection-links-image__container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .collection-links-image__container {
    width: 90%;
    max-width: 1404px;
  }
}
@media print, screen and (min-width: 40em) {
  .collection-links-image__container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-links-image__container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .collection-links-image__container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .collection-links-image__container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-links-image__container {
    height: 700px;
  }
}

.collection-links-image__inner {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-top: 40px;
  padding-bottom: 40px;
}
@media print, screen and (min-width: 40em) {
  .collection-links-image__inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-links-image__inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .collection-links-image__inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .collection-links-image__inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 40em) {
  .collection-links-image__inner {
    padding-bottom: 60px;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-links-image__inner {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    padding-top: 80px;
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.collection-links-image__heading {
  font-family: proxima-nova, sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  color: #c41230;
}
@media print, screen and (min-width: 64em) {
  .collection-links-image__heading {
    font-size: 26px;
    line-height: 32px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

.cards-news-int {
  width: 100%;
  margin-top: 60px;
  margin-bottom: 60px;
}

.cards-news-int__cards-container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  flex-direction: column;
}
@media print, screen and (min-width: 40em) {
  .cards-news-int__cards-container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-news-int__cards-container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-news-int__cards-container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .cards-news-int__cards-container {
    width: 90%;
    max-width: 1404px;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-news-int__cards-container {
    flex-direction: row;
  }
}

.cards-news-int__heading-container {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-bottom: 40px;
}
@media print, screen and (min-width: 40em) {
  .cards-news-int__heading-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-news-int__heading-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-news-int__heading-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .cards-news-int__heading-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-news-int__heading-container {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    align-self: center;
    justify-self: center;
    padding-bottom: 60px;
  }
}

.cards-news-int__cta-container {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-bottom: 20px;
  order: 1;
}
@media print, screen and (min-width: 40em) {
  .cards-news-int__cta-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-news-int__cta-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-news-int__cta-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .cards-news-int__cta-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-news-int__cta-container {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    order: 0;
    padding-bottom: 60px;
    align-self: center;
  }
}

.cards-news-int__card {
  position: relative;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .cards-news-int__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-news-int__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-news-int__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .cards-news-int__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-news-int__card {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.cards-news-int__card .cards-news-int__card-inner {
  position: relative;
}
.cards-news-int__card .cards-news-int__card-inner::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0;
  background-color: transparent;
  left: 0;
  bottom: 0;
  overflow: hidden;
  transition: height 0.2s ease-out;
}
.cards-news-int__card .cards-news-int__card-inner:hover {
  cursor: grab;
}
.cards-news-int__card .cards-news-int__card-inner:hover .cards-news-int__headline {
  border-bottom-color: #231f20;
}

.cards-news-int__image {
  min-height: 200px;
  max-height: 220px;
  width: 100%;
  object-fit: cover;
  padding-bottom: 12px;
}
@media print, screen and (min-width: 64em) {
  .cards-news-int__image {
    height: 220px;
    width: 100%;
  }
}

.cards-news-int__category {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.04375rem;
  color: #c41230;
  font-style: italic;
  padding-bottom: 4px;
}

.cards-news-int__headline {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: #231f20;
  font-weight: 600;
  display: inline;
  line-height: 1.5;
  padding-bottom: 2px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  transition-property: border-bottom-color;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
@media print, screen and (min-width: 64em) {
  .cards-news-int__headline {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

.cards-news-int__divider {
  border-bottom: 1px solid #ccc9b8;
  margin-top: 28px;
  margin-bottom: 40px;
}
@media print, screen and (min-width: 64em) {
  .cards-news-int__divider {
    margin-top: 20px;
  }
}

.cards-image {
  padding-top: 32px;
  padding-bottom: 32px;
}
.cards-image.cards-image--red {
  background-color: #c41230;
}
@media print, screen and (min-width: 64em) {
  .cards-image.cards-image--red.cards-image--2 .cards-image__card-inner {
    background-color: #c41230;
  }
}
.cards-image.cards-image--warm {
  background-color: #faf9f7;
}
@media print, screen and (min-width: 64em) {
  .cards-image.cards-image--warm.cards-image--2 .cards-image__card-inner {
    background-color: #faf9f7;
  }
}
.cards-image.cards-image--white {
  background-color: #fff;
}
@media print, screen and (min-width: 64em) {
  .cards-image.cards-image--white.cards-image--2 .cards-image__card-inner {
    background-color: #fff;
  }
}
.cards-image.cards-image--tan {
  background-color: #edece2;
}
@media print, screen and (min-width: 64em) {
  .cards-image.cards-image--tan.cards-image--2 .cards-image__card-inner {
    background-color: #edece2;
  }
}

.cards-image__header-container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .cards-image__header-container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-image__header-container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-image__header-container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .cards-image__header-container {
    width: 90%;
    max-width: 1404px;
  }
}

.cards-image__header-inner {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  align-self: center;
  justify-self: center;
  padding-bottom: 12px;
}
@media print, screen and (min-width: 40em) {
  .cards-image__header-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-image__header-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-image__header-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .cards-image__header-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-image__header-inner {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    padding-bottom: 12px;
  }
}
.cards-image__header-inner .base-lockup__copy {
  margin-top: 15px;
}

.cards-image_header_cta-container {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-bottom: 12px;
}
@media print, screen and (min-width: 40em) {
  .cards-image_header_cta-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-image_header_cta-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-image_header_cta-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .cards-image_header_cta-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-image_header_cta-container {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    display: flex;
    justify-content: flex-end;
    padding-top: 0px;
    padding-bottom: 12px;
    align-self: center;
  }
}

.cards-image_footer_cta-container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-top: 28px;
}
@media print, screen and (min-width: 40em) {
  .cards-image_footer_cta-container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-image_footer_cta-container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-image_footer_cta-container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .cards-image_footer_cta-container {
    width: 90%;
    max-width: 1404px;
  }
}
@media print, screen and (min-width: 40em) {
  .cards-image_footer_cta-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-image_footer_cta-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-image_footer_cta-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .cards-image_footer_cta-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-image_footer_cta-container {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    display: none;
    justify-content: flex-end;
    padding-top: 0px;
    padding-bottom: 60px;
    align-self: center;
  }
}

.cards-image__header-heading {
  font-family: freight-display-pro, serif;
  font-size: 38px;
  line-height: 48px;
  font-weight: 400;
  color: #c41230;
  padding-bottom: 20px;
}
@media print, screen and (min-width: 64em) {
  .cards-image__header-heading {
    font-weight: 300;
    font-size: 44px;
    line-height: 50px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

.cards-image__header-subheading {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0.015625rem;
  color: #231f20;
}
@media print, screen and (min-width: 64em) {
  .cards-image__header-subheading {
    font-size: 24px;
    line-height: 36px;
  }
}

.cards-image__cta-container {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-bottom: 20px;
  order: 1;
  margin-top: -20px;
}
@media print, screen and (min-width: 40em) {
  .cards-image__cta-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-image__cta-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-image__cta-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .cards-image__cta-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-image__cta-container {
    flex: 0 0 25%;
    max-width: 25%;
    order: 0;
    padding-bottom: 60px;
    align-self: center;
  }
}

.cards-image__cards-container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  flex-direction: column;
}
@media print, screen and (min-width: 40em) {
  .cards-image__cards-container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-image__cards-container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-image__cards-container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .cards-image__cards-container {
    width: 90%;
    max-width: 1404px;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-image__cards-container {
    flex-direction: row;
  }
}

.cards-image__card {
  flex: 1 1 0px;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  min-width: 0;
  padding-top: 24px;
  padding-bottom: 24px;
}
@media print, screen and (min-width: 40em) {
  .cards-image__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-image__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .cards-image__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .cards-image__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.cards-image__card-image {
  height: 200px;
  width: 100%;
  object-fit: cover;
}
@media print, screen and (min-width: 40em) {
  .cards-image--2 .cards-image__card-image, .cards-image-multirow--2 .cards-image__card-image {
    height: 250px !important;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-image--2 .cards-image__card-image, .cards-image-multirow--2 .cards-image__card-image {
    height: 313px !important;
  }
}
@media print, screen and (min-width: 40em) {
  .cards-image--3 .cards-image__card-image, .cards-image-multirow--3 .cards-image__card-image {
    height: 320px;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-image--3 .cards-image__card-image, .cards-image-multirow--3 .cards-image__card-image {
    height: 200px;
  }
}
@media screen and (min-width: 90em) {
  .cards-image--3 .cards-image__card-image, .cards-image-multirow--3 .cards-image__card-image {
    height: 260px;
  }
}
@media print, screen and (min-width: 40em) {
  .cards-image--4 .cards-image__card-image, .cards-image-multirow--4 .cards-image__card-image {
    height: 320px;
  }
}
@media print, screen and (min-width: 64em) {
  .cards-image--4 .cards-image__card-image, .cards-image-multirow--4 .cards-image__card-image {
    height: 160px;
  }
}
@media screen and (min-width: 90em) {
  .cards-image--4 .cards-image__card-image, .cards-image-multirow--4 .cards-image__card-image {
    height: 220px;
  }
}

@media print, screen and (min-width: 64em) {
  .cards-image--2 .cards-image__card-inner {
    width: 92%;
    position: relative;
    top: -20px;
    left: calc(8% + 1px);
    padding: 25px 28px 0 28px;
    margin-bottom: -20px;
  }
  .cards-image--2 .cards-image__card-inner .cards-image__card-headline {
    padding-top: 0;
  }
}
.cards-image--3 .cards-image__card-inner .cards-image__card-headline {
  padding-top: 30px;
}

.cards-image__card-headline {
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #c41230;
  padding-top: 20px;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
@media print, screen and (min-width: 64em) {
  .cards-image__card-headline {
    font-size: 20px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #c41230;
  }
}
.cards-image__card-headline .cards-text__base-link-text {
  display: inline-block;
  padding-right: 24px;
  width: 100%;
}
.cards-image__card-headline .cards-text__base-link-text::after {
  display: inline-block;
  align-self: flex-end;
  width: 9px;
  height: 9px;
  background-color: transparent;
  content: "";
  border-top: 2px solid #c41230;
  border-right: 2px solid #c41230;
  transform: translateY(-100%) rotate(45deg) translateX(10px);
  transition: all 0.2s ease;
  margin-right: -24px;
}
.cards-image__card-headline .cards-text__base-link-text:hover:after {
  transform: translateY(-16px) rotate(45deg) translateX(20px);
}
.cards-image__card-headline .cards-text__base-link-text:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}

.cards-image__card-copy {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
  color: #231f20;
  padding-top: 12px;
  margin-bottom: 8px;
}
.cards-image__card-copy ul {
  display: list-item;
  margin: 0 0 2rem 2rem;
}
.cards-image__card-copy ul::marker {
  color: transparent;
}
.cards-image__card-copy ul li {
  display: list-item;
  padding: 0px;
  line-height: 160%;
  margin: 5px 0;
}
.cards-image__card-copy ul li:before {
  content: "";
  background-image: url("../svgs/functional/icon-target--red.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-left: -20px;
  margin-right: 10px;
}
.cards-image__card-copy ul li ul {
  list-style-type: disc;
  display: list-item;
  margin: 0 0 0 2rem;
}
.cards-image__card-copy ul li ul li::before {
  content: unset;
}
.cards-image__card-copy ul li ul li::marker {
  color: #C41230;
  font-size: 22px;
}
.cards-image__card-copy ul li ul li ul {
  list-style-type: circle;
}
.cards-image__card-copy ul li ul li ul li ul {
  list-style-type: square;
}
.cards-image__card-copy ol {
  list-style: decimal;
  margin: 0 0 2rem 2rem;
}
.cards-image__card-copy ol li {
  padding: 0 0 0 0rem;
  display: list-item;
  line-height: 160%;
  margin: 5px 0;
}
.cards-image__card-copy ol li:before {
  font-family: proxima-nova, sans-serif;
  color: #c41230;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.75px;
  margin-right: 16px;
  text-align: center;
  display: inline-block;
  width: 20px;
  vertical-align: middle;
  margin-left: -36px;
  content: unset;
}
.cards-image__card-copy ol li::marker {
  color: #C41230;
  font-size: 22px;
}
.cards-image__card-copy ol li ol {
  list-style: lower-alpha;
  margin: 0 0 0 2rem;
}
.cards-image__card-copy ol li ol li ol {
  list-style: lower-roman;
}
.cards-image__card-copy ol li ol li ol li ol {
  list-style: decimal;
}
.cards-image__card-copy a {
  color: #c41230;
  text-decoration: underline;
  font-style: normal;
  outline: 2px dotted transparent;
  outline-offset: 0;
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.cards-image__card-copy a:hover {
  text-decoration: none;
}
.cards-image__card-copy a:focus {
  text-decoration: none;
  outline: 2px dotted #c41230;
}
.cards-image__card-copy h2 {
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #c41230;
  padding-top: 8px;
  padding-bottom: 8px;
}
@media print, screen and (min-width: 64em) {
  .cards-image__card-copy h2 {
    font-size: 20px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #c41230;
  }
}
.cards-image__card-copy h3 {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: #231f20;
  padding-top: 8px;
  padding-bottom: 8px;
}
@media print, screen and (min-width: 64em) {
  .cards-image__card-copy h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.cards-image__card-copy h4 {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: #c41230;
  padding-top: 8px;
  padding-bottom: 8px;
}
@media print, screen and (min-width: 64em) {
  .cards-image__card-copy h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.cards-image__card-copy h5 {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: #231f20;
  padding-top: 8px;
  padding-bottom: 8px;
}
@media print, screen and (min-width: 64em) {
  .cards-image__card-copy h5 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.cards-image__card-copy h6 {
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.09375rem;
  color: #c41230;
  padding-top: 8px;
  padding-bottom: 8px;
}
.cards-image__card-copy p {
  margin-bottom: 20px;
}
.cards-image__card-copy ul {
  display: list-item;
  margin: 0 0 2rem 2rem;
}
.cards-image__card-copy ul::marker {
  color: transparent;
}
.cards-image__card-copy ul li {
  display: list-item;
  padding: 0px;
  line-height: 160%;
  margin: 5px 0;
}
.cards-image__card-copy ul li:before {
  content: "";
  background-image: url("../svgs/functional/icon-target--red.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-left: -20px;
  margin-right: 10px;
}
.cards-image__card-copy ul li ul {
  list-style-type: disc;
  display: list-item;
  margin: 0 0 0 2rem;
}
.cards-image__card-copy ul li ul li::before {
  content: unset;
}
.cards-image__card-copy ul li ul li::marker {
  color: #C41230;
  font-size: 22px;
}
.cards-image__card-copy ul li ul li ul {
  list-style-type: circle;
}
.cards-image__card-copy ul li ul li ul li ul {
  list-style-type: square;
}
.cards-image__card-copy ol {
  list-style: decimal;
  margin: 0 0 2rem 2rem;
}
.cards-image__card-copy ol li {
  padding: 0 0 0 0rem;
  display: list-item;
  line-height: 160%;
  margin: 5px 0;
}
.cards-image__card-copy ol li:before {
  font-family: proxima-nova, sans-serif;
  color: #c41230;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.75px;
  margin-right: 16px;
  text-align: center;
  display: inline-block;
  width: 20px;
  vertical-align: middle;
  margin-left: -36px;
  content: unset;
}
.cards-image__card-copy ol li::marker {
  color: #C41230;
  font-size: 22px;
}
.cards-image__card-copy ol li ol {
  list-style: lower-alpha;
  margin: 0 0 0 2rem;
}
.cards-image__card-copy ol li ol li ol {
  list-style: lower-roman;
}
.cards-image__card-copy ol li ol li ol li ol {
  list-style: decimal;
}

@media print, screen and (min-width: 64em) {
  .cards-image__card-cta {
    padding-bottom: 0px;
  }
}
.cards-image__card-cta .base-cta-text {
  margin-top: 0;
}

.cards-image__divider {
  border-bottom: 1px solid #ccc9b8;
  margin-top: 28px;
  margin-bottom: 40px;
}
@media print, screen and (min-width: 64em) {
  .cards-image__divider {
    margin-top: 20px;
  }
}

.cards-image__card--light .cards-image__card-headline {
  color: #fff;
}
.cards-image__card--light .cards-image__card-headline .cards-text__base-link-text::after {
  content: "";
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
}
.cards-image__card--light .cards-image__card-headline .cards-text__base-link-text:focus {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}
.cards-image__card--light .cards-image__card-copy {
  color: #fff;
}
.cards-image__card--light .cards-image__card-copy ul li:before {
  background-image: url("../svgs/functional/icon-target-white.svg");
}
.cards-image__card--light .cards-image__card-copy ul li ul li::marker {
  color: unset;
}
.cards-image__card--light .cards-image__card-copy ol li:before {
  color: unset;
}
.cards-image__card--light .cards-image__card-copy ol li::marker {
  color: unset;
}
.cards-image__card--light .cards-image__card-copy a {
  color: unset;
}
.cards-image__card--light .base-cta-text {
  color: #fff;
}
.cards-image__card--light .base-cta-text--light:before, .cards-image__card--light .news-card-group--background-red .base-cta-text:before, .news-card-group--background-red .cards-image__card--light .base-cta-text:before {
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
}

@media print, screen and (min-width: 64em) {
  .cards-image-multirow--2 .cards-image__card {
    flex-basis: 50%;
    max-width: 50%;
  }
}

@media print, screen and (min-width: 64em) {
  .cards-image-multirow--3 .cards-image__card {
    flex-basis: 33.33%;
    max-width: 33.33%;
  }
}

@media print, screen and (min-width: 64em) {
  .cards-image-multirow--4 .cards-image__card {
    flex-basis: 25%;
    max-width: 25%;
  }
}

.context-list {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #fff;
}
.context-list.context-list--gold {
  background-color: #edece2;
}
.context-list.context-list--red {
  background-color: #c41230;
}
.context-list.context-list--red .context-list__article-cta .base-cta-text:before {
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
}
@media print, screen and (min-width: 40em) {
  .context-list {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media print, screen and (min-width: 64em) {
  .context-list {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.context-list__inner {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .context-list__inner {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .context-list__inner {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .context-list__inner {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .context-list__inner {
    width: 90%;
    max-width: 1404px;
  }
}
.context-list__inner article:last-of-type {
  padding-bottom: 0;
}
.context-list__inner article:last-of-type .context-list__article-inner {
  padding-bottom: 0;
}
.context-list__inner .context-list__lockup-container .base-lockup__copy--large {
  margin-top: 15px;
}
@media print, screen and (min-width: 64em) {
  .context-list__inner .context-list__lockup-container .base-lockup__copy--large {
    margin-top: 24px;
  }
  .context-list__inner .context-list__lockup-container .base-lockup__copy--large p {
    margin-bottom: 24px;
  }
}

.context-list--vertical .context-list__lockup-container {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-bottom: 40px;
}
@media print, screen and (min-width: 40em) {
  .context-list--vertical .context-list__lockup-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .context-list--vertical .context-list__lockup-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .context-list--vertical .context-list__lockup-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .context-list--vertical .context-list__lockup-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .context-list--vertical .context-list__lockup-container {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    padding-bottom: 50px;
  }
}
.context-list--vertical .context-list__articles {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .context-list--vertical .context-list__articles {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .context-list--vertical .context-list__articles {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .context-list--vertical .context-list__articles {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .context-list--vertical .context-list__articles {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .context-list--vertical .context-list__articles {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
}
.context-list--vertical .context-list__article-inner {
  padding-bottom: 40px;
  width: 100%;
}
@media print, screen and (min-width: 64em) {
  .context-list--vertical .context-list__article-inner {
    padding-bottom: 50px;
  }
}

.context-list--horizontal .context-list__lockup-container {
  padding-bottom: 40px;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .context-list--horizontal .context-list__lockup-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .context-list--horizontal .context-list__lockup-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .context-list--horizontal .context-list__lockup-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .context-list--horizontal .context-list__lockup-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .context-list--horizontal .context-list__lockup-container {
    padding-bottom: 50px;
  }
}
.context-list--horizontal .context-list__article {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-bottom: 40px;
}
@media print, screen and (min-width: 40em) {
  .context-list--horizontal .context-list__article {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .context-list--horizontal .context-list__article {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .context-list--horizontal .context-list__article {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .context-list--horizontal .context-list__article {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .context-list--horizontal .context-list__article {
    padding-bottom: 0;
  }
  .context-list--horizontal .context-list__article.context-list__article--2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .context-list--horizontal .context-list__article.context-list__article--3 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .context-list--horizontal .context-list__article.context-list__article--4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media print, screen and (min-width: 64em) {
  .context-list--vertical .context-list__article {
    display: flex;
  }
}

.context-list__article-icon-container {
  min-width: 26px;
  margin-bottom: 28px;
}
.context-list--vertical .context-list__article-icon-container {
  min-width: 34px;
}
@media print, screen and (min-width: 64em) {
  .context-list__article-icon-container {
    margin-right: 28px;
  }
}

.context-list__article-icon {
  width: 26px;
  min-width: 26px;
}
.context-list--vertical .context-list__article-icon {
  width: 34px;
  min-width: 34px;
}

.context-list__article-image {
  width: 100%;
}
.context-list--vertical .context-list__article-image {
  width: 100%;
}

.context-list__article-headline {
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #231f20;
}
@media print, screen and (min-width: 64em) {
  .context-list__article-headline {
    font-size: 20px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #c41230;
  }
}
.context-list__article-headline.context-list__article-headline--red {
  color: #c41230;
}
.context-list__article-headline.context-list__article-headline--red a {
  display: inline-block;
  width: 100%;
}
.context-list__article-headline.context-list__article-headline--red a:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.context-list__article-headline.context-list__article-headline--red a:hover:after {
  transform: translateY(-16px) rotate(45deg) translateX(20px);
}
.context-list__article-headline.context-list__article-headline--red a:after {
  display: inline-block;
  align-self: flex-end;
  width: 9px;
  height: 9px;
  background-color: transparent;
  content: "";
  border-top: 2px solid #c41230;
  border-right: 2px solid #c41230;
  transform: translateY(-100%) rotate(45deg) translateX(10px);
  transition: all 0.2s ease;
  margin-right: -24px;
}
.context-list__article-headline.context-list__article-headline--white {
  color: #fff;
}
.context-list__article-headline.context-list__article-headline--white a {
  display: inline-block;
}
.context-list__article-headline.context-list__article-headline--white a:focus {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}
.context-list__article-headline.context-list__article-headline--white a:hover:after {
  transform: translateY(-16px) rotate(45deg) translateX(20px);
}
.context-list__article-headline.context-list__article-headline--white a:after {
  display: inline-block;
  align-self: flex-end;
  width: 9px;
  height: 9px;
  background-color: transparent;
  content: "";
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: translateY(-100%) rotate(45deg) translateX(10px);
  transition: all 0.2s ease;
  margin-right: -24px;
}

.context-list__article-copy {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
  color: #231f20;
  padding-top: 12px;
}
.context-list--red .context-list__article-copy {
  color: #fff;
}
.context-list__article-copy a {
  color: #c41230;
  text-decoration: underline;
  font-style: normal;
  outline: 2px dotted transparent;
  outline-offset: 0;
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.context-list__article-copy a:hover {
  text-decoration: none;
}
.context-list__article-copy a:focus {
  text-decoration: none;
  outline: 2px dotted #c41230;
}
.context-list__article-copy h6 {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: 45px;
}
@media print, screen and (min-width: 64em) {
  .context-list__article-copy h6 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.context-list__article-copy h5 {
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 45px;
}
@media print, screen and (min-width: 64em) {
  .context-list__article-copy h5 {
    font-size: 20px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #c41230;
  }
}
.context-list__article-copy h4 {
  font-family: proxima-nova, sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 45px;
}
@media print, screen and (min-width: 64em) {
  .context-list__article-copy h4 {
    font-size: 26px;
    line-height: 32px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.context-list__article-copy h3 {
  font-family: freight-display-pro, serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 300;
  margin-bottom: 15px;
  margin-top: 45px;
}
@media print, screen and (min-width: 64em) {
  .context-list__article-copy h3 {
    font-size: 38px;
    line-height: 44px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
.context-list__article-copy h3.table-header {
  font-family: proxima-nova, sans-serif;
  font-size: 20px;
  line-height: 1.3;
  font-weight: 500;
  margin-bottom: 20px;
}
@media print, screen and (min-width: 64em) {
  .context-list__article-copy h3.table-header {
    font-size: 24px;
    line-height: 1.25;
  }
}
.context-list__article-copy p {
  margin-bottom: 20px;
}
.context-list__article-copy ul {
  margin-bottom: 20px;
}
.context-list__article-copy ul li {
  padding: 8px 0 8px 30px;
}
.context-list__article-copy ul li:before {
  content: "";
  background-image: url("../svgs/functional/icon-target--red.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 20px;
  margin-left: -30px;
}
.context-list__article-copy ol {
  list-style: none;
  counter-reset: my-awesome-counter;
  margin-bottom: 20px;
}
.context-list__article-copy ol li {
  padding: 8px 0;
  counter-increment: my-awesome-counter;
  padding-left: 36px;
}
.context-list__article-copy ol li:before {
  content: counter(my-awesome-counter);
  font-family: proxima-nova, sans-serif;
  color: #c41230;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.75px;
  margin-right: 16px;
  text-align: center;
  display: inline-block;
  width: 20px;
  vertical-align: middle;
  margin-left: -36px;
}

.collection-list {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #fff;
}
.collection-list.collection-list--white-warm {
  background-color: #faf9f7;
}
.collection-list.collection-list--red {
  background-color: #c41230;
}
@media print, screen and (min-width: 40em) {
  .collection-list {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-list {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
.collection-list .collection-list__header-container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .collection-list .collection-list__header-container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-list .collection-list__header-container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .collection-list .collection-list__header-container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .collection-list .collection-list__header-container {
    width: 90%;
    max-width: 1404px;
  }
}
.collection-list .collection-list__header-container .collection-list__header-inner {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  justify-self: center;
  padding-bottom: 40px;
}
@media print, screen and (min-width: 40em) {
  .collection-list .collection-list__header-container .collection-list__header-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-list .collection-list__header-container .collection-list__header-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .collection-list .collection-list__header-container .collection-list__header-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .collection-list .collection-list__header-container .collection-list__header-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-list .collection-list__header-container .collection-list__header-inner {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .collection-list .collection-list__header-container .collection-list__header-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .collection-list .collection-list__header-container .collection-list__header-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .collection-list .collection-list__header-container .collection-list__header-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .collection-list .collection-list__header-container .collection-list__header-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.collection-list .collection-list__header-container .collection-list__header-inner .collection-list__header-heading {
  font-family: freight-display-pro, serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 300;
  color: #c41230;
  padding-bottom: 15px;
}
@media print, screen and (min-width: 64em) {
  .collection-list .collection-list__header-container .collection-list__header-inner .collection-list__header-heading {
    font-size: 38px;
    line-height: 44px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-list .collection-list__header-container .collection-list__header-inner .collection-list__header-heading {
    padding-bottom: 20px;
  }
}
.collection-list .collection-list__header-container .collection-list__header-inner .collection-list__header-subheading {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
  color: #231f20;
}
@media print, screen and (min-width: 64em) {
  .collection-list .collection-list__header-container .collection-list__header-inner .collection-list__header-subheading {
    font-family: freight-text-pro, serif;
    font-size: 19px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0.015625rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .collection-list .collection-list__header-container .collection-list__header-inner .collection-list__header-subheading {
    font-size: 24px;
    line-height: 36px;
  }
}
.collection-list .collection-list__list-container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .collection-list .collection-list__list-container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-list .collection-list__list-container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .collection-list .collection-list__list-container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .collection-list .collection-list__list-container {
    width: 90%;
    max-width: 1404px;
  }
}
.collection-list .collection-list__list-container .collection-list__list-inner {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  margin-left: auto;
  margin-right: auto;
}
@media print, screen and (min-width: 40em) {
  .collection-list .collection-list__list-container .collection-list__list-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-list .collection-list__list-container .collection-list__list-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .collection-list .collection-list__list-container .collection-list__list-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .collection-list .collection-list__list-container .collection-list__list-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-list .collection-list__list-container .collection-list__list-inner {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .collection-list .collection-list__list-container .collection-list__list-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .collection-list .collection-list__list-container .collection-list__list-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .collection-list .collection-list__list-container .collection-list__list-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .collection-list .collection-list__list-container .collection-list__list-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.collection-list .collection-list__list-container li.base-list-text {
  padding: 8px 0 8px 30px;
  display: block;
}
.collection-list .collection-list__list-container li.base-list-text:before {
  content: "";
  background-image: url("../svgs/functional/icon-target--red.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 20px;
  margin-left: -30px;
}
.collection-list .collection-list__list-container li.base-list-text .base-list-text__content {
  margin-left: 0;
}
.collection-list .collection-list__cta-container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .collection-list .collection-list__cta-container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-list .collection-list__cta-container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .collection-list .collection-list__cta-container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .collection-list .collection-list__cta-container {
    width: 90%;
    max-width: 1404px;
  }
}
@media print, screen and (min-width: 40em) {
  .collection-list .collection-list__cta-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-list .collection-list__cta-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .collection-list .collection-list__cta-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .collection-list .collection-list__cta-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-list .collection-list__cta-container {
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .collection-list .collection-list__cta-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .collection-list .collection-list__cta-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .collection-list .collection-list__cta-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .collection-list .collection-list__cta-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.collection-figures-2up {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #fff;
}
.collection-figures-2up.collection-figures--white-warm {
  background-color: #faf9f7;
}
.collection-figures-2up.collection-figures--gold {
  background-color: #edece2;
}
@media print, screen and (min-width: 40em) {
  .collection-figures-2up {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-figures-2up {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.collection-figures-2up.collection-figures--white a:focus, .collection-figures-2up.collection-figures--gold a:focus, .collection-figures-2up.collection-figures--white-warm a:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.collection-figures-2up.collection-figures--white a:hover *, .collection-figures-2up.collection-figures--gold a:hover *, .collection-figures-2up.collection-figures--white-warm a:hover * {
  color: #c41230;
  text-decoration: underline;
}
.collection-figures-2up.collection-figures--red a:focus {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}
.collection-figures-2up.collection-figures--red a:hover * {
  color: #231f20;
  text-decoration: underline;
}

.collection-figures-2up__header-container,
.collection-figures__cards-container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .collection-figures-2up__header-container,
  .collection-figures__cards-container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-figures-2up__header-container,
  .collection-figures__cards-container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .collection-figures-2up__header-container,
  .collection-figures__cards-container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .collection-figures-2up__header-container,
  .collection-figures__cards-container {
    width: 90%;
    max-width: 1404px;
  }
}

.collection-figures-2up__header-inner {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  align-self: center;
  justify-self: center;
  padding-bottom: 30px;
}
@media print, screen and (min-width: 40em) {
  .collection-figures-2up__header-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-figures-2up__header-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .collection-figures-2up__header-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .collection-figures-2up__header-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-figures-2up__header-inner {
    padding-bottom: 52px;
  }
}

.collection-figures__header-heading {
  font-family: freight-display-pro, serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 300;
  color: #231f20;
}
@media print, screen and (min-width: 64em) {
  .collection-figures__header-heading {
    font-size: 38px;
    line-height: 44px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

.collection-figures__card {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .collection-figures__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-figures__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .collection-figures__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .collection-figures__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 40em) {
  .collection-figures__card {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.collection-figures--white .collection-figures__card-inner:focus-within,
.collection-figures--gold .collection-figures__card-inner:focus-within,
.collection-figures--white-warm .collection-figures__card-inner:focus-within {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.collection-figures--white .collection-figures__card-inner .base-cta-text:focus,
.collection-figures--gold .collection-figures__card-inner .base-cta-text:focus,
.collection-figures--white-warm .collection-figures__card-inner .base-cta-text:focus {
  outline: 2px dotted #c41230 !important;
}
.collection-figures--white .collection-figures__card-inner .collection-figures-link-example:hover,
.collection-figures--white .collection-figures__card-inner .collection-figures-link-example:focus,
.collection-figures--gold .collection-figures__card-inner .collection-figures-link-example:hover,
.collection-figures--gold .collection-figures__card-inner .collection-figures-link-example:focus,
.collection-figures--white-warm .collection-figures__card-inner .collection-figures-link-example:hover,
.collection-figures--white-warm .collection-figures__card-inner .collection-figures-link-example:focus {
  outline: 0;
}
.collection-figures--white .collection-figures__card-inner .collection-figures-link-example:hover h2,
.collection-figures--white .collection-figures__card-inner .collection-figures-link-example:focus h2,
.collection-figures--gold .collection-figures__card-inner .collection-figures-link-example:hover h2,
.collection-figures--gold .collection-figures__card-inner .collection-figures-link-example:focus h2,
.collection-figures--white-warm .collection-figures__card-inner .collection-figures-link-example:hover h2,
.collection-figures--white-warm .collection-figures__card-inner .collection-figures-link-example:focus h2 {
  text-decoration: underline;
  text-decoration-color: #c41230;
}
.collection-figures--white .collection-figures__card-inner .collection-figures-link-example:hover p,
.collection-figures--white .collection-figures__card-inner .collection-figures-link-example:focus p,
.collection-figures--gold .collection-figures__card-inner .collection-figures-link-example:hover p,
.collection-figures--gold .collection-figures__card-inner .collection-figures-link-example:focus p,
.collection-figures--white-warm .collection-figures__card-inner .collection-figures-link-example:hover p,
.collection-figures--white-warm .collection-figures__card-inner .collection-figures-link-example:focus p {
  text-decoration: underline;
  text-decoration-color: black;
}

.collection-figures__card-inner {
  border-left: solid 4px #ccc9b8;
  padding-left: 20px;
  margin-bottom: 40px;
}

.collection-figures__card-header-heading {
  font-family: freight-display-pro, serif;
  font-size: 54px;
  font-weight: 400;
  line-height: 40px;
  font-variant: lining-nums;
  color: #c41230;
  padding-bottom: 17px;
}

.collection-figures__card-copy {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.04375rem;
  color: #231f20;
}

.collection-figures__card-subcopy {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.04375rem;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-style: italic;
  color: #c41230;
  letter-spacing: normal;
  padding-bottom: 8px;
  padding-top: 8px;
}

.collection-figures-3up {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #fff;
}
.collection-figures-3up.collection-figures-3up--gold {
  background-color: #edece2;
}
@media print, screen and (min-width: 40em) {
  .collection-figures-3up {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-figures-3up {
    padding-top: 100px;
    padding-bottom: 0px;
  }
}
.collection-figures-3up.collection-figures-3up--white .collection-figures-3up__card-inner:focus-within, .collection-figures-3up.collection-figures-3up--gold .collection-figures-3up__card-inner:focus-within, .collection-figures-3up.collection-figures-3up--white-warm .collection-figures-3up__card-inner:focus-within {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.collection-figures-3up.collection-figures-3up--white .collection-figures-link-example:focus,
.collection-figures-3up.collection-figures-3up--white .collection-figures-link-example:hover, .collection-figures-3up.collection-figures-3up--gold .collection-figures-link-example:focus,
.collection-figures-3up.collection-figures-3up--gold .collection-figures-link-example:hover, .collection-figures-3up.collection-figures-3up--white-warm .collection-figures-link-example:focus,
.collection-figures-3up.collection-figures-3up--white-warm .collection-figures-link-example:hover {
  color: #c41230;
  text-decoration: underline;
  outline: 0;
}
.collection-figures-3up.collection-figures-3up--red a:focus {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}
.collection-figures-3up.collection-figures-3up--red a:hover * {
  color: #231f20;
  text-decoration: underline;
}

@media print, screen and (min-width: 64em) {
  .collection-figures-3up__card-inner {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.collection-figures-3up__container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  position: relative;
  align-items: flex-start;
}
@media print, screen and (min-width: 40em) {
  .collection-figures-3up__container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-figures-3up__container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .collection-figures-3up__container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .collection-figures-3up__container {
    width: 90%;
    max-width: 1404px;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-figures-3up__container {
    padding-right: 48px;
  }
  .collection-figures-3up__container::after {
    position: absolute;
    top: 5px;
    right: 0;
    display: block;
    width: 1px;
    height: calc(100% - 5px);
    content: "";
    background-color: #c41230;
  }
}

@media print, screen and (min-width: 64em) {
  .collection-figures-3up__badge {
    position: absolute;
    top: -60px;
    right: -20px;
    width: 40px;
  }
  .collection-figures-3up__badge > img {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
}

.collection-figures-3up__heading-container {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-bottom: 15px;
}
@media print, screen and (min-width: 40em) {
  .collection-figures-3up__heading-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-figures-3up__heading-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .collection-figures-3up__heading-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .collection-figures-3up__heading-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-figures-3up__heading-container {
    flex: 0 0 50%;
    max-width: 50%;
    padding-bottom: 40px;
  }
}

.collection-figures-3up__subheading-container {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-bottom: 40px;
}
@media print, screen and (min-width: 40em) {
  .collection-figures-3up__subheading-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-figures-3up__subheading-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .collection-figures-3up__subheading-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .collection-figures-3up__subheading-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-figures-3up__subheading-container {
    padding-top: 8px;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-left: 16.6666666667%;
    padding-bottom: 40px;
  }
}

.collection-figures-3up__heading {
  font-family: freight-display-pro, serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 300;
  color: #231f20;
}
@media print, screen and (min-width: 64em) {
  .collection-figures-3up__heading {
    font-size: 38px;
    line-height: 44px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

.collection-figures-3up__subheading {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
  color: #231f20;
}

.collection-figures-3up__cards-container {
  margin-right: -0.46875rem;
  margin-left: -0.46875rem;
  margin-right: -0.46875rem;
  margin-left: -0.46875rem;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
@media print, screen and (min-width: 40em) {
  .collection-figures-3up__cards-container {
    margin-right: -0.78125rem;
    margin-left: -0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-figures-3up__cards-container {
    margin-right: -0.78125rem;
    margin-left: -0.78125rem;
  }
}

.collection-figures-3up__card {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  justify-self: center;
  padding-bottom: 40px;
}
@media print, screen and (min-width: 40em) {
  .collection-figures-3up__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-figures-3up__card {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .collection-figures-3up__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .collection-figures-3up__card {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.collection-figures-3up__card:last-of-type {
  padding-bottom: 0px;
}
@media print, screen and (min-width: 64em) {
  .collection-figures-3up__card:last-of-type {
    padding-bottom: 60px;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-figures-3up__card {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    padding-bottom: 60px;
  }
}

.collection-figures-3up__card-headline {
  color: #c41230;
  font-family: freight-text-pro, serif;
  font-weight: 400;
  font-variant: lining-nums;
  font-size: 90px;
  letter-spacing: 0.05625rem;
  padding-bottom: 20px;
}

.collection-figures-3up__card-copy {
  font-family: freight-text-pro, serif;
  font-size: 30px;
  font-weight: 300;
  line-height: 34px;
  padding-bottom: 12px;
  color: #231f20;
}

.collection-figures-3up__card-subcopy {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.04375rem;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-style: italic;
  color: #c41230;
  letter-spacing: normal;
  padding-bottom: 8px;
}

.collection-figures-4up {
  padding-top: 80px;
  padding-bottom: 80px;
  color: #fff;
}
@media print, screen and (min-width: 40em) {
  .collection-figures-4up {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-figures-4up {
    padding-top: 100px;
    padding-bottom: 60px;
  }
}
.collection-figures-4up.collection-figures-4up--red {
  background-color: #c41230;
}
.collection-figures-4up.collection-figures-4up--white {
  background-color: #fff;
}
.collection-figures-4up.collection-figures-4up--gold {
  background-color: #edece2;
}
.collection-figures-4up .collection-figures-4up__container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  position: relative;
  align-items: flex-start;
}
@media print, screen and (min-width: 40em) {
  .collection-figures-4up .collection-figures-4up__container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-figures-4up .collection-figures-4up__container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .collection-figures-4up .collection-figures-4up__container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .collection-figures-4up .collection-figures-4up__container {
    width: 90%;
    max-width: 1404px;
  }
}
.collection-figures-4up.collection-figures-4up--white a:focus, .collection-figures-4up.collection-figures-4up--gold a:focus, .collection-figures-4up.collection-figures-4up--white-warm a:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.collection-figures-4up.collection-figures-4up--white a:hover *, .collection-figures-4up.collection-figures-4up--gold a:hover *, .collection-figures-4up.collection-figures-4up--white-warm a:hover * {
  color: #c41230;
  text-decoration: underline;
}
.collection-figures-4up.collection-figures-4up--white .collection-figures-4up__card-inner:focus-within, .collection-figures-4up.collection-figures-4up--gold .collection-figures-4up__card-inner:focus-within, .collection-figures-4up.collection-figures-4up--white-warm .collection-figures-4up__card-inner:focus-within {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.collection-figures-4up.collection-figures-4up--white .collection-figures-link-example:focus,
.collection-figures-4up.collection-figures-4up--white .collection-figures-link-example:hover, .collection-figures-4up.collection-figures-4up--gold .collection-figures-link-example:focus,
.collection-figures-4up.collection-figures-4up--gold .collection-figures-link-example:hover, .collection-figures-4up.collection-figures-4up--white-warm .collection-figures-link-example:focus,
.collection-figures-4up.collection-figures-4up--white-warm .collection-figures-link-example:hover {
  color: #c41230;
  text-decoration: underline;
  outline: 0;
}
.collection-figures-4up.collection-figures-4up--red .collection-figures-4up__card-inner:focus-within {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}
.collection-figures-4up.collection-figures-4up--red .collection-figures-link-example:focus,
.collection-figures-4up.collection-figures-4up--red .collection-figures-link-example:hover {
  text-decoration: underline;
  outline: 0;
}

.collection-figures-4up__heading-container {
  flex: 0 0 100%;
  max-width: 100%;
  align-self: flex-start;
  justify-self: center;
  padding-bottom: 15px;
}
@media print, screen and (min-width: 64em) {
  .collection-figures-4up__heading-container {
    flex: 0 0 50%;
    max-width: 50%;
    padding-bottom: 100px;
  }
}

.collection-figures-4up__subheading-container {
  flex: 0 0 100%;
  max-width: 100%;
  align-self: flex-start;
  justify-self: center;
  padding-bottom: 60px;
  padding-top: 8px;
}
@media print, screen and (min-width: 64em) {
  .collection-figures-4up__subheading-container {
    flex: 0 0 25%;
    max-width: 25%;
    margin-left: 8.3333333333%;
    padding-bottom: 100px;
  }
}

.collection-figures-4up__heading {
  font-family: freight-display-pro, serif;
  font-size: 38px;
  line-height: 48px;
  font-weight: 400;
  color: #fff;
}
@media print, screen and (min-width: 64em) {
  .collection-figures-4up__heading {
    font-weight: 300;
    font-size: 44px;
    line-height: 50px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

.collection-figures-4up__subheading {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
  color: #fff;
}

.collection-figures-4up__cta-container .base-cta-text:before {
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
}

.collection-figures-4up__card-inner {
  position: relative;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 30px;
  border-left: 4px solid #edece2;
}
.collection-figures-4up__card {
  flex: 0 0 100%;
  max-width: 100%;
  align-self: center;
  justify-self: center;
  padding-bottom: 48px;
  align-self: flex-start;
}
.collection-figures-4up__card:last-of-type {
  padding-bottom: 0px;
}
@media print, screen and (min-width: 64em) {
  .collection-figures-4up__card:last-of-type {
    padding-bottom: 60px;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-figures-4up__card {
    flex: 0 0 25%;
    max-width: 25%;
    padding-bottom: 60px;
  }
}

.collection-figures-4up__card-headline {
  font-family: freight-display-pro, serif;
  font-size: 38px;
  line-height: 48px;
  font-weight: 400;
  color: #edece2;
  padding-bottom: 2px;
  margin-top: -34px;
  font-variant: lining-nums;
}
@media print, screen and (min-width: 64em) {
  .collection-figures-4up__card-headline {
    font-weight: 300;
    font-size: 44px;
    line-height: 50px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-figures-4up__card-headline {
    margin-top: -38px;
  }
}

.collection-figures-4up__card-copy {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.04375rem;
  font-weight: 300;
  color: #fff;
}

.collection-figures-4up__card-subcopy {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.04375rem;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-style: italic;
  color: #fff;
  letter-spacing: normal;
  padding-bottom: 8px;
  padding-top: 8px;
}

.collection-images {
  position: relative;
  overflow: hidden;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #edece2;
}
@media print, screen and (min-width: 40em) {
  .collection-images {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-images {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
.collection-images .collection-images__heading-container .base-lockup__copy--regular {
  margin-top: 15px;
}
@media print, screen and (min-width: 64em) {
  .collection-images .collection-images__heading-container .base-lockup__copy--regular {
    margin-top: 24px;
  }
}

.collection-images__main-container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .collection-images__main-container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-images__main-container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .collection-images__main-container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .collection-images__main-container {
    width: 90%;
    max-width: 1404px;
  }
}

.collection-images__content-container {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .collection-images__content-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-images__content-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .collection-images__content-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .collection-images__content-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-images__content-container {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-images__content-container .collection-images__content-container-inner {
    padding-left: 50px;
  }
}

.collection-images__icon {
  height: 47px;
}

.collection-images__icon-container {
  display: none;
}
@media print, screen and (min-width: 64em) {
  .collection-images__icon-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 33px;
    transform: translate(-45px, -50px);
    height: 100%;
    align-items: center;
    margin-left: -20px;
  }
}

.collection-images__icon-line {
  position: absolute;
  width: 1px;
  height: calc(100% - 80px);
  background-color: #ccc9b8;
  order: 1;
  top: 72px;
}

.collection-images__heading {
  font-family: proxima-nova, sans-serif;
  font-size: 38px;
  font-weight: 500;
  line-height: 1.32;
  color: #c41230;
  padding-bottom: 30px;
}
@media print, screen and (min-width: 64em) {
  .collection-images__heading {
    padding-bottom: 16px;
    margin-top: 10px;
  }
}

.collection-images__copy {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
  color: #231f20;
  padding-bottom: 32px;
}
.collection-images__copy ul {
  display: list-item;
  margin: 0 0 2rem 2rem;
}
.collection-images__copy ul::marker {
  color: transparent;
}
.collection-images__copy ul li {
  display: list-item;
  padding: 0px;
  line-height: 160%;
  margin: 5px 0;
}
.collection-images__copy ul li:before {
  content: "";
  background-image: url("../svgs/functional/icon-target--red.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-left: -20px;
  margin-right: 10px;
}
.collection-images__copy ul li ul {
  list-style-type: disc;
  display: list-item;
  margin: 0 0 0 2rem;
}
.collection-images__copy ul li ul li::before {
  content: unset;
}
.collection-images__copy ul li ul li::marker {
  color: #C41230;
  font-size: 22px;
}
.collection-images__copy ul li ul li ul {
  list-style-type: circle;
}
.collection-images__copy ul li ul li ul li ul {
  list-style-type: square;
}
.collection-images__copy ol {
  list-style: decimal;
  margin: 0 0 2rem 2rem;
}
.collection-images__copy ol li {
  padding: 0 0 0 0rem;
  display: list-item;
  line-height: 160%;
  margin: 5px 0;
}
.collection-images__copy ol li:before {
  font-family: proxima-nova, sans-serif;
  color: #c41230;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.75px;
  margin-right: 16px;
  text-align: center;
  display: inline-block;
  width: 20px;
  vertical-align: middle;
  margin-left: -36px;
  content: unset;
}
.collection-images__copy ol li::marker {
  color: #C41230;
  font-size: 22px;
}
.collection-images__copy ol li ol {
  list-style: lower-alpha;
  margin: 0 0 0 2rem;
}
.collection-images__copy ol li ol li ol {
  list-style: lower-roman;
}
.collection-images__copy ol li ol li ol li ol {
  list-style: decimal;
}
.collection-images__copy a {
  color: #c41230;
  text-decoration: underline;
  font-style: normal;
  outline: 2px dotted transparent;
  outline-offset: 0;
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.collection-images__copy a:hover {
  text-decoration: none;
}
.collection-images__copy a:focus {
  text-decoration: none;
  outline: 2px dotted #c41230;
}
.collection-images__copy h2 {
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #c41230;
  padding-top: 8px;
  padding-bottom: 8px;
}
@media print, screen and (min-width: 64em) {
  .collection-images__copy h2 {
    font-size: 20px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #c41230;
  }
}
.collection-images__copy h3 {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: #231f20;
  padding-top: 8px;
  padding-bottom: 8px;
}
@media print, screen and (min-width: 64em) {
  .collection-images__copy h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.collection-images__copy h4 {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: #c41230;
  padding-top: 8px;
  padding-bottom: 8px;
}
@media print, screen and (min-width: 64em) {
  .collection-images__copy h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.collection-images__copy h5 {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: #231f20;
  padding-top: 8px;
  padding-bottom: 8px;
}
@media print, screen and (min-width: 64em) {
  .collection-images__copy h5 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.collection-images__copy h6 {
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.09375rem;
  color: #c41230;
  padding-top: 8px;
  padding-bottom: 8px;
}
.collection-images__copy p {
  margin-bottom: 20px;
}

.collection-images__cta-container {
  padding-bottom: 48px;
}
@media print, screen and (min-width: 64em) {
  .collection-images__cta-container {
    padding-bottom: 0px;
  }
}

.collection-images__small-container {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  display: none;
  flex-direction: column;
}
@media print, screen and (min-width: 40em) {
  .collection-images__small-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-images__small-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .collection-images__small-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .collection-images__small-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-images__small-container {
    flex: 0 0 25%;
    max-width: 25%;
    margin-left: 8.3333333333%;
    justify-content: center;
    display: flex;
  }
}

.collection-images__image {
  width: 100%;
  object-fit: cover;
  margin-bottom: 30px;
}
@media print, screen and (min-width: 64em) {
  .collection-images__image {
    max-width: 320px;
    max-height: 320px;
  }
}

@media print, screen and (min-width: 64em) {
  .collection-images__image:last-child {
    margin-bottom: 0px;
  }
}

.collection-images__hero-container {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  display: flex;
}
@media print, screen and (min-width: 40em) {
  .collection-images__hero-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-images__hero-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .collection-images__hero-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .collection-images__hero-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .collection-images__hero-container {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    justify-content: center;
    align-items: center;
  }
}

.collection-images__hero-inner {
  width: 100%;
}
@media print, screen and (min-width: 64em) {
  .collection-images__hero-inner {
    width: 100%;
    padding-bottom: 100%;
    position: relative;
  }
}

.collection-images__hero-image {
  object-fit: cover;
  width: 100%;
}
@media print, screen and (min-width: 64em) {
  .collection-images__hero-image {
    left: 0;
    top: 50%;
    height: 110%;
    width: 110%;
    max-width: none;
    max-height: none;
    position: absolute;
    transform: translate(0, -50%);
  }
}

.hero-sublanding__container {
  position: relative;
  display: flex;
  flex-direction: column;
}
.hero-sublanding__header {
  position: relative;
  overflow-x: hidden;
  padding-top: 50px;
  background-color: #c41230;
  background-image: url("../images/illustrations/illustration-duotone-red.png");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}
@media print, screen and (min-width: 64em) {
  .hero-sublanding__header {
    flex-direction: row;
    height: 400px;
    padding-top: 0;
  }
}
.hero-sublanding__header .hero-sublanding__inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
@media print, screen and (min-width: 64em) {
  .hero-sublanding__header .hero-sublanding__inner {
    max-width: 75rem;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-flow: row wrap;
    margin-right: 0.625rem;
    margin-left: 0.625rem;
    flex: 1;
    margin-right: 1.125rem;
    margin-left: 1.125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .hero-sublanding__header .hero-sublanding__inner {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .hero-sublanding__header .hero-sublanding__inner {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .hero-sublanding__header .hero-sublanding__inner {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .hero-sublanding__header .hero-sublanding__inner {
    width: 90%;
    max-width: 1404px;
  }
}

.hero-sublanding__image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  order: 1;
}
@media print, screen and (min-width: 64em) {
  .hero-sublanding__image {
    height: 400px;
    width: 38vw;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.hero-sublanding__heading-container {
  padding-left: 16px;
}
@media print, screen and (min-width: 40em) {
  .hero-sublanding__heading-container {
    padding-left: 38px;
  }
}
@media print, screen and (min-width: 64em) {
  .hero-sublanding__heading-container {
    display: flex;
    height: 400px;
    width: 100%;
    padding-left: 0;
    max-width: none;
    align-items: center;
  }
}

.hero-sublanding__heading {
  font-family: freight-display-pro, serif;
  font-size: 52px;
  font-weight: 300;
  line-height: 58px;
  letter-spacing: 0.015625rem;
  margin-top: 35px;
  margin-bottom: 20px;
  color: #fff;
}
@media print, screen and (min-width: 64em) {
  .hero-sublanding__heading {
    flex: 0 0 54.1666666667%;
    max-width: 54.1666666667%;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.hero-sublanding__breadcrumbs {
  z-index: 1;
}
@media print, screen and (min-width: 40em) {
  .hero-sublanding__breadcrumbs {
    width: auto;
  }
}
@media print, screen and (min-width: 64em) {
  .hero-sublanding__breadcrumbs {
    position: relative;
    max-width: 75rem;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-flow: row wrap;
    margin-right: 0.625rem;
    margin-left: 0.625rem;
    flex: 1;
    margin-right: 1.125rem;
    margin-left: 1.125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .hero-sublanding__breadcrumbs {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .hero-sublanding__breadcrumbs {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .hero-sublanding__breadcrumbs {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .hero-sublanding__breadcrumbs {
    width: 90%;
    max-width: 1404px;
  }
}
@media screen and (min-width: 75em) {
  .hero-sublanding__breadcrumbs {
    width: 100%;
  }
}
.hero-sublanding__breadcrumbs .breadcrumbs {
  height: auto;
  flex-wrap: wrap;
  padding: 10px 20px;
  justify-content: flex-start;
  width: auto;
}
@media print, screen and (min-width: 64em) {
  .hero-sublanding__breadcrumbs .breadcrumbs {
    position: absolute;
    top: -20px;
    left: 2vw;
    width: auto;
  }
}
.hero-sublanding__content-container {
  background-color: #fff;
  overflow: hidden;
}

.hero-sublanding__content-outer {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  position: relative;
  background-color: #fff;
  padding-top: 30px;
  padding-bottom: 30px;
}
@media print, screen and (min-width: 40em) {
  .hero-sublanding__content-outer {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .hero-sublanding__content-outer {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .hero-sublanding__content-outer {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .hero-sublanding__content-outer {
    width: 90%;
    max-width: 1404px;
  }
}
@media print, screen and (min-width: 64em) {
  .hero-sublanding__content-outer {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

.hero-sublanding__content-inner {
  position: relative;
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  margin-left: 8.3333333333%;
}
@media print, screen and (min-width: 40em) {
  .hero-sublanding__content-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .hero-sublanding__content-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .hero-sublanding__content-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .hero-sublanding__content-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .hero-sublanding__content-inner {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    margin-left: 8.3333333333%;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .hero-sublanding__content-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .hero-sublanding__content-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .hero-sublanding__content-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .hero-sublanding__content-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.hero-sublanding__icon-container {
  bottom: 0px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 33px;
  transform: translate(-40px, -1px);
  height: calc(100% + 200px);
  align-items: center;
  overflow: visible;
}
@media print, screen and (min-width: 64em) {
  .hero-sublanding__icon-container {
    transform: translate(-60px, -1px);
  }
}

.hero-sublanding__icon {
  width: 15px;
  height: 15px;
  order: 1;
}

.hero-sublanding__icon-line {
  width: 1px;
  height: 100%;
  background-color: #c41230;
}

.hero-sublanding__copy {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
  padding-bottom: 20px;
  font-size: 18px;
  line-height: 1.67;
  font-weight: 300;
}
@media print, screen and (min-width: 64em) {
  .hero-sublanding__copy {
    font-size: 22px;
    line-height: 1.64;
    padding-bottom: 30px;
  }
}

.hero-sublanding__cta-group {
  display: flex;
  flex-direction: column;
}
@media print, screen and (min-width: 64em) {
  .hero-sublanding__cta-group {
    flex-direction: row;
  }
}
.hero-sublanding__cta-group .hero-sublanding__cta {
  margin-right: 16px;
}
.hero-sublanding__cta-group .hero-sublanding__cta .base-cta-primary__content {
  width: unset;
  max-width: 400px;
}

.hero-landing {
  position: relative;
  z-index: 10;
}

.hero-landing__container {
  position: relative;
}

.hero-landing__background {
  position: absolute;
  width: 100%;
  height: 370px;
  background-color: #c41230;
  z-index: -1;
}
@media print, screen and (min-width: 64em) {
  .hero-landing__background {
    height: 550px;
  }
}

.hero-landing__background-image {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 370px;
  object-fit: cover;
}
@media print, screen and (min-width: 64em) {
  .hero-landing__background-image {
    height: 550px;
  }
}

.hero-landing__header-background {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.4) 30%, rgba(0, 0, 0, 0) 50%);
}

.hero-landing__header-container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  align-items: center;
  z-index: 1;
  height: 370px;
  overflow: hidden;
}
@media print, screen and (min-width: 40em) {
  .hero-landing__header-container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .hero-landing__header-container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .hero-landing__header-container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .hero-landing__header-container {
    width: 90%;
    max-width: 1404px;
  }
}
@media print, screen and (min-width: 64em) {
  .hero-landing__header-container {
    height: 550px;
  }
}

.hero-landing__heading-inner {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  margin-left: 8.3333333333%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
}
@media print, screen and (min-width: 40em) {
  .hero-landing__heading-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .hero-landing__heading-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .hero-landing__heading-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .hero-landing__heading-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .hero-landing__heading-inner {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
    margin-left: 8.3333333333%;
  }
}

.hero-landing__header-icon-wrapper {
  width: 34px;
  height: 50px;
  position: absolute;
  transform: translate(-48px, 0px);
}
@media print, screen and (min-width: 40em) {
  .hero-landing__header-icon-wrapper {
    transform: translate(-54px);
  }
}

.hero-landing__header-icon-line {
  position: absolute;
  width: 1px;
  height: 500px;
  background-color: #c41230;
  transform: translate(24px, 70px);
}
@media print, screen and (min-width: 40em) {
  .hero-landing__header-icon-line {
    transform: translate(30px, 70px);
  }
}
@media print, screen and (min-width: 64em) {
  .hero-landing__header-icon-line {
    transform: translate(10px, 70px);
  }
}

.hero-landing__header-icon {
  width: 30px;
  height: 50px;
  position: absolute;
  transform: translate(9px);
}
@media print, screen and (min-width: 40em) {
  .hero-landing__header-icon {
    transform: translate(15px);
  }
}
@media print, screen and (min-width: 64em) {
  .hero-landing__header-icon {
    transform: translate(-7px);
    width: 36px;
  }
}

.hero-landing__header-icon-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  transform: translate(-43px);
}
@media print, screen and (min-width: 64em) {
  .hero-landing__header-icon-container {
    width: 100%;
    transform: translate(-58px);
  }
}

.hero-landing__header-icon-inner {
  position: relative;
}

.hero-landing__heading {
  font-family: freight-display-pro, serif;
  font-size: 52px;
  font-weight: 300;
  line-height: 58px;
  letter-spacing: 0.015625rem;
  color: #fff;
  margin-top: -11px;
}
@media print, screen and (min-width: 64em) {
  .hero-landing__heading {
    font-size: 84px;
    line-height: 96px;
    letter-spacing: 0.05625rem;
  }
}
@media print, screen and (min-width: 64em) {
  .hero-landing__heading {
    margin-top: -22px;
  }
}

.hero-landing__content-container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  background-color: #fff;
}
@media print, screen and (min-width: 40em) {
  .hero-landing__content-container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .hero-landing__content-container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .hero-landing__content-container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .hero-landing__content-container {
    width: 90%;
    max-width: 1404px;
  }
}

.hero-landing__content-inner {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  margin-left: 8.3333333333%;
  padding-bottom: 30px;
}
@media print, screen and (min-width: 40em) {
  .hero-landing__content-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .hero-landing__content-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .hero-landing__content-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .hero-landing__content-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 40em) {
  .hero-landing__content-inner {
    margin-left: 8.3333333333%;
  }
}
@media print, screen and (min-width: 64em) {
  .hero-landing__content-inner {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

.hero-landing__content-wrapper {
  position: relative;
}

.hero-landing__content-icon-inner {
  width: 36px;
  height: calc(100% + 68px);
  position: absolute;
  bottom: 0;
  transform: translate(-60px);
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media print, screen and (min-width: 40em) {
  .hero-landing__content-icon-inner {
    transform: translate(-61px, -2px);
  }
}
@media print, screen and (min-width: 64em) {
  .hero-landing__content-icon-inner {
    overflow: hidden;
  }
}

.hero-landing__content-icon-line {
  position: absolute;
  bottom: 15px;
  width: 1px;
  height: 100%;
  background-color: #c41230;
  transform: translate(19px, -1px);
}
@media print, screen and (min-width: 64em) {
  .hero-landing__content-icon-line {
    transform: none;
  }
}

.hero-landing__content-icon {
  position: absolute;
  bottom: 0;
  height: 15px;
  width: 15px;
  order: 1;
  transform: translate(19px, -1px);
}
@media print, screen and (min-width: 64em) {
  .hero-landing__content-icon {
    transform: none;
  }
}

@media all and (-ms-high-contrast: none) {
  .hero-landing__content-icon {
    transform: translateX(-8px);
  }
}
.hero-landing__copy {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0.015625rem;
  padding-bottom: 30px;
}
@media print, screen and (min-width: 64em) {
  .hero-landing__copy {
    font-size: 24px;
    line-height: 36px;
  }
}
@media print, screen and (min-width: 64em) {
  .hero-landing__copy {
    padding-bottom: 40px;
  }
}

.hero-landing__cta .base-cta-primary {
  margin-top: 0;
}

.hero-landing__quicklinks {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  transform: translate(0, -25px);
  order: -1;
  z-index: 2;
}
@media print, screen and (min-width: 40em) {
  .hero-landing__quicklinks {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .hero-landing__quicklinks {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .hero-landing__quicklinks {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .hero-landing__quicklinks {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .hero-landing__quicklinks {
    order: 1;
    flex: 0 0 25%;
    max-width: 25%;
    margin-left: 8.3333333333%;
    transform: translate(0, -40px);
  }
}
.hero-landing__quicklinks a:focus {
  outline: 2px dotted #000;
  outline-offset: 4px;
}

.hero-landing__quicklinks-inner {
  background-color: #edece2;
  padding: 30px;
}

.hero-landing__quicklinks-header {
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.09375rem;
  color: #c41230;
  text-transform: uppercase;
  padding-bottom: 20px;
}

.hero-landing__quicklink-text {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.04375rem;
  font-weight: 500;
  padding-bottom: 16px;
}

.hero-landing__video-button {
  position: absolute;
  right: 35px;
  bottom: 35px;
  z-index: 1;
  width: 36px;
  height: 36px;
  padding: 8px;
  border: 2px solid #fff;
  border-radius: 50%;
  background-color: rgba(35, 31, 32, 0.4);
  fill: #fff;
}
@media print, screen and (min-width: 64em) {
  .hero-landing__video-button {
    right: 50px;
    bottom: 50px;
    width: 36px;
    height: 36px;
  }
}
.hero-landing__video-button::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 50%;
  transition-property: border-color;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  content: "";
}
.hero-landing__video-button:hover::before {
  border-color: #fff;
}
.hero-landing__video-button:focus {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}

.hero-landing__video-button-icon--pause {
  width: 100%;
  display: none;
}
.hero-landing__video-button.isPlaying .hero-landing__video-button-icon--pause {
  display: block;
}

.hero-landing__video-button-icon--play {
  width: 100%;
  display: block;
}
.hero-landing__video-button.isPlaying .hero-landing__video-button-icon--play {
  display: none;
}

.hero-primary__video {
  position: absolute;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .hero-landing__header-icon-line {
    position: absolute;
    left: 0;
  }
  .hero-landing__header-icon-container {
    position: static;
  }
}
.hero-landing__heading-inner {
  align-items: flex-end;
}

.hero-landing__background,
.hero-landing__background-image,
.hero-landing__header-container {
  height: 370px;
}
@media print, screen and (min-width: 40em) {
  .hero-landing__background,
  .hero-landing__background-image,
  .hero-landing__header-container {
    height: 400px;
  }
}
@media print, screen and (min-width: 64em) {
  .hero-landing__background,
  .hero-landing__background-image,
  .hero-landing__header-container {
    height: 450px;
  }
}

.hero-landing__subheading {
  color: #c41230;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  letter-spacing: 0.04375rem;
  line-height: 28px;
  font-size: 20px;
}
@media print, screen and (min-width: 40em) {
  .hero-landing__subheading {
    line-height: 36px;
    font-size: 26px;
  }
}

.hero-landing__heading {
  font-size: 42px;
  line-height: 48px;
}
@media print, screen and (min-width: 40em) {
  .hero-landing__heading {
    font-size: 60px;
    line-height: 68px;
  }
}
@media print, screen and (min-width: 64em) {
  .hero-landing__heading {
    font-size: 60px;
    line-height: 68px;
  }
}

.hero-landing__heading-container {
  margin-bottom: 48px;
}
@media print, screen and (min-width: 40em) {
  .hero-landing__heading-container {
    margin-bottom: 64px;
  }
}
@media print, screen and (min-width: 64em) {
  .hero-landing__heading-container {
    margin-bottom: 32px;
  }
}

.hero-landing__content-icon-line {
  height: 100%;
}
@media print, screen and (min-width: 64em) {
  .hero-landing__content-icon-line {
    height: 90%;
  }
}

@media print, screen and (min-width: 64em) {
  .hero-landing__content-inner {
    padding-top: 30px;
  }
}

.feature-text {
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #c41230;
  background-image: url("../images/illustrations/illustration-duotone-red.png");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}
@media print, screen and (min-width: 40em) {
  .feature-text {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media print, screen and (min-width: 64em) {
  .feature-text {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media screen and (min-width: 75em) {
  .feature-text {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
.feature-text .feature-text__container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .feature-text .feature-text__container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .feature-text .feature-text__container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .feature-text .feature-text__container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .feature-text .feature-text__container {
    width: 90%;
    max-width: 1404px;
  }
}
@media print, screen and (min-width: 40em) {
  .feature-text .feature-text__container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .feature-text .feature-text__container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .feature-text .feature-text__container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .feature-text .feature-text__container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.feature-text .feature-text__container .feature-text__inner {
  position: relative;
  flex: 0 0 100%;
  max-width: 100%;
}
.feature-text .feature-text__container .feature-text__inner .feature-text__image-container {
  padding-bottom: 15px;
}
.feature-text .feature-text__container .feature-text__inner .feature-text__image-container .feature-text__image {
  width: 80px;
  margin: auto;
}
.feature-text .feature-text__container .feature-text__inner .feature-text__heading {
  font-family: freight-display-pro, serif;
  font-size: 38px;
  line-height: 48px;
  font-weight: 400;
  color: #fff;
}
@media print, screen and (min-width: 64em) {
  .feature-text .feature-text__container .feature-text__inner .feature-text__heading {
    font-weight: 300;
    font-size: 44px;
    line-height: 50px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
.feature-text .feature-text__container .feature-text__inner .feature-text__copy-container {
  padding-top: 15px;
}
.feature-text .feature-text__container .feature-text__inner .feature-text__copy-container .feature-text__copy {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
  color: #fff;
  padding-bottom: 20px;
}
.feature-text .feature-text__container .feature-text__inner .feature-text__copy-container .feature-text__copy a {
  color: #fff;
  text-decoration: underline;
  font-style: italic;
  outline: 2px dotted transparent;
  outline-offset: 0;
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.feature-text .feature-text__container .feature-text__inner .feature-text__copy-container .feature-text__copy a:hover {
  text-decoration: none;
}
.feature-text .feature-text__container .feature-text__inner .feature-text__copy-container .feature-text__copy a:focus {
  text-decoration: none;
  outline: 2px dotted #fff;
}
.feature-text .feature-text__container .feature-text__inner .feature-text__copy-container .feature-text__copy h6 {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: 45px;
  color: #fff;
}
@media print, screen and (min-width: 64em) {
  .feature-text .feature-text__container .feature-text__inner .feature-text__copy-container .feature-text__copy h6 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.feature-text .feature-text__container .feature-text__inner .feature-text__copy-container .feature-text__copy h5 {
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 45px;
  color: #fff;
}
@media print, screen and (min-width: 64em) {
  .feature-text .feature-text__container .feature-text__inner .feature-text__copy-container .feature-text__copy h5 {
    font-size: 20px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #c41230;
  }
}
.feature-text .feature-text__container .feature-text__inner .feature-text__copy-container .feature-text__copy h4 {
  font-family: proxima-nova, sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 45px;
  color: #fff;
}
@media print, screen and (min-width: 64em) {
  .feature-text .feature-text__container .feature-text__inner .feature-text__copy-container .feature-text__copy h4 {
    font-size: 26px;
    line-height: 32px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.feature-text .feature-text__container .feature-text__inner .feature-text__copy-container .feature-text__copy h3 {
  font-family: freight-display-pro, serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 300;
  margin-bottom: 15px;
  margin-top: 45px;
  color: #fff;
}
@media print, screen and (min-width: 64em) {
  .feature-text .feature-text__container .feature-text__inner .feature-text__copy-container .feature-text__copy h3 {
    font-size: 38px;
    line-height: 44px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
.feature-text .feature-text__container .feature-text__inner .feature-text__copy-container .feature-text__copy h3.table-header {
  font-family: proxima-nova, sans-serif;
  font-size: 20px;
  line-height: 1.3;
  font-weight: 500;
  margin-bottom: 20px;
}
@media print, screen and (min-width: 64em) {
  .feature-text .feature-text__container .feature-text__inner .feature-text__copy-container .feature-text__copy h3.table-header {
    font-size: 24px;
    line-height: 1.25;
  }
}
.feature-text .feature-text__container .feature-text__inner .feature-text__copy-container .feature-text__copy p {
  margin-bottom: 20px;
}
.feature-text .feature-text__container .feature-text__inner .feature-text__copy-container .feature-text__copy ul {
  margin-bottom: 20px;
}
.feature-text .feature-text__container .feature-text__inner .feature-text__copy-container .feature-text__copy ul li {
  padding: 8px 0 8px 30px;
}
.feature-text .feature-text__container .feature-text__inner .feature-text__copy-container .feature-text__copy ul li:before {
  content: "";
  background-image: url("../svgs/functional/icon-list-style--white.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 20px;
  margin-left: -30px;
}
.feature-text .feature-text__container .feature-text__inner .feature-text__copy-container .feature-text__copy ol {
  list-style: none;
  counter-reset: my-awesome-counter;
  margin-bottom: 20px;
}
.feature-text .feature-text__container .feature-text__inner .feature-text__copy-container .feature-text__copy ol li {
  padding: 8px 0;
  counter-increment: my-awesome-counter;
  padding-left: 36px;
}
.feature-text .feature-text__container .feature-text__inner .feature-text__copy-container .feature-text__copy ol li:before {
  content: counter(my-awesome-counter);
  font-family: proxima-nova, sans-serif;
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.75px;
  margin-right: 16px;
  text-align: center;
  display: inline-block;
  width: 20px;
  vertical-align: middle;
  margin-left: -36px;
}
.feature-text .feature-text__container .feature-text__inner .feature-text__copy-container .base-cta-primary {
  margin-top: 10px;
}
@media print, screen and (min-width: 64em) {
  .feature-text .feature-text__container .feature-text__inner .feature-text__copy-container .base-cta-primary {
    margin-top: 20px;
  }
}
.feature-text.feature-text--2 .feature-text__container .feature-text__inner {
  padding-top: 60px;
  border-top: solid 1px #fff;
}
.feature-text.feature-text--2 .feature-text__container .feature-text__inner::before {
  position: absolute;
  top: -5px;
  left: 0;
  content: " ";
  width: 100px;
  height: 4px;
  background-color: #fff;
}
.feature-text.feature-text--2 .feature-text__container .feature-text__inner-container {
  display: flex;
  flex-direction: column;
}
@media print, screen and (min-width: 64em) {
  .feature-text.feature-text--2 .feature-text__container .feature-text__inner-container {
    flex-direction: row;
  }
}
.feature-text.feature-text--2 .feature-text__container .feature-text__inner-container .feature-text__header-container {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}
@media print, screen and (min-width: 64em) {
  .feature-text.feature-text--2 .feature-text__container .feature-text__inner-container .feature-text__header-container {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
    min-height: 70px;
  }
}
.feature-text.feature-text--2 .feature-text__container .feature-text__inner-container .feature-text__copy-container {
  flex: 0 0 100%;
  max-width: 100%;
  min-height: 212px;
}
@media print, screen and (min-width: 64em) {
  .feature-text.feature-text--2 .feature-text__container .feature-text__inner-container .feature-text__copy-container {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
}
.feature-text.feature-text--1 {
  text-align: center;
}
.feature-text.feature-text--1 .feature-text__container .feature-text__inner {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
  margin-left: 8.3333333333%;
}
.feature-text.feature-text--1 .feature-text__container .feature-text__inner .feature-text__inner {
  padding-top: 60px;
}
.feature-text.feature-text--1 .feature-text__container .feature-text__inner .feature-text__inner-container {
  display: flex;
  flex-direction: column;
}
.feature-text.feature-text--1 .feature-text__container .feature-text__inner .feature-text__inner-container .feature-text__header-container {
  flex: 0 0 100%;
  max-width: 100%;
}
@media print, screen and (min-width: 64em) {
  .feature-text.feature-text--1 .feature-text__container .feature-text__inner .feature-text__inner-container .feature-text__header-container {
    min-height: 70px;
  }
}
.feature-text.feature-text--1 .feature-text__container .feature-text__inner .feature-text__inner-container .feature-text__copy-container {
  padding-top: 15px !important;
  flex: 0 0 100%;
  max-width: 100%;
  min-height: 212px;
}
.feature-text.feature-text--1 .feature-text__container .feature-text__inner .feature-text__inner-container .feature-text__copy-container .feature-text__copy {
  font-size: 18px;
  line-height: 1.67;
}
@media print, screen and (min-width: 64em) {
  .feature-text.feature-text--1 .feature-text__container .feature-text__inner .feature-text__inner-container .feature-text__copy-container .feature-text__copy {
    font-size: 24px;
    line-height: 1.5;
  }
}

.feature-image {
  padding-bottom: 80px;
  background-color: #c41230;
}
@media print, screen and (min-width: 40em) {
  .feature-image {
    padding-bottom: 60px;
  }
}
@media print, screen and (min-width: 60em) {
  .feature-image {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.feature-image .feature-image__container {
  position: relative;
}
@media print, screen and (min-width: 60em) {
  .feature-image .feature-image__container::after {
    content: " ";
    position: absolute;
    width: 1px;
    height: calc(100% - 105px);
    background-color: #fff;
    top: 90px;
    right: calc(17% - 40px);
  }
}
.feature-image .feature-image__container .feature-image__inner {
  position: relative;
  width: 100%;
  padding-top: 40px;
}
.feature-image .feature-image__container .feature-image__inner .feature-image__heading__icon {
  display: none;
}
@media screen and (min-width: 60em) {
  .feature-image .feature-image__container .feature-image__inner .feature-image__heading__icon {
    display: block;
  }
}
@media print, screen and (min-width: 60em) {
  .feature-image .feature-image__container .feature-image__inner {
    width: 83%;
    height: 600px;
    padding: 32px 44px;
  }
  .feature-image .feature-image__container .feature-image__inner .feature-image__heading__icon {
    content: " ";
    position: absolute;
    width: 50px;
    height: 50px;
    top: 0;
    right: -65px;
  }
  .feature-image .feature-image__container .feature-image__inner .feature-image__heading__icon.flip {
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
  }
  .feature-image .feature-image__container .feature-image__inner::after {
    content: " ";
    position: absolute;
    width: 15px;
    height: 15px;
    bottom: 0px;
    right: -47px;
    background-image: url("../svgs/functional/icon-list-style--white.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
}
@media print, screen and (min-width: 60em) {
  .feature-image .feature-image__container .feature-image__inner .feature-image__gradient-wrapper::before {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
    height: calc(100% - 125px);
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: auto;
    content: "";
    z-index: 1;
  }
  .feature-image .feature-image__container .feature-image__inner .feature-image__gradient-wrapper::after {
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 125px;
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
  }
}
.feature-image .feature-image__container .feature-image__inner .feature-image__image {
  padding-bottom: 20px;
  padding-right: 25px;
  width: calc(100% - 25px);
  object-fit: cover;
}
@media print, screen and (min-width: 60em) {
  .feature-image .feature-image__container .feature-image__inner .feature-image__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-bottom: 0;
    padding-right: 0;
  }
}
.feature-image .feature-image__container .feature-image__inner .feature-image__text-container {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  margin: 0 auto;
  position: relative;
}
@media print, screen and (min-width: 40em) {
  .feature-image .feature-image__container .feature-image__inner .feature-image__text-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .feature-image .feature-image__container .feature-image__inner .feature-image__text-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .feature-image .feature-image__container .feature-image__inner .feature-image__text-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .feature-image .feature-image__container .feature-image__inner .feature-image__text-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 60em) {
  .feature-image .feature-image__container .feature-image__inner .feature-image__text-container {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    flex: 0 0 87.5%;
    max-width: 87.5%;
    position: absolute;
    bottom: 40px;
    z-index: 9;
  }
}
@media print, screen and (min-width: 60em) and (min-width: 40em) {
  .feature-image .feature-image__container .feature-image__inner .feature-image__text-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 60em) and (min-width: 64em) {
  .feature-image .feature-image__container .feature-image__inner .feature-image__text-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 60em) and (min-width: 75em) {
  .feature-image .feature-image__container .feature-image__inner .feature-image__text-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 60em) and (min-width: 90em) {
  .feature-image .feature-image__container .feature-image__inner .feature-image__text-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.feature-image .feature-image__container .feature-image__inner .feature-image__text-container .feature-image__heading {
  font-family: freight-display-pro, serif;
  font-size: 38px;
  line-height: 48px;
  font-weight: 400;
  color: #fff;
  padding-bottom: 15px;
}
@media print, screen and (min-width: 64em) {
  .feature-image .feature-image__container .feature-image__inner .feature-image__text-container .feature-image__heading {
    font-weight: 300;
    font-size: 44px;
    line-height: 50px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
@media print, screen and (min-width: 60em) {
  .feature-image .feature-image__container .feature-image__inner .feature-image__text-container .feature-image__heading {
    font-family: freight-display-pro, serif;
    font-size: 30px;
    line-height: 36px;
    font-weight: 300;
  }
}
@media print, screen and (min-width: 60em) and (min-width: 64em) {
  .feature-image .feature-image__container .feature-image__inner .feature-image__text-container .feature-image__heading {
    font-size: 38px;
    line-height: 44px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
@media print, screen and (min-width: 64em) {
  .feature-image .feature-image__container .feature-image__inner .feature-image__text-container .feature-image__heading {
    padding-bottom: 16px;
  }
}
.feature-image .feature-image__container .feature-image__inner .feature-image__text-container .feature-image__copy {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
  color: #fff;
}
.feature-image .feature-image__container .feature-image__inner .feature-image__text-container .feature-image__copy a {
  color: #fff;
  text-decoration: underline;
  font-style: italic;
  outline: 2px dotted transparent;
  outline-offset: 0;
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.feature-image .feature-image__container .feature-image__inner .feature-image__text-container .feature-image__copy a:hover {
  text-decoration: none;
}
.feature-image .feature-image__container .feature-image__inner .feature-image__text-container .feature-image__copy a:focus {
  text-decoration: none;
  outline: 2px dotted #fff;
}
.feature-image .feature-image__container .feature-image__inner .feature-image__text-container .feature-image__copy h6 {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: 45px;
  color: #fff;
}
@media print, screen and (min-width: 64em) {
  .feature-image .feature-image__container .feature-image__inner .feature-image__text-container .feature-image__copy h6 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.feature-image .feature-image__container .feature-image__inner .feature-image__text-container .feature-image__copy h5 {
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 45px;
  color: #fff;
}
@media print, screen and (min-width: 64em) {
  .feature-image .feature-image__container .feature-image__inner .feature-image__text-container .feature-image__copy h5 {
    font-size: 20px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #c41230;
  }
}
.feature-image .feature-image__container .feature-image__inner .feature-image__text-container .feature-image__copy h4 {
  font-family: proxima-nova, sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 45px;
  color: #fff;
}
@media print, screen and (min-width: 64em) {
  .feature-image .feature-image__container .feature-image__inner .feature-image__text-container .feature-image__copy h4 {
    font-size: 26px;
    line-height: 32px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.feature-image .feature-image__container .feature-image__inner .feature-image__text-container .feature-image__copy h3 {
  font-family: freight-display-pro, serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 300;
  margin-bottom: 15px;
  margin-top: 45px;
  color: #fff;
}
@media print, screen and (min-width: 64em) {
  .feature-image .feature-image__container .feature-image__inner .feature-image__text-container .feature-image__copy h3 {
    font-size: 38px;
    line-height: 44px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
.feature-image .feature-image__container .feature-image__inner .feature-image__text-container .feature-image__copy h3.table-header {
  font-family: proxima-nova, sans-serif;
  font-size: 20px;
  line-height: 1.3;
  font-weight: 500;
  margin-bottom: 20px;
}
@media print, screen and (min-width: 64em) {
  .feature-image .feature-image__container .feature-image__inner .feature-image__text-container .feature-image__copy h3.table-header {
    font-size: 24px;
    line-height: 1.25;
  }
}
.feature-image .feature-image__container .feature-image__inner .feature-image__text-container .feature-image__copy p {
  margin-bottom: 20px;
}
.feature-image .feature-image__container .feature-image__inner .feature-image__text-container .feature-image__copy ul {
  margin-bottom: 20px;
}
.feature-image .feature-image__container .feature-image__inner .feature-image__text-container .feature-image__copy ul li {
  padding: 8px 0 8px 30px;
}
.feature-image .feature-image__container .feature-image__inner .feature-image__text-container .feature-image__copy ul li:before {
  content: "";
  background-image: url("../svgs/functional/icon-list-style--white.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 20px;
  margin-left: -30px;
}
.feature-image .feature-image__container .feature-image__inner .feature-image__text-container .feature-image__copy ol {
  list-style: none;
  counter-reset: my-awesome-counter;
  margin-bottom: 20px;
}
.feature-image .feature-image__container .feature-image__inner .feature-image__text-container .feature-image__copy ol li {
  padding: 8px 0;
  counter-increment: my-awesome-counter;
  padding-left: 36px;
}
.feature-image .feature-image__container .feature-image__inner .feature-image__text-container .feature-image__copy ol li:before {
  content: counter(my-awesome-counter);
  font-family: proxima-nova, sans-serif;
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.75px;
  margin-right: 16px;
  text-align: center;
  display: inline-block;
  width: 20px;
  vertical-align: middle;
  margin-left: -36px;
}

.content-hub {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #fff;
}
.content-hub.collection-list--white-warm {
  background-color: #faf9f7;
}
.content-hub.collection-list--red {
  background-color: #c41230;
}
@media print, screen and (min-width: 40em) {
  .content-hub {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media print, screen and (min-width: 64em) {
  .content-hub {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.content-hub__heading-container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  margin-bottom: 24px;
}
@media print, screen and (min-width: 40em) {
  .content-hub__heading-container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .content-hub__heading-container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .content-hub__heading-container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .content-hub__heading-container {
    width: 90%;
    max-width: 1404px;
  }
}
.content-hub__heading-container .content-hub__heading-inner {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  align-self: center;
  justify-self: center;
  padding-bottom: 16px;
  flex: 0 0 100%;
  max-width: 100%;
}
@media print, screen and (min-width: 40em) {
  .content-hub__heading-container .content-hub__heading-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .content-hub__heading-container .content-hub__heading-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .content-hub__heading-container .content-hub__heading-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .content-hub__heading-container .content-hub__heading-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .content-hub__heading-container .content-hub__heading-inner {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .content-hub__heading-container .content-hub__heading-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .content-hub__heading-container .content-hub__heading-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .content-hub__heading-container .content-hub__heading-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .content-hub__heading-container .content-hub__heading-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.content-hub__heading-container .content-hub__heading-inner .content-hub__heading {
  font-family: freight-display-pro, serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 300;
  color: #c41230;
  padding-bottom: 16px;
}
@media print, screen and (min-width: 64em) {
  .content-hub__heading-container .content-hub__heading-inner .content-hub__heading {
    font-size: 38px;
    line-height: 44px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
@media print, screen and (min-width: 64em) {
  .content-hub__heading-container .content-hub__heading-inner .content-hub__heading {
    padding-bottom: 0px;
  }
}

.content-hub__content--container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .content-hub__content--container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .content-hub__content--container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .content-hub__content--container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .content-hub__content--container {
    width: 90%;
    max-width: 1404px;
  }
}
@media print, screen and (min-width: 40em) {
  .content-hub__content--container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .content-hub__content--container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .content-hub__content--container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .content-hub__content--container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.content-hub__content--container a:focus {
  outline: 2px dotted #000;
  outline-offset: 4px;
}

.content-hub__featured-container {
  margin-right: -0.46875rem;
  margin-left: -0.46875rem;
  margin-right: -0.46875rem;
  margin-left: -0.46875rem;
  display: flex;
  flex-flow: row wrap;
  border-right: none;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 28px;
}
@media print, screen and (min-width: 40em) {
  .content-hub__featured-container {
    margin-right: -0.78125rem;
    margin-left: -0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .content-hub__featured-container {
    margin-right: -0.78125rem;
    margin-left: -0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .content-hub__featured-container {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    border-right: 1px solid #d1d0d0;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .content-hub__featured-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .content-hub__featured-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .content-hub__featured-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .content-hub__featured-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.content-hub__featured-container .content-hub__featured-headline-inner {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  margin-bottom: 20px;
}
@media print, screen and (min-width: 40em) {
  .content-hub__featured-container .content-hub__featured-headline-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .content-hub__featured-container .content-hub__featured-headline-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .content-hub__featured-container .content-hub__featured-headline-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .content-hub__featured-container .content-hub__featured-headline-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .content-hub__featured-container .content-hub__featured-headline-inner {
    padding-left: 0;
  }
}

.content-hub__headline {
  font-family: proxima-nova, sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  font-size: 24px;
  color: #c41230;
}
@media print, screen and (min-width: 64em) {
  .content-hub__headline {
    font-size: 26px;
    line-height: 32px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

.content-hub__featured-big {
  width: 100%;
  margin-bottom: 40px;
}
@media print, screen and (min-width: 40em) {
  .content-hub__featured-big {
    width: 65%;
  }
}
@media print, screen and (min-width: 64em) {
  .content-hub__featured-big {
    margin-bottom: 0;
  }
}

.content-hub__featured-small {
  width: 100%;
}
@media print, screen and (min-width: 40em) {
  .content-hub__featured-small {
    width: 35%;
  }
}
.content-hub__featured-small .content-hub__featured:first-child {
  margin-bottom: 32px;
}

.content-hub__featured {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  position: relative;
}
@media print, screen and (min-width: 40em) {
  .content-hub__featured {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .content-hub__featured {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .content-hub__featured {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .content-hub__featured {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .content-hub__featured {
    padding-left: 0;
    padding-right: 0;
    margin-right: 0.9375rem;
  }
}
.content-hub__featured a {
  display: block;
}
.content-hub__featured .content-hub__featured-image {
  width: 100%;
}
.content-hub__featured .content-hub__featured-inner {
  position: relative;
  padding-bottom: 32px;
  border-bottom: 1px solid #d1d0d0;
}
.content-hub__featured .content-hub__featured-inner::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0;
  background-color: #fff;
  left: 0;
  bottom: 0;
  overflow: hidden;
  transition: height 0.2s ease-out;
}
@media print, screen and (min-width: 64em) {
  .content-hub__featured .content-hub__featured-inner {
    padding-bottom: 16px;
  }
}
.content-hub__featured .content-hub__featured-inner .content-hub__featured-headline {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.04375rem;
  color: #c41230;
  font-style: italic;
  margin-top: 24px;
  margin-bottom: 16px;
}
@media print, screen and (min-width: 64em) {
  .content-hub__featured .content-hub__featured-inner .content-hub__featured-headline {
    margin-top: 12px;
    margin-bottom: 8px;
  }
}
.content-hub__featured .content-hub__featured-inner .content-hub__featured-copy {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  display: inline;
  line-height: 1.5;
  color: #231f20;
  padding-bottom: 2px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  transition-property: border-bottom-color;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
@media print, screen and (min-width: 64em) {
  .content-hub__featured .content-hub__featured-inner .content-hub__featured-copy {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.content-hub__featured:hover {
  cursor: pointer;
}
.content-hub__featured:hover .content-hub__featured-copy {
  border-color: #231f20;
}

@media print, screen and (min-width: 64em) {
  .content-hub__link--container {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .content-hub__link--container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .content-hub__link--container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .content-hub__link--container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .content-hub__link--container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.content-hub__link--container .content-hub__link--heading-inner {
  margin-bottom: 20px;
}
@media print, screen and (min-width: 64em) {
  .content-hub__link--container .content-hub__link--heading-inner {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .content-hub__link--container .content-hub__link--heading-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .content-hub__link--container .content-hub__link--heading-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .content-hub__link--container .content-hub__link--heading-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .content-hub__link--container .content-hub__link--heading-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .content-hub__link--container .content-hub--divider-wrapper {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .content-hub__link--container .content-hub--divider-wrapper {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .content-hub__link--container .content-hub--divider-wrapper {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .content-hub__link--container .content-hub--divider-wrapper {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .content-hub__link--container .content-hub--divider-wrapper {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.content-hub__link--container .content-hub--divider-wrapper .content-hub--divider {
  margin-top: 28px;
  margin-bottom: 28px;
  border-bottom: 1px solid #ccc9b8;
}
@media print, screen and (min-width: 64em) {
  .content-hub__link--container .content-hub__link {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .content-hub__link--container .content-hub__link {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .content-hub__link--container .content-hub__link {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .content-hub__link--container .content-hub__link {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .content-hub__link--container .content-hub__link {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.content-hub__link--container .content-hub__link .content-hub__link-category {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.04375rem;
  font-style: italic;
  color: #c41230;
  margin-top: 20px;
  padding-bottom: 8px;
}
.content-hub__link--container .content-hub__link .content-hub__link-date {
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.09375rem;
  text-transform: uppercase;
  color: #7e6d53;
  margin-top: 20px;
  padding-bottom: 8px;
}
.content-hub__link--container .content-hub__link .content-hub__link-text {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  display: inline;
  line-height: 1.5;
  color: #231f20;
  padding-bottom: 2px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  transition-property: border-bottom-color;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
@media print, screen and (min-width: 64em) {
  .content-hub__link--container .content-hub__link .content-hub__link-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.content-hub__link--container .content-hub__link .content-hub__link-text:hover {
  cursor: pointer;
  border-color: #231f20;
}
.content-hub__link--container .content-hub__link .content-hub__link-text:hover .base-link-text {
  text-decoration: none;
}
@media print, screen and (min-width: 64em) {
  .content-hub__link--container .content-hub__link-cta {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .content-hub__link--container .content-hub__link-cta {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .content-hub__link--container .content-hub__link-cta {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .content-hub__link--container .content-hub__link-cta {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .content-hub__link--container .content-hub__link-cta {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.content-hub__link--container .localist-widget {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .content-hub__link--container .localist-widget {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .content-hub__link--container .localist-widget {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .content-hub__link--container .localist-widget {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .content-hub__link--container .localist-widget {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.content-hub__link--container .localist-widget .classicList .lw .lwe {
  padding: 20px 10px !important;
}
.content-hub__link--container .localist-widget .classicList .lw .lwe .lwn {
  display: flex;
  flex-direction: column;
}
.content-hub__link--container .localist-widget .classicList .lw .lwe .lwn .lwn0 {
  color: #7e6d53;
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.09375rem;
  line-height: 20px;
  padding-bottom: 8px;
  text-transform: uppercase;
}
.content-hub__link--container .localist-widget .classicList .lw .lwe .lwn a {
  color: #231f20 !important;
  display: inline;
  font-family: proxima-nova, sans-serif;
  font-size: 18px !important;
  font-weight: 700;
  line-height: 24px;
  transition-duration: 0.3s;
  transition-property: border-bottom-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.content-hub__link--container .localist-widget .classicList .lw .lwe .lwl .lwl0, .content-hub__link--container .localist-widget .classicList .lw .lwe .lwl a {
  color: #7e6d53;
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.09375rem;
  line-height: 20px;
  margin-top: 20px;
  padding-bottom: 8px;
  text-transform: uppercase;
}
.content-hub__link--container .localist-widget .modernList .localist_widget_container ul.lw_event_list li.lw_event_item, .content-hub__link--container .localist-widget .verticalModern .localist_widget_container ul.lw_event_list li.lw_event_item {
  border: none !important;
  padding: 0 0 15px !important;
}
.content-hub__link--container .localist-widget .modernList .localist_widget_container ul.lw_event_list li.lw_event_item div.lw_event_content, .content-hub__link--container .localist-widget .verticalModern .localist_widget_container ul.lw_event_list li.lw_event_item div.lw_event_content {
  padding: 0 0 0 20px !important;
}
.content-hub__link--container .localist-widget .modernList .localist_widget_container ul.lw_event_list li.lw_event_item div.lw_event_content div.action_button, .content-hub__link--container .localist-widget .verticalModern .localist_widget_container ul.lw_event_list li.lw_event_item div.lw_event_content div.action_button {
  display: none !important;
}

.feature-stat {
  padding-top: 72px;
  padding-bottom: 72px;
  background-color: #c41230;
  background-image: url("../images/illustrations/illustration-duotone-red.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.feature-stat__inner {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .feature-stat__inner {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .feature-stat__inner {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .feature-stat__inner {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .feature-stat__inner {
    width: 90%;
    max-width: 1404px;
  }
}

.feature-stat__wrapper {
  display: flex;
  flex-direction: column;
}
@media print, screen and (min-width: 64em) {
  .feature-stat__wrapper {
    flex-direction: row;
  }
}

.feature-stat__text-container .feature-stat__text-heading {
  font-family: freight-display-pro, serif;
  font-size: 58px;
  font-weight: 300;
  line-height: 68px;
  color: #fff;
  margin-bottom: 15px;
}
.feature-stat__text-container .feature-stat__text-copy {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
  color: #fff;
  margin-bottom: 30px;
}
.feature-stat__text-container .feature-stat__text-copy a {
  color: #fff;
  text-decoration: underline;
  font-style: italic;
  outline: 2px dotted transparent;
  outline-offset: 0;
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.feature-stat__text-container .feature-stat__text-copy a:hover {
  text-decoration: none;
}
.feature-stat__text-container .feature-stat__text-copy a:focus {
  text-decoration: none;
  outline: 2px dotted #fff;
}
.feature-stat__text-container .feature-stat__text-copy h6 {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: 45px;
  color: #fff;
}
@media print, screen and (min-width: 64em) {
  .feature-stat__text-container .feature-stat__text-copy h6 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.feature-stat__text-container .feature-stat__text-copy h5 {
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 45px;
  color: #fff;
}
@media print, screen and (min-width: 64em) {
  .feature-stat__text-container .feature-stat__text-copy h5 {
    font-size: 20px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #c41230;
  }
}
.feature-stat__text-container .feature-stat__text-copy h4 {
  font-family: proxima-nova, sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 45px;
  color: #fff;
}
@media print, screen and (min-width: 64em) {
  .feature-stat__text-container .feature-stat__text-copy h4 {
    font-size: 26px;
    line-height: 32px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.feature-stat__text-container .feature-stat__text-copy h3 {
  font-family: freight-display-pro, serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 300;
  margin-bottom: 15px;
  margin-top: 45px;
  color: #fff;
}
@media print, screen and (min-width: 64em) {
  .feature-stat__text-container .feature-stat__text-copy h3 {
    font-size: 38px;
    line-height: 44px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
.feature-stat__text-container .feature-stat__text-copy h3.table-header {
  font-family: proxima-nova, sans-serif;
  font-size: 20px;
  line-height: 1.3;
  font-weight: 500;
  margin-bottom: 20px;
}
@media print, screen and (min-width: 64em) {
  .feature-stat__text-container .feature-stat__text-copy h3.table-header {
    font-size: 24px;
    line-height: 1.25;
  }
}
.feature-stat__text-container .feature-stat__text-copy p {
  margin-bottom: 20px;
}
.feature-stat__text-container .feature-stat__text-copy ul {
  margin-bottom: 20px;
}
.feature-stat__text-container .feature-stat__text-copy ul li {
  padding: 8px 0 8px 30px;
}
.feature-stat__text-container .feature-stat__text-copy ul li:before {
  content: "";
  background-image: url("../svgs/functional/icon-list-style--white.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 20px;
  margin-left: -30px;
}
.feature-stat__text-container .feature-stat__text-copy ol {
  list-style: none;
  counter-reset: my-awesome-counter;
  margin-bottom: 20px;
}
.feature-stat__text-container .feature-stat__text-copy ol li {
  padding: 8px 0;
  counter-increment: my-awesome-counter;
  padding-left: 36px;
}
.feature-stat__text-container .feature-stat__text-copy ol li:before {
  content: counter(my-awesome-counter);
  font-family: proxima-nova, sans-serif;
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.75px;
  margin-right: 16px;
  text-align: center;
  display: inline-block;
  width: 20px;
  vertical-align: middle;
  margin-left: -36px;
}

.feature-stat__image-container {
  padding-top: 40px;
}
@media print, screen and (min-width: 64em) {
  .feature-stat__image-container {
    padding-top: 0px;
    margin-left: 130px;
  }
}
.feature-stat__image-container .feature-stat__image-outer {
  border: 1px solid #ccc9b8;
  background-color: #c41230;
}
.feature-stat__image-container .feature-stat__image-outer .feature-stat__image-inner {
  width: 100%;
  height: 380px;
  background-color: #c41230;
  border: 1px solid #ccc9b8;
  transform: translate(-5px, -5px);
  display: flex;
  align-items: center;
}
@media print, screen and (min-width: 64em) {
  .feature-stat__image-container .feature-stat__image-outer .feature-stat__image-inner {
    width: 380px;
  }
}
.feature-stat__image-container .feature-stat__image-outer .feature-stat__image-inner .feature-stat__image {
  margin: auto;
  vertical-align: middle;
  height: 68%;
}

.social {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #fff;
}
@media print, screen and (min-width: 40em) {
  .social {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.social .button-skip-content {
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}
.social .button-skip-content:focus, .social .button-skip-content:active {
  visibility: visible;
  position: static;
  overflow: visible;
  clip: auto;
  width: auto;
  max-width: 1170px;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.social__container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .social__container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .social__container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .social__container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .social__container {
    width: 90%;
    max-width: 1404px;
  }
}
@media print, screen and (min-width: 40em) {
  .social__container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .social__container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .social__container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .social__container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.social__header-container {
  width: 100%;
}
@media print, screen and (min-width: 64em) {
  .social__header-container {
    max-width: 75rem;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-flow: row wrap;
    margin-right: 0.625rem;
    margin-left: 0.625rem;
    flex: 1;
    margin-right: 1.125rem;
    margin-left: 1.125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .social__header-container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .social__header-container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .social__header-container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .social__header-container {
    width: 90%;
    max-width: 1404px;
  }
}

@media print, screen and (min-width: 64em) {
  .social__heading-container {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-left: 0;
    display: flex;
    justify-content: center;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .social__heading-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .social__heading-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .social__heading-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .social__heading-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.social__heading-container-inner {
  width: 100%;
}

.social__header-heading {
  font-family: freight-display-pro, serif;
  font-size: 38px;
  line-height: 48px;
  font-weight: 400;
  color: #c41230;
}
@media print, screen and (min-width: 64em) {
  .social__header-heading {
    font-weight: 300;
    font-size: 44px;
    line-height: 50px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

@media print, screen and (min-width: 64em) {
  .social__copy-container {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    display: flex;
    justify-content: center;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .social__copy-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .social__copy-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .social__copy-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .social__copy-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.social__copy-container-inner {
  width: 100%;
}
@media print, screen and (min-width: 64em) {
  .social__copy-container-inner {
    width: 90%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .social__copy-container-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .social__copy-container-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .social__copy-container-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .social__copy-container-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.social__copy {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
}

.social__content {
  padding-bottom: 16px;
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 0;
}
@media print, screen and (min-width: 64em) {
  .social__content {
    margin-top: 35px;
    width: 100%;
  }
}
.social__content .j-stacker-wrapper {
  margin: 0 !important;
}
.social__content .j-stacker-wrapper .j-stack {
  padding: 0 !important;
}
.social__content .j-stacker-wrapper .j-stack .feed-item {
  position: relative;
  border: 0;
  width: 100%;
  height: 100vw;
  overflow: hidden;
  float: left;
  margin: 0 !important;
}
@media screen and (min-width: 48em) {
  .social__content .j-stacker-wrapper .j-stack .feed-item {
    width: 50%;
    height: 0;
    padding-bottom: 45%;
  }
}
@media screen and (min-width: 60em) {
  .social__content .j-stacker-wrapper .j-stack .feed-item {
    padding-bottom: 40%;
  }
}
@media print, screen and (min-width: 64em) {
  .social__content .j-stacker-wrapper .j-stack .feed-item {
    width: 33.333%;
    padding-bottom: 35%;
  }
}
@media screen and (min-width: 75em) {
  .social__content .j-stacker-wrapper .j-stack .feed-item {
    width: 33.333%;
    padding-bottom: 34.2%;
  }
}
.social__content .j-stacker-wrapper .j-stack .feed-item .j-poster {
  z-index: 4;
  height: 5rem;
  padding: 0;
  position: relative;
  margin: 0;
  overflow: hidden;
}
.social__content .j-stacker-wrapper .j-stack .feed-item .j-poster a:first-child {
  padding: 25px 25px 0 25px;
  color: #fff !important;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  height: 80px;
}
.social__content .j-stacker-wrapper .j-stack .feed-item .j-poster a:first-child .j-date {
  text-transform: none;
  display: none;
}
.social__content .j-stacker-wrapper .j-stack .feed-item .j-poster a:first-child .j-twitter-poster {
  width: calc(100% - 60px);
}
.social__content .j-stacker-wrapper .j-stack .feed-item .j-poster a:first-child .j-twitter-screen-name {
  text-transform: none;
}
.social__content .j-stacker-wrapper .j-stack .feed-item .j-poster a:first-child:hover {
  text-decoration: underline !important;
}
.social__content .j-stacker-wrapper .j-stack .feed-item .j-poster a:first-child:hover .j-twitter-display-name,
.social__content .j-stacker-wrapper .j-stack .feed-item .j-poster a:first-child:hover .j-twitter-screen-name,
.social__content .j-stacker-wrapper .j-stack .feed-item .j-poster a:first-child:hover h3 {
  text-decoration: underline !important;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
}
.social__content .j-stacker-wrapper .j-stack .feed-item .j-poster a:first-child h3,
.social__content .j-stacker-wrapper .j-stack .feed-item .j-poster a:first-child time {
  color: #fff !important;
}
.social__content .j-stacker-wrapper .j-stack .feed-item .j-poster .j-twitter-post-icon {
  color: #fff !important;
  top: 30px;
  right: 25px;
  width: 20px;
  position: absolute;
  text-decoration: none !important;
}
.social__content .j-stacker-wrapper .j-stack .feed-item .j-poster .j-twitter-post-icon:before {
  color: #fff !important;
  font-size: 20px;
}
.social__content .j-stacker-wrapper .j-stack .feed-item .j-poster img {
  width: 40px !important;
  height: 40px;
  border: 1px solid #fff;
}
.social__content .j-stacker-wrapper .j-stack .feed-item .j-text {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  cursor: pointer;
  font-size: 1rem;
  color: #fff !important;
  padding: 5.5rem 25px 1.8125rem !important;
  z-index: 3;
}
.social__content .j-stacker-wrapper .j-stack .feed-item .j-text .j-message {
  z-index: 2;
  position: relative;
  line-height: 25px;
  font-weight: 400 !important;
}
.social__content .j-stacker-wrapper .j-stack .feed-item .j-text .j-message p {
  margin-bottom: 1rem;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.375;
  letter-spacing: 0.5px;
  color: #fff !important;
}
.social__content .j-stacker-wrapper .j-stack .feed-item .j-text .j-message p a {
  color: #d1d0d0 !important;
  font-weight: 400 !important;
}
.social__content .j-stacker-wrapper .j-stack .feed-item .j-text .j-message p a:hover {
  text-decoration: underline !important;
}
.social__content .j-stacker-wrapper .j-stack .feed-item .j-text .j-meta {
  margin-top: 2rem;
  font-size: 0.875rem;
  position: relative;
  z-index: 4;
  display: flex;
  align-items: center;
}
.social__content .j-stacker-wrapper .j-stack .feed-item .j-text .j-meta a,
.social__content .j-stacker-wrapper .j-stack .feed-item .j-text .j-meta .j-twitter-date,
.social__content .j-stacker-wrapper .j-stack .feed-item .j-text .j-meta .j-twitter-intents {
  color: #d1d0d0 !important;
}
.social__content .j-stacker-wrapper .j-stack .feed-item .j-text .j-meta ul {
  order: 2;
  margin-left: auto;
}
.social__content .j-stacker-wrapper .j-stack .feed-item .j-text .j-meta > a,
.social__content .j-stacker-wrapper .j-stack .feed-item .j-text .j-meta nav {
  order: 1;
  letter-spacing: 0.25px;
}
.social__content .j-stacker-wrapper .j-stack .feed-item .j-text .j-meta .j-facebook,
.social__content .j-stacker-wrapper .j-stack .feed-item .j-text .j-meta .j-instagram {
  display: none;
}
.social__content .j-stacker-wrapper .j-stack .feed-item .j-text:after {
  content: "";
  background: rgba(0, 0, 0, 0.8);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
}
.social__content .j-stacker-wrapper .j-stack .feed-item:hover .j-text:after {
  background-color: #c41230;
}
.social__content .j-stacker-wrapper .j-stack .feed-item .j-image {
  top: 0;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto !important;
  width: 100%;
  z-index: 0;
  display: block;
  height: 100%;
}
.social__content .j-stacker-wrapper .j-stack .feed-item .j-image img {
  width: auto !important;
  min-width: 100%;
  min-height: 100%;
  z-index: 0;
  margin-bottom: 0;
  vertical-align: initial;
}
@media print, screen and (min-width: 64em) {
  .social__content .j-stacker-wrapper .j-stack .feed-item .j-image img {
    max-width: 150%;
  }
}
.social__content .j-stacker-wrapper .j-stack .feed-item.j-facebook .j-poster a:first-child {
  position: relative;
}
.social__content .j-stacker-wrapper .j-stack .feed-item.j-facebook .j-poster a:first-child:after {
  content: "";
  width: 20px;
  height: 20px;
  top: 30px;
  right: 25px;
  position: absolute;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 96 96'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3EFacebook%3C/title%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='facebook'%3E%3Cpath id='Blue' class='cls-1' d='M90.7017,0H5.2985A5.2977,5.2977,0,0,0,0,5.2985V90.7015A5.2981,5.2981,0,0,0,5.2985,96H51.2767V58.8238H38.766V44.3355H51.2767V33.6507c0-12.4,7.5729-19.1513,18.6344-19.1513a102.666,102.666,0,0,1,11.18.5708V28.0285l-7.6721.0035c-6.0156,0-7.18,2.8586-7.18,7.0534v9.25h14.347L78.7171,58.8238H66.2383V96H90.7017A5.2987,5.2987,0,0,0,96,90.7015V5.2985A5.2983,5.2983,0,0,0,90.7017,0Z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.social__content .j-stacker-wrapper .j-stack .feed-item.j-instagram .j-poster a:first-child {
  position: relative;
}
.social__content .j-stacker-wrapper .j-stack .feed-item.j-instagram .j-poster a:first-child:after {
  content: "";
  width: 20px;
  height: 20px;
  top: 30px;
  right: 25px;
  position: absolute;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 96 96'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3EInstagram%3C/title%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='instagram'%3E%3Cpath class='cls-1' d='M83.7471,0A12.2549,12.2549,0,0,1,96,12.2546V83.5663A12.29,12.29,0,0,1,83.7471,96H12.4394A12.33,12.33,0,0,1,0,83.5663V12.2546A12.2962,12.2962,0,0,1,12.4394,0Zm1.4584,40.5957h-8.59a20.0265,20.0265,0,0,1,1.2807,8.2268c0,22.0956-13.1611,28.8935-29.8061,28.8935-16.4518,0-29.7988-12.7979-29.7988-28.8935a20.0555,20.0555,0,0,1,1.4584-8.2268H10.6079v40.41a3.9067,3.9067,0,0,0,4.02,3.8425H81.3722a3.8587,3.8587,0,0,0,3.8333-3.8425ZM28.89,47.9133c0,10.0586,8.5987,18.4648,19.1991,18.4648,10.7937,0,19.3842-8.4062,19.3842-18.4648,0-10.6079-8.5905-18.8347-19.3842-18.8347A18.89,18.89,0,0,0,28.89,47.9133ZM69.8486,10.4228a4.7045,4.7045,0,0,0-4.2056,4.3919v10.602a4.6749,4.6749,0,0,0,4.2056,4.2067H80.8214a4.5457,4.5457,0,0,0,4.3841-4.2067V14.8147a4.5768,4.5768,0,0,0-4.3841-4.3919Z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.figure-context {
  padding: 40px 0px;
}
@media print, screen and (min-width: 40em) {
  .figure-context {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media print, screen and (min-width: 64em) {
  .figure-context {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
@media screen and (min-width: 75em) {
  .figure-context {
    padding: 80px 40px;
  }
}
.figure-context .figure-context__container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  flex-direction: column;
  border: 1px solid #edece2;
  background-color: #fff;
}
@media print, screen and (min-width: 40em) {
  .figure-context .figure-context__container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .figure-context .figure-context__container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .figure-context .figure-context__container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .figure-context .figure-context__container {
    width: 90%;
    max-width: 1404px;
  }
}
@media print, screen and (min-width: 64em) {
  .figure-context .figure-context__container {
    flex-direction: row;
  }
}
.figure-context .figure-context__container .figure-context__inner {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  flex-direction: column;
  border: 1px solid #edece2;
  position: relative;
  top: -6px;
  left: -6px;
  margin: 0 !important;
}
@media print, screen and (min-width: 40em) {
  .figure-context .figure-context__container .figure-context__inner {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .figure-context .figure-context__container .figure-context__inner {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .figure-context .figure-context__container .figure-context__inner {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .figure-context .figure-context__container .figure-context__inner {
    width: 90%;
    max-width: 1404px;
  }
}
@media print, screen and (min-width: 40em) {
  .figure-context .figure-context__container .figure-context__inner {
    top: -10px;
    left: -10px;
  }
}
@media print, screen and (min-width: 64em) {
  .figure-context .figure-context__container .figure-context__inner {
    flex-direction: row;
  }
}
.figure-context .figure-context__container .figure-context__inner .figure-context__cards-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: flex-start;
  background-color: #c41230;
  padding: 14px 0 10px 0;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .figure-context .figure-context__container .figure-context__inner .figure-context__cards-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .figure-context .figure-context__container .figure-context__inner .figure-context__cards-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .figure-context .figure-context__container .figure-context__inner .figure-context__cards-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .figure-context .figure-context__container .figure-context__inner .figure-context__cards-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 40em) {
  .figure-context .figure-context__container .figure-context__inner .figure-context__cards-container {
    padding-top: 25px;
  }
}
@media print, screen and (min-width: 64em) {
  .figure-context .figure-context__container .figure-context__inner .figure-context__cards-container {
    padding: 40px;
    flex: 0 0 45.8333333333%;
    max-width: 45.8333333333%;
  }
}
.figure-context .figure-context__container .figure-context__inner .figure-context__cards-container .figure-context__card {
  margin: auto 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 20px 15px 20px;
  width: 100%;
}
@media print, screen and (min-width: 64em) {
  .figure-context .figure-context__container .figure-context__inner .figure-context__cards-container .figure-context__card {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.figure-context .figure-context__container .figure-context__inner .figure-context__cards-container .figure-context__card .figure-context_card-inner {
  margin-left: 15px;
}
@media print, screen and (min-width: 64em) {
  .figure-context .figure-context__container .figure-context__inner .figure-context__cards-container .figure-context__card .figure-context_card-inner {
    margin-left: 32px;
  }
}
.figure-context .figure-context__container .figure-context__inner .figure-context__cards-container .figure-context__card .figure-context_card-inner .figure-context__card-headline {
  font-family: freight-display-pro, serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 300;
  font-family: freight-text-pro, serif;
  color: #fff;
  line-height: 30px;
  margin-bottom: 5px;
  font-size: 24px;
}
@media print, screen and (min-width: 64em) {
  .figure-context .figure-context__container .figure-context__inner .figure-context__cards-container .figure-context__card .figure-context_card-inner .figure-context__card-headline {
    font-size: 38px;
    line-height: 44px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
@media print, screen and (min-width: 64em) {
  .figure-context .figure-context__container .figure-context__inner .figure-context__cards-container .figure-context__card .figure-context_card-inner .figure-context__card-headline {
    font-size: 44px;
    line-height: 48px;
    margin-bottom: 20px;
  }
}
.figure-context .figure-context__container .figure-context__inner .figure-context__cards-container .figure-context__card .figure-context_card-inner .figure-context__card-copy {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.04375rem;
  color: #fff;
  font-style: italic;
  font-weight: 700;
}
.figure-context .figure-context__container .figure-context__inner .figure-context__cards-container .figure-context__card-image-container {
  position: relative;
}
.figure-context .figure-context__container .figure-context__inner .figure-context__cards-container .figure-context__card-image-container img {
  max-width: 118px;
  width: 83px;
}
@media print, screen and (min-width: 64em) {
  .figure-context .figure-context__container .figure-context__inner .figure-context__cards-container .figure-context__card-image-container img {
    width: 118px;
  }
}
.figure-context .figure-context__container .figure-context__inner .figure-context__cards-container .figure-context__card-image-container .figure-context__image-text {
  font-family: freight-text-pro, serif;
  font-size: 26px;
  letter-spacing: -0.65px;
  line-height: 1.08;
  color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  padding: 0 16px;
  text-align: center;
  font-weight: 300;
}
@media print, screen and (min-width: 64em) {
  .figure-context .figure-context__container .figure-context__inner .figure-context__cards-container .figure-context__card-image-container .figure-context__image-text {
    font-size: 40px;
    letter-spacing: -1px;
    line-height: 1.22;
  }
}
.figure-context .figure-context__container .figure-context__inner .figure-context__cards-container .figure-context__card-image-container .figure-context__image-text.figure-context__image-type-oval {
  position: absolute;
  top: 0%;
  transform: translateY(0%);
  height: 100%;
  padding: 10px 16px 20px 16px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.figure-context .figure-context__container .figure-context__inner .figure-context__cards-container .figure-context__card-image-container .figure-context__image-text.figure-context__image-type-circle {
  position: absolute;
  top: 49%;
  transform: translateY(-49%);
}
.figure-context .figure-context__container .figure-context__inner .figure-context__text-container {
  padding: 40px;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .figure-context .figure-context__container .figure-context__inner .figure-context__text-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .figure-context .figure-context__container .figure-context__inner .figure-context__text-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .figure-context .figure-context__container .figure-context__inner .figure-context__text-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .figure-context .figure-context__container .figure-context__inner .figure-context__text-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 40em) {
  .figure-context .figure-context__container .figure-context__inner .figure-context__text-container {
    padding-bottom: 60px;
  }
}
@media print, screen and (min-width: 64em) {
  .figure-context .figure-context__container .figure-context__inner .figure-context__text-container {
    flex: 0 0 54.1666666667%;
    max-width: 54.1666666667%;
  }
}
@media screen and (min-width: 75em) {
  .figure-context .figure-context__container .figure-context__inner .figure-context__text-container {
    padding: 80px 100px;
  }
}
.figure-context .figure-context__container .figure-context__inner .figure-context__text-container .figure-context__text-inner {
  padding-left: 0px;
  padding-right: 0px;
  padding-left: 20px;
  padding-right: 20px;
}
@media print, screen and (min-width: 64em) {
  .figure-context .figure-context__container .figure-context__inner .figure-context__text-container .figure-context__text-inner {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.figure-context .figure-context__container .figure-context__inner .figure-context__text-container .figure-context__text-inner .figure-context__text-heading {
  font-family: proxima-nova, sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  color: #c41230;
  margin-bottom: 15px;
}
@media print, screen and (min-width: 64em) {
  .figure-context .figure-context__container .figure-context__inner .figure-context__text-container .figure-context__text-inner .figure-context__text-heading {
    font-size: 26px;
    line-height: 32px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
@media print, screen and (min-width: 64em) {
  .figure-context .figure-context__container .figure-context__inner .figure-context__text-container .figure-context__text-inner .figure-context__text-heading {
    margin-bottom: 20px;
  }
}
.figure-context .figure-context__container .figure-context__inner .figure-context__text-container .figure-context__text-inner .figure-context__text-subheading {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
  color: #231f20;
  margin-bottom: 20px;
}
.figure-context .figure-context__container .figure-context__inner .figure-context__text-container .figure-context__text-inner .figure-context__text-copy {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
  color: #231f20;
  margin-bottom: 20px;
}
.figure-context.figure-context--white {
  background-color: #faf9f7;
}
.figure-context.figure-context--white .figure-context__text-container {
  background-color: #fff;
}
.figure-context.figure-context--gold {
  background-color: #edece2;
}
.figure-context.figure-context--gold .figure-context__text-container {
  background-color: #fff;
}
.figure-context.figure-context--red {
  background-color: #c41230;
  padding-top: 0;
  padding-bottom: 0;
}
.figure-context.figure-context--red .figure-context__container {
  border: none;
  background-color: transparent;
}
.figure-context.figure-context--red .figure-context__container .figure-context__inner {
  border: none;
  top: 0;
  left: 0;
}
.figure-context.figure-context--red .figure-context__container .figure-context__inner .figure-context__cards-container {
  padding-bottom: 0;
}
@media print, screen and (min-width: 40em) {
  .figure-context.figure-context--red .figure-context__container .figure-context__inner .figure-context__cards-container {
    padding-top: 44px;
    padding-bottom: 25px;
  }
}
@media print, screen and (min-width: 40em) {
  .figure-context.figure-context--red .figure-context__container .figure-context__inner .figure-context__text-container {
    padding-top: 0;
  }
}
@media print, screen and (min-width: 64em) {
  .figure-context.figure-context--red .figure-context__container .figure-context__inner .figure-context__text-container {
    padding: 80px 80px;
  }
}
.figure-context.figure-context--red .figure-context__container .figure-context__inner .figure-context__text-container .figure-context__text-inner .figure-context__text-heading {
  color: #fff;
}
.figure-context.figure-context--red .figure-context__container .figure-context__inner .figure-context__text-container .figure-context__text-inner .figure-context__text-subheading {
  color: #fff;
}
.figure-context.figure-context--red .figure-context__container .figure-context__inner .figure-context__text-container .figure-context__text-inner .figure-context__text-copy {
  color: #fff;
}

.tabs-info {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #fff;
}
@media print, screen and (min-width: 40em) {
  .tabs-info {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media print, screen and (min-width: 64em) {
  .tabs-info {
    padding-top: 80px;
  }
}

.tabs-info_inner {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media print, screen and (min-width: 40em) {
  .tabs-info_inner {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .tabs-info_inner {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .tabs-info_inner {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .tabs-info_inner {
    width: 90%;
    max-width: 1404px;
  }
}
.tabs-info__header-container {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  margin-bottom: 20px;
}
@media print, screen and (min-width: 40em) {
  .tabs-info__header-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .tabs-info__header-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .tabs-info__header-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .tabs-info__header-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .tabs-info__header-container {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .tabs-info__header-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .tabs-info__header-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .tabs-info__header-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .tabs-info__header-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.tabs-info__header-container .tabs-info__header-inner {
  font-family: freight-display-pro, serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 300;
  color: #c41230;
}
@media print, screen and (min-width: 64em) {
  .tabs-info__header-container .tabs-info__header-inner {
    font-size: 38px;
    line-height: 44px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
.tabs-info__header-container .tabs-info__header-inner .tabs-info__header-heading {
  font-family: freight-display-pro, serif;
  font-size: 38px;
  line-height: 48px;
  font-weight: 400;
}
@media print, screen and (min-width: 64em) {
  .tabs-info__header-container .tabs-info__header-inner .tabs-info__header-heading {
    font-weight: 300;
    font-size: 44px;
    line-height: 50px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

.tabs-info__select-container {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 30px;
}
@media print, screen and (min-width: 40em) {
  .tabs-info__select-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .tabs-info__select-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .tabs-info__select-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .tabs-info__select-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .tabs-info__select-container {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    flex-direction: row;
    flex-basis: 50%;
    justify-content: flex-start;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .tabs-info__select-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .tabs-info__select-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .tabs-info__select-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .tabs-info__select-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.tabs-info__panel {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  justify-content: space-between;
}
@media print, screen and (min-width: 40em) {
  .tabs-info__panel {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .tabs-info__panel {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .tabs-info__panel {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .tabs-info__panel {
    width: 90%;
    max-width: 1404px;
  }
}

.tabs-info__image-container {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-bottom: 30px;
}
@media print, screen and (min-width: 40em) {
  .tabs-info__image-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .tabs-info__image-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .tabs-info__image-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .tabs-info__image-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .tabs-info__image-container {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    order: 2;
    margin-top: -80px;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .tabs-info__image-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .tabs-info__image-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .tabs-info__image-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .tabs-info__image-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.tabs-info__image-container .tabs-info__image {
  width: 100%;
}

.tabs-info__content-container {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .tabs-info__content-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .tabs-info__content-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .tabs-info__content-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .tabs-info__content-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .tabs-info__content-container {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    order: 1;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .tabs-info__content-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .tabs-info__content-container {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .tabs-info__content-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .tabs-info__content-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.tabs-info__select-title {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  width: 100%;
  margin-left: 0px;
  margin-bottom: 12px;
  font-weight: 300;
  line-height: 1.57;
  letter-spacing: 0.5px;
  font-size: 14px;
}
@media print, screen and (min-width: 64em) {
  .tabs-info__select-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
@media print, screen and (min-width: 64em) {
  .tabs-info__select-title {
    width: auto;
    margin-right: 20px;
    margin-bottom: 0px;
  }
}

.switch-tab-info__select-wrap {
  position: relative;
  fill: #ccc9b8;
  transition-property: background-color, fill;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
}
.switch-tab-info__select-wrap:focus-within {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
@media print, screen and (min-width: 64em) {
  .switch-tab-info__select-wrap {
    width: inherit;
  }
}
.switch-tab-info__select-wrap::after {
  position: absolute;
  top: 50%;
  right: 20px;
  display: block;
  width: 10px;
  height: 10px;
  border-right: 2px solid #c41230;
  border-bottom: 2px solid #c41230;
  transform: translateY(-70%) rotate(45deg);
  content: "";
  pointer-events: none;
}
.switch-tab-info__select-wrap:hover {
  background-color: #ccc9b8;
}
.switch-tab-info__select-wrap:hover .tabs-info__select {
  background-color: #ccc9b8;
  color: #231f20;
}

.tabs-info__select {
  transition-property: background-color, fill;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.115625rem;
  text-transform: uppercase;
  display: block;
  width: 100%;
  padding: 16px 48px 16px 20px;
  color: #c41230;
  border: 2px solid #231f20;
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0);
  appearance: none;
}

select::-ms-expand {
  display: none;
}

.tabs-info__content-copy {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
  margin-bottom: 30px;
}
@media print, screen and (min-width: 40em) {
  .tabs-info__content-copy {
    margin-bottom: 50px;
  }
}
.tabs-info__content-copy h6 {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: 45px;
}
@media print, screen and (min-width: 64em) {
  .tabs-info__content-copy h6 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.tabs-info__content-copy h5 {
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 45px;
}
@media print, screen and (min-width: 64em) {
  .tabs-info__content-copy h5 {
    font-size: 20px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #c41230;
  }
}
.tabs-info__content-copy h4 {
  font-family: proxima-nova, sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 45px;
}
@media print, screen and (min-width: 64em) {
  .tabs-info__content-copy h4 {
    font-size: 26px;
    line-height: 32px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.tabs-info__content-copy h3 {
  font-family: freight-display-pro, serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 300;
  margin-bottom: 15px;
  margin-top: 45px;
}
@media print, screen and (min-width: 64em) {
  .tabs-info__content-copy h3 {
    font-size: 38px;
    line-height: 44px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
.tabs-info__content-copy h3.table-header {
  font-family: proxima-nova, sans-serif;
  font-size: 20px;
  line-height: 1.3;
  font-weight: 500;
  margin-bottom: 20px;
}
@media print, screen and (min-width: 64em) {
  .tabs-info__content-copy h3.table-header {
    font-size: 24px;
    line-height: 1.25;
  }
}
.tabs-info__content-copy p {
  margin-bottom: 20px;
}
.tabs-info__content-copy a {
  color: #c41230;
  text-decoration: underline;
  font-style: italic;
  outline: 2px dotted transparent;
  outline-offset: 0;
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.tabs-info__content-copy a:hover {
  text-decoration: none;
}
.tabs-info__content-copy a:focus {
  text-decoration: none;
  outline: 2px dotted #c41230;
}
.tabs-info__content-copy ul {
  margin-bottom: 20px;
}
.tabs-info__content-copy ul li {
  padding: 8px 0 8px 30px;
}
.tabs-info__content-copy ul li:before {
  content: "";
  background-image: url("../svgs/functional/icon-target--red.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 20px;
  margin-left: -30px;
}
.tabs-info__content-copy ol {
  list-style: none;
  counter-reset: my-awesome-counter;
  margin-bottom: 20px;
}
.tabs-info__content-copy ol li {
  padding: 8px 0;
  counter-increment: my-awesome-counter;
  padding-left: 36px;
}
.tabs-info__content-copy ol li:before {
  content: counter(my-awesome-counter);
  font-family: proxima-nova, sans-serif;
  color: #c41230;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.75px;
  margin-right: 16px;
  text-align: center;
  display: inline-block;
  width: 20px;
  vertical-align: middle;
  margin-left: -36px;
}

.tabs-info__links-header {
  display: block;
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.115625rem;
  text-transform: uppercase;
  color: #c41230;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.tabs-info__content-header {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.04375rem;
  color: #c41230;
  text-transform: uppercase;
  margin-top: 32px;
}

.tabs-info__links-inner {
  margin-bottom: 23px;
}
.tabs-info__links-inner a:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.tabs-info__links-inner .base-link-primary {
  padding: 0;
}
@media print, screen and (min-width: 40em) {
  .tabs-info__links-inner .base-link-primary {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
.tabs-info__links-inner .base-link-primary .base-link-primary--link {
  display: block;
  position: relative;
  padding-top: 6px;
  padding-right: 16px;
  padding-bottom: 6px;
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
}
@media print, screen and (min-width: 40em) {
  .tabs-info__links-inner .base-link-primary .base-link-primary--link {
    display: inline;
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
.tabs-info__links-inner .base-link-primary .base-link-primary--link:after {
  display: none;
}
.tabs-info__links-inner .base-link-primary .base-link-primary--link:before {
  position: absolute;
  right: 0;
  bottom: 9px;
  display: block;
  width: 9px;
  height: 9px;
  background-color: transparent;
  content: "";
  border-top: 2px solid #c41230;
  border-right: 2px solid #c41230;
  transform: translateY(-50%) rotate(45deg);
  transition: all 0.2s ease;
}
.tabs-info__links-inner .base-link-primary .base-link-primary--link:hover:before {
  transform: translate(5px, -50%) rotate(45deg) scale(1);
}

.tabs-info__ctas-inner .base-cta-primary:first-child {
  margin-bottom: 12px;
}
@media print, screen and (min-width: 40em) {
  .tabs-info__ctas-inner .base-cta-primary:first-child {
    margin-bottom: 0;
    margin-right: 24px;
  }
}

.search-result {
  padding-top: 20px;
  padding-bottom: 20px;
}

.search-result__container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .search-result__container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .search-result__container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .search-result__container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .search-result__container {
    width: 90%;
    max-width: 1404px;
  }
}
.search-result__container > div:last-child {
  width: 100%;
}
.search-result__container > div:last-child .gsc-wrapper .gsc-webResult {
  width: 100%;
}
.search-result__container > div:last-child .gsc-wrapper .gsc-webResult .gsc-result {
  padding-top: 4px;
  padding-bottom: 4px;
}
.search-result__container > div:last-child .gsc-wrapper .gsc-webResult .gsc-result + .gsc-result {
  margin-top: 15px;
}
.search-result__container > div:last-child .gsc-wrapper .gsc-webResult .gsc-result .gs-result {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 32px;
  padding-right: 32px;
}
.search-result__container > div:last-child .gsc-wrapper .gsc-expansionArea {
  margin-top: 20px;
}
.search-result__container > div:last-child .gsc-wrapper .gcsc-find-more-on-google svg {
  display: inline;
  fill: #c41230;
}
.search-result__container > div:last-child .gsc-wrapper .gsc-cursor-box {
  margin: 32px 0px;
}
.search-result__container > div:last-child .gsc-wrapper .gsc-cursor-box .gsc-cursor .gsc-cursor-page {
  padding: 8px;
  margin-right: 4px;
  background-color: #edece2;
}
.search-result__container > div:last-child .gsc-wrapper .gsc-cursor-box .gsc-cursor .gsc-cursor-current-page {
  color: #fff;
  background-color: #c41230;
}

.search-result__header {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  margin-bottom: 40px;
}
@media print, screen and (min-width: 40em) {
  .search-result__header {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .search-result__header {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .search-result__header {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .search-result__header {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.search-result__header .search-result__heading {
  font-family: freight-display-pro, serif;
  font-size: 38px;
  line-height: 48px;
  font-weight: 400;
  color: #c41230;
}
@media print, screen and (min-width: 64em) {
  .search-result__header .search-result__heading {
    font-weight: 300;
    font-size: 44px;
    line-height: 50px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

.search-result__input-inner {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .search-result__input-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .search-result__input-inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .search-result__input-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .search-result__input-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.search-result__input-inner > div:last-child .gsc-search-box-tools {
  margin: 0;
  padding: 0;
}
.search-result__input-inner > div:last-child .gsc-search-box-tools .gsc-search-box {
  margin: 0 !important;
}
.search-result__input-inner > div:last-child .gsc-search-box-tools .gsc-search-box .gsc-input {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  height: 46px;
}
.search-result__input-inner > div:last-child .gsc-search-box-tools .gsc-search-box .gsc-input table {
  margin-bottom: 0;
}
.search-result__input-inner > div:last-child .gsc-search-box-tools .gsc-search-box .gsc-input .gsc-input-box {
  height: 46px;
  border-radius: 0;
  padding: 0;
}
.search-result__input-inner > div:last-child .gsc-search-box-tools .gsc-search-box .gsc-input .gsc-input-box .gsib_a {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}
.search-result__input-inner > div:last-child .gsc-search-box-tools .gsc-search-box .gsc-input .gsc-input-box .gsib_b {
  padding: 0 10px;
}
.search-result__input-inner > div:last-child .gsc-search-box-tools .gsc-search-box .gsc-input .gsc-input-box .gsib_b .gsst_a {
  padding: 0;
}
.search-result__input-inner > div:last-child .gsc-search-box-tools .gsc-search-box .gsc-input .gsc-input-box .gsib_b .gsst_a .gscb_a {
  color: #7e6d53;
}
.search-result__input-inner .gsc-search-button {
  padding: 0 !important;
}
.search-result__input-inner .gsc-search-button .gsc-search-button-v2 {
  position: relative;
  border-radius: 0px;
  border: 1px solid #c41230;
  background: #c41230;
  padding: 12px 20px;
  margin-left: 10px;
  height: 46px;
  width: 46px;
}
@media print, screen and (min-width: 64em) {
  .search-result__input-inner .gsc-search-button .gsc-search-button-v2 {
    margin-left: 20px;
  }
}
.search-result__input-inner .gsc-search-button .gsc-search-button-v2 svg {
  display: none;
}
.search-result__input-inner .gsc-search-button .gsc-search-button-v2::after {
  content: "";
  position: absolute;
  background-image: url("../svgs/functional/icon-magnifier--white.svg");
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 14px;
  left: 15px;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .search-result__input-inner .gsc-search-button-v2 {
    padding: 12px 14px;
  }
}
@supports (-ms-ime-align: auto) {
  .search-result__input-inner .gsc-search-button-v2 {
    padding: 12px 14px;
  }
}
.search-result__input-inner table {
  border: none;
}

.gsc-control-cse .gsc-selected-option-container {
  transition-property: background-color, fill;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.11563rem;
  text-transform: uppercase;
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 10px 48px 10px 14px;
  color: #c41230;
  border: 2px solid #ccc9b8;
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0);
  appearance: none;
  height: auto;
}
.gsc-control-cse .gsc-selected-option-container .gsc-option-selector {
  position: absolute;
  top: 50%;
  right: 16px;
  display: block;
  width: 10px;
  height: 12px;
  border-right: 2px solid #c41230;
  border-bottom: 2px solid #c41230;
  -webkit-transform: translateY(-70%) rotate(45deg);
  transform: translateY(-70%) rotate(45deg);
  margin-top: 0;
  background: none;
}
.gsc-control-cse .gsc-selected-option-container:hover {
  background-color: #ccc9b8;
  color: #fff;
}
.gsc-control-cse .gsc-selected-option-container:hover .gsc-option-selector {
  border-color: #fff;
}

.wysiwyg {
  margin-top: 28px;
  margin-bottom: 28px;
  background-color: #fff;
}
.wysiwyg sup {
  margin-left: 4px;
}

.wysiwyg__container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .wysiwyg__container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .wysiwyg__container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .wysiwyg__container {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .wysiwyg__container {
    width: 90%;
    max-width: 1404px;
  }
}

.wysiwyg__inner {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media print, screen and (min-width: 40em) {
  .wysiwyg__inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .wysiwyg__inner {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .wysiwyg__inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .wysiwyg__inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.wysiwyg__header-heading {
  font-family: freight-display-pro, serif;
  font-size: 38px;
  line-height: 48px;
  font-weight: 400;
  color: #c41230;
  margin-bottom: 20px;
}
@media print, screen and (min-width: 64em) {
  .wysiwyg__header-heading {
    font-weight: 300;
    font-size: 44px;
    line-height: 50px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

.wysiwyg__body a {
  color: #c41230;
  text-decoration: underline;
  font-style: normal;
  outline: 2px dotted transparent;
  outline-offset: 0;
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.wysiwyg__body a:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.wysiwyg__body a:focus, .wysiwyg__body a:hover {
  text-decoration: none;
}
.wysiwyg__body a:focus {
  text-decoration: none;
  outline: 2px dotted #c41230;
}

.wysiwyg__body h2 {
  font-family: freight-display-pro, serif;
  font-size: 38px;
  line-height: 48px;
  font-weight: 400;
  color: #c41230;
  margin-bottom: 20px;
  margin-top: 40px;
}
@media print, screen and (min-width: 64em) {
  .wysiwyg__body h2 {
    font-weight: 300;
    font-size: 44px;
    line-height: 50px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

.wysiwyg__lead-container {
  margin-bottom: 60px;
}
.wysiwyg__lead-container .wysiwyg__lead-container-inner .wysiwyg__lead {
  font-family: freight-display-pro, serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: 0.25px;
}
@media print, screen and (min-width: 40em) {
  .wysiwyg__lead-container .wysiwyg__lead-container-inner .wysiwyg__lead {
    font-size: 22px;
    line-height: 1.64;
    letter-spacing: 0.43px;
  }
}

.wysiwyg__body-container .wysiwyg__body .base-cta-text,
.wysiwyg__body-container .accordion-primary__inner .base-cta-text,
.accordion-primary .wysiwyg__body .base-cta-text,
.accordion-primary .accordion-primary__inner .base-cta-text {
  color: #231f20;
  font-style: normal;
  margin-bottom: 60px;
  text-decoration: none;
}
.wysiwyg__body-container .wysiwyg__body h6,
.wysiwyg__body-container .accordion-primary__inner h6,
.accordion-primary .wysiwyg__body h6,
.accordion-primary .accordion-primary__inner h6 {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: 45px;
}
@media print, screen and (min-width: 64em) {
  .wysiwyg__body-container .wysiwyg__body h6,
  .wysiwyg__body-container .accordion-primary__inner h6,
  .accordion-primary .wysiwyg__body h6,
  .accordion-primary .accordion-primary__inner h6 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.wysiwyg__body-container .wysiwyg__body h5,
.wysiwyg__body-container .accordion-primary__inner h5,
.accordion-primary .wysiwyg__body h5,
.accordion-primary .accordion-primary__inner h5 {
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 45px;
}
@media print, screen and (min-width: 64em) {
  .wysiwyg__body-container .wysiwyg__body h5,
  .wysiwyg__body-container .accordion-primary__inner h5,
  .accordion-primary .wysiwyg__body h5,
  .accordion-primary .accordion-primary__inner h5 {
    font-size: 20px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #c41230;
  }
}
.wysiwyg__body-container .wysiwyg__body h4,
.wysiwyg__body-container .accordion-primary__inner h4,
.accordion-primary .wysiwyg__body h4,
.accordion-primary .accordion-primary__inner h4 {
  font-family: proxima-nova, sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 45px;
}
@media print, screen and (min-width: 64em) {
  .wysiwyg__body-container .wysiwyg__body h4,
  .wysiwyg__body-container .accordion-primary__inner h4,
  .accordion-primary .wysiwyg__body h4,
  .accordion-primary .accordion-primary__inner h4 {
    font-size: 26px;
    line-height: 32px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.wysiwyg__body-container .wysiwyg__body h3,
.wysiwyg__body-container .accordion-primary__inner h3,
.accordion-primary .wysiwyg__body h3,
.accordion-primary .accordion-primary__inner h3 {
  font-family: freight-display-pro, serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 300;
  margin-bottom: 15px;
  margin-top: 45px;
}
@media print, screen and (min-width: 64em) {
  .wysiwyg__body-container .wysiwyg__body h3,
  .wysiwyg__body-container .accordion-primary__inner h3,
  .accordion-primary .wysiwyg__body h3,
  .accordion-primary .accordion-primary__inner h3 {
    font-size: 38px;
    line-height: 44px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
.wysiwyg__body-container .wysiwyg__body h3.table-header,
.wysiwyg__body-container .accordion-primary__inner h3.table-header,
.accordion-primary .wysiwyg__body h3.table-header,
.accordion-primary .accordion-primary__inner h3.table-header {
  font-family: proxima-nova, sans-serif;
  font-size: 20px;
  line-height: 1.3;
  font-weight: 500;
  margin-bottom: 20px;
}
@media print, screen and (min-width: 64em) {
  .wysiwyg__body-container .wysiwyg__body h3.table-header,
  .wysiwyg__body-container .accordion-primary__inner h3.table-header,
  .accordion-primary .wysiwyg__body h3.table-header,
  .accordion-primary .accordion-primary__inner h3.table-header {
    font-size: 24px;
    line-height: 1.25;
  }
}
.wysiwyg__body-container .wysiwyg__body p,
.wysiwyg__body-container .accordion-primary__inner p,
.accordion-primary .wysiwyg__body p,
.accordion-primary .accordion-primary__inner p {
  margin-bottom: 20px;
}
.wysiwyg__body-container .wysiwyg__body a,
.wysiwyg__body-container .accordion-primary__inner a,
.accordion-primary .wysiwyg__body a,
.accordion-primary .accordion-primary__inner a {
  color: #c41230;
  text-decoration: underline;
  font-style: normal;
  outline: 2px dotted transparent;
  outline-offset: 0;
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.wysiwyg__body-container .wysiwyg__body a:hover,
.wysiwyg__body-container .accordion-primary__inner a:hover,
.accordion-primary .wysiwyg__body a:hover,
.accordion-primary .accordion-primary__inner a:hover {
  text-decoration: none;
}
.wysiwyg__body-container .wysiwyg__body a:focus,
.wysiwyg__body-container .accordion-primary__inner a:focus,
.accordion-primary .wysiwyg__body a:focus,
.accordion-primary .accordion-primary__inner a:focus {
  text-decoration: none;
  outline: 2px dotted #c41230;
}
.wysiwyg__body-container .wysiwyg__body em > a,
.wysiwyg__body-container .accordion-primary__inner em > a,
.accordion-primary .wysiwyg__body em > a,
.accordion-primary .accordion-primary__inner em > a {
  font-style: italic !important;
}
.wysiwyg__body-container .wysiwyg__body ul,
.wysiwyg__body-container .accordion-primary__inner ul,
.accordion-primary .wysiwyg__body ul,
.accordion-primary .accordion-primary__inner ul {
  display: list-item;
  margin: 0 0 2rem 2rem;
}
.wysiwyg__body-container .wysiwyg__body ul::marker,
.wysiwyg__body-container .accordion-primary__inner ul::marker,
.accordion-primary .wysiwyg__body ul::marker,
.accordion-primary .accordion-primary__inner ul::marker {
  color: transparent;
}
.wysiwyg__body-container .wysiwyg__body ul li,
.wysiwyg__body-container .accordion-primary__inner ul li,
.accordion-primary .wysiwyg__body ul li,
.accordion-primary .accordion-primary__inner ul li {
  display: list-item;
  padding: 0px;
  line-height: 160%;
  margin: 5px 0;
}
.wysiwyg__body-container .wysiwyg__body ul li:before,
.wysiwyg__body-container .accordion-primary__inner ul li:before,
.accordion-primary .wysiwyg__body ul li:before,
.accordion-primary .accordion-primary__inner ul li:before {
  content: "";
  background-image: url("../svgs/functional/icon-target--red.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-left: -20px;
  margin-right: 10px;
}
.wysiwyg__body-container .wysiwyg__body ul li ul,
.wysiwyg__body-container .accordion-primary__inner ul li ul,
.accordion-primary .wysiwyg__body ul li ul,
.accordion-primary .accordion-primary__inner ul li ul {
  list-style-type: disc;
  display: list-item;
  margin: 0 0 0 2rem;
}
.wysiwyg__body-container .wysiwyg__body ul li ul li::before,
.wysiwyg__body-container .accordion-primary__inner ul li ul li::before,
.accordion-primary .wysiwyg__body ul li ul li::before,
.accordion-primary .accordion-primary__inner ul li ul li::before {
  content: unset;
}
.wysiwyg__body-container .wysiwyg__body ul li ul li::marker,
.wysiwyg__body-container .accordion-primary__inner ul li ul li::marker,
.accordion-primary .wysiwyg__body ul li ul li::marker,
.accordion-primary .accordion-primary__inner ul li ul li::marker {
  color: #C41230;
  font-size: 22px;
}
.wysiwyg__body-container .wysiwyg__body ul li ul li ul,
.wysiwyg__body-container .accordion-primary__inner ul li ul li ul,
.accordion-primary .wysiwyg__body ul li ul li ul,
.accordion-primary .accordion-primary__inner ul li ul li ul {
  list-style-type: circle;
}
.wysiwyg__body-container .wysiwyg__body ul li ul li ul li ul,
.wysiwyg__body-container .accordion-primary__inner ul li ul li ul li ul,
.accordion-primary .wysiwyg__body ul li ul li ul li ul,
.accordion-primary .accordion-primary__inner ul li ul li ul li ul {
  list-style-type: square;
}
.wysiwyg__body-container .wysiwyg__body ol,
.wysiwyg__body-container .accordion-primary__inner ol,
.accordion-primary .wysiwyg__body ol,
.accordion-primary .accordion-primary__inner ol {
  list-style: decimal;
  margin: 0 0 2rem 2rem;
}
.wysiwyg__body-container .wysiwyg__body ol li,
.wysiwyg__body-container .accordion-primary__inner ol li,
.accordion-primary .wysiwyg__body ol li,
.accordion-primary .accordion-primary__inner ol li {
  padding: 0 0 0 0rem;
  display: list-item;
  line-height: 160%;
  margin: 5px 0;
}
.wysiwyg__body-container .wysiwyg__body ol li:before,
.wysiwyg__body-container .accordion-primary__inner ol li:before,
.accordion-primary .wysiwyg__body ol li:before,
.accordion-primary .accordion-primary__inner ol li:before {
  font-family: proxima-nova, sans-serif;
  color: #c41230;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.75px;
  margin-right: 16px;
  text-align: center;
  display: inline-block;
  width: 20px;
  vertical-align: middle;
  margin-left: -36px;
  content: unset;
}
.wysiwyg__body-container .wysiwyg__body ol li::marker,
.wysiwyg__body-container .accordion-primary__inner ol li::marker,
.accordion-primary .wysiwyg__body ol li::marker,
.accordion-primary .accordion-primary__inner ol li::marker {
  color: #C41230;
  font-size: 22px;
}
.wysiwyg__body-container .wysiwyg__body ol li ol,
.wysiwyg__body-container .accordion-primary__inner ol li ol,
.accordion-primary .wysiwyg__body ol li ol,
.accordion-primary .accordion-primary__inner ol li ol {
  list-style: lower-alpha;
  margin: 0 0 0 2rem;
}
.wysiwyg__body-container .wysiwyg__body ol li ol li ol,
.wysiwyg__body-container .accordion-primary__inner ol li ol li ol,
.accordion-primary .wysiwyg__body ol li ol li ol,
.accordion-primary .accordion-primary__inner ol li ol li ol {
  list-style: lower-roman;
}
.wysiwyg__body-container .wysiwyg__body ol li ol li ol li ol,
.wysiwyg__body-container .accordion-primary__inner ol li ol li ol li ol,
.accordion-primary .wysiwyg__body ol li ol li ol li ol,
.accordion-primary .accordion-primary__inner ol li ol li ol li ol {
  list-style: decimal;
}
.wysiwyg__body-container .accordion-primary__inner .accordion-primary__heading,
.accordion-primary .accordion-primary__inner .accordion-primary__heading {
  margin-top: 0;
}
.wysiwyg__body-container .accordion-primary__button:focus,
.accordion-primary .accordion-primary__button:focus {
  outline: 2px dotted currentColor;
  outline-offset: 4px;
}
.wysiwyg__body-container .wysiwyg__body a:focus, .wysiwyg__body-container .accordion-primary a:focus,
.accordion-primary .wysiwyg__body a:focus,
.accordion-primary .accordion-primary a:focus {
  outline: 2px dotted currentColor;
  outline-offset: 4px;
}

.wysiwyg__body,
.accordion-primary {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
}

/* Style tables */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 40px;
}
table caption {
  text-align: left;
  padding-bottom: 15px;
  padding-top: 15px;
  color: #c41230;
  font-size: 1.2em;
}
table thead {
  background-color: #edece2;
}
table thead tr th {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.04375rem;
  padding: 12px 20px;
  text-transform: uppercase;
  text-align: left;
  line-height: 1.86;
  letter-spacing: 1.75px;
  font-weight: 700;
  margin: 0;
}
table tbody a {
  color: #c41230;
  text-decoration: underline;
  outline: 2px dotted transparent;
  outline-offset: 0;
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
table tbody a:hover {
  text-decoration: none;
}
table tbody a:focus {
  text-decoration: none;
  outline: 2px dotted #c41230;
}
table tbody tr th {
  border-bottom: 2px solid #ccc9b8;
  text-align: left;
  padding: 12px 20px;
  vertical-align: top;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  line-height: 110%;
  color: #c41230;
}
table tbody tr td {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  padding: 12px 20px;
  margin: 0;
  border-bottom: 2px solid #ccc9b8;
}
table tbody tr td strong {
  font-family: proxima-nova, sans-serif !important;
  font-weight: 600;
}
table tbody tr td.delimiter {
  border-color: #edece2 !important;
}
table tbody tr.alt td {
  background-color: #edece2;
}
table tfoot tr td {
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: normal;
  background-color: #edece2;
  padding: 12px 20px;
  margin: 0;
  font-weight: 500;
}
@media print, screen and (min-width: 64em) {
  table tfoot tr td {
    font-size: 16px;
    line-height: 1.5;
  }
}

@media print, screen and (min-width: 40em) {
  .wysiwyg--sidebar {
    margin-bottom: 25px;
    margin-right: 0;
    flex: 0 0 50%;
    max-width: 50%;
    margin-left: auto;
  }
}

.wysiwyg--news {
  margin-top: 25px;
  margin-bottom: 25px;
}
.wysiwyg--news .wysiwyg__container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .wysiwyg--news .wysiwyg__container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .wysiwyg--news .wysiwyg__container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .wysiwyg--news .wysiwyg__container {
    width: 90%;
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .wysiwyg--news .wysiwyg__container {
    width: 90%;
    max-width: 1170px;
  }
}
.wysiwyg--news .wysiwyg__lead-container-inner .wysiwyg__lead {
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  font-size: 18px;
}
@media print, screen and (min-width: 40em) {
  .wysiwyg--news .wysiwyg__lead-container-inner .wysiwyg__lead {
    font-size: 36px;
    line-height: 40px;
  }
}
.wysiwyg--news .wysiwyg__body,
.wysiwyg--news .accordion-primary {
  font-family: freight-text-pro, serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 350;
  letter-spacing: 0.015625rem;
  font-family: proxima-nova, sans-serif;
  font-weight: normal;
  line-height: 28px;
}
.wysiwyg--news .wysiwyg__body h2,
.wysiwyg--news .wysiwyg__body h3,
.wysiwyg--news .wysiwyg__body h4,
.wysiwyg--news .wysiwyg__body h5,
.wysiwyg--news .wysiwyg__body h6,
.wysiwyg--news .accordion-primary h2,
.wysiwyg--news .accordion-primary h3,
.wysiwyg--news .accordion-primary h4,
.wysiwyg--news .accordion-primary h5,
.wysiwyg--news .accordion-primary h6 {
  font-family: proxima-nova, sans-serif;
  font-weight: bold;
  margin-bottom: 17px;
}
@media print, screen and (min-width: 40em) {
  .wysiwyg--news .wysiwyg__body h2,
  .wysiwyg--news .wysiwyg__body h3,
  .wysiwyg--news .wysiwyg__body h4,
  .wysiwyg--news .wysiwyg__body h5,
  .wysiwyg--news .wysiwyg__body h6,
  .wysiwyg--news .accordion-primary h2,
  .wysiwyg--news .accordion-primary h3,
  .wysiwyg--news .accordion-primary h4,
  .wysiwyg--news .accordion-primary h5,
  .wysiwyg--news .accordion-primary h6 {
    margin-bottom: 30px;
  }
}
.wysiwyg--news .wysiwyg__body h2,
.wysiwyg--news .accordion-primary h2 {
  font-size: 24px;
  line-height: 26px;
}
.wysiwyg--news .wysiwyg__body h3,
.wysiwyg--news .accordion-primary h3 {
  font-size: 20px;
  line-height: 20px;
}
.wysiwyg--news .wysiwyg__body h4,
.wysiwyg--news .accordion-primary h4 {
  font-size: 17px;
  line-height: 26px;
}
.wysiwyg--news .wysiwyg__body p,
.wysiwyg--news .accordion-primary p {
  font-size: 16px;
  line-height: 28px;
}
.wysiwyg--news .wysiwyg__inner {
  padding: 0;
}
.wysiwyg--news .wysiwyg__header-container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
}
.wysiwyg--news .wysiwyg__heading-container,
.wysiwyg--news .wysiwyg__lead-container {
  flex: 0 0 100%;
  max-width: 100%;
}
.wysiwyg--news .wysiwyg__heading-container-inner,
.wysiwyg--news .wysiwyg__lead-container-inner {
  flex: 0 0 100%;
  max-width: 100%;
}
@media print, screen and (min-width: 40em) {
  .wysiwyg--news .wysiwyg__heading-container-inner,
  .wysiwyg--news .wysiwyg__lead-container-inner {
    flex: 0 0 50%;
    max-width: 50%;
    margin-right: auto;
    margin-left: auto;
  }
  .wysiwyg--news .wysiwyg__heading-container-inner, .wysiwyg--news .wysiwyg__heading-container-inner:last-child:not(:first-child),
  .wysiwyg--news .wysiwyg__lead-container-inner,
  .wysiwyg--news .wysiwyg__lead-container-inner:last-child:not(:first-child) {
    float: none;
    clear: both;
  }
}
.wysiwyg--news .wysiwyg__body-container {
  flex: 0 0 100%;
  max-width: 100%;
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
}
.wysiwyg--news .wysiwyg__body-container-inner {
  flex: 0 0 100%;
  max-width: 100%;
}
@media print, screen and (min-width: 40em) {
  .wysiwyg--news .wysiwyg__body-container-inner {
    flex: 0 0 60%;
    max-width: 60%;
    margin-left: 20%;
  }
}

.news-sidebar__main-content .wysiwyg__inner {
  padding-left: 0px;
  padding-right: 0px;
}
.news-sidebar__main-content .wysiwyg__container {
  margin-left: 0px;
  margin-right: 0px;
}

.wysiwyg__body-container--drop-caps .wysiwyg__body :first-child:first-letter {
  color: #c41230;
  font-family: freight-display-pro, serif;
  font-size: 125px;
  line-height: 100px;
  float: left;
  padding-top: 0px;
  padding-right: 8px;
  padding-left: 3px;
}
.wysiwyg__body-container--drop-caps .wysiwyg__body {
  font-family: freight-text-pro, serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 350;
  letter-spacing: 0.015625rem;
  font-family: proxima-nova, sans-serif;
}
@media print, screen and (min-width: 40em) {
  .wysiwyg__body-container--drop-caps .wysiwyg__body {
    line-height: 28px;
  }
}

.wysiwyg__body-container-inner--with-inline-image {
  flex: 0 0 100%;
  max-width: 100%;
}
@media print, screen and (min-width: 40em) {
  .wysiwyg__body-container-inner--with-inline-image {
    flex: 0 0 50%;
    max-width: 50%;
    margin-right: auto;
    margin-left: auto;
  }
  .wysiwyg__body-container-inner--with-inline-image, .wysiwyg__body-container-inner--with-inline-image:last-child:not(:first-child) {
    float: none;
    clear: both;
  }
}

.wysiwyg__body-inline-block {
  width: 100%;
  background: #fff;
}

@media print, screen and (min-width: 40em) {
  .side-left {
    float: left;
    margin-left: -40%;
    width: 70%;
    margin-right: 21px;
  }
}

@media print, screen and (min-width: 40em) {
  .side-right {
    float: right;
    margin-right: -40%;
    width: 70%;
    margin-left: 21px;
  }
}

.wysiwyg__body-inline-image-wrapper {
  position: relative;
  padding-top: 100%;
  width: 100%;
  height: 0;
}
.wysiwyg__body-inline-image-wrapper .wysiwyg__body-container-inner {
  flex: 0 0 100%;
  max-width: 100%;
}
@media print, screen and (min-width: 40em) {
  .wysiwyg__body-inline-image-wrapper .wysiwyg__body-container-inner {
    flex: 0 0 50%;
    max-width: 50%;
    margin-left: 25%;
  }
}

.wysiwyg__body-inline-image {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  right: 0;
  object-fit: cover;
}

.wysiwyg__body-inline-image-divider {
  background-color: #d1d0d0;
  height: 2px;
  width: 100%;
  margin-bottom: 20px;
}

.wysiwyg__body-inline-image-lockup-wrapper {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  padding: 20px 0;
}

.avoid-clicks {
  pointer-events: none;
}

.highlightable::-moz-selection {
  background-color: #edece2;
}
.highlightable::selection {
  background-color: #edece2;
}

.wysiwyg__body-divider {
  border-bottom: 1px solid #ccc9b8;
  margin-top: 40px;
  margin-bottom: 20px;
}

.tweet-this-container.wysiwyg__body {
  font-family: proxima-nova, sans-serif;
}
.tweet-this-container.wysiwyg__body h2,
.tweet-this-container.wysiwyg__body h3,
.tweet-this-container.wysiwyg__body h4,
.tweet-this-container.wysiwyg__body h5,
.tweet-this-container.wysiwyg__body h6 {
  font-family: proxima-nova, sans-serif;
  font-weight: bold;
  margin-bottom: 17px;
}
@media print, screen and (min-width: 40em) {
  .tweet-this-container.wysiwyg__body h2,
  .tweet-this-container.wysiwyg__body h3,
  .tweet-this-container.wysiwyg__body h4,
  .tweet-this-container.wysiwyg__body h5,
  .tweet-this-container.wysiwyg__body h6 {
    margin-bottom: 30px;
  }
}
.tweet-this-container.wysiwyg__body h2 {
  font-size: 24px;
  line-height: 26px;
}
.tweet-this-container.wysiwyg__body h3 {
  font-size: 20px;
  line-height: 20px;
}
.tweet-this-container.wysiwyg__body h4 {
  font-size: 17px;
  line-height: 26px;
}
.tweet-this-container.wysiwyg__body p {
  font-size: 16px;
  line-height: 28px;
}

.wysiwyg__body .localist-widget .modernList .localist_widget_container li.lw_event_item {
  border: none !important;
}
.wysiwyg__body .localist-widget .modernList .localist_widget_container li.lw_event_item::before {
  content: none;
}
.wysiwyg__body .localist-widget .modernList .localist_widget_container li.lw_event_item a.lw_event_item_image span.lw_event_item_date {
  background-color: #C41230;
  padding: 10px;
}
.wysiwyg__body .localist-widget .modernList .localist_widget_container li.lw_event_item .lw_event_content .lw_event_item_title a {
  font-family: freight-display-pro, serif;
  font-weight: 400;
  font-style: normal;
  line-height: 30px;
  color: #c41230 !important;
  font-size: 24px !important;
  border-bottom: none !important;
  letter-spacing: 0.01562rem;
  outline: 2px dotted transparent;
  transition: all 0.2s ease;
  text-decoration: none;
}
.wysiwyg__body .localist-widget .modernList .localist_widget_container li.lw_event_item .lw_event_content .lw_event_meta div.action_button {
  display: none !important;
}
.wysiwyg__body .localist-widget .cardWidget .event-list li.event::before {
  content: none;
}
.wysiwyg__body .localist-widget .cardWidget .event-list li.event::marker {
  content: none;
}
.wysiwyg__body .localist-widget .cardWidget .event-list li.event .event-card .event-overview h2.event-title {
  font-family: freight-display-pro, serif;
  font-weight: 400;
  line-height: 30px;
  color: #c41230 !important;
  font-size: 24px !important;
  border-bottom: none !important;
  letter-spacing: 0.01562rem;
  outline: 2px dotted transparent;
  transition: all 0.2s ease;
  margin: 0;
}
.wysiwyg__body .localist-widget .classicList .lw li.lwe {
  padding: 30px 10px !important;
}
.wysiwyg__body .localist-widget .classicList .lw li.lwe::before {
  content: none;
}
.wysiwyg__body .localist-widget .classicList .lw li.lwe .lwn .lwn0 {
  color: #7e6d53;
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.125rem;
  line-height: 20px;
  text-transform: uppercase;
}
.wysiwyg__body .localist-widget .classicList .lw li.lwe .lwn a {
  font-family: freight-display-pro, serif;
  font-weight: 400;
  line-height: 30px;
  color: #c41230 !important;
  font-size: 24px !important;
  border-bottom: none !important;
  letter-spacing: 0.01562rem;
  outline: 2px dotted transparent;
  transition: all 0.2s ease;
  font-style: normal;
  text-decoration: none;
}
.wysiwyg__body .localist-widget .classicList .lw li.lwe .lwl .lwl0 {
  color: #464646;
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.05rem;
  line-height: 20px;
  text-transform: uppercase;
}
.wysiwyg__body .localist-widget .classicList .lw li.lwe .lwl a {
  color: #464646;
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.05rem;
  line-height: 20px;
  text-transform: uppercase;
  border-bottom: none;
}
.wysiwyg__body .localist-widget .classicList .lw li.lwe .lwl a:hover {
  color: #464646;
}
.wysiwyg__body #lclst_widget_footer {
  display: none;
}

#form_question_6fc60ce1-9a2e-4a84-8577-611b7432cb4c {
  display: none;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeInDown {
  animation-name: fadeInDown;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

.miniRfiModal {
  z-index: 100;
}

.miniRfiModal h1 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  float: left;
  display: inline-block;
  width: 80%;
}

.miniRfiModal .overlay {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 110;
}

.miniRfiModal .form_rfi {
  position: fixed;
  z-index: 120;
  top: 0px;
}

.miniRfiModal .modal {
  position: fixed;
  z-index: 120;
}

.miniRfiModal .form_rfi {
  background-color: #edece2;
  padding: 20px;
}

.miniRfiModal div.action.form_action > button, .form_button_submit {
  background-color: #c41230;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 0px !important;
  text-transform: uppercase;
}

.miniRfiModal div.action.form_action > button:focus, .form_button_submit:focus {
  background-color: #ad102a;
}

#miniRfiModal {
  float: right;
  margin-bottom: 10px;
  padding: 5px;
}

#miniRfiModal:focus {
  color: black;
  background-color: transparent;
}

#form_mini_rfi {
  clear: both;
}

.dependency-video__modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 500;
}
.dependency-video__modal .ModalContainer__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}
.dependency-video__modal .ModalContainerDialog {
  width: 100%;
  background: #231f20;
  position: relative;
}
.dependency-video__modal .ModalContainerDialog .video-modal__video-wrapper {
  width: 100%;
  height: 56.25vw; /* height:width ratio = 9/16 = .5625  */
  max-height: 100vh;
  max-width: 177.78vh; /* 16/9 = 1.778 */
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0; /* vertical center */
  left: 0;
  right: 0; /* horizontal center */
  background-color: #231f20;
}
.dependency-video__modal .ModalContainerDialog .video-modal__video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
}
.dependency-video__modal .ModalButton--close {
  position: absolute;
  z-index: 6;
  top: 0;
  right: 0;
  height: 70px;
  width: 70px;
  color: #fff;
  background: url("../svgs/functional/icon-menu-white-close.svg") no-repeat scroll center #ad102a;
  cursor: pointer;
  text-indent: -9999px;
}
.dependency-video__modal .ModalButton--closefocus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.dependency-video__modal .ModalButton--close:focus, .dependency-video__modal .ModalButton--close:hover {
  background: url("../svgs/functional/icon-red-close.svg") no-repeat scroll center #fff;
}

.submenu--wrapper {
  position: relative;
  transition: 0.35s cubic-bezier(0.4, 0, 0.2, 1) height;
  /*&.submenu--wrapper--active {
    height: 60px;

    @include breakpoint(xlarge) {
      height: 50px;
    }
  } */
}

@keyframes horizontalBounce {
  0% {
    transform: translateX(0px);
    width: calc(100vw - 24px);
  }
  50% {
    transform: translateX(-95px);
    width: calc(100vw + 95px - 24px);
  }
  100% {
    transform: translateX(0px);
    width: calc(100vw - 24px);
  }
}
#submenu {
  border-bottom: 1px solid #c41230;
  background: #fff;
  position: fixed;
  left: 0;
  right: 0;
  top: 78px;
  z-index: 11;
  height: 0;
  overflow: hidden;
  transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1) all;
}
#submenu.submenu--active {
  height: auto;
}
#submenu .submenu--inner .submenu__title {
  color: #c41230;
  font-family: freight-display-pro, serif;
  font-size: 15px;
  padding: 8px 12px 4px;
}
#submenu .submenu--inner .submenu__title a:hover {
  text-decoration: underline;
}
#submenu .submenu--inner .submenu__title a:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
#submenu .submenu--inner .submenu-nav {
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  letter-spacing: 0.05em;
  position: relative;
}
#submenu .submenu--inner .submenu-nav.submenu-nav__arrow::after {
  content: "";
  position: absolute;
  right: 12px;
  bottom: 10px;
  display: block;
  width: 9px;
  height: 9px;
  background-color: transparent;
  border-top: 2px solid #c41230;
  border-right: 2px solid #c41230;
  transform: translateY(-50%) rotate(45deg);
  -webkit-transform: translateY(-50%) rotate(45deg);
}
@media screen and (min-width: 48em) {
  #submenu .submenu--inner .submenu-nav.submenu-nav__arrow::after {
    display: none;
  }
}
#submenu .submenu--inner .submenu-nav .submenu-nav__list {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  width: calc(100vw - 24px);
  padding: 4px 0 12px;
  ms-overflow-style: none;
  scrollbar-width: none;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-name: horizontalBounce;
}
@media screen and (min-width: 48em) {
  #submenu .submenu--inner .submenu-nav .submenu-nav__list {
    animation: unset;
  }
}
#submenu .submenu--inner .submenu-nav .submenu-nav__list::-webkit-scrollbar {
  display: none;
}
#submenu .submenu--inner .submenu-nav .submenu-nav__list .submenu-nav__list-link {
  white-space: nowrap;
  margin: 0 16px;
  padding-top: 2px;
}
#submenu .submenu--inner .submenu-nav .submenu-nav__list .submenu-nav__list-link:first-child {
  margin-left: 12px;
}
#submenu .submenu--inner .submenu-nav .submenu-nav__list .submenu-nav__list-link:last-child {
  padding-right: 32px;
}
#submenu .submenu--inner .submenu-nav .submenu-nav__list .submenu-nav__list-link a:hover {
  text-decoration: underline;
}
#submenu .submenu--inner .submenu-nav .submenu-nav__list .submenu-nav__list-link a:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
#submenu .submenu--inner .submenu-nav .submenu-nav__list .submenu-nav__list-link a.submenu-nav__link-active {
  font-weight: bold;
  text-decoration: underline;
}
@media print, screen and (min-width: 64em) {
  #submenu {
    top: unset;
  }
  #submenu.submenu--active {
    height: auto;
  }
  #submenu .submenu--inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px 0 16px;
  }
  #submenu .submenu--inner .submenu__title {
    padding: 12px 0;
    font-size: 22px;
    font-weight: 500;
    width: auto;
  }
  #submenu .submenu--inner .submenu-nav {
    width: max-content;
    font-size: 16px;
    font-weight: 400;
  }
  #submenu .submenu--inner .submenu-nav .submenu-nav__list {
    overflow-x: visible;
    padding: 0;
    width: auto;
  }
  #submenu .submenu--inner .submenu-nav .submenu-nav__list::after {
    display: none;
  }
  #submenu .submenu--inner .submenu-nav .submenu-nav__list .submenu-nav__list-link {
    margin: 0 1.5vw;
  }
  #submenu .submenu--inner .submenu-nav .submenu-nav__list .submenu-nav__list-link:last-child {
    padding-right: 0;
  }
}

.visuallyhidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.show-overflow {
  overflow: visible !important;
}

.show-overflow .box-content {
  overflow: visible !important;
}

#flyoutnavkbbtn {
  display: inline-block;
  transition: top 0.3s;
  position: fixed;
}

#flyoutnavkbbtn > ul {
  margin: 0;
  padding: 0;
  background-color: #fff;
  color: #c41230;
}

#flyoutnavkbbtn > ul > li {
  text-align: center;
  position: relative;
  padding: 0;
  margin: 0;
  border-bottom: 2px solid #c41230;
}

@media (max-width: 768px) {
  #flyoutnavkbbtn > ul > li {
    display: flex;
  }
  #flyoutnavkbbtn .has-submenu button {
    border-top: 1px solid #fff;
  }
}
#flyoutnavkbbtn a, #flyoutnavkbbtn .current {
  flex: 1;
  display: block;
  /*padding: .25em;
  */
  border-color: #E8E8E8;
}

#flyoutnavkbbtn a {
  color: #c41230;
  text-decoration: none;
  display: inline-block;
}

#flyoutnavkbbtn li.has-submenu ul li a:hover, #flyoutnavkbbtn li.has-submenu ul li a:focus {
  color: #fff;
  border: none;
  text-decoration: underline;
}

#flyoutnavkbbtn li.has-submenu ul li a:focus {
  outline-offset: 2px;
  outline: 2px dotted #fff;
}

#flyoutnavkbbtn .current {
  background-color: #bbb;
  color: #000;
  border-color: #444;
}

#flyoutnavkbbtn > ul > li > ul {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  padding: 0;
  margin: 0;
  padding-bottom: 0.75em;
  padding-top: 0.75em;
  background-color: #c41230;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
}

@media (max-width: 767px) {
  #flyoutnavkbbtn > ul > li > ul {
    border-bottom: none;
    border-right: none;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
  }
}
#flyoutnavkbbtn > ul > li.open > ul {
  display: block;
}

#flyoutnavkbbtn > ul > li > ul li {
  text-align: left;
  padding: 10px 22px;
}

#flyoutnavkbbtn > ul > li > ul a {
  font-family: proxima-nova, helvetica, arial, sans-serif;
  font-style: normal;
  color: #fff;
  letter-spacing: 0.05em;
  font-size: 16px;
  text-align: left;
  font-weight: 300;
  padding: 0 4px;
}

#flyoutnavkbbtn .has-submenu > a:after {
  margin-left: 5px;
  line-height: 14px;
  content: "";
}

#flyoutnavkbbtn .has-submenu:hover > a:hover:after, #flyoutnavkbbtn .has-submenu > a:focus:after {
  content: "";
}

#flyoutnavkbbtn .has-submenu button {
  background-color: #ad102a;
  border: none;
  padding: 0;
  line-height: 1;
  padding: 3px;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

@media (max-width: 767px) {
  #flyoutnavkbbtn .has-submenu button {
    border-right: none;
    border-bottom: none;
    border-top: 1px solid #fff;
  }
}
#flyoutnavkbbtn .has-submenu button > span {
  display: inline-block;
  width: 0.8em;
  height: 0.8em;
  background: none;
}

#flyoutnavkbbtn .has-submenu:hover button > span, #flyoutnavkbbtn .has-submenu button:focus > span {
  /*background: url(../../img/ex-dropdown-active-330d8be7.png) #fff;
  */
}

#flyoutnavkbbtn .has-submenu button, #flyoutnavkbbtn .has-submenu a:focus + button {
  background-color: #edece2;
  border-left: 1px solid #ccc9b8;
  display: inline-block;
  width: 50px;
  height: 100%;
  padding-top: 16px;
  padding-bottom: 14px;
  outline: 2px dotted transparent;
  outline-offset: -4px;
  transition: all 0.2s ease;
}

@media (max-width: 767px) {
  #flyoutnavkbbtn .has-submenu button, #flyoutnavkbbtn .has-submenu a:focus + button {
    height: auto;
    width: 75px;
  }
}
#flyoutnavkbbtn .open button {
  background-color: #c41230 !important;
  color: #fff;
}

/*#flyoutnavkbbtn .has-submenu:hover button,*/
#flyoutnavkbbtn .has-submenu button:hover, #flyoutnavkbbtn .has-submenu button:focus {
  /*background: #FFF;
  */
  /*border: 1px solid #036;
  */
  /*border-left: none;
  */
  background-color: #ad102a;
}

#flyoutnavkbbtn .has-submenu button:focus {
  outline: 2px dotted #fff;
}

.box-content {
  display: inline-block;
  position: fixed;
  top: 84px;
  left: 0;
  z-index: 99;
}

@media (max-width: 1028px) {
  .box-content {
    top: 78px;
  }
}
#flyoutnavkbbtn > ul > li > a {
  font-size: 26px;
  font-weight: 500;
  width: auto;
  color: #c41230;
  font-family: freight-display-pro, "Times New Roman", times, serif;
  font-style: normal;
  padding: 12px 22px;
  outline-offset: -4px;
  line-height: 19px;
}

@media (max-width: 767px) {
  #flyoutnavkbbtn > ul > li > a {
    font-size: 18px;
    font-family: freight-text-pro, "Times New Roman", times, serif;
    text-align: left;
  }
}
#flyoutnavkbbtn > ul > li > a:hover, #flyoutnavkbbtn > ul > li > a:focus {
  text-decoration: underline;
}

#flyoutnavkbbtn > ul > li > a:focus {
  outline: 2px dotted #c41230;
}

.box-content ul li:before {
  content: none !important;
}

.box-content .has-submenu button div.sub-menu-carat {
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
  width: 10px;
  height: 10px;
  margin-top: -2px;
  transform: translate(0px, 2px) rotate(45deg);
  transition: all 0.2s ease;
}

.box-content .has-submenu.open button div.sub-menu-carat {
  transform: translate(0px, 4px) rotate(225deg);
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

.box-content .has-submenu button:hover div.sub-menu-carat, .box-content .has-submenu button:focus div.sub-menu-carat {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

#flyoutnavkbbtn .has-submenu.open button {
  background-color: #ad102a;
}

@media only screen and (max-width: 600px) {
  .box-content {
    top: 78px;
  }
}
div.omnilertBox {
  bottom: 0;
  left: 0;
  z-index: 9999;
  position: fixed;
  width: 100%;
}

table.SmartBoard_Table {
  position: relative;
  margin-bottom: 0 !important;
  background: #c41230;
  width: 100%;
  color: #fff;
  border: 4px solid white;
  padding: 5px;
}

table.SmartBoard_Table td {
  border: none;
}

table.SmartBoard_Table td.SmartBoard_Subject {
  font-family: freight-display-pro, serif;
  font-size: 24px;
  font-weight: 400 !important;
  color: #fff;
  line-height: 1.3em !important;
  background: transparent; /*color: #fff !important;*/
  padding: 10px 15px 5px;
}

table.SmartBoard_Table td.SmartBoard_DateTime {
  color: #fff;
  line-height: 1.3em;
  background: transparent; /*color: #fff !important;*/
  font-size: 1.2em;
  padding: 10px 15px 5px;
  text-align: right;
}

table.SmartBoard_Table td.SmartBoard_Message {
  background: transparent; /*color: #fff !important;*/
  font-size: 16px;
  padding: 15px;
  background: #fff;
  border: 1px solid #ddd;
  color: #000;
}

@media print, screen and (min-width: 64em) {
  table.SmartBoard_Table td.SmartBoard_Subject {
    font-size: 38px;
  }
  table.SmartBoard_Table td.SmartBoard_Message {
    font-size: 18px;
  }
}
.testjscolor {
  background-color: #EFDB72;
  padding: 10px;
  color: #c41230;
}

.news-category-title-block {
  color: #c41230;
  fill: #c41230;
}
.news-category-title-block.text-red {
  color: #c41230;
  fill: #c41230;
}
.news-category-title-block.text-white {
  color: #fff;
  fill: #fff;
}
.news-category-title-block.text-black {
  color: #231f20;
  fill: #231f20;
}
.news-category-title-block__container {
  display: flex;
  align-items: center;
}
.news-category-title-block__icon-wrapper {
  height: 32px;
  width: 100%;
  max-width: 32px;
  margin-right: 28px;
  margin-top: 5px;
  fill: #c41230;
}
.news-category-title-block__title {
  font-family: freight-display-pro, serif;
  font-size: 36px;
  font-weight: 300;
  line-height: 44px;
  line-height: 38px;
}
@media print, screen and (min-width: 40em) {
  .news-category-title-block__title {
    font-size: 48px;
    font-weight: 300;
    line-height: 59px;
  }
}
@media print, screen and (min-width: 40em) {
  .news-category-title-block__title {
    line-height: 44px;
  }
}
@media print, screen and (min-width: 64em) {
  .news-category-title-block__title {
    font-family: freight-display-pro, serif;
    font-size: 58px;
    font-weight: 300;
    line-height: 71px;
  }
}

.title-block-w-cta__container .base-cta-text {
  margin-bottom: 20px;
}
@media print, screen and (min-width: 40em) {
  .title-block-w-cta__container {
    display: flex;
    flex-flow: row nowrap;
  }
}

.title-block-w-cta__title-block {
  flex: 1;
}

.article-6col {
  flex: 0 0 100%;
  max-width: 100%;
}
@media print, screen and (min-width: 40em) {
  .article-6col {
    flex: 0 0 49.1666666667%;
    max-width: 49.1666666667%;
  }
}
.article-6col__background-image {
  object-fit: cover;
  z-index: -1;
}
@media print, screen and (min-width: 40em) {
  .article-6col__card-container {
    margin-bottom: 0;
  }
}
.article-6col__card-link {
  display: block;
}
.article-6col__copy-wrapper {
  background: #fff;
  padding-top: 20px;
  position: relative;
  z-index: 2;
  display: flex;
  flex-flow: column wrap;
}
@media print, screen and (min-width: 40em) {
  .article-6col__copy-wrapper {
    width: calc(100% - 86px);
    padding: 20px 20px;
    margin-top: -65px;
  }
}
@media print, screen and (min-width: 64em) {
  .article-6col__copy-wrapper {
    padding: 32px 36px;
  }
}
.article-6col__copy-wrapper:after {
  margin-top: 18px;
  margin-bottom: 31px;
}
@media print, screen and (min-width: 40em) {
  .article-6col__copy-wrapper:after {
    display: none;
  }
}
.article-6col__copy-wrapper .news-lockup__title {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 22px;
  margin-bottom: 13px;
}
@media print, screen and (min-width: 40em) {
  .article-6col__copy-wrapper .news-lockup__title {
    font-family: proxima-nova, sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
  }
}
@media print, screen and (min-width: 64em) {
  .article-6col__copy-wrapper .news-lockup__title {
    font-family: proxima-nova, sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
  }
}
.article-6col__copy-wrapper .news-lockup__headline {
  margin-bottom: 13px;
}
@media print, screen and (min-width: 40em) {
  .article-6col--odd {
    flex: 0 0 32.5%;
    max-width: 32.5%;
  }
  .article-6col--odd .article-6col__copy-wrapper {
    width: 100%;
    margin-top: 0;
    padding: 20px 0 31px 0;
  }
  .article-6col--odd .article-6col__copy-wrapper .news-lockup__headline {
    margin-bottom: 13px;
  }
  .article-6col--odd .article-6col__copy-wrapper .news-lockup__title {
    font-family: proxima-nova, sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 22px;
    font-size: 18px;
  }
}

.article-6col__copy:focus .news-lockup__title {
  text-decoration: underline;
}

.blog-lockup-item__item-container {
  top: 0;
  height: 100%;
  display: flex;
  height: 100%;
}
.blog-lockup-item__image {
  top: 0;
  left: 0;
  -webkit-box-shadow: 7.5px 7.5px 0 0 rgb(196, 18, 48);
  -moz-box-shadow: 7.5px 7.5px 0 0 rgb(196, 18, 48);
  box-shadow: 7.5px 7.5px 0 0 rgb(196, 18, 48);
  min-width: 42px;
}
.blog-lockup-item__date {
  top: 0;
  left: 0;
  border: 1px solid #c41230;
  -webkit-box-shadow: 7.5px 7.5px 0 0 rgb(196, 18, 48);
  -moz-box-shadow: 7.5px 7.5px 0 0 rgb(196, 18, 48);
  box-shadow: 7.5px 7.5px 0 0 rgb(196, 18, 48);
  width: 75px;
  height: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.blog-lockup-item__date-month {
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.09375rem;
  text-transform: uppercase;
}
.blog-lockup-item__date-day {
  font-family: freight-display-pro, serif;
  font-size: 46px;
  font-weight: 400;
  margin-top: -25px;
}
@media print, screen and (min-width: 64em) {
  .blog-lockup-item__date-day {
    font-size: 58px;
    font-weight: 300;
  }
}
.blog-lockup-item__image-shadow, .blog-lockup-item__date-shadow {
  position: absolute;
  z-index: -1;
  background-color: #c41230;
  top: 10%;
  left: 10%;
}
.blog-lockup-item__image-wrapper, .blog-lockup-item__date-wrapper {
  flex: 0 0 25%;
  max-width: 25%;
  position: relative;
  margin-right: 25px;
}
.blog-lockup-item__date-wrapper {
  display: flex;
  align-items: center;
}
.blog-lockup-item__link {
  width: 100%;
  height: 100%;
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  max-width: unset;
}
.blog-lockup-item__lockup {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.blog-lockup-item__lockup .news-lockup__headline {
  margin-bottom: 5px;
}
.blog-lockup-item__lockup .news-lockup__title {
  margin-bottom: 0;
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 22px;
}

.category-index-card {
  max-width: unset;
}
.category-index-card__link:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.category-index-card__link:focus .news-lockup__title, .category-index-card__link:hover .news-lockup__title {
  text-decoration: underline;
}
.category-index-card__card-lockup {
  margin-top: 15px;
}
.category-index-card__card-lockup .news-lockup__title {
  margin-bottom: 5px;
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 22px;
}
.category-index-card__card-lockup .news-lockup__body {
  margin-bottom: 18px;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media print, screen and (min-width: 40em) {
  .category-index-card__card-lockup-wrapper {
    padding-left: 20px;
  }
}
.category-index-card__container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  position: relative;
}
.category-index-card__image {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  object-fit: cover;
}
.category-index-card__image-wrapper {
  padding-top: 75%;
  position: relative;
  height: 0;
}
@media print, screen and (min-width: 40em) {
  .category-index-card__image-wrapper {
    padding-top: 37.5%;
  }
}
.category-index-card__card-lockup-wrapper, .category-index-card__image-wrapper {
  flex: 0 0 100%;
  max-width: 100%;
}
@media print, screen and (min-width: 40em) {
  .category-index-card__card-lockup-wrapper, .category-index-card__image-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.text-italic {
  font-style: italic;
}

.text-title {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 22px;
  color: #282828;
  margin-bottom: 23px;
}
@media print, screen and (min-width: 40em) {
  .text-title {
    line-height: 40px;
    font-size: 36px;
    margin-bottom: 16px;
  }
}

.news-lockup__headline,
.news-lockup__title,
.news-lockup__body,
.news-lockup__byLine {
  font-family: proxima-nova, sans-serif;
}
.news-lockup__headline.text-white,
.news-lockup__title.text-white,
.news-lockup__body.text-white,
.news-lockup__byLine.text-white {
  color: #fff;
}
.news-lockup__headline.text-red,
.news-lockup__title.text-red,
.news-lockup__body.text-red,
.news-lockup__byLine.text-red {
  color: #c41230;
}
.news-lockup__headline.text-white-warm,
.news-lockup__title.text-white-warm,
.news-lockup__body.text-white-warm,
.news-lockup__byLine.text-white-warm {
  color: #faf9f7;
}
.news-lockup__headline:focus-within a,
.news-lockup__title:focus-within a,
.news-lockup__body:focus-within a,
.news-lockup__byLine:focus-within a {
  text-decoration: underline;
}
.news-lockup__headline a:hover,
.news-lockup__title a:hover,
.news-lockup__body a:hover,
.news-lockup__byLine a:hover {
  text-decoration: underline;
}
.news-lockup__headline:focus-within,
.news-lockup__title:focus-within,
.news-lockup__body:focus-within,
.news-lockup__byLine:focus-within {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.news-lockup__headline a:focus,
.news-lockup__title a:focus,
.news-lockup__body a:focus,
.news-lockup__byLine a:focus {
  outline: 0;
  text-decoration: underline;
}

.news-lockup__headline {
  font-family: proxima-nova, sans-serif;
  font-style: italic;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.0625rem;
  margin-bottom: 8px;
}
@media print, screen and (min-width: 64em) {
  .news-lockup__headline {
    margin-bottom: 16px;
  }
}

.news-lockup__title {
  margin-bottom: 23px;
}
@media print, screen and (min-width: 64em) {
  .news-lockup__title {
    margin-bottom: 16px;
  }
}

.news-lockup__body {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
}
@media print, screen and (min-width: 64em) {
  .news-lockup__body {
    margin-bottom: 16px;
  }
}
.news-lockup__body.news-lockup__title a:focus {
  outline: none;
}
.news-lockup__body a {
  color: #c41230;
  text-decoration: underline;
  font-style: italic;
  outline: 2px dotted transparent;
  outline-offset: 0;
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.news-lockup__body a:hover {
  text-decoration: none;
}
.news-lockup__body a:focus {
  text-decoration: none;
  outline: 2px dotted #c41230;
}
.news-lockup__body h2 {
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #c41230;
  padding-top: 8px;
  padding-bottom: 8px;
}
@media print, screen and (min-width: 64em) {
  .news-lockup__body h2 {
    font-size: 20px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #c41230;
  }
}
.news-lockup__body h3 {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: #231f20;
  padding-top: 8px;
  padding-bottom: 8px;
}
@media print, screen and (min-width: 64em) {
  .news-lockup__body h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.news-lockup__body h4 {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: #c41230;
  padding-top: 8px;
  padding-bottom: 8px;
}
@media print, screen and (min-width: 64em) {
  .news-lockup__body h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.news-lockup__body h5 {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: #231f20;
  padding-top: 8px;
  padding-bottom: 8px;
}
@media print, screen and (min-width: 64em) {
  .news-lockup__body h5 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.news-lockup__body h6 {
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.09375rem;
  color: #c41230;
  padding-top: 8px;
  padding-bottom: 8px;
}
.news-lockup__body p {
  margin-bottom: 20px;
}
.news-lockup__body ul {
  margin-bottom: 20px;
}
.news-lockup__body ul li {
  padding: 8px 0 8px 18px;
}
.news-lockup__body ul li:before {
  content: "";
  background-image: url("../svgs/functional/icon-target--red.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 20px;
  margin-left: -30px;
}
.news-lockup__body ol {
  list-style: none;
  counter-reset: my-awesome-counter;
  margin-bottom: 20px;
}
.news-lockup__body ol li {
  padding: 8px 0;
  counter-increment: my-awesome-counter;
  padding-left: 36px;
}
.news-lockup__body ol li:before {
  content: counter(my-awesome-counter);
  font-family: proxima-nova, sans-serif;
  color: #c41230;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.75px;
  margin-right: 16px;
  text-align: center;
  display: inline-block;
  width: 20px;
  vertical-align: middle;
  margin-left: -36px;
}

.news-lockup__byline {
  display: flex;
  align-items: center;
}

.news-lockup__byline--text {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.0625rem;
  color: #7e6d53;
}

.news-lockup__byLine--divider {
  color: #7e6d53;
  margin: 0 1ch;
}

.news-lockup__title span {
  padding-right: 10px;
}

.news-lockup__title-inline-image {
  display: inline-block;
  height: 12px;
  width: 12px;
}
.news-lockup__title-inline-image > img {
  height: 100%;
  width: 100%;
  margin-top: 1px;
}

div.modal {
  display: block;
  border: 0;
}

.invisible {
  visibility: hidden;
}

/* removes scroll when modal is opened */
.no-scroll {
  overflow: hidden;
}

/* overlay covers everything */
.simple-animated-modal-overlay,
.simple-modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #c41230;
  opacity: 0.95;
  z-index: 666;
  cursor: pointer;
}

.simple-modal-overlay[data-background-click=disabled],
.simple-animated-modal-overlay[data-background-click=disabled] {
  cursor: auto;
}

.simple-animated-modal-overlay {
  animation: fadewhite ease 0.5s 1 normal;
}

@keyframes fadewhite {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.8;
  }
}
/* modal */
.simple-animated-modal,
.simple-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  /*max-height: 98vh;
  */
  /*border: 2px solid #000;
  */
  background: #fff;
  z-index: 667;
  padding-left: 2em;
  padding-right: 2em;
  max-width: 1400px;
  right: auto;
  overflow: auto;
}

.simple-animated-modal-close,
.simple-modal-close {
  float: right;
  background: #fff;
  /*border-radius: 1em;
  */
  color: #fff;
  border: 0;
  font: inherit;
  padding: 0.75em 1em;
  cursor: pointer;
  outline: 2px dotted transparent;
  outline-offset: -4px;
}

.simple-animated-modal-close:active,
.simple-animated-modal-close:focus,
.simple-animated-modal-close:hover,
.simple-modal-close:active,
.simple-modal-close:focus,
.simple-modal-close:hover {
  outline: 2px dotted #c41230;
}

.simple-animated-modal-close:active,
.simple-animated-modal-close:hover,
.simple-modal-close:active,
.simple-modal-close:hover {
  background: #ccc9b8;
}

.simple-animated-modal {
  animation: apparition ease 0.5s 1 normal;
}

@keyframes apparition {
  0% {
    opacity: 0;
    max-height: 100%;
    width: 80%;
  }
  100% {
    opacity: 1;
    max-height: 100%;
    width: 80%;
  }
}
/* CUSTOM STYLES FOR PSA MODAL */
div[class$=-modal] {
  background: transparent;
  border: none;
}

.simple-animated-modal__content > div {
  display: flex;
  background-color: #000;
}

.profilePopupInfo {
  flex-basis: 40%;
  padding: 1em;
}

.profilePopupInfo h3 {
  color: #c41230;
  font-family: "Freight Display Semibold", Times, Serif;
  font-size: 2em;
}

.profilePopupInfo span {
  color: #c41230;
  font-family: "Proxima Bold", Helvetica, Sans-Serif;
  font-size: 1.25em;
  text-transform: uppercase;
}

.profilePopupInfo p {
  color: #c41230;
  font-family: "Freight Text Book", Times, Serif;
  font-size: 1.125em;
  line-height: 140%;
}

.gridImagePopup {
  width: 100%;
  height: 100%;
  /*flex-basis:60%;
  */
  /*margin:1em;
  */
  /*outline:2px solid #c41230;
  */
  /*position:relative;
  */
  /*box-shadow:4px 4px #fff, 6px 6px #c41230;
  */
}

/*.gridImagePopup:before,*/
/*.gridImagePopup:after {
	*/
/* content:"";
	*/
/* position:absolute;
	*/
/* background:#c41230;
	*/
/*
}
*/
/*.gridImagePopup:before {
	*/
/* top:100%;
	*/
/* left:4px;
	*/
/* height:6px;
	*/
/* width:2px;
	*/
/*
}
*/
/*.gridImagePopup:after {
	*/
/* left:100%;
	*/
/* top:4px;
	*/
/* height:2px;
	*/
/* width:6px;
	*/
/*
}
*/
.songPopup {
  width: 100%;
  height: 100%;
  flex-basis: 100%;
  margin: 1em;
  outline: 2px solid #c41230;
  position: relative;
  box-shadow: 4px 4px #fff, 6px 6px #c41230;
}

.songPopup:after,
.songPopup:before {
  content: "";
  position: absolute;
  background: #c41230;
}

.songPopup:before {
  top: 100%;
  left: 4px;
  height: 6px;
  width: 2px;
}

.songPopup:after {
  left: 100%;
  top: 4px;
  height: 2px;
  width: 6px;
}

/* it can be easily adapted in media-queries for tablets/mobile */
/* for this example: tablets */
@media (max-width: 55.625em) {
  .simple-animated-modal,
  .simple-modal {
    left: 5%;
    top: 10%;
    height: 90%;
    width: 90%;
    transform: translate(0%, 0%);
  }
  .simple-animated-modal__content > div {
    display: block;
  }
}
/* MOBILE */
@media (max-width: 44.375em) {
  .simple-animated-modal__wrapper {
    position: relative;
  }
  .simple-animated-modal,
  .simple-modal {
    /*left: 1%;
    */
    left: 0;
    /*top: 20%;
    */
    top: 12%;
    /*width: 98%;
    */
    /*height: 98%;
    */
    width: 100%;
    height: 100%;
    transform: none;
    max-height: 100vh;
    padding-bottom: 50%;
    padding-left: 1em;
    padding-right: 1em;
  }
  .simple-animated-modal-close,
  .simple-modal-close {
    /*position: absolute;
    */
    /*top: 0;
    */
    /*right: 0;
    */
  }
  .simple-animated-modal__content {
    position: relative;
    /*top: 40px;
    */
    left: 0;
    width: 90%;
  }
  .simple-animated-modal__content > div {
    display: block;
  }
}
.hh-gallery-folder .post-feature-media__image-wrapper,
.hh-gallery-folder .post-feature-media__video-wrapper {
  padding-top: 0;
  height: auto;
}

.gridImageContainer {
  position: relative;
}

.gridImageInnerContainer {
  position: relative;
}

.cards-text__card-copy .gridImageContainer .gridImageInnerContainer a {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.gridImageContainer .gridImageInnerContainer > img {
  height: 280px;
  object-fit: cover;
  object-position: center;
}

.gridImageContainer .gridImageInnerContainer a {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.gridImageContainer .gridImageInnerContainer a img {
  width: 60px;
  height: 60px;
  background: #c41230;
  padding: 0.7em;
  border-radius: 30px;
  outline: 2px dotted transparent;
  transition: all 0.2s ease;
}

.gridImageContainer .gridImageInnerContainer a img:hover {
  background: transparent;
}

.gridImageContainer .gridImageInnerContainer a img:focus {
  background: #c41230;
  outline: 2px dotted #fff;
}

.responsiveImage {
  padding-top: 0;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  background: #000;
}

.plus-icon {
  position: relative;
  display: block;
  width: 60px;
  height: 60px;
  background-color: #c41230;
  border-radius: 30px;
  transition: 0.3s all ease;
}

.plus-icon:before {
  content: "";
  background-color: #fff;
  width: 12px;
  height: 2px;
  position: absolute;
  top: calc(50% - 0px);
  left: calc(50% - 6px);
}

.plus-icon:after {
  content: "";
  background-color: #fff;
  width: 2px;
  height: 12px;
  position: absolute;
  top: calc(50% - 5px);
  left: calc(50% - 1px);
}

.gridImageContainer a:hover .plus-icon {
  background-color: #fff;
}

.gridImageContainer a:hover .plus-icon:after,
.gridImageContainer a:hover .plus-icon:before {
  background-color: #c41230;
}

.gridImageContainer a:focus {
  outline: 2px dotted #fff;
}

.gridImageCaption {
  font-size: 0.9em;
  margin-top: 0.5em;
}

.gridImagePopup .imageFrame .gridImageCaption {
  color: #fff;
  padding: 0.5em 1em;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  bottom: 0;
  width: 100%;
}

.cards-text.cards-text--white .cards-text__card .cards-text__card-inner .cards-text__card-copy h2 {
  padding-top: 16px !important;
  padding-bottom: 0 !important;
}

.hidden {
  display: none;
}

/* overall container */
.post-feature-media__image-wrapper,
.post-feature-media__video-wrapper {
  position: initial;
  padding-top: 0;
  height: auto;
}

.post-feature-media__image-wrapper {
  padding-top: 0;
}

.g-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.g-row .gridImageContainer {
  flex-basis: 100%;
  padding-right: 1.25em;
  padding-left: 1.25em;
  margin-bottom: 3em;
}

@media print, screen and (min-width: 40em) {
  .g-row .gridImageContainer {
    flex-basis: 50%;
  }
}
@media print, screen and (min-width: 64em) {
  .g-row .gridImageContainer {
    flex-basis: 33.33333%;
  }
}
/* block styling */
.gridImageCaption {
  font-size: 1.125em;
  margin-top: 0.5em;
  font-family: freight-text-pro, serif;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.01562rem;
}

.hh-gallery-row .g-row-img {
  height: auto !important;
  margin: 0;
}

.hh-gallery-row .g-row-caption {
  margin: 0;
  width: auto;
}

.hh-gallery-row .g-row-img-wrapper .g-row-img {
  margin: 0;
}

.hh-gallery-row .g-row-img-wrapper:first-of-type .g-row-img {
  margin: 0;
  margin-left: 0;
}

.hh-gallery-row .g-row-img-wrapper:last-of-type .g-row-img {
  margin: 0;
  margin-right: 0;
}

.hh-gallery-row .g-row-img-wrapper .g-row-caption {
  margin: 0;
}

.hh-gallery-row .g-row-img-wrapper:first-of-type .g-row-caption {
  margin: 0;
}

.hh-gallery-row .g-row-img-wrapper:last-of-type .g-row-caption {
  margin: 0;
}

.hh-gallery-row .g-row-img-wrapper {
  margin-bottom: 1em;
}

/*@media print, screen and (min-width: 715px) {*/
/*    .hh-gallery-row .g-row-img-wrapper {*/
/*        width:100% !important;*/
/*    }*/
/*}*/
/* image widths */
.twentyFive {
  width: 100%;
}

.thirtyThree {
  width: 100%;
}

.fifty {
  width: 100%;
}

.sixtySix {
  width: 100%;
}

.seventyFive {
  width: 100%;
}

.oneHundred {
  width: 100%;
}

@media print, screen and (min-width: 880px) {
  .hh-gallery-row .g-row-img-wrapper {
    margin-bottom: 0em;
  }
  .twentyFive {
    width: 25%;
  }
  .thirtyThree {
    width: 33.333%;
  }
  .fifty {
    width: 50%;
  }
  .sixtySix {
    width: 66.666%;
  }
  .seventyFive {
    width: 75%;
  }
  .oneHundred {
    width: 100%;
  }
  .hh-gallery-row .g-row-img {
    height: auto !important;
    margin: 0 1em;
  }
  .hh-gallery-row .g-row-caption {
    margin: 0 1em 1em 1em;
    width: auto;
  }
  .hh-gallery-row .g-row-img-wrapper .g-row-img {
    margin: 0 0.5em 0 0.5em;
  }
  .hh-gallery-row .g-row-img-wrapper:first-of-type .g-row-img {
    margin: 0 1em 0 0;
    margin-left: 0;
  }
  .hh-gallery-row .g-row-img-wrapper:last-of-type .g-row-img {
    margin: 0 0 0 1em;
    margin-right: 0;
  }
  .hh-gallery-row .g-row-img-wrapper .g-row-caption {
    margin: 0 0.5em 2em 0.5em;
  }
  .hh-gallery-row .g-row-img-wrapper:first-of-type .g-row-caption {
    margin: 0 1.125em 2em 0;
  }
  .hh-gallery-row .g-row-img-wrapper:last-of-type .g-row-caption {
    margin: 0 0 2em 1.125em;
  }
}
.news-search-bar {
  width: 100%;
  position: relative;
}
.news-search-bar__form {
  width: 100%;
}
.news-search-bar__input-clear-button {
  margin: 0 13px;
  height: 16px;
  width: 16px;
  min-width: 16px;
}
.news-search-bar__input-clear-button:focus img {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}
.news-search-bar__input-container {
  border: #fff 1px solid;
  display: flex;
  flex-flow: row nowrap;
  color: #fff;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
}
@media print, screen and (min-width: 40em) {
  .news-search-bar__input-container {
    margin-right: 10px;
  }
}
.news-search-bar__search-icon {
  margin: 0 auto;
}
.news-search-bar__submit {
  display: block;
  box-sizing: border-box;
  width: 60px;
  background-color: white;
  margin: 0;
  padding: 21px;
}
.news-search-bar__submit:focus img {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.news-search-bar__text-input {
  -webkit-appearance: none;
  background: unset;
  display: block;
  box-sizing: border-box;
  border: 0;
  color: #fff;
  height: 100%;
  padding: 18px 0 18px 25px;
  font-family: proxima-nova, sans-serif;
  font-size: 20px;
  line-height: 26px;
  width: 80%;
}
.news-search-bar__text-input::placeholder {
  color: #fff;
  opacity: 1;
}
.news-search-bar__text-input::-ms-input-placeholder, .news-search-bar__text-input:-ms-input-placeholder {
  color: #fff;
}
.news-search-bar__wrapper {
  display: flex;
  max-width: 100%;
}

.news-pagination__container {
  margin: 10px 0 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.news-pagination__link {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.03125rem;
  line-height: 14px;
  height: 30px;
  display: block;
  min-width: 20px;
  padding: 7px;
  background-color: #edece2;
  outline-offset: 2px;
  outline: 2px dotted transparent;
  transition: 0.2s all ease;
}
.news-pagination__link:hover {
  background-color: #ad102a;
  color: white;
}
.news-pagination__link:focus {
  outline: 2px dotted #c41230;
}
.news-pagination__link.active {
  background-color: #c41230;
  color: white;
  display: none;
}
.news-pagination__link[aria-current]:not([aria-current=false]) {
  color: white;
  background-color: #c41230;
}
.news-pagination__list {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.news-pagination__list-item {
  height: 30px;
  min-width: 20px;
  margin-right: 10px;
  display: none;
}
.news-pagination__list-item:first-of-type, .news-pagination__list-item:last-of-type {
  display: block;
}
.bio-accordion__container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .bio-accordion__container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .bio-accordion__container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .bio-accordion__container {
    width: 90%;
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .bio-accordion__container {
    width: 90%;
    max-width: 1170px;
  }
}

.bio-accordion {
  flex: 0 0 100%;
  max-width: 100%;
}

.bio-accordion__header {
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  font-weight: 800;
  color: #c41230;
  padding-top: 32px;
  padding-bottom: 32px;
}
@media print, screen and (min-width: 64em) {
  .bio-accordion__header {
    font-size: 20px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #c41230;
  }
}

.bio__dropdown-bar {
  position: relative;
}

.bio__name {
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  padding-top: 28px;
  padding-bottom: 28px;
}
@media print, screen and (min-width: 64em) {
  .bio__name {
    font-size: 20px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #c41230;
  }
}

.bio__spacer {
  width: 100%;
  height: 1px;
  background-color: #ccc9b8;
}

.bio__image {
  width: 60vw;
  height: 45vw;
  object-fit: cover;
}
@media print, screen and (min-width: 64em) {
  .bio__image {
    width: 30vw;
    height: 22.5vw;
  }
}

.bio__blurb {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
  padding-top: 28px;
  padding-bottom: 28px;
}

.bio-accordion__button {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  width: 82px;
}
.bio-accordion__button:focus, .bio-accordion__button:hover {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}

.bio-accordion__button-element {
  position: absolute;
  background-color: #231f20;
}

.bio-accordion__button-element--horizontal {
  height: 2px;
  width: 33%;
  left: 33%;
  top: calc(50% - 1px);
}

.bio-accordion__button-element--vertical {
  height: 33%;
  width: 2px;
  left: calc(50% - 1px);
  top: 33%;
  transition: all 0.25s;
}

.bio-accordion__button-element--vertical-hidden {
  height: 0px;
  top: 50%;
}

.bio-accordion__button img {
  height: 100%;
}

.bio__content {
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.25s;
}

.bio__content--open {
  max-height: 200vh;
}

.bio-accordion__contact {
  font-family: freight-text-pro, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015625rem;
  font-family: freight-text-pro, serif;
}

.bio-accordion__contact-header {
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  padding-top: 28px;
  padding-bottom: 28px;
  color: #c41230;
}
@media print, screen and (min-width: 64em) {
  .bio-accordion__contact-header {
    font-size: 20px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #c41230;
  }
}

.bio-accordion__links {
  padding-left: 0px;
  padding-top: 24px;
  padding-bottom: 28px;
}

.bio-accordion__link {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  padding-top: 4px;
  padding-bottom: 4px;
}

.bio-accordion__link a {
  color: #c41230;
  text-decoration: underline;
}

.contact-card-group {
  background-color: #faf9f7;
}
.contact-card-group__container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .contact-card-group__container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .contact-card-group__container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .contact-card-group__container {
    width: 90%;
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .contact-card-group__container {
    width: 90%;
    max-width: 1170px;
  }
}
.contact-card-group__contact {
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  left: 0%;
}
@media print, screen and (min-width: 40em) {
  .contact-card-group__contact {
    flex: 0 0 25%;
    max-width: 25%;
    position: relative;
    left: 8.3333333333%;
    margin-right: 21px;
  }
}
.contact-card-group__contact-image-wrapper {
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: 33px;
}
@media print, screen and (min-width: 40em) {
  .contact-card-group__contact-image-wrapper {
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
}
.contact-card-group__contact-name {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
  font-family: proxima-nova, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 27px;
  font-weight: 400;
}
@media print, screen and (min-width: 40em) {
  .contact-card-group__contact-name {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 18px;
  }
}
.contact-card-group__contacts-wrapper {
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  left: 0%;
  margin-bottom: 19px;
}
@media print, screen and (min-width: 40em) {
  .contact-card-group__contacts-wrapper {
    display: flex;
    margin-bottom: 0;
  }
}
.contact-card-group__copy {
  flex: 0 0 75%;
  max-width: 75%;
  font-family: freight-text-pro, serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 350;
  letter-spacing: 0.015625rem;
  margin-bottom: 91px;
}
@media print, screen and (min-width: 40em) {
  .contact-card-group__copy {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 86px;
  }
}
.contact-card-group__header {
  font-family: freight-display-pro, serif;
  font-size: 36px;
  font-weight: 300;
  line-height: 44px;
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
  position: relative;
  left: 0%;
  color: #c41230;
}
@media print, screen and (min-width: 40em) {
  .contact-card-group__header {
    font-size: 48px;
    font-weight: 300;
    line-height: 59px;
  }
}
@media print, screen and (min-width: 40em) {
  .contact-card-group__header {
    position: relative;
    left: 8.3333333333%;
    font-family: proxima-nova, sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
  }
}
.contact-card-group__header-wrapper {
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  left: 0%;
  margin-top: 38px;
  margin-bottom: 62px;
}
@media print, screen and (min-width: 40em) {
  .contact-card-group__header-wrapper {
    margin-top: 53px;
    margin-bottom: 33px;
  }
}
.contact-card-group .contact-info {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.015625rem;
  font-weight: 600;
  margin-bottom: 19px;
}
.contact-card-group .contact-info a:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.contact-card-group .contact-info__department {
  margin-bottom: 6px;
  display: block;
}
.contact-card-group .contact-info__email {
  margin-bottom: 8px;
  display: block;
}
.contact-card-group .contact-info__email:focus, .contact-card-group .contact-info__email:hover {
  text-decoration: underline;
}
.contact-card-group .contact-info__phone {
  font-weight: 400;
  display: block;
}
.contact-card-group .contact-info__phone:focus, .contact-card-group .contact-info__phone:hover {
  text-decoration: underline;
}
@media print, screen and (min-width: 40em) {
  .contact-card-group .contact-info {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.text-red {
  color: #c41230;
}

.category-hero__wrapper {
  position: relative;
  padding-top: 80px;
  overflow: hidden;
  background-image: url("../images/illustrations/illustration-duotone-red.png");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% 95vw;
}
@media print, screen and (min-width: 40em) {
  .category-hero__wrapper {
    background-size: 100% 80vw;
  }
}
@media screen and (min-width: 48em) {
  .category-hero__wrapper {
    background-size: 100% 75vw;
  }
}
@media print, screen and (min-width: 60em) {
  .category-hero__wrapper {
    background-size: 100% 72vw;
  }
}
@media print, screen and (min-width: 64em) {
  .category-hero__wrapper {
    padding-top: 80px;
    padding-bottom: 60px;
    background-position: center top;
    background-size: 100% 55%;
  }
}

.category-hero__side-decoration {
  display: none;
  margin-top: 30px;
  position: absolute;
}
@media print, screen and (min-width: 64em) {
  .category-hero__side-decoration {
    position: relative;
    left: 0%;
    position: absolute;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.category-hero__side-decoration > .category-hero__side-decoration-bar {
  height: 80vh;
  background: #ccc9b8;
  width: 1px;
  display: block;
  margin-top: -22px;
  margin-right: 3px;
}
.category-hero__side-decoration .hero-landing__content-icon {
  top: 100%;
  left: 14px;
}

.category-hero__side-decoration-icon-wrapper {
  display: flex;
  height: 82px;
  fill: #fff;
}

@media print, screen and (min-width: 64em) {
  .category-hero__side-decoration-icon {
    height: auto;
    width: 46px;
  }
}

.category-hero__interior {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  max-width: none;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  padding: 0;
  flex: 1;
  position: relative;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  padding: 0;
}
@media print, screen and (min-width: 40em) {
  .category-hero__interior {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .category-hero__interior {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .category-hero__interior {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .category-hero__interior {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .category-hero__interior {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .category-hero__interior {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .category-hero__interior {
    width: 90%;
    max-width: 1170px;
  }
}

.category-hero__header-wrapper {
  padding: 0 5%;
  flex: 0 0 100%;
  max-width: 100%;
  color: white;
  margin-top: 0;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media print, screen and (min-width: 64em) {
  .category-hero__header-wrapper {
    margin-top: 30px;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
    margin-right: auto;
    margin-left: auto;
  }
  .category-hero__header-wrapper, .category-hero__header-wrapper:last-child:not(:first-child) {
    float: none;
    clear: both;
  }
}
.category-hero__header-wrapper > .category-hero__header-icon-container {
  height: 32px;
  width: 32px;
  margin-right: 10px;
  display: none;
}
.category-hero__header-wrapper .category-hero__description {
  font-size: 20px;
  font-family: freight-text-pro, serif;
  font-weight: 300;
}

.category-hero__article {
  flex: 0 0 100%;
  max-width: 100%;
  color: white;
  background-color: white;
}
@media print, screen and (min-width: 64em) {
  .category-hero__article {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
    margin-right: auto;
    margin-left: auto;
  }
  .category-hero__article, .category-hero__article:last-child:not(:first-child) {
    float: none;
    clear: both;
  }
}

@media print, screen and (min-width: 64em) {
  .category-hero__interior--no-decoration .category-hero__header-wrapper {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media print, screen and (min-width: 64em) {
  .category-hero__interior--no-decoration .category-hero__article {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.category-hero__title {
  font-family: freight-display-pro, serif;
  font-size: 38px;
  line-height: 48px;
  font-weight: 400;
  font-weight: 100;
}
@media print, screen and (min-width: 64em) {
  .category-hero__title {
    font-weight: 300;
    font-size: 44px;
    line-height: 50px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

.category-hero__article-media {
  height: 0;
  position: relative;
  overflow: hidden;
}
.category-hero__article-media .dependency-video__background {
  position: initial;
  top: 0;
  height: 100%;
  width: 100%;
}
.category-hero__article-media .dependency-video__background .dependency-video__video {
  top: 0;
}
.category-hero__article-media .dependency-video__video-button {
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.category-hero__article-media--video {
  padding-top: 56.25%;
}

.category-hero__article-media--figure {
  /*padding-top: calc((3 / 4) * 100%);*/
  padding-top: 55%;
}

.category-hero__lockup-wrapper {
  padding: 20px;
  color: #282828;
}
@media print, screen and (min-width: 40em) {
  .category-hero__lockup-wrapper {
    padding: 17px 23px;
  }
}
.category-hero__lockup-wrapper::after {
  display: block;
  content: "";
  height: 2px;
  background-color: #ccc9b8;
  margin-top: 24px;
}
.category-hero__lockup-wrapper > div {
  margin-top: 16px;
}
@media print, screen and (min-width: 64em) {
  .category-hero__lockup-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}
@media print, screen and (min-width: 64em) {
  .category-hero__lockup-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}
.category-hero__lockup-wrapper .news-lockup__headline {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 15px;
  letter-spacing: 0.86px;
  font-style: normal;
  color: #333;
}
.category-hero__lockup-wrapper .news-lockup__title {
  font-family: proxima-nova, sans-serif;
  font-size: 26px;
  line-height: 30px;
  font-weight: bold;
  color: #c41230;
}
.category-hero__lockup-wrapper .news-lockup__title .news-lockup__title-link {
  text-decoration: none;
}
.category-hero__lockup-wrapper .news-lockup__title .news-lockup__title-link:hover {
  text-decoration: none;
}
.category-hero__lockup-wrapper .news-lockup__title .news-lockup__title-link:hover::after {
  transform: rotate(45deg) translateY(-10px) translateX(7px);
}
.category-hero__lockup-wrapper .news-lockup__title .news-lockup__title-link::after {
  content: "";
  display: inline-block;
  width: 11px;
  height: 11px;
  border-right: 3px solid #c41230;
  border-top: 3px solid #c41230;
  transform: rotate(45deg) translateY(-6px) translateX(3px);
  transition: all 0.2s ease;
}
.category-hero__lockup-wrapper .news-lockup__body {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 24px;
}
.category-hero__lockup-wrapper .news-lockup__byline {
  display: flex;
  align-items: baseline;
}
.category-hero__lockup-wrapper .news-lockup__byline--text {
  color: #7e6d53;
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.86px;
}
.category-hero__lockup-wrapper .news-lockup__byLine--divider {
  color: #7e6d53;
  margin: 0 1ch;
}

.category-hero__article-image {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 100%;
  object-fit: cover;
}

.category-hero__article-figure-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  padding: 24px;
  color: white;
}
.category-hero__article-figure-caption::before {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
  height: calc(100% - 75px);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: auto;
  content: "";
}
@media print, screen and (min-width: 64em) {
  .category-hero__article-figure-caption::before {
    height: 125px;
    top: calc(100% - 75px - 125px);
  }
}
.category-hero__article-figure-caption::after {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 75px;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}
@media print, screen and (min-width: 64em) {
  .category-hero__article-figure-caption::after {
    height: 75px;
  }
}

.category-hero__article-figure-caption--heading {
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
}
@media print, screen and (min-width: 64em) {
  .category-hero__article-figure-caption--heading {
    font-size: 20px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #c41230;
  }
}

.category-hero__article-figure-caption--copy {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.04375rem;
  position: relative;
  font-weight: 300;
}
.category-hero__article-figure-caption--heading + .category-hero__article-figure-caption--copy {
  margin-top: 20px;
}

.category-hero__lockup-byLine-author-text {
  font-weight: 600;
}

/*===== IF NO FEATURED ARTICLE IS ATTACHED =====*/
.no-featured-article {
  background-color: #c41230;
  background-size: cover;
  margin-bottom: 60px;
}
@media print, screen and (min-width: 64em) {
  .no-featured-article {
    padding-top: 60px;
    padding-bottom: 120px;
  }
}
.no-featured-article .category-hero__side-decoration {
  display: none;
}
.no-featured-article .category-hero__header-wrapper {
  flex: 0 0 100%;
  max-width: 100%;
  align-items: flex-start;
}

.no-featured-article-gallery {
  margin-bottom: -100px;
  padding-bottom: 120px;
  padding-top: 60px;
}

.in-news-index {
  background-size: cover;
}
@media print, screen and (min-width: 40em) {
  .in-news-index {
    background-image: linear-gradient(to right, transparent 50%, #ffffff 50%), url("../images/illustrations/illustration-duotone-red.png");
    background-attachment: fixed;
  }
}
.in-news-index__copy {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 24px;
}
@media print, screen and (min-width: 64em) {
  .in-news-index__copy {
    font-family: freight-text-pro, serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 350;
    letter-spacing: 0.015625rem;
  }
}
.in-news-index__header {
  font-family: freight-display-pro, serif;
  font-size: 46px;
  font-weight: 300;
  line-height: 56px;
  padding-top: 1em;
}
@media print, screen and (min-width: 64em) {
  .in-news-index__header {
    font-family: freight-display-pro, serif;
    font-size: 58px;
    font-weight: 300;
    line-height: 71px;
  }
}
.in-news-index__header-container {
  margin: 30px 0;
}
@media print, screen and (min-width: 40em) {
  .in-news-index__header-container {
    margin: 65px 0 32px 0;
  }
}
.in-news-index__search-bar-container {
  height: 60px;
  margin: 50px 0;
}
.in-news-index__search-results-divider {
  height: 2px;
  background-color: #ccc9b8;
  margin: 17px 0;
}
@media print, screen and (min-width: 40em) {
  .in-news-index__search-results-divider {
    margin: 12px 0 50px 0;
  }
}
.in-news-index__search-results-item {
  position: relative;
}
.in-news-index__search-results-item-link {
  /* Remove the overlay preventing links from being clickable */
  /* &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  } */
}
.in-news-index__search-results-item-link:focus .news-lockup__title, .in-news-index__search-results-item-link:hover .news-lockup__title {
  text-decoration: underline;
}
.in-news-index__search-results-nav-container {
  width: 100%;
  margin-top: 56px;
  margin-bottom: 150px;
}
.in-news-index__stats {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 22px;
}
@media print, screen and (min-width: 40em) {
  .in-news-index__stats {
    font-family: proxima-nova, sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 22px;
  }
}
.in-news-index__search-results-stats-container {
  margin: 20px 0 30px 0;
}
@media print, screen and (min-width: 40em) {
  .in-news-index__search-results-stats-container {
    margin: 78px 0 50px 0;
  }
}
.in-news-index__search-container {
  color: #fff;
}
.in-news-index__search-wrapper {
  background: url("../images/illustrations/illustration-duotone-red.png");
}
@media print, screen and (min-width: 40em) {
  .in-news-index__search-wrapper {
    background: none;
    height: 100vh;
    top: 0;
  }
}
@media print, screen and (max-width: 31.24875em) {
  .in-news-index__search-wrapper {
    background: url("../images/illustrations/illustration-duotone-red.png");
  }
}
@media print, screen and (max-width: 31.24875em) {
  .in-news-index__search-container, .in-news-index__search-results-container {
    max-width: 75rem;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-flow: row wrap;
    margin-right: 0.625rem;
    margin-left: 0.625rem;
    flex: 1;
    margin-right: 1.125rem;
    margin-left: 1.125rem;
  }
}
@media print, screen and (max-width: 31.24875em) and (min-width: 40em) {
  .in-news-index__search-container, .in-news-index__search-results-container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (max-width: 31.24875em) and (min-width: 64em) {
  .in-news-index__search-container, .in-news-index__search-results-container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (max-width: 31.24875em) and (min-width: 75em) {
  .in-news-index__search-container, .in-news-index__search-results-container {
    width: 90%;
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (max-width: 31.24875em) and (min-width: 90em) {
  .in-news-index__search-container, .in-news-index__search-results-container {
    width: 90%;
    max-width: 1170px;
  }
}
.in-news-index__search-wrapper, .in-news-index__search-results-wrapper {
  flex: 0 0 100%;
  max-width: 100%;
}
@media print, screen and (min-width: 40em) {
  .in-news-index__search-wrapper, .in-news-index__search-results-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.in-news-index__search-results-wrapper {
  background: #fff;
}
@media print, screen and (min-width: 40em) {
  .in-news-index__search-results-container {
    margin-left: 8.3333333333%;
  }
}
@media print, screen and (max-width: 31.24875em) {
  .in-news-index__header-contanier, .in-news-index__copy-container, .in-news-index__search-bar-container, .in-news-index__search-results-stats-container, .in-news-index__search-results-items-container, .in-news-index__search-results-nav-container {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.in-news-index__search-results-item .news-lockup__headline {
  margin: 0;
}
.in-news-index__search-results-item .news-lockup__title {
  font-family: proxima-nova, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  margin: 10px 0 15px 0;
}
.in-news-index__search-results-item .news-lockup__body {
  margin: 0;
}
.in-news-index__search-results-item .news-lockup__byline {
  margin-top: 5px;
}

@media screen and (max-width: 39.99875em) {
  .in-news-index__search-container,
  .in-news-index__search-results-container {
    max-width: 75rem;
    margin-right: auto;
    margin-left: auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: 1.5625rem;
    margin-left: 1.5625rem;
    -ms-flex: 1;
    flex: 1;
  }
}
@media screen and (max-width: 39.99875em) and (min-width: 23.4375em) {
  .in-news-index__search-container,
  .in-news-index__search-results-container {
    margin-right: 1.5625rem;
    margin-left: 1.5625rem;
  }
}
.in-news-index__search-container,
.in-news-index__search-results-container {
  flex: 0 0 100%;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .in-news-index__search-container, .in-news-index__search-results-container {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
}
@media print, screen and (min-width: 40em) {
  .in-news-index__inner {
    max-width: 75rem;
    margin-right: auto;
    margin-left: auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: 0.125rem;
    margin-left: 0.125rem;
    -ms-flex: 1;
    flex: 1;
  }
}
@media print, screen and (min-width: 40em) and (min-width: 23.4375em) {
  .in-news-index__inner {
    margin-right: 1.5625rem;
    margin-left: 1.5625rem;
  }
}
@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .in-news-index__inner {
    margin-right: 2.1875rem;
    margin-left: 2.1875rem;
  }
}
@media print, screen and (min-width: 40em) and (min-width: 64em) {
  .in-news-index__inner {
    margin-right: 8.4375rem;
    margin-left: 8.4375rem;
  }
}
@media screen and (min-width: 40em) and (min-width: 75em) {
  .in-news-index__inner {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
.in-the-news {
  position: relative;
  margin-top: 60px;
  margin-bottom: 60px;
}
.in-the-news .in-the-news__inner {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0rem;
  margin-left: 0rem;
  flex: 1;
  margin-right: 0rem;
  margin-left: 0rem;
  background-color: #faf9f7;
  padding-bottom: 40px;
}
@media print, screen and (min-width: 40em) {
  .in-the-news .in-the-news__inner {
    margin-right: 0rem;
    margin-left: 0rem;
  }
}
@media print, screen and (min-width: 64em) {
  .in-the-news .in-the-news__inner {
    margin-right: 1.125rem;
    margin-left: 1.125rem;
  }
}
@media screen and (min-width: 75em) {
  .in-the-news .in-the-news__inner {
    max-width: 1164px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .in-the-news .in-the-news__inner {
    width: 90%;
    max-width: 1270px;
  }
}
@media print, screen and (min-width: 40em) {
  .in-the-news .in-the-news__inner {
    padding-bottom: 0;
  }
}
@media print, screen and (min-width: 64em) {
  .in-the-news .in-the-news__inner {
    -webkit-box-shadow: 7.5px 7.5px 0 0 rgb(196, 18, 48);
    -moz-box-shadow: 7.5px 7.5px 0 0 rgb(196, 18, 48);
    box-shadow: 7.5px 7.5px 0 0 rgb(196, 18, 48);
  }
}
.in-the-news .in-the-news__content {
  width: 100%;
}
@media print, screen and (min-width: 64em) {
  .in-the-news .news-card-group__header-wrapper,
  .in-the-news .news-card-group__cards-wrapper {
    margin-right: 3.125rem;
    margin-left: 3.125rem;
  }
}
@media screen and (min-width: 75em) {
  .in-the-news .news-card-group__header-wrapper,
  .in-the-news .news-card-group__cards-wrapper {
    width: 100%;
  }
}
.in-the-news .article-6col__copy-wrapper {
  background-color: #faf9f7;
  border-bottom: 1px solid #ccc9b8;
}
@media print, screen and (min-width: 40em) {
  .in-the-news .article-6col__copy-wrapper {
    border-bottom: none;
  }
}
.in-the-news .article-6col__copy-wrapper::after {
  display: none;
}
.in-the-news .article-6col--odd .article-6col__copy-wrapper {
  padding-bottom: 5px;
}

.load-more-section {
  width: 100%;
  flex: 0 0 100%;
  order: 3;
}
.load-more-section__button-container {
  margin: 0 auto;
}
.load-more-section__button-container:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
  text-decoration: underline;
}
.load-more-section__button-container.expanded {
  display: none;
}
.load-more-section__button-container .base-cta-text::before {
  transform: translateY(-80%) rotate(135deg);
  -webkit-transform: translateY(-80%) rotate(135deg);
  -moz-transform: translateY(-80%) rotate(135deg);
  -ms-transform: translateY(-80%) rotate(135deg);
  -o-transform: translateY(-80%) rotate(135deg);
}
.load-more-section__button-container:focus .base-cta-text::before, .load-more-section__button-container:hover .base-cta-text::before {
  transform: translate(0, -5px) rotate(135deg) scale(1);
  -webkit-transform: translate(0, -5px) rotate(135deg) scale(1);
  -moz-transform: translate(0, -5px) rotate(135deg) scale(1);
  -ms-transform: translate(0, -5px) rotate(135deg) scale(1);
  -o-transform: translate(0, -5px) rotate(135deg) scale(1);
}
.load-more-section__button-wrapper {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .load-more-section__button-wrapper {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .load-more-section__button-wrapper {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .load-more-section__button-wrapper {
    width: 90%;
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .load-more-section__button-wrapper {
    width: 90%;
    max-width: 1170px;
  }
}
.load-more-section__content .category-index-card {
  left: 0;
}
.load-more-section .base-cta-text {
  margin-bottom: 20px;
}

.news-social-share {
  display: flex;
  padding: 25px 10px;
  margin: 0 auto;
  max-width: 300px;
  justify-content: center;
  align-items: center;
}
.news-social-share .socialShareLabel {
  float: left;
  text-transform: uppercase;
  font-weight: 800;
  margin-right: 10px;
  font-size: 12px;
  font-family: proxima-nova, sans-serif;
}
.news-social-share .news-social-share__copy {
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.09375rem;
  text-transform: uppercase;
  color: #c41230;
  margin-right: 35px;
}
.news-social-share .news-social-share__share-icons {
  height: 20px;
  display: flex;
  align-items: center;
}
.news-social-share .news-social-share__share-icons .news-social-share__icon {
  margin-right: 25px;
  width: 20px;
  height: auto;
}
.news-social-share .news-social-share__share-icons .news-social-share__icon:last-of-type {
  margin-right: 0;
}
.news-social-share .news-social-share__share-icons .news-social-share__icon .news-social-share__link > img {
  height: 20px;
  width: auto;
}

a.news-social-share__link {
  display: block;
}
a.news-social-share__link:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}

.news-tags-list {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: #c41230;
  font-size: 22px !important;
  font-weight: 600 !important;
  max-width: unset !important;
  margin-bottom: 40px !important;
  margin: 0 auto;
  flex: 0 0 100%;
  max-width: 100%;
}
@media print, screen and (min-width: 64em) {
  .news-tags-list {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
@media print, screen and (min-width: 40em) {
  .news-tags-list {
    max-width: 776px;
    flex: 0 0 50%;
    max-width: 50%;
    margin-right: auto;
    margin-left: auto;
  }
  .news-tags-list, .news-tags-list:last-child:not(:first-child) {
    float: none;
    clear: both;
  }
}
.news-tags-list .news-tags-list__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.news-tags-list .news-tags-list__container .news-tags-list__tag {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.news-tags-list .news-tags-list__container .news-tags-list__tag::after {
  display: inline-block;
  content: ",";
  margin-left: -0.25ch;
  margin-right: 1ch;
}
.news-tags-list .news-tags-list__container .news-tags-list__tag:first-of-type {
  margin-left: 1ch;
}
.news-tags-list .news-tags-list__container .news-tags-list__tag:last-of-type::after {
  display: none;
}
.news-tags-list .news-tags-list__container .news-tags-list__tag span.news-tags-list__tag-link {
  text-decoration: none;
  background-color: #c41230;
  color: #fff;
  padding: 4px 12px;
  margin: 8px;
  font-weight: 600;
  font-size: 18px;
}
.news-tags-list .news-tags-list__container .news-tags-list__tag a.news-tags-list__tag-link {
  background-color: #c41230;
  color: #fff;
  padding: 4px 12px;
  margin: 8px;
  font-weight: 600;
  font-size: 18px;
}
.news-tags-list .news-tags-list__container .news-tags-list__tag a.news-tags-list__tag-link:hover {
  text-decoration: underline;
}
.news-tags-list .news-tags-list__container .news-tags-list__tag a.news-tags-list__tag-link:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}

.news-post-footer {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media print, screen and (min-width: 40em) {
  .news-post-footer {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .news-post-footer {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .news-post-footer {
    width: 90%;
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .news-post-footer {
    width: 90%;
    max-width: 1170px;
  }
}

.news-post-footer__share-wrapper {
  margin-top: 20px;
}

.news-contact {
  position: relative;
  z-index: 2;
  padding-top: 47px;
  background-color: #edece2;
}
.news-contact__address-line {
  font-family: freight-display-pro, serif;
  font-weight: 350;
  letter-spacing: 0.015625rem;
  font-size: 24px;
  line-height: 36px;
  font-weight: 350px;
}
.news-contact__address-wrapper {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}
@media print, screen and (min-width: 40em) {
  .news-contact__address-wrapper {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    position: relative;
    left: 0%;
    padding-right: 2ch;
  }
}
.news-contact__contacts-wrapper {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
  margin-bottom: 132px;
}
@media print, screen and (min-width: 40em) {
  .news-contact__contacts-wrapper {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    position: relative;
    left: 0%;
    padding-right: 2ch;
  }
}
.news-contact__container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .news-contact__container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .news-contact__container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .news-contact__container {
    width: 90%;
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .news-contact__container {
    width: 90%;
    max-width: 1170px;
  }
}
.news-contact__email {
  display: block;
  font-family: freight-display-pro, serif;
  font-weight: 350;
  letter-spacing: 0.015625rem;
  font-size: 24px;
  line-height: 36px;
}
.news-contact__email:focus, .news-contact__email:hover {
  text-decoration: underline;
}
.news-contact__email:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.news-contact__links-wrapper {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
  margin-bottom: 96px;
}
@media print, screen and (min-width: 40em) {
  .news-contact__links-wrapper {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    position: relative;
    left: 0%;
    padding-right: 2ch;
  }
}
.news-contact__link {
  display: block;
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  font-weight: 550;
  line-height: 20px;
  letter-spacing: 0;
  margin-bottom: 15px;
}
.news-contact__link:focus, .news-contact__link:hover {
  text-decoration: underline;
}
@media print, screen and (min-width: 40em) {
  .news-contact__link {
    line-height: 35px;
    margin-bottom: 0;
  }
}
.news-contact__link:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.news-contact__phone {
  display: block;
  font-family: freight-display-pro, serif;
  font-weight: 350;
  letter-spacing: 0.015625rem;
  font-size: 24px;
  line-height: 36px;
}
@media print, screen and (min-width: 40em) {
  .news-contact__phone {
    margin-bottom: 5px;
  }
}
.news-contact__phone:focus, .news-contact__phone:hover {
  text-decoration: underline;
}
.news-contact__phone:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.news-contact__social-follow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 24px;
  margin-bottom: 31px;
}
@media print, screen and (min-width: 40em) {
  .news-contact__social-follow {
    margin-top: 20px;
  }
}
.news-contact__social-icon-wrapper {
  position: relative;
  height: 0;
  width: 25px;
  height: 25px;
  margin-right: 20px;
}
.news-contact__social-icon-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.news-contact__social-follow-copy {
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.125rem;
  color: #c41230;
}
.news-contact__social-follow-copy:focus, .news-contact__social-follow-copy:hover {
  text-decoration: underline;
}
.news-contact__title {
  font-family: freight-display-pro, serif;
  font-size: 36px;
  font-weight: 300;
  line-height: 44px;
  color: #c41230;
}
@media print, screen and (min-width: 40em) {
  .news-contact__title {
    font-size: 48px;
    font-weight: 300;
    line-height: 59px;
  }
}
.news-contact__title-wrapper {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 28px;
}
@media print, screen and (min-width: 40em) {
  .news-contact__title-wrapper {
    margin-bottom: 24px;
  }
}
.news-contact__title-wrapper ::after {
  display: block;
  content: "";
  height: 1px;
  background-color: #ccc9b8;
  width: 100%;
  margin-top: 18.31px;
}
@media print, screen and (min-width: 40em) {
  .news-contact__title-wrapper ::after {
    margin-top: 12px;
  }
}

.news-card-group {
  z-index: 0;
  position: relative;
  overflow: hidden;
}
.news-card-group--background-white {
  background-color: #fff;
}
.news-card-group--background-white-warm {
  background-color: #faf9f7;
}
.news-card-group--background-white-warm .article-6col__copy-wrapper {
  background-color: #faf9f7;
}
.news-card-group--background-red {
  background-color: #c41230;
}
.news-card-group--background-red .news-category-title-block__icon-wrapper {
  fill: white;
}
.news-card-group--background-red .news-category-title-block__title {
  color: white;
}
.news-card-group--background-red .article-6col__copy-wrapper {
  background-color: #c41230;
  color: white;
}
.news-card-group--background-red .news-lockup__headline,
.news-card-group--background-red .news-lockup__title,
.news-card-group--background-red .news-lockup__byline--text {
  color: white;
}
.news-card-group__cards-wrapper {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  left: 0%;
  justify-content: space-between;
  margin-bottom: 0px;
}
@media print, screen and (min-width: 40em) {
  .news-card-group__cards-wrapper {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .news-card-group__cards-wrapper {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .news-card-group__cards-wrapper {
    width: 90%;
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .news-card-group__cards-wrapper {
    width: 90%;
    max-width: 1170px;
  }
}
@media print, screen and (min-width: 40em) {
  .news-card-group__cards-wrapper {
    margin-bottom: 55px;
  }
}
.news-card-group__cards-wrapper--noheader {
  margin-top: 55px;
}
.news-card-group__header-cta {
  flex: 0 1 auto;
}
@media print, screen and (min-width: 40em) {
  .news-card-group__header-cta {
    display: flex;
  }
  .news-card-group__header-cta .base-cta-text {
    margin-top: auto;
    margin-bottom: -12px;
  }
}
@media print, screen and (min-width: 64em) {
  .news-card-group__header-cta .base-cta-text {
    margin-bottom: 0px;
  }
}
.news-card-group__header-title-block {
  flex: 1;
}
@media print, screen and (max-width: 31.24875em) {
  .news-card-group__header-title-block {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.news-card-group__header-wrapper {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  margin-bottom: 40px;
  margin-top: 40px;
}
@media print, screen and (min-width: 40em) {
  .news-card-group__header-wrapper {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .news-card-group__header-wrapper {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .news-card-group__header-wrapper {
    width: 90%;
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .news-card-group__header-wrapper {
    width: 90%;
    max-width: 1170px;
  }
}
@media print, screen and (min-width: 40em) {
  .news-card-group .text-padded {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media print, screen and (min-width: 40em) {
  .news-hero {
    padding-top: 72px;
    background-image: url("../images/illustration-duotone-red.png");
    background-repeat: no-repeat;
    background-size: 100% 75%;
  }
}
.news-hero__background-image-wrapper {
  display: none;
}
.news-hero__hero-copy-wrapper {
  position: relative;
  background-color: #fff;
}
@media print, screen and (min-width: 40em) {
  .news-hero__hero-copy-wrapper {
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .news-hero__hero-copy-wrapper:after {
    position: absolute;
    bottom: 0;
    height: 1px;
    display: block;
    background-color: #ccc9b8;
    width: 100%;
    content: "";
  }
}
.news-hero__hero-copy-container .news-lockup__title {
  font-family: proxima-nova, sans-serif;
  font-weight: 800;
  font-size: 32px;
  line-height: 36px;
}
@media print, screen and (min-width: 40em) {
  .news-hero__hero-copy-container .news-lockup__title {
    font-family: proxima-nova, sans-serif;
    font-weight: 800;
    font-size: 36px;
    line-height: 40px;
  }
}
@media print, screen and (max-width: 31.24875em) {
  .news-hero__hero-copy-container {
    max-width: 75rem;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-flow: row wrap;
    margin-right: 0.625rem;
    margin-left: 0.625rem;
    flex: 1;
    margin-right: 1.125rem;
    margin-left: 1.125rem;
  }
}
@media print, screen and (max-width: 31.24875em) and (min-width: 40em) {
  .news-hero__hero-copy-container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (max-width: 31.24875em) and (min-width: 64em) {
  .news-hero__hero-copy-container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (max-width: 31.24875em) and (min-width: 75em) {
  .news-hero__hero-copy-container {
    width: 90%;
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (max-width: 31.24875em) and (min-width: 90em) {
  .news-hero__hero-copy-container {
    width: 90%;
    max-width: 1170px;
  }
}
@media print, screen and (min-width: 40em) {
  .news-hero__hero-copy-container {
    background-color: #fff;
    margin: 36px 39px 40px 30px;
    display: flex;
    flex-flow: column wrap;
  }
  .news-hero__hero-copy-container .news-lockup__headline {
    order: 0;
  }
  .news-hero__hero-copy-container .news-lockup__title {
    order: 0;
    margin-bottom: 24px;
  }
  .news-hero__hero-copy-container .news-lockup__body {
    order: 2;
  }
}
.news-hero__hero-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media print, screen and (min-width: 40em) {
  .news-hero__hero-image-container {
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
}
.news-hero__hero-image-wrapper {
  position: relative;
  padding-top: 66.6666666667%;
  height: 0;
  margin-bottom: 23px;
}
@media print, screen and (min-width: 40em) {
  .news-hero__hero-image-wrapper {
    height: 100%;
  }
}
@media print, screen and (min-width: 40em) {
  .news-hero__interior {
    max-width: 75rem;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-flow: row wrap;
    margin-right: 0.625rem;
    margin-left: 0.625rem;
    flex: 1;
    margin-right: 1.125rem;
    margin-left: 1.125rem;
  }
}
@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .news-hero__interior {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 40em) and (min-width: 64em) {
  .news-hero__interior {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 40em) and (min-width: 75em) {
  .news-hero__interior {
    width: 90%;
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 40em) and (min-width: 90em) {
  .news-hero__interior {
    width: 90%;
    max-width: 1170px;
  }
}

@media print, screen and (min-width: 40em) {
  .news-hero__cards-wrapper .article-6col__card-container .news-lockup__headline {
    margin-bottom: 8px !important;
  }
}
@media print, screen and (min-width: 40em) {
  .news-hero__cards-wrapper .article-6col__card-container .news-lockup__title {
    margin-bottom: 8px;
    font-family: proxima-nova, sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
  }
}
@media print, screen and (min-width: 40em) {
  .news-hero__cards-wrapper .article-6col__card-container {
    position: relative;
    margin-bottom: 0;
  }
  .news-hero__cards-wrapper .article-6col__card-container:after {
    position: absolute;
    bottom: 0;
    height: 1px;
    display: block;
    background-color: #ccc9b8;
    width: 100%;
    content: "";
  }
}

@media print, screen and (min-width: 40em) {
  .news-hero__hero-copy-container > .news-lockup__byline {
    order: 1;
    margin-bottom: 24px;
  }
}

.news-hero :focus .news-lockup__title,
.news-hero :active .news-lockup__title {
  text-decoration: underline;
}

.news-category-group {
  background-image: linear-gradient(to bottom, #fff, #fff 10%, #c41230 10%);
}
@media print, screen and (min-width: 40em) {
  .news-category-group {
    background-image: linear-gradient(to bottom, #fff, #fff 25%, #c41230 25%);
  }
}
.news-category-group .news-category-group__image-overlay {
  background: linear-gradient(to top, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.news-category-group .news-category-group__image-overlay--hero {
  display: none;
}
@media print, screen and (min-width: 40em) {
  .news-category-group .news-category-group__image-overlay--hero {
    display: block;
  }
}
.news-category-group__cta {
  margin-left: auto;
  margin-bottom: 24px;
  margin-top: -25px;
}
.news-category-group__cta .base-cta-text {
  margin-top: 14px;
}
@media print, screen and (min-width: 40em) {
  .news-category-group__cta {
    width: 100%;
    justify-content: flex-end;
    max-width: 75rem;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-flow: row wrap;
    margin-right: 0.625rem;
    margin-left: 0.625rem;
    flex: 1;
    margin-right: 1.125rem;
    margin-left: 1.125rem;
    flex: 1 1 auto;
  }
}
@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .news-category-group__cta {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 40em) and (min-width: 64em) {
  .news-category-group__cta {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 40em) and (min-width: 75em) {
  .news-category-group__cta {
    width: 90%;
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 40em) and (min-width: 90em) {
  .news-category-group__cta {
    width: 90%;
    max-width: 1170px;
  }
}
@media print, screen and (min-width: 40em) {
  .news-category-group__cta .base-cta-text {
    margin-top: 24px;
    float: right;
  }
}
.news-category-group__feature-story-block {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  flex-wrap: column wrap;
  width: 100%;
}
@media print, screen and (min-width: 40em) {
  .news-category-group__feature-story-block {
    flex: 0 0 26.2%;
    margin-right: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .news-category-group__feature-story-block {
    margin-right: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .news-category-group__feature-story-block {
    margin-right: calc((100vw - 1064px) / 2);
  }
}
@media screen and (min-width: 90em) {
  .news-category-group__feature-story-block {
    margin-right: calc((100% - 1170px) / 2);
  }
}
.news-category-group__feature-story-caption {
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  left: 8.3333333333%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  padding: 27px 25px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.news-category-group__feature-story-caption-copy {
  margin-top: 8px;
  font-family: proxima-nova, sans-serif;
  font-style: italic;
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  font-style: normal;
}
.news-category-group__feature-story-caption-copy:focus-within {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}
.news-category-group__feature-story-caption-copy a:hover,
.news-category-group__feature-story-caption-copy a:focus {
  text-decoration: underline;
  outline: 0;
}
.news-category-group__feature-story-caption-heading {
  font-family: proxima-nova, sans-serif;
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.03125rem;
  padding: 5px 10px;
  background-color: #c41230;
}
.news-category-group__feature-story-caption-heading:focus-within {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}
.news-category-group__feature-story-caption-heading a:hover,
.news-category-group__feature-story-caption-heading a:focus {
  text-decoration: underline;
  outline: 0;
}
.news-category-group__feature-story-figure {
  width: 100%;
  position: relative;
  margin-bottom: 23px;
}
@media print, screen and (min-width: 40em) {
  .news-category-group__feature-story-figure {
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
  }
}
.news-category-group__header {
  font-family: freight-display-pro, serif;
  font-size: 38px;
  line-height: 48px;
  font-weight: 400;
  font-weight: 800;
  color: #c41230;
}
@media print, screen and (min-width: 64em) {
  .news-category-group__header {
    font-weight: 300;
    font-size: 44px;
    line-height: 50px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
@media print, screen and (min-width: 40em) {
  .news-category-group__header-wrapper {
    width: 100%;
    margin-right: 2.375rem;
    margin-left: 2.375rem;
    margin-bottom: 30px;
  }
}
@media print, screen and (min-width: 64em) {
  .news-category-group__header-wrapper {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .news-category-group__header-wrapper {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .news-category-group__header-wrapper {
    width: 90%;
    max-width: 1170px;
  }
}
@media print, screen and (min-width: 40em) {
  .news-category-group__hero-block {
    flex: 0 0 55.5%;
    max-width: 55.5%;
    position: relative;
    height: 75%;
  }
}
.news-category-group__hero-caption-block {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  background-color: #c41230;
  padding-top: 11px;
}
@media print, screen and (min-width: 40em) {
  .news-category-group__hero-caption-block {
    background-color: transparent;
    max-width: 75rem;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-flow: row wrap;
    position: absolute;
    bottom: 0;
  }
}
.news-category-group__hero-copy-container {
  flex: 1;
  padding-left: 8px;
  margin-bottom: 46px;
}
@media print, screen and (min-width: 40em) {
  .news-category-group__hero-copy-container {
    flex: 1;
    padding-left: 0;
    transform: translateX(-8.3333333333%);
    -webkit-transform: translateX(-8.3333333333%);
    -moz-transform: translateX(-8.3333333333%);
    -ms-transform: translateX(-8.3333333333%);
    -o-transform: translateX(-8.3333333333%);
  }
}
.news-category-group__hero-copy {
  font-family: freight-display-pro, serif;
  font-weight: 350;
  letter-spacing: 0.015625rem;
  font-size: 24px;
  line-height: 36px;
  color: #fff;
}
.news-category-group__hero-image {
  object-fit: cover;
}
.news-category-group__hero-title {
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #fff;
  font-size: 36px;
  line-height: 42px;
}
.news-category-group__hero-title:focus-within {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}
.news-category-group__hero-title a:hover,
.news-category-group__hero-title a:focus {
  text-decoration: underline;
  outline: 0;
}
@media print, screen and (min-width: 40em) {
  .news-category-group__hero-title {
    font-size: 48px;
    line-height: 58px;
  }
}
@media print, screen and (min-width: 40em) {
  .news-category-group__hero-title {
    margin-bottom: 11px;
  }
}
@media print, screen and (max-width: 31.24875em) {
  .news-category-group__inner {
    max-width: 75rem;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-flow: row wrap;
    margin-right: 0.625rem;
    margin-left: 0.625rem;
    flex: 1;
    margin-right: 1.125rem;
    margin-left: 1.125rem;
  }
}
@media print, screen and (max-width: 31.24875em) and (min-width: 40em) {
  .news-category-group__inner {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (max-width: 31.24875em) and (min-width: 64em) {
  .news-category-group__inner {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (max-width: 31.24875em) and (min-width: 75em) {
  .news-category-group__inner {
    width: 90%;
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (max-width: 31.24875em) and (min-width: 90em) {
  .news-category-group__inner {
    width: 90%;
    max-width: 1170px;
  }
}
@media print, screen and (min-width: 40em) {
  .news-category-group__inner {
    max-width: 75rem;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-flow: row wrap;
    font-family: freight-display-pro, serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
    max-width: unset;
  }
}
@media screen and (min-width: 90em) {
  .news-category-group__inner {
    max-width: 1440px;
    margin: 0 auto;
  }
}
.news-category-group__middle-decoration {
  flex: 0 0 9%;
  max-width: 9%;
}
@media print, screen and (min-width: 40em) {
  .news-category-group__middle-decoration {
    margin-top: 10%;
    flex: 1 1;
    max-width: unset;
  }
}
.news-category-group__middle-decoration-bar {
  width: 1px;
  background-color: white;
  height: 100%;
  margin: 0 auto;
}
.news-category-group__side-decoration {
  flex: 0 0 12.5%;
  max-width: 12.5%;
  display: flex;
  margin-right: 8px;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
}
@media print, screen and (max-width: 31.24875em) {
  .news-category-group__side-decoration {
    position: relative;
    left: 8.3333333333%;
  }
}
@media print, screen and (min-width: 40em) {
  .news-category-group__side-decoration {
    margin-left: 2.375rem;
    margin-right: 30px;
  }
}
@media print, screen and (min-width: 64em) {
  .news-category-group__side-decoration {
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .news-category-group__side-decoration {
    margin-left: calc((100vw - 1064px) / 2);
  }
}
@media screen and (min-width: 90em) {
  .news-category-group__side-decoration {
    margin-left: 135px;
  }
}
.news-category-group__side-decoration-bar {
  width: 1px;
  background-color: #fff;
  height: 100%;
}
@media print, screen and (min-width: 40em) {
  .news-category-group__side-decoration-bar {
    height: 100%;
    margin-bottom: -122px;
  }
}
.news-category-group__side-decoration-icon {
  fill: #fff;
}
.news-category-group__side-decoration-icon-wrapper {
  width: 100%;
  max-width: 46px;
}

.news-category-spotlight {
  padding-top: 32px;
  padding-bottom: 32px;
  padding-top: 32px;
  padding-bottom: 32px;
  background-image: unset;
  position: relative;
  background-color: #fff;
}
@media print, screen and (min-width: 40em) {
  .news-category-spotlight {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}
@media print, screen and (min-width: 64em) {
  .news-category-spotlight {
    padding-top: 72px;
    padding-bottom: 72px;
  }
}
@media screen and (min-width: 75em) {
  .news-category-spotlight {
    padding-top: 88px;
    padding-bottom: 88px;
  }
}
@media screen and (min-width: 90em) {
  .news-category-spotlight {
    padding-top: 88px;
    padding-bottom: 88px;
  }
}
.news-category-spotlight__header {
  color: #c41230;
}
.news-category-spotlight__feature-story-block {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media print, screen and (min-width: 40em) {
  .news-category-spotlight__feature-story-block {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .news-category-spotlight__feature-story-block {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .news-category-spotlight__feature-story-block {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .news-category-spotlight__feature-story-block {
    width: 90%;
    max-width: 1404px;
  }
}
.news-category-spotlight__feature-story-block::after {
  content: "";
  position: absolute;
  bottom: 5%;
  left: 0;
  background-color: #CCCAAB;
  width: 100%;
  z-index: 0;
  height: 35%;
}
.news-category-spotlight__feature-story-block .news-category-spotlight__feature-story-figure {
  flex-basis: 100%;
  margin: 16px auto;
  z-index: 1;
}
.news-category-spotlight__feature-story-block .news-category-spotlight__feature-story-figure img {
  object-fit: cover;
}
.news-category-spotlight__feature-story-block .news-category-spotlight__feature-story-figure .news-category-spotlight__feature-story-caption {
  background: linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, 0.8) 80%);
  padding: 10px 25px;
  position: absolute;
  bottom: 0;
  width: 100%;
  color: #fff;
  font-family: proxima-nova, sans-serif;
  min-height: 110px;
}
.news-category-spotlight__feature-story-block .news-category-spotlight__feature-story-figure .news-category-spotlight__feature-story-caption .news-category-spotlight__feature-story-caption-copy {
  padding: 6px 12px;
  background-color: #c41230;
  font-size: 20px;
  display: inline-block;
  font-weight: 600;
}
.news-category-spotlight__feature-story-block .news-category-spotlight__feature-story-figure .news-category-spotlight__feature-story-caption .news-category-spotlight__feature-story-caption-copy a:focus {
  outline: dotted 2px #fff;
  outline-offset: 2px;
}
.news-category-spotlight__feature-story-block .news-category-spotlight__feature-story-figure .news-category-spotlight__feature-story-caption .news-category-spotlight__feature-story-caption-heading {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  padding: 6px;
  background-color: unset !important;
}
@media print, screen and (min-width: 60em) {
  .news-category-spotlight__feature-story-block .news-category-spotlight__feature-story-figure {
    flex-basis: 32%;
  }
}

.news-video-article__link--card-link {
  position: relative;
}
.news-video-article__lockup-block {
  padding: 0 20px;
}
@media print, screen and (min-width: 64em) {
  .news-video-article__lockup-block {
    padding: 0 20px 30px 20px;
  }
}
.news-video-article__lockup-block .news-lockup__headline,
.news-video-article__lockup-block .news-lockup__title,
.news-video-article__lockup-block .news-lockup__body,
.news-video-article__lockup-block .news-lockup__byline {
  margin-bottom: 10px;
}
.news-video-article__lockup-block .base-cta-text {
  margin-bottom: 20px;
}
.news-video-article__lockup-block .news-lockup__headline {
  font-family: proxima-nova, sans-serif;
  font-style: italic;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.0625rem;
  color: #7e6d53 !important;
  font-style: normal !important;
}
.news-video-article__lockup-block .news-lockup__headline:focus-within {
  outline: unset;
  outline-offset: unset;
}
.news-video-article__lockup-block .news-lockup__headline a {
  color: #7e6d53 !important;
}
.news-video-article__lockup-block .news-lockup__headline a:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.news-video-article__lockup-block .news-lockup__headline-spotlight {
  font-family: proxima-nova, sans-serif;
  font-style: italic;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.0625rem;
  color: #7e6d53 !important;
  font-style: normal !important;
}
.news-video-article__lockup-block .news-lockup__headline-spotlight a {
  color: #7e6d53 !important;
}
.news-video-article__lockup-block .news-lockup__headline-spotlight a:hover {
  text-decoration: underline;
}
.news-video-article__lockup-block .title-desc {
  font-weight: 800;
}
.news-video-article__lockup-block .news-lockup__title {
  font-family: proxima-nova, sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}
.news-video-article__lockup-block .news-lockup__title a {
  color: #c41230;
  font-style: normal;
  text-decoration: none;
}
.news-video-article__lockup-block .news-lockup__title a:hover {
  text-decoration: none;
}
.news-video-article__lockup-block .news-lockup__title a:hover::after {
  transform: translateY(-16px) rotate(45deg) translateX(20px);
}
.news-video-article__lockup-block .news-lockup__title a::after {
  align-self: flex-end;
  background-color: transparent;
  border-right: 2px solid #c41230;
  border-top: 2px solid #c41230;
  content: "";
  display: inline-block;
  height: 9px;
  margin-right: -24px;
  transform: translateY(-100%) rotate(45deg) translateX(10px);
  transition: all 0.2s ease;
  width: 9px;
}
.news-video-article__video-block {
  margin-bottom: 16px;
  display: block;
}
.news-video-article__video-block:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.news-video-article__video-block .dependency-video__video-button {
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.news-video-article__video-image {
  object-fit: cover;
}
.news-video-article__video-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 25%;
  fill: white;
}

.news-video-article__link--card-link .news-lockup__body,
.news-video-article__link--card-link .news-lockup__body-link {
  position: relative;
  z-index: 99;
}

.news-video-article__title--card-link a::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.news-video-card-group {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .news-video-card-group {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .news-video-card-group {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .news-video-card-group {
    width: 90%;
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .news-video-card-group {
    width: 90%;
    max-width: 1170px;
  }
}
.news-video-card-group__card {
  margin-right: 1.2%;
  margin-bottom: 20px;
  flex: 0 0 100%;
  max-width: 100%;
}
@media print, screen and (min-width: 40em) {
  .news-video-card-group__card {
    flex: 0 0 32.0833333333%;
    max-width: 32.0833333333%;
  }
}
.news-video-card-group__card:after {
  margin-top: 30px;
  margin-bottom: 34px;
  height: 2px;
  display: block;
  background-color: #ccc9b8;
  width: 100%;
  content: "";
}
@media print, screen and (min-width: 40em) {
  .news-video-card-group__card:after {
    display: none;
  }
}
.news-video-card-group__cards-container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  max-width: unset;
  width: 100%;
}
@media print, screen and (min-width: 40em) {
  .news-video-card-group__cards-container {
    margin-bottom: 45px;
    justify-content: flex-start;
  }
}
.news-video-card-group__title-block-container {
  width: 100%;
}
@media print, screen and (min-width: 40em) {
  .news-video-card-group__title-block-container {
    margin-top: 28px;
    margin-bottom: 28px;
  }
}

.news-video-group {
  position: relative;
  margin-top: 40px;
  margin-bottom: 40px;
}
@media print, screen and (min-width: 64em) {
  .news-video-group::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 55%;
    height: 300px;
    background-color: #faf9f7;
    z-index: -1;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .news-video-group::after {
    display: none;
  }
}
.news-video-group a:focus .news-lockup__title, .news-video-group a:hover .news-lockup__title {
  text-decoration: underline;
}

.news-video-group__inner {
  position: relative;
}
@media print, screen and (min-width: 64em) {
  .news-video-group__inner {
    max-width: 75rem;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-flow: row wrap;
    margin-right: 0.625rem;
    margin-left: 0.625rem;
    flex: 1;
    margin-right: 1.125rem;
    margin-left: 1.125rem;
    flex: 0 0 100%;
    max-width: 100%;
    border-left: 1px solid #c41230;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .news-video-group__inner {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .news-video-group__inner {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 75em) {
  .news-video-group__inner {
    width: 90%;
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 64em) and (min-width: 90em) {
  .news-video-group__inner {
    width: 90%;
    max-width: 1170px;
  }
}

.news-video-group__featured {
  position: relative;
  margin-bottom: 40px;
}
@media print, screen and (min-width: 64em) {
  .news-video-group__featured {
    width: 50%;
    margin-bottom: 0px;
  }
}
.news-video-group__featured::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0.625rem;
  width: 24px;
  height: 24px;
  background-image: url("../svgs/news/icon-play-ornament.svg");
  background-repeat: no-repeat;
  background-size: contain;
  left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .news-video-group__featured::before {
    left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .news-video-group__featured::before {
    left: -24px;
    width: 45px;
    height: 45px;
  }
}
.news-video-group__featured::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 200px;
  background-color: #faf9f7;
  z-index: -1;
}
@media print, screen and (min-width: 64em) {
  .news-video-group__featured::after {
    display: none;
  }
}

.news-video-group__featured-wrapper {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .news-video-group__featured-wrapper {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .news-video-group__featured-wrapper {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .news-video-group__featured-wrapper {
    width: 90%;
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .news-video-group__featured-wrapper {
    width: 90%;
    max-width: 1170px;
  }
}
.news-video-group__featured-wrapper .news-lockup__title {
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
}
.news-video-group__featured-wrapper .news-lockup__title:focus-within {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.news-video-group__featured-wrapper .news-lockup__title:focus {
  outline: 0;
}
@media print, screen and (min-width: 64em) {
  .news-video-group__featured-wrapper {
    margin-right: 0px;
    margin-left: 48px;
    margin-top: 8px;
  }
  .news-video-group__featured-wrapper .news-lockup__title {
    font-family: proxima-nova, sans-serif;
    font-weight: 800;
    font-size: 32px;
    line-height: 36px;
  }
}
@media screen and (min-width: 75em) {
  .news-video-group__featured-wrapper .news-lockup__title {
    font-family: proxima-nova, sans-serif;
    font-weight: 800;
    font-size: 36px;
    line-height: 40px;
  }
}

.news-video-group__featured-header {
  font-family: proxima-nova, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 8px;
  margin-left: 28px;
}
@media print, screen and (min-width: 64em) {
  .news-video-group__featured-header {
    margin-left: 0px;
    margin-bottom: 40px;
  }
}

.news-video-group__featured-thumbnail {
  margin-bottom: 24px;
  position: relative;
}
.news-video-group__featured-thumbnail:hover, .news-video-group__featured-thumbnail:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.news-video-group__featured-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-box-shadow: 7.5px 7.5px 0 0 rgb(196, 18, 48);
  -moz-box-shadow: 7.5px 7.5px 0 0 rgb(196, 18, 48);
  box-shadow: 7.5px 7.5px 0 0 rgb(196, 18, 48);
}
.news-video-group__featured-thumbnail .news-video-group__video-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25%;
  fill: white;
}

@media print, screen and (min-width: 64em) {
  .news-video-group__more-videos {
    width: 50%;
    padding-left: 76px;
    padding-top: 76px;
  }
  .news-video-group__more-videos .news-video-card-group {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  .news-video-group__more-videos .news-video-card-group__cards-container {
    flex-flow: column nowrap;
    margin-bottom: 0px;
    width: 100%;
  }
  .news-video-group__more-videos .news-video-card-group__card {
    max-width: 100%;
    margin-bottom: 2em;
  }
  .news-video-group__more-videos .news-video-article {
    border-bottom: 1px solid #ccc9b8;
    margin-bottom: 24px;
  }
  .news-video-group__more-videos .news-video-card-group__flex-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .news-video-group__more-videos .news-video-article__container {
    width: 100%;
  }
  .news-video-group__more-videos .news-video-article__lockup-block {
    padding-left: 24px;
  }
  .news-video-group__more-videos .news-video-article__lockup-block .news-lockup__title {
    font-family: proxima-nova, sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 22px;
  }
}
@media screen and (min-width: 75em) {
  .news-video-group__more-videos {
    padding-left: 148px;
  }
}

.news-video-group__cta {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  justify-content: flex-end;
}
@media print, screen and (min-width: 40em) {
  .news-video-group__cta {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .news-video-group__cta {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .news-video-group__cta {
    width: 90%;
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .news-video-group__cta {
    width: 90%;
    max-width: 1170px;
  }
}
@media print, screen and (min-width: 64em) {
  .news-video-group__cta {
    margin-right: 0px;
  }
}
.news-video-group__cta .base-cta-text {
  margin-top: 0px;
}

.news-video-index__checkbox:checked {
  display: none;
}
.news-video-index__content-wrapper {
  margin-top: 50px;
}
@media print, screen and (min-width: 40em) {
  .news-video-index__content-wrapper {
    margin-top: 100px;
  }
}
.news-video-index__copy {
  display: none;
}
@media print, screen and (min-width: 40em) {
  .news-video-index__copy {
    display: block;
    font-family: proxima-nova, sans-serif;
    font-size: 16px;
    line-height: 24px;
  }
}
.news-video-index__copy-container {
  flex: 0 0 50%;
  max-width: 50%;
}
.news-video-index__copy-wrapper {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .news-video-index__copy-wrapper {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .news-video-index__copy-wrapper {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .news-video-index__copy-wrapper {
    width: 90%;
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .news-video-index__copy-wrapper {
    width: 90%;
    max-width: 1170px;
  }
}
@media print, screen and (min-width: 40em) {
  .news-video-index__copy-wrapper {
    margin-top: 80px;
  }
}
.news-video-index__header {
  font-family: freight-display-pro, serif;
  font-size: 46px;
  font-weight: 300;
  line-height: 56px;
}
@media print, screen and (min-width: 40em) {
  .news-video-index__header {
    font-family: freight-display-pro, serif;
    font-size: 58px;
    font-weight: 300;
    line-height: 71px;
  }
}
.news-video-index__header-container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  color: #fff;
}
@media print, screen and (min-width: 40em) {
  .news-video-index__header-container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .news-video-index__header-container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .news-video-index__header-container {
    width: 90%;
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .news-video-index__header-container {
    width: 90%;
    max-width: 1170px;
  }
}
.news-video-index__header-wrapper {
  background-image: url("../images/illustration-duotone-red.png");
  padding: 30px 0;
}
.news-video-index__show-more-button {
  display: flex;
  justify-content: center;
}
.news-video-index__show-more-button .base-cta-text {
  margin: 0 auto 50px auto;
}
.news-video-index__show-more-button.expanded .base-cta-text {
  display: none;
}
.news-video-index__show-more-content {
  width: 100%;
}
.news-video-index__show-more-button .base-cta-text::before {
  transform: translateY(-80%) rotate(135deg);
  -webkit-transform: translateY(-80%) rotate(135deg);
  -moz-transform: translateY(-80%) rotate(135deg);
  -ms-transform: translateY(-80%) rotate(135deg);
  -o-transform: translateY(-80%) rotate(135deg);
}
.news-video-index__show-more-button:focus .base-cta-text::before, .news-video-index__show-more-button:hover .base-cta-text::before {
  transform: translate(0, -5px) rotate(135deg) scale(1);
  -webkit-transform: translate(0, -5px) rotate(135deg) scale(1);
  -moz-transform: translate(0, -5px) rotate(135deg) scale(1);
  -ms-transform: translate(0, -5px) rotate(135deg) scale(1);
  -o-transform: translate(0, -5px) rotate(135deg) scale(1);
}

#news-video-index__show-more-check-1:checked #news-video-index__show-more-button-1 {
  display: none;
}

.news-video-index__show-more-button:focus span {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
  text-decoration: underline;
}

@media print, screen and (min-width: 40em) {
  .news-podcast-group {
    overflow: hidden;
  }
}
.news-podcast-group__bar {
  height: 100%;
  width: 1px;
  background-color: #fff;
}
.news-podcast-group__blog-container {
  background-color: #c41230;
  overflow: hidden;
}
@media print, screen and (min-width: 40em) {
  .news-podcast-group__blog-container {
    overflow: unset;
    -webkit-box-shadow: -50px 0px 0px 0px #c41230;
    -moz-box-shadow: -50px 0px 0px 0px #c41230;
    box-shadow: -50px 0px 0px 0px #c41230;
  }
}
.news-podcast-group__podcast-item, .news-podcast-group__blog-item {
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  flex: 1 1 100%;
}
@media print, screen and (max-width: 31.24875em) {
  .news-podcast-group__blog-items-cta {
    flex: 0 1 auto;
    margin-left: auto;
  }
}
.news-podcast-group__podcast-container, .news-podcast-group__blog-container {
  flex: 0 0 50%;
  max-width: 50%;
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  max-width: unset;
}
.news-podcast-group__podcast-items-cta .base-cta-text, .news-podcast-group__blog-items-cta .base-cta-text {
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 45px;
}
.news-podcast-group__podcast-items-title, .news-podcast-group__blog-items-title {
  margin-bottom: 25px;
  margin-top: 35px;
}
@media print, screen and (min-width: 40em) {
  .news-podcast-group__blog-items-container {
    flex: 0 0 67%;
    margin-right: auto;
    margin-left: auto;
  }
  .news-podcast-group__blog-items-container, .news-podcast-group__blog-items-container:last-child:not(:first-child) {
    float: none;
    clear: both;
  }
}
@media print, screen and (max-width: 31.24875em) {
  .news-podcast-group__podcast-items-container, .news-podcast-group__blog-items-container {
    max-width: 75rem;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-flow: row wrap;
    margin-right: 0.625rem;
    margin-left: 0.625rem;
    flex: 1;
    margin-right: 1.125rem;
    margin-left: 1.125rem;
  }
}
@media print, screen and (max-width: 31.24875em) and (min-width: 40em) {
  .news-podcast-group__podcast-items-container, .news-podcast-group__blog-items-container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (max-width: 31.24875em) and (min-width: 64em) {
  .news-podcast-group__podcast-items-container, .news-podcast-group__blog-items-container {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (max-width: 31.24875em) and (min-width: 75em) {
  .news-podcast-group__podcast-items-container, .news-podcast-group__blog-items-container {
    width: 90%;
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (max-width: 31.24875em) and (min-width: 90em) {
  .news-podcast-group__podcast-items-container, .news-podcast-group__blog-items-container {
    width: 90%;
    max-width: 1170px;
  }
}
@media print, screen and (min-width: 40em) {
  .news-podcast-group__podcast-items-container, .news-podcast-group__blog-items-container {
    display: flex;
    flex-flow: column;
  }
}
.news-podcast-group__icon-container {
  height: 72px;
  width: 33px;
  transform: translateX(-50%);
  margin-top: 35px;
}
@media print, screen and (min-width: 40em) {
  .news-podcast-group__icon-container {
    display: flex;
  }
}
@media print, screen and (min-width: 40em) {
  .news-podcast-group__inner {
    max-width: 75rem;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-flow: row wrap;
    margin-right: 0.625rem;
    margin-left: 0.625rem;
    flex: 1;
    margin-right: 1.125rem;
    margin-left: 1.125rem;
  }
}
@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .news-podcast-group__inner {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 40em) and (min-width: 64em) {
  .news-podcast-group__inner {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 40em) and (min-width: 75em) {
  .news-podcast-group__inner {
    width: 90%;
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 40em) and (min-width: 90em) {
  .news-podcast-group__inner {
    width: 90%;
    max-width: 1170px;
  }
}
.news-podcast-group__item-divider {
  content: "";
  width: 100%;
  height: 1px;
  background: white;
  display: block;
  margin: 25px 0;
}
.news-podcast-group__podcast-container {
  background: #faf9f7;
}
@media print, screen and (min-width: 40em) {
  .news-podcast-group__podcast-container {
    overflow: unset;
    -webkit-box-shadow: 50px 0px 0px 0px #faf9f7;
    -moz-box-shadow: 50px 0px 0px 0px #faf9f7;
    box-shadow: 50px 0px 0px 0px #faf9f7;
  }
}
.news-podcast-group__podcast-item .news-podcast-group__item-divider {
  background: #ccc9b8;
}
@media print, screen and (min-width: 40em) {
  .news-podcast-group__podcast-items-container {
    flex: 0 0 84%;
    margin-left: auto;
  }
}
.news-podcast-group__side-deocration {
  display: none;
  height: 100%;
}
@media print, screen and (min-width: 40em) {
  .news-podcast-group__side-deocration {
    display: block;
  }
}

.news-podcast-group__blog-container a:focus .blog-lockup-item__item-container {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}

.focus-white:focus-within, .focus-white:focus, .focus-white:hover {
  text-decoration: underline;
}
.focus-white:focus-within {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}

svg.news-podcast-group__icon {
  height: auto;
  width: 32px;
}

.news-category-index {
  flex: 0 0 100%;
  max-width: 100%;
}
@media print, screen and (min-width: 40em) {
  .news-category-index {
    flex: 0 0 58%;
    max-width: 58%;
  }
}
.news-category-index__item-divider {
  height: 2px;
  width: 100%;
  background: #edece2;
  margin: 25px 0;
}
@media print, screen and (min-width: 40em) {
  .news-category-index__item-divider {
    margin: 30px 0;
  }
}

.load-more-section__content .news-category-index {
  flex-flow: column wrap;
}
@media print, screen and (max-width: 31.24875em) {
  .load-more-section__content .category-index-card,
  .load-more-section__content .news-category-index__item-divider {
    flex: 0 0 100%;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  .load-more-section__content .category-index-card, .load-more-section__content .category-index-card:last-child:not(:first-child),
  .load-more-section__content .news-category-index__item-divider,
  .load-more-section__content .news-category-index__item-divider:last-child:not(:first-child) {
    float: none;
    clear: both;
  }
}
@media print, screen and (min-width: 40em) {
  .load-more-section__content .category-index-card,
  .load-more-section__content .news-category-index__item-divider {
    flex: 0 0 58%;
    max-width: 58%;
  }
}
.load-more-section__content .news-category-index__item-divider {
  flex-basis: auto;
}

.news-latest {
  margin-bottom: 60px;
  position: relative;
}

.news-latest__row {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  flex: 0 0 100%;
  max-width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
}
@media print, screen and (min-width: 40em) {
  .news-latest__row {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .news-latest__row {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .news-latest__row {
    width: 90%;
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .news-latest__row {
    width: 90%;
    max-width: 1170px;
  }
}
@media print, screen and (min-width: 40em) {
  .news-latest__row {
    background-color: #faf9f7;
  }
}

.news-latest__row--icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
@media print, screen and (max-width: 31.24875em) {
  .news-latest__row--icon {
    display: none;
  }
}
.news-latest__row--icon img {
  width: 75px;
}

.news-latest__row--ornament-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 30px;
  margin-bottom: -30px;
}
.news-latest__row--ornament-wrapper .news-latest__row--ornament {
  width: 50%;
  height: 75px;
  border-right: 1px solid #ccc9b8;
}
@media print, screen and (min-width: 40em) {
  .news-latest__row--ornament-wrapper {
    display: none;
  }
}

.news-latest__row--wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 25px;
}
@media print, screen and (min-width: 40em) {
  .news-latest__row--wrapper {
    flex-direction: row;
  }
}

@media print, screen and (min-width: 40em) {
  .news-latest__column {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }
}
@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .news-latest__column {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 40em) and (min-width: 64em) {
  .news-latest__column {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 40em) and (min-width: 75em) {
  .news-latest__column {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 40em) and (min-width: 90em) {
  .news-latest__column {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 40em) {
  .news-latest__column > div {
    padding-left: 0;
    padding-right: 0;
  }
}
@media print, screen and (min-width: 60em) {
  .news-latest__column > div {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media print, screen and (min-width: 64em) {
  .news-latest__column > div {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (min-width: 75em) {
  .news-latest__column > div {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media screen and (min-width: 90em) {
  .news-latest__column > div {
    padding-left: 75px;
    padding-right: 75px;
  }
}

.news-latest__column:first-of-type {
  margin-bottom: 75px;
}
@media print, screen and (min-width: 40em) {
  .news-latest__column:first-of-type {
    margin-bottom: 0;
    border-right: 1px solid #ccc9b8;
  }
}

.news-latest__story .blog-lockup-item {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ccc9b8;
}
@media print, screen and (min-width: 40em) {
  .news-latest__story .blog-lockup-item {
    padding-top: 5px;
    padding-bottom: 0;
    border-bottom: 1px solid #ccc9b8;
  }
}

.news-latest__event .blog-lockup-item {
  padding-top: 40px;
}
@media print, screen and (min-width: 40em) {
  .news-latest__event .blog-lockup-item {
    border-bottom: 1px solid #ccc9b8;
  }
}

.news-latest__body .blog-lockup-item__link {
  /*height: 100%;*/
  padding-top: 10px;
  padding-bottom: 15px;
}
@media print, screen and (min-width: 40em) {
  .news-latest__body .blog-lockup-item__link {
    /*height: 100%;*/
    padding-top: 10px;
    padding-bottom: 15px;
  }
}
.news-latest__body .blog-lockup-item__link .blog-lockup-item__item-container {
  position: static;
  width: 100%;
}
.news-latest__body .blog-lockup-item__link .blog-lockup-item__item-container .news-lockup__title {
  font-size: 18px;
}

.news-latest__body .lw_event_item_title {
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
}

.news-latest__event .blog-lockup-item__date-wrapper {
  align-items: start;
}

.news-latest__event .news-lockup__headline {
  order: 1;
}

.news-latest__event .blog-lockup-item__lockup {
  justify-content: start;
  padding-bottom: 30px;
  border-bottom: 1px solid #ccc9b8;
}
@media print, screen and (min-width: 40em) {
  .news-latest__event .blog-lockup-item__lockup {
    border: none;
    padding-bottom: 10px;
    justify-content: center;
  }
}

@media print, screen and (min-width: 40em) {
  .news-latest__cta {
    float: right;
  }
  .news-latest__cta .base-cta-text {
    margin-top: 0px;
  }
}

@media print, screen and (min-width: 40em) {
  .news-latest__column .news-latest__header .news-category-title-block__title {
    font-size: 38px;
  }
}
@media print, screen and (min-width: 60em) {
  .news-latest__column .news-latest__header .news-category-title-block__title {
    font-family: freight-display-pro, serif;
    font-size: 46px;
    font-weight: 300;
    line-height: 56px;
  }
}
@media screen and (min-width: 75em) {
  .news-latest__column .news-latest__header .news-category-title-block__title {
    font-family: freight-display-pro, serif;
    font-size: 58px;
    font-weight: 300;
    line-height: 71px;
  }
}

.news-form {
  background-color: #c41230;
  color: #fff;
}
.news-form__body-wrapper {
  font-family: freight-text-pro, serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 350;
  letter-spacing: 0.015625rem;
}
.news-form__copy-wrapper {
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: 71px;
}
@media print, screen and (min-width: 40em) {
  .news-form__copy-wrapper {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    position: relative;
    left: 0%;
    margin-top: 114px;
    margin-bottom: 114px;
  }
}
.news-form__decoration-bar {
  width: 1px;
  background-color: #fff;
  height: 100%;
}
.news-form__form-container {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  max-width: 100%;
  justify-content: space-between;
  align-items: flex-end;
}
.news-form__form-wrapper {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 85px;
  margin-top: 15px;
}
@media print, screen and (min-width: 40em) {
  .news-form__form-wrapper {
    margin-top: 117px;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    position: relative;
    left: 16.6666666667%;
    margin-bottom: 0;
  }
}
.news-form__heading-wrapper {
  font-family: freight-display-pro, serif;
  font-size: 47px;
  line-height: 57px;
  font-weight: 400;
  margin-bottom: 20px;
}
.news-form__icon-wrapper {
  height: 31px;
  width: 31px;
  margin-bottom: 13px;
}
.news-form__inner {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .news-form__inner {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .news-form__inner {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .news-form__inner {
    width: 90%;
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .news-form__inner {
    width: 90%;
    max-width: 1170px;
  }
}
.news-form__side-decoration {
  display: none;
}
@media print, screen and (min-width: 40em) {
  .news-form__side-decoration {
    display: block;
    position: relative;
    left: 0%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
    margin-top: 125px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
  }
}
.news-form__submit-button {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 24px 28px;
}
@media print, screen and (min-width: 40em) {
  .news-form__submit-button {
    height: 50px;
    width: 50px;
    padding: 0;
  }
}
.news-form__submit-button-text {
  color: #c41230;
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: rem-cal(2);
  text-transform: uppercase;
}
@media print, screen and (min-width: 40em) {
  .news-form__submit-button-text {
    display: none;
  }
}
.news-form__submit-button-icon {
  height: 13px;
  width: 8px;
}
@media print, screen and (min-width: 40em) {
  .news-form__submit-button-icon {
    margin: 0 auto;
  }
}
.news-form .form-success-message {
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: rem-cal(2);
  text-transform: uppercase;
  flex: 0 0 100%;
  padding: 25px 28px;
}
.news-form input:focus, .news-form button:focus, .news-form select:focus {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}
.news-form .form-element {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  width: calc(-10px + 50%);
  max-width: calc(-10px + 50%);
  flex: 0 0 calc(-10px + 50%);
}
@media print, screen and (max-width: 31.24875em) {
  .news-form .form-element {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }
}
.news-form .form-element.form-element--layout-2 {
  flex: 2 1 20%;
  max-width: 100%;
}
.news-form .form-element.form-element--layout-2 > .form-input__inner {
  padding-right: 20px;
}
@media print, screen and (max-width: 31.24875em) {
  .news-form .form-element.form-element--layout-2 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }
  .news-form .form-element.form-element--layout-2 > .form-input__inner {
    padding-right: 0px;
  }
}
.news-form .form-element.form-element--layout-3 {
  width: 50px;
  max-width: 50px;
  flex: 0 0 50px;
  background-color: #fff;
}
@media print, screen and (max-width: 31.24875em) {
  .news-form .form-element.form-element--layout-3 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    margin-top: 27px;
    border: white solid 2px;
  }
}
.news-form .form-element .form-input__inner {
  width: 100%;
}
.news-form .form-element .m_form-input {
  position: relative;
  margin-bottom: 12px;
}
@media print, screen and (min-width: 40em) {
  .news-form .form-element .m_form-input {
    margin-bottom: 0;
  }
}
.news-form .form-element .m_form-input::before,
.news-form .form-element .m_form-input::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 1px;
  background-color: #d1d0d0;
  z-index: 1;
}
.news-form .form-element .m_form-input::after {
  width: 0%;
  background-color: #7e6d53;
  transition: all 0.2s;
}
.news-form .form-element .m_form-input.focus::after {
  width: 100%;
}
.news-form .form-element label {
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: rem-cal(2);
  text-transform: uppercase;
  display: block;
  position: relative;
  top: 20px;
  opacity: 0;
  transition: all 0.2s;
  color: #fff;
  margin-bottom: 12px;
}
@media print, screen and (min-width: 40em) {
  .news-form .form-element label {
    margin-bottom: 5px;
    margin-top: 5px;
  }
}
.news-form .form-element label.focus {
  color: #fff;
  padding-left: 20px;
}
.news-form .form-element label.no-empty,
.news-form .form-element label.focus {
  top: 0px;
  opacity: 1;
}
.news-form .form-element input,
.news-form .form-element select {
  position: relative;
  width: 100%;
  border: none;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: rem-cal(2);
  text-transform: uppercase;
  font-family: proxima-nova, sans-serif;
  background-color: #c41230;
  color: #fff;
  border: white solid 2px;
  padding: 24px 28px;
  height: 50px;
}
@media print, screen and (min-width: 40em) {
  .news-form .form-element input,
  .news-form .form-element select {
    padding: 12px 20px;
  }
}
.news-form .form-element input::placeholder,
.news-form .form-element select::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1; /* Firefox */
}
.news-form .form-element input:-ms-input-placeholder,
.news-form .form-element select:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}
.news-form .form-element input::-ms-input-placeholder,
.news-form .form-element select::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff;
}
.news-form .form-element select {
  background: none;
  color: #777;
  -webkit-appearance: none;
}
.news-form .form-element select.focus {
  color: #231f20;
}
.news-form .form-element .caret-down {
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #d1d0d0;
}
.news-form .form-element .caret-down.focus {
  border-top: 5px solid #231f20;
}
.news-form .form-element .caret-up {
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #d1d0d0;
}
.news-form .form-element textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: none;
  border: 1px solid #d1d0d0;
  font-size: 24px;
  font-family: freight-text-pro, serif;
}
.news-form .form-element textarea:focus {
  outline: none;
  border: 1px solid #7e6d53;
}
.news-form .form-element p {
  height: 16px;
  color: #fff;
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 400;
  margin-top: 4px;
  position: absolute;
  bottom: -15px;
  left: 8px;
}
@media print, screen and (min-width: 40em) {
  .news-form .form-element p {
    bottom: -24px;
    left: 15px;
  }
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
}

.news-sidebar {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .news-sidebar {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .news-sidebar {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .news-sidebar {
    width: 90%;
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .news-sidebar {
    width: 90%;
    max-width: 1170px;
  }
}
.news-sidebar .base-lockup {
  margin-bottom: 28px;
}
.news-sidebar .base-lockup__heading {
  font-family: proxima-nova, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
}

.news-sidebar__inner {
  flex: 0 0 100%;
  max-width: 100%;
}

.news-sidebar__inner-content {
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: row wrap;
}

.news-sidebar__side-content {
  width: 100%;
  margin-bottom: 28px;
}
@media print, screen and (min-width: 40em) {
  .news-sidebar__side-content {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    position: relative;
    float: right;
    top: 0px;
    right: 0px;
  }
}
.news-sidebar__side-content .base-lockup__copy {
  font-family: proxima-nova, sans-serif;
  line-height: 28px;
  font-size: 16px;
  font-weight: 350;
  margin-top: 7px;
}

.news-sidebar__main-content {
  flex: 0 0 100%;
  max-width: 100%;
}
.news-sidebar__main-content > :first-child {
  margin-top: 0;
  margin-bottom: 0;
}
@media print, screen and (min-width: 40em) {
  .news-sidebar__main-content {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
    position: relative;
    top: 0px;
    left: 0px;
  }
}

@media print, screen and (min-width: 40em) {
  .news-sidebar--spacing-header {
    margin-top: 150px;
  }
}

@media print, screen and (min-width: 40em) {
  .news-sidebar--spacing-title {
    margin-top: 58px;
  }
}

@media print, screen and (min-width: 40em) {
  .news-sidebar--spacing-no-header {
    margin-top: 0px;
  }
}

.news-sidebar__side-content .base-lockup__heading {
  color: #231f20;
}

.news-sidebar__related-spacer {
  height: 1px;
  background-color: #ccc9b8;
  margin: 20px 0;
}

.news-sidebar__related .news-lockup__title {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 22px;
}
.news-sidebar__related .text-normal {
  font-weight: normal;
}
.news-sidebar__related .news-lockup__headline {
  margin-bottom: 8px;
}

.news-sidebar__related-category {
  color: #c41230;
  margin-bottom: 5px;
  font-family: proxima-nova, sans-serif;
  font-style: italic;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.0625rem;
}

.news-sidebar__related-wrapper {
  position: relative;
}

.news-sidebar--hero {
  padding-top: 100px;
}
@media print, screen and (min-width: 40em) {
  .news-sidebar--hero .news-sidebar__main-content {
    flex: 0 0 50%;
    max-width: 50%;
    position: relative;
    left: 8.3333333333%;
  }
}
.news-sidebar--hero .news-sidebar__side-content {
  display: none;
}
@media print, screen and (min-width: 40em) {
  .news-sidebar--hero .news-sidebar__side-content {
    margin-top: 85px;
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
    padding-left: 2%;
  }
}
.news-sidebar--hero .news-sidebar__side-decoration {
  position: relative;
  left: 0%;
  width: 1px;
  margin: 1% 0;
  background-color: #c41230;
}
@media print, screen and (max-width: 31.24875em) {
  .news-sidebar--hero .news-sidebar__side-decoration {
    display: none;
  }
}
.news-sidebar--hero .wysiwyg__container {
  margin: 0;
  width: 100%;
  max-width: unset;
}
.news-sidebar--hero .wysiwyg__inner {
  padding: 0;
}

@media print, screen and (min-width: 40em) {
  .news-sidebar--article .news-sidebar__main-content {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
}
.news-sidebar--article .news-sidebar__side-content {
  display: none;
}
@media print, screen and (min-width: 40em) {
  .news-sidebar--article .news-sidebar__side-content {
    display: block;
    flex: 0 0 24.75%;
    max-width: 24.75%;
    padding-left: 33px;
    margin-top: 60px;
  }
}
.news-sidebar--article .wysiwyg__body-container-inner {
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  left: 0%;
  margin-left: 0;
}
.news-sidebar--article .wysiwyg__container {
  width: 100%;
  max-width: unset;
  margin: 0;
}

@media print, screen and (min-width: 40em) {
  .news-sidebar--index .news-sidebar__inner {
    flex: 0 0 100%;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  .news-sidebar--index .news-sidebar__inner, .news-sidebar--index .news-sidebar__inner:last-child:not(:first-child) {
    float: none;
    clear: both;
  }
}
@media print, screen and (min-width: 40em) {
  .news-sidebar--index .news-sidebar__main-content {
    flex: 0 0 50%;
    max-width: 50%;
    position: relative;
    left: 8.3333333333%;
  }
}
.news-sidebar--index .news-sidebar__side-content {
  display: none;
  margin-left: auto;
}
@media print, screen and (min-width: 40em) {
  .news-sidebar--index .news-sidebar__side-content {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
}
.news-sidebar--index .news-category-index {
  margin: 0;
}
@media print, screen and (min-width: 40em) {
  .news-sidebar--index .news-category-index {
    max-width: unset;
    width: 100%;
  }
}

.news-sidebar--mobile {
  padding-top: 100px;
}
@media print, screen and (min-width: 40em) {
  .news-sidebar--mobile {
    display: none;
  }
}
.news-sidebar--mobile .news-sidebar__main-content {
  display: none;
}
.news-sidebar--mobile .wysiwyg__body-container-inner {
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  left: 0%;
  margin-left: 0;
}
.news-sidebar--mobile .wysiwyg__container {
  width: 100%;
  max-width: unset;
}

.news-context-image__context {
  flex: 0 0 100%;
  max-width: 100%;
}
.news-context-image__context .news-lockup__title {
  font-family: freight-display-pro, serif;
  font-size: 36px;
  font-weight: 300;
  line-height: 44px;
}
@media print, screen and (min-width: 40em) {
  .news-context-image__context .news-lockup__title {
    font-size: 48px;
    font-weight: 300;
    line-height: 59px;
  }
}
.news-context-image__context .news-lockup__body {
  font-family: freight-text-pro, serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 350;
  letter-spacing: 0.015625rem;
  margin-top: 20px;
  margin-bottom: 30px;
}
.news-context-image__context .base-cta-primary {
  margin-bottom: 30px;
}
@media print, screen and (min-width: 40em) {
  .news-context-image__context {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
}
.news-context-image__context.right {
  order: 1;
}
.news-context-image__figure {
  flex: 0 0 100%;
  max-width: 100%;
}
@media print, screen and (min-width: 40em) {
  .news-context-image__figure {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.news-context-image__inner {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
}
@media print, screen and (min-width: 40em) {
  .news-context-image__inner {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .news-context-image__inner {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .news-context-image__inner {
    width: 90%;
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .news-context-image__inner {
    width: 90%;
    max-width: 1170px;
  }
}

.post-photo-gallery {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  position: relative;
}
@media print, screen and (min-width: 40em) {
  .post-photo-gallery {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .post-photo-gallery {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .post-photo-gallery {
    width: 90%;
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .post-photo-gallery {
    width: 90%;
    max-width: 1170px;
  }
}
.post-photo-gallery__carousel-button {
  padding: 20px;
}
.post-photo-gallery__carousel-button--previous {
  transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  -o-transform: scaleX(-1);
}
.post-photo-gallery__carousel-button:focus img {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.post-photo-gallery__carousel-buttons-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  max-width: unset;
  padding-left: 0;
  padding-right: 0;
}
.post-photo-gallery__carousel-container {
  margin-right: auto;
  margin-left: auto;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  height: 100%;
}
.post-photo-gallery__carousel-container, .post-photo-gallery__carousel-container:last-child:not(:first-child) {
  float: none;
  clear: both;
}
@media print, screen and (min-width: 40em) {
  .post-photo-gallery__carousel-container {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.post-photo-gallery__carousel-item {
  height: 100%;
  width: 100%;
}
.post-photo-gallery__carousel-item > div {
  height: 0;
  width: 100%;
  position: relative;
  padding-top: 73%;
}
.post-photo-gallery__carousel-item > div > p {
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  font-style: italic;
  color: #c41230;
  border-bottom: 2px solid #d1d0d0;
  padding-top: 10px;
  padding-bottom: 10px;
}
.post-photo-gallery__carousel-item-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.post-photo-gallery__carousel-item-list {
  height: 100%;
  width: 100%;
}
.post-photo-gallery__divider-bar {
  height: 2px;
  width: 100%;
  background-color: #d1d0d0;
  margin-bottom: 20px;
}
.post-photo-gallery__model-button {
  position: absolute;
  width: 5%;
  z-index: 99;
  top: 15%;
  left: 5%;
}
.post-photo-gallery__model-button:hover, .post-photo-gallery__model-button:focus {
  outline: 2px dotted #fff;
  outline-offset: 4px;
}
.post-photo-gallery__model-image-wrapper {
  position: relative;
  padding-top: 100%;
  width: 100%;
  display: block;
}
.post-photo-gallery__model-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 99;
}
.post-photo-gallery__modal-wrapper {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  display: flex;
  align-items: center;
  color: white;
}
@media print, screen and (min-width: 40em) {
  .post-photo-gallery__modal-wrapper {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .post-photo-gallery__modal-wrapper {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .post-photo-gallery__modal-wrapper {
    width: 90%;
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .post-photo-gallery__modal-wrapper {
    width: 90%;
    max-width: 1170px;
  }
}
.post-photo-gallery__modal-wrapper .post-photo-gallery__carousel-item-list .post-photo-gallery__carousel-item p {
  color: white;
  border-bottom: none;
}
.post-photo-gallery__title-wrapper {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}

.ModalContainerDialog {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: black;
  z-index: 101;
}
.ModalContainerDialog .post-photo-gallery__divider-bar {
  background-color: #fff;
}
.ModalContainerDialog .post-photo-gallery__carousel-container {
  flex: 0 0 100%;
  max-width: 100%;
}
.ModalContainerDialog .post-photo-gallery__carousel-container {
  height: unset;
}
.ModalContainerDialog .post-photo-gallery__carousel-button > img {
  filter: invert(1);
  -webkit-filter: invert(1);
}

@media (min-aspect-ratio: 3/4) {
  .post-photo-gallery__modal-wrapper {
    max-width: 120vh;
  }
}
.post-feature-media {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .post-feature-media {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .post-feature-media {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .post-feature-media {
    width: 90%;
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .post-feature-media {
    width: 90%;
    max-width: 1170px;
  }
}
.post-feature-media__interior {
  flex: 0 0 100%;
  max-width: 100%;
}
.post-feature-media__copy-wrapper {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  padding: 20px 0;
}
.post-feature-media__divider {
  height: 1px;
  width: 100%;
  background-color: #d1d0d0;
  margin-bottom: 20px;
}
.post-feature-media__image-wrapper {
  padding-top: 133.3333333333%;
}
.post-feature-media__image-wrapper, .post-feature-media__video-wrapper {
  position: relative;
  padding-top: 56.25%;
  height: 0;
  width: 100%;
}
.post-feature-media__image-container, .post-feature-media__video-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.post-feature-media__image-container > *, .post-feature-media__video-container > * {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.post-feature-media__copy-wrapper, .post-feature-media__divider, .post-feature-media__image-wrapper, .post-feature-media__video-wrapper {
  width: 100%;
}

.post-feature-media--inline .post-feature-media__interior {
  margin-right: auto;
  margin-left: auto;
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: 30px;
}
.post-feature-media--inline .post-feature-media__interior, .post-feature-media--inline .post-feature-media__interior:last-child:not(:first-child) {
  float: none;
  clear: both;
}
@media print, screen and (max-width: 31.24875em) {
  .post-feature-media--inline .post-feature-media__interior {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.post-feature-quote {
  position: relative;
  background-color: #EDECE2;
}
.post-feature-quote__attribution-wrapper {
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 15px;
}
@media print, screen and (max-width: 31.24875em) {
  .post-feature-quote__attribution-wrapper {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
    position: relative;
    left: 8.3333333333%;
    text-align: left;
  }
}
@media print, screen and (min-width: 40em) {
  .post-feature-quote__attribution-wrapper {
    flex: 0 0 100%;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    font-size: 16px;
    text-align: center;
    line-height: 20px;
  }
  .post-feature-quote__attribution-wrapper, .post-feature-quote__attribution-wrapper:last-child:not(:first-child) {
    float: none;
    clear: both;
  }
}
.post-feature-quote__background-wrapper {
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.post-feature-quote__background-container {
  height: 100%;
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .post-feature-quote__background-container {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .post-feature-quote__background-container {
    margin-right: calc(4.25rem + 15px);
    margin-left: calc(4.25rem + 15px);
  }
}
@media screen and (min-width: 75em) {
  .post-feature-quote__background-container {
    max-width: 1117px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .post-feature-quote__background-container {
    width: 95%;
    max-width: 1755px;
  }
}
.post-feature-quote__interior {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  z-index: 1;
  position: relative;
  padding: 15px 0;
}
@media print, screen and (min-width: 40em) {
  .post-feature-quote__interior {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .post-feature-quote__interior {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .post-feature-quote__interior {
    width: 90%;
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .post-feature-quote__interior {
    width: 90%;
    max-width: 1170px;
  }
}
.post-feature-quote__quote-icon-wrapper {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
  margin: 15px 0;
}
@media print, screen and (max-width: 31.24875em) {
  .post-feature-quote__quote-icon-wrapper {
    position: relative;
    left: 8.3333333333%;
  }
  .post-feature-quote__quote-icon-wrapper > img {
    width: 16px;
    height: auto;
  }
}
@media print, screen and (min-width: 40em) {
  .post-feature-quote__quote-icon-wrapper {
    margin-right: auto;
    margin-left: auto;
    margin: 30px auto;
  }
  .post-feature-quote__quote-icon-wrapper, .post-feature-quote__quote-icon-wrapper:last-child:not(:first-child) {
    float: none;
    clear: both;
  }
  .post-feature-quote__quote-icon-wrapper > img {
    width: 31px;
    height: auto;
    margin: 0 auto;
  }
}
.post-feature-quote__quote-icon-wrapper--bottom > img {
  transform: rotate(180deg);
}
.post-feature-quote__quote-wrapper {
  text-align: left;
  font-family: freight-display-pro, serif;
  font-size: 22px;
  line-height: 28px;
  font-weight: 550;
}
@media print, screen and (max-width: 31.24875em) {
  .post-feature-quote__quote-wrapper {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
    position: relative;
    left: 8.3333333333%;
  }
}
@media print, screen and (min-width: 40em) {
  .post-feature-quote__quote-wrapper {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
    margin-right: auto;
    margin-left: auto;
    font-size: 32px;
    text-align: center;
    line-height: 40px;
  }
  .post-feature-quote__quote-wrapper, .post-feature-quote__quote-wrapper:last-child:not(:first-child) {
    float: none;
    clear: both;
  }
}

.post-masthead {
  padding-top: 20px;
}
@media print, screen and (min-width: 40em) {
  .post-masthead {
    padding-top: 40px;
  }
}
.post-masthead__author {
  font-style: italic;
  font-weight: 500;
}
.post-masthead__body {
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  color: #282828;
}
.post-masthead__byLine {
  margin-top: 0;
}
@media print, screen and (min-width: 40em) {
  .post-masthead__byLine {
    font-size: 16px;
    line-height: 30px;
    font-weight: 300;
  }
}
.post-masthead__heading {
  letter-spacing: 0.86px;
  font-style: italic;
  font-weight: 600;
}
@media print, screen and (min-width: 40em) {
  .post-masthead__heading {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 1.29px;
  }
}
.post-masthead__heading:hover {
  text-decoration: underline;
}
.post-masthead__interior {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
@media print, screen and (min-width: 40em) {
  .post-masthead__interior {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .post-masthead__interior {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .post-masthead__interior {
    width: 90%;
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .post-masthead__interior {
    width: 90%;
    max-width: 1170px;
  }
}
.post-masthead__lockup-body-container .title-description {
  font-weight: 800;
  margin: 18px;
}
.post-masthead__lockup-byLine-container {
  color: #282828;
  margin-top: 15px;
}
@media print, screen and (min-width: 40em) {
  .post-masthead__lockup-byLine-container {
    margin-top: 30px;
    display: block;
    font-size: 16px;
    line-height: 30px;
    font-weight: 300;
  }
}
.post-masthead__lockup-heading-container {
  color: #c41230;
  font-size: 12px;
  margin-bottom: 8px;
  letter-spacing: 0.86px;
  font-style: italic;
  font-weight: 600;
}
@media print, screen and (min-width: 40em) {
  .post-masthead__lockup-heading-container {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 1.29px;
  }
}
.post-masthead__lockup-title-container {
  margin-bottom: 8px;
}
@media print, screen and (min-width: 40em) {
  .post-masthead__lockup-title-container {
    margin-bottom: 14px;
  }
}
.post-masthead__lockup-wrapper {
  flex: 0 0 100%;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  line-height: 15px;
  margin-top: 13px;
  margin-bottom: 13px;
}
.post-masthead__lockup-wrapper, .post-masthead__lockup-wrapper:last-child:not(:first-child) {
  float: none;
  clear: both;
}
@media print, screen and (min-width: 40em) {
  .post-masthead__lockup-wrapper {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    text-align: center;
    margin-top: 55px;
  }
}
.post-masthead__published {
  font-style: italic;
  font-weight: 500;
}
.post-masthead__published-date {
  font-style: normal;
  font-weight: normal;
}
.post-masthead__updated {
  display: none;
}
.post-masthead__read-time {
  font-style: normal;
}
.post-masthead__social-container {
  display: none;
}
@media print, screen and (min-width: 40em) {
  .post-masthead__social-container {
    display: block;
  }
}
.post-masthead__title {
  font-size: 26px;
  line-height: 30px;
  letter-spacing: unset;
  font-weight: 700;
}
@media print, screen and (min-width: 40em) {
  .post-masthead__title {
    font-size: 28px;
    line-height: 32px;
  }
}
.post-next {
  margin-top: 50px;
  margin-bottom: 50px;
}

.post-next__inner {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  background: #000;
}
@media print, screen and (min-width: 40em) {
  .post-next__inner {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .post-next__inner {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .post-next__inner {
    width: 90%;
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .post-next__inner {
    width: 90%;
    max-width: 1170px;
  }
}

.post-next__image-container {
  width: 100%;
  min-height: 250px;
}
.post-next__image-container:hover {
  outline: 2px solid #c41230;
  outline-offset: 4px;
}

.post-next__image-container:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}

.post-next__image {
  position: relative;
}
@media print, screen and (max-width: 47.99875em) {
  .post-next__image {
    padding-top: 75%;
    position: relative;
    height: 0;
    width: 100%;
  }
  .post-next__image img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}
.post-next__image img {
  object-fit: cover;
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 250px;
  max-height: 500px;
}

.post-next__image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.post-next__image-text-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5%;
  color: white;
}
@media print, screen and (min-width: 40em) {
  .post-next__image-text-container {
    width: 65%;
    padding-left: 50px;
  }
}
.post-next__image-text-container p {
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 5px;
}
@media print, screen and (min-width: 40em) {
  .post-next__image-text-container p {
    font-family: proxima-nova, sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 22px;
  }
}
.post-next__image-text-container h3 {
  font-family: proxima-nova, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
}
@media print, screen and (min-width: 40em) {
  .post-next__image-text-container h3 {
    font-family: proxima-nova, sans-serif;
    font-weight: 800;
    font-size: 36px;
    line-height: 40px;
  }
}

.post-masthead-feature-image {
  display: flex;
  flex-flow: column nowrap;
  margin-top: 3em;
}
.post-masthead-feature-image__feature-image-background-wrapper {
  position: absolute;
  top: 0;
  height: 75%;
  width: 100%;
  background-color: #c41230;
  height: 85%;
  height: 85%;
}
@media print, screen and (min-width: 40em) {
  .post-masthead-feature-image__feature-image-background-wrapper {
    display: none;
  }
}
.post-masthead-feature-image__feature-image-container {
  margin-top: 68px;
}
@media print, screen and (min-width: 40em) {
  .post-masthead-feature-image__feature-image-container {
    margin-top: 23px;
  }
}
.post-masthead-feature-image__feature-image-container .post-feature-media__divider,
.post-masthead-feature-image__feature-image-container .post-feature-media__copy-wrapper {
  display: none;
}
@media print, screen and (min-width: 40em) {
  .post-masthead-feature-image__feature-image-container .post-feature-media__divider,
  .post-masthead-feature-image__feature-image-container .post-feature-media__copy-wrapper {
    display: block;
  }
}
.post-masthead-feature-image__feature-image-wrapper {
  position: relative;
  padding-top: 23px;
}
@media print, screen and (min-width: 40em) {
  .post-masthead-feature-image__feature-image-wrapper {
    order: 1;
  }
}

.post-masthead-feature-image--brief .post-masthead-feature-image__feature-image-background-wrapper {
  display: none;
}
.post-masthead-feature-image--brief .post-masthead-feature-image__feature-image-container {
  margin-top: 0;
}
.post-masthead-feature-image--brief .post-masthead-feature-image__feature-image-wrapper {
  padding-top: 0;
  order: 1;
}
@media print, screen and (min-width: 40em) {
  .post-masthead-feature-image--brief .post-feature-media__interior {
    flex: 0 0 50%;
    max-width: 50%;
    margin-right: auto;
    margin-left: auto;
  }
  .post-masthead-feature-image--brief .post-feature-media__interior, .post-masthead-feature-image--brief .post-feature-media__interior:last-child:not(:first-child) {
    float: none;
    clear: both;
  }
}

.post-masthead-feature-image__masthead-wrapper--desktop {
  display: none;
}
@media print, screen and (min-width: 40em) {
  .post-masthead-feature-image__masthead-wrapper--desktop {
    display: block;
  }
}

.post-masthead-feature-image__masthead-wrapper--mobile {
  display: none;
}
@media print, screen and (max-width: 40em) {
  .post-masthead-feature-image__masthead-wrapper--mobile {
    display: block;
  }
}

.news-feeds-group {
  z-index: 0;
  position: relative;
  overflow: hidden;
  padding-top: 32px;
  padding-bottom: 32px;
  /*******************************/
  /*******************************/
  /*******************************/
  /*******************************/
}
.news-feeds-group .news-feeds-group__cards-wrapper {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex: 1;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 12px;
}
@media print, screen and (min-width: 40em) {
  .news-feeds-group .news-feeds-group__cards-wrapper {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .news-feeds-group .news-feeds-group__cards-wrapper {
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
}
@media screen and (min-width: 75em) {
  .news-feeds-group .news-feeds-group__cards-wrapper {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 90em) {
  .news-feeds-group .news-feeds-group__cards-wrapper {
    width: 90%;
    max-width: 1404px;
  }
}
@media print, screen and (min-width: 64em) {
  .news-feeds-group .news-feeds-group__cards-wrapper {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.news-feeds-group .news-feeds-group__cards-wrapper .article-6col {
  flex: 1 1 0px;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  min-width: 0;
}
@media print, screen and (min-width: 40em) {
  .news-feeds-group .news-feeds-group__cards-wrapper .article-6col {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media print, screen and (min-width: 64em) {
  .news-feeds-group .news-feeds-group__cards-wrapper .article-6col {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}
@media screen and (min-width: 75em) {
  .news-feeds-group .news-feeds-group__cards-wrapper .article-6col {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .news-feeds-group .news-feeds-group__cards-wrapper .article-6col {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.news-feeds-group .news-feeds-group__cards-wrapper .article-6col .article-6col__card-container article.article-6col__card-link {
  padding-top: 24px;
  padding-bottom: 0;
}
@media print, screen and (min-width: 64em) {
  .news-feeds-group .news-feeds-group__cards-wrapper .article-6col .article-6col__card-container article.article-6col__card-link {
    padding-bottom: 24px;
  }
}
.news-feeds-group .news-feeds-group__cards-wrapper .article-6col__copy-wrapper {
  margin: 0;
  padding: 0;
}
.news-feeds-group .news-feeds-group__cards-wrapper .article-6col__copy-wrapper .article-6col__copy {
  padding-top: 20px;
}
.news-feeds-group .news-feeds-group__cards-wrapper .article-6col__copy-wrapper .article-6col__copy .news-lockup__headline {
  color: #c41230;
  margin: 0 0 12px 0;
  outline: unset;
  outline-offset: unset;
}
.news-feeds-group .news-feeds-group__cards-wrapper .article-6col__copy-wrapper .article-6col__copy .news-lockup__headline a:focus {
  outline: 2px dotted #c41230;
  outline-offset: 4px;
}
.news-feeds-group .news-feeds-group__cards-wrapper .article-6col__copy-wrapper .article-6col__copy .news-lockup__title {
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  margin: 0 0 12px 0;
}
@media print, screen and (min-width: 64em) {
  .news-feeds-group .news-feeds-group__cards-wrapper .article-6col__copy-wrapper .article-6col__copy .news-lockup__title {
    font-size: 20px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #c41230;
  }
}
@media print, screen and (min-width: 64em) {
  .news-feeds-group .news-feeds-group__cards-wrapper .article-6col__copy-wrapper .article-6col__copy .news-lockup__title {
    margin: 0 0 12px 0;
  }
}
.news-feeds-group .news-feeds-group__cards-wrapper .article-6col__copy-wrapper .article-6col__copy .news-lockup__title .news-lockup__title-link {
  color: #231f20;
}
.news-feeds-group .news-feeds-group__cards-wrapper .article-6col__copy-wrapper .article-6col__copy .news-lockup__title .news-lockup__title-link::after {
  align-self: flex-end;
  background-color: transparent;
  border-right: 2px solid #c41230;
  border-top: 2px solid #c41230;
  content: "";
  display: inline-block;
  height: 9px;
  margin-right: -24px;
  transform: translateY(-100%) rotate(45deg) translateX(10px);
  transition: all 0.2s ease;
  width: 9px;
}
.news-feeds-group .news-feeds-group__cards-wrapper .article-6col__copy-wrapper .article-6col__copy .news-lockup__title .news-lockup__title-link:hover::after {
  transform: translateY(-16px) rotate(45deg) translateX(20px);
}
.news-feeds-group .news-feeds-group__cards-wrapper .article-6col__copy-wrapper .article-6col__copy .news-lockup__body {
  margin: 0 0 12px 0;
}
.news-feeds-group .news-feeds-group__cards-wrapper .article-6col__copy-wrapper .article-6col__copy .news-lockup__byline--text {
  display: block;
  margin: 0 0 20px 0;
}
.news-feeds-group .column-noimg {
  display: flex;
  margin-top: 12px;
}
.news-feeds-group .column-noimg .article-6col {
  flex: 0 0 100%;
  max-width: 100%;
}
@media print, screen and (min-width: 64em) {
  .news-feeds-group .column-noimg .article-6col {
    flex: 0 0 48%;
    max-width: 48%;
  }
}
.news-feeds-group .column-noimg .article-6col .article-6col__card-container {
  height: 100%;
}
.news-feeds-group .column-noimg .article-6col .article-6col__card-container .article-6col__card-link {
  height: 100%;
  padding: 0 !important;
}
.news-feeds-group .column-noimg .article-6col .article-6col__card-container .article-6col__card-link .article-6col__copy-wrapper {
  background-color: unset;
  border-bottom: 1px solid #CCCAB8;
  width: 100%;
  height: 100%;
}
.news-feeds-group .column-noimg .noimage {
  display: none;
}
.news-feeds-group .column-2 .article-6col {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 20px;
}
@media print, screen and (min-width: 64em) {
  .news-feeds-group .column-2 .article-6col {
    flex: 0 0 48%;
    max-width: 48%;
  }
}
@media print, screen and (min-width: 64em) {
  .news-feeds-group .column-2 .article-6col .article-6col__card-container .article-6col__card-link .article-6col__copy-wrapper {
    padding: 24px;
    margin-top: -36px;
  }
}
@media print, screen and (min-width: 64em) {
  .news-feeds-group .column-2 .article-6col .article-6col__card-container .article-6col__card-link .article-6col__copy-wrapper .article-6col__copy {
    padding: 0;
  }
}
.news-feeds-group .column-2 .article-6col .article-6col__card-container .article-6col__card-link .article-6col__copy-wrapper .article-6col__copy .news-lockup__headline {
  margin-top: 0;
}
.news-feeds-group .column-3 .article-6col {
  flex: 0 0 100%;
  max-width: 100%;
}
@media print, screen and (min-width: 64em) {
  .news-feeds-group .column-3 .article-6col {
    flex: 0 0 33.3%;
    max-width: 33.3%;
  }
}
.news-feeds-group .column-3 .article-6col .article-6col__card-container .article-6col__card-link .article-6col__copy-wrapper {
  background-color: unset;
  width: 100%;
}
.news-feeds-group .column-4 .article-6col {
  flex: 0 0 100%;
  max-width: 100%;
}
@media print, screen and (min-width: 64em) {
  .news-feeds-group .column-4 .article-6col {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
.news-feeds-group .column-4 .article-6col .article-6col__card-container .article-6col__card-link .article-6col__copy-wrapper {
  background-color: unset;
  width: 100%;
}

/*******************************/
.section-bgcolor-white .column-2 .article-6col__copy-wrapper {
  background-color: #fff;
}

.section-bgcolor-warmwhite .column-2 .article-6col__copy-wrapper {
  background-color: #FAF9F7;
}

.section-bgcolor-lighttan .column-2 .article-6col__copy-wrapper {
  background-color: #EDECE2;
}

.section-bgcolor-tan .column-2 .article-6col__copy-wrapper {
  background-color: #CCCAB8;
}
.section-bgcolor-tan .news-lockup__byline--text {
  color: #231f20;
}

.section-bgcolor-red .column-2 .article-6col__copy-wrapper {
  background-color: #c41230;
}
.section-bgcolor-red .news-lockup__headline {
  outline: unset;
  outline-offset: unset;
}
.section-bgcolor-red .news-lockup__headline a {
  color: #fff;
}
.section-bgcolor-red .news-lockup__headline a:focus {
  outline: 2px dotted #fff !important;
}
.section-bgcolor-red .news-lockup__title {
  color: #fff;
}
.section-bgcolor-red .news-lockup__title:focus-within {
  outline: 2px dotted #fff !important;
  outline-offset: 4px;
}
.section-bgcolor-red .news-lockup__title .news-lockup__title-link {
  color: #fff !important;
}
.section-bgcolor-red .news-lockup__title .news-lockup__title-link::after {
  border-right: 2px solid #fff !important;
  border-top: 2px solid #fff !important;
}
.section-bgcolor-red .news-lockup__body {
  color: #fff;
}
.section-bgcolor-red .news-lockup__byline--text {
  color: #fff;
}

.rfiModal {
  z-index: 100;
}

.rfiModal h1 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  float: left;
  display: inline-block;
  width: 80%;
}

.rfiModal .overlay {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 110;
}

.rfiModal .form_rfi {
  position: fixed;
  z-index: 120;
}

.rfiModal .modal {
  position: fixed;
  z-index: 120;
}

.rfiModal .form_rfi {
  background-color: #edece2;
  padding: 20px;
}

.rfiModal div.action.form_action > button, .form_button_submit {
  background-color: #c41230;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 0px !important;
  text-transform: uppercase;
}

.rfiModal div.action.form_action > button:focus, .form_button_submit:focus {
  background-color: #ad102a;
}

.form_rfi {
  margin: 40px auto;
  font-family: sans-serif;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  left: calc(50% - 150px);
}

.form_rfi *:focus {
  outline-color: #c41230;
  outline-style: inset;
}

.form_rfi #form_description {
  display: none;
}

@media screen and (min-width: 480px) {
  .form_rfi {
    width: 480px;
    left: calc(50% - 240px);
  }
}
@media screen and (min-width: 768px) {
  .form_rfi {
    width: 760px;
    left: calc(50% - 380px);
  }
}
.form_rfi .form_question {
  display: block;
  box-sizing: border-box;
  margin: 0 0 1em 0;
  padding: 0;
}

.form_rfi .form_label a {
  color: #c41230 !important;
}
.form_rfi .form_label a:hover {
  color: black !important;
}

.form_rfi div.form_header .form_label {
  background: #D6D2C1 !important;
}

.form_rfi .form_question label, .form_rfi div.form_page label {
  font-size: 1rem;
  margin-bottom: 5px;
}

.form_rfi .form_question input, .form_rfi .form_question select {
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 10px !important;
  width: 100% !important;
}

.form_rfi .form_address input, .form_rfi .form_address select, .form_rfi .form_address textarea {
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 10px !important;
  width: 100% !important;
  margin-bottom: 1em;
}

.form_rfi .form_question input[type=checkbox], .form_rfi .form_question input[type=radio] {
  font-size: 1rem;
  width: 13px !important;
}

.form_rfi .form_question.form_birthdate select {
  width: 30% !important;
}

.form_rfi legend {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.form_rfi .form_birthdate legend, .form_rfi .form_checkbox legend, .form_rfi .form_radio legend {
  font-size: 1rem;
  margin-bottom: 5px;
}

#rfiCloseBtn {
  float: right;
  margin-bottom: 10px;
  padding: 5px;
}

#rfiCloseBtn:focus {
  color: black;
  background-color: transparent;
}