.context-image {
  position: relative;
  background-color: color(white);
}

.context-image__badge {
  position: absolute;
  top: space(12);
  display: block;
  width: 60px;
  height: 65px;
  content: "";

  @media screen and (max-width: 768px){
    width: 50px;
    height: 55px;
  }
  @include breakpoint(medium) {
    right: 0;
  }
}

.context-image__inner {
  @include create-row;
}

.context-image__context {
  position: relative;
  @include flex-grid-column(12);
  padding-top: space(15);
  // @include grid-col-off(7);

  @include breakpoint(medium) {
    @include flex-grid-size(5);

    padding-top: space(25);
    padding-bottom: space(25);

    &.right {
      @include grid-col-off(7);
    }
  }

}

.context-image__figure {
  position: relative;
  margin-top: space(12);
  margin-right: space(6);
  margin-bottom: space(12);
  margin-left: space(6);
  background-color: color(red);

  @include breakpoint(medium) {
    position: absolute;
    top: 0;

    width: 48%;
    height: 100%;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 0;

    &.left {
      right: 0;
    }
  }

  // &::after {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   display: block;
  //   width: 100%;
  //   height: 100%;
  //   content: "";
  //   background: -moz-linear-gradient(269deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 52%, rgba(0, 0, 0, 0.7) 100%);
  //   /* ff3.6+ */
  //   background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(52%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.7)));
  //   /* safari4+,chrome */
  //   background: -webkit-linear-gradient(269deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 52%, rgba(0, 0, 0, 0.7) 100%);
  //   /* safari5.1+,chrome10+ */
  //   background: -o-linear-gradient(269deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 52%, rgba(0, 0, 0, 0.7) 100%);
  //   /* opera 11.10+ */
  //   background: -ms-linear-gradient(269deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 52%, rgba(0, 0, 0, 0.7) 100%);
  //   /* ie10+ */
  //   background: linear-gradient(181deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 52%, rgba(0, 0, 0, 0.7) 100%);
  //   /* w3c */
  //   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#000000', GradientType=0);
  //   /* ie6-9 */
  // }
}

.context-image__image {
  width: 100%;
  height: 375px;
  object-fit: cover;

  @include breakpoint(medium) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}

.context-image__caption {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  padding: space(6);
  color: color(white);

  &::before {
    background: linear-gradient(to bottom,
        color(transparent) 0%,
        color(black-overlay-light) 100%);
    height: calc(100% - 75px);
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: auto;
    content: "";
    // z-index: 1;

    @include breakpoint(large) {
      height: 125px;
      top: calc(100% - 75px - 125px);
    }
  }

  &::after {
    background-color: color(black-overlay-light);
    width: 100%;
    height: 75px;
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;

    @include breakpoint(large) {
      height: 75px;
    }
  }

  @include breakpoint(large) {
    // padding: 0 space(6) space(6) space(6);
  }
}

.context-image__caption-heading {
  @include text-heading-5;
  color: color(white) !important;
}

.context-image__caption-copy {
  @include text-body-small;
  position: relative;
  font-weight: 300;

  .context-image__caption-heading+& {
    margin-top: space(5);
  }
}
