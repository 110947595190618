.news-contact {
  position: relative;
  z-index: 2;
  padding-top: rem(47);
  background-color: color(gold);
  &__address-line {
    @include news-body-large--desktop;
    font-weight: 350px;
  }
  &__address-wrapper {
    @include flex-grid-size(10);
    @include breakpoint(medium) {
      @include flex-grid-size(4);
      @include grid-column-position(0);
      padding-right: 2ch;
    }
  }
  &__contacts-wrapper {
    @include flex-grid-size(10);
    margin-bottom: 132px;
    @include breakpoint(medium) {
      @include flex-grid-size(4);
      @include grid-column-position(0);
      padding-right: 2ch;
    }
  }
  &__container {
    @include create-news-row;
  }
  &__email {
    display: block;
    @include news-body-large--desktop;
    &:focus,
    &:hover {
      text-decoration: underline;
    }
    &:focus {
      @include focus-red;
    }
  }
  &__links-wrapper {
    @include flex-grid-size(10);
    margin-bottom: rem(96);
    @include breakpoint(medium) {
      @include flex-grid-size(4);
      @include grid-column-position(0);
      padding-right: 2ch;
    }
  }
  &__link {
    display: block;
    @include news-navigation-footer-secondary;
    margin-bottom: rem(15);
    &:focus,
    &:hover {
      text-decoration: underline;
    }
    @include breakpoint(medium) {
      line-height: rem(35);
      margin-bottom: 0;
    }
    &:focus {
      @include focus-red;
    }
  }
  &__phone {
    display: block;
    @include news-body-large--desktop;
    @include breakpoint(medium) {
      margin-bottom: rem(5);
    }
    &:focus,
    &:hover {
      text-decoration: underline;
    }
    &:focus {
      @include focus-red;
    }
  }
  &__social-follow {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: rem(24);
    margin-bottom: rem(31);
    @include breakpoint(medium) {
      margin-top: rem(20);
      // margin-bottom: rem(31);
    }
  }
  &__social-icon-wrapper {
    position: relative;
    height: 0;
    width: rem(25);
    height: rem(25);
    margin-right: rem(20);
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  &__social-follow-copy {
    @include news-cta-button;
    color: color(red);
    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }
  &__title {
    @include news-heading-3;
    color: color(red);
  }
  &__title-wrapper {
    @include flex-grid-size(12);
    // @include grid-column-position(1);
    margin-bottom: rem(28);
    @include breakpoint(medium) {
      margin-bottom: rem(24);
    }
    ::after {
      display: block;
      content: "";
      height: 1px;
      background-color: color(gold-accent);
      width: 100%;
      margin-top: rem(18.31);
      @include breakpoint(medium) {
        margin-top: rem(12);
      }
    }
  }
}
