.cards-news {
  padding-top: space(10);
  padding-bottom: space(10);
  background-color: color(gold);

  @include breakpoint(medium) {
    padding-top: space(15);
    padding-bottom: space(15);
  }

  @include breakpoint(large) {
    padding-top: space(15);
    padding-bottom: space(15);
  }
}

.cards-news__cta-container {
  @include flex-grid-column(12);
  padding-top: space(7);
  display: none;

  @include breakpoint(large) {
    @include flex-grid-size(4);
    padding-top: 0px;
    display: flex;
    justify-content: flex-end;
    padding-bottom: space(6);
    align-self: center;
  }
}

.cards-news__cta-container-footer {
  @include flex-grid-column(12);
  // padding-top: space(7);
  padding-top: 0;

  @include breakpoint(large) {
    @include flex-grid-size(4);
    padding-top: 0px;
    justify-content: flex-end;
    padding-bottom: space(15);
    align-self: center;
    display: none;
  }
}

.cards-news__cards-container {
  @include create-row;
  justify-content:center;
}

.cards-news__heading-container {
  @include flex-grid-column(12);
  align-self: center;
  justify-self: center;
  padding-bottom: space(7);

  @include breakpoint(large) {
    @include flex-grid-size(8);
    padding-bottom: space(7);
  }
}

.cards-news__heading {
  @include text-heading-2;
  color: color(red);
}

.cards-news__card {
  position: relative;
  // margin-bottom: space(10);
  margin-bottom: 0;
  @include flex-grid-column(12);

  &:last-child {
    margin-bottom: 0;
  }

  @include breakpoint(large) {
    margin-bottom: 0;
    @include flex-grid-size(4);
    @include grid-column-offset(0);
  }

  .cards-news__card-inner {
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 0;
      background-color: transparent;
      left: 0;
      bottom: 0;
      overflow: hidden;
      transition: height 0.2s ease-out;
    }
  }

  &:hover {
    cursor: grab;

    .cards-news__headline {
      border-bottom-color: color(black);
    }
  }
}

.cards-news__card-link {
  display: block;
  width: 100%;
}

.cards-news__card-link:focus{
  @include focus-red;
}

.cards-news__image-container {
  position: relative;
  padding-bottom: space(3.75);
}

.cards-news__image {
  height: 220px;
  width: 100%;
  object-fit: cover;
}

.cards-news__card-inner {
  position: relative;
  // padding-bottom: space(6.25);
  padding-bottom: space(2.5);
  // border-bottom: 1px solid color(gold-accent);
}

.cards-news__category {
  @include text-body-small;
  color: color(red);
  font-style: italic;
  padding-bottom: space(1);
}

.cards-news__headline {
  @include text-heading-6;
  display: inline;
  line-height: 1.5;
  color: color(black);
  font-weight: 600;
  padding-bottom: 2px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  transition-property: border-bottom-color;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.cards-news__divider {
  border-bottom: 1px solid color(gold-accent);
  margin-top: space(7);
  margin-bottom: space(10);

  @include breakpoint(large) {
    margin-top: space(5);
  }
}
