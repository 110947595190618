.news-video-index {
  &__checkbox {
    &:checked {
      display: none;
    }
  }
  &__content-wrapper {
    margin-top: rem(50);
    @include breakpoint(medium) {
      margin-top: rem(100);
    }
  }
  &__copy {
    display: none;
    @include breakpoint(medium) {
      display: block;
      @include news-body-copy-tight;
    }
  }
  &__copy-container {
    @include flex-grid-size(6);
  }
  &__copy-wrapper {
    @include create-news-row;
    @include breakpoint(medium) {
      margin-top: rem(80);
    }
  }
  &__group-container {
  }
  &__header {
    @include news-heading-2--mobile;
    @include breakpoint(medium) {
      @include news-heading-2--desktop;
    }
  }
  &__header-container {
    @include create-news-row;
    color: color(white);
  }
  &__header-wrapper {
    background-image: url("../images/illustration-duotone-red.png");
    padding: rem(30) 0;
  }
  &__inner {
  }
  &__items-group-wrapper {
  }
  &__show-more-button {
    display: flex;
    justify-content: center;

    .base-cta-text {
      margin: 0 auto rem(50) auto;
    }

    &.expanded {
      .base-cta-text {
        display: none;
      }
    }
  }
  &__show-more-content {
    width: 100%;
  }
  &__show-more-button {
    .base-cta-text::before {
      transform: translateY(-80%) rotate(135deg);
      -webkit-transform: translateY(-80%) rotate(135deg);
      -moz-transform: translateY(-80%) rotate(135deg);
      -ms-transform: translateY(-80%) rotate(135deg);
      -o-transform: translateY(-80%) rotate(135deg);
    }
    &:focus,
    &:hover {
      .base-cta-text::before {
        transform: translate(0, -5px) rotate(135deg) scale(1);
        -webkit-transform: translate(0, -5px) rotate(135deg) scale(1);
        -moz-transform: translate(0, -5px) rotate(135deg) scale(1);
        -ms-transform: translate(0, -5px) rotate(135deg) scale(1);
        -o-transform: translate(0, -5px) rotate(135deg) scale(1);
      }
    }
  }
}

#news-video-index__show-more-check-1 {
  &:checked {
    #news-video-index__show-more-button-1 {
      display: none;
    }
  }
}

.news-video-index__show-more-button {
  &:focus {
    span {
      @include focus-red;
      text-decoration: underline;
    }
  }
}
