.news-context-image {
  &__badge {
  }
  &__caption {
  }
  &__caption-copy {
  }
  &__caption-heading {
  }
  &__context {
    .news-lockup {
      &__title {
        @include news-heading-3;
      }
      &__body {
        @include news-body-regular;
        margin-top: rem(20);
        margin-bottom: rem(30);
      }
    }
    .base-cta-primary {
      margin-bottom: rem(30);
    }

    @include flex-grid-size(12);
    @include breakpoint(medium) {
      @include flex-grid-size(5);
    }

    &.right {
      order: 1;
    }
  }
  &__figure {
    @include flex-grid-size(12);
    @include breakpoint(medium) {
      @include flex-grid-size(6);
    }
  }
  &__image {
  }
  &__inner {
    @include create-news-row;
    justify-content: space-between;
    align-items: center;
    padding-top: rem(50);
    padding-bottom: rem(50);
  }
}
