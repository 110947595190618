.text-red {
  // color: red;
  color: color(red);
}

.category-hero__wrapper {
  position: relative;
  padding-top: space(20);
  overflow: hidden;

  background: {
    // color: color(red);
    image: url('../images/illustrations/illustration-duotone-red.png');
    repeat: no-repeat;
    position: center top;
    size: 100% 95vw;
  }

  @include breakpoint(medium) {
    background-size: 100% 80vw;
  }

  @include breakpoint(tablet-min) {
    background-size: 100% 75vw;
  }
  @include breakpoint(small-desktop) {
    background-size: 100% 72vw;
  }

  @include breakpoint(large) {
    padding-top: space(20);
    padding-bottom: space(15);

    background: {
      position: center top;
      size: 100% 55%;
    }
  }
}

.category-hero__side-decoration {
  display: none;
  margin-top: 30px;
  position: absolute;

  @include breakpoint(large) {
    @include grid-column-position(0);
    position: absolute;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  > .category-hero__side-decoration-bar {
    height: 80vh;
    background: color(gold-accent);
    width: 1px;
    display: block;
    margin-top: -22px;
    margin-right: 3px;
  }

  .hero-landing__content-icon {
    top:100%;
    left:14px;
  }
}

.category-hero__side-decoration-icon-wrapper {
  display: flex;
  height: 82px;
  fill: color(white);
}

.category-hero__side-decoration-icon {
  @include breakpoint(large) {
    height: auto;
    width: 46px;
  }
}

.category-hero__interior {
  @include flex-grid-column(12);
  @include flex-grid-row(null, expand, 12);
  padding: 0;
  flex: 1;
  position: relative;

  @include breakpoint(small) {
    margin-right: get-row-margin(small);
    margin-left: get-row-margin(small);
    padding: 0;
  }

  @include breakpoint(large) {
    margin-right: get-row-margin(large);
    margin-left: get-row-margin(large);
  }

  @include breakpoint(xlarge) {
    max-width: rem(1064);
    margin-right: auto;
    margin-left: auto;
  }

  @include breakpoint(xxlarge) {
    width: 90%;
    max-width: rem(1170);
  }
}

.category-hero__header-wrapper {
  padding: 0 5%;

  @include flex-grid-size(12);
  color: white;
  margin-top: 0;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include breakpoint(large) {
    margin-top: 30px;
    @include flex-grid-size(10);
    @include grid-column-position(center);
  }

  > .category-hero__header-icon-container {
    height: 32px;
    width: 32px;
    margin-right: 10px;
    display: none;
  }
  .category-hero__description {
    font-size: 20px;
    font-family: font(freight-text);
    font-weight: 300;
  }
}

.category-hero__article {
  @include flex-grid-size(12);
  color: white;
  background-color: white;

  @include breakpoint(large) {
    @include flex-grid-size(10);
    @include grid-column-position(center);
  }

  // &:focus,
  // &:hover {
  //   .news-lockup__title {
  //     text-decoration: underline;
  //   }
  // }
}

.category-hero__interior--no-decoration {
  .category-hero__header-wrapper {
    @include breakpoint(large) {
      @include flex-grid-size(12);
    }
  }

  .category-hero__article {
    @include breakpoint(large) {
      @include flex-grid-size(12);
    }
  }
}

.category-hero__title {
  @include text-heading-2;
  font-weight: 100;
}

.category-hero__article-media {
  height: 0;
  position: relative;
  overflow: hidden;

  .dependency-video__background {
    position: initial;
    top: 0;
    height: 100%;
    width: 100%;

    .dependency-video__video {
      top: 0;
    }
  }

  .dependency-video__video-button {
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.category-hero__article-media--video {
  padding-top: calc((9 / 16) * 100%);
}

.category-hero__article-media--figure {
  /*padding-top: calc((3 / 4) * 100%);*/
  padding-top: calc((1 / 2) * 100% + 5%);
}

.category-hero__lockup-wrapper {
  padding: 20px;
  color: #282828;

  @include breakpoint(medium) {
    padding: 17px 23px;
  }

  &::after {
    display: block;
    content: "";
    height: 2px;
    background-color: #ccc9b8;
    margin-top: 24px;
  }

  > div {
    margin-top: 16px;
  }

  @include breakpoint(large) {
    padding-left: 0;
    padding-right: 0;
  }
  @include breakpoint(large) {
    padding-left: 0;
    padding-right: 0;
  }

  .news-lockup__headline {
    font-family: font(proxima-nova);
    font-size: rem(16);
    line-height: rem(15);
    letter-spacing: rem(0.86);
    font-style: normal;
    color: #333;
  }
  .news-lockup__title {
    font-family: font(proxima-nova);
    font-size: rem(26);
    line-height: rem(30);
    font-weight: bold;
    color: #c41230;

    .news-lockup__title-link{
      text-decoration: none;

      &:hover{
        text-decoration: none;
        &::after{
          transform: rotate(45deg) translateY(-10px) translateX(7px);
        }

      }
      &::after{
        content: "";
        display: inline-block;
        width: 11px;
        height: 11px;
        border-right: 3px solid #c41230;
        border-top: 3px solid #c41230;
        transform: rotate(45deg) translateY(-6px) translateX(3px);
        transition: all .2s ease;

      }
    }
  }

  .news-lockup__body {
    font-family: font(proxima-nova);
    font-size: rem(16);
    line-height: rem(24);
  }
  .news-lockup__byline {
    display: flex;
    align-items: baseline;
  }

  .news-lockup__byline--text {
    color: color(dark-gray);
    font-family: font(proxima-nova);
    font-size: rem(16);
    line-height: rem(24);
    letter-spacing: rem(0.86);
  }
  .news-lockup__byLine--divider {
    color: color(dark-gray);

    margin: 0 1ch;
  }
}

.category-hero__article-image {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 100%;
  object-fit: cover;
}

.category-hero__article-figure-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  padding: space(6);
  color: white;

  &::before {
    background: linear-gradient(
      to bottom,
      color(transparent) 0%,
      color(black-overlay-light) 100%
    );
    height: calc(100% - 75px);
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: auto;
    content: "";
    // z-index: 1;

    @include breakpoint(large) {
      height: 125px;
      top: calc(100% - 75px - 125px);
    }
  }

  &::after {
    background-color: color(black-overlay-light);
    width: 100%;
    height: 75px;
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;

    @include breakpoint(large) {
      height: 75px;
    }
  }
}

.category-hero__article-figure-caption--heading {
  @include text-heading-5;
}

.category-hero__article-figure-caption--copy {
  @include text-body-small;
  position: relative;
  font-weight: 300;

  .category-hero__article-figure-caption--heading + & {
    margin-top: space(5);
  }
}

.category-hero__lockup-byLine-author-text {
  font-weight: 600;
}

/*===== IF NO FEATURED ARTICLE IS ATTACHED =====*/

.no-featured-article {
  background: {
    color: color(red);
    // image: none;
    size:cover;
  }
  margin-bottom: space(15);
  @include breakpoint(large) {
    padding-top: space(15);
    padding-bottom: space(30);
  }

  .category-hero__side-decoration {
    display: none;
  }

  .category-hero__header-wrapper {
    @include flex-grid-size(12);
    align-items: flex-start;
  }
}
.no-featured-article-gallery{
  margin-bottom: -100px;
  padding-bottom: 120px;
	padding-top: 60px;
}

// .category-hero__article-link:hover
//   > .category-hero__lockup-wrapper
//   > .category-hero__lockup-title {
//   text-decoration: underline;
// }
// .category-hero__article-link:focus
//   > .category-hero__lockup-wrapper
//   > .category-hero__lockup-title {
//   text-decoration: underline;
// }
