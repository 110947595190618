.news-video-article {
  // &__container {
  // }
  &__link {
    &--card-link {
      position: relative;
    }
    // .news-video-card-group__flex-wrapper {
    // }
    // &:focus {
    // 	@include focus-red;
    // 	.news-video-card-group__flex-wrapper{
    // 		@include focus-red;

    // 	}
    // }
    // &:focus,
    // &:hover {
    // 	.news-video-article__lockup-block {
    // 		.news-lockup__title {
    // 			text-decoration: underline;
    // 		}
    // 	}
    // }
  }
  &__lockup-block {
    padding: 0 20px;

    @include breakpoint(large) {
      padding: 0 20px 30px 20px;
    }

    .news-lockup__headline,
    .news-lockup__title,
    .news-lockup__body,
    .news-lockup__byline {
      margin-bottom: rem(10);
    }
    .base-cta-text {
      margin-bottom: rem(20);
    }
    .news-lockup__headline {
      @include news-card-category;
      color: #7e6d53 !important;
	    font-style: normal !important;

      &:focus-within {
        outline: unset;
        outline-offset: unset;
      }

      a{
        color: #7e6d53 !important;

        &:focus{
          outline: 2px dotted #c41230;
          outline-offset: 4px;
       }
      }
    }
    .news-lockup__headline-spotlight{
      @include news-card-category;
      color: #7e6d53 !important;
	    font-style: normal !important;

      a{
        color: #7e6d53 !important;

        &:hover{
          text-decoration: underline;
        }
      }
    }
    .title-desc {
      font-weight: 800;
    }
    .news-lockup__title {
      font-family: font(proxima-nova);
      font-size: rem(20);
      font-weight: 700;
      line-height: rem(28);
      a{
        color: #c41230;
        font-style: normal;
	      text-decoration: none;

        &:hover{
          text-decoration: none;
          &::after{
            transform: translateY(-16px) rotate(45deg) translateX(20px);
          }
        }
        &::after{
          align-self: flex-end;
          background-color: transparent;
          border-right: 2px solid #c41230;
          border-top: 2px solid #c41230;
          content: "";
          display: inline-block;
          height: 9px;
          margin-right: -24px;
          transform: translateY(-100%) rotate(45deg) translateX(10px);
          transition: all .2s ease;
          width: 9px;
        }
      }
    }
  }
  &__video-block {
    margin-bottom: 16px;
    display: block;
    &:focus {
      @include focus-red;
    }

    .dependency-video__video-button {
      margin: auto;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
  &__video-image {
    object-fit: cover;
  }
  &__video-play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    // transform: translate(-50%, -50%);
    width: 25%;
    fill: white;
  }
}

.news-video-article__link--card-link {
  .news-lockup__body,
  .news-lockup__body-link {
    position: relative;
    z-index: 99;
  }
}

.news-video-article__title--card-link {
  // background-color: green;
  & a::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
