.news-category-group {
  background-image: linear-gradient(to bottom, #fff, #fff 10%, color(red) 10%);
  @include breakpoint(medium) {
    background-image: linear-gradient(
      to bottom,
      #fff,
      #fff 25%,
      color(red) 25%
    );
  }

  .news-category-group__image-overlay {
    // background-color: rgba(0, 0, 0, 0.5);
    background: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0) 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .news-category-group__image-overlay--hero {
    display: none;

    @include breakpoint(medium) {
      display: block;
    }
  }

  &__cta {
    margin-left: auto;
    margin-bottom: rem(24);
    margin-top: -25px;
    .base-cta-text {
      margin-top: rem(14);
    }
    @include breakpoint(medium) {
      width: 100%;
      justify-content: flex-end;
      @include create-news-row;
      flex: 1 1 auto;
      .base-cta-text {
        margin-top: rem(24);
        float: right;
      }
    }
  }
  &__feature-story-block {
    @include flex-grid-row;
    flex-wrap: column wrap;
    width: 100%;

    @include breakpoint(medium) {
      flex: 0 0 26.2%;
      margin-right: get-news-row-margin(medium);
    }

    @include breakpoint(large) {
      margin-right: get-news-row-margin(large);
    }

    @include breakpoint(xlarge) {
      margin-right: calc((100vw - 1064px) / 2);
      // margin-right: auto;
    }
    @include breakpoint(xxlarge) {
      // 1064
      margin-right: calc((100% - 1170px) / 2);
      // margin-right: auto;
    }
  }
  &__feature-story-caption {
    @include flex-grid-size(12);
    @include grid-column-position(1);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    padding: 27px 25px;
    color: color(white);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  &__feature-story-caption-copy {
    margin-top: 8px;
    @include news-subcategory-title;
    font-style: normal;
    &:focus-within {
      @include focus-white;
    }

    a:hover,
    a:focus {
      text-decoration: underline;
      outline: 0;
    }
  }
  &__feature-story-caption-heading {
    @include news-card-category-ko;
    padding: 5px 10px;
    background-color: color(red);
    &:focus-within {
      @include focus-white;
    }

    a:hover,
    a:focus {
      text-decoration: underline;
      outline: 0;
    }
  }
  &__feature-story-figure {
    width: 100%;
    position: relative;
    margin-bottom: rem(23);

    @include breakpoint(medium) {
      margin-top: 0;
      margin-right: 0;
      margin-left: 0;
    }
  }
  &__header {
    @include text-heading-2;
    font-weight: 800;
    color: #c41230;
  }
  &__header-wrapper {
    @include breakpoint(medium) {
      width: 100%;
      margin-right: get-news-row-margin(medium);
      margin-left: get-news-row-margin(medium);
      margin-bottom: rem(30);
    }

    @include breakpoint(large) {
      margin-right: get-news-row-margin(large);
      margin-left: get-news-row-margin(large);
    }

    @include breakpoint(xlarge) {
      max-width: rem(1064);
      margin-right: auto;
      margin-left: auto;
    }

    @include breakpoint(xxlarge) {
      width: 90%;
      max-width: rem(1170);
    }
  }
  &__feature-story-figure,
  &__hero-block {
    // &:focus {
    //   @include focus-white;
    // }

    // &:focus,
    // &:hover {
    //   .news-category-group__hero-title {
    //     text-decoration: underline;
    //   }
    // }
  }
  &__hero-block {
    @include breakpoint(medium) {
      flex: 0 0 55.5%;
      max-width: 55.5%;
      position: relative;
      height: calc((3 / 4) * 100%);
    }
  }
  &__hero-caption-block {
    @include flex-grid-row;
    background-color: color(red);
    padding-top: rem(11);

    @include breakpoint(medium) {
      background-color: transparent;
      @include flex-grid-row;
      position: absolute;
      bottom: 0;
    }
  }
  &__hero-copy-container {
    flex: 1;

    padding-left: 8px;
    margin-bottom: rem(46);
    @include breakpoint(medium) {
      flex: 1;
      padding-left: 0;
      transform: translateX(calc((-1 / 12) * 100%));
      -webkit-transform: translateX(calc((-1 / 12) * 100%));
      -moz-transform: translateX(calc((-1 / 12) * 100%));
      -ms-transform: translateX(calc((-1 / 12) * 100%));
      -o-transform: translateX(calc((-1 / 12) * 100%));
    }
  }
  &__hero-copy {
    @include news-body-large--desktop;
    color: color(white);
  }
  &__hero-image {
    object-fit: cover;
  }
  &__hero-image-wrapper {
  }
  &__hero-title {
    &:focus-within {
      @include focus-white;
    }

    a:hover,
    a:focus {
      text-decoration: underline;
      outline: 0;
    }

    @include news-category-title;
    color: color(white);
    font-size: 36px;
    line-height: 42px;
    @include breakpoint(medium) {
      margin-bottom: rem(11);
    }
  }

  &__inner {
    @include breakpoint(small down) {
      @include create-news-row;
    }
    @include breakpoint(medium) {
      @include flex-grid-row;
      @include news-heading-3--desktop;
      max-width: unset;
    }
    @include breakpoint(xxlarge) {
      max-width: 1440px;
      margin: 0 auto;
    }
  }

  &__middle-decoration {
    flex: 0 0 9%;
    max-width: 9%;
    @include breakpoint(medium) {
      margin-top: 10%;
      flex: 1 1;
      max-width: unset;
    }
  }
  &__middle-decoration-bar {
    width: 1px;
    background-color: white;
    height: 100%;
    margin: 0 auto;
  }

  &__side-decoration {
    @include flex-grid-size(1.5);
    display: flex;
    margin-right: 8px;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    @include breakpoint(small down) {
      @include grid-column-position(1);
    }

    @include breakpoint(medium) {
      margin-left: get-news-row-margin(medium);
      margin-right: 30px;
    }
    @include breakpoint(large) {
      margin-left: get-news-row-margin(large);
    }
    @include breakpoint(xlarge) {
      margin-left: calc((100vw - 1064px) / 2);
    }
    @include breakpoint(xxlarge) {
      margin-left: 135px;
    }
  }
  &__side-decoration-bar {
    width: 1px;
    background-color: color(white);
    height: 100%;
    @include breakpoint(medium) {
      height: 100%;
      margin-bottom: -122px;
    }
  }
  &__side-decoration-icon {
    fill: color(white);
  }
  &__side-decoration-icon-wrapper {
    width: 100%;
    max-width: 46px;
  }
}
