.ctaArrow{
  a{
    @include text-cta-default;
    position: relative;
    display: inline-block;
    padding: space(3) space(4) space(3) 0;
    margin-top: space(5);
    color: color(black) !important;
    font-style: normal !important;
    text-decoration: none !important;
  
    &:focus {
      @include focus-red;
    }
  
    &.base-cta-text--light {
      color: color(white);
  
      &:focus {
        @include focus-white;
      }
    }
  
    &::before {
      position: absolute;
      top: 50%;
      right: 0;
      display: block;
      align-self: flex-end;
      width: space(2.25);
      height: space(2.25);
      background-color: transparent;
      content: "";
      border-top: 2px solid color(red);
      border-right: 2px solid color(red);
      transform: translateY(-50%) rotate(45deg);
      transition: all 0.2s ease;
    }
  
    // &.base-cta-text--light::before {
    //   //background-color: color(white);
    // }
    &:hover {
      &::before {
        transform: translate(5px, -50%) rotate(45deg) scale(1);
      }
    }
  }
}

.ctaButton{
  position: relative;
  transform: translate(6px, 6px);
  outline: 2px solid #c41230;
  outline-offset: -2px;

  @include breakpoint(large) {
    width: 200px;
  }
  
  a{
    background-color: #c41230;
    color: #fff !important;
    transform: translate(-6px, -6px);
    font-style: normal !important;
    text-decoration: none !important;
    display: block;
    font-family: proxima-nova,sans-serif;
    font-size: rem(16);
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: rem-calc(1);
    padding: 20px 40px 20px 20px;
    position: relative;
    text-transform: capitalize;
    z-index: 1;
    transition-duration: .3s !important;
    transition-property: transform !important;
    transition-timing-function: cubic-bezier(.4,0,.2,1) !important;

    @include breakpoint(large) {
      width: 200px;
    }

    &:hover{
      transform: translate(0px, 0px);
    }

    &::after{
      content: "";
      display: block;
      position: absolute;
      width: 10px;
      height: 10px;
      border-right: 2px solid #fff;
      border-top: 2px solid #fff;
      right: 20px;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
    }

    &:focus {
      outline: 2px dotted #c41230 !important;
      outline-offset: 8px !important;
      transform: translate(0px, 0px);
    }
 }
}