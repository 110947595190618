.carousel-cards {
  padding-top: space(15);
  padding-bottom: space(15);

  .carousel-cards__wrap--heading {

    .carousel-cards__header-inner {
      flex: 0 0 66.6666666667%;
      max-width: 66.6666666667%;
      padding-bottom: 12px;

      .carousel-cards__heading {
        font-size: 38px;
        line-height: 44px;
        font-weight: 300;
        margin-bottom: 10px;
        margin-top: 30px;
      }
      .carousel-cards__header-subheading {
        font-family: font(freight-text);
        font-size: 24px;
        line-height: 36px;
        font-weight: 300;
        letter-spacing: .015625rem;
        padding-right: .9375rem;
        padding-left: .9375rem;
      }
    }
    
    .carousel-cards__header_cta-container {
      align-self: center;
      display: flex;
      flex: 0 0 33.3333333333%;
      justify-content: flex-end;
      max-width: 33.3333333333%;
      padding-bottom: 12px;
      padding-top: 0;
    }
  }

  .button-skip-content__wrap {
    @include create-row;
  }

  .button-skip-content {
    @include flex-grid-column(12);
    width: 1px;
    height: 1px;
    position: absolute;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    word-wrap: normal;

    &:focus,
    &:active {
      visibility: visible;
      position: static;
      overflow: visible;
      // max-width: 1170px;
      clip: auto;
      width: auto;
      height: auto;
      display: block;
    }
  }
}

.carousel-cards__inner {
  overflow: hidden;
  width: 100%;
}

.carousel-cards__wrap {
  @include create-row;
}

.carousel-cards__heading {
  @include flex-grid-column(12);
  @include text-heading-2;
  color: color(red);
  text-align: left;
}

.carousel-cards__item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-cards__list {
  display: inline-flex;
  align-items: flex-end;
  height: 360px;

  margin: {
    top: 40px;
    bottom: 40px;
  }

  @include breakpoint(medium) {
    height: 500px;
  }
}

.carousel-cards__list--no-top-margin {
  margin-top: 0px;
}

.carousel-cards__list-item {
  $_space: space(3.75);
  margin-left: $_space;

  @include breakpoint(medium) {
    margin-left: calc(#{$_space} * 2);
  }

  &:hover {
    cursor: pointer;
  }
}

.carousel-cards__top-controls {
  @include flex-grid-column(12);
  @include text-heading-2;
  $_space: space(3.75);
  margin-top: 15px;
  margin-bottom: 35px;
  line-height: 0;

  @include breakpoint(medium) {
    display: block;
    line-height: 0;
  }

  .carousel-secondary__slider {
    text-align: left;
  }
}

.carousel-container__item-overlay {
  // &::before {
  //   background: linear-gradient(
  //     to bottom,
  //     color(transparent) 0%,
  //     color(black-overlay-light) 100%
  //   );
  //   height: calc(100% - 75px);
  //   width: 100%;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   bottom: auto;
  //   content: "";
  //   z-index: 1;

  //   @include breakpoint(large) {
  //     height: calc(100% - 125px);
  //   }
  // }

  &::after {
    background-color: color(black-overlay-light);
    width: 100%;
    height: 75px;
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    // z-index: 1;

    @include breakpoint(large) {
      height: 220px;
      background: linear-gradient(
      to bottom,
      color(transparent) 0%,
      rgba(0,0,0,0.9) 100%
      );
    }
  }
}

.carousel-container__item-content {
  // position: relative;
  // display: none;
  $_padding: 25px;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;

  padding: {
    right: $_padding;
    bottom: $_padding;
    left: $_padding;
  }

  .carousel-cards__list-item--isActive & {
    $_padding: 25px;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;

    padding: {
      right: $_padding;
      bottom: $_padding;
      left: $_padding;
    }
  }
}

.CarouselContainerListItem {
  position: relative;
  width: 325px;
  height: 285px;
  background-color: color(red);

  @include breakpoint(medium) {
    // width: 430px;
    // height: 400px;
    width: 530px;
    height: 500px;
  }
}

.carousel-container__content-heading {
  @include text-heading-5;
  color: color(white) !important;
  margin-bottom: space(5);
  display: inline-block;
  font-size: 22px;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.4);
  letter-spacing: rem-calc(0.7);

  &:focus {
    @include focus-white;
  }

  a{
    &::after{
      display: inline-block;
      width: space(2.25);
      height: space(2.25);
      align-self: flex-end;
      background-color: transparent;
      content: "";
      border-top: 2px solid color(white);
      border-right: 2px solid color(white);
      transform: translateY(-100%) rotate(45deg) translateX(10px);
      transition: all 0.2s ease;
    }
  
    &:hover{
      &::after{
        transform: translateY(-16px) rotate(45deg) translateX(20px);
      }
    }
  }
}

.carousel-container__content-text {
  @include news-cards-text-body-small;
  color: color(white);
  font-weight: 300;
  margin: 0;
  display: none;
  // margin-bottom: space(5);

  &:focus {
    @include focus-white;
  }
  a {
    color: color(white);
    text-decoration: underline;
    font-style: italic;
    outline:2px dotted transparent;
    outline-offset:0;
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
      &:hover {
        text-decoration: none;
      }
      &:focus {
        text-decoration:none;
        outline:2px dotted color(white);
      }
    }
    
  .carousel-cards__list-item--isActive & {
    display: block;
  }
}

.carousel-cards__wrap-inner {
  @include flex-grid-column(12);
}

.carousel-cards__inner-wrap {
  display: none;

  @include breakpoint(large) {
    position: relative;
    display: block;
  }

  &:hover .carouse-cards__slider-indicator {
    background-color: color(red);
    $_size: 12px;
    width: $_size;
    height: $_size;
    top: -3px;
  }
}

$_slider-color: color(gold-accent);

.carouse-cards__slider-indicator-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% - 40px);
  height: 5px;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.carousel-cards__slider-indicator-rail {
  position: absolute;
  height: 1px;
  width: 100%;
  background-color: $_slider-color;
  top: 2px;
}

.carouse-cards__slider-indicator {
  transition: all 1s ease;
  $_size: 10px;
  top: -2px;
  position: absolute;
  width: $_size;
  height: $_size;
  border-radius: 50%;
  background-color: $_slider-color;
}

.carousel-cards__slider {
  // width: calc(100% - 30px);
  width: 100%;
  height: 36px;
  background-color: color(transparent);
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  padding: 0px;
  // margin-left: 20px;
}

.carousel-cards__slider::-webkit-slider-thumb {
  position: relative;
  width: 40px;
  height: 32px;
  background-image: url("../svgs/functional/icon-range-thumb.svg");
  appearance: none;
  -webkit-appearance: none;
  z-index: 100000;
}

.carousel-cards__slider::-moz-range-thumb {
  position: relative;
  width: 42px;
  height: 34px;
  background-image: url("../svgs/functional/icon-range-thumb.svg");
  appearance: none;
  -webkit-appearance: none;
  z-index: 100000;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  background-color: transparent;
}

.carousel-cards__slider:focus::-moz-range-thumb {
  @include focus-red;
  // outline: none;
}

input[type="range"]::-moz-focus-outer {
  border: 0;
}

.carousel-cards__slider:focus::-webkit-slider-thumb {
  @include focus-red;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-ms-track {
  background: transparent;
  border-color: transparent;
  color: black;
}

input[type="range"]::-ms-thumb {
  background: red;
  // border-color: transparent;
  color: red;
  width: 40px;
  height: 32px;
  background-image: url("../svgs/functional/icon-range-thumb.svg");
  background-repeat: no-repeat;
  margin-left: 2px;
  margin-right: 2px;
  border: 4px solid transparent;
  outline: 2px dashed transparent;
  padding: 2px;
}

input[type="range"]:focus::-ms-thumb {
  border: 4px solid transparent;
  outline: 2px dashed red;
  padding: 2px;
}

input[type="range"]::-ms-fill-upper {
  background: transparent;
}

input[type="range"]::-ms-fill-lower {
  background: transparent;
}

input[type="range"]::-ms-tooltip {
  display: block;
}

@media screen and (-ms-high-contrast: none) {
  .carousel-cards__slider {
    height: 48px;
    width: 100%;
    margin-left: 0px;
  }
}

@supports (-ms-ime-align: auto) {
  .carousel-cards__slider {
    height: 48px;
  }
}

.carousel-cards__wrap-mobile {
  position: relative;
  padding-top: space(7.5);
  padding-bottom: space(7.5);

  @include breakpoint(medium) {
    position: relative;
    bottom: space(3);
    padding-bottom: 0;
    padding-top: space(10);
  }

  @include breakpoint(large) {
    position: absolute;
    bottom: space(10);
    left: space(20);
  }

  @include breakpoint(xlarge) {
    left: space(30);
  }
}

.carousel-cards__inner-wrap-mobile {
  width: 100%;
  background-color: color(transparent);
  appearance: none;
  outline: none;
  text-align: center;

  @include breakpoint(large) {
    text-align: left;
  }
}

.carousel-cards__slider-mobile {
  width: 100%;
  background-color: color(transparent);
  appearance: none;
  outline: none;
  text-align: center;

  @include breakpoint(large) {
    text-align: left;
    display: none;
  }

  input {
    outline: none;
    border: none;
    position: relative;
    width: 30px;
    height: 30px;
    appearance: none;
    margin-right: 5px;
    background-color: color(red);
    background-repeat: no-repeat;
    background-size: auto;
    cursor: pointer;
    color: rgba(0, 0, 0, 0);
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;

    &:first-child {
      background-image: url("../svgs/functional/icon-arrow-left--white.svg");
    }

    &:last-child {
      background-image: url("../svgs/functional/icon-arrow-right--white.svg");
    }

    &:disabled {
      background-color: rgba(color(red-accent), 0.5);
    }

    &:focus {
      @include focus-red;
    }

    &:hover {
      background-color: color(red-accent);
    }

    background-position: center;
  }
}
