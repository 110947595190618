.post-photo-gallery {
  @include create-news-row;
  position: relative;
  &__carousel-button {
    padding: 20px;
    &--next {
    }
    &--previous {
      transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
      -moz-transform: scaleX(-1);
      -ms-transform: scaleX(-1);
      -o-transform: scaleX(-1);
    }
    &:focus {
      img {
        @include focus-red;
      }
    }
  }
  &__carousel-buttons-container {
  }
  &__carousel-buttons-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    max-width: unset;
    padding-left: 0;
    padding-right: 0;
  }
  &__carousel-container {
    @include grid-column-position(center);
    @include flex-grid-size(12);
    @include breakpoint(medium) {
      @include flex-grid-size(6);
    }
    position: relative;
    height: 100%;
  }
  &__carousel-item {
    height: 100%;
    width: 100%;

    > div {
      height: 0;
      width: 100%;
      position: relative;
      padding-top: 73%;

      > p {
        font-family: font(proxima-nova);
        font-size: 12px;
        font-weight: 600;
        line-height: 24px;
        font-style: italic;
        color: color(red);
        border-bottom:2px solid color(grey);
        padding-top:10px;
        padding-bottom:10px;
      }
    }
  }
  &__carousel-item-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  &__carousel-item-list {
    height: 100%;
    width: 100%;
  }
  &__divider-bar {
    height: 2px;
    width: 100%;
    background-color: color(grey);
    margin-bottom: rem(20);
  }
  &__model-button {
    position: absolute;
    // height: 5%;
    width: 5%;
    z-index: 99;
    top: 15%;
    left: 5%;
    &:hover,
    &:focus {
      @include focus-white;
    }
  }
  &__model-image-wrapper {
    position: relative;
    padding-top: 100%;
    width: 100%;
    display: block;
  }
  &__model-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 99;
  }
  &__modal-wrapper {
    @include create-news-row;
    // height: 100%;
    display: flex;
    align-items: center;
    color: white;

    .post-photo-gallery__carousel-item-list .post-photo-gallery__carousel-item p {
      color:white;
      border-bottom:none;
    }
  }
  &__title-wrapper {
    @include news-caption-title;
  }
}

.ModalContainerDialog {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: black;
  z-index: 101;
  .post-photo-gallery__divider-bar {
    background-color: color(white);
  }
  .post-photo-gallery__carousel-container {
    @include flex-grid-size(12);
  }

  .post-photo-gallery__carousel-container {
    height: unset;
  }

  .post-photo-gallery__carousel-button {
    > img {
      filter: invert(1);
      -webkit-filter: invert(1);
    }
  }
}
@media (min-aspect-ratio: 3/4){
  .post-photo-gallery__modal-wrapper{
    max-width: 120vh;
  }
}
