.feature-image {
  padding-bottom: space(20);
  background-color: color(red);

  @include breakpoint(medium) {
    padding-bottom: space(15);
  }

  @include breakpoint(small-desktop) {
    padding-top: space(20);
    padding-bottom: space(20);
  }

  .feature-image__container {
    position: relative;

    @include breakpoint(small-desktop) {
      &::after {
        content: " ";
        position: absolute;
        width: 1px;
        height: calc(100% - 105px);
        background-color: #fff;
        top: 90px;
        right: calc(17% - 40px);
      }
    }

    .feature-image__inner {
      position: relative;
      width: 100%;
      padding-top: 40px;

      //height: 475px;
      //padding: space(4) space(8);

      .feature-image__heading__icon {
        display: none;

        @include breakpoint(960px) {
          display:block;
        }
      }

      @include breakpoint(small-desktop) {
        width: 83%;
        height: 600px;
        padding: space(8) space(11);

        .feature-image__heading__icon {
          content: " ";
          position: absolute;
          width: 50px;
          height: 50px;
          top: 0;
          right: -65px;


          &.flip {
            -moz-transform: scaleX(-1);
            -o-transform: scaleX(-1);
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            filter: FlipH;
            -ms-filter: "FlipH";
          }
        }

        &::after {
          content: " ";
          position: absolute;
          width: 15px;
          height: 15px;
          bottom: 0px;
          right: -47px;
          background-image: url("../svgs/functional/icon-list-style--white.svg");
          background-repeat: no-repeat;
          background-size: contain;
        }
      }

      .feature-image__gradient-wrapper {
        @include breakpoint(small-desktop) {
          &::before {
            background: linear-gradient(to bottom, color(transparent) 0%, color(black-overlay-light) 100%);
            height: calc(100% - 125px);
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            bottom: auto;
            content: '';
            z-index: 1;
          }

          &::after {
            background-color: color(black-overlay-light);
            width: 100%;
            height: 125px;
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 1;
          }
        }
      }

      .feature-image__image {
        padding-bottom: space(5);
        padding-right: space(6.25);
        width: calc(100% - 25px);
        object-fit: cover;

        @include breakpoint(small-desktop) {
          @include adhere;
          padding-bottom: 0;
          padding-right: 0;
        }
      }

      .feature-image__text-container {
        @include flex-grid-column(11);
        margin: 0 auto;

        @include breakpoint(small-desktop) {
          @include flex-grid-column(12);
          @include flex-grid-size(10.5);
          position: absolute;
          bottom: space(10);
          z-index: 9;
        }

        position: relative;

        .feature-image__heading {
          @include text-heading-2;

          @include breakpoint(small-desktop) {
            @include text-heading-3;
          }

          color: color(white);
          padding-bottom: space(3.75);

          @include breakpoint(large) {
            padding-bottom: space(4);
          }
        }

        .feature-image__copy {
          @include text-body-regular;
          color: color(white);

          a {
            color: color(white);
            text-decoration: underline;
            font-style: italic;
            outline:2px dotted transparent;
            outline-offset:0;
            transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
            &:hover {
               text-decoration: none;
            }
            &:focus {
               text-decoration:none;
               outline:2px dotted color(white);
            }
          }
          h6 {
            @include text-heading-6;
            margin-bottom: space(3.75);
            margin-top: space(11.25);
            color: color(white);
          }
          h5 {
            @include text-heading-5;
            margin-bottom: space(3.75);
            margin-top: space(11.25);
            color: color(white);
          }
          h4 {
            @include text-heading-4;
            margin-bottom: space(3.75);
            margin-top: space(11.25);
            color: color(white);
          }
          h3 {
            @include text-heading-3;
            margin-bottom: space(3.75);
            margin-top: space(11.25);
            color: color(white);
            &.table-header {
              font-family: font(proxima-nova);
              font-size: rem(20);
              line-height: 1.3;
              font-weight: 500;
              margin-bottom: space(5);
              @include breakpoint(large) {
                font-size: rem(24);
                line-height: 1.25;
              }
            }
          }
          p {
            margin-bottom: space(5);
          }
          ul {
            margin-bottom: space(5);
            li {
              padding: space(2) 0 space(2) space(7.5);
              &:before {
                content: "";
                background-image: url("../svgs/functional/icon-list-style--white.svg");
                background-repeat: no-repeat;
                background-size: contain;
                width: 10px;
                height: 10px;
                display: inline-block;
                margin-right: space(5);
                margin-left: space(-7.5);
              }
            }
          }
          ol {
            list-style: none;
            counter-reset: my-awesome-counter;
            margin-bottom: space(5);
            li {
              padding: space(2) 0;
              counter-increment: my-awesome-counter;
              padding-left: space(9);
              &:before {
                content: counter(my-awesome-counter);
                font-family: font(proxima-nova);
                color: color(white);
                font-size: rem(24);
                font-weight: 400;
                letter-spacing: 0.75px;
                margin-right: space(4);
                text-align: center;
                display: inline-block;
                width: 20px;
                vertical-align: middle;
                margin-left: space(-9);
              }
            }
          }
        }
      }
    }
  }
}
