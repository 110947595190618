.context-list {
  padding-top: space(10);
  padding-bottom: space(10);
  background-color: color(white);

  &.context-list--gold {
    background-color: color(gold);
  }
  &.context-list--red {
    background-color: color(red);
    .context-list__article-cta {
      .base-cta-text {
        &:before {
          border-top: 2px solid color(white);
          border-right: 2px solid color(white);
        }
      }
    }
  }

  @include breakpoint(medium) {
    padding-top: space(15);
    padding-bottom: space(15);
  }

  @include breakpoint(large) {
    padding-top: space(25);
    padding-bottom: space(25);
  }
}

.context-list__inner {
  @include create-row;
  article:last-of-type {
    padding-bottom: 0;
    .context-list__article-inner {
      padding-bottom: 0;
    }
  }
  .context-list__lockup-container {
    .base-lockup__copy--large {
      margin-top: space(3.75);
      @include breakpoint(large) {
        margin-top: space(6);

        p {
          margin-bottom:space(6);
        }
      }
    }
  }
}

.context-list--vertical {
  .context-list__lockup-container {
    @include flex-grid-column(12);
    padding-bottom: space(10);

    @include breakpoint(large) {
      @include flex-grid-size(5);
      padding-bottom: space(12.5);
    }
  }

  .context-list__articles {
    @include flex-grid-column(12);

    @include breakpoint(large) {
      @include flex-grid-size(7);
    }
  }

  .context-list__article-inner {
    padding-bottom: space(10);
    width: 100%;

    @include breakpoint(large) {
      padding-bottom: space(12.5);
    }
  }
}

.context-list--horizontal {
  .context-list__lockup-container {
    padding-bottom: space(10);
    @include flex-grid-column(12);

    @include breakpoint(large) {
      padding-bottom: space(12.5);
    }
  }

  .context-list__article {
    @include flex-grid-column(12);
    padding-bottom: space(10);

    @include breakpoint(large) {
      padding-bottom: 0;

      &.context-list__article--2 {
        @include flex-grid-size(6);
      }
      &.context-list__article--3 {
        @include flex-grid-size(4);
      }
      &.context-list__article--4 {
        @include flex-grid-size(3);
      }
    }
  }
}

.context-list--vertical {
  .context-list__article {
    @include breakpoint(large) {
      display: flex;
    }
  }
}
.context-list__article-icon-container {
  min-width: 26px;
  margin-bottom: space(7);

  .context-list--vertical & {
    min-width: 34px;
  }
  @include breakpoint(large) {
    margin-right: space(7);
  }
}

.context-list__article-icon {
  width: 26px;
  min-width: 26px;

  .context-list--vertical & {
    width: 34px;
    min-width: 34px;
  }
}

.context-list__article-image {
  width: 100%;

  .context-list--vertical & {
    width: 100%;
  }
}

.context-list__article-headline {
  @include text-heading-5;
  color: color(black);

  &.context-list__article-headline--red {
    color: color(red);

    a {
      display:inline-block;
      width:100%;

      &:focus {
        outline: 2px dotted color(red);
        outline-offset: 4px;
      }

      &:hover {

        &:after {
          transform:translateY(-16px) rotate(45deg) translateX(20px);
        }
      }

      &:after {
        display: inline-block;
        align-self: flex-end;
        width: space(2.25);
        height: space(2.25);
        background-color: transparent;
        content: "";
        border-top: 2px solid color(red);
        border-right: 2px solid color(red);
        transform: translateY(-100%) rotate(45deg) translateX(10px);
        transition: all 0.2s ease;
        margin-right:space(-6);
      }
    }
  }

  &.context-list__article-headline--white {
    color: color(white);

    a {
      display:inline-block;

      &:focus {
        outline: 2px dotted color(white);
        outline-offset: 4px;
      }

      &:hover {
        &:after {
          transform:translateY(-16px) rotate(45deg) translateX(20px);
        }
      }

      &:after {
        display: inline-block;
        align-self: flex-end;
        width: space(2.25);
        height: space(2.25);
        background-color: transparent;
        content: "";
        border-top: 2px solid color(white);
        border-right: 2px solid color(white);
        transform: translateY(-100%) rotate(45deg) translateX(10px);
        transition: all 0.2s ease;
        margin-right:space(-6);
      }
    }
  }
}

.context-list__article-copy {
  @include text-body-regular;
  color: color(black);
  padding-top: space(3);

  .context-list--red & {
    color: color(white);
  }

  a {
    color: color(red);
    text-decoration: underline;
    font-style: normal;
    outline:2px dotted transparent;
    outline-offset:0;
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
    &:hover {
       text-decoration: none;
    }
    &:focus {
       text-decoration:none;
       outline:2px dotted color(red);
    }
  }
  h6 {
    @include text-heading-6;
    margin-bottom: space(3.75);
    margin-top: space(11.25);
  }
  h5 {
    @include text-heading-5;
    margin-bottom: space(3.75);
    margin-top: space(11.25);
  }
  h4 {
    @include text-heading-4;
    margin-bottom: space(3.75);
    margin-top: space(11.25);
  }
  h3 {
    @include text-heading-3;
    margin-bottom: space(3.75);
    margin-top: space(11.25);
    &.table-header {
      font-family: font(proxima-nova);
      font-size: rem(20);
      line-height: 1.3;
      font-weight: 500;
      margin-bottom: space(5);
      @include breakpoint(large) {
        font-size: rem(24);
        line-height: 1.25;
      }
    }
  }
  p {
    margin-bottom: space(5);
  }
  ul {
    margin-bottom: space(5);
    li {
      padding: space(2) 0 space(2) space(7.5);
      &:before {
        content: "";
        background-image: url("../svgs/functional/icon-target--red.svg");
        background-repeat: no-repeat;
        background-size: contain;
        width: 10px;
        height: 10px;
        display: inline-block;
        margin-right: space(5);
        margin-left: space(-7.5);
      }
    }
  }
  ol {
    list-style: none;
    counter-reset: my-awesome-counter;
    margin-bottom: space(5);
    li {
      padding: space(2) 0;
      counter-increment: my-awesome-counter;
      padding-left: space(9);
      &:before {
        content: counter(my-awesome-counter);
        font-family: font(proxima-nova);
        color: color(red);
        font-size: rem(24);
        font-weight: 400;
        letter-spacing: 0.75px;
        margin-right: space(4);
        text-align: center;
        display: inline-block;
        width: 20px;
        vertical-align: middle;
        margin-left: space(-9);
      }
    }
  }
}
