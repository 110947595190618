.rfiModal {
    z-index:100;
}
.rfiModal h1 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    float: left;
    display: inline-block;
    width: 80%;
}
.rfiModal .overlay {
    background-color:rgba(0,0,0,0.5);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index:110;
}
.rfiModal .form_rfi {
    position: fixed;
    z-index:120;
}
.rfiModal .modal {
    position:fixed;
    z-index:120;
}

.rfiModal .form_rfi {
    background-color:#edece2;
    padding: 20px;
}
.rfiModal div.action.form_action > button, .form_button_submit {
    background-color: #c41230;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius:0px!important;
    text-transform:uppercase;
}
.rfiModal div.action.form_action > button:focus, .form_button_submit:focus {
    background-color: #ad102a;
}
.form_rfi {
    margin:40px auto;
    font-family: sans-serif;
    max-height:calc(100vh - 200px);
    overflow-y:auto;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    left: calc(50% - 150px); 
}

.form_rfi *:focus {
    outline-color: #c41230;
    outline-style: inset;
}

.form_rfi #form_description {
    display:none;
}

@media screen and (min-width: 480px) {
    .form_rfi {
        width: 480px;
        left: calc(50% - 240px);
    }
}

@media screen and (min-width: 768px) {
    .form_rfi {
        width: 760px;
        left: calc(50% - 380px);
    }
}

.form_rfi .form_question {
    display: block;
    box-sizing: border-box;
    margin: 0 0 1em 0;
    padding: 0;
}

.form_rfi .form_label a {
    color:#c41230!important;

    &:hover {
        color:black!important;
    }
}

.form_rfi div.form_header .form_label {
    background:#D6D2C1!important;
}

.form_rfi .form_question label, .form_rfi div.form_page label {
    font-size: 1rem;
    margin-bottom: 5px;
}
.form_rfi .form_question input, .form_rfi .form_question select {
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 10px!important;
    width: 100%!important;
}

.form_rfi .form_address input, .form_rfi .form_address select, .form_rfi .form_address textarea {
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 10px!important;
    width: 100%!important;
    margin-bottom: 1em;
}

.form_rfi .form_question input[type='checkbox'], .form_rfi .form_question input[type='radio'] {
    font-size: 1rem;
    width: 13px!important;
}

.form_rfi .form_question.form_birthdate select {
    width:30%!important;
}

.form_rfi legend {
    font-size: 1.25rem;
    margin-bottom: 1rem;
}

.form_rfi .form_birthdate legend, .form_rfi .form_checkbox legend, .form_rfi .form_radio legend {
    font-size: 1rem;
    margin-bottom: 5px;
}

#rfiCloseBtn {
    float: right;
    margin-bottom:10px;
    padding:5px;
}

#rfiCloseBtn:focus {
    color: black;
    background-color:transparent;
}