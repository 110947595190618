.accordion-primary {
  width: 100%;
  margin-top: space(3);
  margin-bottom: space(5);
}

.accordion-primary__container {
  @include create-row;
}

.accordion-primary__inner {
  @include flex-grid-column(12);
}

.accordion-primary__column {
  flex-basis: auto;
  position: relative;
  padding-top: space(2);
  padding-bottom: space(2);
  border-top: 1px solid color(gold);

  &:last-child {
    border-bottom: 1px solid color(gold);
  }

  &.accordion-primary__column--resources {
    // margin-top: space(8);

    @include breakpoint(medium) {
      margin-top: 0;
      order: 2;
    }
  }

  .accordion-primary__accordion {
    .accordion-primary__heading {
      @include text-heading-4;
      margin-bottom: 0 !important;

      .accordion-primary__button {
        // outline: none;
        width: 100%;
        text-align: left;
        @include transition(color);

        &::after {
          content: "";
          position: absolute;
          width: 20px;
          height: 1px;
          border: 1px solid color(red);
          background:color(red);
          top: 50%;
          left: 0;
          transform: rotate(0deg);
          transition: all 0.3s;
          cursor: pointer;
        }

        &::before {
          content: "";
          position: absolute;
          width: 20px;
          height: 1px;
          border: 1px solid color(red);
          background:color(red);
          top: 50%;
          left: 0;
          transform: rotate(90deg);
          transition: all 0.3s;
          cursor: pointer;
        }

        &:hover {
          color:color(red);

          &::after {
            border: 1px solid color(red-accent);
          }

          &::before {
            border: 1px solid color(red-accent);
          }
        }

        &:focus {
          background-color: transparent;
          color: currentColor;
        }

        &[aria-expanded="true"] {
          &::after {
            border: 1px solid color(red-accent);
            transform: rotate(225deg);
            background: color(red-accent);
          }

          &::before {
            border: 1px solid color(red-accent);
            transform: rotate(315deg);
            background: color(red-accent);
          }
        }

        &-label {
          display: block;
          // max-width: calc(100% - 30px);
          max-width:100%;
          margin-left:space(10);
          font-size: 24px;
	        font-weight: 500;
          line-height: 115%;
        }
      }
    }
  }
}

.accordion-primary--body {
  padding-top: space(10);
  padding-bottom: space(10);
}
