.cards-image {
  padding-top: space(8);
  padding-bottom: space(8);
  // background-color: color(white-warm);

  // &.cards-image--3 {
  //   background-color: transparent;
  // }
  &.cards-image--red {
    background-color: color(red);

    &.cards-image--2 {
      .cards-image__card-inner {
        @include breakpoint(large) {
          background-color: color(red);
        }
      }
    }
  }

  &.cards-image--warm {
    background-color: color(white-warm);

    &.cards-image--2 {
      .cards-image__card-inner {
        @include breakpoint(large) {
          background-color: color(white-warm);
        }
      }
    }
  }

  &.cards-image--white {
    background-color: color(white);

    &.cards-image--2 {
      .cards-image__card-inner {
        @include breakpoint(large) {
          background-color: color(white);
        }
      }
    }
  }

  &.cards-image--tan{
    background-color: color(gold);

    &.cards-image--2 {
      .cards-image__card-inner {
        @include breakpoint(large) {
          background-color: color(gold);
        }
      }
    }
  }
  // @include breakpoint(medium) {
  //   padding-top: space(15);
  //   padding-bottom: space(15);
  // }

  // @include breakpoint(large) {
  //   padding-top: space(15);
  //   padding-bottom: space(15);
  // }
}

.cards-image__header-container {
  @include create-row;
}

.cards-image__header-inner {
  @include flex-grid-column(12);
  align-self: center;
  justify-self: center;
  padding-bottom: space(3);

  @include breakpoint(large) {
    @include flex-grid-size(8);
    padding-bottom: space(3);
  }

  .base-lockup__copy {
    margin-top: space(3.75);
  }
}

.cards-image_header_cta-container {
  @include flex-grid-column(12);
  // display: none;
  padding-bottom: space(3);

  @include breakpoint(large) {
    @include flex-grid-size(4);
    display: flex;
    justify-content: flex-end;
    padding-top: 0px;
    padding-bottom: space(3);
    align-self: center;
  }
}

.cards-image_footer_cta-container {
  @include create-row;
  @include flex-grid-column(12);
  padding-top: space(7);

  @include breakpoint(large) {
    @include flex-grid-size(4);
    display: none;
    justify-content: flex-end;
    padding-top: 0px;
    padding-bottom: space(15);
    align-self: center;
  }
}

.cards-image__header-heading {
  @include text-heading-2;
  color: color(red);
  padding-bottom: space(5);
}

.cards-image__header-subheading {
  @include text-body-large;
  color: color(black);
}

.cards-image__cta-container {
  @include flex-grid-column(12);
  padding-bottom: space(5);
  order: 1;
  margin-top: space(-5);

  @include breakpoint(large) {
    @include flex-grid-size(3);
    order: 0;
    padding-bottom: space(15);
    align-self: center;
  }
}

// .cards-image {
//
//   .cards-image__cards-container {
//     flex-wrap:wrap;
//   }
//
//   &.cards-image--8 {
//
//     .cards-image__card {
//       flex-basis:25%;
//     }
//   }
// }

.cards-image__cards-container {
  @include create-row;
  flex-direction: column;

  @include breakpoint(large) {
    flex-direction: row;
  }
}

.cards-image__card {
  @include flex-grid-column();
  padding-top: space(6);
  padding-bottom: space(6);
}

.cards-image__card-image {
  height: 200px;
  width: 100%;
  object-fit: cover;

  .cards-image--2 &, .cards-image-multirow--2 & {
    @include breakpoint(medium) {
      height: 250px !important;
    }

    @include breakpoint(large) {
      height: 313px !important;
    }
  }
  .cards-image--3 &, .cards-image-multirow--3 & {
    @include breakpoint(medium) { 
      height: 320px;
    }

    @include breakpoint(large) { 
      height: 200px;
    }

    @include breakpoint(xxlarge) { 
      height: 260px;
    }
  }
  .cards-image--4 &, .cards-image-multirow--4 & {
    @include breakpoint(medium) { 
      height: 320px;
    }

    @include breakpoint(large) { 
      height: 160px;
    }
    
    @include breakpoint(xxlarge) { 
      height: 220px;
    }
  }

  // @include breakpoint(large) {
  //   height: 172px;
  // }

}




.cards-image__card-inner {
  .cards-image--2 & {
    @include breakpoint(large) {
      // background-color: color(white-warm);
      width: 92%;
      position: relative;
      top: space(-5);
      left: calc(8% + 1px);
      // border-bottom: 1px solid color(gold-accent);
      padding: space(6.25) space(7) 0 space(7);
      margin-bottom: -20px;

      .cards-image__card-headline {
        padding-top: 0;
      }
    }
  }

  .cards-image--3 & {
    .cards-image__card-headline {
      padding-top: space(7.5);
    }
  }
}

.cards-image__card-headline {
  @include text-heading-5;
  color: color(red);
  padding-top: space(5);
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  .cards-text__base-link-text {
    display:inline-block;
    padding-right:space(6);
    width:100%;

    &::after {
      display: inline-block;
      align-self: flex-end;
      width: space(2.25);
      height: space(2.25);
      background-color: transparent;
      content: "";
      border-top: 2px solid color(red);
      border-right: 2px solid color(red);
      transform: translateY(-100%) rotate(45deg) translateX(10px);
      transition: all 0.2s ease;
      margin-right:space(-6);
    }
    &:hover {
      &:after {
        transform:translateY(-16px) rotate(45deg) translateX(20px);
      }
    }
    &:focus {
      outline:2px dotted color(red);
      outline-offset:4px;
    }
  }
}

.cards-image__card-copy {
  @include text-body-regular;
  @include bullet-list;
  
  color: color(black);
  padding-top: space(3);
  margin-bottom: space(2);

  a {
   color: color(red);
   text-decoration: underline;
   font-style: normal;
   outline:2px dotted transparent;
   outline-offset:0;
   transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
   &:hover {
      text-decoration: none;
   }
   &:focus {
      text-decoration:none;
      outline:2px dotted color(red);
   }
  }
  h2 {
    @include text-heading-5;
    color: color(red);
    padding-top: space(2);
    padding-bottom: space(2);
  }
  h3 {
    @include text-heading-6;
    color: color(black);
    padding-top: space(2);
    padding-bottom: space(2);
  }
  h4 {
    @include text-heading-6;
    color: color(red);
    padding-top: space(2);
    padding-bottom: space(2);
  }
  h5 {
    @include text-heading-6;
    color: color(black);
    padding-top: space(2);
    padding-bottom: space(2);
  }
  h6 {
    @include text-heading-7;
    color: color(red);
    padding-top: space(2);
    padding-bottom: space(2);
  }
  p {
    margin-bottom: space(5);
  }

  @include bullet-list;
}

.cards-image__card-cta {
  // padding-bottom: space(7);
  @include breakpoint(large) {
    padding-bottom: 0px;
  }

  .base-cta-text{
    margin-top: 0;
  }

}

.cards-image__divider {
  border-bottom: 1px solid color(gold-accent);
  margin-top: space(7);
  margin-bottom: space(10);

  @include breakpoint(large) {
    margin-top: space(5);
  }
}

// If Background Color is Red
.cards-image__card--light{
  .cards-image__card-headline{
    color: #fff;

    .cards-text__base-link-text {
  
      &::after {
        content: "";
        border-top: 2px solid color(white);
        border-right: 2px solid color(white);
      }
      &:focus {
        outline:2px dotted color(white);
        outline-offset:4px;
      }
    }
  }
  .cards-image__card-copy{
    @include bullet-list-light;
    color: #fff;

    a{
      color: unset;
    }
  }
  .base-cta-text{
    color: #fff;
  }
  .base-cta-text--light:before{
    border-right: 2px solid #fff;
    border-top: 2px solid #fff;
  }
}

// Adding image multirow
.cards-image-multirow--2{

  @include breakpoint(large) {
    .cards-image__card{
      flex-basis: 50%;
      max-width: 50%;
    }
  }
}
.cards-image-multirow--3{

  @include breakpoint(large) {
    .cards-image__card{
      flex-basis: 33.33%;
      max-width: 33.33%;
    }
  }
}
.cards-image-multirow--4{
  
  @include breakpoint(large) {
    .cards-image__card{
      flex-basis: 25%;
      max-width: 25%;
    }
  }
}