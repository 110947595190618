.category-index-card {
  max-width: unset;
  &__link {

    &:focus {
      @include focus-red;
    }

    &:focus,
    &:hover {
      .news-lockup__title {
        text-decoration: underline;
      }
    }
  }
  &__card-lockup {
    margin-top: rem(15);
    .news-lockup__title {
      margin-bottom: 5px;
      @include news-heading-6;
    }
    .news-lockup__body {
      margin-bottom: rem(18);
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .news-lockup__byline {
      color: color(dark-grey);
    }
  }
  &__card-lockup-wrapper {
    // padding-top: rem(20);
    @include breakpoint(medium) {
      padding-left: rem(20);
    }
  }
  &__container {
    @include flex-grid-row;
    position: relative;
  }
  &__image {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    // background-color: pink;
    bottom: 0;
    object-fit: cover;
  }
  &__image-wrapper {
    padding-top: calc((3 / 4) * 100%);

    @include breakpoint(medium) {
      padding-top: calc((3 / 4) * 50%);
    }
    // background: green;
    position: relative;
    height: 0;
  }
  &__card-lockup-wrapper,
  &__image-wrapper {
    @include flex-grid-size(12);
    @include breakpoint(medium) {
      @include flex-grid-size(6);
    }
  }
}
