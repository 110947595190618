.news-hero {
  @include breakpoint(medium) {
    padding-top: space(18);
    background-image: url("../images/illustration-duotone-red.png");
    background-repeat: no-repeat;
    background-size: 100% 75%;
  }

  &__background-image {
  }
  &__background-image-wrapper {
    display: none;
  }
  &__hero-copy-wrapper {
    position: relative;
    background-color: color(white);
    @include breakpoint(medium) {
      @include flex-grid-size(4.5);
      &:after {
        position: absolute;
        bottom: 0;
        @include bottom-border-thin(gold-accent);
      }
    }
  }
  &__hero-copy-container {
    .news-lockup__title {
      @include news-article-heading-1--mobile;

      @include breakpoint(medium) {
        @include news-article-heading-1--desktop;
      }
    }
    @include breakpoint(small down) {
      @include create-news-row;
    }
    @include breakpoint(medium) {
      background-color: color(white);
      margin: 36px 39px 40px 30px;
      display: flex;
      flex-flow: column wrap;

      .news-lockup__headline {
        order: 0;
      }
      .news-lockup__title {
        order: 0;
        margin-bottom: rem(24);
      }
      .news-lockup__body {
        order: 2;
      }
    }
  }
  &__hero-image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__hero-image-container {
    @include breakpoint(medium) {
      @include flex-grid-size(7.5);
    }
  }

  &__hero-image-wrapper {
    position: relative;
    padding-top: calc((2 / 3) * 100%);
    height: 0;
    // width: 100%;
    margin-bottom: rem(23);

    @include breakpoint(medium) {
      height: 100%;
    }
  }
  &__interior {
    // &:focus,
    // &:hover {
    //   .news-lockup__title {
    //     text-decoration: underline;
    //   }
    // }
    @include breakpoint(medium) {
      @include create-news-row;
    }
  }
}

.news-hero__cards-wrapper {
  .article-6col__card-container {
    .news-lockup__headline {
      @include breakpoint(medium) {
        margin-bottom: 8px !important;
      }
    }
    .news-lockup__title {
      @include breakpoint(medium) {
        margin-bottom: 8px;
        @include news-article-heading-4;
      }
    }
    @include breakpoint(medium) {
      position: relative;
      margin-bottom: 0;
      &:after {
        position: absolute;
        bottom: 0;
        @include bottom-border-thin(gold-accent);
      }
    }
  }
}

.news-hero__hero-copy-container > .news-lockup__byline {
  @include breakpoint(medium) {
    order: 1;
    margin-bottom: rem(24);
  }
}

.news-hero {
  :focus,
  :active {
    .news-lockup__title {
      text-decoration: underline;
    }
  }
}
