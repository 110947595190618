.load-more-section {
  width: 100%;
  flex: 0 0 100%;
  order: 3;

  &__button-container {
    margin: 0 auto;
    &:focus {
      @include focus-red;
      text-decoration: underline;
    }

    &.expanded {
      display: none;
    }
    .base-cta-text::before {
      transform: translateY(-80%) rotate(135deg);
      -webkit-transform: translateY(-80%) rotate(135deg);
      -moz-transform: translateY(-80%) rotate(135deg);
      -ms-transform: translateY(-80%) rotate(135deg);
      -o-transform: translateY(-80%) rotate(135deg);
    }
    &:focus,
    &:hover {
      .base-cta-text::before {
        transform: translate(0, -5px) rotate(135deg) scale(1);
        -webkit-transform: translate(0, -5px) rotate(135deg) scale(1);
        -moz-transform: translate(0, -5px) rotate(135deg) scale(1);
        -ms-transform: translate(0, -5px) rotate(135deg) scale(1);
        -o-transform: translate(0, -5px) rotate(135deg) scale(1);
      }
    }
  }
  &__button-wrapper {
    @include create-news-row;
  }
  &__content {
    .category-index-card {
      left: 0;
    }
  }
  &__inner {
  }
  .base-cta-text {
    margin-bottom: 20px;
  }
}
