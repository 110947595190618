.news-latest {
  margin-bottom: space(15);
  position: relative;
}

.news-latest__row {
  @include create-news-row;
  @include flex-grid-size(12);
  padding-top: space(10);
  padding-bottom: space(10);

  @include breakpoint(medium) {
    background-color: color(white-warm);
  }
}

.news-latest__row--icon {
  @include breakpoint(small down) {
    display: none;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  img {
    width: 75px;
  }
}

.news-latest__row--ornament-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 30px;
  margin-bottom: -30px;

  .news-latest__row--ornament {
    width: 50%;
    height: 75px;
    border-right: 1px solid color(gold-accent);
  }

  @include breakpoint(medium) {
    display: none;
  }
}

.news-latest__row--wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 25px;

  @include breakpoint(medium) {
    flex-direction: row;
  }
}

.news-latest__column {
  @include breakpoint(medium) {
    @include flex-grid-column(6);

    > div {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @include breakpoint(small-desktop) {
    > div {
      padding-left: 25px;
      padding-right: 25px;
    }
  }

  @include breakpoint(large) {
    > div {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  @include breakpoint(xlarge) {
    > div {
      padding-left: 50px;
      padding-right: 50px;
    }
  }

  @include breakpoint(xxlarge) {
    > div {
      padding-left: 75px;
      padding-right: 75px;
    }
  }
}

.news-latest__column:first-of-type {
  margin-bottom: 75px;

  @include breakpoint(medium) {
    margin-bottom: 0;
    border-right: 1px solid color(gold-accent);
  }
}

.news-latest__story .blog-lockup-item {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid color(gold-accent);

  @include breakpoint(medium) {
    padding-top: 5px;
    padding-bottom: 0;
    border-bottom: 1px solid color(gold-accent);
  }
}

.news-latest__event .blog-lockup-item {
  padding-top: 40px;

  @include breakpoint(medium) {
    border-bottom: 1px solid color(gold-accent);
  }
}

.news-latest__body .blog-lockup-item__link {
  /*height: 100%;*/
  padding-top: 10px;
  padding-bottom: 15px;

  @include breakpoint(medium) {
    /*height: 100%;*/
    padding-top: 10px;
    padding-bottom: 15px;
  }

  .blog-lockup-item__item-container {
    position: static;
    width: 100%;

    .news-lockup__title {
      font-size: rem(18);
    }
  }
}
.news-latest__body .lw_event_item_title {
  font-family: font(proxima-nova);
  font-size: rem(18);
}

.news-latest__event .blog-lockup-item__date-wrapper {
  align-items: start;
}

.news-latest__event .news-lockup__headline {
  order: 1;
}

.news-latest__event .blog-lockup-item__lockup {
  justify-content: start;
  padding-bottom: 30px;
  border-bottom: 1px solid color(gold-accent);

  @include breakpoint(medium) {
    border: none;
    padding-bottom: 10px;
    justify-content: center;
  }
}

.news-latest__cta {
  @include breakpoint(medium) {
    float: right;

    .base-cta-text {
      margin-top: 0px;
    }
  }
}

.news-latest__column .news-latest__header .news-category-title-block__title {
  @include breakpoint(medium) {
    font-size: rem(38);
  }

  @include breakpoint(small-desktop) {
    @include news-heading-2--mobile;
  }

  @include breakpoint(xlarge) {
    @include news-heading-2--desktop;
  }
}
