.cards-events {
  padding-top: space(10);
  padding-bottom: space(10);
  background-color: color(red);

  @include breakpoint(medium) {
    padding-top: space(15);
    padding-bottom: space(15);
  }

  @include breakpoint(large) {
    padding-top: space(25);
    padding-bottom: space(25);
  }
}

.cards-events__cards-container {
  @include create-row;
  // justify-content: center;
}

.cards-events__heading-container {
  @include flex-grid-column(12);
  align-self: center;
  justify-self: center;
  padding-bottom: space(10);

  @include breakpoint(large) {
    @include flex-grid-size(8);
    padding-bottom: space(15);
  }
}

.cards-events__cta-container {
  @include flex-grid-column(12);
  display: none;
  padding-top: space(7);

  @include breakpoint(large) {
    @include flex-grid-size(4);
    display: flex;
    justify-content: flex-end;
    padding-top: 0px;
    padding-bottom: space(15);
    align-self: center;
  }
}

.cards-events__cta-container-footer {
  @include flex-grid-column(12);
  padding-top: space(7);

  @include breakpoint(large) {
    @include flex-grid-size(4);
    display: none;
    justify-content: flex-end;
    padding-top: 0px;
    padding-bottom: space(15);
    align-self: center;
  }
}

.cards-events__heading {
  @include text-heading-2;
  color: color(white);
}

.cards-events__card-inner {
  position: relative;
  padding-bottom: space(6.25);
  border-bottom: 1px solid color(gold-accent);
}

.cards-events__inner {
  position: relative;
  display: block;
}

.cards-events__inner:focus {
  outline: none;
  .cards-events__card-inner {
    @include focus-white;
  }
}

.cards-events__card {
  position: relative;
  margin-bottom: space(10);

  &:last-child {
    margin-bottom: 0;
  }

  @include flex-grid-column(12);

  @include breakpoint(large) {
    margin-bottom: 0;
    @include flex-grid-size(4);
    @include grid-column-offset(0);
  }

  .cards-events__card-inner {
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 0;
      background-color: transparent;
      left: 0;
      bottom: 0;
      overflow: hidden;
      transition: height 0.2s ease-out;
    }
  }

  &:hover {
    cursor: grab;

    .cards-events__headline {
      border-bottom-color: color(white);
    }
  }
}

.cards-events__image-container {
  position: relative;
  padding-bottom: space(3.75);
}

.cards-events__image {
  height: 200px;
  width: 100%;
  object-fit: cover;
  top: 0px;
}

.cards-events__date-container {
  height: 25px;
  background-color: color(red);
  position: absolute;
  top: 10px;
  left: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cards-events__date {
  @include text-heading-7;
  color: color(white);
  margin: 0;
  font-weight: 500;
}

.cards-events__time {
  @include text-heading-7;
  text-transform: uppercase;
  color: color(white);
  padding-bottom: space(2);
}

.cards-events__headline {
  @include text-heading-6;
  display: inline;
  line-height: 1.5;
  color: color(white);
  font-weight: 600;
  padding-bottom: 2px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  transition-property: border-bottom-color;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.cards-events__divider {
  border-bottom: 1px solid color(gold-accent);
  margin-top: space(7);
  margin-bottom: space(10);

  @include breakpoint(large) {
    margin-top: space(5);
  }
}
