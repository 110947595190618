#form_question_6fc60ce1-9a2e-4a84-8577-611b7432cb4c {
    display: none;
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeInDown {
    animation-name: fadeInDown;
    animation-duration: 0.5s;
    animation-fill-mode: both;
} 

.miniRfiModal {
    z-index:100;
}
.miniRfiModal h1 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    float: left;
    display: inline-block;
    width: 80%;
}
.miniRfiModal .overlay {
    background-color:rgba(0,0,0,0.5);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index:110;
}
.miniRfiModal .form_rfi {
    position: fixed;
    z-index: 120;
    top: 0px;
}
.miniRfiModal .modal {
    position:fixed;
    z-index:120;
}

.miniRfiModal .form_rfi {
    background-color:#edece2;
    padding: 20px;
}
.miniRfiModal div.action.form_action > button, .form_button_submit {
    background-color: #c41230;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius:0px!important;
    text-transform:uppercase;
}
.miniRfiModal div.action.form_action > button:focus, .form_button_submit:focus {
    background-color: #ad102a;
}

#miniRfiModal {
    float: right;
    margin-bottom:10px;
    padding:5px;
}

#miniRfiModal:focus {
    color: black;
    background-color:transparent;
}

#form_mini_rfi {
 clear: both;
}