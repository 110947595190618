.news-social-share {
  display: flex;
  padding: 25px 10px;
  margin: 0 auto;
  max-width: 300px;
  justify-content: center;
  align-items: center;

  .socialShareLabel {
    float:left;
    text-transform:uppercase;
    font-weight:800;
    margin-right:10px;
    font-size:12px;
    font-family: font(proxima-nova);
  }

  .news-social-share__copy {
    @include text-heading-7;
    text-transform: uppercase;
    color: color(red);
    margin-right: 35px;
  }

  .news-social-share__share-icons {
    height: 20px;
    display: flex;
    align-items: center;

    .news-social-share__icon {
      margin-right: 25px;
      width: 20px;
      height: auto;

      &:last-of-type {
        margin-right: 0;
      }

      .news-social-share__link > img {
        height: 20px;
        width: auto;
      }
    }
  }
}

a.news-social-share__link {
  display: block;
  &:focus {
    @include focus-red;
  }
}
