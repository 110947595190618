.news-card-group {
	z-index: 0;
	position: relative;
	overflow: hidden;
	&--background-white {
		background-color: color(white);
	}
	&--background-white-warm {
		background-color: color(white-warm);
		.article-6col__copy-wrapper {
			background-color: color(white-warm);
		}
	}
	&--background-red {
		background-color: color(red);
		.news-category-title-block__icon-wrapper {
			fill: white;
		}
		.news-category-title-block__title {
			color: white;
		}
		.base-cta-text {
			@extend .base-cta-text--light;
		}
		.article-6col__copy-wrapper {
			background-color: color(red);
			color: white;
		}
		.news-lockup__headline,
		.news-lockup__title,
		.news-lockup__byline--text {
			color: white;
		}
	}

	&__cards-wrapper {
		@include create-news-row;

		@include flex-grid-size(12);
		@include grid-column-position(0);
		justify-content: space-between;
		margin-bottom: 0px;

		@include breakpoint(medium) {
			margin-bottom: rem(55);
		}
	}
	&__cards-wrapper--noheader {
		margin-top: rem(55);
	}
	&__header-cta {
		// margin-left: auto;
		flex: 0 1 auto;
		@include breakpoint(medium) {
			display: flex;
			.base-cta-text {
				margin-top: auto;
				margin-bottom: -12px;
			}
		}

		@include breakpoint(large) {
			.base-cta-text {
				margin-bottom: 0px;
			}
		}
	}
	&__header-title-block {
		flex: 1;
		@include breakpoint(small down) {
			@include flex-grid-size(12);
		}
	}
	&__header-wrapper {
		@include create-news-row;
		margin-bottom: 40px;
		margin-top: 40px;
	}

	& .text-padded {
		@include breakpoint(medium) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
}
