.content-hub {
  padding-top: space(10);
  padding-bottom: space(10);
  background-color: color(white);

  &.collection-list--white-warm {
    background-color: color(white-warm);
  }

  &.collection-list--red {
    background-color: color(red);
  }

  @include breakpoint(medium) {
    padding-top: space(15);
    padding-bottom: space(15);
  }

  @include breakpoint(large) {
    padding-top: space(5);
    padding-bottom: space(5);
  }
}

.content-hub__heading-container {
  @include create-row;
  margin-bottom: space(6);

  .content-hub__heading-inner {
    @include flex-grid-column(8);
    align-self: center;
    justify-self: center;
    padding-bottom: space(4);
    flex: 0 0 100%;
    max-width: 100%;

    @include breakpoint(large) {
      @include flex-grid-column(8);
    }

    .content-hub__heading {
      @include text-heading-3;
      color: color(red);
      padding-bottom: space(4);

      @include breakpoint(large) {
        padding-bottom: space(0);
      }
    }
  }
}

.content-hub__content--container {
  @include create-row;
  @include flex-grid-column(12);

  a:focus {
     outline: 2px dotted #000;
     outline-offset: 4px;
  }
  // @include breakpoint(large) {
  //   padding-left: 0;
  //   padding-right: 0;
  // }
}

.content-hub__featured-container {
  @include flex-grid-row(nest);
  border-right: none;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: space(7);

  @include breakpoint(large) {
    @include flex-grid-column(8);
    border-right: 1px solid color(grey);
  }

  .content-hub__featured-headline-inner {
    @include flex-grid-column(12);
    margin-bottom: space(5);

    @include breakpoint(large) {
      padding-left: 0;
    }
  }
}

.content-hub__headline {
  @include text-heading-4;
  font-size: rem(24);
  color: #c41230;
}

.content-hub__featured-big {
  width: 100%;
  margin-bottom: space(10);

  @include breakpoint(medium) {
    width: 65%;
  }

  @include breakpoint(large) {
    margin-bottom: 0;
  }
}

.content-hub__featured-small {
  width: 100%;

  @include breakpoint(medium) {
    width: 35%;
  }

  .content-hub__featured {
    &:first-child {
      margin-bottom: space(8);
    }
  }
}

.content-hub__featured {
  @include flex-grid-column(12);
  position: relative;

  @include breakpoint(large) {
    padding-left: 0;
    padding-right: 0;
    margin-right: .9375rem;
  }
  a {
    display: block;
  }

  .content-hub__featured-image {
    width: 100%;
  }

  .content-hub__featured-inner {
    position: relative;
    padding-bottom: space(8);
    border-bottom: 1px solid color(grey);

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 0;
      background-color: color(white);
      left: 0;
      bottom: 0;
      overflow: hidden;
      transition: height 0.2s ease-out;
    }

    @include breakpoint(large) {
      padding-bottom: space(4);
    }

    .content-hub__featured-headline {
      @include text-body-small;
      color: color(red);
      font-style: italic;
      margin-top: space(6);
      margin-bottom: space(4);

      @include breakpoint(large) {
        margin-top: space(3);
        margin-bottom: space(2);
      }
    }

    .content-hub__featured-copy {
      @include text-heading-6;
      display: inline;
      line-height: 1.5;
      color: color(black);
      padding-bottom: 2px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0);
      transition-property: border-bottom-color;
      transition-duration: 300ms;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }
  }

  &:hover {
    cursor: pointer;
    .content-hub__featured-copy {
      border-color: color(black);
    }
  }
}



.content-hub__link--container {
  // @include flex-grid-column(12);

  @include breakpoint(large) {
    @include flex-grid-column(4);
  }

  .content-hub__link--heading-inner {
    margin-bottom: space(5);

    @include breakpoint(large) {
      @include flex-grid-column(12);
    }
  }

  .content-hub--divider-wrapper {
    @include breakpoint(large) {
      @include flex-grid-column(12);
    }

    .content-hub--divider {
      margin-top: space(7);
      margin-bottom: space(7);
      border-bottom: 1px solid color(gold-accent);
    }
  }

  .content-hub__link {
    @include breakpoint(large) {
      @include flex-grid-column(12);
    }

    .content-hub__link-category {
      @include text-body-small;
      font-style: italic;
      color: color(red);
      margin-top: space(5);
      padding-bottom: space(2);
      // font-weight: normal;
    }

    .content-hub__link-date {
      @include text-heading-7;
      text-transform: uppercase;
      color: color(dark-gray);
      margin-top: space(5);
      padding-bottom: space(2);
    }

    .content-hub__link-text {
      @include text-heading-6;
      display: inline;
      line-height: 1.5;
      color: color(black);
      padding-bottom: 2px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0);
      transition-property: border-bottom-color;
      transition-duration: 300ms;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

      &:hover {
        cursor: pointer;
        border-color: color(black);
        .base-link-text {
          text-decoration: none;
        }
      }
    }
  }

  .content-hub__link-cta {
    @include breakpoint(large) {
      @include flex-grid-column(12);
    }
  }

  // Localist Embed
  .localist-widget {
    @include flex-grid-column(12);

    .classicList{
      .lw{
        .lwe{
          padding: 20px 10px !important;

          .lwn{
            display: flex;
            flex-direction: column;

            .lwn0{
              color: #7e6d53;
              font-family: proxima-nova,sans-serif;
              font-size: 12px;
              font-weight: 700;
              letter-spacing: .09375rem;
              line-height: 20px;
              padding-bottom: 8px;
              text-transform: uppercase;

            }
            a{
              color: #231f20 !important;
              display: inline;
              font-family: proxima-nova,sans-serif;
              font-size: 18px !important;
              font-weight: 700;
              line-height: 24px;
              transition-duration: .3s;
              transition-property: border-bottom-color;
              transition-timing-function: cubic-bezier(.4,0,.2,1);

            }
          }

          .lwl{
            .lwl0, a{
              color: #7e6d53;
              font-family: proxima-nova,sans-serif;
              font-size: 12px;
              font-weight: 700;
              letter-spacing: .09375rem;
              line-height: 20px;
              margin-top: 20px;
              padding-bottom: 8px;
              text-transform: uppercase;
            }
          }
        }
      }

    }

    .modernList, .verticalModern{
      .localist_widget_container{
        ul.lw_event_list{
          li.lw_event_item{
            border: none !important;
            padding: 0 0 15px !important;

            div.lw_event_content{
              padding: 0 0 0 20px !important;

              div.action_button{
                display: none !important;
              }
            }
          }
        }

      }
    }

  
    // .modernList .localist_widget_container div.action_button,
    // .verticalModern .localist_widget_container div.action_button {
    //   display: none !important;
    // }
    // .modernList .localist_widget_container li.lw_event_item,
    // .verticalModern .localist_widget_container li.lw_event_item {
    //   border: none !important;
    //   padding: 0 0 15px !important;
    // }
    // .localist_widget_container div.lw_event_content {
    //   padding: 0 0 0 20px !important;
    // }

  }

}
