.context-image-int {
  width: 100%;
  margin-top: space(7);
  margin-bottom: space(14);
}

.context-image-int__container {
  @include create-row;
}

.context-image-int__image {
  @include flex-grid-column(12);
  height: 335px;
  object-fit: cover;

  @include breakpoint(large) {
    @include flex-grid-size(5);
    order: 2;
  }
}

.context-image-int__text {
  @include flex-grid-column(12);

  @include breakpoint(large) {
    @include flex-grid-size(7);
  }
}

.context-image-int__lockup-container {
  padding-top: space(8);
  padding-bottom: space(4);

  @include breakpoint(large) {
    padding-top: 0;
  }

  .base-lockup__copy--regular {
    margin-top: space(3.75);
    h6 {
      @include text-heading-6;
      margin-bottom: space(3.75);
      margin-top: space(11.25);
    }
    h5 {
      @include text-heading-5;
      margin-bottom: space(3.75);
      margin-top: space(11.25);
    }
    h4 {
      @include text-heading-4;
      margin-bottom: space(3.75);
      margin-top: space(11.25);
    }
    h3 {
      @include text-heading-3;
      margin-bottom: space(3.75);
      margin-top: space(11.25);
      &.table-header {
        font-family: font(proxima-nova);
        font-size: rem(20);
        line-height: 1.3;
        font-weight: 500;
        margin-bottom: space(5);
        @include breakpoint(large) {
          font-size: rem(24);
          line-height: 1.25;
        }
      }
    }
    p {
      margin-bottom: space(5);
    }
    a {
      color: color(red);
      text-decoration: underline;
      font-style: normal;
      outline:2px dotted transparent;
      outline-offset:0;
      transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
        &:hover {
          text-decoration: none;
        }
        &:focus {
          text-decoration:none;
          outline:2px dotted color(red);
        }
      }
    ul {
      margin-bottom: space(5);
      li {
        padding: space(2) 0 space(2) space(7.5);
        &:before {
          content: "";
          background-image: url("../svgs/functional/icon-target--red.svg");
          background-repeat: no-repeat;
          background-size: contain;
          width: 10px;
          height: 10px;
          display: inline-block;
          margin-right: space(5);
          margin-left: space(-7.5);
        }
      }
    }
    ol {
      list-style: none;
      counter-reset: my-awesome-counter;
      margin-bottom: space(5);
      li {
        padding: space(2) 0;
        counter-increment: my-awesome-counter;
        padding-left: space(9);
        &:before {
          content: counter(my-awesome-counter);
          font-family: font(proxima-nova);
          color: color(red);
          font-size: rem(24);
          font-weight: 400;
          letter-spacing: 0.75px;
          margin-right: space(4);
          text-align: center;
          display: inline-block;
          width: 20px;
          vertical-align: middle;
          margin-left: space(-9);
        }
      }
    }
  }
  @include breakpoint(large) {
    margin-top: space(6);
  }
}
