.submenu--wrapper {
  position: relative;
  transition: .35s ease(default) height;
  // height: 0;

  /*&.submenu--wrapper--active {
    height: 60px;

    @include breakpoint(xlarge) {
      height: 50px;
    }
  } */
}

@keyframes horizontalBounce {
  0% {
    transform: translateX(0px);
    width: calc(100vw - 24px);
  }
  50% {
    transform: translateX(-95px);
    width: calc(100vw + 95px - 24px);
  }
  100% {
    transform: translateX(0px);
    width: calc(100vw - 24px);
  }
}

#submenu {
  border-bottom: 1px solid color(red);
  background: color(white);
  position: fixed;
  left: 0;
  right: 0;
  top: 78px;
  z-index: 11;

  height: 0;
  overflow: hidden;

  transition: .25s ease(default) all;

  &.submenu--active {
    height: auto;
  }

  .submenu--inner {
    .submenu__title {
      color: color(red);
      font-family: font(freight-display);
      font-size: rem(15);
      padding: space(2) space(3) space(1);

      a {
         &:hover {
           text-decoration: underline;
         }

         &:focus {
           @include focus-red;
         }
      }
    }

    .submenu-nav {
      font-family: font(proxima-nova);
      font-size: rem(12);
      letter-spacing: .05em;
      position: relative;

      &.submenu-nav__arrow::after {
        content: '';
        position: absolute;
        right: 12px;
        bottom: 10px;
        display: block;
        width: 9px;
        height: 9px;
        background-color: transparent;
        border-top: 2px solid color(red);
        border-right: 2px solid color(red);
        transform: translateY(-50%) rotate(45deg);
        -webkit-transform: translateY(-50%) rotate(45deg);

        @include breakpoint(tablet-min) {
          display: none;
        }
      }

      .submenu-nav__list {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: scroll;
        width: calc(100vw - 24px);
        padding: space(1) 0 space(3);
        ms-overflow-style: none;
        scrollbar-width: none;

        animation-duration: 2s;
        animation-timing-function: ease(default);
        animation-name: horizontalBounce;

        @include breakpoint(tablet-min) {
          animation: unset;
        }

        &::-webkit-scrollbar {
          display: none;
        }

        .submenu-nav__list-link {
          white-space: nowrap;
          margin: 0 space(4);
          padding-top: space(.5);

          &:first-child {
            margin-left: space(3);
          }
          &:last-child {
            padding-right: space(8);
          }

          a {
            &:hover {
              text-decoration: underline;
            }

            &:focus {
              @include focus-red;
            }

            &.submenu-nav__link-active {
              font-weight: bold;
              text-decoration: underline;
            }
        }
        }
      }
    }
  }

  @include breakpoint(large) {
    top: unset;

    &.submenu--active {
      height: auto;
    }
    .submenu--inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 space(2) 0 space(4);

      .submenu__title {
        padding: space(3) 0;
        font-size: rem(22);
        font-weight: 500;
        width: auto;
      }

      .submenu-nav {
        width: max-content;
        font-size: rem(16);
        font-weight: 400;

        .submenu-nav__list {
          overflow-x: visible;
          padding:  0;
          width: auto;

          &::after {
            display: none;
          }

          .submenu-nav__list-link {
            margin: 0 1.5vw;

            &:last-child {
              padding-right: 0;
            }
          }
        }
      }
    }
  }
}
