.news-video-group {
  position: relative;
  margin-top: space(10);
  margin-bottom: space(10);

  @include breakpoint(large) {
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 55%;
      height: 300px;
      background-color: color(white-warm);
      z-index: -1;
      @include breakpoint(medium) {
        display: none;
      }
    }
  }

  a {
    // &:focus {
    //   @include focus-red;
    // }

    &:focus,
    &:hover {
      .news-lockup__title {
        text-decoration: underline;
      }
    }
  }
}

.news-video-group__inner {
  position: relative;

  @include breakpoint(large) {
    @include create-news-row;
    @include flex-grid-size(12);

    border-left: 1px solid color(red);
  }
}

.news-video-group__featured {
  position: relative;
  margin-bottom: space(10);

  @include breakpoint(large) {
    width: 50%;
    margin-bottom: 0px;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0px;
    left: get-news-row-margin(xsmall);
    width: 24px;
    height: 24px;
    background-image: url("../svgs/news/icon-play-ornament.svg");
    background-repeat: no-repeat;
    background-size: contain;

    @include breakpoint(small) {
      left: get-news-row-margin(small);
    }
    @include breakpoint(medium) {
      left: get-news-row-margin(medium);
    }

    @include breakpoint(large) {
      left: -24px;
      width: 45px;
      height: 45px;
    }
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 200px;
    background-color: color(white-warm);
    z-index: -1;

    @include breakpoint(large) {
      display: none;
    }
  }
}

.news-video-group__featured-wrapper {
  @include create-news-row;

  .news-lockup__title {
    @include news-article-heading-3;

    &:focus-within {
      @include focus-red;
    }

    &:focus {
      outline: 0;
    }
  }

  @include breakpoint(large) {
    margin-right: 0px;
    margin-left: space(12);
    margin-top: space(2);

    .news-lockup__title {
      @include news-article-heading-1--mobile;
    }
  }

  @include breakpoint(xlarge) {
    .news-lockup__title {
      @include news-article-heading-1--desktop;
    }
  }
}

.news-video-group__featured-header {
  @include news-heading-5;
  margin-bottom: space(2);
  margin-left: space(7);

  @include breakpoint(large) {
    margin-left: 0px;
    margin-bottom: space(10);
  }
}

.news-video-group__featured-thumbnail {
  margin-bottom: space(6);
  position: relative;

  &:hover,
  &:focus {
    @include focus-red;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-box-shadow: 7.5px 7.5px 0 0 rgba(196, 18, 48, 1);
    -moz-box-shadow: 7.5px 7.5px 0 0 rgba(196, 18, 48, 1);
    box-shadow: 7.5px 7.5px 0 0 rgba(196, 18, 48, 1);
  }

  .news-video-group__video-play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25%;
    fill: white;
  }
}

.news-video-group__more-videos {
  @include breakpoint(large) {
    width: 50%;
    padding-left: space(19);
    padding-top: space(19);

    .news-video-card-group {
      width: 100%;
      margin-left: 0px;
      margin-right: 0px;
    }

    .news-video-card-group__cards-container {
      flex-flow: column nowrap;
      margin-bottom: 0px;
      width: 100%;
    }

    .news-video-card-group__card {
      max-width: 100%;
      margin-bottom:2em;
    }

    .news-video-article {
      border-bottom: 1px solid color(gold-accent);
      margin-bottom: space(6);
    }

    .news-video-card-group__flex-wrapper {
      display: flex;
      flex-direction: row;
      width: 100%;
    }

    .news-video-article__container {
      width: 100%;
    }

    .news-video-article__lockup-block {
      padding-left: space(6);
    }

    .news-video-article__lockup-block .news-lockup__title {
      @include news-heading-6;
    }
  }

  @include breakpoint(xlarge) {
    padding-left: space(37);
  }
}

.news-video-group__cta {
  @include create-news-row;
  justify-content: flex-end;

  @include breakpoint(large) {
    margin-right: 0px;
  }

  .base-cta-text {
    margin-top: 0px;
  }
}
