.blog-lockup-item {
  &__item-container {
    // position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    height: 100%;
  }

  &__image {
    top: 0;
    left: 0;
    -webkit-box-shadow: 7.5px 7.5px 0 0 rgba(196, 18, 48, 1);
    -moz-box-shadow: 7.5px 7.5px 0 0 rgba(196, 18, 48, 1);
    box-shadow: 7.5px 7.5px 0 0 rgba(196, 18, 48, 1);
    min-width: 42px;
  }
  &__date {
    top: 0;
    left: 0;
    border: 1px solid color(red);
    -webkit-box-shadow: 7.5px 7.5px 0 0 rgba(196, 18, 48, 1);
    -moz-box-shadow: 7.5px 7.5px 0 0 rgba(196, 18, 48, 1);
    box-shadow: 7.5px 7.5px 0 0 rgba(196, 18, 48, 1);
    width: 75px;
    height: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__date-month {
    @include text-heading-7;
    text-transform: uppercase;
  }
  &__date-day {
    font-family: font(freight-display);
    font-size: rem(46);
    font-weight: 400;

    @include breakpoint(large) {
      font-size: rem(58);
      font-weight: 300;
    }

    margin-top: -25px;
  }
  &__image-shadow,
  &__date-shadow {
    position: absolute;
    z-index: -1;
    background-color: color(red);
    top: 10%;
    left: 10%;
  }
  &__image-wrapper,
  &__date-wrapper {
    // height: 83px;
    // width: 83px;
    @include flex-grid-size(3);
    position: relative;
    margin-right: rem(25);
  }
  &__date-wrapper {
    display: flex;
    align-items: center;
  }
  &__link {
    width: 100%;
    height: 100%;
    @include flex-grid-row;
    max-width: unset;

    // position: relative;
    // height: 0;
    // padding-top: 25%;
    // &:focus,
    // &:hover {
    // 	.news-lockup__title {
    // 		text-decoration: underline;
    // 	}
    // }
    // &:focus {
    // 	@include focus-red;
    // }
  }
  &__lockup {
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .news-lockup__headline {
      margin-bottom: rem(5);
    }
    .news-lockup__title {
      margin-bottom: 0;
      @include news-heading-6;
    }
  }

  // &:after {
  //   content: "";
  //   width: 100%;
  //   height: 2px;
  //   background: pink;
  //   display: block;
  //   margin: rem(25) 0;
  // }
}
