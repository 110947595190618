.base-lockup__label {
  @include text-body-small;
  font-style: italic;

  .base-lockup--dark & {
    color: color(red);
  }

  .base-lockup--light & {
    color: color(white);
  }

  .base-lockup--black & {
    color: color(black);
  }
}

.base-lockup__heading {
  .base-lockup--dark & {
    color: color(red);
  }

  .base-lockup--light & {
    color: color(white);
  }

  .base-lockup--black & {
    color: color(black);
  }

  &.base-lockup__heading--1 {
    @include text-heading-1;
  }

  &.base-lockup__heading--2 {
    @include text-heading-2;
  }

  &.base-lockup__heading--3 {
    @include text-heading-3;
  }

  &.base-lockup__heading--4 {
    @include text-heading-4;
  }

  .base-lockup__label + & {
    margin-top: space(1);
  }
}

.base-lockup__copy {
  @include text-body-regular;

  .base-lockup--dark & {
    color: color(black);
  }

  .base-lockup--light & {
    color: color(white);
  }

  .base-lockup--black & {
    color: color(black);
  }

  &.base-lockup__copy--small {
    @include text-body-small;
  }

  &.base-lockup__copy--large {
    @include text-body-large;
  }
  &.base-lockup__copy--regular {
    h6 {
      @include text-heading-6;
      margin-bottom: space(3.75);
      margin-top: space(11.25);
    }
    h5 {
      @include text-heading-5;
      margin-bottom: space(3.75);
      margin-top: space(11.25);
    }
    h4 {
      @include text-heading-4;
      margin-bottom: space(3.75);
      margin-top: space(11.25);
    }
    h3 {
      @include text-heading-3;
      margin-bottom: space(3.75);
      margin-top: space(11.25);
      &.table-header {
        font-family: font(proxima-nova);
        font-size: rem(20);
        line-height: 1.3;
        font-weight: 500;
        margin-bottom: space(5);
        @include breakpoint(large) {
          font-size: rem(24);
          line-height: 1.25;
        }
      }
    }
    p {
      margin-bottom: space(5);
    }
    a {
      color: color(red);
      text-decoration: underline;
      font-style: normal;
      outline:2px dotted transparent;
      outline-offset:0;
      transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
        &:hover {
          text-decoration: none;
        }
        &:focus {
          text-decoration:none;
          outline:2px dotted color(red);
        }
      }
    ul {
      margin-bottom: space(5);
      li {
        padding: space(2) 0 space(2) space(7.5);
        &:before {
          content: "";
          background-image: url("../svgs/functional/icon-target--red.svg");
          background-repeat: no-repeat;
          background-size: contain;
          width: 10px;
          height: 10px;
          display: inline-block;
          margin-right: space(5);
          margin-left: space(-7.5);
        }
      }
    }
    ol {
      list-style: none;
      counter-reset: my-awesome-counter;
      margin-bottom: space(5);
      li {
        padding: space(2) 0;
        counter-increment: my-awesome-counter;
        padding-left: space(9);
        &:before {
          content: counter(my-awesome-counter);
          font-family: font(proxima-nova);
          color: color(red);
          font-size: rem(24);
          font-weight: 400;
          letter-spacing: 0.75px;
          margin-right: space(4);
          text-align: center;
          display: inline-block;
          width: 20px;
          vertical-align: middle;
          margin-left: space(-9);
        }
      }
    }
  }

  .base-lockup__heading + & {
    margin-top: space(6);
  }
}

.base-lockup__ctas {
  margin-top: space(6);

  @include breakpoint(medium) {
    .base-cta-primary:not(:last-child) {
      margin-right: space(6);
    }
  }
}
