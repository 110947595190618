.collection-links {
  margin-top: space(15);
  margin-bottom: space(15);

  &.collection-links--white {
    background-color: color(white);

    .collection-links-description {
      color:color(black);
    }

    a.base-cta-text {
      color:color(black);
    }
  }

  &.collection-links--gold {
    background-color: color(gold);
    padding-top: space(15);
    padding-bottom: space(15);
    margin: 0;
  }

  &.collection-links--red {
    background-color: color(red);
    padding-top: space(15);
    padding-bottom: space(15);
    margin: 0;

    .base-lockup__copy {
      color:color(white);
    }

    a.base-cta-text {
      color:color(white);

      &:before {
        border-top: 2px solid color(white);
        border-right: 2px solid color(white);
      }
    }
  }

  .collection-links__link-container {
    display:flex;
    flex-wrap:wrap;
    padding-left:0;
    padding-right:0;
  }
}

.collection-links__container {
  @include create-row;
}

.collection-links__heading-container {
  @include flex-grid-column(12);
  padding-bottom: space(7.5);

  .collection-links--white &,
  .collection-links--gold & {
    color: color(red);
  }

  .collection-links--red & {
    color: white;
  }

  @include breakpoint(large) {
    @include flex-grid-size(12);
    padding-bottom: space(3);
  }
}

.collection-links__description-container {
  @include flex-grid-column(12);
  padding-bottom: space(7.5);

  @include breakpoint(large) {
    padding-bottom: space(15);
    @include flex-grid-size(8);
  }
}

.collection-links__link {
  @include flex-grid-column(12);
  @include breakpoint(large) {
    @include flex-grid-size(4);
  }
  a {
    margin-top: 0;
    margin-bottom: space(3);
  }
}
