div.modal {
  display: block;
  border: 0;
}

.invisible {
  visibility: hidden;
}
/* removes scroll when modal is opened */
.no-scroll {
  overflow: hidden;
}
/* overlay covers everything */
.simple-animated-modal-overlay,
.simple-modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #c41230;
  opacity: 0.95;
  z-index: 666;
  cursor: pointer;
}

.simple-modal-overlay[data-background-click="disabled"],
.simple-animated-modal-overlay[data-background-click="disabled"] {
  cursor: auto;
}

.simple-animated-modal-overlay {
  animation: fadewhite ease 0.5s 1 normal;
}
@keyframes fadewhite {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.8;
  }
}
/* modal */
.simple-animated-modal,
.simple-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  /*max-height: 98vh;
	*/
  /*border: 2px solid #000;
	*/
  background: #fff;
  z-index: 667;
  padding-left: 2em;
  padding-right: 2em;
  max-width: 1400px;
  right: auto;
  overflow: auto;
}

.simple-animated-modal-close,
.simple-modal-close {
  float: right;
  background: #fff;
  /*border-radius: 1em;
	*/
  color: #fff;
  border: 0;
  font: inherit;
  padding: 0.75em 1em;
  cursor: pointer;
  outline: 2px dotted transparent;
  outline-offset: -4px;
}

.simple-animated-modal-close:active,
.simple-animated-modal-close:focus,
.simple-animated-modal-close:hover,
.simple-modal-close:active,
.simple-modal-close:focus,
.simple-modal-close:hover {
  outline: 2px dotted #c41230;
}

.simple-animated-modal-close:active,
.simple-animated-modal-close:hover,
.simple-modal-close:active,
.simple-modal-close:hover {
  background: #ccc9b8;
}

.simple-animated-modal {
  animation: apparition ease 0.5s 1 normal;
}
@keyframes apparition {
  0% {
    opacity: 0;
    max-height: 100%;
    width: 80%;
  }

  100% {
    opacity: 1;
    max-height: 100%;
    width: 80%;
  }
}
/* CUSTOM STYLES FOR PSA MODAL */
div[class$="-modal"] {
  background: transparent;
  border: none;
}

.simple-animated-modal__content > div {
  display: flex;
  background-color: #000;
}

.profilePopupInfo {
  flex-basis: 40%;
  padding: 1em;
}

.profilePopupInfo h3 {
  color: #c41230;
  font-family: 'Freight Display Semibold', Times, Serif;
  font-size: 2em;
}

.profilePopupInfo span {
  color: #c41230;
  font-family: 'Proxima Bold', Helvetica, Sans-Serif;
  font-size: 1.25em;
  text-transform: uppercase;
}

.profilePopupInfo p {
  color: #c41230;
  font-family: 'Freight Text Book', Times, Serif;
  font-size: 1.125em;
  line-height: 140%;
}

.gridImagePopup {
  width: 100%;
  height: 100%;
  /*flex-basis:60%;
	*/
  /*margin:1em;
	*/
  /*outline:2px solid #c41230;
	*/
  /*position:relative;
	*/
  /*box-shadow:4px 4px #fff, 6px 6px #c41230;
	*/
}
/*.gridImagePopup:before,*/
/*.gridImagePopup:after {
	*/
/* content:"";
	*/
/* position:absolute;
	*/
/* background:#c41230;
	*/
/*
}
*/
/*.gridImagePopup:before {
	*/
/* top:100%;
	*/
/* left:4px;
	*/
/* height:6px;
	*/
/* width:2px;
	*/
/*
}
*/
/*.gridImagePopup:after {
	*/
/* left:100%;
	*/
/* top:4px;
	*/
/* height:2px;
	*/
/* width:6px;
	*/
/*
}
*/
.songPopup {
  width: 100%;
  height: 100%;
  flex-basis: 100%;
  margin: 1em;
  outline: 2px solid #c41230;
  position: relative;
  box-shadow: 4px 4px #fff, 6px 6px #c41230;
}

.songPopup:after,
.songPopup:before {
  content: "";
  position: absolute;
  background: #c41230;
}

.songPopup:before {
  top: 100%;
  left: 4px;
  height: 6px;
  width: 2px;
}

.songPopup:after {
  left: 100%;
  top: 4px;
  height: 2px;
  width: 6px;
}
/* it can be easily adapted in media-queries for tablets/mobile */
/* for this example: tablets */
@media (max-width: 55.625em) {
  .simple-animated-modal,
  .simple-modal {
    left: 5%;
    top: 10%;
    height: 90%;
    width: 90%;
    transform: translate(0%,0%);
  }

  .simple-animated-modal__content > div {
    display: block;
  }
}
/* MOBILE */
@media (max-width: 44.375em) {
  .simple-animated-modal__wrapper {
    position: relative;
  }

  .simple-animated-modal,
  .simple-modal {
    /*left: 1%;
		*/
    left: 0;
    /*top: 20%;
		*/
    top: 12%;
    /*width: 98%;
		*/
    /*height: 98%;
		*/
    width: 100%;
    height: 100%;
    transform: none;
    max-height: 100vh;
    padding-bottom: 50%;
    padding-left: 1em;
    padding-right: 1em;
  }

  .simple-animated-modal-close,
  .simple-modal-close {
    /*position: absolute;
		*/
    /*top: 0;
		*/
    /*right: 0;
		 */
  }

  .simple-animated-modal__content {
    position: relative;
    /*top: 40px;
		*/
    left: 0;
    width: 90%;
  }

  .simple-animated-modal__content > div {
    display: block;
  }
}

.hh-gallery-folder .post-feature-media__image-wrapper,
.hh-gallery-folder .post-feature-media__video-wrapper {
  padding-top:0;
  height:auto;
}

.gridImageContainer {
  position: relative;
}

.gridImageInnerContainer {
  position:relative;
}

.cards-text__card-copy .gridImageContainer .gridImageInnerContainer a {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.gridImageContainer .gridImageInnerContainer > img {
  height: 280px;
  object-fit: cover;
  object-position: center;
}

.gridImageContainer .gridImageInnerContainer a {
  position:absolute;
  bottom:20px;
  right:20px;
}

.gridImageContainer .gridImageInnerContainer a img {
  width: 60px;
  height: 60px;
  background: #c41230;
  padding: 0.7em;
  border-radius: 30px;
  outline: 2px dotted transparent;
  transition: all 0.2s ease;
}

.gridImageContainer .gridImageInnerContainer a img:hover {
  background: transparent;
}

.gridImageContainer .gridImageInnerContainer a img:focus {
  background: #c41230;
  outline: 2px dotted #fff;
}

.responsiveImage {
  padding-top: 0;
  position: relative;
  padding-bottom: 56.25%;
  // padding-top: 30px;
  height: 0;
  overflow: hidden;
  background: #000;
}

.plus-icon {
  position: relative;
  display: block;
  width: 60px;
  height: 60px;
  background-color: #c41230;
  border-radius: 30px;
  transition: 0.3s all ease;
}

.plus-icon:before {
  content: "";
  background-color: #fff;
  width: 12px;
  height: 2px;
  position: absolute;
  top: calc(50% - 0px);
  left: calc(50% - 6px);
}

.plus-icon:after {
  content: "";
  background-color: #fff;
  width: 2px;
  height: 12px;
  position: absolute;
  top: calc(50% - 5px);
  left: calc(50% - 1px);
}

.gridImageContainer a:hover .plus-icon {
  background-color: #fff;
}

.gridImageContainer a:hover .plus-icon:after,
.gridImageContainer a:hover .plus-icon:before {
  background-color: #c41230;
}

.gridImageContainer a:focus {
  outline: 2px dotted #fff;
}

.gridImageCaption {
  font-size: 0.9em;
  margin-top: 0.5em;
}

.gridImagePopup .imageFrame .gridImageCaption {
  color: #fff;
  padding: 0.5em 1em;
  background: rgba(0,0,0,.7);
  position: absolute;
  bottom: 0;
  width: 100%;
}

.cards-text--white {
  // padding-top: 20px;
  // padding-bottom: 20px;
}

.cards-text.cards-text--white .cards-text__card .cards-text__card-inner .cards-text__card-copy h2 {
  padding-top: 16px !important;
  padding-bottom: 0 !important;
}

// .collection-links {
//   margin-top: 0;
// }

.hidden {
  display: none;
}
@media screen and (max-width: 768px) {
  .cards-text.cards-text--white .cards-text__card .cards-text__card-inner .cards-text__card-copy {
    // padding-bottom: 50px;
  }
}

/* overall container */
.post-feature-media__image-wrapper,
.post-feature-media__video-wrapper {
    position:initial;
    padding-top:0;
    height:auto;
}

.post-feature-media__image-wrapper {
    padding-top:0;
}

.g-row {
    display:flex;
    justify-content:center;
    align-items:center;
    flex-wrap:wrap;
}

.g-row .gridImageContainer {
    flex-basis:100%;
    padding-right:1.25em;
    padding-left:1.25em;
    margin-bottom:3em;
}

@media print, screen and (min-width: 40em) {
    .g-row .gridImageContainer {
        flex-basis:50%;
    }
}

@media print, screen and (min-width: 64em) {
    .g-row .gridImageContainer {
        flex-basis:33.33333%;
    }
}


/* block styling */
.plus-icon {
    // position:absolute;
    // bottom:50px;
    // right:34px;
}

.gridImageCaption {
    font-size: 1.125em;
    margin-top: 0.5em;
    font-family: freight-text-pro,serif;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: .01562rem;
}

.hh-gallery-row .g-row-img {
    height:auto !important;
    margin:0;
}

.hh-gallery-row .g-row-caption {
    margin:0;
    width:auto;
}

.hh-gallery-row .g-row-img-wrapper .g-row-img {
    margin:0;
}

.hh-gallery-row .g-row-img-wrapper:first-of-type .g-row-img {
    margin:0;
    margin-left:0;
}

.hh-gallery-row .g-row-img-wrapper:last-of-type .g-row-img {
    margin:0;
    margin-right:0;
}

.hh-gallery-row .g-row-img-wrapper .g-row-caption {
    margin:0;
}

.hh-gallery-row .g-row-img-wrapper:first-of-type .g-row-caption {
    margin:0;
}

.hh-gallery-row .g-row-img-wrapper:last-of-type .g-row-caption {
    margin:0;
}

.hh-gallery-row .g-row-img-wrapper {
    margin-bottom:1em;
}


/*@media print, screen and (min-width: 715px) {*/
/*    .hh-gallery-row .g-row-img-wrapper {*/
/*        width:100% !important;*/
/*    }*/
/*}*/

/* image widths */

.twentyFive {
    width:100%;
}

.thirtyThree {
    width:100%;
}

.fifty {
    width:100%;
}

.sixtySix {
    width:100%;
}

.seventyFive {
    width:100%;
}

.oneHundred {
    width:100%;
}

@media print, screen and (min-width: 880px) {
    .hh-gallery-row .g-row-img-wrapper {
        margin-bottom:0em;
    }

    .twentyFive {
        width:25%;
    }

    .thirtyThree {
        width:33.333%;
    }

    .fifty {
        width:50%;
    }

    .sixtySix {
        width:66.666%;
    }

    .seventyFive {
        width:75%;
    }

    .oneHundred {
        width:100%;
    }

    .hh-gallery-row .g-row-img {
        height:auto !important;
        margin:0 1em;
    }

    .hh-gallery-row .g-row-caption {
        margin:0 1em 1em 1em;
        width:auto;
    }

    .hh-gallery-row .g-row-img-wrapper .g-row-img {
        margin:0 .5em 0 .5em;
    }

    .hh-gallery-row .g-row-img-wrapper:first-of-type .g-row-img {
        margin:0 1em 0 0;
        margin-left:0;
    }

    .hh-gallery-row .g-row-img-wrapper:last-of-type .g-row-img {
        margin:0 0 0 1em;
        margin-right:0;
    }

    .hh-gallery-row .g-row-img-wrapper .g-row-caption {
        margin:0 .5em 2em .5em;
    }

    .hh-gallery-row .g-row-img-wrapper:first-of-type .g-row-caption {
        margin:0 1.125em 2em 0;
    }

    .hh-gallery-row .g-row-img-wrapper:last-of-type .g-row-caption {
        margin:0 0 2em 1.125em;
    }
}
