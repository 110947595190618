.news-search-bar {
  width: 100%;
  position: relative;
  &__form {
    width: 100%;
  }
  &__input-clear-button {
    &:focus {
      img {
        @include focus-white;
      }
    }
    margin: 0 rem(13);
    height: rem(16);
    width: rem(16);
    min-width: rem(16);
  }
  &__input-container {
    border: color(white) 1px solid;
    display: flex;
    flex-flow: row nowrap;
    color: color(white);
    align-items: center;
    width: 100%;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    @include breakpoint(medium) {
      margin-right: rem(10);
    }
  }
  &__search-icon {
    margin: 0 auto;
  }
  &__submit {
    &:focus {
      img {
        @include focus-red;
      }
    }
    display: block;
    box-sizing: border-box;
    width: rem(60);
    background-color: white;
    margin: 0;
    padding:21px;
  }
  &__text-input {
    -webkit-appearance: none;
    background: unset;
    display: block;
    box-sizing: border-box;
    border: 0;
    color: color(white);
    height: 100%;
    padding: rem(18) 0 rem(18) rem(25);
    font-family: font("proxima-nova");
    font-size: rem(20);
    line-height: rem(26);
    width: 80%;
    &::placeholder {
      color: color(white);
      opacity: 1;
    }
    &::-ms-input-placeholder,
    &:-ms-input-placeholder {
      color: color(white);
    }
  }
  &__wrapper {
    display: flex;
    max-width: 100%;
    // height: rem(60);
  }
}
