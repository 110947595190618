.post-feature-media {
  @include create-news-row;
  &__interior {
    @include flex-grid-size(12);
  }
  &__copy-wrapper {
    font-family: font(proxima-nova);
    font-size: rem(14);
    font-weight: 600;
    line-height: rem(24);
    padding: rem(20) 0;
  }
  &__divider {
    height: 1px;
    width: 100%;
    background-color: color(grey);
    margin-bottom: rem(20);
  }

  &__image-wrapper {
    padding-top: calc((4 / 3) * 100%);
  }
  &__image-wrapper,
  &__video-wrapper {
    position: relative;
    padding-top: calc((9 / 16) * 100%);
    height: 0;
    width: 100%;
  }
  &__image-container,
  &__video-container {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    > * {
      height: 100%;
      width: 100%;
      object-fit:cover;
      object-position:center;
    }
  }
  &__copy-wrapper,
  &__divider,
  &__image-wrapper,
  &__video-wrapper {
    width: 100%;
  }
}

.post-feature-media--inline {
  .post-feature-media__interior {
    @include grid-column-position(center);
    @include breakpoint(small down) {
      @include flex-grid-size(12);
    }
    @include breakpoint(medium) {
    }
    @include flex-grid-size(12);
    margin-top:30px;
  }
}
