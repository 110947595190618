.hero-primary {
  position: relative;
  // display: flex;
  overflow: hidden;
  min-height: 75vh;
  padding-top: space(20);
  padding-bottom: space(20);
  align-items: center;

  @include breakpoint(large) {
    min-height: 625px;
    padding-top: space(25);
    padding-bottom: space(25);
  }
}

.hero-primary__background-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: color(red);
  z-index: -1;

  // &::after {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   display: block;
  //   width: 100%;
  //   height: 100%;
  //   content: "";
  //   background-color: rgba(color(black), 0.7);
  // }
}

.hero-primary__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-primary__inner {
  @include create-row;
  @include flex-grid-column(12);
}

.hero-primary__content {
  background-color: rgba(0, 0, 0, 0.4);
  
  @include flex-grid-column(11);
  // @include grid-col-off(1);
  position: relative;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);

  @include breakpoint(medium) {
    @include flex-grid-size(8);
  }

  @include breakpoint(xxlarge) {
    @include flex-grid-size(7);
  }
}

.hero-primary__badge {
  position: absolute;
  top: 0;
  left: 54px;
  display: block;
  width: 35px;
  height: 35px;
  transform: translate(-72px, 12px);

  &::after {
    position: absolute;
    top: 130%;
    left: 50%;
    display: block;
    width: 1px;
    height: 1000px;
    background-color: color(white);
    content: "";
  }

  @include breakpoint(large) {
    width: 50px;
    height: 50px;
    transform: translate(-80px, 22px);
  }
}

.hero-primary__text {
  padding-left: 30px;
}

.hero-primary__video-button {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 1;
  width: 40px;
  height: 40px;
  padding: space(0.5);
  border: 2px solid color(white);
  border-radius: 50%;
  background-color: rgba(color(black), 0.4);
  fill: color(white);

  @include breakpoint(large) {
    right: 30px;
    bottom: 30px;
    width: 40px;
    height: 40px;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border: 1px solid color(transparent);
    border-radius: 50%;
    @include transition(border-color);
    content: "";
  }

  &:hover::before {
    border-color: color(white);
  }

  &:focus {
    @include focus-white;
  }
}

.hero-primary__video-button-icon--pause {
  width: 100%;
  display: none;

  .hero-primary__video-button.isPlaying & {
    display: block;
  }
}

.hero-primary__video-button-icon--play {
  width: 100%;
  display: block;

  .hero-primary__video-button.isPlaying & {
    display: none;
  }
}
