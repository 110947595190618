.article-6col {
  @include flex-grid-size(12);
  @include breakpoint(medium) {
    @include flex-grid-size(5.9);
  }

  &--even {
    @include breakpoint(medium) {
    }
  }

  &__background-image {
    object-fit: cover;
    z-index: -1;
  }

  &__card-container {
    @include breakpoint(medium) {
      margin-bottom: 0;
    }
  }

  &__card-link {
    display: block;

    // &:focus {
    //   @include focus-red;
    // }

    // &:focus,
    // &:hover {
    //   .news-lockup__title {
    //     text-decoration: underline;
    //   }
    // }
  }

  &__copy-wrapper {
    background: color(white);
    padding-top: rem(20);
    position:relative;
    z-index: 2;
    display: flex;
    flex-flow: column wrap;

    @include breakpoint(medium) {
      width: calc(100% - 86px);
      padding: space(5) space(5);
      margin-top: -65px;
    }

    @include breakpoint(large) {
      padding: space(8) space(9);
    }

    &:after {
      // @include bottom-border-thin("gold-accent");
      margin-top: rem(18);
      margin-bottom: rem(31);
      @include breakpoint(medium) {
        display: none;
      }
    }

    .news-lockup__title {
      @include news-heading-6;
      margin-bottom: rem(13);
      @include breakpoint(medium) {
        @include news-article-heading-4;
      }
      @include breakpoint(large) {
        @include news-article-heading-3;
      }
    }
    .news-lockup__headline {
      margin-bottom: rem(13);
    }
  }

  &--odd {
    @include breakpoint(medium) {
      @include flex-grid-size(3.9);

      .article-6col__copy-wrapper {
        width: 100%;
        margin-top: 0;
        padding: 20px 0 31px 0;

        .news-lockup__headline {
          margin-bottom: 13px;
        }

        .news-lockup__title {
          @include news-heading-6;
          font-size: rem(18);
        }
      }
    }
  }
}

.article-6col__copy:focus {
  .news-lockup__title {
    text-decoration: underline;
  }
}
