@mixin text-heading-1 {
  font-family: font(freight-display);
  font-size: rem(52);
  font-weight: 300;
  line-height: rem(58);
  letter-spacing: rem-calc(0.25);

  @include breakpoint(large) {
    font-size: rem(84);
    line-height: rem(96);
    letter-spacing: rem-calc(0.9);
  }
}

@mixin text-heading-1--sublanding {
  font-family: font(freight-display);
  font-size: rem(52);
  font-weight: 300;
  line-height: rem(58);
  letter-spacing: rem-calc(0.25);
}

@mixin text-heading-2 {
  font-family: font(freight-display);
  // font-size: rem(46);
  // line-height: rem(57);
  font-size: rem(38);
  line-height: rem(48);
  font-weight: 400;

  @include breakpoint(large) {
    // font-size: rem(54);
    // line-height: rem(66);
    font-weight: 300;
    font-size: 44px;
    line-height: 50px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
@mixin text-heading-2--news {
  font-family: font(freight-display);
  font-size: rem(46);
  font-weight: 400;
  line-height: rem(57);

  @include breakpoint(large) {
    font-size: rem(58);
    font-weight: 300;
    line-height: rem(71);
  }
}

@mixin text-heading-3 {
  font-family: font(freight-display);
  // font-size: rem(36);
  // line-height: rem(44);
  font-size: rem(30);
  line-height: rem(36);
  font-weight: 300;

  @include breakpoint(large) {
    // font-size: rem(44);
    // line-height: rem(54);
    font-size: 38px;
    line-height: 44px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

@mixin text-heading-4 {
  font-family: font(proxima-nova);
  // font-size: rem(28);
  // line-height: rem(32);
  font-size: rem(24);
  line-height: rem(30);
  font-weight: 600;

  @include breakpoint(large) {
    // font-size:rem(28);
    // line-height:rem(32);
    font-size: 26px;
    line-height: 32px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

@mixin text-heading-5 {
  font-family: font(proxima-nova);
  // font-size: rem(24);
  // line-height: rem(28);
  font-size: rem(18);
  line-height: rem(24);
  font-weight: 600;

  @include breakpoint(large) {
    // font-size: rem(22);
    // line-height: rem(28);
    font-size: 20px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: color(red);
  }
}

@mixin text-heading-6 {
  font-family: font(proxima-nova);
  // font-size: rem(20);
  // line-height: rem(24);
  font-size: rem(16);
  line-height: rem(22);
  font-weight: 700;

  @include breakpoint(large) {
    // font-size: rem(18);
    // line-height: rem(24);
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
@mixin text-heading-3--news {
  font-family: font(freight-display);
  font-size: rem(36);
  font-weight: 300;
  line-height: rem(44);

  @include breakpoint(large) {
    font-size: rem(48);
    line-height: rem(58);
  }
}

@mixin text-heading-4--news {
  font-family: font(proxima-nova);
  font-size: rem(24);
  font-weight: 600;
  line-height: rem(30);
}

@mixin text-heading-5--news {
  font-family: font(proxima-nova);
  font-size: rem(20);
  font-weight: 600;
  line-height: rem(26);
}

@mixin text-heading-6--news {
  font-family: font(proxima-nova);
  font-size: rem(16);
  font-weight: 700;
  line-height: rem(22);
}

@mixin text-heading-7 {
  font-family: font(proxima-nova);
  font-size: rem(12);
  font-weight: 700;
  line-height: rem(20);
  letter-spacing: rem-calc(1.5);
}

@mixin text-body-large {
  font-family: font(freight-text);
  font-size: rem(19);
  font-weight: 300;
  line-height: rem(30);
  letter-spacing: rem-calc(0.25);

  @include breakpoint(large) {
    font-size: rem(24);
    line-height: rem(36);
  }
}

@mixin text-body-regular {
  font-family: font(freight-text);
  font-size: rem(19);
  font-weight: 400;
  line-height: rem(25);
  letter-spacing: rem-calc(0.25);
}

@mixin news-heading-1 {
  font-family: font(freight-display);

  font-size: rem(52);
  font-weight: 300;
  line-height: rem(58);
  letter-spacing: rem-calc(0.25);

  @include breakpoint(medium) {
    font-size: rem(84);
    font-weight: 300;
    line-height: rem(96);
    letter-spacing: rem-calc(0.9);
  }

  &--left {
    text-align: left;
  }

  &--center {
    text-align: left;
  }

  &--right {
    text-align: left;
  }
}

@mixin news-heading-2--mobile {
  font-family: font(freight-display);
  font-size: rem(46);
  font-weight: 300;
  line-height: rem(56);
}
@mixin news-heading-2--desktop {
  font-family: font(freight-display);
  font-size: rem(58);
  font-weight: 300;
  line-height: rem(71);
}

@mixin news-heading-3 {
  font-family: font(freight-display);

  font-size: rem(36);
  font-weight: 300;
  line-height: rem(44);

  @include breakpoint(medium) {
    font-size: rem(48);
    font-weight: 300;
    line-height: rem(59);
  }
}
@mixin news-heading-3--desktop {
  font-family: font(freight-display);

  font-size: rem(28);
  font-weight: 700;
  line-height: rem(34);
}

@mixin news-heading-4 {
  font-family: font(proxima-nova);

  font-size: rem(24);
  font-weight: 600;
  line-height: rem(30);
}

@mixin news-heading-5 {
  font-family: font(proxima-nova);

  font-size: rem(20);
  font-weight: 600;
  line-height: rem(26);
}

@mixin news-heading-6 {
  font-family: font(proxima-nova);
  font-size: rem(16);
  font-weight: 800;
  line-height: rem(22);
}

@mixin news-heading-7-sm {
  font-family: font(proxima-nova);
  font-size: rem(12);
  font-weight: 600;
  line-height: rem(20);
}

@mixin news-body-large--mobile {
  font-family: font(freight-display);
  font-weight: 350;
  letter-spacing: rem-calc(0.25);
  font-size: rem(18);
  line-height: rem(30);
}
@mixin news-body-large--desktop {
  font-family: font(freight-display);
  font-weight: 350;
  letter-spacing: rem-calc(0.25);
  font-size: rem(24);
  line-height: rem(36);
}

@mixin news-body-regular {
  font-family: font(freight-text);
  font-size: rem(16);
  line-height: rem(24);
  font-weight: 350;
  letter-spacing: rem-calc(0.25);
} 

@mixin news-body-small {
  font-family: font(proxima-nova);
  font-size: rem(14);
  line-height: rem(22);
  letter-spacing: rem-calc(0.5);
}

@mixin news-caption {
  font-family: font(proxima-nova);
  font-size: rem(12);
  line-height: rem(18);
  letter-spacing: rem-calc(0.22);
}

@mixin news-category-text {
  font-family: font(proxima-nova);
  font-size: rem(14);
  line-height: rem(17);
  letter-spacing: rem-calc(1);
  font-style: italic;
  color: color(red);
}

@mixin news-source-text {
  font-family: font(proxima-nova);
  font-size: rem(14);
  line-height: rem(17);
  letter-spacing: rem-calc(1);
  color: color(dark-gray);
}

@mixin news-cta {
  font-family: font(proxima-nova);
  font-size: rem(12);
  line-height: rem(15);
  letter-spacing: rem-calc(2);
  color: color(dark-gray);
}

@mixin news-cta-mega-text {
  font-family: font(freight-display);
}

@mixin news-cta-text-link {
  font-family: font(proxima-nova);
  font-size: rem(12);
  line-height: rem(22);
  letter-spacing: rem-calc(0.5);
  color: color(red);
}

@mixin news-navigation-footer-primary {
}

@mixin news-navigation-footer-primary {
  font-family: font(proxima-nova);
  font-size: rem(12);
  font-weight: 600;
  line-height: rem(15);
  letter-spacing: rem-calc(1.85);
}

@mixin news-navigation-footer-secondary {
  font-family: font(proxima-nova);
  font-size: rem(16);
  font-weight: 550;
  line-height: rem(20);
  letter-spacing: rem-calc(0);
}

@mixin news-cta-button {
  font-family: font(proxima-nova);
  font-size: rem(12);
  font-weight: 700;
  line-height: rem(15);
  letter-spacing: rem-calc(2);
}

@mixin news-card-category {
  font-family: font(proxima-nova);
  font-style: italic;
  font-size: rem(14);
  line-height: rem(17);
  letter-spacing: rem-calc(1);
}
@mixin news-subcategory-title {
  font-family: font(proxima-nova);
  font-style: italic;
  font-weight: 600;
  font-size: rem(24);
  line-height: rem(26);
}
@mixin news-category-title {
  font-family: font(proxima-nova);
  // font-style: italic;
  font-weight: 700;
  font-size: rem(28);
  line-height: rem(34);
  @include breakpoint(medium) {
    font-size: rem(48);
    line-height: rem(58);
  }
}

@mixin news-card-category-ko {
  font-family: font(proxima-nova);
  font-style: italic;
  font-weight: 500;
  font-size: rem(14);
  line-height: rem(17);
  letter-spacing: rem-calc(0.5);
}

@mixin news-article-heading-1--mobile {
  font-family: font(proxima-nova);
  font-weight: 800;
  font-size: rem(32);
  line-height: rem(36);
}
@mixin news-article-heading-1--desktop {
  font-family: font(proxima-nova);
  font-weight: 800;
  font-size: rem(36);
  line-height: rem(40);
}
@mixin news-article-heading-3 {
  font-family: font(proxima-nova);
  font-weight: 700;
  font-size: rem(24);
  line-height: rem(30);
}
@mixin news-article-heading-4 {
  font-family: font(proxima-nova);
  font-weight: 700;
  font-size: rem(20);
  line-height: rem(26);
}

@mixin news-body-copy-tight {
  font-family: font(proxima-nova);
  font-size: rem(16);
  line-height: rem(24);
}

@mixin text-body-small {
  font-family: font(proxima-nova);
  font-size: rem(14);
  font-weight: 400;
  line-height: rem(22);
  letter-spacing: rem-calc(0.7);
}

@mixin news-cards-text-body-small {
  font-family: font(proxima-nova);
  font-size: rem(16);
  font-weight: 400;
  line-height: rem(24);
  letter-spacing: rem-calc(0.7);
}

@mixin news-caption-title {
  font-family: font(proxima-nova);
  font-size: rem(14);
  font-weight: 600;
  line-height: rem(24);
}

@mixin text-body-exact {
  font-family: font(proxima-nova);
  font-size: rem(14);
  font-weight: 400;
  line-height: rem(20);
}

@mixin text-caption {
  font-family: font(proxima-nova);
  font-size: rem(12);
  font-weight: 400;
  line-height: rem(18);
  letter-spacing: rem-calc(0.23);
}

@mixin text-cta-default {
  font-family: font(proxima-nova);
  // font-size: rem(12);
  // font-weight: 700;
  // line-height: 1;
  // letter-spacing: rem-calc(2);
  // text-transform: uppercase;
  font-size: rem(14);
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: rem-calc(1);
  text-transform: capitalize;
  @include breakpoint(medium) {
    font-size: rem(16);
  }
}

@mixin text-footer-navigation {
  font-family: font(proxima-nova);
  font-size: rem(12);
  font-weight: 700;
  line-height: rem(15);
  letter-spacing: rem-calc(1.85);
  text-transform: uppercase;
}

@mixin text-footer-legal {
  font-family: font(proxima-nova);
  font-size: rem(12);
  font-weight: 400;
  line-height: rem(15);
}

@mixin figures-numbers-h2 {
  font-family: font(freight-display);
  font-size: rem(54);
  font-weight: 400;
  line-height: rem(40);
  font-variant: lining-nums;
}

@mixin figures-card-copy {
  font-family: font(freight-text);
  font-size: rem(30);
  font-weight: 300;
  line-height: rem(34);
}

@mixin figure-context-image-text {
  font-family: font(freight-text);
  font-size: rem(26);
  letter-spacing: -0.65px;
  line-height: 1.08;

  @include breakpoint(large) {
    font-size: rem(40);
    letter-spacing: -1px;
    line-height: 1.22;
  }
}

.text-black {
  color: color(black);
}

@mixin breadcrumbs{
  font-family: font(proxima-nova);
  font-size: rem(14);
  font-weight: 500;
  line-height: rem(21);
  letter-spacing: 1px;
}

@mixin bullet-list{
  ul {
    display: list-item;
    margin: 0 0 2rem 2rem;

    &::marker{
      color: transparent;
    }

    li {
      display: list-item;
      padding: 0px;
      line-height: 160%;
      margin: 5px 0;

      &:before {
        content: "";
        background-image: url("../svgs/functional/icon-target--red.svg");
        background-repeat: no-repeat;
        background-size: contain;
        width: 10px;
        height: 10px;
        display: inline-block;
        margin-left: -20px;
        margin-right: 10px;  
      }

      ul{
        list-style-type: disc;
        display: list-item;
        margin: 0 0 0 2rem;

        li{
          &::before{
            content: unset;
          }
          &::marker{
            color: #C41230;
            font-size: 22px;
          }

          ul{
            list-style-type: circle;

            li{
              ul{
                list-style-type: square;
              }
            }
          }
        }
      }
    }
  }

  ol {
    // counter-reset: unset;
    list-style: decimal ;
    margin: 0 0 2rem 2rem;
    
    li {
      padding: 0 0 0 0rem;
      display: list-item;
      line-height: 160%;
      margin: 5px 0;

      &:before {
        font-family: font(proxima-nova);
        color: color(red);
        font-size: rem(24);
        font-weight: 400;
        letter-spacing: 0.75px;
        margin-right: space(4);
        text-align: center;
        display: inline-block;
        width: 20px;
        vertical-align: middle;
        margin-left: space(-9);
        content: unset;
      }
      &::marker{
        color: #C41230;
        font-size: 22px;
      }
      ol{
        list-style: lower-alpha;
        margin: 0 0 0 2rem;

        li{
          ol{
            list-style: lower-roman;

            li{
              ol{
                list-style: decimal;
              }
            }
          }
        }
      }
    }
  }
}

@mixin bullet-list-light{
  ul {
    li {
      &:before {
        background-image: url("../svgs/functional/icon-target-white.svg");
      }

      ul{
        li{
          &::marker{
            color: unset;
          }
        }
      }
    }
  }

  ol {
    li {
      &:before {
        color: unset;
      }
      &::marker{
        color: unset;
      }
    }
  }

}