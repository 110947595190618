.cards-text {
  padding-top: space(8);
  padding-bottom: space(8);
  background-color: color(red);
  color: color(white);

  // @include breakpoint(medium) {
  //   padding-top: space(15);
  //   padding-bottom: space(15);
  // }

  // @include breakpoint(large) {
  //   padding-top: space(15);
  //   padding-bottom: space(15);
  // }

  .cards-text__header-container {
    @include create-row;

    .cards-text__header-inner {
      @include flex-grid-column(12);
      align-self: center;
      justify-self: center;
      padding-bottom: space(3);

      @include breakpoint(large) {
        @include flex-grid-size(8);
        padding-bottom: space(3);
      }

      .cards-text__header-heading {
        @include text-heading-3;
        padding-bottom: space(3.75);

        @include breakpoint(large) {
          padding-bottom: space(5);
        }
      }

      .cards-text__header-subheading {
        @include text-body-large;
        padding-left: 0 !important;
      }
    }

    .cards-text_header_cta-container {
      @include flex-grid-column(12);
      /*display: none;*/
      // padding-top: space(7);
      padding-top: 0;
      padding-bottom: space(3);

      @include breakpoint(large) {
        @include flex-grid-size(4);
        display: flex;
        justify-content: flex-end;
        padding-top: 0px;
        padding-bottom: space(3);
        align-self: center;
      }
    }
  }

  .cards-text_footer_cta-container {
    @include create-row;
    @include flex-grid-column(12);
    padding-top: space(7);

    @include breakpoint(large) {
      @include flex-grid-size(4);
      display: none;
      justify-content: flex-end;
      padding-top: 0px;
      padding-bottom: space(15);
      align-self: center;
    }
  }

  .cards-text__cards-container {
    @include create-row;
    flex-direction: column;

    @include breakpoint(large) {
      flex-direction: row;
      // justify-content:center;
    }
  }

  &.cards-text--2 {
    .cards-text__card {
      @include flex-grid-column(12);

      @include breakpoint(large) {
        @include flex-grid-column(6);
      }

      padding-top: space(6);
      padding-bottom: space(6);
    }
  }

  &.cards-text--3 {
    .cards-text__card {
      @include flex-grid-column(12);

      @include breakpoint(large) {
        @include flex-grid-column(4);
      }

      padding-top: space(6);
      padding-bottom: space(6);
    }
  }

  &.cards-text--4 {
    .cards-text__card {
      @include flex-grid-column(12);

      @include breakpoint(large) {
        @include flex-grid-column(3);
      }

      padding-top: space(6);
      padding-bottom: space(6);
    }
  }

  .cards-text__card {
    .cards-text__card-image {
      height: 26px;
      object-fit: cover;
      display: none;

      @include breakpoint(medium) {
        display: block;
      }
    }

    .cards-text__card-inner {
      padding-right: space(5);

      .cards-text__card-headline {
        @include text-heading-5;
        color: color(white);
        // padding-top: space(8);
        padding: 0;

        .cards-text__base-link-text:hover {
          // text-decoration: underline;
        }
        .cards-text__base-link-text:focus {
          outline:2px dotted color(red);
          outline-offset:4px;
        }
        // @include text-heading-4;
        // color: color(black);
        padding-bottom: space(5);

        .cards-text__base-link-text {
          // display: flex;
          // align-items: center;
          display:inline-block;
          &::after {
            display: inline-block;
            width: space(2.25);
            height: space(2.25);
            align-self: flex-end;
            background-color: transparent;
            content: "";
            border-top: 2px solid color(red);
            border-right: 2px solid color(red);
            transform: translateY(-100%) rotate(45deg) translateX(10px);
            transition: all 0.2s ease;
          }
          &:hover {
            &:after {
              transform:translateY(-16px) rotate(45deg) translateX(20px);
            }
          }
        }
      }

      .cards-text__card-copy {
        @include text-body-regular;
        @include bullet-list;
        
        color: color(white);
        padding-top: space(3.5);
        margin-bottom: space(2);

        a {
         color: color(red);
         text-decoration: underline;
         font-style: normal;
         outline:2px dotted transparent;
         outline-offset:0;
         transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
         &:hover {
            text-decoration: none;
         }
         &:focus {
            text-decoration:none;
            outline:2px dotted color(red);
         }
        }
        h2 {
          @include text-heading-5;
          color: color(red);
          padding-top: space(2);
          padding-bottom: space(2);
        }
        h3 {
          @include text-heading-6;
          color: color(black);
          padding-top: space(2);
          padding-bottom: space(2);
        }
        h4 {
          @include text-heading-6;
          color: color(red);
          padding-top: space(2);
          padding-bottom: space(2);
        }
        h5 {
          @include text-heading-6;
          color: color(black);
          padding-top: space(2);
          padding-bottom: space(2);
        }
        h6 {
          @include text-heading-7;
          color: color(red);
          padding-top: space(2);
          padding-bottom: space(2);
        }
        p {
          margin-bottom: space(5);
        }

        // ul {
        //   margin-bottom: space(5);
        //   li {
        //     padding: space(2) 0 space(2) space(4.5);
        //     &:before {
        //       content: "";
        //       background-image: url("../svgs/functional/icon-target--red.svg");
        //       background-repeat: no-repeat;
        //       background-size: contain;
        //       width: 10px;
        //       height: 10px;
        //       display: inline-block;
        //       margin-right: space(5);
        //       margin-left: space(-7.5);
        //     }
        //   }
        // }
        // ol {
        //   list-style: none;
        //   counter-reset: my-awesome-counter;
        //   margin-bottom: space(5);
        //   li {
        //     padding: space(2) 0;
        //     counter-increment: my-awesome-counter;
        //     padding-left: space(9);
        //     &:before {
        //       content: counter(my-awesome-counter);
        //       font-family: font(proxima-nova);
        //       color: color(red);
        //       font-size: rem(24);
        //       font-weight: 400;
        //       letter-spacing: 0.75px;
        //       margin-right: space(4);
        //       text-align: center;
        //       display: inline-block;
        //       width: 20px;
        //       vertical-align: middle;
        //       margin-left: space(-9);
        //     }
        //   }
        // }
      }

      .cards-text__card-cta {
        // padding-bottom: space(15);

        @include breakpoint(large) {
          padding-bottom: 0;
        }

        .base-cta-text{
          margin-top: 0;
        }

        a {
          &:before {
            position: absolute;
            top: 50%;
            right: 0;
            display: block;

            width: space(2.25);
            height: space(2.25);
            background-color: transparent;
            content: "";
            border-top: 2px solid color(red);
            border-right: 2px solid color(red);
            transform: translateY(-50%) rotate(45deg);
            transition: all 0.2s ease;
          }

          .base-cta-text__arrow {
            display: none;
          }

          &:hover {
            &::before {
              transform: translate(5px, -50%) rotate(45deg) scale(1);
            }
          }
        }
      }
    }
  }

  &.warm{
    background-color: color(white-warm) !important;
  }

  &.cards-text--warm {
    background-color: color(white-warm) !important;
    color: color(black);

    .cards-text__header-heading {
      color: color(red);
    }

    .cards-text__card {
      .cards-text__card-inner {
        .cards-text__card-headline {
          @include text-heading-5;
          color: color(black);
          // padding-top: space(8);
          padding: 0;
        }

        .cards-text__card-copy {
          @include text-body-regular;
          color: color(black);
          padding-top: space(3.5);

          a {
           color: color(red);
           text-decoration: underline;
           font-style: normal;
           outline:2px dotted transparent;
           outline-offset:0;
           transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
           &:hover {
              text-decoration: none;
           }
           &:focus {
              text-decoration:none;
              outline:2px dotted color(red);
           }

           h2 {
             @include text-heading-5;
             color: color(black);
             padding-top: space(2);
             padding-bottom: space(2);
           }
           h3 {
             @include text-heading-6;
             color: color(red);
             padding-top: space(2);
             padding-bottom: space(2);
           }
           h4 {
             @include text-heading-6;
             color: color(black);
             padding-top: space(2);
             padding-bottom: space(2);
           }
           h5 {
             @include text-heading-6;
             color: color(red);
             padding-top: space(2);
             padding-bottom: space(2);
           }
           h6 {
             @include text-heading-7;
             color: color(black);
             padding-top: space(2);
             padding-bottom: space(2);
           }
          }
        }
      }
    }
  }

  &.cards-text--white {
    background-color: color(white);
    color: color(black);

    .cards-text__header-heading {
      color: color(red);
    }

    .cards-text__card {
      .cards-text__card-inner {
        .cards-text__card-headline {
          @include text-heading-5;
          color: color(black);
          // padding-top: space(6);
          padding: 0;
        }

        .cards-text__card-copy {
          @include text-body-regular;
          color: color(black);
          padding-top: space(3);

          a {
           color: color(red);
           text-decoration: underline;
           font-style: normal;
           outline:2px dotted transparent;
           outline-offset:0;
           transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
           &:hover {
              text-decoration: none;
           }
           &:focus {
              text-decoration:none;
              outline:2px dotted color(red);
           }
          }

          h2 {
            @include text-heading-5;
            color: color(black);
            padding-top: space(2);
            padding-bottom: space(2);
          }
          h3 {
            @include text-heading-6;
            color: color(red);
            padding-top: space(2);
            padding-bottom: space(2);
          }
          h4 {
            @include text-heading-6;
            color: color(black);
            padding-top: space(2);
            padding-bottom: space(2);
          }
          h5 {
            @include text-heading-6;
            color: color(red);
            padding-top: space(2);
            padding-bottom: space(2);
          }
          h6 {
            @include text-heading-7;
            color: color(black);
            padding-top: space(2);
            padding-bottom: space(2);
          }
        }
      }
    }
  }

  &.cards-text--red {
    .cards-text__card {
      .cards-text__card-inner {
        .cards-text__card-headline {
          color: color(white) !important;
          padding: 0;
        }
        .cards-text__card-copy {
          a{
            color: unset;
          }

          @include bullet-list-light;
          
        }
        .cards-text__card-cta {
          a {
            &:before {
              border-top: 2px solid color(white);
              border-right: 2px solid color(white);
            }
          }
        }
        .cards-text__base-link-text {
          &:focus {
            outline:2px dotted color(white);
            outline-offset:4px;
          }
          &:after {
            border-top:2px solid color(white);
            border-right:2px solid color(white);
          }
        }
      }
    }
  }

  .cards-text__card:last-child {
    .cards-text__card-cta {
      padding-bottom: 0;
    }
  }
  
  // Adding text multirow
  
  &.cards-text-multirow--2{
    .cards-text__card{
      @include flex-grid-column(12);
      padding-top: space(6);
      padding-bottom: space(6);

      @include breakpoint(large) {
        @include flex-grid-column(6);
      }
    }
  }
  &.cards-text-multirow--3{
    .cards-text__card{
      @include flex-grid-column(12);
      padding-top: space(6);
      padding-bottom: space(6);

      @include breakpoint(large) {
        @include flex-grid-column(4);
      }
    }
    
  }
  &.cards-text-multirow--4{
    .cards-text__card{
      @include flex-grid-column(12);
      padding-top: space(6);
      padding-bottom: space(6);

      @include breakpoint(large) {
        @include flex-grid-column(3);
      }
    }
  }
}
