.visuallyhidden {
	 clip: rect(0 0 0 0);
	 clip-path: inset(50%);
	 height: 1px;
	 overflow: hidden;
	 position: absolute;
	 white-space: nowrap;
	 width: 1px;
}
 .show-overflow {
	 overflow: visible !important;
}
 .show-overflow .box-content {
	 overflow: visible !important;
}
 #flyoutnavkbbtn {
	 display: inline-block;
	 transition: top 0.3s;
	 position: fixed;
}
 #flyoutnavkbbtn>ul {
	 margin: 0;
	 padding: 0;
	 background-color:#fff;
	 color: #c41230;
}
 #flyoutnavkbbtn>ul>li {
	 text-align: center;
	 position: relative;
	 padding:0;
	 margin:0;
	 border-bottom:2px solid #c41230;
}
 @media (max-width: 768px){
	 #flyoutnavkbbtn>ul>li {
		 display: flex;
	}
	 #flyoutnavkbbtn .has-submenu button {
		 border-top: 1px solid #fff;
	}
}
 #flyoutnavkbbtn a, #flyoutnavkbbtn .current {
	 flex: 1;
	 display: block;
	/*padding: .25em;
	*/
	 border-color: #E8E8E8;
}
 #flyoutnavkbbtn a {
	 color: #c41230;
	 text-decoration: none;
	 display:inline-block;
}
 #flyoutnavkbbtn li.has-submenu ul li a:hover, #flyoutnavkbbtn li.has-submenu ul li a:focus {
	 color: #fff;
	 border: none;
	 text-decoration: underline;
}
 #flyoutnavkbbtn li.has-submenu ul li a:focus {
	 outline-offset:2px;
	 outline:2px dotted #fff;
}
 #flyoutnavkbbtn .current {
	 background-color: #bbb;
	 color: #000;
	 border-color: #444;
}
 #flyoutnavkbbtn>ul>li>ul {
	 display: none;
	 position: absolute;
	 left: 0;
	 right: 0;
	 top: 100%;
	 padding: 0;
	 margin: 0;
	 padding-bottom:.75em;
	 padding-top:.75em;
	 background-color: #c41230;
	 border-bottom: 1px solid #fff;
	 border-right: 1px solid #fff;
}
 @media (max-width: 767px){
	 #flyoutnavkbbtn>ul>li>ul {
		 border-bottom:none;
		 border-right:none;
		 box-shadow:0px 4px 6px rgba(0,0,0,.4);
	}
}
 #flyoutnavkbbtn>ul>li.open>ul {
	 display: block;
}
 #flyoutnavkbbtn>ul>li>ul li {
	 text-align:left;
	 padding:10px 22px;
}
 #flyoutnavkbbtn>ul>li>ul a {
	 font-family:proxima-nova, helvetica, arial, sans-serif;
	 font-style:normal;
	 color:#fff;
	 letter-spacing:.05em;
	 font-size:16px;
	 text-align:left;
	 font-weight:300;
	 padding:0 4px;
}
 #flyoutnavkbbtn .has-submenu>a:after {
	 margin-left: 5px;
	 line-height: 14px;
	 content: '';
}
 #flyoutnavkbbtn .has-submenu:hover>a:hover:after, #flyoutnavkbbtn .has-submenu>a:focus:after {
	 content: '';
}
 #flyoutnavkbbtn .has-submenu button {
	 background-color: #ad102a;
	 border: none;
	 padding: 0;
	 line-height: 1;
	 padding: 3px;
	 border-right: 1px solid #fff;
	 border-bottom: 1px solid #fff;
}

 @media (max-width: 767px){
	  #flyoutnavkbbtn .has-submenu button {
		 border-right:none;
		 border-bottom:none;
		 border-top:1px solid #fff;
	}
}

 #flyoutnavkbbtn .has-submenu button>span {
	 display: inline-block;
	 width: .8em;
	 height: .8em;
	 background:none;
}
 #flyoutnavkbbtn .has-submenu:hover button>span, #flyoutnavkbbtn .has-submenu button:focus>span {
	/*background: url(../../img/ex-dropdown-active-330d8be7.png) #fff;
	*/
}
 #flyoutnavkbbtn .has-submenu button, #flyoutnavkbbtn .has-submenu a:focus+button {
	 background-color: #edece2;
	 border-left:1px solid #ccc9b8;
	 display:inline-block;
	 width:50px;
	 height:100%;
	 padding-top:16px;
	 padding-bottom:14px;
	 outline:2px dotted transparent;
	 outline-offset:-4px;
	 transition:all .2s ease;
}
 @media (max-width: 767px){
	 #flyoutnavkbbtn .has-submenu button, #flyoutnavkbbtn .has-submenu a:focus+button {
		 height:auto;
		 width:75px;
	}
}
 #flyoutnavkbbtn .open button {
	 background-color: #c41230 !important;
	 color: #fff;
}
/*#flyoutnavkbbtn .has-submenu:hover button,*/
 #flyoutnavkbbtn .has-submenu button:hover, #flyoutnavkbbtn .has-submenu button:focus {
	/*background: #FFF;
	*/
	/*border: 1px solid #036;
	*/
	/*border-left: none;
	*/
	 background-color:#ad102a;
}
 #flyoutnavkbbtn .has-submenu button:focus {
	 outline:2px dotted #fff;
}
 .box-content {
	 display: inline-block;
	 position: fixed;
	 top: 84px;
	 left: 0;
	 z-index: 99;
}
 @media (max-width: 1028px){
	 .box-content {
		 top: 78px;
	}
}
 #flyoutnavkbbtn > ul > li > a {
	 font-size:26px;
	 font-weight:500;
	 width:auto;
	 color:#c41230;
	 font-family:freight-display-pro, "Times New Roman", times, serif;
	 font-style:normal;
	 padding:12px 22px;
	 outline-offset:-4px;
	 line-height:19px;
}
 @media (max-width: 767px){
	 #flyoutnavkbbtn > ul > li > a {
		 font-size:18px;
		 font-family:freight-text-pro, "Times New Roman", times, serif;
		 text-align:left;
	}
}
 #flyoutnavkbbtn > ul > li > a:hover, #flyoutnavkbbtn > ul > li > a:focus {
	 text-decoration:underline;
}
 #flyoutnavkbbtn > ul > li > a:focus {
	 outline:2px dotted #c41230;
}
 .box-content ul li:before {
	 content:none !important;
}
 .box-content .has-submenu button div.sub-menu-carat {
	 border-right:2px solid #000;
	 border-bottom:2px solid #000;
	 width:10px;
	 height:10px;
	 margin-top:-2px;
	 transform:translate(0px,2px) rotate(45deg);
	 transition:all .2s ease;
}
 .box-content .has-submenu.open button div.sub-menu-carat {
	 transform: translate(0px,4px) rotate(225deg);
	 border-right:2px solid #fff;
	 border-bottom:2px solid #fff;
}
 .box-content .has-submenu button:hover div.sub-menu-carat, .box-content .has-submenu button:focus div.sub-menu-carat {
	 border-right:2px solid #fff;
	 border-bottom:2px solid #fff;
}
 #flyoutnavkbbtn .has-submenu.open button {
	 background-color:#ad102a;
}
 @media only screen and (max-width: 600px) {
	 .box-content {
		 top:78px;
	}
}
