.news-tags-list {
  @include text-heading-6;
  color: #c41230;
  font-size: 22px !important;;
  font-weight: 600 !important;
  max-width: unset !important;
  margin-bottom: space(10)!important;
  margin: 0 auto;
  @include flex-grid-size(12);

  @include breakpoint(medium) {
    max-width: 776px;
    @include flex-grid-size(6);
    @include grid-column-position(center);
  }

  .news-tags-list__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .news-tags-list__tag {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      &::after {
        display: inline-block;
        content: ",";
        margin-left: -0.25ch;
        margin-right: 1ch;
      }

      &:first-of-type {
        margin-left: 1ch;
      }

      &:last-of-type {
        &::after {
          display: none;
        }
      }

      span.news-tags-list__tag-link {
        text-decoration: none;
        background-color: color(red);
        color: color(white);
        padding: space(1) space(3);
        margin: space(2);
        font-weight: 600;
        font-size: 18px;
      }

      a.news-tags-list__tag-link {
        background-color: color(red);
        color: color(white);
        padding: space(1) space(3);
        margin: space(2);
        font-weight: 600;
        font-size: 18px;

        &:hover {
          text-decoration: underline;
        }

        &:focus {
          @include focus-red;
        }
      }
    }
  }
}
