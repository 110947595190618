.text-italic {
  font-style: italic;
}
.news-lockup {
}
.text-title {
  @include news-heading-6;
  color: color(black-28);
  margin-bottom: rem(23);

  @include breakpoint(medium) {
    line-height: rem(40);
    font-size: rem(36);
    margin-bottom: rem(16);
  }
}

.news-lockup__headline,
.news-lockup__title,
.news-lockup__body,
.news-lockup__byLine {
  font-family: font(proxima-nova);

  &.text-white {
    color: color(white);
  }
  &.text-red {
    color: color(red);
  }
  &.text-white-warm {
    color: color(white-warm);
  }
  &:focus-within {
    // @include focus-red;
    a {
      text-decoration: underline;
    }
  }
  a:hover {
    text-decoration: underline;
  }
  &:focus-within {
    @include focus-red;
  }
  a:focus {
    outline: 0;
    text-decoration: underline;
  }
}

.news-lockup__headline {
  @include news-card-category;

  margin-bottom: rem(8);

  @include breakpoint(large) {
    margin-bottom: rem(16);
  }
}

.news-lockup__title {
  margin-bottom: rem(23);

  @include breakpoint(large) {
    margin-bottom: rem(16);
  }
}

.news-lockup__body {
  font-size: rem(16);
  line-height: rem(24);
  margin-bottom: rem(8);

  @include breakpoint(large) {
    margin-bottom: rem(16);
  }

  &.news-lockup__title {

    a {

      &:focus {
        outline:none;
      }
    }
  }

  a {
   color: color(red);
   text-decoration: underline;
   font-style: italic;
   outline:2px dotted transparent;
   outline-offset:0;
   transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
   &:hover {
      text-decoration: none;
   }
   &:focus {
      text-decoration:none;
      outline:2px dotted color(red);
   }
  }
  h2 {
    @include text-heading-5;
    color: color(red);
    padding-top: space(2);
    padding-bottom: space(2);
  }
  h3 {
    @include text-heading-6;
    color: color(black);
    padding-top: space(2);
    padding-bottom: space(2);
  }
  h4 {
    @include text-heading-6;
    color: color(red);
    padding-top: space(2);
    padding-bottom: space(2);
  }
  h5 {
    @include text-heading-6;
    color: color(black);
    padding-top: space(2);
    padding-bottom: space(2);
  }
  h6 {
    @include text-heading-7;
    color: color(red);
    padding-top: space(2);
    padding-bottom: space(2);
  }
  p {
    margin-bottom: space(5);
  }
  ul {
    margin-bottom: space(5);
    li {
      padding: space(2) 0 space(2) space(4.5);
      &:before {
        content: "";
        background-image: url("../svgs/functional/icon-target--red.svg");
        background-repeat: no-repeat;
        background-size: contain;
        width: 10px;
        height: 10px;
        display: inline-block;
        margin-right: space(5);
        margin-left: space(-7.5);
      }
    }
  }
  ol {
    list-style: none;
    counter-reset: my-awesome-counter;
    margin-bottom: space(5);
    li {
      padding: space(2) 0;
      counter-increment: my-awesome-counter;
      padding-left: space(9);
      &:before {
        content: counter(my-awesome-counter);
        font-family: font(proxima-nova);
        color: color(red);
        font-size: rem(24);
        font-weight: 400;
        letter-spacing: 0.75px;
        margin-right: space(4);
        text-align: center;
        display: inline-block;
        width: 20px;
        vertical-align: middle;
        margin-left: space(-9);
      }
    }
  }
}

.news-lockup__byline {
  display: flex;
  align-items: center;
}

.news-lockup__byline--text {
  @include news-source-text;
  // color: color(dark-gray);
  // font-family: font(proxima-nova);
  // font-size: rem(16);
  // line-height: rem(24);
  // letter-spacing: rem(0.86);
}

.news-lockup__byLine--divider {
  color: color(dark-gray);
  margin: 0 1ch;
}

.news-lockup__title span {
  padding-right: 10px;
}

.news-lockup__title-inline-image {
  display: inline-block;
  height: 12px;
  width: 12px;
  // margin-left: 1ch;

  > img {
    height: 100%;
    width: 100%;
    margin-top: 1px;
  }
}
