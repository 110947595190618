@mixin news-wysiwyg-fonts {
  font-family: font(proxima-nova);
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: font(proxima-nova);
    font-weight: bold;
    margin-bottom: rem(17);
    @include breakpoint(medium) {
      margin-bottom: rem(30);
    }
  }

  h2 {
    font-size: rem(24);
    line-height: rem(26);
  }

  h3 {
    font-size: rem(20);
    line-height: rem(20);
  }

  h4 {
    font-size: rem(17);
    line-height: rem(26);
  }
  p {
    font-size: rem(16);
    line-height: rem(28);
  }
}

.wysiwyg {
  margin-top: space(7);
  margin-bottom: space(7);
  background-color: color(white);

  sup {
    margin-left:space(1);
  }
}

.wysiwyg__container {
  @include create-row;
}

.wysiwyg__inner {
  @include flex-grid-column(12);
}

.wysiwyg__header-heading {
  @include text-heading-2;
  color: color(red);
  margin-bottom: space(5);
}
.wysiwyg__body {
   a {
      color: color(red);
      text-decoration: underline;
      font-style: normal;
      outline:2px dotted transparent;
      outline-offset:0;
      transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);

      &:focus {
          @include focus-red;
      }

      &:focus,
      &:hover {
          text-decoration: none;
      }
      &:focus {
          text-decoration:none;
          outline:2px dotted color(red);
      }
   }
}
.wysiwyg__body h2 {
  @include text-heading-2;
  color: color(red);
  margin-bottom: space(5);
  margin-top: space(10);
}

.wysiwyg__lead-container {
  margin-bottom: space(15);
  .wysiwyg__lead-container-inner {
    .wysiwyg__lead {
      font-family: font(freight-display);
      font-weight: 300;
      font-size: rem(18);
      line-height: 1.67;
      letter-spacing: 0.25px;
      @include breakpoint(medium) {
        font-size: rem(22);
        line-height: 1.64;
        letter-spacing: 0.43px;
      }
    }
  }
}
.wysiwyg__body-container,
.accordion-primary {
  .wysiwyg__body,
  .accordion-primary__inner {
    .base-cta-text {
      color: color(black);
      font-style: normal;
      margin-bottom: space(15);
      text-decoration: none;
    }
    h6 {
      @include text-heading-6;
      margin-bottom: space(3.75);
      margin-top: space(11.25);
    }
    h5 {
      @include text-heading-5;
      margin-bottom: space(3.75);
      margin-top: space(11.25);
    }
    h4 {
      @include text-heading-4;
      margin-bottom: space(3.75);
      margin-top: space(11.25);
    }
    h3 {
      @include text-heading-3;
      margin-bottom: space(3.75);
      margin-top: space(11.25);
      &.table-header {
        font-family: font(proxima-nova);
        font-size: rem(20);
        line-height: 1.3;
        font-weight: 500;
        margin-bottom: space(5);
        @include breakpoint(large) {
          font-size: rem(24);
          line-height: 1.25;
        }
      }
    }
    p {
      margin-bottom: space(5);
    }
    a {
      color: color(red);
      text-decoration: underline;
      font-style: normal;
      outline:2px dotted transparent;
      outline-offset:0;
      transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
        &:hover {
          text-decoration: none;
        }
        &:focus {
          text-decoration:none;
          outline:2px dotted color(red);
        }
    }
    em > a{
      font-style: italic !important;
    }


    @include bullet-list;
    
    // ul {
    //   // margin-bottom: space(5);
    //   display: list-item;
    //   margin: 0 0 2rem 3rem;

    //   &::marker{
    //     color: transparent;
    //   }

    //   li {
    //     // padding: space(2) 0 space(2) space(7.5);
    //     display: list-item;
    //     padding: 0px;
    //     line-height: 160%;
    //     margin: 5px 0;

    //     &:before {
    //       content: "";
    //       background-image: url("../svgs/functional/icon-target--red.svg");
    //       background-repeat: no-repeat;
    //       background-size: contain;
    //       width: 10px;
    //       height: 10px;
    //       display: inline-block;
    //       // margin-right: space(5);
    //       // margin-left: space(-7.5);
    //       margin-left: -20px;
    //       margin-right: 10px;  
    //     }

    //     ul{
    //       list-style-type: disc;
    //       display: list-item;
    //       margin: 0 0 0 3rem;
  
    //       li{
    //         &::before{
    //           content: unset;
    //         }
    //         &::marker{
    //           color: #C41230;
    //           font-size: 22px;
    //         }
  
    //         ul{
    //           list-style-type: circle;
  
    //           li{
    //             ul{
    //               list-style-type: square;
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }

    // ol {
    //   // list-style: none;
    //   // counter-reset: my-awesome-counter;
    //   // margin-bottom: space(5);
    //   counter-reset: unset;
    //   list-style: decimal ;
    //   margin: 0 0 2rem 3rem;
      
    //   li {
    //     // padding: space(2) 0;
    //     // counter-increment: my-awesome-counter;
    //     // padding-left: space(9);
    //     padding: 0 0 0 0rem;
    //     display: list-item;
    //     line-height: 160%;
    //     margin: 5px 0;

    //     &:before {
    //       // content: counter(my-awesome-counter);
    //       font-family: font(proxima-nova);
    //       color: color(red);
    //       font-size: rem(24);
    //       font-weight: 400;
    //       letter-spacing: 0.75px;
    //       margin-right: space(4);
    //       text-align: center;
    //       display: inline-block;
    //       width: 20px;
    //       vertical-align: middle;
    //       margin-left: space(-9);
    //       content: unset;
    //     }
    //     &::marker{
    //       color: #C41230;
    //       font-size: 22px;
    //     }
    //     ol{
    //       list-style: lower-alpha;
    //       margin: 0 0 0 3rem;
  
    //       li{
    //         ol{
    //           list-style: lower-roman;
  
    //           li{
    //             ol{
    //               list-style: decimal;
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    
  }
  .accordion-primary__inner .accordion-primary__heading {
     margin-top: 0;
  }
  .accordion-primary__button {
     &:focus {
       outline: 2px dotted currentColor;
       outline-offset: 4px;
     }
  }
  .wysiwyg__body, .accordion-primary {
     a:focus {
       outline: 2px dotted currentColor;
       outline-offset: 4px;
     }
  }
}

.wysiwyg__body,
.accordion-primary {
  @include text-body-regular;
}

/* Style tables */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: space(10);
  caption {
     text-align: left;
     padding-bottom: 15px;
     padding-top: 15px;
     color: color(red);
     font-size: 1.2em;
  }
  thead {
    background-color: color(gold);
    tr {
      th {
        @include text-body-small;
        padding: space(3) space(5);
        text-transform: uppercase;
        text-align: left;
        line-height: 1.86;
        letter-spacing: 1.75px;
        font-weight: 700;
        margin: 0;
      }
    }
  }
  tbody {
   a {
     color: color(red);
     text-decoration: underline;
     outline:2px dotted transparent;
     outline-offset:0;
     transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
     &:hover {
        text-decoration: none;
     }
     &:focus {
        text-decoration:none;
        outline:2px dotted color(red);
      }
    }
    tr {
      th {
         border-bottom: 2px solid color(gold-accent);
         text-align: left;
         padding: space(3) space(5);
         vertical-align: top;
         font-family: font(proxima-nova);
         font-weight:700;
         line-height:110%;
         color:color(red);
      }
      td {
        font-family: font(proxima-nova);
        font-weight: 400;
        font-size: rem(16);
        line-height: 1.5;
        letter-spacing: normal;
        padding: space(3) space(5);
        margin: 0;
        border-bottom: 2px solid color(gold-accent);

        strong {
          font-family: font(proxima-nova) !important;
          font-weight: 600;
        }
        &.delimiter {
          border-color: color(gold) !important;
        }
      }
      &.alt {
        td {
          background-color: color(gold);
        }
      }
    }
  }
  tfoot {
    tr {
      td {
        font-family: font(proxima-nova);
        font-size: rem(12);
        line-height: 1.33;
        letter-spacing: normal;
        background-color: color(gold);
        padding: space(3) space(5);
        margin: 0;
        font-weight: 500;
        @include breakpoint(large) {
          font-size: rem(16);
          line-height: 1.5;
        }
      }
    }
  }
}

.wysiwyg--sidebar {
  @include breakpoint(medium) {
    // margin-top: rem(25);
    margin-bottom: rem(25);
    margin-right: 0;
    flex: 0 0 50%;
    max-width: 50%;
    margin-left: auto;
  }
}

.wysiwyg--news {
  margin-top: rem(25);
  margin-bottom: rem(25);

  .wysiwyg__container {
    @include create-news-row;
  }

  .wysiwyg__lead-container-inner {
    .wysiwyg__lead {
      font-family: font(proxima-nova);
      font-weight: 600;
      font-size: rem(18);
      @include breakpoint(medium) {
        font-size: rem(36);
        line-height: rem(40);
      }
    }
  }

  .wysiwyg__body,
  .accordion-primary {
    @include news-body-regular;
    @include news-wysiwyg-fonts;
    font-weight: normal;
    line-height: rem(28);
  }
  .wysiwyg__inner {
    padding: 0;
    // @include flex-grid-size(6);
    // @include grid-column-position(center);
  }

  .wysiwyg__header-container {
    @include flex-grid-row();
  }

  .wysiwyg__heading-container,
  .wysiwyg__lead-container {
    @include flex-grid-size(12);
  }
  .wysiwyg__heading-container-inner,
  .wysiwyg__lead-container-inner {
    @include flex-grid-size(12);
    @include breakpoint(medium) {
      @include flex-grid-size(6);
      @include grid-column-position(center);
    }
  }
  .wysiwyg__body-container {
    @include flex-grid-size(12);
    @include flex-grid-row;
    // @include grid-column-position(center);
    // margin-right: 0;
  }

  .wysiwyg__body-container-inner {
    @include flex-grid-size(12);
    @include breakpoint(medium) {
      // @include flex-grid-size(6);
      // @include grid-column-offset(3);
      @include flex-grid-size(7.2);
      @include grid-column-offset(2.4)
      // -webkit-box-flex: 0;
      // -ms-flex: 0 0 60%;
      // flex: 0 0 60%;
      // max-width: 60%;
      // margin-left: 20%;
    }
  }
}

.news-sidebar__main-content {
  .wysiwyg__inner {
    padding-left: 0px;
    padding-right: 0px;
  }

  .wysiwyg__container {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.wysiwyg__body-container--drop-caps {
  .wysiwyg__body :first-child {
    &:first-letter {
      color: color(red);
      font-family: font(freight-display);
      font-size: rem(125);
      line-height: 100px;
      float: left;
      padding-top: 0px;
      padding-right: 8px;
      padding-left: 3px;
    }
  }
  .wysiwyg__body {
    @include news-body-regular;
    font-family: font(proxima-nova);
    @include breakpoint(medium) {
      line-height: rem(28);
    }
  }
}

.wysiwyg__body-container-inner--with-inline-image {
  @include flex-grid-size(12);
  @include breakpoint(medium) {
    @include flex-grid-size(6);
    @include grid-column-position(center);
  }
}

.wysiwyg__body-inline-block {
  width: 100%;
  background: #fff;
}
.side-left {
  @include breakpoint(medium) {
    float: left;
    margin-left: -40%;
    width: 70%;
    margin-right: 21px;
  }
}
.side-right {
  @include breakpoint(medium) {
    float: right;
    margin-right: -40%;
    width: 70%;
    margin-left: 21px;
  }
}

.wysiwyg__body-inline-image-wrapper {
  position: relative;
  // padding-top: calc((3 / 4) * 100%);
  padding-top:100%;
  width: 100%;
  height: 0;

  .wysiwyg__body-container-inner {
    @include flex-grid-size(12);
    @include breakpoint(medium) {
      @include flex-grid-size(6);
      @include grid-column-offset(3);
    }
  }
}

.wysiwyg__body-inline-image {
  position: absolute;
  top: 0;
  // left: 0;
  // height: auto;
  height: 100%;
  width: 100%;
  // max-width: unset;
  // max-width:80%;
  right:0;
  object-fit: cover;
}

.wysiwyg__body-inline-image-divider {
  background-color: color(grey);
  height: 2px;
  width: 100%;
  margin-bottom: rem(20);
}

.wysiwyg__body-inline-image-lockup-wrapper {
  font-family: font(proxima-nova);
  font-size: rem(14);
  font-weight: 600;
  line-height: rem(24);
  padding: rem(20) 0;
}

.avoid-clicks {
  pointer-events: none;
}

.highlightable {
  &::-moz-selection {
    background-color: color(gold);
  }
  &::selection {
    background-color: color(gold);
  }
}

.wysiwyg__body-divider {
  border-bottom: 1px solid color(gold-accent);
  margin-top: 40px;
  margin-bottom: 20px;
}

.tweet-this-container.wysiwyg__body {
  @include news-wysiwyg-fonts;
}

// Localist Style
.wysiwyg__body{
  .localist-widget{
    .modernList{
      .localist_widget_container{
        li.lw_event_item{
          border: none !important;

          &::before{
            content: none;
          }

          a.lw_event_item_image{
            span.lw_event_item_date{
              background-color: #C41230;
              padding: 10px;
            }
          }
          .lw_event_content{
            .lw_event_item_title{
              a{
                font-family: freight-display-pro,serif;
                font-weight: 400;
                font-style: normal;
                line-height: 30px;
                color: #c41230 !important;
                font-size: 24px !important;
                border-bottom: none !important;
                letter-spacing: .01562rem;
                outline: 2px dotted transparent;
                transition: all .2s ease;
                text-decoration: none;
              }
            } 
            .lw_event_meta{
              div.action_button{
                display: none !important;
              }
            }
          }
          
        } 
      } 

    }

    .cardWidget{
      .event-list{
        li.event{
          &::before{
            content: none;
          }

          &::marker{
            content: none;
          }

          .event-card{
            .event-overview{
              h2.event-title{
                font-family: freight-display-pro,serif;
                font-weight: 400;
                line-height: 30px;
                color: #c41230 !important;
                font-size: 24px !important;
                border-bottom: none !important;
                letter-spacing: .01562rem;
                outline: 2px dotted transparent;
                transition: all .2s ease;
                margin: 0;
              }
            } 
          } 
        } 
      } 
    }

    .classicList{
      .lw{
        li.lwe{
          padding: 30px 10px !important;

          &::before{
            content: none;
          }

          .lwn{
            .lwn0{
              color: #7e6d53;
              font-family: proxima-nova,sans-serif;
              font-size: 14px;
              font-weight: 600;
              letter-spacing: .125rem;
              line-height: 20px;
              text-transform: uppercase;
            }
            a{
              font-family: freight-display-pro,serif;
              font-weight: 400;
              line-height: 30px;
              color: #c41230 !important;
              font-size: 24px !important;
              border-bottom: none !important;
              letter-spacing: .01562rem;
              outline: 2px dotted transparent;
              transition: all .2s ease;
              font-style: normal;
              text-decoration: none;
            }
          }
          .lwl{
            .lwl0{
              color: #464646;
              font-family: proxima-nova,sans-serif;
              font-size: 14px;
              font-weight: 600;
              letter-spacing: .05rem;
              line-height: 20px;
              text-transform: uppercase;
            }
            a{
              color: #464646;
              font-family: proxima-nova,sans-serif;
              font-size: 14px;
              font-weight: 600;
              letter-spacing: .05rem;
              line-height: 20px;
              text-transform: uppercase;
              border-bottom: none;

              &:hover{
                color: #464646;
              }
            }
          }
        }

      } 
    }

  }

  #lclst_widget_footer{
    display: none;
  }
}