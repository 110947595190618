// Adhere
@mixin adhere {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// Aspect Ratio
@mixin aspect-ratio($x, $y) {
  padding-top: unquote(($y / $x) * 100 + "%");
}

// Transition
@mixin transition($property, $duration: time(), $timing-function: ease()) {
  transition: {
    property: $property;
    duration: $duration;
    timing-function: $timing-function;
  }
}

// Focus
@mixin focus-red {
  outline: 2px dotted color(red);
  outline-offset: 4px;
}

@mixin focus-red-ie {
  border: 4px solid transparent;
  outline: 2px dashed color(red);
  padding: 2px;
}

@mixin focus-white {
  outline: 2px dotted color(white);
  outline-offset: 4px;
}

// Background gradient
@mixin gradient-black {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  content: "";
  background: linear-gradient(to top, color(black-00) 0%, color(black-gradient) 50%);
  pointer-events: none;
  left: 0;
  right: 0;
}


// Vertical align element
@mixin vertical-align($position: relative, $percentage: 50%) {
  position: $position;
  top: $percentage;
  transform: translateY(-$percentage);
}

@mixin bottom-border($color) {
  height: 2px;
  display: block;
  background-color: color($color);
  width: 100%;
  content: "";
}

@mixin bottom-border-thin($color) {
  height: 1px;
  display: block;
  background-color: color($color);
  width: 100%;
  content: "";
}

@mixin fixed-aspect-ratio-4-3 {
  padding-top: calc((3 / 4) * 100%);
  position: relative;
  height: 0;
  width: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.fixed-aspect-ratio-4-3 {
  @include fixed-aspect-ratio-4-3;
}
.fixed-aspect-ratio-16-9 {
  padding-top: calc((9 / 16) * 100%);
  position: relative;
  height: 0;
  width: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

// Use with text that should be accessed by screen readers only
.sr-only {
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
}
