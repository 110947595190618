.news-category-index {
  // @include create-news-row;
  flex: 0 0 100%;
  max-width: 100%;
  @include breakpoint(medium) {
    flex: 0 0 58%;
    max-width: 58%;
  }

  &__item-divider {
    height: 2px;
    width: 100%;
    background: color(gold);
    margin: rem(25) 0;
    @include breakpoint(medium) {
      margin: rem(30) 0;
    }
  }
}
.load-more-section__content {
  .news-category-index {
    flex-flow: column wrap;
  }
  .category-index-card,
  .news-category-index__item-divider {
    @include breakpoint(small down) {
      @include flex-grid-size(12);
      @include grid-column-position(center);
    }
    @include breakpoint(medium) {
      // @include flex-grid-size(6);
      flex: 0 0 58%;
      max-width: 58%;
      // @include grid-column-position(1);
    }
  }
  .news-category-index__item-divider {
    flex-basis: auto;
  }
}
