.bio-accordion__container {
  @include create-news-row;
}

.bio-accordion {
  @include flex-grid-size(12);
}

.bio-accordion__header {
  @include text-heading-5;
  font-weight: 800;
  color: color(red);
  padding-top: space(8);
  padding-bottom: space(8);
}

.bio__dropdown-bar {
  position: relative;
}

.bio__name {
  @include text-heading-5;
  padding-top: space(7);
  padding-bottom: space(7);
}

.bio__spacer {
  width: 100%;
  height: 1px;
  background-color: color(gold-accent);
}

.bio__image {
  width: 60vw;
  height: 45vw;
  object-fit: cover;

  @include breakpoint(large) {
    width: 30vw;
    height: 22.5vw;
  }
}

.bio__blurb {
  @include text-body-regular;
  padding-top: space(7);
  padding-bottom: space(7);
}

.bio-accordion__button {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  width: 82px;

  &:focus,
  &:hover {
    @include focus-red;
  }
}

.bio-accordion__button-element {
  position: absolute;
  background-color: color(black);
}

.bio-accordion__button-element--horizontal {
  height: 2px;
  width: 33%;
  left: 33%;
  top: calc(50% - 1px);
}

.bio-accordion__button-element--vertical {
  height: 33%;
  width: 2px;
  left: calc(50% - 1px);
  top: 33%;
  transition: all 0.25s;
}

.bio-accordion__button-element--vertical-hidden {
  height: 0px;
  top: 50%;
}

.bio-accordion__button img {
  height: 100%;
}

.bio__content {
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.25s;
}

.bio__content--open {
  max-height: 200vh;
}

.bio-accordion__contact {
  @include text-body-regular;
  font-family: font(freight-text);
}

.bio-accordion__contact-header {
  @include text-heading-5;
  padding-top: space(7);
  padding-bottom: space(7);
  color: color(red);
}

.bio-accordion__links {
  padding-left: 0px;
  padding-top: space(6);
  padding-bottom: space(7);
}

.bio-accordion__link {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  padding-top: space(1);
  padding-bottom: space(1);
}

.bio-accordion__link a {
  color: color(red);
  text-decoration: underline;
}
