.news-video-card-group {
  
  @include create-news-row;
  &__card {
    margin-right: 1.2%;
    margin-bottom: 20px;

    @include flex-grid-size(12);
    @include breakpoint(medium) {
      @include flex-grid-size(3.85);
    }
    &:after {
      margin-top: rem(30);
      margin-bottom: rem(34);
      @include bottom-border("gold-accent");
      @include breakpoint(medium) {
        display: none;
      }
    }
  }
  &__cards-container {
    @include flex-grid-row;
    max-width: unset;
    width:100%;

    @include breakpoint(medium) {
      margin-bottom: rem(45);
      justify-content: flex-start;
    }
  }
  &__title-block-container {
    width: 100%;
    @include breakpoint(medium) {
      margin-top: rem(28);
      margin-bottom: rem(28);
    }
  }
}
