.post-masthead-feature-image {
  display: flex;
  flex-flow: column nowrap;
  margin-top:3em;

  &__feature-image-background-wrapper {
    position: absolute;
    top: 0;
    height: 75%;
    width: 100%;
    background-color: color(red);

    @include breakpoint (xsmall) {
      height: 85%;
    }
    @include breakpoint (small) {
      height: 85%;
    }

    @include breakpoint(medium) {
      display: none;
    }
  }
  &__feature-image-container {
    // margin-top: 23px;

    @include breakpoint (xsmall) {
      margin-top: 68px;
    }
    @include breakpoint (medium) {
      margin-top: 23px;
    }

    .post-feature-media__divider,
    .post-feature-media__copy-wrapper {
      display: none;
      @include breakpoint(medium) {
        display: block;
      }
    }
  }
  &__feature-image-wrapper {
    position: relative;
    padding-top: 23px;
    @include breakpoint(medium) {
      order: 1;
    }
  }
}

.post-masthead-feature-image--brief {
  .post-masthead-feature-image__feature-image-background-wrapper {
    display: none;
  }
  .post-masthead-feature-image__feature-image-container {
    margin-top: 0;
  }
  .post-masthead-feature-image__feature-image-wrapper {
    padding-top: 0;
    order: 1;
  }
  .post-feature-media__interior {
    @include breakpoint(medium) {
      @include flex-grid-size(6);
      @include grid-column-position(center);
    }
  }
}


.post-masthead-feature-image__masthead-wrapper--desktop{
  display: none;
  @include breakpoint (medium) {
    display: block;
  }
}

// .post-masthead-feature-image__masthead-wrapper--mobile {
//   display: none;
//   @include breakpoint (medium down) {
//     display: block;
//   }
// }

.post-masthead-feature-image__masthead-wrapper--mobile {
  display: none;
  @media print, screen and (max-width: 40em) {
    display:block;
  }
}
