.contact-card-group {
  background-color: color(white-warm);
  &__container {
    @include create-news-row;
  }

  &__contact {
    @include flex-grid-size(12);

    @include grid-column-position(0);

    @include breakpoint(medium) {
      @include flex-grid-size(3);
      @include grid-column-position(1);
      margin-right: rem(21);
    }
  }

  &__contact-image {
  }

  &__contact-image-wrapper {
    @include flex-grid-size(6);
    // @include grid-column-position(1);
    margin-bottom: rem(33);

    @include breakpoint(medium) {
      @include flex-grid-size(7.5);
    }
  }

  &__contact-name {
    @include flex-grid-size(5);
    // @include grid-column-position(1);
    @include news-heading-4;
    margin-bottom: rem(27);
    font-weight: 400;
    @include breakpoint(medium) {
      @include flex-grid-size(12);
      margin-bottom: rem(18);
    }
  }

  &__contacts-wrapper {
    @include flex-grid-size(12);
    @include grid-column-position(0);
    margin-bottom: rem(19);

    @include breakpoint(medium) {
      display: flex;
      margin-bottom: 0;

      // @include create-news-row;
      // margin-left: 0;
      // margin-right: 0;
    }
  }

  &__copy {
    @include flex-grid-size(9);
    @include news-body-regular;
    margin-bottom: rem(91);
    @include breakpoint(medium) {
      @include flex-grid-size(12);
      margin-bottom: rem(86);
    }
  }

  &__header {
    @include news-heading-3;
    @include flex-grid-size(5);
    @include grid-column-position(0);
    color: color(red);
    @include breakpoint(medium) {
      @include grid-column-position(1);
      color: color(dark-grey);
      @include news-heading-7-sm;
    }
  }

  &__header-wrapper {
    @include flex-grid-size(12);
    @include grid-column-position(0);
    margin-top: rem(38);
    margin-bottom: rem(62);

    @include breakpoint(medium) {
      margin-top: rem(53);
      margin-bottom: rem(33);
    }
  }

  .contact-info {
    a:focus {
      @include focus-red;
    }
    @include flex-grid-size(10);
    // @include grid-column-position(1);
    font-family: font(proxima-nova);
    font-size: rem(16);
    line-height: rem(21);
    letter-spacing: rem-calc(0.25);
    font-weight: 600;
    margin-bottom: rem(19);

    &__department {
      margin-bottom: 6px;
      display: block;
    }

    &__email {
      margin-bottom: 8px;
      display: block;
      &:focus,
      &:hover {
        text-decoration: underline;
      }
    }

    &__phone {
      font-weight: 400;
      display: block;
      &:focus,
      &:hover {
        text-decoration: underline;
      }
    }

    @include breakpoint(medium) {
      @include flex-grid-size(12);
    }
  }
}
