.cards-text-alt {
  padding-top: space(10);
  padding-bottom: space(10);
  background-color: color(white-warm);

  @include breakpoint(medium) {
    padding-top: space(15);
    padding-bottom: space(15);
  }

  @include breakpoint(large) {
    padding-top: space(15);
    padding-bottom: space(15);
  }

  .cards-text-alt__header-container {
    @include create-row;

    .cards-text-alt__header-inner {
      @include flex-grid-column(12);
      align-self: center;
      justify-self: center;
      padding-bottom: space(3);

      @include breakpoint(large) {
        @include flex-grid-size(8);
        padding-bottom: space(3);
      }

      .cards-text-alt__header-heading {
        @include text-heading-3;
        padding-bottom: space(3.75);
        color: color(red);

        @include breakpoint(large) {
          padding-bottom: space(5);
        }
      }

      .cards-text-alt__header-subheading {
        @include text-body-large;
        padding-left: 0 !important;
      }
    }

    .cards-text-alt_header_cta-container {
      @include flex-grid-column(12);
      // display: none;
      padding-bottom: space(3);

      @include breakpoint(large) {
        @include flex-grid-size(4);
        display: flex;
        justify-content: flex-end;
        padding-top: 0px;
        padding-bottom: space(3);
        align-self: center;
      }
    }
  }

  .cards-text-alt_footer_cta-container {
    @include create-row;
    @include flex-grid-column(12);
    padding-top: space(7);

    @include breakpoint(large) {
      @include flex-grid-size(4);
      display: none;
      justify-content: flex-end;
      padding-top: 0px;
      padding-bottom: space(15);
      align-self: center;
    }
  }

  .cards-text-alt__cards-container {
    @include create-row;
    flex-direction: column;

    @include breakpoint(large) {
      flex-direction: row;
    }

    .cards-text-alt__card {
      padding-bottom: space(6);
      padding-top: space(6);
      padding-left: 1.06rem;

      .cards-text-alt__card-outlone {
        background-color: color(red);
        height: 100%;

        .cards-text-alt__card-inner {
          height: 100%;
          position: relative;
          z-index: 1;
          display: block;
          padding: space(5) space(8);
          color: color(white);
          background-color: color(white-warm);
          border: 1px solid color(red);
          transform: translate(-10px, -10px);
          //@include transition(transform);

          .cards-text-alt__card-headline {
            @include text-heading-5;
            color: color(black);
            padding-bottom: space(5);

            .cards-text__base-link-text {
              // display: flex;
              // align-items: center;
              display:inline-block;
              &::after {
                display: inline-block;
                align-self: flex-end;
                width: space(2.25);
                height: space(2.25);
                background-color: transparent;
                content: "";
                border-top: 2px solid color(red);
                border-right: 2px solid color(red);
                transform: translateY(-100%) rotate(45deg) translateX(10px);
                transition: all 0.2s ease;
              }
              &:focus {
                outline:2px dotted color(red);
                outline-offset:4px;
              }
            }
          }

          .cards-text-alt__card-copy {
            @include text-body-regular;
            color: color(black);

            a {
               color: color(red);
               text-decoration: underline;
               font-style: normal;
               outline:2px dotted transparent;
               outline-offset:0;
               transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
               &:hover {
                  text-decoration: none;
               }
               &:focus {
                  text-decoration:none;
                  outline:2px dotted color(red);
               }
            }
            h6 {
              @include text-heading-6;
              margin-bottom: space(3.75);
              margin-top: space(11.25);
            }
            h5 {
              @include text-heading-5;
              margin-bottom: space(3.75);
              margin-top: space(11.25);
            }
            h4 {
              @include text-heading-4;
              margin-bottom: space(3.75);
              margin-top: space(11.25);
            }
            h3 {
              @include text-heading-3;
              margin-bottom: space(3.75);
              margin-top: space(11.25);
              &.table-header {
                font-family: font(proxima-nova);
                font-size: rem(20);
                line-height: 1.3;
                font-weight: 500;
                margin-bottom: space(5);
                @include breakpoint(large) {
                  font-size: rem(24);
                  line-height: 1.25;
                }
              }
            }
            p {
              margin-bottom: space(5);
            }

            @include bullet-list;
            // ul {
            //   margin-bottom: space(5);
            //   li {
            //     padding: space(2) 0 space(2) space(7.5);
            //     &:before {
            //       content: "";
            //       background-image: url("../svgs/functional/icon-target--red.svg");
            //       background-repeat: no-repeat;
            //       background-size: contain;
            //       width: 10px;
            //       height: 10px;
            //       display: inline-block;
            //       margin-right: space(5);
            //       margin-left: space(-7.5);
            //     }
            //   }
            // }
            // ol {
            //   list-style: none;
            //   counter-reset: my-awesome-counter;
            //   margin-bottom: space(5);
            //   li {
            //     padding: space(2) 0;
            //     counter-increment: my-awesome-counter;
            //     padding-left: space(9);
            //     &:before {
            //       content: counter(my-awesome-counter);
            //       font-family: font(proxima-nova);
            //       color: color(red);
            //       font-size: rem(24);
            //       font-weight: 400;
            //       letter-spacing: 0.75px;
            //       margin-right: space(4);
            //       text-align: center;
            //       display: inline-block;
            //       width: 20px;
            //       vertical-align: middle;
            //       margin-left: space(-9);
            //     }
            //   }
            // }
          }

          &:hover,
          &:focus {
            //transform: translate(0px, 0px);
          }
        }
      }
    }
  }

  &.cards-text-alt--2 {
    .cards-text-alt__card {


      @include breakpoint(large) {
        @include flex-grid-column(6);
        padding-right: 1.87rem !important;
        padding-left: 1.87rem !important;
        margin-left: 0;
      }
    }
  }

  &.cards-text-alt--3 {
    .cards-text-alt__card {
      @include breakpoint(large) {
        @include flex-grid-column(4);
      }
    }
  }

  &.cards-text-alt--4 {
    .cards-text-alt__card {
      @include breakpoint(large) {
        @include flex-grid-column(3);
      }
    }
  }
}
