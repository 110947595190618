.in-news-index {
  background-size:cover;
  // Background
  @include breakpoint(medium) {
    background-image: linear-gradient(to right, transparent 50%, #ffffff 50%),
      url("../images/illustrations/illustration-duotone-red.png");
    background-attachment:fixed;
  }

  &__copy {
    @include news-body-copy-tight;

    @include breakpoint(large) {
      @include news-body-regular;
    }
  }

  &__header {
    @include news-heading-2--mobile;
    padding-top:1em;

    @include breakpoint(large) {
      @include news-heading-2--desktop;
    }
  }

  &__header-container {
    margin: rem(30) 0;

    @include breakpoint(medium) {
      margin: rem(65) 0 rem(32) 0;
    }
  }

  &__search-bar-container {
    height: 60px;
    margin: rem(50) 0;
  }

  &__search-results-divider {
    height: rem(2);
    background-color: color(gold-accent);
    margin: rem(17) 0;

    @include breakpoint(medium) {
      margin: rem(12) 0 rem(50) 0;
    }
  }

  &__search-results-item {
    position: relative;
  }

  &__search-results-item-link {
    &:focus,
    &:hover {
      .news-lockup__title {
        text-decoration: underline;
      }
    }
    /* Remove the overlay preventing links from being clickable */
    /* &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    } */
  }

  &__search-results-nav-container {
    width: 100%;
    margin-top: rem(56);
    margin-bottom: rem(150);
  }

  &__stats {
    @include news-heading-6;

    @include breakpoint(medium) {
      @include news-heading-6;
    }
  }

  &__search-results-stats-container {
    margin: rem(20) 0 rem(30) 0;

    @include breakpoint(medium) {
      margin: rem(78) 0 rem(50) 0;
    }
  }

  &__search-container {
    color: color(white);

    // @include breakpoint(medium) {
    //   position: sticky;
    //   top: 65px;
    // }
  }

  &__search-wrapper {
    background:url("../images/illustrations/illustration-duotone-red.png");

    @include breakpoint(medium) {
      background:none;
      // overflow: scroll;
      height: 100vh;
      // position: sticky;
      top: 0;
    }
    @include breakpoint(small down) {
      background: url("../images/illustrations/illustration-duotone-red.png");
    }
  }

  // GRID SIZES

  &__search-container,
  &__search-results-container {
    @include breakpoint(small down) {
      @include create-news-row;
    }
  }

  &__inner {
    // @include breakpoint(medium) {
    //   @include create-news-row;
    // }
  }

  &__search-wrapper,
  &__search-results-wrapper {
    @include flex-grid-size(12);

    @include breakpoint(medium) {
      @include flex-grid-size(6);
    }
  }

  &__search-results-wrapper {
    background:color(white);
  }

  &__search-container,
  &__search-results-container {
    // @include flex-grid-size(12);

    // @include breakpoint(medium) {
    //   @include flex-grid-size(5 of 6);
    // }
  }

  &__search-results-container {
    @include breakpoint(medium) {
      @include grid-column-offset(1);
    }
  }

  &__header-contanier,
  &__copy-container,
  &__search-bar-container,
  &__search-results-stats-container,
  &__search-results-items-container,
  &__search-results-nav-container {
    @include breakpoint(small down) {
      @include flex-grid-size(12);
    }
  }

  // Lockup Customization
  &__search-results-item {
    .news-lockup__headline {
      // Font
      // Margins
      margin: 0;
      // Padding
    }

    .news-lockup__title {
      // Font
      @include news-heading-4;
      // Margins
      margin: rem(10) 0 rem(15) 0;
      // Padding
    }

    .news-lockup__body {
      // Font
      // Margins
      margin: 0;
      // Padding
    }

    .news-lockup__byline {
      // Font
      // Margins
      margin-top: rem(5);
      // Padding
    }
  }
}

// fixing broken grid
@media screen and (max-width: 39.99875em) {
  .in-news-index__search-container,
  .in-news-index__search-results-container {
    max-width: 75rem;
    margin-right: auto;
    margin-left: auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: 1.5625rem;
    margin-left: 1.5625rem;
    -ms-flex: 1;
    flex: 1;
  }
}

@media screen and (max-width: 39.99875em) and (min-width: 23.4375em) {
  .in-news-index__search-container,
  .in-news-index__search-results-container {
    margin-right: 1.5625rem;
    margin-left: 1.5625rem;
  }
}

.in-news-index__search-container,
.in-news-index__search-results-container {
  @include flex-grid-size(12);
}

@media print, screen and (min-width: 40em) {
  .in-news-index__search-container, .in-news-index__search-results-container {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
}

@media print, screen and (min-width: 40em) {
  .in-news-index__inner {
    max-width: 75rem;
    margin-right: auto;
    margin-left: auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: .125rem;
    margin-left: .125rem;
    -ms-flex: 1;
    flex: 1;
  }
}

@media print, screen and (min-width: 40em) and (min-width: 23.4375em) {
  .in-news-index__inner {
    margin-right: 1.5625rem;
    margin-left: 1.5625rem;
  }
}

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .in-news-index__inner {
    margin-right: 2.1875rem;
    margin-left: 2.1875rem;
  }
}

@media print, screen and (min-width: 40em) and (min-width: 64em) {
  .in-news-index__inner {
    margin-right: 8.4375rem;
    margin-left: 8.4375rem;
  }
}

@media screen and (min-width: 40em) and (min-width: 75em) {
  .in-news-index__inner {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
}
