.in-the-news {
	position: relative;
	margin-top: space(15);
	margin-bottom: space(15);

	.in-the-news__inner {
		@include create-expanded-news-row;
		background-color: color(white-warm);
		padding-bottom: 40px;

		@include breakpoint(medium) {
			padding-bottom: 0;
		}

		@include breakpoint(large) {
			-webkit-box-shadow: 7.5px 7.5px 0 0 rgba(196, 18, 48, 1);
			-moz-box-shadow: 7.5px 7.5px 0 0 rgba(196, 18, 48, 1);
			box-shadow: 7.5px 7.5px 0 0 rgba(196, 18, 48, 1);
		}
	}

	.in-the-news__content {
		width: 100%;
	}

	.news-card-group__header-wrapper,
	.news-card-group__cards-wrapper {
		@include breakpoint(large) {
			margin-right: rem-calc(50);
			margin-left: rem-calc(50);
		}

		@include breakpoint(xlarge) {
			width: 100%;
		}
	}

	.article-6col__copy-wrapper {
		background-color: color(white-warm);
		border-bottom: 1px solid color(gold-accent);

		@include breakpoint(medium) {
			border-bottom: none;
		}
	}

	.article-6col__copy-wrapper::after {
		display: none;
	}

	.article-6col--odd .article-6col__copy-wrapper {
		padding-bottom: 5px;
	}
}
