// Configured for our standard grid – SHOULD NOT be changed
// (xlarge and above are omitted as max-width takes over from there)
$_row-margin: (
  xsmall: 10,
  small: 18,
  medium: 38,
  large: 68,
);

// Lookup row-margin values per breakpoint
@function get-row-margin($breakpoint) {
  @return rem-calc(map-get($_row-margin, $breakpoint));
}

$_news-row-margin: (
  xsmall: 10,
  small: 18,
  medium: 38,
  large: 68,
);

// Lookup row-margin values per breakpoint
@function get-news-row-margin($breakpoint) {
  @return rem-calc(map-get($_news-row-margin, $breakpoint));
}

@mixin create-news-row {
  @include flex-grid-row;
  margin-right: get-news-row-margin(xsmall);
  margin-left: get-news-row-margin(xsmall);
  flex: 1;

  @include breakpoint(small) {
    margin-right: get-news-row-margin(small);
    margin-left: get-news-row-margin(small);
  }

  @include breakpoint(medium) {
    margin-right: get-news-row-margin(medium);
    margin-left: get-news-row-margin(medium);
  }

  @include breakpoint(large) {
    margin-right: get-news-row-margin(large);
    margin-left: get-news-row-margin(large);
  }

  @include breakpoint(xlarge) {
    width:90%;
    max-width: rem(1064);
    margin-right: auto;
    margin-left: auto;
  }

  @include breakpoint(xxlarge) {
    width: 90%;
    max-width: rem(1170);
  }
}

// Creates a row that keeps content on the grid but the container extends through the normal margin.
// See "in-the-news" component for an example.
@mixin create-expanded-news-row {
  @include flex-grid-row;
  margin-right: get-news-row-margin(xsmall) - get-news-row-margin(xsmall);
  margin-left: get-news-row-margin(xsmall) - get-news-row-margin(xsmall);
  flex: 1;

  @include breakpoint(small) {
    margin-right: get-news-row-margin(small) - get-news-row-margin(small);
    margin-left: get-news-row-margin(small) - get-news-row-margin(small);
  }

  @include breakpoint(medium) {
    margin-right: get-news-row-margin(medium) - get-news-row-margin(medium);
    margin-left: get-news-row-margin(medium) - get-news-row-margin(medium);
  }

  @include breakpoint(large) {
    margin-right: get-news-row-margin(large) - rem-calc(50);
    margin-left: get-news-row-margin(large) - rem-calc(50);
  }

  @include breakpoint(xlarge) {
    max-width: rem(1064 + 100);
    margin-right: auto;
    margin-left: auto;
  }

  @include breakpoint(xxlarge) {
    width: 90%;
    max-width: rem(1170 + 100);
  }
}

// Create our standard row gutter system
// IMPORTANT: This mixin is used in place of `flex-grid-row`
@mixin create-row {
  @include flex-grid-row;
  margin-right: get-row-margin(xsmall);
  margin-left: get-row-margin(xsmall);
  flex: 1;

  @include breakpoint(small) {
    margin-right: get-row-margin(small);
    margin-left: get-row-margin(small);
  }

  @include breakpoint(medium) {
    margin-right: get-row-margin(medium);
    margin-left: get-row-margin(medium);
  }

  @include breakpoint(large) {
    margin-right: get-row-margin(large);
    margin-left: get-row-margin(large);
  }

  @include breakpoint(xlarge) {
    max-width: rem(1064);
    margin-right: auto;
    margin-left: auto;
    // justify-content:center;
  }

  @include breakpoint(xxlarge) {
    width: 90%;
    max-width: rem(1404);
  }
}

// Lookup Foundation grid-column-gutter values per breakpoint
@function grid-gutter($breakpoint) {
  @return rem-calc(map-get($grid-column-gutter, $breakpoint));
}

// Calculate the margin value needed to break content out of the grid
@function break-grid($breakpoint) {
  @return -(get-row-margin($breakpoint) + (grid-gutter($breakpoint) / 2));
}

// Breaks column content out of the grid to
// full bleed at `xsmall` and `small` breakpoints
@mixin break-grid-small {
  margin-right: break-grid(xsmall);
  margin-left: break-grid(xsmall);

  @include breakpoint(small) {
    margin-right: break-grid(small);
    margin-left: break-grid(small);
  }

  @include breakpoint(medium) {
    margin-right: 0;
    margin-left: 0;
  }
}
