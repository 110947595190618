.news-pagination {
  &__container {
    margin: 10px 0 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  &__indices {
  }
  &__link {
    @include news-body-small;
    line-height: rem(14);
    height: 30px;
    display: block;
    min-width: 20px;
    padding: 7px;
    background-color: color(gold);
    outline-offset:2px;
    outline:2px dotted transparent;
    transition:.2s all ease;

    &:hover {
      background-color:color(red-accent);
      color:white;
    }

    &:focus {
      outline:2px dotted color(red);
    }

    &.active {
      background-color:color(red);
      color:white;

      // temporary
      display:none;
    }

    &[aria-current]:not([aria-current="false"]) {
      color: white;
      background-color: color(red);
    }
  }
  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  // temporarily remove the pagination numbers, but leave prev/next buttons
  &__list-item {
    height: 30px;
    min-width: 20px;
    margin-right: 10px;

    display:none;
  }
  &__list-item:first-of-type,
  &__list-item:last-of-type {
    display:block;
  }
  &__next {
  }
}
.text-body {
}
