.base-cta-mega {
  position: relative;
  display: block;
  padding-top: space(7.50);
  padding-right: space(15);
  padding-bottom: space(3.75);
  color: color(black);
  font-family: font(freight-display);
  font-size: rem(26);
  font-weight: 300;
  line-height: 1;

  @include breakpoint(large) {
    padding-top: space(8.75);
    padding-right: space(15);
    padding-bottom: space(5);
    font-size: rem(38);
  }

  &.base-cta-mega--small {
    font-size: rem(26);
  }

  &::before,
  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background-color: color(gray);
    content: "";
  }

  &::after {
    background-color: color(red);
    transform-origin: left;
    transform: scaleX(0);
    @include transition(transform);
  }

  &.base-cta-mega--light {
    &::before {
      background-color: color(white);
    }
    &::after {
      background-color: color(gold-accent);
    }
  }

  &:hover::after,
  &:focus::after {
    transform: scaleX(1);
  }

  &:focus {
    @include focus-red;
  }

  &.base-cta-mega--light {
    color: color(white);

    &:focus {
      @include focus-white;
    }
  }
}

.base-cta-mega__arrow {
  position: absolute;
  top: 50%;
  right: 5px;
  display: block;
  width: space(3);
  height: space(3);
  border-top: 2px solid color(red);
  border-right: 2px solid color(red);
  transform: translateY(-50%) rotate(45deg);

  .base-cta-mega--light & {
    border-color: color(white);
  }
}
