
/*===== HERO LANDING - EMBED FORM =====*/
.hero-landing__embed-form {
  flex: 0 0 100%;
  max-width: 100%;
  margin-left: 0;
  order: -1;
  -webkit-box-flex: 0;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
  transform: translateY(-25px);
  -webkit-transform: translateY(-25px);
}
@media screen and (min-width: 64em){
  .hero-landing__embed-form {
    flex: 0 0 25%;
    max-width: 25%;
    margin-left: 8.33333%;
    order: 1;
    -webkit-box-flex: 0;
    transform: translateY(-350px);
    -webkit-transform: translateY(-350px);
    height:0;
  }
}
@media screen and (min-width: 90em){
  .hero-landing__embed-form {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.hero-landing__embed-form-inner {
  background-color: #edece2;
  padding: 30px;
}
.hero-landing__embed-form-header {
  color: #c41230;
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: .09375rem;
  padding-bottom: 20px;
}

.hero-landing__embed-form input[type=text], .hero-landing__embed-form input[type=email], .context-embed__context input[type=text], .context-embed__context input[type=email] {
  width: 100%;
  padding: 5px;
}
.hero-landing__embed-form div.form_page input[type=tel] {
  padding:5px;
}
.hero-landing__embed-form select, .context-embed__context select {
  padding: 5px;
  margin-top: 5px;
}

.hero-landing__embed-form .form_label, .context-embed__context .form_label {
  font-size: 15px;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
}
.hero-landing__embed-form .form_button_submit, .context-embed__context .form_button_submit {
  background: #c41230;
  color: #fff;
  padding: 20px 40px;
  display: block;
  font-size: 18px;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  line-height: 1;
  letter-spacing: .125rem;
}
.hero-landing__embed-form .form_button_submit:hover, .context-embed__context .form_button_submit:hover {
  background: #fff;
  color: #c41230;
}
.hero-landing__embed-form .form_button_submit:focus, .context-embed__context .form_button_submit:focus {
  outline: 2px dotted #c41230;
  outline-offset: 3px;
}

.form_question.form_select select {
  width:100%;
}

/* Hide some sample form elements to make it shorter */
// #form_description, .form_address, .form_question_5d93a0fb-3425-4d06-8b41-40092fa6041c, #form_question_e23c60c7-a08c-4284-a53b-94baac719c7a, #form_question_df07a06d-2ec4-4c99-afbb-aff0d59c0beb, #form_question_f290d0ca-48c3-4c76-be9d-6d9cb076f42d, #form_question_404bb81f-a110-42c0-a9f5-3ebb95402795, #form_question_e3764943-4603-4601-aa7f-3315b1b9e0d7, #form_question_2ec187ec-deca-4660-8d36-d9269c1ebb8d, #form_question_a2e8b840-23dc-40ee-a952-ca5ed76a8d43, #form_question_dfaf7c24-40f6-402e-b861-0358c3e6e163, #form_question_1baf26c3-aa7c-48ed-8d58-768ab0488a47 {
//   display: none;
// }



/*==== New Context Embed Widget ====*/
.context-embed {
  position: relative;
  background: #fff;
}
.context-embed__inner {
  display: flex;
  flex-flow: row wrap;
  flex: 1;
  max-width: 75rem;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
}
.context-embed__context {
  position: relative;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}
@media screen and (min-width: 40em){
  .context-embed__inner {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
  .context-embed__context {
    position: relative;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .context-embed__context.right {
    margin-left: 58.33333%;
  }
  .context-embed__context.left {
    margin-right: 58.33333%;
  }
  .context-embed__figure.left {
    right: 0;
  }
}

@media screen and (min-width: 64em){
  .context-embed__inner {
    max-width: 75rem;
    margin-right: 4.25rem;
    margin-left: 4.25rem;
  }
  .context-embed__context {
    padding-right: 0.78125rem;
    padding-left: 0.78125rem;
  }
}

@media screen and (min-width: 75em){
  .context-embed__inner {
    max-width: 1064px;
    margin-right: auto;
    margin-left: auto;
  }
  .context-embed__context {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (min-width: 90em){
  .context-embed__inner {
    max-width: 1404px;
    width: 90%;
  }
}
