.tabs-info {
  padding-top: space(10);
  padding-bottom: space(10);
  background-color: color(white);

  @include breakpoint(medium) {
    padding-top: space(15);
    padding-bottom: space(15);
  }

  @include breakpoint(large) {
    padding-top: space(20);
  }
}

.tabs-info_inner {
  @include create-row;
  @include flex-grid-size(12);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include breakpoint(large) {
    // flex-direction: row;
  }
}

.tabs-info__header-container {
  @include flex-grid-column(12);
  margin-bottom: space(5);

  @include breakpoint(large) {
    @include flex-grid-column(12);
  }

  .tabs-info__header-inner {
    @include text-heading-3;
    color: color(red);

    .tabs-info__header-heading {
      @include text-heading-2;
    }
  }
}

.tabs-info__select-container {
  @include flex-grid-column(12);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: space(7.5);

  @include breakpoint(large) {
    @include flex-grid-column(6);
    flex-direction: row;
    flex-basis: 50%;
    justify-content: flex-start;
    // max-width: none;
  }
}

.tabs-info__panel {
  @include create-row;
  justify-content: space-between;
}

.tabs-info__image-container {
  @include flex-grid-column(12);
  padding-bottom: space(7.5);

  @include breakpoint(large) {
    @include flex-grid-column(6);
    order: 2;
    margin-top: rem(-80);
  }

  .tabs-info__image {
    width: 100%;
  }
}

.tabs-info__content-container {
  @include flex-grid-column(12);

  @include breakpoint(large) {
    @include flex-grid-column(5);
    order: 1;
  }
}

.tabs-info__select-title {
  @include text-heading-6;
  width: 100%;
  margin-left: space(0);
  margin-bottom: space(3);
  font-weight: 300;
  line-height: 1.57;
  letter-spacing: 0.5px;
  font-size: 14px;

  @include breakpoint(large) {
    width: auto;
    margin-right: space(5);
    margin-bottom: space(0);
  }
}

.switch-tab-info__select-wrap {
  position: relative;
  fill: color(gold-accent);
  @include transition((background-color, fill));
  width: 100%;

  &:focus-within {
    @include focus-red;
  }

  @include breakpoint(large) {
    width: inherit;
  }

  &::after {
    position: absolute;
    top: 50%;
    right: space(5);
    display: block;
    width: space(2.5);
    height: space(2.5);
    border-right: 2px solid color(red);
    border-bottom: 2px solid color(red);
    transform: translateY(-70%) rotate(45deg);
    content: "";
    pointer-events: none;
  }

  &:hover {
    background-color: color(gold-accent);

    .tabs-info__select {
      background-color: color(gold-accent);
      color: color(black);
    }
  }
}

.tabs-info__select {
  @include transition((background-color, fill));
  @include text-footer-navigation;
  display: block;
  width: 100%;
  padding: space(4) space(12) space(4) space(5);
  color: color(red);
  border: 2px solid color(black);
  border-radius: 0;
  background-color: color(transparent);
  appearance: none;
}

select::-ms-expand {
  display: none;
}

.tabs-info__content-copy {
  @include text-body-regular;
  margin-bottom: space(7.5);

  @include breakpoint(medium) {
    margin-bottom: space(12.5);
  }

  h6 {
    @include text-heading-6;
    margin-bottom: space(3.75);
    margin-top: space(11.25);
  }
  h5 {
    @include text-heading-5;
    margin-bottom: space(3.75);
    margin-top: space(11.25);
  }
  h4 {
    @include text-heading-4;
    margin-bottom: space(3.75);
    margin-top: space(11.25);
  }
  h3 {
    @include text-heading-3;
    margin-bottom: space(3.75);
    margin-top: space(11.25);
    &.table-header {
      font-family: font(proxima-nova);
      font-size: rem(20);
      line-height: 1.3;
      font-weight: 500;
      margin-bottom: space(5);
      @include breakpoint(large) {
        font-size: rem(24);
        line-height: 1.25;
      }
    }
  }
  p {
    margin-bottom: space(5);
  }
  a {
    color: color(red);
    text-decoration: underline;
    font-style: italic;
    outline:2px dotted transparent;
    outline-offset:0;
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
      &:hover {
        text-decoration: none;
      }
      &:focus {
        text-decoration:none;
        outline:2px dotted color(red);
      }
    }
  ul {
    margin-bottom: space(5);
    li {
      padding: space(2) 0 space(2) space(7.5);
      &:before {
        content: "";
        background-image: url("../svgs/functional/icon-target--red.svg");
        background-repeat: no-repeat;
        background-size: contain;
        width: 10px;
        height: 10px;
        display: inline-block;
        margin-right: space(5);
        margin-left: space(-7.5);
      }
    }
  }
  ol {
    list-style: none;
    counter-reset: my-awesome-counter;
    margin-bottom: space(5);
    li {
      padding: space(2) 0;
      counter-increment: my-awesome-counter;
      padding-left: space(9);
      &:before {
        content: counter(my-awesome-counter);
        font-family: font(proxima-nova);
        color: color(red);
        font-size: rem(24);
        font-weight: 400;
        letter-spacing: 0.75px;
        margin-right: space(4);
        text-align: center;
        display: inline-block;
        width: 20px;
        vertical-align: middle;
        margin-left: space(-9);
      }
    }
  }
}

.tabs-info__links-header {
  display: block;
  // @include text-body-small;
  @include text-footer-navigation;
  color: color(red);
  margin-bottom: space(2.5);
  text-transform: uppercase;
}

.tabs-info__content-header {
  @include text-body-small;
  color: color(red);
  text-transform: uppercase;
  margin-top: space(8);
}

.tabs-info__links-inner {
  margin-bottom: space(5.75);


    a {
       &:focus {
         @include focus-red;
       }
    }

  .base-link-primary {
    padding: 0;

    @include breakpoint(medium) {
      padding-top: space(1.5);
      padding-bottom: space(1.5);
    }

    .base-link-primary--link {
      display: block;
      position: relative;
      padding-top: space(1.5);
      padding-right: space(4);
      padding-bottom: space(1.5);
      @include text-body-regular;

      @include breakpoint(medium) {
        display: inline;
        padding-top: space(1.5);
        padding-bottom: space(1.5);
      }

      &:after {
        display: none;
      }

      &:before {
        position: absolute;
        right: 0;
        bottom: 9px;
        display: block;
        width: 9px;
        height: 9px;
        background-color: transparent;
        content: "";
        border-top: 2px solid #c41230;
        border-right: 2px solid #c41230;
        transform: translateY(-50%) rotate(45deg);
        transition: all 0.2s ease;
      }

      &:hover {
        &:before {
          transform: translate(5px, -50%) rotate(45deg) scale(1);
        }
      }
    }
  }
}

.tabs-info__ctas-inner {
  .base-cta-primary {
    &:first-child {
      margin-bottom: space(3);

      @include breakpoint(medium) {
        margin-bottom: 0;
        margin-right: space(6);
      }
    }
  }
}
