// row header element
.section__header-container{
  @include create-row;
  padding-bottom: space(3);

  .section__header-inner{
    @include flex-grid-column(12);
    align-self: center;
    justify-self: center;
    padding-bottom: space(3);

    @include breakpoint(large) {
      @include flex-grid-size(8);
      padding-bottom: space(3);
    }

    .base-lockup__heading {
      @include text-heading-3;
      padding-bottom: space(3.75);

      @include breakpoint(large) {
        padding-bottom: space(5);
      }
    }

    .base-lockup__copy {
      @include text-body-large;
      padding-left: 0 !important;
      margin-top: 0;
    }

  }
  .section-cta-container{
    @include flex-grid-column(12);
    padding-bottom: space(3);

    @include breakpoint(large) {
      @include flex-grid-size(4);
      display: flex;
      justify-content: flex-end;
      padding-top: 0px;
      padding-bottom: space(3);
      align-self: center;
    }
  }
}

// section header widget
.sectionheader{
  padding-bottom: 32px;
  padding-top: 32px;
}
.section-bgcolor-white{
  background-color: #fff;
}
.section-bgcolor-warmwhite{
  background-color: #FAF9F7;
}
.section-bgcolor-lighttan{
  background-color: #EDECE2;
}
.section-bgcolor-tan{
  background-color: #CCCAB8;
}
.section-bgcolor-red{
  background-color: #c41230;

  .base-lockup__heading{
    color: #fff;
  }
  .base-lockup__copy{
    color: #fff;
  }
  .base-cta-primary__outline{
    border: 2px solid color(white);
  }
  .base-cta-primary__content{
    background-color: color(white);
    color: color(red);

    .base-cta-primary__arrow{
      border-right: 2px solid color(red);
      border-top: 2px solid color(red);
    }
  }
}