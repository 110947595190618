.news-category-spotlight {
  @include section-padding ();
  // background-image: linear-gradient(to bottom, #fff, #fff 10%, color(red) 10%);
  background-image: unset;
  position: relative;
  background-color: color(white);

  &__header {
    color: #c41230;
  }
  &__feature-story-block {
    @include create-row;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &::after {
      content: "";
      position: absolute;
      bottom: 5%;
      left: 0;
      background-color: #CCCAAB;
      width: 100%;
      z-index: 0;
      height: 35%;
    }

    .news-category-spotlight__feature-story-figure {
      flex-basis: 100%;
      margin: space(4) auto;
      z-index: 1;

      img{
        object-fit: cover;
      }
      .news-category-spotlight__feature-story-caption {
        background: linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, 0.8) 80%);
        padding: 10px 25px;
        position: absolute;
        bottom: 0;
        width: 100%;
        color: #fff;
        font-family: font(proxima-nova);
        min-height: 110px;

        .news-category-spotlight__feature-story-caption-copy {
          padding: 6px 12px;
          background-color: #c41230;
          font-size: 20px;
          display: inline-block;
          font-weight: 600;

          a:focus {
            outline: dotted 2px #fff;
            outline-offset: 2px;
          }
        }
        .news-category-spotlight__feature-story-caption-heading {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          padding: 6px;
          background-color: unset !important;
        }
      }
    }
    @include breakpoint(small-desktop){
      .news-category-spotlight__feature-story-figure {
        flex-basis: 32%;
      }
    }
  }
}
