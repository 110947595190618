.post-feature-quote {
  position: relative;
  background-color: #EDECE2;
  &__attribution-wrapper {
    font-family: font(proxima-nova);
    font-weight: 600;
    font-size: rem(12);
    line-height: rem(15);
    margin-bottom: rem(15);
    @include breakpoint(small down) {
      @include flex-grid-size(10);
      @include grid-column-position(1);
      text-align: left;
    }
    @include breakpoint(medium) {
      @include flex-grid-size(12);
      @include grid-column-position(center);
      font-size: rem(16);
      text-align: center;
      line-height: rem(20);
    }
  }
  &__background-wrapper {
    position: absolute;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  &__background-container {
    height: 100%;
    @include flex-grid-row;
    margin-right: get-news-row-margin(xsmall);
    margin-left: get-news-row-margin(xsmall);
    flex: 1;

    @include breakpoint(small) {
      margin-right: get-news-row-margin(small);
      margin-left: get-news-row-margin(small);
    }

    @include breakpoint(medium) {
      margin-right: get-news-row-margin(medium);
      margin-left: get-news-row-margin(medium);
    }

    @include breakpoint(large) {
      margin-right: calc(get-news-row-margin(large) + 15px);
      margin-left: calc(get-news-row-margin(large) + 15px);
    }

    @include breakpoint(xlarge) {
      max-width: rem(1117);
      margin-right: auto;
      margin-left: auto;
    }

    @include breakpoint(xxlarge) {
      width: 95%;
      max-width: rem(1755);
    }
  }
  // &__background-image {
  //   background-color: color(red);
  //   background-image: url("../images/illustration-duotone-red.png");
  //   background-repeat: no-repeat;
  //   background-position: center top;
  //   height: 100%;
  //   width: 100%;
  // }
  &__interior {
    @include breakpoint(medium) {
    }
    @include create-news-row;
    z-index: 1;
    position: relative;
    // color: color(white);
    padding: rem(15) 0;
    // padding: rem(30) 0;
  }
  &__quote-icon-wrapper {
    @include flex-grid-size(10);
    margin: 15px 0;
    @include breakpoint(small down) {
      @include grid-column-position(1);
      > img {
        width: 16px;
        height: auto;
      }
    }
    @include breakpoint(medium) {
      @include grid-column-position(center);

      margin: rem(30) auto;
      > img {
        width: 31px;
        height: auto;
        margin: 0 auto;
      }
    }
  }
  &__quote-icon-wrapper--bottom{
    > img{
      transform: rotate(180deg);
    }
  }
  &__quote-wrapper {
    text-align: left;
    font-family: font(freight-display);
    font-size: rem(22);
    line-height: rem(28);
    font-weight: 550;
    @include breakpoint(small down) {
      @include flex-grid-size(10);
      @include grid-column-position(1);
    }
    @include breakpoint(medium) {
      @include flex-grid-size(10);
      @include grid-column-position(center);
      font-size: rem(32);
      text-align: center;
      line-height: rem(40);
    }
  }
}
