.video-container {
  position:relative;
  padding-bottom:56.25%;
  padding-top:30px;
  height:0;
  overflow:hidden;

  iframe,
  object,
  embed {
    position:absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
  } // iframe, object, embed
} // .video-container
// USAGE: youtube embed code must be wrapped in a (preferably <div>) tag which includes the .video-container class

// .leftImage,
// .rightImage {
//   float:none;
//   width:100%;
//   margin-right:0;
//   margin-left:0;
// }
//
// .leftImage img,
// .rightImage img {
//   width:100%;
//   height:auto;
// }
//
// .leftCaption,
// .rightCaption {
//   width:100%;
// }
//
// @media print, screen and (min-width: 40em) {
//   .leftImage {
//     float:left;
//     margin-right:20px;
//   }
//
//   .rightImage {
//     float:right;
//     margin-left:20px;
//   }
//
//   .leftImage,
//   .rightImage {
//     margin-bottom:10px;
//   }
//
//   .leftCaption,
//   .rightCaption {
//     font-size:14px !important;
//     line-height:140% !important;
//     font-style:italic;
//     margin-top:10px;
//     margin-bottom:10px !important;
//     width:auto;
//   }
//
//   .leftCaption img,
//   .rightCaption img {
//     width:auto;
//     height:auto;
//   }
//
//   .leftCaption {
//     float:left;
//     margin-right:20px;
//   }
//
//   .rightCaption {
//     float:right;
//     margin-left:20px;
//   }
// }

  .leftImage {
    float:left;
    margin-right:20px;
  }

  .rightImage {
    float:right;
    margin-left:20px;
  }

  .leftImage,
  .rightImage {
    margin-bottom:10px;
  }

  .leftCaption,
  .rightCaption {
    font-size:14px !important;
    line-height:140% !important;
    font-style:italic;
    margin-top:10px;
    margin-bottom:10px !important;
    width:auto;
  }

  .leftCaption img,
  .rightCaption img {
    width:auto;
    height:auto;
  }

  .leftCaption {
    float:left;
    margin-right:20px;
  }

  .rightCaption {
    float:right;
    margin-left:20px;
  }

// right/left image/caption styles for figure format
figure.leftAlignedImage,
figure.rightAlignedImage {
  max-width:400px;
  margin-bottom:20px;
}

figure.leftAlignedImage {
  float:left;
  margin-right:20px;
}

figure.rightAlignedImage {
  float:right;
  margin-left:20px;
}

figure.leftAlignedImage img,
figure.rightAlignedImage img {
  width:100%;
  height:auto;
}

figure.leftAlignedImage figcaption,
figure.rightAlignedImage figcaption {
  font-size:14px;
  line-height:140%;
  font-style:italic;
  margin-top:10px;
}

.dotted-rule {

}

.red-dotted-rule {

}

.red-double-rule {

}

.red-solid-rule {

}

// bullets for ordered lists and unordered lists

// simple grid
.grid {
  display:grid;
}

.grid > div {
  padding:1rem;
}

.grid.no-padding > div {
  padding:0;
}

.grid-gap-1 {
  gap:1rem;
}

.grid-gap-2 {
  gap:2rem;
}

.grid-cols-4 {
  grid-template-columns: repeat(1, 1fr);

  @include breakpoint(medium) {
    grid-template-columns:repeat(2, 1fr);
  }

  @include breakpoint(large) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.grid-cols-3 {
  grid-template-columns: repeat(1, 1fr);

  @include breakpoint(medium) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include breakpoint(large) {
    grid-template-columns: repeat(3, 1fr)
  }
}

.grid-cols-2 {
  grid-template-columns: repeat(1, 1fr);

  @include breakpoint (medium) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include breakpoint(large) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.grid-cols-1 {
  grid-template-columns: repeat(1, 1fr);
}

// simplified buttons
// simple padding/margin

// basic class to hide elements
.noShow {
  display:none;
}

.visuallyHidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
