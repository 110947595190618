.news-post-footer {
  @include create-news-row;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.news-post-footer__share-wrapper {
  margin-top: rem(20);
}
