.feature-image-2up {
  display: flex;
  flex-direction: column;

  @include breakpoint(large) {
    flex-direction: row;
  }
}

.feature-image-2up__card {
  position: relative;
  padding: space(8.75) space(6.25);
  background-color: color(red);
  // height: 475px;

  @include breakpoint(large) {
    height: 600px;
    width: 100%;
    padding: space(11);
  }
}

.feature-image-2up__image {
  @include adhere;
  object-fit: cover;
}

.feature-image-2up__overlay {
  @include adhere;
  background-color: color(black-overlay-light);
}

.feature-image-2up__content {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  border: solid 1px color(gold-accent);
  padding: space(6);

  @include breakpoint(large) {
    padding: space(13);
  }
}

.feature-image-2up__heading {
  @include text-heading-3;
  color: color(white);
  padding-bottom: space(3.75);
  @include breakpoint(large) {
    padding-bottom: space(4);
  }
}

.feature-image-2up__copy {
  @include text-body-regular;
  color: color(white);
  padding-bottom: space(3);

  @include breakpoint(medium) {
    padding-bottom: space(10);
  }

  @include breakpoint(large) {
    padding-bottom: space(10);
  }
  a {
    color: color(white);
    text-decoration: underline;
    font-style: italic;
    outline:2px dotted transparent;
    outline-offset:0;
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
    &:hover {
       text-decoration: none;
    }
    &:focus {
       text-decoration:none;
       outline:2px dotted color(white);
    }
  }
  h6 {
    @include text-heading-6;
    margin-bottom: space(3.75);
    margin-top: space(11.25);
    color: color(white);
  }
  h5 {
    @include text-heading-5;
    margin-bottom: space(3.75);
    margin-top: space(11.25);
    color: color(white);
  }
  h4 {
    @include text-heading-4;
    margin-bottom: space(3.75);
    margin-top: space(11.25);
    color: color(white);
  }
  h3 {
    @include text-heading-3;
    margin-bottom: space(3.75);
    margin-top: space(11.25);
    color: color(white);
    &.table-header {
      font-family: font(proxima-nova);
      font-size: rem(20);
      line-height: 1.3;
      font-weight: 500;
      margin-bottom: space(5);
      @include breakpoint(large) {
        font-size: rem(24);
        line-height: 1.25;
      }
    }
  }
  p {
    margin-bottom: space(5);
  }
  ul {
    margin-bottom: space(5);
    li {
      padding: space(2) 0 space(2) space(7.5);
      &:before {
        content: "";
        background-image: url("../svgs/functional/icon-list-style--white.svg");
        background-repeat: no-repeat;
        background-size: contain;
        width: 10px;
        height: 10px;
        display: inline-block;
        margin-right: space(5);
        margin-left: space(-7.5);
      }
    }
  }
  ol {
    list-style: none;
    counter-reset: my-awesome-counter;
    margin-bottom: space(5);
    li {
      padding: space(2) 0;
      counter-increment: my-awesome-counter;
      padding-left: space(9);
      &:before {
        content: counter(my-awesome-counter);
        font-family: font(proxima-nova);
        color: color(white);
        font-size: rem(24);
        font-weight: 400;
        letter-spacing: 0.75px;
        margin-right: space(4);
        text-align: center;
        display: inline-block;
        width: 20px;
        vertical-align: middle;
        margin-left: space(-9);
      }
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  // IE10+ CSS here
  .hero-landing__header-icon-line {
    transform: translate(0, 0);
  }
  .hero-landing__header-icon-container {
    position: static;
  }
}
