.post-masthead {
  padding-top: space(5); // should be 5? was 25

  @include breakpoint(medium) {
    padding-top: space(10);
  }

  &__author {
    font-style: italic;
    font-weight: 500;
  }
  &__body {
    font-size: rem(18);
    line-height: rem(24);
    font-weight: 300;
    color: color(black-28);
  }
  &__byLine {
    margin-top: 0;
    @include breakpoint(medium) {
      font-size: rem(16);
      line-height: rem(30);
      font-weight: 300;
    }
  }
  &__heading {
    letter-spacing: rem(0.86);
    font-style: italic;
    font-weight: 600;
    @include breakpoint(medium) {
      font-size: rem(18);
      line-height: rem(22);
      letter-spacing: rem(1.29);
    }
    &:hover{
      text-decoration: underline;
    }
  }
  &__interior {
    @include create-news-row;
  }
  &__lockup-body-container {
    .title-description{
      font-weight: 800;
      margin: 18px;
   }
  }
  &__lockup-byLine-container {
    color: #282828;
    // display: none;
    @include breakpoint(xsmall) {
      margin-top: rem(15);
    }
    @include breakpoint(medium) {
      margin-top: rem(30);
      display: block;
      font-size: rem(16);
      line-height: rem(30);
      font-weight: 300;
    }
  }
  &__lockup-heading-container {
    color: color(red);
    font-size: rem(12);
    margin-bottom: rem(8);

    letter-spacing: rem(0.86);
    font-style: italic;
    font-weight: 600;
    @include breakpoint(medium) {
      font-size: rem(18);
      line-height: rem(22);
      letter-spacing: rem(1.29);
    }
  }
  &__lockup-title-container {
    margin-bottom: rem(8);
    @include breakpoint(medium) {
      margin-bottom: rem(14);
    }
  }
  &__lockup-wrapper {
    @include flex-grid-size(12);
    @include grid-column-position(center);
    font-family: font(proxima-nova);

    font-size: rem(12);
    line-height: rem(15);
    margin-top: rem(13);
    margin-bottom: rem(13);
    @include breakpoint(medium) {
      @include flex-grid-size(8);
      text-align: center;
      margin-top: rem(55);
    }
  }

  &__published {
    font-style: italic;
    font-weight: 500;
  }

  &__published-date {
    font-style: normal;
    font-weight: normal;
  }
  &__updated {
    display: none;
  }

  &__read-time {
    font-style: normal;
  }
  &__social-container {
    display: none;
    @include breakpoint(medium) {
      display: block;
    }
  }
  &__social-wrapper {
  }
  &__title {
    font-size: rem(26);
    line-height: rem(30);
    letter-spacing: unset;
    font-weight: 700;
    @include breakpoint(medium) {
      font-size: rem(28);
      line-height: rem(32);
    }
  }
  &__updated {
  }
}
