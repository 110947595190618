// @keyframes pulse {
//   0% {
//     background-color: #ff9933;
//   }
//
//   100% {
//     background-color: #c3142d;
//   }
// }

div.omnilertBox {
  bottom: 0;
  left: 0;
  z-index: 9999;
  position: fixed;
  width: 100%;
}

table.SmartBoard_Table {
  // animation: pulse 1s ease-in-out alternate infinite;
  position: relative;
  margin-bottom: 0 !important;
  background: #c41230;
  width: 100%;
  color: #fff;
  border: 4px solid white;
  padding: 5px;
}

table.SmartBoard_Table td {
  border: none;
}

table.SmartBoard_Table td.SmartBoard_Subject {
  font-family:freight-display-pro,serif;
  font-size:24px;
  font-weight:400 !important;
  color: #fff;
  line-height: 1.3em !important;
  background: transparent; /*color: #fff !important;*/
  padding: 10px 15px 5px;
}

table.SmartBoard_Table td.SmartBoard_DateTime {
  color: #fff;
  line-height: 1.3em;
  background: transparent; /*color: #fff !important;*/
  font-size: 1.2em;
  padding: 10px 15px 5px;
  text-align: right;
}

table.SmartBoard_Table td.SmartBoard_Message {
  background: transparent; /*color: #fff !important;*/
  font-size: 16px;
  padding: 15px;
  background: #fff;
  border: 1px solid #ddd;
  color: #000;
}

@include breakpoint(large) {
  table.SmartBoard_Table td.SmartBoard_Subject {
    font-size:38px;
  }

  table.SmartBoard_Table td.SmartBoard_Message {
    font-size:18px;
  }
}
