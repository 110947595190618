.news-category-title-block {
	color: color(red);
	fill: color(red);

	&.text-red {
		color: color(red);
		fill: color(red);
	}
	&.text-white {
		color: color(white);
		fill: color(white);
	}
	&.text-black {
		color: color(black);
		fill: color(black);
	}
	&__container {
		display: flex;
		align-items: center;
	}
	&__icon-wrapper {
		height: rem(32);
		width: 100%;
		max-width: rem(32);
		margin-right: 28px;
		margin-top: 5px;
		fill: color(red);
	}
	&__title {
		@include news-heading-3;
		line-height: rem(38);

		@include breakpoint(medium) {
			line-height: rem(44);
		}

		@include breakpoint(large) {
			@include news-heading-2--desktop;
		}
	}
}

.title-block-w-cta__container {
	.base-cta-text {
		margin-bottom: 20px;
	}
	@include breakpoint(medium) {
		display: flex;
		flex-flow: row nowrap;
		.base-cta-text {
			// margin-bottom: 20px;
		}
	}
}
.title-block-w-cta__title-block {
	flex: 1;
}
