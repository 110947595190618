.post-next {
  margin-top: 50px;
  margin-bottom: 50px;
}

.post-next__inner {
  @include create-news-row;
  background: #000;
}

.post-next__image-container {
  width: 100%;
  min-height: 250px;

  &:hover {
    outline: 2px solid color(red);
    outline-offset: 4px;
  }
}

.post-next__image-container:focus{
  @include focus-red;
}

.post-next__image {
  @include breakpoint(medium down) {
    @include fixed-aspect-ratio-4-3;
  }
  position: relative;

  img {
    object-fit: cover;
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 250px;
    max-height: 500px;
  }
}

.post-next__image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.post-next__image-text-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5%;
  color: white;

  @include breakpoint(medium) {
    width: 65%;
    padding-left: 50px;
  }

  p {
    @include news-heading-7-sm;
    text-transform: uppercase;
    margin-bottom: 5px;

    @include breakpoint(medium) {
      @include news-heading-6;
    }
  }

  h3 {
    @include news-heading-5;

    @include breakpoint(medium) {
      @include news-article-heading-1--desktop;
    }
  }
}
