.feature-stat {
  padding-top: space(18);
  padding-bottom: space(18);
  background-color: color(red);
  background-image: url("../images/illustrations/illustration-duotone-red.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.feature-stat__inner {
  @include create-row;
}

.feature-stat__wrapper {
  display: flex;
  flex-direction: column;

  @include breakpoint(large) {
    flex-direction: row;
  }
}

.feature-stat__text-container {
  .feature-stat__text-heading {
    font-family: font(freight-display);
    font-size: rem(58);
    font-weight: 300;
    line-height: rem(68);
    color: color(white);
    margin-bottom: 15px;
  }

  .feature-stat__text-copy {
    @include text-body-regular;
    color: color(white);
    margin-bottom: 30px;

    a {
      color: color(white);
      text-decoration: underline;
      font-style: italic;
      outline:2px dotted transparent;
      outline-offset:0;
      transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
      &:hover {
         text-decoration: none;
      }
      &:focus {
         text-decoration:none;
         outline:2px dotted color(white);
      }
    }
    h6 {
      @include text-heading-6;
      margin-bottom: space(3.75);
      margin-top: space(11.25);
      color: color(white);
    }
    h5 {
      @include text-heading-5;
      margin-bottom: space(3.75);
      margin-top: space(11.25);
      color: color(white);
    }
    h4 {
      @include text-heading-4;
      margin-bottom: space(3.75);
      margin-top: space(11.25);
      color: color(white);
    }
    h3 {
      @include text-heading-3;
      margin-bottom: space(3.75);
      margin-top: space(11.25);
      color: color(white);
      &.table-header {
        font-family: font(proxima-nova);
        font-size: rem(20);
        line-height: 1.3;
        font-weight: 500;
        margin-bottom: space(5);
        @include breakpoint(large) {
          font-size: rem(24);
          line-height: 1.25;
        }
      }
    }
    p {
      margin-bottom: space(5);
    }
    ul {
      margin-bottom: space(5);
      li {
        padding: space(2) 0 space(2) space(7.5);
        &:before {
          content: "";
          background-image: url("../svgs/functional/icon-list-style--white.svg");
          background-repeat: no-repeat;
          background-size: contain;
          width: 10px;
          height: 10px;
          display: inline-block;
          margin-right: space(5);
          margin-left: space(-7.5);
        }
      }
    }
    ol {
      list-style: none;
      counter-reset: my-awesome-counter;
      margin-bottom: space(5);
      li {
        padding: space(2) 0;
        counter-increment: my-awesome-counter;
        padding-left: space(9);
        &:before {
          content: counter(my-awesome-counter);
          font-family: font(proxima-nova);
          color: color(white);
          font-size: rem(24);
          font-weight: 400;
          letter-spacing: 0.75px;
          margin-right: space(4);
          text-align: center;
          display: inline-block;
          width: 20px;
          vertical-align: middle;
          margin-left: space(-9);
        }
      }
    }
  }
}

.feature-stat__image-container {
  padding-top: space(10);

  @include breakpoint(large) {
    padding-top: space(0);
    margin-left: 130px;
  }

  .feature-stat__image-outer {
    border: 1px solid color(gold-accent);
    background-color: color(red);

    .feature-stat__image-inner {
      width: 100%;
      height: 380px;
      background-color: color(red);
      border: 1px solid color(gold-accent);
      transform: translate(-5px, -5px);
      display: flex;
      align-items: center;

      @include breakpoint(large) {
        width: 380px;
      }

      .feature-stat__image {

        margin: auto;
        vertical-align: middle;
        height: 68%;


      }
    }
  }
}
