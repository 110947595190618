.news-sidebar {
  @include create-news-row;
  // padding-top: 25px;

  .base-lockup {
    margin-bottom: rem(28);
  }
  .base-lockup__heading {
    @include news-heading-4;
  }
}

.news-sidebar__inner {
  @include flex-grid-size(12);
}

.news-sidebar__inner-content {
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: row wrap;
}

.news-sidebar__side-content {
  width: 100%;
  margin-bottom: space(7);

  @include breakpoint(medium) {
    @include flex-grid-size(4);
    position: relative;
    float: right;
    top: 0px;
    right: 0px;
  }

  .base-lockup__copy {
    font-family: font(proxima-nova);
    line-height: 28px;
    font-size: 16px;
    font-weight: 350;
    margin-top: 7px;
  }
}

.news-sidebar__main-content {
  > :first-child {
    margin-top: 0;
    margin-bottom: 0;
  }
  @include flex-grid-size(12);

  @include breakpoint(medium) {
    @include flex-grid-size(7);
    position: relative;
    top: 0px;
    left: 0px;
  }
}

.news-sidebar--spacing-header {
  @include breakpoint(medium) {
    margin-top: 150px;
  }
}

.news-sidebar--spacing-title {
  @include breakpoint(medium) {
    margin-top: 58px;
  }
}

.news-sidebar--spacing-no-header {
  @include breakpoint(medium) {
    margin-top: 0px;
  }
}

// .news-sidebar__side-content {
// 	margin-top: space(7);
// }

.news-sidebar__side-content .base-lockup__heading {
  color: color(black);
}

.news-sidebar__related-spacer {
  height: 1px;
  background-color: color(gold-accent);
  margin: rem(20) 0;
}

.news-sidebar__related {
  .news-lockup__title {
    @include news-heading-6;
  }

  & .text-normal {
    font-weight: normal;
  }

  .news-lockup__headline {
    margin-bottom: space(2);
  }
}

.news-sidebar__related-category {
  color: color(red);
  margin-bottom: rem(5);
  @include news-card-category;
}

.news-sidebar__related-wrapper {
  position: relative;
}

.news-sidebar--hero {
  padding-top: rem(100);
  .news-sidebar__main-content {
    @include breakpoint(medium) {
      @include flex-grid-size(6);
      @include grid-column-position(1);
    }
  }
  .news-sidebar__side-content {
    display: none;

    @include breakpoint(medium) {
      margin-top: rem(85);
      display: block;
      @include flex-grid-size(3);
      // @include grid-column-position(2);
      padding-left: 2%;
    }
  }

  .news-sidebar__side-decoration {
    @include breakpoint(small down) {
      display: none;
    }
    @include grid-column-position(0);
    width: 1px;
    // height: 100%;
    margin: 1% 0;
    background-color: color(red);
  }

  .wysiwyg__container {
    margin: 0;
    width: 100%;
    max-width: unset;
  }
  .wysiwyg__inner {
    padding: 0;
  }
}

.news-sidebar--article {
  .news-sidebar__inner {
    @include breakpoint(medium) {
      // @include flex-grid-size(9);
      // @include grid-column-position(3);
    }
  }
  .news-sidebar__main-content {
    @include breakpoint(medium) {
      @include flex-grid-size(8);
      // @include grid-column-position(1);
    }
  }
  .news-sidebar__side-content {
    display: none;

    @include breakpoint(medium) {
      display: block;
      flex: 0 0 24.75%;
      max-width: 24.75%;
      padding-left: rem(33);
      margin-top: 60px;
    }
  }
  .wysiwyg__body-container-inner {
    @include flex-grid-size(12);
    @include grid-column-position(0);
    margin-left: 0;
  }
  .wysiwyg__container {
    width: 100%;
    max-width: unset;
    margin: 0;
  }
}

.news-sidebar--index {
  .news-sidebar__inner {
    @include breakpoint(medium) {
      @include flex-grid-size(12);
      @include grid-column-position(center);
    }
  }
  .news-sidebar__main-content {
    @include breakpoint(medium) {
      @include flex-grid-size(6);
      @include grid-column-position(1);
    }
  }
  .news-sidebar__side-content {
    display: none;
    margin-left: auto;
    @include breakpoint(medium) {
      display: block;
      @include flex-grid-size(3);
      // @include grid-column-position(2);
    }
  }
  .news-category-index {
    margin: 0;

    @include breakpoint(medium) {
      max-width: unset;
      width: 100%;
    }
  }
}

.news-sidebar--mobile {
  padding-top: rem(100);
  @include breakpoint(medium) {
    display: none;
  }

  .news-sidebar__main-content {
    display: none;
  }
  .wysiwyg__body-container-inner {
    @include flex-grid-size(12);
    @include grid-column-position(0);
    margin-left: 0;
  }
  .wysiwyg__container {
    width: 100%;
    max-width: unset;
  }
}
